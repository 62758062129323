import Accordion from "@src/Components/Accordion"
import { Section } from "@src/Components/StyledUtils"
import { colors, media, shadows } from "@src/theme"
import styled from "styled-components"

interface IMetricPanel {
  selected?: boolean
}

interface IMetric {
  selected?: boolean
}

interface ICategory {
  isSubCategory?: boolean
  cursor?: string
}

export const Category = styled.div<ICategory>`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ isSubCategory }) => (isSubCategory ? "24px" : "28px")};
  line-height: 60px;
  letter-spacing: 0.02em;
  margin-top: ${({ isSubCategory }) => !isSubCategory && "28px"};
  margin-left: ${({ isSubCategory }) => isSubCategory && "28px"};
  color: ${colors.darkBlue};
  cursor: ${({ cursor }) => cursor};
`

export const GroupIcon = styled.img<{ isSubCategory?: boolean }>`
  height: ${({ isSubCategory }) => (isSubCategory ? "24px" : "28px")};
  padding: 0 12px 4px 0;
`
export const ScoreTable = styled.table`
  margin: 10px 0 24px 28px;
  width: calc(100% - 28px);
  border-collapse: collapse;
`

export const MetricPanel = styled(Accordion)<IMetricPanel>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 14px 22px;
  background-color: ${({ selected }) => selected && colors.lightBlue5};
  cursor: pointer;
  &:hover {
    background: rgba(95, 215, 255, 0.3);
  }
`

export const MetricTitle = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  padding: 4px 0;
  letter-spacing: 0.02em;
  color: ${colors.black50};
  @media (max-width: 1300px) {
    flex-basis: 100%;
  }
`

export const Metric = styled.td<IMetric>`
  padding: 0;
  border: ${({ selected }) =>
    selected ? "1px double " + colors.blue5 : "1px solid " + colors.gray15};
  box-shadow: ${({ selected }) => selected && shadows.panel};
  &:hover {
    border: 1px double #39ceff;
  }
`

export const LevelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 360px;
  margin: 0 20px;
  @media (max-width: 835px) {
    margin: 8px 0 0;
  }
`

export const AdvanceTitle = styled.div`
  color: #26249e;
  font-weight: bold;
`

export const ExpandIcon = styled.i`
  display: block;
  cursor: pointer;
  padding: 8px;
`

export const ElementPanel = styled.div`
  min-height: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.02em;
  background-color: ${colors.lightBlue6};
  @media ${media.medium} {
    background-color: ${colors.white};
  }
`

export const ScoreboardPanel = styled.div`
  width: 100%;
`
export const ScoreGroup = styled.div`
  width: 100%;
`

export const OverviewHeader = styled.div`
  display: flex;
  padding-left: 16px;
  font-color: 18px;
  align-items: center;
  background: #828282;
  color: #fff;
  height: 43px;
`

export const OverviewContent = styled.div`
  display: flex;
  padding: 24px 24px 0;
  gap: 20px;
  @media (max-width: 1100px) {
    flex-direction: column;
    padding: 20px 20px 0;
  }
`

export const ElementIcon = styled.img`
  max-height: 80px;
  max-width: 100%;
  margin: 0 10px;
  @media (max-width: 110px) {
    margin: 0;
  }
`

export const OverviewTitle = styled.span`
  padding-left: 16px;
`

export const AdvancePanel = styled.div`
  margin: 16px 24px;
  background: ${colors.white};
  border: 1px solid #39ceff;
  box-sizing: border-box;
  border-radius: 6px;
  @media ${media.medium} {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
  }
`

export const AdvanceImage = styled.img`
  width: 32px;
  height: 32px;
`

export const ExplanationPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 16px 28px;
  border: 1px solid rgba(0, 98, 216, 0.5);
  border-radius: 6px;
  background-color: rgba(0, 98, 216, 0.05);
  color: ${colors.gray60};
`

export const ExplanationTitle = styled.span`
  color: ${colors.darkBlue};
  font-size: 18px;
  font-weight: 700;
  margin-right: 12px;
`
export const ExplanationDescription = styled.span`
  display: flex;
  align-items: end;
`

export const ExplanationText = styled.div`
  @media (max-width: 880px) {
    display: none;
  }
`

export const Description = styled.div`
  line-height: 1.5;
`
export const ElementFooter = styled(Section)``
export const ElementFooterLeftSection = styled(Section)`
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const NeedHelpSection = styled(Section)`
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 19px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid ${colors.blue10};
  }
`
