import { colors, media, shadows } from "@src/theme"
import * as React from "react"
import styled from "styled-components"

interface IContentArea {
  borderTopColor?: string
  maxHeight?: string
  alignItems?: string
  padding?: string
  ref?: any
}

interface IPanel {
  flexGrow?: number
  minHeight?: number
  margin?: string
  noShadow?: boolean
  maxWidth?: number
  alignItems?: string
  borderColor?: string
  hidden?: boolean
  isTopBorder?: boolean
  maxGrow?: number
  isStickyOnMobile?: boolean
}

interface IContent {
  hidden?: boolean
  maxWidth?: string
  maxHeight?: string
  justify?: string
  padding?: string
  alignItems?: string
}

export const View = React.forwardRef((props: any, ref) => (
  <ContentArea ref={ref} alignItems={props.alignItems}>
    <Panel maxWidth={props.maxWidth} maxGrow={props.maxGrow}>
      <Content>{props.children}</Content>{" "}
    </Panel>
  </ContentArea>
))
View.displayName = "View"

export const Panel = styled.div<IPanel>`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  flex-grow: ${({ flexGrow }) => (flexGrow !== undefined ? flexGrow : 1)};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  box-shadow: ${({ noShadow }) => (noShadow ? undefined : shadows.panel)};
  border: ${({ borderColor, noShadow }) =>
    borderColor
      ? "1px solid " + borderColor
      : noShadow
      ? "1px solid rgba(118, 118, 118, 0.4)"
      : undefined};
  max-width: ${({ maxWidth }) => maxWidth || "1600px"};
  align-items: ${(props: any) =>
    props.alignItems ? props.alignItems : undefined};
  .contact-us {
    padding-top: 40px;
    a {
      color: ${colors.link};
    }
  }
  border-top: ${({ isTopBorder, borderColor }) =>
    isTopBorder && "13px solid" + borderColor};
  @media (min-width: 1932px) {
    max-width: ${({ maxGrow }) => `${maxGrow}px` || null};
  }
  @media ${media.medium} {
    box-shadow: none;
    border-radius: 0;
    border-width: 0;
    border-top-width: ${({ isTopBorder }) => isTopBorder && "9px"};
    position: ${({ isStickyOnMobile }) => isStickyOnMobile && "sticky"};
    top: ${({ isStickyOnMobile }) => isStickyOnMobile && "70px"};
  }
`

export const Content = styled.div<IContent>`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  flex-direction: column;
  flex: 1 1 auto;
  max-width: ${({ maxWidth }) => {
    return maxWidth || "1600px"
  }};
  max-height: ${({ maxHeight }) => maxHeight || null};
  justify-content: ${({ justify }) => justify || undefined};
  padding: ${({ padding }) => (padding ? padding : "24px 44px 24px 44px")};
  font-size: 16px;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : undefined)};
  @media ${media.medium} {
    padding: 14px 14px 14px 14px;
  }
`

export const ContentArea = styled.div<IContentArea>`
  width: 100%;
  max-height: ${({ maxHeight }) => maxHeight || undefined};
  min-height: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || "center"};
  justify-content: left;
  padding: ${({ padding }) => padding || "30px 32px"};
  @media ${media.medium} {
    padding: 0;
    min-width: 100px;
    border-top: ${({ borderTopColor }) =>
      borderTopColor && `solid 8px ${borderTopColor}`};
  }
`

export default View
