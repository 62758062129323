import { IRatingDetails, ITabsContext } from "@src/types"
import * as React from "react"

import { TabsContext } from "../Ratings"
import { CNGGrade, CNGName, CNGStyled } from "./styles"

interface IProps {
  risk: IRatingDetails
}

const CategoryNameGrade = ({ risk }: IProps) => {
  const { setScrollTo, setSelectedTab, selectedTab } = React.useContext(
    TabsContext,
  ) as ITabsContext

  const { name, grade, beta, info, grade_color } = risk
  const color = beta && grade !== "N/A" ? "#a1a9b5" : grade_color

  const onNameClick = () => {
    setScrollTo(name)
    selectedTab !== "details" && setSelectedTab("details")
  }

  return (
    <CNGStyled>
      <CNGName onClick={onNameClick}>
        {name}
        {info ? "**" : beta && "*"}
      </CNGName>
      <CNGGrade color={color}>{grade}</CNGGrade>
    </CNGStyled>
  )
}

export default CategoryNameGrade
