import { ButtonSmall } from "@src/Components/Button"
import C from "@src/Controller"
import { validateEmail } from "@src/Validators"
import { TraineeData as ITrainee } from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"

import FormRow from "../FormRow"
import { ButtonWrap, Dialog, FormWrap, Section } from "./styles"

interface IModalEdit {
  isOpen: boolean
  trainee: ITrainee
  onClose: () => void
}
const ModalEdit: React.FC<IModalEdit> = ({ isOpen, trainee, onClose }) => {
  const { firstName, lastName, email } = trainee
  const [data, setData] = React.useState<ITrainee>(trainee)

  const onChange = (value: ITrainee) => {
    setData(value)
  }

  const onEdit = () => {
    const { inactive, email, lastName, firstName, traineeId } = data
    const trimmedEmail = email.trim()
    setData({ ...data, email: trimmedEmail })
    C.Training.editTrainees([
      { inactive, email: trimmedEmail, lastName, firstName, traineeId },
    ])
  }

  const onModalClose = () => {
    onClose()
  }

  React.useEffect(() => {
    setData(trainee)
  }, [trainee])

  const isEnabled =
    (data?.firstName !== firstName ||
      data?.lastName !== lastName ||
      data?.email.trim() !== email) &&
    data?.firstName &&
    data?.lastName &&
    data?.email &&
    validateEmail(data?.email.trim())

  return (
    <Dialog
      title="Edit Team Member"
      className="zeg-dialog"
      isOpen={isOpen}
      onClose={onModalClose}
      content={
        <Section className="bp5-dialog-body">
          <FormWrap>
            <FormRow
              isEdit
              data={data}
              allData={[data]}
              onChange={onChange}
              isColumn
            />
          </FormWrap>
          <ButtonWrap>
            <ButtonSmall
              borderRadius={6}
              disabled={!isEnabled}
              onClick={onEdit}
            >
              Edit Team Member
            </ButtonSmall>
          </ButtonWrap>
        </Section>
      }
    />
  )
}

export default ModalEdit
