import { ProgressBar } from "@blueprintjs/core"
import {
  actions as GLOBAL_MODALS,
  GLOBAL_MODALS_KEYS,
} from "@src/Actions/Reducers/GlobalModalsReducer"
import { ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import { textToPath } from "@src/Portal/Zimm/utils"
import { updateCustomerPortalData } from "@src/Sagas"
import { colors } from "@src/theme"
import { ViewTypes } from "@src/types"
import { calculatePercentage } from "@src/Utils"
import * as _ from "lodash"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  ButtonClose,
  EmptyProgressInfoBlock,
  Footer,
  ModalContent,
  NotStartedInfoBlock,
  ProgressInfoBlock,
  StyledModal,
  TooltipText,
} from "./styles"
interface ICybersecurityRoadBlockModal {
  isOpen: boolean
}
export const CybersecurityRoadBlockModal = ({
  isOpen,
}: ICybersecurityRoadBlockModal) => {
  const { isDesktop } = usePortalSize()
  const dispatch = useDispatch()
  const history = useHistory()
  const isZeguroServiceSession = useSelector(
    (state: tState) => state.portal.isZeguroServiceSession,
  )
  const companyName = useSelector(
    (state: tState) => state.portal.companyProfile.companyName,
  )
  const allQuestionsConfig = useSelector(
    (state: tState) => state.zimm.getConfig.elements,
  )
  const assessmentData = useSelector(
    (state: tState) => state.zimm.getAssessmentData.data,
  )
  const interstitials = useSelector(
    (store: tState) => store.zimm.getConfig.interstitials,
  )
  const completedQuestions = assessmentData?.response?.questions
  const isEmptyState = _.isEmpty(assessmentData)
  const zimmNextQuestion = useSelector(
    (state: tState) => state.zimm.getAssessmentData.nextQuestion,
  )

  const handleClose = () => {
    dispatch(
      GLOBAL_MODALS.toggleGlobalModal(
        GLOBAL_MODALS_KEYS.isCybersecurityRoadBlock,
      ),
    )
  }
  const handleStartAssessment = () => {
    history.push(
      `${ViewTypes.zimm}/assessment/${textToPath(
        interstitials[0].elementCategory,
      )}`,
    )
    handleClose()
  }
  const handleCompleteAssessment = () => {
    history.push(
      `${ViewTypes.zimm}/assessment/${zimmNextQuestion?.elementPath}`,
    )
    handleClose()
  }

  const percentageCompleted = calculatePercentage(
    completedQuestions?.length || 0,
    allQuestionsConfig?.length,
  )
  React.useEffect(() => {
    if (!isZeguroServiceSession) {
      dispatch(
        updateCustomerPortalData({
          cybermaturity: { dontShowRoadBlock: true },
        }),
      )
    }
  }, [])
  return (
    <StyledModal
      isOpen={isOpen}
      onClose={handleClose}
      content={
        <div>
          <ButtonClose type="button" aria-label="Close" onClick={handleClose}>
            <FaIcon className="fal fa-times" />
          </ButtonClose>
          <ModalContent>
            <h1>
              <img
                alt="cyber maturity"
                height="40px"
                src="images/zimm/icon_cyber_maturity_lg.svg"
              />
              {companyName}&apos;s Cyber Maturity Level
            </h1>
            <h3>Analyze and strengthen your cybersecurity.</h3>
            {isEmptyState && (
              <NotStartedInfoBlock>
                <li>
                  Get a complete snapshot of your organization’s current
                  cybersecurity stance.
                </li>
                <li>
                  Discover actionable insights to fortify your organization
                  against bad actors.
                </li>
              </NotStartedInfoBlock>
            )}
            <EmptyProgressInfoBlock isEmptyState={isEmptyState}>
              {isEmptyState && <span>Security Areas</span>}
              <ul>
                <li>
                  <img alt="people" src="images/zimm/icon_people.svg" />
                  <span>People</span>
                  <FaIcon
                    data-tip
                    data-for="zimm-people-tooltip"
                    className="fas fa-info-circle"
                    color={colors.darkBlue}
                    cursor="pointer"
                    fontSize="16px"
                  />
                  <Tooltip
                    width="415px"
                    id="zimm-people-tooltip"
                    content={
                      <TooltipText>
                        Your team is the backbone of your organization, and a
                        prime target for cyber attacks. Arm them with the tools
                        they need as your first line of defense.
                      </TooltipText>
                    }
                  />
                </li>
                <li>
                  <img alt="process" src="images/zimm/icon_process.svg" />
                  <span>Process</span>
                  <FaIcon
                    data-tip
                    data-for="zimm-process-tooltip"
                    className="fas fa-info-circle"
                    color={colors.darkBlue}
                    cursor="pointer"
                    fontSize="16px"
                  />
                  <Tooltip
                    width="415px"
                    id="zimm-process-tooltip"
                    content={
                      <TooltipText>
                        Processes define how you address cyber threats. They can
                        reduce the chance of an attack. If an attack does occur,
                        they will guide your response.
                      </TooltipText>
                    }
                  />
                </li>
                <li>
                  <img alt="technology" src="images/zimm/icon_technology.svg" />
                  <span>Technology</span>
                  <FaIcon
                    data-tip
                    data-for="zimm-technology-tooltip"
                    className="fas fa-info-circle"
                    color={colors.darkBlue}
                    cursor="pointer"
                    fontSize="16px"
                  />
                  <Tooltip
                    width="415px"
                    id="zimm-technology-tooltip"
                    content={
                      <TooltipText>
                        To protect your organization, you need the right tools.
                        Implementing even basic protections can save your
                        organization significant time and money.
                      </TooltipText>
                    }
                  />
                </li>
              </ul>
            </EmptyProgressInfoBlock>
            {!isEmptyState && (
              <ProgressInfoBlock>
                <p>You’re {percentageCompleted}% done</p>
                <ProgressBar
                  intent="primary"
                  value={percentageCompleted / 100}
                  stripes={false}
                />
              </ProgressInfoBlock>
            )}
            <ButtonSmall
              margin="30 0 0 0"
              onClick={
                isEmptyState ? handleStartAssessment : handleCompleteAssessment
              }
            >
              {isEmptyState ? (
                "Start Assessment"
              ) : (
                <>
                  {isDesktop && (
                    <FaIcon
                      className="fas fas fa-money-check-edit"
                      fontSize="14px"
                    />
                  )}{" "}
                  Finish Assessment
                </>
              )}
            </ButtonSmall>
            <Footer>
              <Link onClick={handleClose}>Skip</Link>
            </Footer>
          </ModalContent>
        </div>
      }
      isCloseButtonShown
      canOutsideClickClose={false}
    />
  )
}
