import { media } from "@src/theme"
import * as _ from "lodash"
import styled from "styled-components"

export const P = (p: any) => (props: any) => {
  return props[p]
}

const do_pixels = (type: string, p: any) =>
  !p[type]
    ? ""
    : type +
      ": " +
      String(p[type])
        .split(" ")
        .map((e: string) => e + "px")
        .join(" ") +
      ";"

const do_size = (e: string[]) =>
  `width:${e[0]}px;height: ${e.length === 1 ? e[0] : e[1]}px;`

const do_flex = (e: string[]) => {
  return `display:flex; justify-content:${e[0]};align-items: ${e[1]}; ${
    e[2] ? "flex-direction: " + e[2] + ";" : ""
  }`
}

export const margin = do_pixels.bind(null, "margin")
export const padding = do_pixels.bind(null, "padding")
export const width = (p: any) =>
  !p.w ? "" : "width: " + p.w + (_.isNumber(p.w) ? "px;" : ";")
export const height = (p: any) =>
  !p.h ? "" : "height: " + p.h + (_.isNumber(p.h) ? "px;" : ";")
export const hidden = (p: any) => (!p.hidden ? "" : "display: none;")
export const lineThrough = (p: any) =>
  !p.lineThrough ? "" : "text-decoration: line-through;"
export const size = (p: any) => (!p.size ? "" : do_size(p.size.split(" ")))
export const align = (p: any) =>
  !p.align ? "" : "text-align: " + p.align + ";"
export const css = (p: any) => (!p.css ? "" : p.css)
export const display = (p: any) =>
  !p.display ? ";" : "display: " + p.display + ";"
export const flex = (p: any) => (!p.flex ? "" : do_flex(p.flex.split(" ")))
export const flexGrow = (p: any) =>
  !p.flexGrow ? ";" : "flex-grow: " + p.flexGrow + ";"
export const flexShrink = (p: any) =>
  !p.flexShrink ? ";" : "flex-shrink: " + p.flexShrink + ";"
export const flexBasis = (p: any) =>
  !p.flexBasis ? ";" : "flex-basis: " + p.flexBasis + ";"
export const flexWrap = (p: any) =>
  p.flexWrap ? "flex-wrap: " + p.flexWrap + ";" : ";"
export const fontWeight = (p: any) =>
  p.fontWeight ? "font-weight: " + p.fontWeight + ";" : ";"
export const fontSize = (p: any) =>
  p.fontSize ? "font-size: " + p.fontSize + ";" : ";"
export const fontColor = (p: any) =>
  p.fontColor ? "color: " + p.fontColor + ";" : ";"
export const fontFamily = (p: any) =>
  "font-family: " +
  (p.fontFamily ? p.fontFamily + ";" : "proxima-nova, sans-serif;")
export const lineHeight = (p: any) =>
  p.lineHeight ? "line-height: " + p.lineHeight + ";" : ";"
export const gap = (p: any) => (p.gap ? "gap: " + p.gap + ";" : ";")
export const mobile = () => {
  return `@media ${media.medium} {
   ${(p: any) => (!p.mobileFlex ? "" : do_flex(p.mobileFlex.split(" ")))}
  }`
}

export const Section = styled.div<any>`
  ${margin}
  ${padding}
  ${width}
  ${height}
  ${align}
  ${display}
  ${flex}
  ${flexGrow}
  ${flexShrink}
  ${flexBasis}
  ${mobile}
  ${flexWrap}
  ${fontWeight}
  ${fontSize}
  ${fontColor}
  ${fontFamily}
  ${lineHeight}
  ${hidden};
  ${lineThrough};
  ${gap};
  ${css}
`
