import { media, shadows } from "@src/theme"
import { colors } from "@src/theme"
import styled from "styled-components"
interface IInsMainImg {
  marginBottom?: boolean
}
interface ISubHeading {
  gridColumn?: number
  height?: string
  color?: string
  fontSize?: string
  textAlign?: string
  maxWidth?: string
}
interface IFeatureCard {
  color?: string
  noTopBorder?: boolean
  isEmpty?: boolean
  minHeight?: string
  gridColumn?: string
}

export const FeatureCard = styled.div<IFeatureCard>`
  max-height: 365px;
  min-height: ${({ minHeight }) => minHeight || "365px"};
  width: 100%;
  box-shadow: ${shadows.panel};
  padding: 15px 20px;
  border-radius: 6px;
  border: solid 1px ${({ color }) => color};
  border-top: solid ${({ noTopBorder }) => (noTopBorder ? "2px" : "10px")}
    ${({ color }) => color};
  padding-top: ${({ noTopBorder }) => (noTopBorder ? "28px" : "15px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  position: relative;
  @media ${media.medium} {
    width: 100%;
    margin-right: 0;
  }
  @media ${media.large} {
    grid-column: ${({ gridColumn }) => gridColumn || "span 4"};
  }
`

export const Heading = styled.div<any>`
  font-family: Montserrat;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: ${(props: any) => props.color || "#26249e"};
  margin-bottom: 40px;
  width: 100%;
  @media (max-width: 380px) {
    font-size: 23px;
  }
  @media (max-width: 330px) {
    font-size: 18px;
  }
`

export const DashboardCardHeading = styled.div<{
  marginBottom?: string
}>`
  font-family: montserrat;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  height: 35px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #26249e;
  margin-bottom: ${({ marginBottom }) => marginBottom || "40px"};
  width: 100%;
  @media (max-width: 380px) {
    font-size: 23px;
  }
  @media (max-width: 330px) {
    font-size: 18px;
  }
  @media ${media.large} {
    justify-content: center;
  }
  > label {
    margin-top: 22px;
  }
`

export const HeadingImg = styled.img<{ marginBottom?: string }>`
  max-height: 40px;
  margin-right: 10px;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`
export const InsMainImg = styled.img<IInsMainImg>`
  margin-bottom: ${({ marginBottom = true }) => marginBottom && "10px"};
`
export const FaIconHead = styled.i`
  font-size: 18px;
  opacity: 0.5;
  margin-left: 8px;
  cursor: pointer;
`

export const SubHeading = styled.div<ISubHeading>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize || "21px"};
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${({ color }) => color || "#212528"};
  height: ${({ height }) => height && height};
  margin-bottom: 15px;
  text-align: ${({ textAlign }) => textAlign || "left"};
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  @media (max-width: 330px) {
    margin-bottom: 10px;
    line-height: 20px;
    font-size: 19px;
  }
  @media ${media.large} {
    text-align: center;
  }
`

export const Description = styled.div<any>`
  max-height: 100px;
  min-height: 90px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #212528;
  width: 100%;
  @media ${media.medium} {
    font-size: 14px;
    line-height: 20px;
  }
  @media (max-width: 330px) {
    line-height: 20px;
  }
  @media ${media.large} {
    text-align: center;
  }
`

export const CTA = styled.div<{ bottom?: string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: ${({ bottom }) => bottom || "32px"};
  left: 0;
  width: 100%;
`
export const DashboardContainerStyled = styled.div`
  padding: 30px 32px 50px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 900px) {
    padding: 10px 10px 50px 10px;
    min-width: 100px;
  }
`

export const CardContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  max-width: 1270px;
  width: 100%;
  @media ${media.medium} {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
  @media ${media.large} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
`
export const SliderCardWrapper = styled.div`
  padding: 0 20px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
`

export const InfoMain = styled.div<IInfoName>`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  hr {
    height: ${({ hrHeight }) => hrHeight || "64px"};
    border: none;
    background-color: #d6d6d6;
    width: 1px;
    margin: 15px 10px 0 10px;
  }
`
interface IInfoName {
  width?: string
  hrHeight?: string
}

export const InfoName = styled.div<IInfoName>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ width }) => width || "100px"};
  text-align: center;
`

export const InfoNumber = styled.p`
  color: #26249e;
  font-size: 32px;
  font-weight: 800;
  margin: 0 0 5px 0;
  line-height: 1;
`
interface IHeadingBadge {
  content: string
}
export const HeadingBadge = styled.div<IHeadingBadge>`
  display: inline;
  ::after {
    font-size: 8px;
    border-radius: 3px;
    background: ${colors.violet};
    color: ${colors.white};
    content: "${({ content }) => content}";
    padding: 3px 6px;
    height: 16px;
    vertical-align: middle;
    margin-left: 10px;
  }
`

interface IWarnIcon {
  color?: string
}
export const WarnIcon = styled.i<IWarnIcon>`
  font-size: 16px;
  color: ${({ color }) => color || colors.error};
  margin-right: 8px;
`
export const LargeScreenRow = styled.div`
  grid-column: 1/-1;
  display: flex;
  justify-content: space-evenly;
  > div {
    width: 400px;
  }
`
