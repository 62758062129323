import { colors } from "@src/theme"
import styled, { css } from "styled-components"

interface IInput {
  isError?: boolean
  padding?: string
  align?: string
}
interface IInputWrapper {
  margin?: string
  activeField?: string
}

const padV = 11
const padH = 20
const labelSize = 15
const fontSize = 16
const lineHeight = 20
const borderWidth = 1

export const Input = styled.input<IInput>`
  font-size: ${fontSize}px;
  line-height: ${lineHeight}px;
  width: 100%;
  padding: ${({ padding }) =>
    padding ? padding : `${padV + 2}px ${padH}px ${padV}px`};
  border-radius: 3px;
  border: ${({ isError }) =>
    isError ? `1px solid ${colors.red}` : `1px solid ${colors.gray30}`};
  text-align: ${({ align }) => align};
  &:focus {
    border: ${({ isError }) =>
      isError ? `1px solid ${colors.red}` : `1px solid ${colors.gray30}`};
    outline: none;
  }
  background-color: transparent;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  :[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }
`
export const InputWrapper = styled.div<IInputWrapper>`
  margin: ${({ margin }) => margin};
  color: ${({ activeField }) =>
    !activeField ? colors.gray42 : colors.black50};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
`
export const ErrorMessage = styled.span`
  color: ${colors.red};
  margin-top: 3px;
  min-width: 200px;
  white-space: nowrap;
`
export const Label = styled.label<any>`
  font-family: proxima-nova, sans-serif;
  color: inherit;
  font-size: ${fontSize}px;
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.06, 0.67, 0.32, 0.82);
  transform: translate(${padH - 8}px, ${labelSize + borderWidth + padV + 5}px);
  background-color: white;
  padding: 0 10px;
  line-height: 1;
  border-radius: 5px;
  z-index: 1;
  ${(props: any) =>
    props.up &&
    css`
      transform-origin: left;
      transform: scale(0.8125) translate(${padH - 2}px, ${padV - borderWidth}px);
      color: ${props.error ? colors.red : "#26249e"};
    `}
`
export const LockIcon = styled.i`
  color: ${colors.gray40};
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 14px;
`
