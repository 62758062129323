import { tState } from "@src/Model/Model"
import { CyberMaturityCatalog as ICyberMaturityCatalog } from "@zeguro/schema-validator/dist/types/coco/cyberMaturity/combined"
import * as React from "react"
import { useSelector } from "react-redux"

import ScoreBoard from "./ScoreBoard"

export type tElement = ICyberMaturityCatalog["elements"][number]

const Report = () => {
  const config = useSelector((store: tState) => store.zimm.getConfig.elements)
  const assessmentData = useSelector(
    (store: tState) => store.zimm.getAssessmentData,
  )

  const categories = config
    .map(({ elementCategory, elementSubCategory }) => ({
      elementCategory,
      elementSubCategory,
    }))
    .filter(
      (element, index, arr) =>
        arr.findIndex(
          (el) =>
            el.elementCategory === element.elementCategory &&
            el.elementSubCategory === element.elementSubCategory,
        ) === index,
    )

  return (
    <ScoreBoard
      config={config}
      categories={categories}
      assessmentData={assessmentData}
    />
  )
}
export default Report
