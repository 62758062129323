import styled from "styled-components"

export const Body = styled.div`
  width: 640px;
  font-family: proxima-nova, sans-serif;
  text-align: left;
  @media (max-width: 674px) {
    width: 95vw;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 177px;
  width: 640px;
  background-image: linear-gradient(to bottom, #6be9ff, #39ceff);
  @media (max-width: 674px) {
    width: 95vw;
  }
`

export const HeaderImg = styled.img<{ imageHeight?: string }>`
  height: ${({ imageHeight }) => (imageHeight ? imageHeight : "110px")};
  max-width: 90%;
`

export const Container = styled.div`
  display: flex;
  justify-content: left;
  padding: 36px 40px 6px;
  flex-direction: column;
  @media (max-width: 674px) {
    width: 95vw;
  }
`

export const Title = styled.div`
  display: flex;
  font-size: 32px;
  font-weight: bold;
  text-align: left;
  color: #212294;
  font-family: montserrat;
`

export const Text = styled.div`
  display: flex;
  font-size: 16px;
  text-align: left;
  color: #212528;
  margin-top: 13px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  font-size: 16px;
  flex-direction: column;
`

export const Counter = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => (hidden ? "none" : "inline-block")};
  margin-left: 22px;
  margin-right: 22px;
  @media (max-width: 330px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`
