import styled from "styled-components"

export const SliderContainer = styled.div<any>`
  width: 100%;
  .slick-prev,
  .slick-next {
    z-index: 1;
    opacity: 0;
    transition: 0.5s all;
  }
  .slick-prev {
    height: 63px;
    width: 63px;
    transform: translate(50%, -50%);
  }
  .slick-next {
    height: 63px;
    width: 63px;
    transform: translate(-50%, -50%);
  }
  .slick-prev:before,
  .slick-next:before {
    color: rgba(33, 37, 40, 0.5);
    font-size: 63px;
  }
  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
    }
  }
  .slick-dots {
    bottom: -30px;
    li {
      button {
        &:before {
          font-size: 10px;
        }
      }
    }
  }
`
