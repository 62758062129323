import * as ACTION from "@src/Actions/ActionTypes"
import { ISecurityPolicy, tComplianceState } from "@src/Model/Model"
import initial_state from "@src/Model/Model"
import * as T from "@src/types"
import { PolicyListItem } from "@zeguro/schema-validator/dist/types/coco/securityPolicy/combined"
import * as _ from "lodash"

interface tAction {
  type: string
  payload: any
}

function reducer(state: tComplianceState, action: tAction) {
  if (!state) {
    state = initial_state.policy
  }

  switch (action.type) {
    case ACTION.COMPLIANCE_REQ_STATUS: {
      const status: T.IRequestStatus = action.payload.status
      return { ...state, request: status }
    }

    case ACTION.UPDATE_POLICY: {
      const policy: ISecurityPolicy = action.payload
      return {
        ...state,
        entry: policy,
        initialCustomValues: policy ? _.cloneDeep(policy.customValues) : {},
      }
    }

    case ACTION.UPDATE_POLICY_TEXT: {
      const customText: string = action.payload
      return { ...state, policyText: customText }
    }

    case ACTION.SELECT_MENU: {
      const item: string = action.payload
      return { ...state, selectedMenuItem: item }
    }

    case ACTION.CHANGE_POLICY_MODE: {
      const item: string = action.payload
      return { ...state, policyMode: item, draftMode: "customize" }
    }

    case ACTION.CHANGE_DRAFT_MODE: {
      const item: string = action.payload
      return { ...state, draftMode: item }
    }

    case ACTION.SHOW_CUSTOM_DIALOG: {
      const item: string = action.payload
      return { ...state, customDialog: item }
    }

    case ACTION.UPDATE_CUSTOM_VALUES: {
      const customValues = action.payload
      const entry = { ...state.entry, customValues }
      return { ...state, customDialog: null, entry }
    }

    case ACTION.CANCEL_CUSTOM_FORM: {
      return { ...state, customDialog: null }
    }

    case ACTION.NOTIFY_APPROVER: {
      return { ...state, draftMode: "sent" }
    }

    case ACTION.OPEN_POLICY_HISTORY: {
      const p: {
        historyOpen: boolean
        policyType: string
        policyName: string
        data: { approver: string; version: number }[]
      } = action.payload
      return {
        ...state,
        historyOpen: p.historyOpen,
        historyList: p.historyOpen ? p.data : null,
        historyPolicyType: p.historyOpen ? p.policyType : "",
        historyPolicyName: p.historyOpen ? p.policyName : "",
      }
    }

    case ACTION.UPDATE_POLICY_LIST: {
      const p: PolicyListItem[] = action.payload
      return { ...state, list: p }
    }

    case ACTION.UPDATE_OUT_OF_BOX_LIST: {
      const p: string[][] = action.payload
      return { ...state, outOfBoxList: p }
    }

    case ACTION.UPDATE_STORAGE_LIST: {
      const p: string[] = action.payload
      return { ...state, storageList: p }
    }

    default:
      return state
  }
}

export default reducer
