import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  initialSubscriptionIdState,
  tSubscriptionIdState,
} from "@src/Model/Model"

export const subscriptionIdSlice = createSlice({
  name: "counter",
  initialState: initialSubscriptionIdState,
  reducers: {
    updateSubscriptionId: (
      state,
      action: PayloadAction<tSubscriptionIdState>,
    ) => action.payload,
  },
})

export const actions = subscriptionIdSlice.actions

export default subscriptionIdSlice.reducer
