import * as moment from "moment"
import * as Yup from "yup"

type editScheduleValidation = Yup.AnyObjectSchema

export const useEditScheduleValidation = (): editScheduleValidation => {
  return Yup.object({
    scanLevel: Yup.string().required().default(""),
    scheduleDate: Yup.string()
      .required("Please enter a date.")
      .default("")
      .test("dateIsValid", "Date is not valid.", (value) => {
        return !moment(value)
          .startOf("day")
          .isBefore(moment(new Date()).startOf("day"))
      }),
    dayOfMonth: Yup.number()
      .min(1, "Not a valid day of the month.")
      .max(31, "Not a valid day of the month."),
  })
}
