import { ButtonCancel, ButtonSmall } from "@src/Components/Button"
import TextField from "@src/Components/Form/TextField"
import { tState } from "@src/Model/Model"
import { useEditWebAppFormValidation } from "@src/Portal/Monitoring/EditAppForm/validation"
import { SpinnerStyled } from "@src/Portal/Settings/styles"
import * as T from "@src/types"
import { ProbelyEditTargetRequest } from "@zeguro/schema-validator/dist/types/coco/probely/combined"
import { useFormik } from "formik"
import * as React from "react"
import { useSelector } from "react-redux"

import { ButtonContainer, Label, Row } from "./styles"

type tEditTarget = Omit<ProbelyEditTargetRequest, "orgId">

interface IEditAppForm {
  onClose?: () => void
  editTarget: (values: tEditTarget) => void
  appName: string
  targetId: string
  targetMap: T.IProbelyTarget[]
}
interface IFormFields {
  appName: string
}
const EditAppForm = ({
  onClose,
  editTarget,
  appName,
  targetId,
  targetMap,
}: IEditAppForm) => {
  const isLoading = useSelector(
    (state: tState) => state.monitoring.editTarget.isLoading,
  )

  const onSubmit = () => {
    editTarget({
      zeguroTargetId: targetId,
      appName: formik.values.appName,
    })
  }

  const validationSchema = useEditWebAppFormValidation({
    targetMap,
  })

  const formik = useFormik<IFormFields>({
    initialValues: { appName: appName || "" },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="bp5-dialog-body">
        <Row>
          <Label>Website Name</Label>
          <TextField
            name="appName"
            placeholder="Website Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.appName}
            error={Boolean(formik.errors.appName && formik.touched.appName)}
            errorMsg={formik.errors.appName}
          />
        </Row>
      </div>
      <ButtonContainer className="bp5-dialog-footer">
        <ButtonCancel type="button" width={100} onClick={onClose}>
          Cancel
        </ButtonCancel>
        &nbsp;
        <ButtonSmall
          width={100}
          onClick={onSubmit}
          disabled={!(formik.isValid && formik.dirty)}
        >
          {isLoading ? <SpinnerStyled size={25} /> : "Save"}
        </ButtonSmall>
      </ButtonContainer>
    </form>
  )
}

export default EditAppForm
