import { colors } from "@src/theme"
import styled from "styled-components"

interface IPausedProps {
  paused: boolean
}

interface IBadge {
  color: string
}

export const Content = styled.span<IPausedProps>`
  padding: 8px 0;
  display: flex;
  // flex-direction: column;
  align-items: center;
  opacity: ${({ paused }) => (paused ? 0.5 : 1)};
  pointer-events: ${({ paused }) => paused && "none"};
`

export const ConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const Badge = styled.div<IBadge>`
  color: ${colors.white};
  background-color: ${({ color }) => color};
  font-size: 13px;
  font-weight: 600;
  padding: 0 10px;
  line-height: 24px;
  border-radius: 3px;
  margin-right: 8px;
`

export const FaIcon = styled.i<{ color?: string }>`
  color: ${({ color }) => color};
`

export const ConfirmText = styled.div`
  font-size: 16px;
  margin: 5px 0;
`

export const ConfirmTextBold = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
`

export const ConfirmTextGray = styled.span`
  font-size: 16px;
  color: ${colors.gray40};
`

export const WarnIcon = styled.i`
  font-size: 16px;
  color: ${colors.error};
  margin-right: 5px;
`

export const InfoIcon = styled.i`
  font-size: 16px;
  color: ${colors.success};
  margin-right: 5px;
`

export const CheckWrap = styled.div`
  display: flex;
  align-items: center;
`
export const Circle = styled.i`
  color: ${colors.success};
  margin-right: 10px;
  margin-left: 3px;
`
export const CheckButton = styled.div`
  background: ${colors.green30};
  font-size: 15px;
  border: none;
  padding: 2px 5px;
  color: ${colors.white};
  border-radius: 3px;
`
