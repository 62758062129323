import { Failed, Waiting } from "@src/Components/Waiting"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { tDictAny, ViewTypes } from "@src/types"
import { MarketplacePartner as IMarketplacePartner } from "@zeguro/schema-validator/dist/types/coco/marketplace/combined"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router"

import Marketplace from "./Marketplace"
import PartnerPage from "./PartnerPage"

interface IMarketplaceNavParams {
  partner: string
}

const MarketplaceContainer = () => {
  const history = useHistory()
  const match = useRouteMatch<IMarketplaceNavParams>()
  const [currentPartner, setCurrentPartner] = React.useState(null)

  const partnerParam = match.params.partner

  const subscriptionStatus: "subscribed" | "nonSubscribed" = useSelector(
    (store: tState) => {
      return store.plans.subscriptionStatus === "INTRIAL"
        ? "nonSubscribed"
        : "subscribed"
    },
  )

  const { data: marketplaceData, status } = useSelector(
    (store: tState) => store.marketplace,
  )
  const partners = marketplaceData.reduce(
    (a: IMarketplacePartner[], p: tDictAny) => {
      return [...a, ...p.partners]
    },
    [],
  )

  React.useEffect(() => {
    if (partnerParam && marketplaceData.length) {
      const partner = partners.find(
        (p: IMarketplacePartner) => p.name === partnerParam,
      )
      if (partner) {
        setCurrentPartner(partner)
      } else {
        history.push("/404")
      }
    } else {
      setCurrentPartner(null)
    }
  }, [partnerParam, marketplaceData.length])

  React.useEffect(() => {
    C.Marketplace.fetchMarketingJson()
  }, [])

  if (status === "LOADING" || status === "READY") {
    return <Waiting text="Loading Marketplace." />
  } else if (status === "FAILED") {
    return (
      <Failed text="Failed to load Marketplace. Please try after some time." />
    )
  } else if (partners.length < 1) {
    return <Failed text="No partners available." />
  } else if (currentPartner) {
    return (
      <PartnerPage
        onSubscribe={() => {
          history.push(ViewTypes.plan)
        }}
        subscriptionStatus={subscriptionStatus}
        partnerData={currentPartner}
        goToMarketplace={() => history.push(ViewTypes.marketplace)}
      />
    )
  } else {
    return (
      <Marketplace
        subscriptionStatus={subscriptionStatus}
        marketingData={marketplaceData}
      />
    )
  }
}

export default MarketplaceContainer
