import { PageLoadFailure } from "@src/Components/PageLoadFailure"
import { serverLog } from "@src/ControllerModules/Logging"
import * as React from "react"

export class ErrorBoundary extends React.Component<object, any> {
  public state = { hasError: false, message: "" }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    serverLog({
      level: "error",
      message: "Unhandled front-end rendering exception",
      stack: { stack: error.stack },
    })
    this.setState({ hasError: true, message: error.stack })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <PageLoadFailure />
    }

    return this.props.children
  }
}
