import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { tState } from "@src/Model/Model"
import * as T from "@src/types"
import { ProbelyCreateTargetRequest } from "@zeguro/schema-validator/dist/types/coco/probely/combined"
import { useFormik } from "formik"
import * as moment from "moment"
import * as React from "react"
import { useSelector } from "react-redux"

import AddAppStepOne from "./AddAppStepOne"
import AddAppStepThree from "./AddAppStepThree"
import AddAppStepTwo from "./AddAppStepTwo"
import { useAddWebAppValidation } from "./validation"

type IAddWebApp = Omit<ProbelyCreateTargetRequest, "orgId">
interface IAddWebAppFormForm {
  scanLevels: string[][]
  targetMap: T.IProbelyTarget[]
  grayBackground?: boolean
  addWebApp: (values: IAddWebApp) => void
  onClose: () => void
  setGrayModalBackground: (value: boolean) => void
}

const protocols: string[][] = [
  ["HTTPS", "https://"],
  ["HTTP", "http://"],
]

export const getUrl = (protocol: string, host: string) =>
  `${protocol}${host.replace(new RegExp("^https?://"), "")}`

export interface IAddWebAppFormFields {
  protocol: string
  webAppName: string
  webAppUrl: string
  urlConfirmed: boolean
  scanLevel: string
  scheduleDate: Date
  scheduleTime: Date
  recurrence: string
  dayOfMonth: number
  scheduledDayOfWeek: number | null
  weekIndex: string | null
  runOnDayOfWeek: boolean
}

const firstScanDate = moment(new Date())
  .add(1, "days")
  .set("hour", 1)
  .set("minute", 0)
  .set("second", 0)
  .toDate()

const formInitial: IAddWebAppFormFields = {
  protocol: "",
  webAppName: "",
  webAppUrl: "",
  urlConfirmed: false,
  scanLevel: "",
  scheduleDate: firstScanDate,
  scheduleTime: firstScanDate,
  recurrence: "",
  dayOfMonth: firstScanDate.getDate(),
  scheduledDayOfWeek: 1,
  weekIndex: "first",
  runOnDayOfWeek: false,
}
const AddWebAppForm = ({
  scanLevels,
  targetMap,
  grayBackground,
  addWebApp,
  onClose,
  setGrayModalBackground,
}: IAddWebAppFormForm) => {
  const { isDesktop } = usePortalSize()
  const [page, setPage] = React.useState(1)

  const isLoadingCreateTarget = useSelector(
    (state: tState) => state.monitoring.createTarget.isLoading,
  )
  const isLoadingScheduleScan = useSelector(
    (state: tState) => state.monitoring.createScheduleScan.isLoading,
  )

  const addWebAppSchema = useAddWebAppValidation({ targetMap })

  React.useEffect(() => {
    setGrayModalBackground(isDesktop && page === 3)
  }, [isDesktop, page])

  const onSubmit = () => {
    const {
      protocol,
      webAppUrl,
      scheduleDate,
      scheduleTime,
      scanLevel,
      webAppName,
      recurrence,
      runOnDayOfWeek,
      scheduledDayOfWeek,
      weekIndex,
    } = formik.values
    const url = getUrl(protocol, webAppUrl)
    const currentDate: Date = scheduleDate
    currentDate.setHours(scheduleTime.getHours())
    currentDate.setMinutes(scheduleTime.getMinutes())
    const partOfPayload = {
      scan_profile: scanLevel,
      targetName: webAppName,
      targetUrl: url,
      recurrence: recurrence,
      date_time: currentDate.toISOString().split(".")[0] + "Z",
    }
    const payload =
      runOnDayOfWeek && ["q", "m"].includes(recurrence)
        ? {
            ...partOfPayload,
            run_on_day_of_week: runOnDayOfWeek,
            scheduled_day_of_week: scheduledDayOfWeek,
            week_index: weekIndex,
          }
        : partOfPayload

    addWebApp(payload)
  }

  const formik = useFormik<IAddWebAppFormFields>({
    initialValues: formInitial,
    enableReinitialize: true,
    validationSchema: addWebAppSchema,
    onSubmit: onSubmit,
  })

  const onNext = () => {
    setPage(2)
  }

  const onBack = () => {
    let currentPage = page
    if (currentPage > 0) {
      setPage(--currentPage)
    }
  }

  const onAgreeTerms = () => {
    setPage(3)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {page === 1 ? (
        <AddAppStepOne
          formik={formik}
          protocols={protocols}
          onNext={onNext}
          onClose={onClose}
        />
      ) : page === 2 ? (
        <AddAppStepTwo
          formik={formik}
          onBack={onBack}
          onAgreeTerms={onAgreeTerms}
        />
      ) : page === 3 ? (
        <AddAppStepThree
          formik={formik}
          scanLevels={scanLevels}
          grayBackground={grayBackground}
          onBack={onBack}
          isAddNew
          isLoading={isLoadingCreateTarget || isLoadingScheduleScan}
        />
      ) : null}
    </form>
  )
}

export default AddWebAppForm
