import { colors } from "@src/theme"
import styled from "styled-components"

interface IBadge {
  color: string
  margin?: string
}

enum SeverityColors {
  "CRITICAL" = "#9A002A",
  "HIGH" = "#CD3C15",
  "MEDIUM" = "#FF7800",
  "LOW" = "#8D7B18",
  "INFO" = "#1C7F30",
}

export const WarningSection = styled.div`
  padding: 16px 20px;
  border-radius: 6px;
  background: #ffeced;
`

export const AlertsContainer = styled.div`
  max-width: 100%;
`

export const Alert = styled.div`
  width: 100%;
  font-size: 18px;
  padding: 30px 0 10px;
  line-height: 24px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.gray5};
  }
`
export const AlertsTable = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 80px 1fr;
  > div:first-child {
    margin: 5px 8px 5px 0;
  }
`

export const Badge = styled.div<IBadge>`
  color: ${colors.white};
  background-color: ${({ color }) =>
    SeverityColors[color.toUpperCase() as keyof typeof SeverityColors]};
  font-size: 13px;
  font-weight: 600;
  padding: 0 8px;
  line-height: 24px;
  border-radius: 3px;
  width: 62px;
  text-align: center;
  margin: ${({ margin }) => margin};
`
