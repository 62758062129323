import { colors, media } from "@src/theme"
import styled from "styled-components"

const boxShadow = "4px 4px 8px -2px rgba(0, 0, 0, 0.1)"
const borderColor = "#C4C4C4"

interface ICardContainer {
  isLock: boolean
}

export const CardContainer = styled.div<ICardContainer>`
  display: flex;
  border: 1px solid ${borderColor};
  box-shadow: ${boxShadow};
  padding: 20px 30px;
  border-radius: 3px;
  flex-direction: column;
  width: 100%;
  background: ${({ isLock }) => isLock && colors.gray3};
  position: relative;
  min-height: 270px;

  // @media (max-width: 1000px) {
  //   padding: 20px 10px;
  // }

  @media ${media.medium} {
    border-color: rgba(38, 36, 158, 0.2);
    box-shadow: none;
    padding: 16px;
    min-height: 100px;
  }
`

export const CardDescription = styled.p`
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${colors.black50};
  margin: 0;
  margin-top: 10px;
`
export const CardTitle = styled.h3`
  font-weight: 700;
  font-size: 18px;
  color: ${colors.darkBlue};
  margin: 0;
`

export const HeadWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
`

export const NameWrap = styled.div``

export const HeadSubject = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${colors.darkBlue};
`

export const BodyWrap = styled.div`
  text-align: left;
`

export const Image = styled.img`
  height: 44px;
  margin-right: 10px;
`

export const LockIcon = styled.i<ICardContainer>`
  color: ${colors.gray40};
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  visibility: ${({ isLock }) => !isLock && "hidden"};
`
