import * as T from "@src/types"

import * as ACTION from "./ActionTypes"

export const setRatingsData = (ratingsData: T.tDictAny) => ({
  type: ACTION.SET_RATINGS_DATA,
  payload: ratingsData,
})

export const setRatingsStatus = (status: string) => ({
  type: ACTION.SET_RATINGS_STATUS,
  payload: status,
})
