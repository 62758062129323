import { Spinner } from "@blueprintjs/core"
import Dialog from "@src/Components/Dialog"
import { FaIcon } from "@src/Components/FaIcon"
import InfoBox from "@src/Components/InfoBox"
import { Link } from "@src/Components/Link"
import { PageLoadFailure } from "@src/Components/PageLoadFailure"
import { Section } from "@src/Components/StyledUtils"
import View from "@src/Components/View"
import Waiting from "@src/Components/Waiting"
import C from "@src/Controller"
import { tBrandState } from "@src/Model/Model"
import { colors, media } from "@src/theme"
import {
  IEligiblePlan,
  tChannelUiRules,
  tStatus,
  tSubscriptionStatus,
} from "@src/types"
import * as moment from "moment"
import * as React from "react"
import styled from "styled-components"

import PlanCard from "./PlanCard"

interface ISubscription {
  planName: string
  subscriptionStatus: tSubscriptionStatus
  subscriptionDate: string
  expirationDate: string
  onSubscribe: (
    chargebeePlanId: string,
    planId: string,
    couponCode?: string,
  ) => void
  eligiblePlans: IEligiblePlan[]
  menuHidden: boolean
  uiRules?: tChannelUiRules
  onPlanUpdate: (planId: string) => void
  updatePlansStatus: tStatus
  currPlanIndex: number
  eligiblePlansStatus: tStatus
  hasInsurance: boolean
  chargebeeSubscriptionStatus: boolean
  brand: tBrandState
}

interface ISubscriptionState {
  updatePlanModalOpen: boolean
  upgradePlanId: string
  upgradePlanName: string
  updatePlanModalError: string | JSX.Element
  upgradePlanPrice: number
  upgradePlanDiscountedPrice?: number
  upgradePlanDiscountMessage?: string
}

class Subscription extends React.Component<ISubscription, ISubscriptionState> {
  public readonly state: ISubscriptionState = {
    updatePlanModalOpen: false,
    upgradePlanId: "",
    upgradePlanName: "",
    updatePlanModalError: "",
    upgradePlanPrice: 0,
  }

  getPlanActionType = (index: number) => {
    const { subscriptionStatus, currPlanIndex } = this.props
    let planActionType: "subscribe" | "current" | "downgrade" | "upgrade" =
      "downgrade"
    if (subscriptionStatus === "INTRIAL") {
      planActionType = "subscribe"
    } else if (currPlanIndex === index) {
      planActionType = "current"
    } else if (currPlanIndex < index) {
      planActionType = "upgrade"
    }
    return planActionType
  }

  onClickUpgrade = (
    planId: string,
    planName: string,
    price: number,
    chargebeePlanId?: string,
    couponCode?: string,
    discountedPrice?: number,
    planDiscountMessage?: string,
  ) => {
    if (chargebeePlanId && !this.props.chargebeeSubscriptionStatus) {
      this.props.onSubscribe(chargebeePlanId, planId, couponCode)
    } else {
      this.setState({
        updatePlanModalOpen: true,
        upgradePlanId: planId,
        upgradePlanPrice: price,
        upgradePlanDiscountedPrice: discountedPrice,
        upgradePlanDiscountMessage: planDiscountMessage,
        upgradePlanName: planName,
      })
    }
  }

  getUpdatePlanModal = () => {
    return (
      <Dialog
        title="Manage Plan"
        text={
          <span>
            Are you sure you want to upgrade to the {this.state.upgradePlanName}{" "}
            Plan?
            <br />
            {this.state.upgradePlanDiscountedPrice ? (
              <span
                style={{ textDecoration: "line-through", color: "#6f7379" }}
              >
                ${this.state.upgradePlanPrice}
              </span>
            ) : null}
            $
            {this.state.upgradePlanDiscountedPrice ||
              this.state.upgradePlanPrice}
            /mo + taxes
            {this.state.upgradePlanDiscountedPrice ? (
              <span style={{ color: "#6f7379" }}>
                {" "}
                {this.state.upgradePlanDiscountMessage ? (
                  <span>
                    <i
                      className="fas fa-tag"
                      style={{
                        color: "#87BC2F",
                        paddingLeft: "8px",
                      }}
                    ></i>
                    {this.state.upgradePlanDiscountMessage}
                  </span>
                ) : null}
              </span>
            ) : null}
            .
          </span>
        }
        actionText="Upgrade"
        isOpen={this.state.updatePlanModalOpen}
        onClose={() =>
          this.setState({
            updatePlanModalOpen: false,
            upgradePlanId: "",
            updatePlanModalError: "",
          })
        }
        error={this.state.updatePlanModalError}
        onAction={() => {
          this.props.onPlanUpdate(this.state.upgradePlanId)
        }}
        content={
          this.props.updatePlansStatus === "LOADING" ? (
            <div style={{ padding: "10px" }}>
              <Spinner size={50} />
            </div>
          ) : undefined
        }
      />
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps: ISubscription) {
    if (this.props.updatePlansStatus === "LOADING") {
      if (nextProps.updatePlansStatus === "SUCCESS") {
        C.toastNotification(
          `You have successfully upgraded to ${this.state.upgradePlanName} .`,
          "success",
        )
        this.setState({
          updatePlanModalOpen: false,
          upgradePlanId: "",
          upgradePlanName: "",
          updatePlanModalError: "",
          upgradePlanPrice: 0,
        })
      }
      if (nextProps.updatePlansStatus === "FAILED") {
        this.setState({
          updatePlanModalError: (
            <span>
              Unable to upgrade your plan. Please contact us at{" "}
              <a
                style={{ color: "#0062d9" }}
                href={`mailto:support@${this.props.brand.emailDomain}`}
                target="_blank"
                rel="noreferrer"
              >
                support@{this.props.brand.emailDomain}
              </a>
              .
            </span>
          ),
        })
      }
    }
  }

  render() {
    const isSubscribed = this.props.subscriptionStatus === "ACTIVE"
    const expirationDate = this.props.expirationDate
    const trialDaysLeft = moment(expirationDate)
      .startOf("day")
      .diff(moment().startOf("day"), "days")
    const numOfCards = this.props.eligiblePlans.filter(
      (e) => e.showToCustomer,
    ).length

    return (
      <View alignItems={this.props.menuHidden ? "center" : undefined}>
        {this.props.eligiblePlansStatus === "FAILED" ? (
          <PageLoadFailure />
        ) : this.props.eligiblePlansStatus === "LOADING" ? (
          <Waiting text="Loading your plan." />
        ) : (
          <React.Fragment>
            {this.getUpdatePlanModal()}
            <Row>
              <Section flex="start start" id="dashboard-heading">
                <img height="60px" src="images/icon_my_plan.svg" />
                <Section padding="0 0 0 20">
                  <Heading id="dashboard-heading" align="left">
                    My Cyber Safety Plan
                  </Heading>
                </Section>
              </Section>
            </Row>
            {
              <span>
                <Row>
                  {!isSubscribed && trialDaysLeft > 0 ? (
                    <span>
                      <InfoBox
                        message={`Your free trial ends in ${trialDaysLeft} day${
                          trialDaysLeft > 1 ? "s" : ""
                        }.`}
                      />
                    </span>
                  ) : null}
                </Row>
                <Row>
                  <Subheading>
                    Choose a plan that suits your needs.
                    <br />
                    {this.props.uiRules?.channelDiscountTitle ? (
                      <span>
                        {this.props.uiRules?.channelDiscountTitle}
                        <sup>*</sup>
                      </span>
                    ) : null}
                  </Subheading>
                </Row>
              </span>
            }
            <Section flex="center" padding="10 0">
              <CardPanel numOfCards={numOfCards}>
                {this.props.eligiblePlans.map((eligiblePlan, index) => {
                  return eligiblePlan.showToCustomer ? (
                    <PlanCard
                      key={index}
                      name={eligiblePlan.planDetail?.planName}
                      numOfCards={numOfCards}
                      teamMembers={
                        eligiblePlan.planDetail?.trainingNumberOfSeats
                      }
                      price={
                        eligiblePlan.planPrice
                          ? eligiblePlan.planPrice / 100
                          : 0
                      }
                      numWebApps={
                        eligiblePlan.planDetail?.probelyNumberOfTargets
                      }
                      scanFrequency={
                        eligiblePlan.planDetail?.probelyScanFrequency
                      }
                      planColors={eligiblePlan?.planColors}
                      chargebeePlanId={eligiblePlan.chargebeePlanId}
                      chargebeeCoupon={
                        eligiblePlan.chargebeeCoupons &&
                        eligiblePlan.chargebeeCoupons[0]
                      }
                      planId={eligiblePlan.planPointer?.appId.planId}
                      planActionType={this.getPlanActionType(index)}
                      isSubscribed={isSubscribed}
                      onSubscribe={this.props.onSubscribe}
                      onClickUpgrade={this.onClickUpgrade}
                      monitoring={eligiblePlan.planDetail?.probely === "ACTIVE"}
                      training={eligiblePlan.planDetail?.training === "ACTIVE"}
                      securityPolicies={
                        eligiblePlan.planDetail?.securityPolicies === "ACTIVE"
                      }
                      cybermaturity={
                        eligiblePlan.planDetail?.cybermaturity === "ACTIVE"
                      }
                      planDiscountMessage={eligiblePlan.planDiscountMessage}
                    />
                  ) : null
                })}
              </CardPanel>
            </Section>
            <Section flexGrow="1" />
            <ContactUs>
              <div>
                {this.props.uiRules?.channelDiscountDescription ? (
                  <ChannelDiscountDescriptionText>
                    <sup>*</sup>
                    {this.props.uiRules?.channelDiscountDescription}
                  </ChannelDiscountDescriptionText>
                ) : null}
                <PlusTaxesText>
                  <sup>+</sup>
                  plus taxes
                </PlusTaxesText>
              </div>
              <ContactUsLinkContainer>
                <span>
                  <FaIcon
                    className="fas fa-question-circle"
                    color={colors.blue5}
                    fontSize="16px"
                    padding="0 6px 0 0"
                  />
                  {window.innerWidth > 1050 ? (
                    "Need additional resources, or other assistance? Contact us at"
                  ) : (
                    <>Need assistance?&nbsp;</>
                  )}
                </span>
                <span>
                  {window.innerWidth < 1050 && <>Contact us at&nbsp;</>}
                  <Link
                    href={`mailto:support@${this.props.brand.emailDomain}`}
                    isUnderline
                    margin="0 0 0 3px"
                  >
                    support@{this.props.brand.emailDomain}
                  </Link>
                  .
                </span>
              </ContactUsLinkContainer>
            </ContactUs>
          </React.Fragment>
        )}
      </View>
    )
  }
}

const CardPanel = styled.div<any>`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`
const ContactUsLinkContainer = styled(Section)`
  justify-content: center;
  align-items: center;
  display: flex;
  @media (min-width: 900px) {
    background: rgba(0, 98, 216, 0.05);
    border: 1px solid rgba(0, 98, 216, 0.5);
    height: 65px;
    margin-bottom: 10px;
  }
  @media (max-width: 900px) {
    border-top: 1px solid ${colors.gray5};
    padding-top: 20px;
    margin-top: 18px;
    flex-direction: column;
    align-items: baseline;
  }
`
const ChannelDiscountDescriptionText = styled.span`
  color: #8c8c8c;
  line-height: 30px;
  margin-right: 20px;
`
const PlusTaxesText = styled.span`
  color: #8c8c8c;
  line-height: 30px;
`
const Heading = styled.div<any>`
  font-family: montserrat;
  font-size: 36px;
  font-weight: 600;
  text-align: ${(props: any) => props.align || "center"};
  color: #212294;
  margin-bottom: 3px;
  @media (max-width: 500px) {
    font-size: 24px;
  }
`

const Subheading = styled.div`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: #4c545a;
  font-family: montserrat;
  @media (max-width: 500px) {
    font-size: 24px;
  }
`

const Row = styled.div`
  padding: 10px 0px;
  @media ${media.medium} {
    #dashboard-heading {
      align-items: center;
    }
  }
`

const ContactUs = styled.div`
  padding: 10px 0;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  @media (max-width: 900px) {
    flex-direction: column;
    text-align: left;
  }
`

export default Subscription
