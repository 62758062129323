import DataTable from "@src/Components/DataTable"
import { Section } from "@src/Components/StyledUtils"
import { scoreToGrade } from "@src/Portal/Training/utils"
import { colors } from "@src/theme"
import { ITopicCourseResults, TCollapse } from "@src/types"
import {
  CourseResults as ICourseResults,
  TraineeData as ITrainee,
} from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"

import { GRADE_COLOR } from "../../constants"
import CustomNameHead from "../CustomNameHead"
import {
  Badge,
  conditionalRowStyles,
  customStyles,
  Grade,
  GradeText,
  NameStyled,
  TableWrapper,
  TeamAveragePanel,
} from "./styles"

interface ITraineeCard {
  trainee: ITrainee
  currentCourse: ICourseResults
  collapse: TCollapse
  courseResults: ICourseResults[]
}

interface ITopic {
  name: string
  grade: string
  enrollDate?: string
  gapGrade?: string
}

const TraineeCard = ({
  currentCourse,
  collapse,
  courseResults,
}: ITraineeCard) => {
  const courseName =
    currentCourse.name === "Knowledge Assessment"
      ? "Beginner"
      : currentCourse.name
  const courseDifficulty = currentCourse.difficulty
  const topics =
    (currentCourse.topics.length > 1
      ? currentCourse.topics.sort((a, b) =>
          String(a?.category) > String(b?.category) ? 1 : -1,
        )
      : currentCourse.topics) || []
  const averageScore = currentCourse?.averageScore
  const beginnerData = courseResults.find((cR) => cR.difficulty === 2)
  const gapData = courseResults.find((cR) => cR.difficulty === 1)
  const gapTopics = gapData?.topics
    ? gapData.topics.sort((a, b) =>
        String(a?.category) > String(b?.category) ? 1 : -1,
      )
    : []
  const tableData = React.useMemo((): Array<
    ITopicCourseResults | { name: string }
  > => {
    if (currentCourse.difficulty < 5) {
      if (currentCourse.difficulty < 3) {
        const topicsNAB = topics?.map((course, index) => ({
          ...course,
          gapGrade: gapTopics[index]?.grade,
        }))
        // return [{ name: "Course Average" }, ...topicsNAB];
        return topicsNAB
      } else {
        return topics
      }
    } else {
      return [{ name: "Grade" }]
    }
  }, [currentCourse, gapData])
  const getGrade = (score: number | null | undefined) =>
    typeof score === "number" ? scoreToGrade(score) : "PENDING"
  const courseGrade = getGrade(averageScore)
  const gapGrade = getGrade(gapData?.averageScore)
  const beginnerGrade = getGrade(beginnerData?.averageScore)

  const getGradeCell = (
    courseName: string,
    grade: string | undefined,
    index: number,
    enrollDate?: string,
  ) => {
    if (courseName === "Gap")
      return (
        <div>
          {gapData?.averageScore ? (
            <GradeText>{grade}</GradeText>
          ) : (
            <Badge>Assigned</Badge>
          )}
        </div>
      )
    if (!beginnerData && courseName === "Beginner")
      return (
        <div>
          <Badge>Not Assigned</Badge>
        </div>
      )
    const courseGrade =
      typeof averageScore === "number" ? scoreToGrade(averageScore) : "-"
    return (
      <div>
        {currentCourse.difficulty > 4 ? (
          courseGrade
        ) : grade || enrollDate ? (
          grade ? (
            <GradeText>{grade}</GradeText>
          ) : (
            <Badge>Assigned</Badge>
          )
        ) : (
          <Badge>Not Assigned</Badge>
        )}
      </div>
    )
  }
  const columns = [
    {
      name: <CustomNameHead name="Topic" />,
      selector: "name",
      sortable: false,
      cell: (res: ICourseResults) => <NameStyled>{res?.name}</NameStyled>,
    },
    ...(courseDifficulty < 3
      ? [
          {
            name: <CustomNameHead name="Knowledge Assessment" />,
            selector: "topics",
            sortable: false,
            width: "25%",
            cell: ({ gapGrade }: ITopic, index: number) =>
              getGradeCell("Gap", gapGrade, index),
          },
        ]
      : []),
    {
      name: <CustomNameHead name={courseName} />,
      selector: "topics",
      sortable: false,
      width: "25%",
      cell: ({ grade, enrollDate }: ITopic, index: number) =>
        getGradeCell(courseName, grade, index, enrollDate),
    },
  ]

  return (
    <TableWrapper isCollapse={collapse[courseName]}>
      {/* { */}
      {currentCourse.difficulty < 5 && (
        <TeamAveragePanel>
          <Section
            fontSize="18px"
            fontWeight="700"
            fontColor={colors.darkBlue}
            flexBasis="140px"
            flexShrink="0"
            margin="0 0 4"
          >
            Course Average
          </Section>
          {currentCourse.difficulty < 3 ? (
            <Section flex="center" gap="55px" flexGrow="1">
              <Section flex="center center column" gap="12px">
                {gapGrade === "PENDING" ? (
                  <Badge>Pending</Badge>
                ) : (
                  <Grade color={GRADE_COLOR[gapGrade]}>{gapGrade}</Grade>
                )}
                Knowledge Assessment
              </Section>
              <Section flex="center center column" gap="12px">
                {beginnerGrade === "PENDING" ? (
                  <Badge>Pending</Badge>
                ) : (
                  <Grade color={GRADE_COLOR[beginnerGrade]}>
                    {beginnerGrade}
                  </Grade>
                )}
                Beginner
              </Section>
            </Section>
          ) : (
            <Section flex="center center column" gap="12px">
              {courseGrade === "PENDING" ? (
                <Badge>Pending</Badge>
              ) : (
                <Grade color={GRADE_COLOR[courseGrade]}>{courseGrade}</Grade>
              )}
            </Section>
          )}
          <Section flexBasis="140px" flexShrink="0" />
        </TeamAveragePanel>
      )}
      <DataTable
        columns={columns}
        data={tableData}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        striped
        noTableHead={courseDifficulty > 4}
      />
      {/* } */}
    </TableWrapper>
  )
}

export default TraineeCard
