import { usePortalSize } from "@src/Components/Common/usePortalSize"
import * as React from "react"

interface IAccordion extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  onClick?: () => void
  selected: boolean
  headerHeight?: number
}

const Accordion = ({
  onClick,
  children,
  selected,
  headerHeight = 70,
  ...rest
}: IAccordion) => {
  const { isDesktop } = usePortalSize()
  const ref = React.useRef<HTMLDivElement>(null)
  const smoothScrollTo = () => {
    const current = ref.current
    const headerOffset = isDesktop ? 0 : headerHeight
    if (current && !selected) {
      setTimeout(() => {
        const elementTop = current.getBoundingClientRect().top + window.scrollY
        const offset = elementTop - headerOffset
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        })
      }, 30)
    }
  }
  const handleClick = () => {
    onClick && onClick()
    smoothScrollTo()
  }

  return (
    <div {...rest} ref={ref} onClick={handleClick}>
      {children}
    </div>
  )
}

export default Accordion
