import { tState } from "@src/Model/Model"
import { isAnyOfGlobalModalIsActiveSelector } from "@src/Selectors/utils"
import * as React from "react"
import { useSelector } from "react-redux"

import ModalAdd from "../ManageTeam/ModalAdd"
import { Image, List, StartPageContainer, Subtitle, Title } from "./styles"

interface IStartPage {
  onChangeMode: () => void
  numberOfSeats: Record<string, number>
  modalIsOpen?: boolean
}

const StartPage: React.FC<IStartPage> = ({
  onChangeMode,
  numberOfSeats,
  modalIsOpen,
}) => {
  const isAnyOfGlobalModalIsActive = useSelector((state: tState) =>
    isAnyOfGlobalModalIsActiveSelector(state),
  )
  const introState = useSelector(
    (state: tState) => state.globalModals.introModalState,
  )
  return (
    <StartPageContainer>
      <Image
        src="images/training/final_onboarding_form.svg"
        alt="on boarding"
      />
      <Title>Start Training Your Team Members Now.</Title>

      <Subtitle>Prepare your team for:</Subtitle>

      <List>
        <li>Ransomware Scams</li>
        <li>Password Management</li>
        <li>Phishing Schemes</li>
        <li>And More...</li>
      </List>
      <div onClick={onChangeMode}>
        {introState.openedFeature !== "training" &&
          !isAnyOfGlobalModalIsActive && (
            <ModalAdd
              numberOfSeats={numberOfSeats}
              title="Add Team Members to Training"
              modalIsOpen={modalIsOpen}
            />
          )}
      </div>
    </StartPageContainer>
  )
}

export default StartPage
