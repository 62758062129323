import { colors, media } from "@src/theme"
import styled from "styled-components"

export const TrainingContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media (max-width: 1300px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 1000px) {
      gap: 10px;
    }

    @media ${media.medium} {
      gap: 10px;
      display: flex;
      flex-wrap: wrap;
    }
  }
`

export const DateTime = styled.span`
  font-size: 16px;
  color: ${colors.gray40};
`
