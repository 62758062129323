import { colors, mediaWidth } from "@src/theme"
import styled from "styled-components"

export const LearnMoreLink = styled.p`
  color: ${colors.link};
  font-size: 16px;
  line-height: 16px;
  z-index: 1;
  padding: 25px 0 10px 0;
  @media (min-width: ${mediaWidth.medium}) {
    text-align: center;
  }
`
