import { createSlice } from "@reduxjs/toolkit"
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction"
import { basicInitialState, tCyberConciergeState } from "@src/Model/Model"
import { ICyberConciergeAlert } from "@src/types"
import {
  CyberConciergeConfig,
  CyberConciergeMessageJson,
} from "@zeguro/schema-validator/dist/types/coco/cyberConcierge/combined"
export const initialCyberConciergeState: tCyberConciergeState = {
  getCyberConciergeConfig: {
    cyberConciergePage: {
      cyberConciergeIconUrl: "",
      cyberConciergeTitle: "",
      cyberConciergeTitleSubText: "",
      cyberConciergeHeading: "",
      cyberConciergeSubscriptionInformation: "",
      cyberConciergeOfferingsHeading: "",
      cyberConciergeEmailCta: {
        cyberConciergeCtaText: "",
        cyberConciergeCtaBtnInfo: "PRIMARY",
        cyberConciergeCtaIcon: "",
      },
      cyberConciergeCallCta: {
        cyberConciergeCtaText: "",
        cyberConciergeCtaBtnInfo: "PRIMARY",
        cyberConciergeCtaIcon: "",
      },
      cyberConciergeOfferings: [],
      cyberConciergeKnowledgeBase: {
        cyberConciergeKnowledgeBaseLinkText: "",
        cyberConciergeKnowledgeBaseIcon: "",
      },
    },
    ...basicInitialState,
  },
  getCyberConciergeAlert: {
    data: null,
    ...basicInitialState,
  },
  getCyberConciergeAlerts: { alerts: [], ...basicInitialState },
}

const cyberConciergeSlice = createSlice({
  name: "cyberConcierge",
  initialState: initialCyberConciergeState,
  reducers: {
    getCyberConciergeConfigRequest({ getCyberConciergeConfig }) {
      getCyberConciergeConfig.isLoading = true
      getCyberConciergeConfig.error = undefined
    },
    getCyberConciergeConfigSuccess(
      { getCyberConciergeConfig },
      { payload }: PayloadAction<{ data: CyberConciergeConfig }>,
    ) {
      getCyberConciergeConfig.cyberConciergePage =
        payload.data.cyberConciergePage
      getCyberConciergeConfig.isLoading = false
      getCyberConciergeConfig.success = true
      getCyberConciergeConfig.error = undefined
    },
    getCyberConciergeConfigFailure(
      { getCyberConciergeConfig },
      { payload }: PayloadAction<Error>,
    ) {
      getCyberConciergeConfig.error = payload
      getCyberConciergeConfig.isLoading = false
    },
    getCyberConciergeAlertsRequest({ getCyberConciergeAlerts }) {
      getCyberConciergeAlerts.isLoading = true
      getCyberConciergeAlerts.error = undefined
    },
    getCyberConciergeAlertsSuccess(
      { getCyberConciergeAlerts },
      { payload }: { payload: ICyberConciergeAlert[] },
    ) {
      getCyberConciergeAlerts.alerts = payload
      getCyberConciergeAlerts.isLoading = false
      getCyberConciergeAlerts.success = true
      getCyberConciergeAlerts.error = undefined
    },
    getCyberConciergeAlertsFailure(
      { getCyberConciergeAlerts },
      { payload }: PayloadAction<Error>,
    ) {
      getCyberConciergeAlerts.error = payload
      getCyberConciergeAlerts.isLoading = false
    },
    getCyberConciergeAlertRequest({ getCyberConciergeAlert }) {
      getCyberConciergeAlert.isLoading = true
      getCyberConciergeAlert.error = undefined
    },
    getCyberConciergeAlertSuccess(
      { getCyberConciergeAlert },
      { payload }: PayloadAction<{ data: CyberConciergeMessageJson }>,
    ) {
      getCyberConciergeAlert.data = payload.data
      getCyberConciergeAlert.isLoading = false
      getCyberConciergeAlert.success = true
      getCyberConciergeAlert.error = undefined
    },
    getCyberConciergeAlertFailure(
      { getCyberConciergeAlert },
      { payload }: PayloadAction<Error>,
    ) {
      getCyberConciergeAlert.error = payload
      getCyberConciergeAlert.isLoading = false
    },
  },
})

export const { name, actions, reducer } = cyberConciergeSlice
