import * as ACTION from "../../Actions/ActionTypes"
import { tRatingsState } from "../../Model/Model"
import initial_state from "../../Model/Model"

interface tAction {
  type: string
  payload: any
}

function reducer(state: tRatingsState, action: tAction) {
  if (!state) {
    state = initial_state.ratings
  }

  switch (action.type) {
    case ACTION.SET_RATINGS_STATUS: {
      const status: string = action.payload
      return { ...state, status }
    }
    case ACTION.SET_RATINGS_DATA: {
      const ratingsData: string[][] = action.payload
      const status = "READY"
      return { ...state, ratingsData, status }
    }
    default:
      return state
  }
}

export default reducer
