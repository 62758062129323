import * as React from "react"

import { Link } from "./styles"

export const supportMessage = (text: string, brandEmailDomain: string) => {
  const emailDomain = brandEmailDomain ?? "cybersafety-mail.com"
  return (
    <>
      <span>{text}</span>&nbsp;
      <span>Try again. If this problem persists, contact</span>&nbsp;
      <Link href={`mailto:support@${emailDomain}`}>support@{emailDomain}</Link>.
    </>
  )
}

export const GetGenericErrorMassage = (brandEmailDomain: string) => {
  const emailDomain = brandEmailDomain ?? "cybersafety-mail.com"
  return (
    <>
      <span>Something went wrong. Try refreshing the page or email</span>&nbsp;
      <Link href={`mailto:support@${emailDomain}`}>support@{emailDomain}</Link>.
    </>
  )
}
