import { colors, media, shadows } from "@src/theme"
import styled from "styled-components"

const borderColor = "#C4C4C4"

interface ICardProps {
  multiple?: boolean
}
interface IGradeProps {
  gradeColor: string
}

export const CardContainer = styled.div`
  display: flex;
  border: ${({ multiple }: ICardProps) =>
    multiple ? "none" : `1px solid ${borderColor}`};
  box-shadow: ${({ multiple }: ICardProps) =>
    multiple ? "none" : shadows.panel};
  padding: 30px;
  border-radius: 6px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  position: relative;
  justify-content: end;
  min-width: 0;

  &:hover {
    box-shadow: ${({ multiple }: ICardProps) =>
      multiple ? "none" : shadows.hoveredPanel};
    border: ${({ multiple }: ICardProps) =>
      multiple ? "none" : `1px solid ${colors.gray40}`};
  }

  @media ${media.medium} {
    border: none;
    box-shadow: none;
    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  img {
    max-height: 51px;
    margin-right: 13px;
  }

  p {
    margin: 0;
  }

  .zeg-head-wrapper {
    display: flex;
    justify-content: start;
    text-align: left;
    align-items: center;

    p {
      font-weight: 600;
      line-height: 22px;
      min-height: 22px;
      color: ${colors.darkBlue};
      font-size: 15px;
    }

    h3 {
      text-align: left;
    }
  }

  .zeg-body-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 240px;
    width: 100%;
    margin: 0 auto;
    padding: 25px 0 10px;
    height: 100%;

    img {
      height: 32px;
      margin-bottom: 5px;
    }
  }

  button {
    background: ${colors.gray40};
    border-radius: 3px;
    height: 25px;
    width: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: ${colors.white};
    cursor: pointer;
  }
`

export const CardTitleWrap = styled.div`
  min-width: 0;
`

export const CardTitle = styled.h3`
  font-weight: bold;
  font-size: 19px;
  color: ${colors.darkBlue};
  margin: 0;
`

export const Grade = styled.div`
  width: 108px;
  height: 108px;
  display: flex;
  border-radius: 75px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${colors.white};
  border: 1px solid ${colors.gray5};

  @media (max-width: 1000px) {
    width: 90px;
    height: 90px;
    margin: 0 2px;
  }
  @media (min-width: 769px) and (max-width: 830px) {
    width: 70px;
    height: 70px;
    margin: 0 2px;
  }

  h3 {
    font-size: 21px;
    line-height: 12px;
    color: ${({ gradeColor }: IGradeProps) => gradeColor};
    font-weight: 700;
    margin: 0;
    height: 16px;
  }
  .pending-grades {
    height: 34px;
  }
  p {
    font-size: 15px;
    line-height: 16px;
    color: ${({ gradeColor }: IGradeProps) => gradeColor};
    margin: 0;
    font-weight: 600;

    @media (max-width: 1000px) {
      font-size: 12px;
    }
  }
`

export const MultipleCardWrapper = styled.div`
  grid-column: 1 / 3;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  box-shadow: ${shadows.panel};
  border-radius: 6px;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  position: relative;

  .zeg-multi-cards-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    gap: 30px;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    @media (max-width: 1000px) {
      gap: 10px;
    }
  }

  &:hover {
    box-shadow: ${shadows.hoveredPanel};
    border: ${({ multiple }: ICardProps) =>
      multiple ? "none" : `1px solid ${colors.gray40}`};
  }

  @media ${media.medium} {
    border: none;
    box-shadow: none;
    &:hover {
      border: none;
      box-shadow: none;
    }
  }
`

export const PendingIcon = styled.i`
  color: white;
  font-size: 24px;
  padding: 4px;
`

export const DetailsLink = styled.span`
  left: 0;
  right: 0;
  bottom: 10px;
  position: absolute;
`

export const PlusIcon = styled.i`
  font-size: 32px;
  color: ${colors.darkBlue};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const CardsDivider = styled.div`
  height: 6px;
  background-color: ${colors.gray5};
  margin: 0 -15px;
`

export const CourseIcon = styled.img`
  @media ${media.medium} {
    width: 32px;
  }
`
