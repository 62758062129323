import { colors } from "@src/theme"
import styled from "styled-components"

export const TooltipStyled = styled.div<{
  type: "dark" | "light"
  width?: string
  textAlign?: "right" | "left" | "center"
  pointerEvents?: string
}>`
  .tooltip-content-custom {
    pointer-events: ${({ pointerEvents }) => pointerEvents};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 10px 10px;
    max-width: ${({ width }) => width};
    width: auto;
    font-weight: normal;
    text-align: ${({ textAlign }) => textAlign};
    opacity: 1 !important;
    p {
      margin: 0;
      color: ${({ type }) => (type === "dark" ? colors.white : colors.black50)};
      font-size: 16px;
      line-height: 21px;
      font-weight: normal;
    }
    h3 {
      font-size: 16px;
    }
    a {
      color: white;
    }
  }
`
