import { colors } from "@src/theme"
import styled from "styled-components"

export const Link = styled.a`
  color: ${colors.white};
  font-weight: 600;
  &:hover {
    color: ${colors.white};
    font-weight: 600;
  }
`
