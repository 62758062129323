import * as React from "react"

import { IntroArticle, IntroListStyle, IntroSection } from "./styles"
type CyberSafetyModalContentType = {
  heading: {
    imageSrc?: string
    mainHeading?: {
      align?: string
      text?: string
    }
    subHeading?: {
      align?: string
      text?: string
    }
  }
  content?: JSX.Element
  pageNumber: number
}
export const ZimmIcons = {
  INTRO: "/images/intro/icon_cloud_lock_shield.svg",
  PEOPLE: "/images/zimm/icon_people.svg",
  PROCESS: "/images/zimm/icon_process.svg",
  TECHNOLOGY: "/images/zimm/icon_technology.svg",
}
export const CyberSafetyIntroModalContent: CyberSafetyModalContentType[] = [
  {
    heading: {
      imageSrc: ZimmIcons.INTRO,
      mainHeading: { text: "Cyber Safety" },
      subHeading: { text: "Cybersecurity. Simplified.", align: "center" },
    },
    content: (
      <IntroListStyle>
        <li>
          Take guided steps to reduce your organization&apos;s cyber risk.
        </li>
        <li>
          Improve your team&apos;s security and compliance awareness with
          training.
        </li>
        <li>Identify security risks with our website scanning tool.</li>
        <li>Define rules and procedures for protecting your organization.</li>
      </IntroListStyle>
    ),
    pageNumber: 1,
  },
  {
    heading: {
      imageSrc: "",
      mainHeading: {
        text: "Protect your organization by addressing these three Security Areas.",
        align: "left",
      },
    },
    content: (
      <IntroArticle>
        <section>
          <div>
            <img src={ZimmIcons.PEOPLE}></img>
          </div>

          <article>
            <h2>People</h2>
            Your team is the backbone of your organization and a prime target
            for cyber attacks. Arm them with the knowledge they need as your
            first line of defense.
          </article>
        </section>
        <section>
          <div>
            <img src={ZimmIcons.PROCESS}></img>
          </div>

          <article>
            <h2>Process</h2>
            Processes define how you address cyber threats. They can reduce the
            chance of an attack. If an attack does occur, they will guide your
            response and reduce the impact.
          </article>
        </section>
        <section>
          <div>
            <img src={ZimmIcons.TECHNOLOGY}></img>
          </div>
          <article>
            <h2>Technology</h2>
            To protect your organization you need the right tools. Cyber Safety
            will provide a strong foundation to enable you to improve your
            cybersecurity.
          </article>
        </section>
      </IntroArticle>
    ),
    pageNumber: 2,
  },
  {
    heading: {
      imageSrc: ZimmIcons.PEOPLE,
      mainHeading: { text: "People" },
    },
    content: (
      <IntroSection>
        <p>
          Your team members are often the weakest link in your organization’s
          security. Everyone has a role to play in preventing a cyber attack.
          Attackers often exploit human behavior to compromise your
          organization. Your team members could click on malicious links, share
          passwords, or fall for phishing emails that would open the door to a
          breach.
        </p>
        <p>
          All your team members should be educated on cyber risks and how their
          actions can impact the overall security of the organization. We will
          help you mitigate these risks with security training, and identifying
          roles and responsibilities.
        </p>
      </IntroSection>
    ),
    pageNumber: 3,
  },
  {
    heading: {
      imageSrc: ZimmIcons.PROCESS,
      mainHeading: { text: "Process" },
    },
    content: (
      <IntroSection>
        <p>
          Processes are structured procedures and guidelines that your team
          follows to reduce your cyber risks and mitigate their impact. Like
          everyday safety routines, such as locking your door after you get
          home, your processes lay out how you will prepare for, identify, and
          respond to cyber threats.
        </p>
        <p>
          Having good processes will answer questions like:
          <ul>
            <li>What password policies should I enforce on my team?</li>
            <li>
              How would I know if my organization is impacted by a security
              event?
            </li>
            <li>What are my legal obligations in case of a breach?</li>
          </ul>
        </p>
        <p>
          We will help you set up Security Policies that establish safer working
          practices.
        </p>
      </IntroSection>
    ),
    pageNumber: 4,
  },
  {
    heading: {
      imageSrc: ZimmIcons.TECHNOLOGY,
      mainHeading: { text: "Technology" },
    },
    content: (
      <IntroSection>
        <p>
          Every organization relies on technology to some degree. Your devices,
          software, and systems need to be set up securely.
        </p>
        <p>
          Cyber Safety can help you get started by scanning your websites for
          vulnerabilities. It can also help you determine the right way to
          configure the technologies you already use. We’ll also inform you
          about other tools that can help you proactively address cyber risk.
        </p>
      </IntroSection>
    ),
    pageNumber: 5,
  },
]
