import { colors, media } from "@src/theme"
import styled from "styled-components"

interface IFaIcon {
  large?: boolean
}

export const Container = styled.div`
  display: flex;
  @media ${media.medium} {
    flex-direction: column;
  }
`

export const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 28px;
  @media ${media.medium} {
    font-size: 21px;
  }
`

export const WrappedImg = styled.img`
  margin: 0 40px 0 0;
  width: 56px;
`

export const FieldsContainer = styled.div`
  flex: 1;
  max-width: 650px;
  @media ${media.medium} {
    max-width: 100%;
  }
`

export const Link = styled.a`
  color: ${colors.link};
`

export const Hint = styled.div`
  margin-bottom: 60px;
  color: ${colors.gray40};
`

export const FaIcon = styled.i<IFaIcon>`
  font-size: ${({ large }) => (large ? "64" : "26")}px;
  margin-right: ${({ large }) => (large ? "36" : "12")}px;
  color: ${colors.darkBlue};
  width: ${({ large }) => large && "100%"};
  max-width: ${({ large }) => large && "62px"};
`
