import { Menu, MenuItem, Popover, Position } from "@blueprintjs/core"
import { colors } from "@src/theme"
import * as _ from "lodash"
import * as React from "react"
import styled from "styled-components"

interface IDropdown {
  id?: string
  items: string[][]
  placeholder: string
  onSelectItem: (data: any) => void
  selectedItem?: string[]
  height: string
  width?: string
  maxWidth?: string
  disabled?: boolean
}

interface IDropdownState {
  selectedItem: string[]
}

export class Dropdown extends React.Component<IDropdown, IDropdownState> {
  public readonly state: IDropdownState = {
    selectedItem: [],
  }

  UNSAFE_componentWillMount() {
    this.onSelect(this.props.selectedItem)
  }

  UNSAFE_componentWillReceiveProps(nextProps: IDropdown) {
    if (!_.isEqual(this.props.selectedItem, nextProps.selectedItem)) {
      this.onSelect(nextProps.selectedItem)
    }
  }

  onSelect(item: string[] | undefined) {
    if (item && item.length > 0) {
      this.setState({ selectedItem: item })
      this.props.onSelectItem(item)
    }
  }
  getMenu() {
    return (
      <MenuContainer>
        <Menu
          style={{
            width: this.props.width,
            maxWidth: this.props.maxWidth || "100vw",
            fontFamily: "proxima-nova, sans-serif",
            fontSize: "15px",
          }}
        >
          {this.props.items.map((item, n) =>
            item[0] === "" ? (
              <MenuItem
                key={"sep" + n}
                style={{ fontWeight: 600, cursor: "default" }}
                text={item[1]}
                onClick={(e: any) => {
                  e.stopPropagation()
                }}
              />
            ) : (
              <MenuItem
                key={item[1] || item[0]}
                text={item[0]}
                active={item[0] === this.state.selectedItem[0]}
                onClick={() => {
                  this.onSelect(item)
                }}
              />
            ),
          )}
        </Menu>
      </MenuContainer>
    )
  }
  render() {
    const { selectedItem } = this.state
    const { placeholder, width, height, maxWidth, disabled } = this.props
    return (
      <StyledPopover
        content={this.getMenu()}
        position={Position.BOTTOM}
        minimal={true}
        usePortal={false}
        disabled={disabled}
      >
        <ButtonDropdown
          id={this.props.id}
          width={width}
          maxWidth={maxWidth || "100%"}
          height={height}
          disabled={disabled}
          placeholderTheme={!selectedItem[0]}
        >
          <ButtonContent>{selectedItem[0] || placeholder}</ButtonContent>
          &nbsp;
          <i
            className="fas fa-chevron-down"
            style={{ float: "right", color: "darkslategray" }}
          />
        </ButtonDropdown>
      </StyledPopover>
    )
  }
}

const padV = 9
const padH = 16

const StyledPopover = styled(Popover)`
  width: 100%;
`

const MenuContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
`

const ButtonDropdown = styled.button<any>`
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: ${(props: any) => (props.width ? props.width : "100%")};
  max-width: ${(props: any) => (props.maxWidth ? props.maxWidth : "100vw")};
  color: ${(props: any) =>
    props.placeholderTheme ? "#767676" : "rgb(0, 0, 0)"};
  background-color: rgba(255, 255, 255, 0);
  outline: none;
  border-radius: 3px;
  text-align: left;
  padding: ${padV}px ${padH}px;
  border: 1px solid #979797;
  cursor: pointer;

  &:hover {
    background-color: ${colors.gray3};
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:focus {
    border: 1px solid #26249e;
  }
`

const ButtonContent = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
`
