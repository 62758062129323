import { Popover, PopoverInteractionKind, Position } from "@blueprintjs/core"
import * as PortalAction from "@src/Actions/PortalActions"
import { ButtonSmall } from "@src/Components/Button"
import {
  Content,
  Divider,
  FormContainer,
  FormElement,
  Heading,
  LinkWrapper,
  Panel,
  PanelContainer,
  PopoverContent,
  SubHeading,
} from "@src/Components/Common"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import FloatingLabelInput from "@src/Components/FloatingLabelInput"
import { Link } from "@src/Components/Link"
import PasswordInput from "@src/Components/PasswordInput"
import Waiting from "@src/Components/Waiting"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import MessageContainer from "@src/Portal/MessageContainer"
import * as _ from "lodash"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router-dom"

import { ButtonRow, Wait } from "./styles"

interface ILogin {
  history: any
}

interface IRoutesParams {
  subscriptionId?: string
}

const Login = (props: ILogin) => {
  const { clientHeight: height } = usePortalSize()
  const pContent = { height }

  const { accountsPageMode: mode, authenticated } = useSelector(
    (state: tState) => {
      return state.portal
    },
  )

  React.useEffect(() => {
    if (authenticated) {
      props.history.push("/")
    }
  }, [authenticated])

  const dispatch = useDispatch()

  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [newPasswordValid, setNewPasswordValid] = React.useState(false)
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [confirmPasswordValid, setConfirmPasswordValid] = React.useState(false)
  const [verificationCode, setVerificationCode] = React.useState("")

  const setLoginPageView = (mode: string) => {
    dispatch(PortalAction.setAccountsPageMode(mode))
  }

  const onLogin = (username: string, password: string, newPassword: string) => {
    const trimmedUsername = username.trim()
    setUsername(trimmedUsername)
    C.heapIdentify(trimmedUsername)
    const currentLoginPageView = mode
    setLoginPageView("request")
    C.Cognito.authenticate(
      trimmedUsername,
      password,
      newPassword,
      (ret: any) => {
        if (ret.success) {
          if (ret.changePassword) {
            setLoginPageView("change")
          } else {
            C.createSession(ret, () => {})
          }
        } else {
          C.toastNotification(ret.error, "danger")
          setLoginPageView(currentLoginPageView)
          C.heapResetIdentity()
        }
      },
    )
  }

  const requestVerificationCode = (username: string) => {
    const username_ = _.toLower(username.trim())
    C.Cognito.forgotPassword(
      username_,
      (msg: string) => {
        C.toastNotification(msg, "success")
        setLoginPageView("reset")
      },
      (msg: string) => {
        C.toastNotification(msg, "danger")
      },
    )
  }

  const resetPassword = (
    username: string,
    password: string,
    verificationCode: string,
  ) => {
    const username_ = _.toLower(username.trim())
    C.Cognito.resetPassword(username_, password, verificationCode, {
      onSuccess: () => {
        C.toastNotification("Your password has been reset", "success")
        setLoginPageView("login")
      },
      onFailure: (err: any) => {
        C.serverLog({
          level: "error",
          message: "Reset Password Failed",
          endpoint: "cognitoUser.confirmPassword",
          filename: __filename,
          stack: err,
        })
        C.toastNotification(err.message, "danger")
      },
    })
  }

  const alreadyHaveCode = () => {
    setLoginPageView("reset")
  }

  const match = useRouteMatch<IRoutesParams>(["/signin/:subscriptionId"])
  const subscriptionId = match?.params?.subscriptionId
  const forgotPassword = () => {
    props.history.push(
      subscriptionId ? `/forgotPassword/${subscriptionId}` : "/forgotPassword",
    )
  }
  const redirectToSignup = () => {
    props.history.push(subscriptionId ? `/signup/${subscriptionId}` : "/signup")
  }

  const submitLogin = () => {
    const username_ = _.toLower(username.trim())
    const password_ = password.trim()
    const newPassword_ = newPassword.trim()

    if (username_.length > 0 && password_.length > 0) {
      onLogin(username_, password_, newPassword_)
      clearPasswords()
    } else {
      C.toastNotification("Please enter username and password", "danger")
    }
  }

  const startPasswordRecovery = () => {
    const username_ = _.toLower(username.trim())

    clearPasswords()
    if (username_.length > 0) {
      requestVerificationCode(username_)
      return
    } else {
      C.toastNotification("Please enter username", "danger")
    }
  }

  const submitPasswordChange = () => {
    const username_ = _.toLower(username.trim())
    const password_ = password.trim()
    const newPassword_ = newPassword.trim()
    const confirmPassword_ = confirmPassword.trim()

    if (
      password_.length > 0 &&
      newPassword_.length > 0 &&
      confirmPassword_.length > 0 &&
      _.isEqual(newPassword, confirmPassword)
    ) {
      onLogin(username_, password_, newPassword_)
    } else {
      if (password_.length === 0) {
        C.toastNotification(`Please enter the temporary password.`, "danger")
      } else if (newPassword_.length === 0 || confirmPassword_.length === 0) {
        C.toastNotification(
          `Please enter and confirm the new password.`,
          "danger",
        )
      } else if (!_.isEqual(newPassword_, confirmPassword_)) {
        C.toastNotification(
          `New password does not match the reentered password.`,
          "danger",
        )
      }
    }
  }

  const isMode = (modes: string[]) => {
    return _.includes(modes, mode)
  }

  const clearPasswords = () => {
    setPassword("")
    setNewPassword("")
  }

  return (
    <Content {...pContent} backgroundImage='url("images/illo-office3.svg")'>
      <MessageContainer />

      <PanelContainer height={height}>
        <Panel>
          {/* Regular Login Form */}

          <FormElement hidden={!isMode(["request"])}>
            <Wait>
              <Waiting text="Signing you in..." padding={5} height="100%" />
            </Wait>
          </FormElement>

          {/* Regular Login Form */}

          <FormContainer hidden={!isMode(["login"])} onSubmit={submitLogin}>
            <Heading align="center" margin="20px 0px">
              Sign in to your account.
            </Heading>
            <FloatingLabelInput
              label="Work email address"
              type="email"
              value={username}
              onChange={({ target }) => setUsername(target?.value)}
              id="signInInput"
            />
            <FloatingLabelInput
              label="Password"
              type="password"
              value={password}
              onChange={({ target }) => setPassword(target?.value)}
              id="signInPassword"
            />
            <ButtonRow hidden={mode !== "login"}>
              <ButtonSmall
                onClick={submitLogin}
                id="sign-in-button"
                disabled={!(username && password)}
              >
                Sign In
              </ButtonSmall>
            </ButtonRow>
          </FormContainer>

          {/* Password Reset Form */}

          <FormElement hidden={!isMode(["reset"])}>
            <Heading align="center" margin="20px 0px">
              Reset your account password.
            </Heading>
            <FloatingLabelInput
              label="Work email address"
              type="email"
              value={username}
              onChange={({ target }) => setUsername(target?.value)}
              id="reset-password-email"
            />
            <FloatingLabelInput
              label="Verification code"
              type="text"
              value={verificationCode}
              onChange={({ target }) => setVerificationCode(target?.value)}
              id="reset-password-code"
            />
            <PasswordInput
              label="New password"
              value={newPassword}
              onChange={({ target }) => setNewPassword(target?.value)}
              id="reset-password-new"
              isValid={(valid) => setNewPasswordValid(valid)}
            />
            <ButtonRow>
              <ButtonSmall
                onClick={() => {
                  resetPassword(username, newPassword, verificationCode)
                }}
                id="reset-password-button"
                disabled={
                  !(
                    username &&
                    newPassword &&
                    verificationCode &&
                    newPasswordValid
                  )
                }
              >
                Reset Password
              </ButtonSmall>
            </ButtonRow>
          </FormElement>

          {/* Password Change Form */}

          <FormElement hidden={!isMode(["change"])}>
            <Heading align="center" margin="20px 0px">
              Change your password.
            </Heading>
            <Heading fontSize="24px" align="left" width="100%">
              Temporary Password
            </Heading>
            <SubHeading margin="0px" display="flex">
              Use the temporary password we emailed you. &nbsp;
              <Popover
                content={
                  <PopoverContent>
                    If you can’t find the email, check other places like junk,
                    spam, social, or other folders.
                  </PopoverContent>
                }
                position={Position.TOP}
                interactionKind={PopoverInteractionKind.HOVER}
              >
                <Link>Can&apos;t find it?</Link>
              </Popover>
            </SubHeading>

            <FloatingLabelInput
              id="change-password-old"
              label="Temporary password"
              type="password"
              value={password}
              onChange={({ target }) => setPassword(target?.value)}
            />
            <Divider margin="20px 0px" />
            <Heading fontSize="24px" align="left" width="100%">
              New Password
            </Heading>
            <PasswordInput
              id="change-password-new"
              label="New password"
              value={newPassword}
              onChange={({ target }) => setNewPassword(target?.value)}
              isValid={(valid) => setNewPasswordValid(valid)}
              noIcon
            />
            <PasswordInput
              id="reenter-password-new"
              label="Reenter new password"
              value={confirmPassword}
              passwordToMatch={newPassword}
              onChange={({ target }) => setConfirmPassword(target?.value)}
              isValid={(valid) => setConfirmPasswordValid(valid)}
              noIcon
            />
            <ButtonRow>
              <ButtonSmall
                id="change-password-button"
                onClick={() => {
                  submitPasswordChange()
                }}
                disabled={
                  !(newPasswordValid && confirmPasswordValid && password)
                }
              >
                Change Password
              </ButtonSmall>
            </ButtonRow>
          </FormElement>

          {/* Request Verification Key Form */}
          <FormElement hidden={!isMode(["forgot"])}>
            <Heading align="center" margin="20px 0px">
              Verify your account.
            </Heading>
            <FloatingLabelInput
              id="verify-account-email"
              label="Work email address"
              type="email"
              value={username}
              onChange={({ target }) => setUsername(target?.value)}
            />
            <ButtonRow>
              <ButtonSmall
                id="verify-account-request-code"
                onClick={() => {
                  startPasswordRecovery()
                }}
                disabled={!username}
              >
                Request Verification Code
              </ButtonSmall>
            </ButtonRow>
            <LinkWrapper>
              <p>
                <Link
                  onClick={() => {
                    clearPasswords()
                    alreadyHaveCode()
                  }}
                  id="already-have-verify-code-link"
                >
                  Already have a verification code?
                </Link>
              </p>
            </LinkWrapper>
          </FormElement>

          <LinkWrapper hidden={mode !== "login"}>
            <p>
              <Link
                id="forgot-password-link"
                onClick={() => {
                  clearPasswords()
                  forgotPassword()
                }}
              >
                I forgot my password
              </Link>
            </p>
          </LinkWrapper>
          <Divider hidden={mode !== "login"} />
          <LinkWrapper hidden={mode !== "login"}>
            <p>
              Don’t have an account? <br />
              <Link
                onClick={() => {
                  clearPasswords()
                  redirectToSignup()
                }}
              >
                Sign up
              </Link>
            </p>
          </LinkWrapper>
        </Panel>
      </PanelContainer>
    </Content>
  )
}

export default Login
