import { colors } from "@src/theme"
import styled from "styled-components"

interface IFooterProps {
  disabled: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Section = styled.div`
  margin: 20px;
`

export const InfoText = styled.div`
  margin: 0 5px;
`

export const FileName = styled.span`
  font-weight: bold;
  margin: 10px 5px;
`

export const Icon = styled.i`
  font-size: 16px;
  margin: 0 5px;
`

export const Link = styled.div`
  color: #0062d8;
  cursor: pointer;
`

export const BulkContent = styled.div``

export const BulkFooter = styled.div<IFooterProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 10px 10px;
  opacity: ${({ disabled }) => disabled && 0.7};
  pointer-events: ${({ disabled }) => disabled && "none"};
`

export const DragDropSection = styled.div`
  width: 100%;
  height: 250px;
  padding: 0 5px;
  background: ${colors.gray3};
  border: 1px dashed ${colors.gray30};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

export const UploadFileContainer = styled.div`
  width: 100%;
  height: 50px;
  background: ${colors.gray3};
  border: 1px dashed ${colors.gray30};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;

  &:focus {
    outline: none;
  }
`

export const DragDropIcon = styled.i`
  font-size: 60px;
  margin: 10px;
  color: ${colors.gray30};
`

export const DragDropText = styled.div`
  max-width: 320px;
  text-align: center;
`

export const DragDropInnerText = styled.div`
  margin: 10px 0;
`

// export const ButtonWrap = styled.div`
//   width: 180px;
//   @media (max-width: 560px) {
//     button {
//       font-size: 14px;
//     }
//   }
// `;

export const WaitingWrap = styled.div`
  height: 250px;
`

export const ConfirmSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
  max-height: 500px;
  overflow: auto;
`

export const ConfirmList = styled.div`
  margin-bottom: 10px;
`

export const ConfirmItem = styled.li`
  color: ${colors.gray50};
  margin: 5px;
`

export const TrashIcon = styled.i`
  color: ${colors.error};
  font-size: 18px;
  cursor: pointer;
`
