import { Popover, Position } from "@blueprintjs/core"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import * as T from "@src/types"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"

import C from "../../Controller"
import { usePortalSize } from "../Common/usePortalSize"
import { FaIcon } from "../FaIcon"
import {
  CompanyName,
  Logo,
  LogoImg,
  MenuIcon,
  Navbar,
  SignOutButton,
  UserIcon,
  UserInfo,
  UserInfoContent,
  UserName,
  UserNameContainer,
  ZeguroSessionBanner,
} from "./styles"

interface IHeader {
  height: number
  offsetTop: number
  uiRules?: T.tChannelUiRules
  hideBorder?: boolean
  authenticated: boolean
  onBoarding: boolean
  isFirstTimeLogin: boolean
  isIncorrectURL: boolean
  onOpenMenu: () => void
}
interface IRoutesParams {
  subscriptionId?: string
}

const Header = ({
  height,
  offsetTop,
  uiRules,
  hideBorder = true,
  authenticated,
  onBoarding,
  isFirstTimeLogin,
  isIncorrectURL,
  onOpenMenu,
}: IHeader) => {
  const { isDesktop, clientWidth } = usePortalSize()
  const history = useHistory()

  const isZeguroServiceSession = useSelector(
    (state: tState) => state.portal.isZeguroServiceSession,
  )
  const userProfile = useSelector((state: tState) => state.portal.userProfile)

  const companyName = useSelector(
    (state: tState) => state.portal.companyProfile?.companyName,
  )

  const { brandLogo } = useSelector((state: tState) => state.brand)

  const email = userProfile?.email || ""
  const isLongEmail = email.length > 25
  const emailBody = email.split("@")[0]
  const emailTail = `@${email.split("@")[1]}`
  const truncatedEmailBody =
    emailBody.length > 25 ? `${emailBody.slice(0, 25)}...` : emailBody
  const username = [userProfile?.firstName, userProfile?.lastName]
    .filter((name) => name)
    .join(" ")
  const usernameLine1 = username || (isLongEmail ? truncatedEmailBody : email)
  const usernameLine2 = !username && isLongEmail && emailTail

  const match = useRouteMatch<IRoutesParams>([
    "/signin/:subscriptionId",
    "/signup/:subscriptionId",
    "/resetPassword/:linkId/:subscriptionId",
    "/forgotPassword/:subscriptionId",
  ])

  const handleLogoClick = () => {
    if (authenticated) {
      history.push("/")
    } else {
      history.push(
        match?.params?.subscriptionId
          ? `/signin/${match.params.subscriptionId}`
          : "/signin",
      )
    }
  }

  const showLogo = () => (
    <Logo id="header-zeguro-logo" onClick={handleLogoClick}>
      {uiRules?.marketingLogoSecondary ? (
        <LogoImg isCustom src={uiRules?.marketingLogoSecondary} />
      ) : (
        <LogoImg src={brandLogo} />
      )}
    </Logo>
  )

  if (
    isDesktop &&
    authenticated &&
    !onBoarding &&
    !isFirstTimeLogin &&
    !isIncorrectURL
  ) {
    return null
  }

  return (
    <>
      {!authenticated || onBoarding || isFirstTimeLogin || isIncorrectURL ? (
        <>
          <Navbar height="70" padding="0 10px" hideBorder>
            {showLogo()}
          </Navbar>
          {(onBoarding ||
            (authenticated && (isFirstTimeLogin || isIncorrectURL))) && (
            <UserInfoContent>
              <Popover
                key={clientWidth}
                minimal
                content={
                  <SignOutButton
                    className="bp5-menu-item bp5-popover-dismiss"
                    onClick={() => C.Portal.logout()}
                  >
                    {!isDesktop && <UserName>{username || email}</UserName>}
                    <a>
                      <FaIcon
                        className="far fa-sign-out"
                        color={colors.gray40}
                      />
                      &nbsp; Sign Out
                    </a>
                  </SignOutButton>
                }
                position={Position.BOTTOM}
              >
                <UserInfo>
                  <UserIcon className={"fas fa-user-circle"} />
                  {!isDesktop && <FaIcon className="fas fa-sort-down" />}
                  {isDesktop && (
                    <>
                      <UserNameContainer data-tip data-for="full-email-tooltip">
                        <UserName>{usernameLine1}</UserName>&nbsp;
                        {usernameLine2 && (
                          <>
                            <br />
                            <UserName>{usernameLine2}</UserName>&nbsp;
                          </>
                        )}
                        <FaIcon
                          className="fas fa-sort-down"
                          verticalAlign="top"
                          margin="1px 0 0"
                        />
                      </UserNameContainer>
                      {!username && emailBody !== truncatedEmailBody && (
                        <Tooltip
                          id="full-email-tooltip"
                          content={<p>{email}</p>}
                        />
                      )}
                    </>
                  )}
                  {isDesktop && companyName && (
                    <CompanyName>{companyName}</CompanyName>
                  )}
                </UserInfo>
              </Popover>
            </UserInfoContent>
          )}
        </>
      ) : (
        <Navbar height={height} offsetTop={offsetTop} hideBorder={hideBorder}>
          {isZeguroServiceSession ? (
            <ZeguroSessionBanner>
              Attention! Cyber Safety service session is in progress!
            </ZeguroSessionBanner>
          ) : null}
          <MenuIcon className={"fas fa-bars"} onClick={() => onOpenMenu()} />
        </Navbar>
      )}
    </>
  )
}

export default Header
