import { colors, shadows } from "@src/theme"
import styled from "styled-components"

export const Row = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  color: ${colors.black50};
  width: 100%;
  margin-bottom: 20px;
`

export const Label = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: ${colors.black80};
  margin-bottom: 5px;
  display: flex;
`

export const ConfirmUrlBox = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: ${shadows.panel};
  border: solid 1px ${colors.navyBlue};
  background-color: rgba(0, 98, 216, 0.05);
  padding: 13px 26px;
`

export const PopoverContent = styled.div`
  width: 298px;
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  color: ${colors.white};
  padding: 10px;
`

export const AppUrl = styled.div`
  padding: 10px 0 20px;
  font-weight: 700;
  font-size: 18px;
  word-break: break-all;
`
