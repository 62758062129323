import { colors } from "@src/theme"
import * as React from "react"
import styled, { css } from "styled-components"

interface IFloatingLabelInput {
  label: string
  value: string
  hidden?: boolean
  onChange: (e: any) => void
  onValidate?: (value: string) => string
  onBlur?: () => void
  onFocus?: () => void
  inputIcon?: string
  onClickIcon?: () => void
  id?: string
  [key: string]: any
}

interface IFloatingLabelInputState {
  isFocused: boolean
  error: string
}

export default class FloatingLabelInput extends React.Component<
  IFloatingLabelInput,
  IFloatingLabelInputState
> {
  public readonly state: IFloatingLabelInputState = {
    isFocused: false,
    error: "",
  }

  handleFocus = () => {
    this.setState({ isFocused: true })
    this.props.onFocus && this.props.onFocus()
  }

  handleBlur = () => {
    const { onValidate, value, onBlur } = this.props
    this.setState({ isFocused: false })
    onValidate && this.setState({ error: onValidate(value) })
    onBlur && onBlur()
  }

  floatingInp: any

  setFocus = () => {
    const { isFocused } = this.state

    if (!isFocused) {
      this.setState({ isFocused })
      this.floatingInp.focus()
    }
  }

  handleLabelClick = () => {
    const { isFocused } = this.state
    const { value } = this.props

    if (!isFocused && !value) {
      this.setState({ isFocused })
      this.floatingInp.focus()
    }
  }

  render() {
    if (this.props.hidden) {
      return null
    }
    const {
      label,
      value,
      onChange,
      onFocus,
      onBlur,
      onValidate,
      inputIcon,
      onClickIcon,
      ...rest
    } = this.props
    const { isFocused, error } = this.state
    return (
      <TextFieldWrapper>
        {label && (
          <Label
            up={isFocused || value}
            onClick={this.handleLabelClick}
            error={error}
          >
            {label}
          </Label>
        )}
        <InputWrapper error={error} focus={isFocused}>
          <Input
            id={this.props.id}
            value={value}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            ref={(ip: any) => (this.floatingInp = ip)}
            {...rest}
          />
          {rest.disabled ? (
            <LockIcon className={"fas fa-lock"} />
          ) : inputIcon && inputIcon.length ? (
            <Icon isClickable={onClickIcon}>
              <i
                className={inputIcon}
                onClick={() => onClickIcon && onClickIcon()}
              />
            </Icon>
          ) : null}
        </InputWrapper>
        <ErrorContainer> {error} </ErrorContainer>
      </TextFieldWrapper>
    )
  }
}

const defaultColor = "#777777"
const padV = 11
const padH = 20
const labelSize = 15
const fontSize = 16
const lineHeight = 20
const borderWidth = 1

const TextFieldWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${(props) => props.theme.textColor || defaultColor};
  width: 100%;
`
const Icon = styled.span<any>`
  font-size: ${lineHeight}px;
  line-height: 1;
  padding-right: ${padV + 2}px;
  i {
    cursor: ${({ isClickable }) => isClickable && "pointer"};
  }
`

const LockIcon = styled.i`
  font-size: 16px;
  color: ${colors.gray40};
  padding-right: 11px;
`

const Label = styled.label<any>`
  font-family: proxima-nova, sans-serif;
  color: ${colors.gray42};
  font-size: ${fontSize}px;
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.06, 0.67, 0.32, 0.82);
  transform: translate(${padH - 8}px, ${labelSize + borderWidth + padV + 5}px);
  background-color: white;
  padding: 0 10px;
  border-radius: 5px;
  line-height: 1;
  ${(props: any) =>
    props.up &&
    css`
      transform-origin: left;
      transform: scale(0.8125) translate(${padH - 2}px, ${padV - borderWidth}px);
      color: ${props.error ? colors.red : "#26249e"};
    `}
`

const Input = styled.input<any>`
  font-size: ${fontSize}px;
  line-height: ${lineHeight}px;
  font-family: proxima-nova, sans-serif;
  padding: ${padV + 2}px ${padH}px ${padV}px;
  border-width: 0px;
  border-color: transparent;
  flex: 1;
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${colors.white};
    cursor: not-allowed;
  }
`

const InputWrapper = styled.div<any>`
  color: defaultColor;
  background-color: transparent;
  outline: none;
  border-radius: 3px;
  font-size: ${labelSize}px;
  width: 100%;
  border: ${(props: any) =>
    props.error
      ? `${borderWidth}px solid red`
      : props.focus
      ? `${borderWidth}px solid #26249e`
      : `${borderWidth}px solid #979797`};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const ErrorContainer = styled.div`
  color: red;
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  padding: 2px 0px;
`
