import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Section } from "@src/Components/StyledUtils"
import { tState } from "@src/Model/Model"
import DownloadCSV from "@src/Portal/Training/DownloadCSV"
import { CourseMap as ICourseMap } from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"
import { useSelector } from "react-redux"

import Card from "../Card"
import { DateTime } from "../styles"
import { HeadContainer, HeadTitle } from "./styles"

interface ICompanyTab {
  courseList: ICourseMap[]
  setCurrentCourse: (key: ICourseMap) => void
  companyName: string
  date: string
}

const CompanyTab: React.FC<ICompanyTab> = ({
  courseList,
  setCurrentCourse,
  companyName,
  date,
}) => {
  const { clientWidth } = usePortalSize()

  const traineesData = useSelector(
    (state: tState) => state.training.trainingData,
  )
  const gapCourse = courseList.find((c) => c.difficulty === 1)
  const beginnerCourse = courseList.find(
    (c) => c.difficulty === 2 && c.assessmentData.totalCourses,
  )

  const list = beginnerCourse
    ? courseList.filter(
        (c) => c.assessmentData.totalCourses && c.difficulty !== 1,
      )
    : courseList.filter((c) => c.assessmentData.totalCourses)
  const listForRender = !list.length && gapCourse ? [gapCourse] : list

  return (
    <div>
      <HeadContainer>
        <Section
          flex={
            clientWidth > 1100
              ? "flex-start flex-end"
              : "flex-start flex-start column"
          }
          gap="4px 8px"
        >
          <HeadTitle>{companyName}&apos;s Report Cards</HeadTitle>
          <DateTime>Updated {date}</DateTime>
        </Section>
        <DownloadCSV data={traineesData} />
      </HeadContainer>
      <div className="cards-container">
        {listForRender?.length ? (
          listForRender?.map((course) => {
            return (
              <Card
                key={course.name}
                course={course}
                setCurrentCourse={setCurrentCourse}
                gapCourse={
                  gapCourse && course.difficulty < 3 ? gapCourse : undefined
                }
                courseList={courseList}
              />
            )
          })
        ) : (
          <span>There are no courses with trainees enrolled yet</span>
        )}
      </div>
    </div>
  )
}

export default React.memo(CompanyTab)
