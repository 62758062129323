import { CybersecurityRoadBlockModal } from "@src/GlobalModals/CybersecurityRoadBlockModal"
import { tState } from "@src/Model/Model"
import { isAnyOfGlobalModalIsActiveSelector } from "@src/Selectors/utils"
import * as React from "react"
import { useSelector } from "react-redux"

import ContactCyberConciergeModal from "./ContactCyberConciergeModal"
import CybersecurityReleaseNotes from "./CybersecurityReleaseNotes"
import IntroDialog from "./Intro/IntroDialog"

const GlobalModals = () => {
  const isAnyOfGlobalModalIsActive = useSelector((state: tState) =>
    isAnyOfGlobalModalIsActiveSelector(state),
  )
  const isCybersecurityReleaseNotesOpen = useSelector(
    (store: tState) =>
      store.globalModals.globalModalState.cybersecurityReleaseNotes,
  )
  const isCybersecurityRoadBlockOpen = useSelector(
    (store: tState) =>
      store.globalModals.globalModalState.isCybersecurityRoadBlock,
  )
  const isIntroDialogOpen = !!useSelector(
    (store: tState) => store.globalModals.introModalState.openedFeature,
  )
  const isContactCyberConciergeModal = useSelector(
    (store: tState) =>
      store.globalModals.globalModalState.isContactCyberConcierge,
  )
  return (
    <>
      {isCybersecurityReleaseNotesOpen && (
        <CybersecurityReleaseNotes isOpen={isCybersecurityReleaseNotesOpen} />
      )}
      {isCybersecurityRoadBlockOpen && (
        <CybersecurityRoadBlockModal isOpen={isCybersecurityRoadBlockOpen} />
      )}
      {!isAnyOfGlobalModalIsActive && isIntroDialogOpen && (
        <IntroDialog isOpen={isIntroDialogOpen} />
      )}
      {isContactCyberConciergeModal && (
        <ContactCyberConciergeModal isOpen={isContactCyberConciergeModal} />
      )}
    </>
  )
}
export default GlobalModals
