import _ from "lodash"
import * as moment from "moment-timezone"
import * as Mustache from "mustache"

export const numberWithCommas = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const effectiveDateUsa = (d: string | number | Date) => {
  return moment(d).tz("America/New_York")
}

export const getDateIso = (d: string | number | Date) => {
  return moment(d).format("YYYY-MM-DD")
}

export const getDateStr = (d: Date) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  }
  return d.toLocaleDateString("en-US", dateOptions)
}

export const getQuoteFormUrl = (environmentName?: string) => {
  if (
    environmentName &&
    ["dev", "demo", "staging", "test", "test2", "qa", "preflight"].includes(
      environmentName,
    )
  ) {
    return `https://zeguro-stage.webflow.io/quote?env=${environmentName}`
  } else {
    return `https://www.zeguro.com/quote`
  }
}

export const getPrivateAsset = (privateUrl: string) => {
  try {
    const parts = privateUrl.split("/")
    const filename = parts[parts.length - 1]
    const url = `/api/resources/asset?fileName=${filename}`
    window.open(url, "_blank")
  } catch (e) {
    console.error(e)
  }
}

export const calculatePercentage = (
  currentValues: number,
  allValues: number,
) => {
  if (currentValues === 0 || allValues === 0) return 0

  return Math.round((currentValues / allValues) * 100)
}

export function formatCustomValue(value: any, k: string, config: any) {
  let fmtValue = ""

  if (
    config &&
    (config.type === "section" || config.type === "classificator")
  ) {
    return ""
  }

  if (!(config && config.formatter)) {
    fmtValue = value
  } else {
    if (config.namedList) {
      const values: { [key: string]: any } = value
      const fo = config.items.map((name: string) => {
        const value = values[name]
        if (!value || value.length === 0) {
          return ""
        }
        return Mustache.render(config.formatter, { value, name })
      })
      fmtValue = fo.join(config.joinChar || "")
    } else {
      if (config.multipleValues) {
        if (value === null) {
          fmtValue = config.defaultText || "[Click to configure]"
        } else {
          const values: any[] = value
          const fo = values.map((value: any) => {
            if (value.userText) {
              const userText = _.escape(value.userText)
              const v = { ...value, userText }
              return Mustache.render(config.formatter, { value: v })
            }

            if (config.formatMap) {
              value = config.formatMap[value]
            }
            return Mustache.render(config.formatter, { value })
          })
          fmtValue = fo.join(config.joinChar || "")
        }
      } else {
        if (value === null) {
          fmtValue = config.defaultText || "[Click to configure]"
        } else {
          if (config.formatMap) {
            value = config.formatMap[value]
          }
          fmtValue = Mustache.render(config.formatter, { value })
        }
      }
    }
  }

  if (config && config.capitalize) {
    fmtValue = fmtValue.charAt(0).toUpperCase() + fmtValue.slice(1)
  }

  if (config && config.header && fmtValue.length > 0) {
    fmtValue = config.header + fmtValue
  }

  return fmtValue
}
