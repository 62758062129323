import * as ACTION from "@src/Actions/ActionTypes"
import { ISecurityPolicy } from "@src/Model/Model"
import * as T from "@src/types"
import { PolicyListItem } from "@zeguro/schema-validator/dist/types/coco/securityPolicy/combined"
export const requestList = () => ({
  type: ACTION.COMPLIANCE_REQ_LIST,
  payload: null,
})

export const requestPolicy = (entry: any) => ({
  type: ACTION.COMPLIANCE_REQ_POLICY,
  payload: entry,
})

export const requestVersion = (entry: any) => ({
  type: ACTION.COMPLIANCE_REQ_VERSION,
  payload: entry,
})

export const requestSavePolicy = (
  policy: ISecurityPolicy,
  policyAction: string,
) => ({
  type: ACTION.COMPLIANCE_REQ_SAVE_POLICY,
  payload: { policy, policyAction },
})

export const requestDeletePolicy = (policyId: string, version: number) => ({
  type: ACTION.COMPLIANCE_REQ_DELETE_POLICY,
  payload: { policyId, version },
})

export const requestHistory = (policyType: string, policyName: string) => ({
  type: ACTION.COMPLIANCE_REQ_HISTORY,
  payload: { policyType, policyName },
})

export const updateRequestStatus = (
  target: string,
  status: T.tStatus,
  error?: string,
) => ({
  type: ACTION.COMPLIANCE_REQ_STATUS,
  payload: { target, status: { target, status, error } },
})

export const changePolicyMode = (mode: string) => ({
  type: ACTION.CHANGE_POLICY_MODE,
  payload: mode,
})
export const changeDraftMode = (mode: string) => ({
  type: ACTION.CHANGE_DRAFT_MODE,
  payload: mode,
})
export const showCustomDialog = (data: { id: string; offset: number }) => ({
  type: ACTION.SHOW_CUSTOM_DIALOG,
  payload: data,
})
export const updateCustomValues = (data: any) => ({
  type: ACTION.UPDATE_CUSTOM_VALUES,
  payload: data,
})
export const cancelCustomForm = () => ({
  type: ACTION.CANCEL_CUSTOM_FORM,
  payload: {},
})
export const notifyApprover = (data: any) => ({
  type: ACTION.NOTIFY_APPROVER,
  payload: data,
})
export const openPolicyHistory = (data: any) => ({
  type: ACTION.OPEN_POLICY_HISTORY,
  payload: data,
})
export const updatePolicyList = (data: PolicyListItem[]) => ({
  type: ACTION.UPDATE_POLICY_LIST,
  payload: data,
})
export const updateOutOfBoxList = (data: string[][]) => ({
  type: ACTION.UPDATE_OUT_OF_BOX_LIST,
  payload: data,
})

export const updatePolicy = (data: ISecurityPolicy | null) => ({
  type: ACTION.UPDATE_POLICY,
  payload: data,
})
export const updatePolicyText = (policyText: string) => ({
  type: ACTION.UPDATE_POLICY_TEXT,
  payload: policyText,
})
export const updateStorageList = (storageList: string[]) => ({
  type: ACTION.UPDATE_STORAGE_LIST,
  payload: storageList,
})
