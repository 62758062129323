import { colors, media } from "@src/theme"
import styled from "styled-components"

interface IGradeTextProps {
  isGrade?: boolean
  isEnrolled?: boolean
}

interface ITableWrapper {
  isCollapse?: boolean
}

export const GradeText = styled.span<IGradeTextProps>`
  font-size: 16px;
  color: ${({ isEnrolled }) => (isEnrolled ? colors.gray40 : colors.black50)};
`

export const TableWrapper = styled.div<ITableWrapper>`
  margin-bottom: ${({ isCollapse }) => isCollapse && "30px"};
`

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const NameStyled = styled.span`
  font-size: 16px;
  color: ${colors.black};
`

export const TeamAveragePanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: rgba(0, 98, 216, 0.05);
  border: 1px solid rgba(0, 98, 216, 0.5);
  border-radius: 6px;
  margin-top: 24px;
  margin-bottom: 30px;
  padding: 14px 20px;
  @media ${media.medium} {
    background-color: inherit;
    border: none;
  }
`

export const Grade = styled.div<{ color?: string }>`
  line-height: 44px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  width: 44px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: ${colors.white};
`

export const Badge = styled.div`
  background-color: ${colors.gray20};
  color: ${colors.white};
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  padding: 4px 8px;
  margin: 10px 0;
  border-radius: 3px;
`

const borderStyle = "1px solid rgba(173, 173, 173, 0.5)"
export const conditionalRowStyles: any = [
  {
    when: ({ name }: any) => name === "Grade",
    style: {
      borderTop: borderStyle,

      "& span": {
        fontWeight: "bold",
      },
    },
  },
]

export const customStyles: any = {
  headCells: {
    style: {
      "&.rdt_TableCol": {
        minWidth: "210px",
      },
      "&:first-of-type": {
        minWidth: "300px",
      },
      "&:not(:first-of-type)": {
        justifyContent: "center",
      },
    },
  },
  cells: {
    style: {
      "&.rdt_TableCell": {
        minWidth: "210px",
      },
      "&:first-of-type": {
        minWidth: "300px",
      },
      "&:not(:first-of-type)": {
        justifyContent: "center",
      },
    },
  },
}
