import * as React from "react"

import { CheckboxStyled } from "./styles"

interface IIndeterminateCheckbox {
  name: string
  value: string | boolean | undefined
  disabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const IndeterminateCheckbox: React.FC<IIndeterminateCheckbox> = ({
  name,
  value,
  disabled,
  onChange,
}) => {
  const el = React.useRef<HTMLInputElement | null>(null)

  React.useEffect(() => {
    const { current } = el
    if (current !== null) {
      current.checked = value === true
      current.indeterminate = value === "partially"
    }
  }, [value])

  return (
    <CheckboxStyled disabled={disabled}>
      <input
        type="checkbox"
        name={name}
        ref={el}
        onChange={onChange}
        disabled={disabled}
      />
      {name}
    </CheckboxStyled>
  )
}

export default IndeterminateCheckbox
