import * as T from "../types"
import * as ACTION from "./ActionTypes"

export const setSaasPlans = (plans: T.tPlansRequestState) => ({
  type: ACTION.SET_SAAS_PLANS,
  payload: plans,
})

export const setUiRules = (uiRules: T.tChannelUiRules) => ({
  type: ACTION.SET_UI_RULES,
  payload: uiRules,
})

export const setSaasPlansStatus = (status: string) => ({
  type: ACTION.SET_SAAS_PLANS_STATUS,
  payload: status,
})

export const setEligiblePlans = (eligiblePlans: T.IEligiblePlan[]) => ({
  type: ACTION.SET_ELIGIBLE_PLANS,
  payload: eligiblePlans,
})

export const setChargebeeSubscriptionStatus = (
  chargebeeSubscriptionStatus: boolean,
) => ({
  type: ACTION.SET_CHARGEBEE_SUBSCRIPTION_STATUS,
  payload: chargebeeSubscriptionStatus,
})

export const setEligiblePlansStatus = (status: string) => ({
  type: ACTION.SET_ELIGIBLE_PLANS_STATUS,
  payload: status,
})

export const setUpdatePlanStatus = (status: string) => ({
  type: ACTION.SET_UPDATE_PLANS_STATUS,
  payload: status,
})
