import * as moment from "moment"

export const getAllDaysInMonth = (dayIndex: number, date: Date) => {
  const result = []
  const lastDayOfMonth = moment(date).daysInMonth()
  for (let i = 1; i <= lastDayOfMonth; i++) {
    const newDate = new Date(date.getFullYear(), date.getMonth(), i)
    if (newDate.getDay() === dayIndex) {
      result.push(moment(date).date(i).toDate().getDate())
    }
  }
  return result
}

export const getNumberEnding = function (d: number) {
  if (d > 3 && d < 21) return "th"
  switch (d % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}
