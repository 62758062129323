import { Classes, Popover, Position } from "@blueprintjs/core"
import * as React from "react"
import styled from "styled-components"

import Icon from "./Icon"

interface IThreeDotMenu {
  menuItems: IMenuItemConf[]
  iconName?: string
  color?: string
  buttonContent?: JSX.Element
  position?: Position
  title?: string
}

interface IMenuItemConf {
  iconClass?: string
  caption: string
  color?: string
  action: () => void
}

const ThreeDotMenu = (props: IThreeDotMenu) => {
  return (
    <Popover
      content={<PopoverMenu menuItems={props.menuItems} title={props?.title} />}
      position={props.position || Position.TOP}
    >
      {props.buttonContent ? (
        props.buttonContent
      ) : (
        <Icon style={{ color: props.color || undefined }}>
          <i className={props.iconName || "far fa-ellipsis-v"} />
        </Icon>
      )}
    </Popover>
  )
}

interface IPopoverMenu {
  menuItems: IMenuItemConf[]
  title?: string
}

const PopoverMenu = (props: IPopoverMenu) => {
  const items = props.menuItems.map((e: any, n: number) => {
    return (
      <MenuItem
        id={e.id}
        key={n}
        className={Classes.POPOVER_DISMISS}
        onClick={e.action}
      >
        {e.iconClass && (
          <Icon style={{ color: e.color || undefined }}>
            <i className={e.iconClass} />
          </Icon>
        )}
        <MenuCaption color={e.color}>{e.caption}</MenuCaption>
      </MenuItem>
    )
  })
  return (
    <MenuPanel>
      {props?.title && <Title>{props.title}</Title>}
      {items}
    </MenuPanel>
  )
}

const MenuPanel = styled.div`
  padding: 5px 0;
  white-space: nowrap;
`

const MenuItem = styled.div`
  line-height: 20px;
  padding: 5px 15px;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`

const MenuCaption = styled.div`
  display: inline-block;
  text-indent: 10px;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.color || "inherit"};
`
const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 15px;
`
export default ThreeDotMenu
