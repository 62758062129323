import * as React from "react"

import { LinkIcon, StyledLink } from "./styles"

interface ILink {
  children?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  color?: string
  href?: string
  isUnderline?: boolean
  withIcon?: boolean
  padding?: string
  margin?: string
  id?: string
  fontSize?: string
  lineHeight?: string
  bold?: boolean
  onMouseEnter?: () => void
}

export const Link = ({
  children,
  onClick,
  disabled,
  color,
  href,
  isUnderline = false,
  withIcon,
  padding,
  margin,
  fontSize,
  lineHeight,
  bold,
  ...restProps
}: ILink) => {
  return (
    <StyledLink
      onClick={!disabled ? onClick : () => {}}
      disabled={disabled}
      isUnderline={isUnderline}
      href={href}
      color={color}
      padding={padding}
      margin={margin}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      target="_blank"
      {...restProps}
    >
      {children}
      {withIcon && <LinkIcon className="fas fa-external-link" />}
    </StyledLink>
  )
}
