import { hidden } from "@src/Components/StyledUtils"
import { colors } from "@src/theme"
import styled from "styled-components"

export const StyledCell = styled.div`
  display: table-cell;
  vertical-align: middle;
  height: 23px;
  width: 23px;
`

export const MenuText = styled.div<{ badge?: "BETA" | "NEW" }>`
  display: table-cell;
  vertical-align: middle;
  height: 23px;
  padding-right: 5px;
  position: relative;
  &::after {
    font-size: 8px;
    border-radius: 3px;
    background: ${colors.violet};
    color: ${colors.white};
    content: "${({ badge }) => (badge ? `${badge}` : "")}";
    padding: 3px 6px;
    margin-left: 4px;
    vertical-align: bottom;
    display: ${({ badge }) => (badge ? "flex" : "none")};
    position: absolute;
    height: 16px;
    margin-bottom: auto;
    margin-top: auto;
    top: 0;
    bottom: 0;
    right: -30px;
    align-items: center;
  }
`

export const SubMenuItem = styled.div`
  margin-left: 20px;
`

export const StyledIconItem = styled.img<any>`
  padding-right: 10px;
  vertical-align: middle;
  height: 23px;
  width: 33px;
  display: ${(props: any) => (props.hidden ? "none" : "table-cell")};
`

export const StyledMenuItem = styled.div<any>`
  height: ${(props: any) => props.height || "56px"};
  font-family: montserrat;
  font-size: 16px;
  line-height: ${(props: any) => props.height || "56px"};
  color: ${(props: any) =>
    props.selected ? colors.black : props.isDisabled ? "#ADADAD" : "#484C53"};
  cursor: ${(props: any) => (props.isHover ? "default" : "pointer")};
  font-weight: ${(props: any) => (props.selected ? 600 : 0)};
  outline: 0;
  &:hover {
    color: ${(props: any) =>
      props.isDisabled ? "#ADADAD" : props.isHover ? "#212528" : "#04a9f5"};
    cursor: ${(props: any) => props.isDisabled && "not-allowed"};
    text-decoration: ${(props: any) => props.isExternalLink && "underline"};
  }
`

export const MainMenuRoot = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #cccccc;
  width: ${(props: any) => (props.width ? `${props.width}px` : "80%")};
  max-width: 250px;
  background-color: #f6f7f8;
  overflow: auto;
  user-select: none;
  ${hidden}
  margin-top: ${(props: any) => props.offset}px;
  height: calc(
    100vh ${(props: any) => (props.offset ? " - " + props.offset + "px" : "")}
  );
  overflow: auto;
  position: fixed;
  left: ${(props: any) =>
    props.width || (!props.width && props.isOpen) ? "0" : "min(80%, -300px)"};
  z-index: 10;
  transition: left 0.4s;
`

export const Divider = styled.hr`
  height: 1px;
  background-color: #d7d7d7;
  border-width: 0;
  margin-bottom: 6px;
`

export const Badge = styled.div`
  display: table-cell;
  vertical-align: middle;
  background: #87bc30;
  border: 1px solid #87bc30;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 3px 10px;
`

export const HeaderGradient = styled.div`
  height: 48px;
  background: radial-gradient(
    100.68% 275.48% at 1.14% 98.12%,
    #ff04a1 0%,
    rgba(0, 98, 217, 0.964108) 100%
  );
`

export const MenuItemsContainer = styled.div`
  padding-left: 20px;
`

export const UserInfo = styled.div`
  padding: 32px 11px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const UserNameContainer = styled.div`
  text-align: center;
  cursor: pointer;
  padding: 4px 0;
`

export const UserName = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 1.4;
  text-align: center;
  word-break: break-word;
`

export const CompanyName = styled.div`
  text-align: center;
  font-size: 15px;
  line-height: 1.4;
  word-break: break-word;
`

export const UserIcon = styled.i`
  position: absolute;
  left: 50%;
  top: 0;
  padding: 0 1px;
  background-color: ${colors.white};
  border-radius: 99px;
  font-size: 48.5px;
  transform: translate(-50%, -50%);
  color: #6563d3;
  border: 1px solid ${colors.white};
  text-align: center;
`

export const MainMenuFooter = styled.div`
  height: 166px;
  background-color: ${colors.white};
  border-top: 0.5px solid #d6d6d6;
`

export const UserMenuButton = styled.div<{ selected?: boolean }>`
  &.bp5-menu-item {
    font-size: 16px;
    padding: 8px 48px;
    cursor: pointer;
    align-items: center;
    a {
      color: #484c53;
      font-weight: ${({ selected }) => selected && "700"};
    }
    i {
      text-align: center;
      width: 28px;
      color: ${colors.gray40};
    }
    &:hover {
      background-color: ${colors.white};
      i,
      a {
        color: ${colors.link};
      }
      a {
        text-decoration: none;
      }
    }
  }
`

export const Logo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const LogoImg = styled.img<any>`
  height: ${(props: any) => (props.isCustom ? "100%" : "26px")};
  object-fit: contain;
`

export const LinkIcon = styled.i`
  font-size: 12px;
`

export const MenuBackDrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9;
`

export const MenuItemContainer = styled.div`
  width: "fit-content";
`

export const MenuGroup = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 700;
`

export const EmailTooltip = styled.p`
  word-wrap: break-word;
`
