import * as T from "../types"
import * as ACTION from "./ActionTypes"

export const setMarketplaceJson = (data: T.tMarketplaceState) => ({
  type: ACTION.SET_MARKETPLACE_JSON,
  payload: data,
})

export const setMarketplaceStatus = (status: T.tStatus) => ({
  type: ACTION.SET_MARKETPLACE_STATUS,
  payload: status,
})
