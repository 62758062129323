import {
  cancelCustomForm,
  updateCustomValues,
} from "@src/Actions/PolicyActions"
import * as ACTION from "@src/Actions/PolicyActions"
import Waiting from "@src/Components/Waiting"
import C from "@src/Controller"
import {
  clearCustomPolicy,
  downloadPolicy,
  expandPolicyText,
  newCustomPolicy,
} from "@src/ControllerModules/Compliance"
import { ISecurityPolicy, tState } from "@src/Model/Model"
import * as T from "@src/types"
import { PolicyListItem } from "@zeguro/schema-validator/dist/types/coco/securityPolicy/combined"
import * as _ from "lodash"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import { v4 as uuid } from "uuid"

import Policy from "../Policy"

interface IPolicyParams {
  id?: string
  version?: string
}

const PolicyContainer = () => {
  const history = useHistory()
  const match = useRouteMatch<IPolicyParams>()
  const dispatch = useDispatch()

  const {
    entry,
    customDialog,
    policyText,
    initialCustomValues,
    storageList,
    request,
    historyOpen,
    list,
    outOfBoxList,
  } = useSelector((store: tState) => store.policy)
  const { companyProfile } = useSelector((store: tState) => store.portal)

  const [listLoaded, setListLoaded] = React.useState(false)

  const { id: idParam, version: versionParam } = match.params

  const policySaving =
    request.target === "policySave" && request.status === "LOADING"
  const policyDeleting =
    request.target === "policyDelete" && request.status === "LOADING"

  const initParamCnt = (entry: ISecurityPolicy | null) => {
    if (entry) {
      const paramCnt = _.entries(entry.customValues).reduce(
        (accum: [number, number], e: [string, string]) => {
          const totalNum = accum[0]
          const totalUnintialized = accum[1]
          const newValue = [
            totalNum + 1,
            e[1] || e[1] === "" ? totalUnintialized : totalUnintialized + 1,
          ]
          return newValue
        },
        [0, 0],
      )
      return [...paramCnt]
    } else {
      return [0, 0]
    }
  }

  const initPolicy = (entry: ISecurityPolicy | null) => {
    if (entry) {
      // Populalate parameters from company profile
      const policy = _.cloneDeep(entry)
      if (
        companyProfile &&
        policy.customValues.Approved_Collab_Tools === null
      ) {
        policy.customValues.Approved_Collab_Tools =
          companyProfile.cloudStoragePlatforms || null
      }
      return policy
    } else {
      return null
    }
  }

  const paramCnt = initParamCnt(entry)
  const policy = initPolicy(entry)
  const policyList = list

  const isLastActiveVersion = (id: string, version: number) =>
    policyList.some((e: PolicyListItem) => {
      return e.id === id && e.version === version && e.status === "Active"
    })

  const hasDraft = (id: string) =>
    policyList.some((e: PolicyListItem) => {
      return e.id === id && e.status === "Draft"
    })

  const outOfBoxPolicyIds = outOfBoxList.reduce((acc: any, e) => {
    acc[e[0]] = true
    return acc
  }, {})

  React.useEffect(() => {
    if (
      policy &&
      request.target === "policySave" &&
      request.status === "SUCCESS"
    ) {
      const version =
        policy?.status === "Active" || policy?.version === 0
          ? policy.version + 1
          : policy.version
      history.push(`${T.ViewTypes.compliance}/${policy.id}/${version}`)
    }
    if (request.target === "list" && request.status === "SUCCESS") {
      setListLoaded(true)
    }
    if (
      (request.target === "policySave" || request.target === "policyDelete") &&
      request.status === "SUCCESS"
    ) {
      history.push(T.ViewTypes.compliance)
    }
  }, [request.target, request.status])

  React.useEffect(() => {
    C.heapTrack("Viewed Security Policy Home")
    setListLoaded(false)
    clearCustomPolicy()
    loadPolicyList()
  }, [])

  React.useEffect(() => {
    if (idParam && listLoaded) {
      const listPolicy = list.reduce((acc: PolicyListItem | undefined, cur) => {
        if (cur.id === idParam && (!acc || cur.version > acc.version)) {
          return cur
        }
        return acc
      }, undefined)
      const lastVersion =
        (listPolicy &&
          (hasDraft(idParam)
            ? listPolicy?.version - 1
            : listPolicy?.version)) ||
        0
      // When a user navigates directly yo a policy page without a version, use the last version
      const versionNumber = Number(versionParam) || lastVersion
      if (
        listPolicy?.category !== "custom" &&
        listPolicy &&
        versionNumber === listPolicy.version + 1
      ) {
        onNewCustomPolicy(
          idParam,
          listPolicy?.name || decodeURIComponent(idParam),
          versionNumber,
        )
      } else if (!listPolicy && versionNumber === 0) {
        const CustomPolicyId = `CustomPolicy-${uuid()}`
        onNewCustomPolicy(CustomPolicyId, decodeURIComponent(idParam), 1)
      } else if (
        listPolicy &&
        (versionNumber > 0 ||
          (versionNumber === 0 && listPolicy.version === 0)) &&
        listPolicy.version >= versionNumber
      ) {
        loadPolicy(
          idParam,
          versionNumber,
          !!versionNumber && listPolicy.version !== versionNumber,
        )
      } else {
        history.push("/404")
      }
    }
  }, [listLoaded, idParam, versionParam])

  const onCustomParam = (
    policy: ISecurityPolicy,
    params: any,
    condition: string,
  ) => {
    const customValues = { ...policy.customValues }
    _.forEach(params, (v, k) => {
      customValues[k] = v
      if (k === condition) {
        if (!v) {
          delete customValues[k]
        }
      }
    })

    dispatch(updateCustomValues(customValues))

    const policyCustomText = expandPolicyText(
      policy.name,
      policy.approvalDate || "",
      customValues,
      policy.template,
      {},
      "custom",
    )

    dispatch(ACTION.updatePolicyText(policyCustomText))
  }

  const onCancelCustomForm = () => {
    dispatch(cancelCustomForm())
  }

  const onDelete = (policy: ISecurityPolicy) => {
    dispatch(ACTION.requestDeletePolicy(policy.id, policy.version))
  }

  const onDownload = (policy: ISecurityPolicy) => {
    downloadPolicy(policy.id, policy.version)
  }

  const loadPolicyList = () => {
    dispatch(ACTION.requestList())
  }

  const loadPolicy = (id: string, version: number, lockEdit?: boolean) => {
    dispatch(ACTION.requestPolicy({ id, version, lockEdit }))
  }

  const onApprove = (policy: ISecurityPolicy) => {
    const p = _.cloneDeep(policy)
    dispatch(ACTION.requestSavePolicy(p, "approve"))
  }

  const onSaveDraft = (policy: any) => {
    const p = _.cloneDeep(policy)
    dispatch(ACTION.requestSavePolicy(p, "save"))
  }

  const onNewCustomPolicy = (
    policyId: string,
    policyName: string,
    version: number,
  ) => {
    newCustomPolicy(policyId, policyName, version)
  }

  if (!listLoaded || !policy?.name) {
    return <Waiting text="Loading your Security Policy." />
  }

  if (policyDeleting) {
    return <Waiting text="Deleting your Security Policy." />
  }

  if (policySaving) {
    return <Waiting text="Saving your Security Policy." />
  }

  return (
    <Policy
      policy={policy}
      hasDraft={hasDraft(policy.id)}
      storageList={storageList}
      policyText={policyText}
      customDialog={customDialog}
      numParamsEmpty={paramCnt[1]}
      initialCustomValues={initialCustomValues}
      historyOpen={historyOpen}
      outOfBoxPolicyIds={outOfBoxPolicyIds}
      isLastActiveVersion={isLastActiveVersion(policy.id, policy.version)}
      onBackToList={() => history.push(T.ViewTypes.compliance)}
      onDelete={onDelete}
      onApprove={onApprove}
      onSaveDraft={onSaveDraft}
      onCustomParam={onCustomParam}
      onCancelCustomForm={onCancelCustomForm}
      onDownload={onDownload}
    />
  )
}

export default PolicyContainer
