import { colors } from "@src/theme"

export const defaultCourseIcon = "images/training/default_course.svg"

export const GRADE_COLOR: Record<string, string> = {
  A: colors.success,
  B: colors.success,
  C: colors.orange,
  D: colors.orange,
  E: colors.error,
  F: colors.error,
  PENDING: colors.gray20,
}

export const TOOLTIP_INFO_COURSES: Record<string, string[]> = {
  "Knowledge Assessment": [
    "Every team member enrolled in the Cybersecurity Course Series will start with a Knowledge Assessment to determine their current cybersecurity awareness.",
    "After completing the assessment, one module of the Beginner course will be assigned per month. Courses will be assigned based on their assessment results.",
  ],
  Beginner: [
    "Every team member enrolled in the Cybersecurity Course Series will start with a Knowledge Assessment to determine their current cybersecurity awareness.",
    "After completing the assessment, one module of the Beginner course will be assigned per month. Courses will be assigned based on their assessment results.",
  ],
  Intermediate: [
    "After completing the beginner course, one module of the intermediate course will be assigned per month.",
  ],
  Advanced: [
    "After completing the intermediate course, one module of the advanced course will be assigned per month.",
  ],
}

export const TRAINING_TABS = [
  { id: "reportCard", text: "Report Cards", path: "reportcards" },
  { id: "manageTrainee", text: "Team Member Enrollment", path: "members" },
  { id: "courseLibrary", text: "Course Library", path: "library" },
]
export const TRAINING_TABS_WITHOUT_TRAINEES = [
  { id: "getStarted", text: "Get Started", path: "getstarted" },
  { id: "courseLibrary", text: "Course Library", path: "library" },
]
