import { Heading } from "@src/Components/Heading"
import { Link } from "@src/Components/Link"
import { Text } from "@src/Components/Text"
import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import * as React from "react"
import { useSelector } from "react-redux"

import { ErrorPageContainer } from "./styles"

const ErrorPageUsedLink = () => {
  const { emailDomain } = useSelector((state: tState) => state.brand)
  return (
    <ErrorPageContainer>
      <img src="/images/laptop-error-screen.svg" alt="error-screen" />
      <Heading variant="h1" margin="25px 0 0 0">
        Oops!
      </Heading>
      <Heading variant="h3" margin="0">
        The link is not valid. Check it and try again.
      </Heading>
      <Text
        fontSize="21px"
        color={colors.black50}
        lineHeight="32px"
        margin="15px 0 0 0"
      >
        Contact the person who invited you <br /> and ask them to resend your
        invitation.
      </Text>
      <Text
        fontSize="18px"
        color={colors.black50}
        lineHeight="22px"
        margin="15px 0 0 0"
      >
        Still having trouble? Email us at{" "}
        <Link href={`mailto:support@${emailDomain}`}>
          support@{emailDomain}
        </Link>
        .
      </Text>
    </ErrorPageContainer>
  )
}

export default ErrorPageUsedLink
