import { initialBrandState } from "@src/Actions/Reducers/BrandReducer"
import { initialCustomerPortalDataState } from "@src/Actions/Reducers/CustomerPortalDataReducer"
import { initialCyberConciergeState } from "@src/Actions/Reducers/CyberConciergeReducer"
import { initialModalsState } from "@src/Actions/Reducers/GlobalModalsReducer"
import { initialMonitoringState } from "@src/Actions/Reducers/MonitoringReducer"
import { initialZimmState } from "@src/Actions/Reducers/ZimmReducer"
import { CustomerPortalData } from "@zeguro/schema-validator/dist/types/coco/core/combined"
import {
  PolicyListItem,
  SecurityPolicyData,
} from "@zeguro/schema-validator/dist/types/coco/securityPolicy/combined"
import { LoadTrainingDataResponse } from "@zeguro/schema-validator/dist/types/coco/training/combined"

import * as T from "../types"
import {
  IBasicState,
  IGetAssessmentData,
  IGetConfig,
  IGetCyberConciergeAlert,
  IGetCyberConciergeAlerts,
  IGetCyberConciergeConfig,
  IRatingsData,
} from "../types"

export interface ISecurityPolicy extends SecurityPolicyData {
  text: string
}

export type tCustomValues = { [key: string]: any }
export type tCustomDialog = tCustomText | tCustomCheckbox | tCustomRadio

export type tCustomText = {
  id: string
  caption: string
  value: string
}

export type tCustomCheckbox = {
  id: string
  caption: string
  value: boolean
}

export type tCustomRadio = {
  id: string
  caption: string
  options: string[]
  value: number
}

///////////////////////////////////////////////////////////////////

export type tSupportedPolicy = {
  id: string
  name: string
}

export type tPortalState = {
  config: T.tConfig
  userPool: {
    ClientId: string
    UserPoolId: string
  }
  accountsPageMode: string
  isProduction: boolean
  productionMode: string
  authenticated: boolean | null
  isZeguroServiceSession: boolean
  cognitoId: string | null
  orgId: string | null
  companyProfile: T.tCompanyProfile
  userProfile: T.tUserProfile
  userList: T.tUserList | null
  userInviteList: T.tUserInviteList | null
  userListError: string | null
  userInviteListError: string | null
  companyProfileState: T.tStatus
  height: number
  width: number
  portalMenu: string
  environment: any
  disclaimer: string
  magicLinkSignup: T.tMagicLinkSignupFetchResponse
  dashboard: T.tDashboard
  maintenance: T.IMaintenance | null
  signupData?: T.ISignupData
}

export type IHistoryRecord = {
  version: number
  approver: string
  ts: string
}

export type tComplianceState = {
  request: T.IRequestStatus
  list: PolicyListItem[]
  entry: ISecurityPolicy | null
  policyText: string
  versionOpen: boolean
  historyOpen: boolean
  historyPolicyType: string
  historyPolicyName: string
  historyList: IHistoryRecord[]
  customDialog: { id: string; offset: number } | null
  initialCustomValues: any
  version?: ISecurityPolicy
  storageList: string[]
  outOfBoxList: string[][]
}

export type tInsuranceState = {
  module: string
  termsOfService: string
  generateInsuranceCOIStatus: string
  insuranceLoadStatus: string
  plaidLink: string
  plaidLinkStatus: string
  achPaymentRequestStatus: string
  chargeRequestStatus: string
  transactionsRequestStatus: string
  policyDocsStatus: string
}

export type tTrainingState = {
  target: string | null
  status: string
  trainingData: LoadTrainingDataResponse
}

export type tRatingsState = {
  status: string
  ratingsData: IRatingsData | any
}

export interface IGetTargetList extends IBasicState {
  data: T.IProbelyTarget[]
}
export interface IGetScheduleMap extends IBasicState {
  data: { [targetId: string]: T.IProbelySchedule[] }
}
export interface IGetScanMap extends IBasicState {
  data: { [targetId: string]: T.IProbelyScan[] }
}

export interface ICheckWebAppUrl extends IBasicState {
  data: { inAlexaTopSites?: boolean; usedInTargets?: boolean }
}

export interface IGetCustomerPortalDataState extends IBasicState {
  data: CustomerPortalData["data"] | null
}

export interface IMonitoringState {
  createTarget: IBasicState
  editTarget: IBasicState
  deleteTarget: IBasicState
  createScheduleScan: IBasicState
  updateScheduleScan: IBasicState
  editScheduleScan: IBasicState
  cancelScan: IBasicState
  syncScans: IBasicState
  getTargetList: IGetTargetList
  getScheduleMap: IGetScheduleMap
  getScanMap: IGetScanMap
  getScanReport: IBasicState
  checkAppUrl: ICheckWebAppUrl
  toggleModal: {
    isWebScanCancelConfirm: boolean
    isAddAppModal: boolean
    isCloseAddAppConfirm: boolean
    isEditAppModal: boolean
    isEditScheduleModal: boolean
    isDeleteAppConfirm: boolean
  }
}

export type tSubscriptionIdState = {
  subscriptionIdState:
    | "expired"
    | "invalid"
    | "valid"
    | "loading"
    | "failed"
    | "ready"
  uiRules: {
    isTrialDisabled?: boolean
    marketingLogoPrimary?: string
    marketingLogoSecondary?: string
    marketingName?: string
  }
  channelId: string
  planId: string
  subscriptionId: string
}

export type tZimmState = {
  getConfig: IGetConfig
  getAssessmentData: IGetAssessmentData
  saveAssessmentData: IBasicState
  removeAssessmentData: IBasicState
}

export type tCyberConciergeState = {
  getCyberConciergeConfig: IGetCyberConciergeConfig
  getCyberConciergeAlert: IGetCyberConciergeAlert
  getCyberConciergeAlerts: IGetCyberConciergeAlerts
}

export interface tBrandState extends IBasicState {
  brandId: string
  brandLogo: string
  brandNameLong: string
  brandNameShort: string
  webDomain: string
  conciergeEmail: string
  conciergeSchedulingLink: string
  helpCenter: string
  emailDomain: string
  marketingCollateral: string
}

export type tCustomerPortalDataState = {
  data: CustomerPortalData["data"] | null
  getCustomerPortalData: IBasicState
  updateCustomerPortalData: IBasicState
}

export type tFeatures = Required<
  Required<CustomerPortalData>["data"]
>["introViewed"]

export type tGlobalModals = {
  introModalState: {
    currentPage: number
    openedFeature: keyof tFeatures | null
  }
  globalModalState: {
    cybersecurityReleaseNotes: boolean
    isCybersecurityRoadBlock: boolean
    isContactCyberConcierge: boolean
  }
}

export interface tSendResetPasswordMagicLink extends IBasicState {
  email?: string
}

export interface tCheckResetPasswordMagicLink extends IBasicState {
  isValidLink?: boolean
}
export interface tGetInviteLink extends IBasicState {
  data?: any
}

export interface tInviteLinkLookup extends IBasicState {
  data: { linkId: string } | null
}

export type tAccountsState = {
  sendResetPasswordMagicLink: tSendResetPasswordMagicLink
  checkResetPasswordMagicLink: tCheckResetPasswordMagicLink
  getInviteLink: tGetInviteLink
  inviteLinkSignup: IBasicState
  inviteLinkLookup: tInviteLinkLookup
  resetPassword: IBasicState
}

export type tState = {
  portal: tPortalState
  policy: tComplianceState
  training: tTrainingState
  ratings: tRatingsState
  monitoring: IMonitoringState
  plans: tPlansState
  marketplace: tMarketplaceState
  subscriptionId: tSubscriptionIdState
  zimm: tZimmState
  globalModals: tGlobalModals
  customerPortalData: tCustomerPortalDataState
  account: tAccountsState
  cyberConcierge: tCyberConciergeState
  brand: tBrandState
}

export type tIntroState = {
  viewed: { [key: string]: boolean }
  welcomeEmailSent?: boolean
  currentPage: number
  openedFeature: string | null
  force?: boolean
}

export const initialSubscriptionIdState: tSubscriptionIdState = {
  subscriptionIdState: "ready",
  uiRules: { isTrialDisabled: false },
  channelId: "",
  planId: "",
  subscriptionId: "",
}

export const initialAccountsState: tAccountsState = {
  sendResetPasswordMagicLink: {
    isLoading: false,
    error: undefined,
    success: false,
    email: undefined,
  },
  inviteLinkSignup: {
    error: undefined,
    isLoading: false,
    message: "",
    success: false,
  },
  inviteLinkLookup: {
    error: undefined,
    isLoading: false,
    message: "",
    success: false,
    data: null,
  },
  getInviteLink: {
    error: undefined,
    isLoading: false,
    message: "",
    success: false,
    data: null,
  },
  checkResetPasswordMagicLink: {
    isLoading: false,
    error: undefined,
    success: false,
    isValidLink: undefined,
  },
  resetPassword: {
    isLoading: false,
    error: undefined,
    success: false,
  },
}

const initial_state: tState = {
  plans: {
    subscribedPlan: {
      planName: "",
      training: "NOT_AVAILABLE",
      trainingNumberOfSeats: 0,
      insurance: "NOT_AVAILABLE",
      securityPolicies: "NOT_AVAILABLE",
      securityPoliciesSignOff: "NOT_AVAILABLE",
      cybermaturity: "NOT_AVAILABLE",
      probely: "NOT_AVAILABLE",
      probelyNumberOfTargets: 0,
      probelyScanFrequency: undefined,
      probelyScanProfile: undefined,
    },
    status: "READY",
    eligiblePlansStatus: "LOADING",
    updatePlansStatus: "READY",
    training: "NOT_AVAILABLE",
    trainingNumberOfSeats: null,
    insurance: "NOT_AVAILABLE",
    securityPolicies: "NOT_AVAILABLE",
    securityPoliciesSignOff: "NOT_AVAILABLE",
    probely: "NOT_AVAILABLE",
    probelyNumberOfTargets: null,
    probelyScanFrequency: null,
    probelyScanProfile: null,
    subscriptionStatus: "INTRIAL",
    subscriptionDate: "",
    expirationDate: "",
    channelObject: { eligiblePlans: [] },
    signUpDate: "",
    modules: {
      cybermaturity: "NOT_AVAILABLE",
      compliance: "NOT_AVAILABLE",
      training: "NOT_AVAILABLE",
      monitoring: "NOT_AVAILABLE",
      insurance: "NOT_AVAILABLE",
      dashboard: "NOT_AVAILABLE",
      marketplace: "NOT_AVAILABLE",
      ratings: "NOT_AVAILABLE",
      cyberconcierge: "ACTIVE",
    },
    chargebeeSubscriptionStatus: false,
  },
  subscriptionId: initialSubscriptionIdState,
  marketplace: { data: [], status: "READY" },
  training: {
    target: null,
    status: "LOADING",
    trainingData: {
      catalogMap: [],
      courseMap: [],
      traineesData: [],
      ts: "",
    },
  },
  ratings: {
    status: "LOADING",
    ratingsData: null,
  },
  monitoring: initialMonitoringState,
  portal: {
    config: {
      stripeApiKey: "",
      environmentName: "",
      sessionInactivityTimeout: 0,
      securityPolicyConfig: {},
      chargebeeSite: "",
    },
    userPool: {
      ClientId: "",
      UserPoolId: "",
    },
    accountsPageMode: "login",
    isProduction: true,
    productionMode: "",
    authenticated: null,
    isZeguroServiceSession: false,
    cognitoId: null,
    orgId: null,
    companyProfile: {
      companyName: "",
      companyUrl: "",
    },
    userProfile: {
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
    },
    userList: null,
    userListError: null,
    userInviteList: null,
    userInviteListError: null,
    companyProfileState: "READY",
    portalMenu: "dashboard",
    environment: {},
    disclaimer: "",
    height: 0,
    width: 0,
    magicLinkSignup: {
      createdFor: "",
      expirationDate: "",
      updateStatus: "READY",
      fetchStatus: "READY",
      signupStatus: "READY",
    },
    dashboard: {
      status: "READY",
      monitoringStatus: "READY",
    },
    maintenance: null,
  },
  policy: {
    entry: null,
    request: {
      target: null,
      status: "READY",
    },
    policyText: "ERROR LOADING TEMPLATE",
    historyOpen: false,
    historyPolicyType: "",
    historyPolicyName: "",
    historyList: [],
    versionOpen: false,
    list: [],
    customDialog: null,
    initialCustomValues: null,
    storageList: [],
    outOfBoxList: [],
  },
  zimm: initialZimmState,
  globalModals: initialModalsState,
  customerPortalData: initialCustomerPortalDataState,
  account: initialAccountsState,
  cyberConcierge: initialCyberConciergeState,
  brand: initialBrandState,
}

export const basicInitialState: IBasicState = {
  error: undefined,
  isLoading: false,
  message: "",
  success: false,
}

export type tPlansState = T.tPlansState

export type tMarketplaceState = T.tMarketplaceState

export default initial_state
