import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { tState } from "@src/Model/Model"
import { ICategory } from "@src/Portal/Zimm/Assessment"
import { colors } from "@src/theme"
import {
  CyberMaturityCatalog as ICyberMaturityCatalog,
  CyberMaturityOrgData as ICyberMaturityOrgData,
} from "@zeguro/schema-validator/dist/types/coco/cyberMaturity/combined"
import * as React from "react"
import { useSelector } from "react-redux"
import Select, { StylesConfig } from "react-select"

import { CATEGORY_ICONS } from "../constants"
import {
  ControlPanel,
  GroupIcon,
  GroupItem,
  MenuItem,
  MenuItemIcon,
  MenuItemText,
  QuestionNumber,
  QuestionSelectorStyled,
} from "./styles"

interface IProps {
  categories: ICategory[]
  config: ICyberMaturityCatalog["elements"]
  response?: ICyberMaturityOrgData["response"]
  curOption: string
  goToQuestion: (element: string) => void
}

interface ISelectorOption {
  label: JSX.Element
  value: string
}

type MyOptionType = {
  label: React.ReactNode
  value: string
}

const customStyles: StylesConfig<MyOptionType, false> = {
  control: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
  singleValue: (provided) => ({
    ...provided,
    width: "100%",
  }),
  indicatorSeparator: (provided) => ({ ...provided, width: "0" }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(0, 98, 217, 0.1)" : colors.white,
    "&:hover": {
      backgroundColor: state.isSelected
        ? "rgba(0, 98, 217, 0.1)"
        : "rgba(133, 133, 133, 0.1)",
    },
    color: colors.black,
  }),
  menu: (provided) => ({
    ...provided,
    width: "420px",
    maxWidth: "calc(100vw - 48px)",
    right: 0,
  }),
  groupHeading: (provided) => ({ ...provided, paddingLeft: "6px" }),
}
const QuestionSelector = ({
  categories,
  config,
  response,
  curOption,
  goToQuestion,
}: IProps) => {
  const isLoading = useSelector(
    (state: tState) => state.zimm.getAssessmentData.additionalLoading,
  )
  const { isDesktop } = usePortalSize()
  const isQuestionAnswered = (id: string) =>
    !!response?.questions?.find((question) => question.questionId === id)

  const getCategoryIcon = (cat: string) =>
    CATEGORY_ICONS.find(({ name }) => name === cat)?.icon

  const options: ISelectorOption[] = React.useMemo(
    () =>
      categories.reduce((acc, category) => {
        const categoryName = category.elementCategory
        const a = [
          {
            label: (
              <GroupItem>
                <GroupIcon>
                  <img
                    src={`/images/zimm/${getCategoryIcon(categoryName)}`}
                    alt={categoryName}
                  />
                </GroupIcon>
                {categoryName}
              </GroupItem>
            ),
            value: categoryName,
          },
          ...config
            .filter((e) => category.elementCategory === e.elementCategory)
            .map((e, optionIndex) => {
              const isAnswered = isQuestionAnswered(e.question.questionId)
              return {
                label: (
                  <MenuItem
                    answered={!!isAnswered}
                    id={`cm-questions-selector-option-${optionIndex}`}
                  >
                    <QuestionNumber>
                      {category.startIndex + optionIndex}
                    </QuestionNumber>
                    <MenuItemText>{e.elementName}</MenuItemText>
                    <ControlPanel>
                      <MenuItemIcon
                        className={
                          isAnswered
                            ? "fas fa-check-circle"
                            : "far fa-check-circle"
                        }
                        color={isAnswered ? "darkgreen" : "#ccc"}
                      />
                    </ControlPanel>
                  </MenuItem>
                ),
                value: e.elementName,
              }
            }),
        ]
        return [...acc, ...a]
      }, []),
    [config, categories, response],
  )

  const value = options.find(({ value }) => value === curOption)

  return (
    <QuestionSelectorStyled>
      {isDesktop && <span>Questions:</span>}
      <Select
        id="cm-questions-selector"
        className="react-select-container"
        isSearchable={false}
        maxMenuHeight={500}
        options={options}
        isDisabled={isLoading}
        onChange={(item) => item && goToQuestion(item.value)}
        value={value}
        styles={customStyles}
      />
    </QuestionSelectorStyled>
  )
}

export default QuestionSelector
