import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Section } from "@src/Components/StyledUtils"
import { colors } from "@src/theme"
import * as React from "react"

import { Description, SubHeading } from "../../styles"

export const EmptyStateCard = () => {
  const { isLargeScreen } = usePortalSize()

  return (
    <>
      <SubHeading maxWidth={isLargeScreen ? "400px" : undefined}>
        Talk with an expert about your cybersecurity needs.
      </SubHeading>
      <Description>
        <div>Get live help from a trusted advisor.</div>
        <Section fontColor={colors.gray40}>
          Included with your subscription
        </Section>
      </Description>
    </>
  )
}
