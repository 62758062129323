import { usePortalSize } from "@src/Components/Common/usePortalSize"
import * as React from "react"

import { Description, SubHeading } from "../styles"

export const EmptyStateCard = () => {
  const { isLargeScreen } = usePortalSize()

  return (
    <>
      <SubHeading maxWidth={isLargeScreen ? "400px" : undefined}>
        Analyze and strengthen your security strategies.
      </SubHeading>
      <Description>
        Identify opportunities for improving your organization’s security
        posture. Learn how to remedy issues that we help uncover.
      </Description>
    </>
  )
}
