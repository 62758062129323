import { Checkbox, InputGroup, Radio, Slider } from "@blueprintjs/core"
import * as _ from "lodash"
import * as Mustache from "mustache"
import * as React from "react"
import styled from "styled-components"

import { ButtonCancel, ButtonSmall } from "../../Components/Button"
import C from "../../Controller"
import { ISecurityPolicy } from "../../Model/Model"

interface ICustomDialog {
  hidden?: boolean
  width?: number
  offset: number
  customId: string
  policy: ISecurityPolicy
  storageList?: string[]
  onCustomParam: (
    policy: ISecurityPolicy,
    params: any,
    condition: string,
  ) => void
  onCancelCustomForm: () => void
}

type tCustomDialogState = {
  value: any
  userOptions: string[]
  userInput: string
  options: any
  categorySelected: string[]
  category: string[][]
}

class CustomDialog extends React.Component<ICustomDialog, tCustomDialogState> {
  public readonly state: tCustomDialogState = {
    value: [],
    userOptions: [],
    userInput: "",
    options: [],
    category: [],
    categorySelected: [],
  }

  constructor(props: ICustomDialog) {
    super(props)
    const policy = this.props.policy
    const customId = this.props.customId
    const customValues = policy.customValues[customId]
    const config = C.window.securityPolicyConfig[customId]

    let userOptions: string[] = []
    let category: string[][] = []

    const env: any = { ...C.getEnvironment() }
    const options = config.options
      ? typeof config.options === "function"
        ? config.options(this.props)
        : config.options.map((e: any) => {
            if (!e.text) {
              return e
            }
            const text = Mustache.render(e.text, env)
            return { ...e, text }
          })
      : null

    if (config.allowUserOptions) {
      if (customValues) {
        userOptions = customValues.filter(
          (v: string) => !_.includes(options, v),
        )
      } else {
        userOptions = []
      }
    }

    if (config.category) {
      const v1 = config.category[0].variable
      const v2 = config.category[1].variable
      category = [
        [...(policy.customValues[v1] || [])],
        [...(policy.customValues[v2] || [])],
      ]
    }

    this.state.value = customValues

    this.state.options = [...userOptions, ...(options || [])]
    this.state.category = category

    this.handleChange = this.handleChange.bind(this)
    this.handleCheckboxes = this.handleCheckboxes.bind(this)
    this.handleTextOptions_Checkbox = this.handleTextOptions_Checkbox.bind(this)
    this.handleTextOptions_Text = this.handleTextOptions_Text.bind(this)
    this.getControl = this.getControl.bind(this)
    this.handleUserInput = this.handleUserInput.bind(this)
    this.handleNamedField = this.handleNamedField.bind(this)
    this.handleSlider = this.handleSlider.bind(this)
    this.handleAddOption = this.handleAddOption.bind(this)
    this.handleRadio = this.handleRadio.bind(this)
    this.handleText = this.handleText.bind(this)
    this.moveUp = this.moveUp.bind(this)
    this.moveDown = this.moveDown.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.categoryMove = this.categoryMove.bind(this)
  }

  render() {
    if (this.props.hidden) {
      return null
    }

    const policy = this.props.policy
    const customId = this.props.customId
    const config = C.window.securityPolicyConfig[customId]

    const save = () => {
      const policy = this.props.policy
      const value = this.state.value
      const params: any = {}
      const condition = config.condition
      const category = config.category

      if (condition) {
        params[condition] = value === "Yes"
      }

      if (category) {
        params[category[0].variable] = this.state.category[0]
        params[category[1].variable] = this.state.category[1]
      }

      params[customId] = value

      this.props.onCustomParam(policy, params, condition)
    }

    const cancel = () => {
      this.props.onCancelCustomForm()
    }

    const env = C.getEnvironment()
    const title = Mustache.render(config.title || "", env)
    const description = Mustache.render(config.description || "", env)

    return (
      <CustomDialogRoot
        offset={this.props.offset}
        width={this.props.width}
        hidden={this.props.hidden}
      >
        <Content>
          <Caption dangerouslySetInnerHTML={{ __html: title }} />
          <Description dangerouslySetInnerHTML={{ __html: description }} />
          <Control>{this.getControl(config)}</Control>
          <ButtonPanel>
            <ButtonSmall
              width={100}
              onClick={save}
              id="security-policy-customize-field"
              data-security-policy-name={`${policy.name}`}
              data-custom-field-id={`${customId}`}
            >
              Customize
            </ButtonSmall>
            <ButtonCancel width={100} onClick={cancel}>
              Cancel
            </ButtonCancel>
          </ButtonPanel>
        </Content>
      </CustomDialogRoot>
    )
  }

  moveUp(idx: number) {
    const value = [...this.state.value]
    const e = value[idx - 1]
    value[idx - 1] = value[idx]
    value[idx] = e
    this.setState({ value })
  }

  moveDown(idx: number) {
    const value = [...this.state.value]
    const e = value[idx + 1]
    value[idx + 1] = value[idx]
    value[idx] = e
    this.setState({ value })
  }

  handleChange(event: any) {
    const input = event.target.value
    const value = input.trim().length > 0 ? input : null
    this.setState({ value })
  }

  handleSelect(categoryNum: number, event: any) {
    const value = event.target.value
    if (value) {
      const categorySelected = [...this.state.categorySelected]
      categorySelected[categoryNum] = value
      this.setState({ categorySelected })
    }
  }

  categoryMove(categoryNum: number) {
    const category = [...this.state.category]
    const value = this.state.categorySelected[categoryNum]
    if (value) {
      category[categoryNum] = category[categoryNum].filter((e) => e !== value)
      category[categoryNum === 0 ? 1 : 0].push(value)
      this.setState({ category })
      this.setState({ categorySelected: ["", ""] })
    }
  }

  handleNamedField(event: any) {
    const value = { ...this.state.value }
    value[event.target.name] = event.target.value
    this.setState({ value: value })
  }

  handleSlider(v: number) {
    this.setState({ value: v })
  }

  handleText(event: any) {
    const value = event.target.value
    const lines = value.length > 0 ? value.split("\n") : []
    this.setState({ value: lines })
  }

  handleUserInput(event: any) {
    const userInput = event.target.value
    this.setState({ userInput })
  }

  handleAddOption() {
    const options = [this.state.userInput, ...this.state.options]
    const value = [this.state.userInput, ...(this.state.value || [])]
    this.setState({ options, value, userInput: "" })
  }

  handleCheckboxes(event: any) {
    const checked = event.target.checked
    const value = event.target.value
    const stateValue = this.state.value || []
    const state = [...stateValue]

    if (checked) {
      state.push(value)
    } else {
      _.remove(state, (e) => e === value)
    }
    this.setState({ value: state.length > 0 ? state : null })
  }

  handleTextOptions_Checkbox(cbId: string) {
    const v = _.cloneDeep(this.state.value).filter(
      (e: any) => !(e && e.id === cbId),
    )

    if (v.length !== this.state.value.length) {
      // Option removed
      this.setState({ value: v })
      return
    }

    // Option not present
    const options = this.state.options

    const order = options.reduce((acc: any, e: any, n: number) => {
      acc[e.id] = n
      return acc
    }, {})

    const option_index = _.findIndex(options, (e: any) => e.id === cbId)
    const value = [...v, _.cloneDeep(options[option_index])]
    value.sort((a, b) => order[a.id] - order[b.id])
    this.setState({ value })
  }

  handleTextOptions_Text(cbId: string, event: any) {
    const value = _.cloneDeep(this.state.value)
    const index = _.findIndex(value, (e: any) => e && e.id === cbId)
    value[index].userText = event.target.value

    this.setState({ value })
  }

  handleRadio(event: any) {
    const value = event.target.value
    this.setState({ value })
  }

  number(config: any) {
    return (
      <span>
        <span>{config.caption || "Enter value: "}</span>
        <InputGroup
          value={this.state.value || ""}
          onChange={this.handleChange}
          type="number"
          style={{ height: 30, width: 200 }}
          name="text"
        />
      </span>
    )
  }

  slider() {
    return (
      <div style={{ width: "50%" }}>
        <Slider
          min={0}
          max={22}
          stepSize={1}
          labelStepSize={10}
          onChange={this.handleSlider}
          value={this.state.value}
          vertical={false}
        />
      </div>
    )
  }

  input(config: any) {
    return (
      <span>
        <span>{config.caption || "Enter value: "}</span>
        <InputWrapper width={config.width || 200}>
          <InputGroup
            value={this.state.value || ""}
            onChange={this.handleChange}
            name="text"
          />
        </InputWrapper>
      </span>
    )
  }

  text() {
    const stateValue = this.state.value || []
    return (
      <span>
        <textarea
          onChange={this.handleText}
          style={{ height: 150, width: "100%" }}
          value={stateValue.join("\n")}
        />
      </span>
    )
  }

  radio(config: any) {
    const options = this.state.options
    const items: any[] = []

    _.forEach(options, (v, k) => {
      let fmtVal = v

      if (config.formatMap) {
        fmtVal = config.formatMap[v]
      }

      items.push(
        <div key={k}>
          <Radio
            name="formRadio"
            value={v}
            checked={this.state.value === v}
            onChange={this.handleRadio}
          >
            {fmtVal}
          </Radio>
        </div>,
      )
    })
    return items
  }

  section(config: any) {
    const options = this.state.options
    const items: any[] = []

    _.forEach(options, (v, k) => {
      let fmtVal = v
      if (config.formatMap) {
        fmtVal = config.formatMap[v]
      }

      const value = this.state.value
      items.push(
        <div key={k}>
          <Radio
            name="formRadio"
            type="radio"
            value={v}
            checked={value === v || (!value && v === "No")}
            onChange={this.handleRadio}
          >
            {fmtVal}
          </Radio>
        </div>,
      )
    })
    return items
  }

  namedFields(config: any) {
    const configItems = config.items
    const values = this.state.value
    const items: any[] = []

    _.forEach(configItems, (k) => {
      const v = values[k]

      items.push(
        <div key={k}>
          <div
            style={{
              display: "inline-block",
              width: config.labelWidth,
              marginBottom: 5,
            }}
          >
            {k}
          </div>
          <div
            style={{
              display: "inline-block",
              height: 34,
            }}
          >
            <InputGroup
              name={k}
              value={v}
              style={{ height: 30, width: 200 }}
              onChange={this.handleNamedField}
            />
          </div>
        </div>,
      )
    })

    return items
  }

  sortedList(config: any) {
    const options = this.state.value
    const items: any[] = []

    options.forEach((v: string, k: number) => {
      let fmtVal = v

      if (config.formatMap) {
        fmtVal = config.formatMap[v]
      }

      const lastIdx = options.length - 1

      items.push(
        <tr key={k}>
          <td style={{ paddingRight: 20 }}>{fmtVal}</td>
          <td>
            <button
              disabled={k === 0}
              onClick={() => {
                this.moveUp(k)
              }}
            >
              &#8593;
            </button>
          </td>
          <td>
            <button
              disabled={k === lastIdx}
              onClick={() => {
                this.moveDown(k)
              }}
            >
              &#8595;
            </button>
          </td>
        </tr>,
      )
    })
    return (
      <table>
        <tbody>{items}</tbody>
      </table>
    )
  }

  checkboxes(config: any) {
    const checkboxes: any = []
    const userOptions = this.state.userOptions

    const options = this.state.options

    _.forEach(options, (v, k) => {
      let fmtVal = v

      if (config.formatMap) {
        fmtVal = config.formatMap[v]
      }

      const value = this.state.value
      checkboxes.push(
        <div key={k}>
          <Checkbox
            value={v}
            checked={_.includes(value, v) || _.includes(userOptions, v)}
            onChange={this.handleCheckboxes}
          >
            {fmtVal}
          </Checkbox>
        </div>,
      )
    })

    const height = config.formHeight
    let add
    if (config.allowUserOptions) {
      add = (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Other: </label>
          <div
            style={{
              width: "70%",
              margin: 10,
            }}
          >
            <InputGroup
              value={this.state.userInput}
              onInput={this.handleUserInput}
            />
          </div>
          <ButtonSmall width={100} onClick={this.handleAddOption}>
            Add
          </ButtonSmall>
        </div>
      )
    }

    return (
      <div>
        <div
          style={
            config.formHeight
              ? {
                  borderRadius: 5,
                  padding: 5,
                  border: "1px solid gray",
                }
              : undefined
          }
        >
          <div
            style={{
              height,
              overflow: height ? "auto" : undefined,
            }}
          >
            {checkboxes}
          </div>
        </div>
        <br />
        {add}
      </div>
    )
  }

  classificator(config: any) {
    const category = this.state.category

    const options1: any = [<option key="-1" style={{ display: "none" }} />]
    const options2: any = [<option key="-1" style={{ display: "none" }} />]

    _.forEach(category[0], (e, k) => {
      options1.push(<option key={k}>{e}</option>)
    })

    _.forEach(category[1], (e, k) => {
      options2.push(<option key={k}>{e}</option>)
    })

    return (
      <div style={{ display: "flex", overflow: "auto" }}>
        <div style={{ float: "left" }}>
          <div>
            <b>{config.category[0].name}</b>
          </div>
          <select
            value={this.state.categorySelected[0]}
            size={5}
            style={{ width: 300 }}
            onClick={this.handleSelect.bind(null, 0)}
          >
            {options1}
          </select>
        </div>
        <div
          style={{
            padding: 10,
            paddingTop: 30,
            float: "left",
            outline: "none",
          }}
        >
          <button onClick={this.categoryMove.bind(null, 0)}>{">>"}</button>
          <br />
          <button onClick={this.categoryMove.bind(null, 1)}>{"<<"}</button>
        </div>
        <div style={{ float: "left" }}>
          <div>
            <b>{config.category[1].name}</b>
          </div>
          <select
            value={this.state.categorySelected[1]}
            size={5}
            style={{ width: 300 }}
            onClick={this.handleSelect.bind(null, 1)}
          >
            {options2}
          </select>
        </div>
      </div>
    )
  }

  textOptions(config: any) {
    const checkboxes: any = []

    const options = this.state.options

    _.forEach(options, (v, k) => {
      let fmtVal = v.header

      if (config.formatMap) {
        fmtVal = config.formatMap[v.id]
      }

      const value = this.state.value
      const id = v.id
      const index = _.findIndex(
        this.state.value,
        (e: any) => e && e.id === v.id,
      )
      const checked = index === -1 ? false : true
      const disabled = !checked
      const userText = checked ? value[index].userText : ""

      checkboxes.push(
        <div key={k}>
          <Checkbox
            type="checkbox"
            data-index={k}
            value={v.id}
            checked={checked}
            onChange={this.handleTextOptions_Checkbox.bind(null, id)}
          >
            <label style={{ fontWeight: "bold" }}>{fmtVal}</label>
          </Checkbox>
          <span style={{ width: "100%" }}> {v.text}</span>
          <div>
            <textarea
              style={{
                width: "100%",
                height: 60,
                backgroundColor: disabled ? "#e5e5e5" : undefined,
              }}
              id={id}
              disabled={disabled}
              onChange={this.handleTextOptions_Text.bind(null, id)}
              value={userText}
            />
          </div>
        </div>,
      )
    })

    return <div>{checkboxes}</div>
  }

  getControl(config: any) {
    switch (config.type) {
      case "slider":
        return this.slider()
      case "number":
        return this.number(config)
      case "input":
        return this.input(config)
      case "checkboxes":
        return this.checkboxes(config)
      case "radio":
        return this.radio(config)
      case "sortedList":
        return this.sortedList(config)
      case "text":
        return this.text()
      case "namedFields":
        return this.namedFields(config)
      case "section":
        return this.section(config)
      case "textOptions":
        return this.textOptions(config)
      case "classificator":
        return this.classificator(config)
    }
  }
}

interface ICustomDilagRoot {
  hidden?: boolean
  offset: number
  width: number | undefined
}
interface IInputWrapper {
  width: number
}

const CustomDialogRoot = styled.div<ICustomDilagRoot>`
  position: fixed;
  left: 0px;
  top: 20px;
  height: 80%;
  display: ${(props) => (props.hidden ? "none" : null)};
  width: 100%;
  z-index: 100;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-family: proxima-nova, sans-serif;
  padding: 24px;
  margin: auto;
  width: 100%;
  max-width: 920px;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  max-height: 100%;
  overflow: scroll;
`

/////////////////////////////////////////////////////////////////

const Control = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

const ButtonPanel = styled.div`
  display: flex;
  width: 210px;
  justify-content: space-between;
`

const Caption = styled.div`
  font-weight: bold;
`

const InputWrapper = styled.div<IInputWrapper>`
  display: inline-block;
  height: 30px;
  width: ${({ width }) => `${width}px`};
  @media (max-width: 450px) {
    width: 100%;
  }
`

const Description = styled.div``

export default CustomDialog
