import Waiting from "@src/Components/Waiting"
import * as React from "react"
import { Suspense } from "react"
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"
const PolicyContainer = React.lazy(
  () => import("@src/Portal/SecurityPolicies/PolicyContainer"),
)
const PolicyListContainer = React.lazy(
  () => import("@src/Portal/SecurityPolicies/PolicyListContainer"),
)

export const SecurityPolicyRouter = ({ match }: RouteComponentProps) => (
  <Suspense fallback={<Waiting text="" />}>
    <Switch>
      <Route exact path={match.path} component={PolicyListContainer} />
      <Route
        exact
        path={[`${match.path}/:id`, `${match.path}/:id/:version`]}
        component={PolicyContainer}
      />
      <Redirect exact to="/404" />
    </Switch>
  </Suspense>
)
