import { FocusStyleManager } from "@blueprintjs/core"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { sagaMiddleware, store } from "@src/Controller"
import { watchCompliance } from "@src/ControllerModules/Compliance"
import { globalModalsWatcherSaga } from "@src/Sagas/GlobalModals/globalModalsSaga"
import monitoringWatcherSaga from "@src/Sagas/Monitoring/monitoringWatcherSaga"
import { zimmWatcherSaga } from "@src/Sagas/Zimm/zimmWatcherSaga"
import * as React from "react"
import * as ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Router } from "react-router-dom"

import history from "./browserHistory"
import { ErrorBoundary } from "./Portal/ErrorBoundary"
import Routes from "./Routes"
import { accountsWatcherSaga } from "./Sagas/Accounts/accountsWatcherSaga"
import { cyberConciergWatcherSaga } from "./Sagas/CyberConcierge/cyberConciergeSaga"
import customerPortalDataSaga from "./Sagas/Portal/customerPortalDataSaga"

FocusStyleManager.onlyShowFocusOnTabs()

Sentry.init({
  dsn: "https://88c83a39ad0943b09bc3e49754e89281@o931215.ingest.sentry.io/5880141",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

// browser compatibility check
try {
  const agent = navigator.userAgent
  const supportedBrowsers = ["Safari", "Chrome", "Edg"]
  const agentParts = agent.split(" ").concat(agent.split(";"))
  let supported = false
  for (let part of agentParts) {
    part = part.split("/")[0]
    // supported browsers that are NOT internet explorer
    if (supportedBrowsers.includes(part)) {
      supported = true
    }
    // handle/support IE 11
    if (part === "Trident") {
      // get version
      const version = parseInt(agent.substr(agent.indexOf("rv:") + 3, 2))
      if (version >= 11) {
        supported = true
      } else {
        supported = false
      }
      break
    }
  }
  if (!supported) {
    alert(`For an optimal experience, the following browsers are supported:
      Latest Chrome
      Latest Safari
      Latest Edge`)
  }
} catch (e) {
  console.error(e)
}

// Start asynchronous processes (sagas)
sagaMiddleware.run(watchCompliance)
sagaMiddleware.run(zimmWatcherSaga)
sagaMiddleware.run(monitoringWatcherSaga)
sagaMiddleware.run(customerPortalDataSaga)
sagaMiddleware.run(accountsWatcherSaga)
sagaMiddleware.run(globalModalsWatcherSaga)
sagaMiddleware.run(cyberConciergWatcherSaga)

const container = document.getElementById("portal")

if (module.hot && container) {
  module.hot.accept()
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Router>
    </Provider>,
    container,
  )
}
if (container) {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Router>
    </Provider>,
    container,
  )
}
