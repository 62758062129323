import { colors } from "@src/theme"
import styled from "styled-components"

export const ContactUs = styled.p`
  padding-top: 44px;
  font-size: 16px;
  margin-bottom: 0px;
  @media (max-width: 1000px) {
    padding: 24px 12px;
  }

  a {
    color: ${colors.link};
  }
`
export const TooltipText = styled.span`
  font-size: 16px;
`

export const LoadingPanel = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const LoadingText = styled.div`
  margin-left: 10px;
`
