import { colors } from "@src/theme"
import styled from "styled-components"

export const Content = styled.div`
  display: flex;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`

export const RightContent = styled.div`
  flex: 1 0 40%;
  width: 50%;
  background: ${colors.gray3};
  padding: 20px;

  @media (max-width: 1100px) {
    width: 100%;
    margin-top: 50px;
  }
`

export const LeftContent = styled.div`
  flex: 1 1 50%;
  padding: 20px 20px 0 0;
  width: 100%;
`
export const NoDataAvailable = styled.h3`
  padding-top: 60px;
`

export const Description = styled.p`
  margin: 0;
  flex: 3;
`

export const DescriptionTop = styled.p`
  margin-bottom: 20px;
`

export const TrendTitle = styled.h3`
  border-bottom: 1px solid ${colors.gray30};
  margin: 60px 0 20px;
  color: ${colors.darkBlue};
`

export const TrendTitleRight = styled(TrendTitle)`
  margin-top: 20px;
`

export const TrendImg = styled.img`
  width: 100%;
  padding: 10px;
`

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 10px;
  border-bottom: 1px solid ${colors.gray30};
`

export const ButtonWrap = styled.div`
  flex: 2;
  padding-right: 10px;
`

interface ICompanyUrl {
  companyUrl?: string
  color?: string
}

export const RatingHead = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 5px;
`

export const RatingTitle = styled.h2`
  margin: 0;
`

export const RatingSubTitle = styled.h3`
  margin-bottom: 10px;
`

export const RatingNumberWrap = styled.div<ICompanyUrl>`
  border: 1px solid ${colors.gray50};
  border-radius: 10px;
  flex-direction: column;
  padding: 10px;
  display: flex;
  filter: ${({ companyUrl }) => !companyUrl && "blur(4px)"};
`

export const RatingNumber = styled.p<ICompanyUrl>`
  color: ${({ color }) => color || colors.blue50};
  font-size: 32px;
  margin: 0;
  line-height: 26px;
  font-weight: 700;
  padding-bottom: 3px;
`

export const RatingText = styled.span`
  font-size: 9px;
  font-weight: 700;
  color: ${colors.gray50};
  white-space: nowrap;
`
