import { Section } from "@src/Components/StyledUtils"
import { colors } from "@src/theme"
import * as React from "react"

interface IFilledStateCard {
  numberOfAlerts: number
}

export const FilledStateCard = ({ numberOfAlerts }: IFilledStateCard) => {
  return (
    <>
      <Section
        fontColor={colors.darkBlue}
        fontSize="32px"
        fontWeight="700"
        margin="16 0 10"
      >
        {numberOfAlerts}
      </Section>
      <Section fontSize="16px" align="center">
        <div>{`Security Alert${numberOfAlerts > 1 ? "s" : ""}`}</div>
        <div>for you that may require your attention.</div>
      </Section>
    </>
  )
}
