import { PageHeader } from "@src/Components/PageHeader"
import { Section } from "@src/Components/StyledUtils"
import { Content, Panel } from "@src/Components/View"
import { colors } from "@src/theme"
import * as React from "react"

import { usePortalSize } from "../Common/usePortalSize"
import { BodyWrapper, CardBodyWrapper, CardIcon } from "./styles"

interface IPageHeaderCard {
  heading?: string | JSX.Element
  infoText?: string | JSX.Element
  showButton?: boolean
  onClickButton?: () => void
  buttonType?: "primary" | "secondary"
  buttonText?: string
  buttonWidth?: number
  showHelpCenter?: boolean
  helpCenterLinkUrl?: string
  showViewIntro?: boolean
  onClickViewIntro?: () => void
  id?: string
  cardIconUrl?: string
  cardBody?: JSX.Element
  color?: string
  maxGrow?: number
  maxWidth?: number
  imageWidth?: string
  contentWidth?: string
  hidden?: boolean
  maxHeight?: number
  minHeight?: number
  bodyWidth?: string
  borderColor?: string
  isStickyOnMobile?: boolean
}

const PageHeaderCard = (props: IPageHeaderCard) => {
  const {
    contentWidth,
    imageWidth,
    cardIconUrl,
    cardBody,
    maxGrow,
    maxWidth,
    minHeight,
    borderColor = colors.warning,
    isStickyOnMobile,
  } = props
  const { isDesktop } = usePortalSize()

  return (
    <Panel
      maxWidth={maxWidth}
      maxGrow={maxGrow}
      flexGrow={0}
      margin={`0px 0px ${isDesktop ? 32 : 0}px 0px`}
      minHeight={minHeight || (isDesktop && 200) || undefined}
      borderColor={borderColor}
      isTopBorder
      isStickyOnMobile={isStickyOnMobile}
    >
      <Content padding="34px 44px 25px 44px">
        <PageHeader {...props} />
        {isDesktop && (
          <Section flex="start start">
            {cardIconUrl && <CardIcon width={imageWidth} src={cardIconUrl} />}
            <BodyWrapper>
              <CardBodyWrapper width={contentWidth}>{cardBody}</CardBodyWrapper>
            </BodyWrapper>
          </Section>
        )}
      </Content>
    </Panel>
  )
}

export default PageHeaderCard
