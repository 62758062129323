import DialogComp from "@src/Components/Dialog"
import { media } from "@src/theme"
import styled from "styled-components"

interface IDivProps {
  height?: number
}

export const Dialog = styled(DialogComp)`
  width: 90%;
  max-width: 800px;

  &.zeg-danger {
    max-width: 500px;
  }
`

export const Content = styled.div<IDivProps>`
  font-family: proxima-nova, sans-serif;
  height: ${(props) => props.height}px;
  background-color: #fff;
`

export const Panel = styled.div`
  padding: 20px 40px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  @media ${media.medium} {
    width: 100%;
    margin: 0px;
    flex: 1 0 auto;
    padding: 20px 20px;
    justify-content: center;
  }
`

export const ConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ConfirmText = styled.div`
  font-size: 16px;
  margin: 5px 0;
`

export const ConfirmTextBold = styled.span`
  font-weight: 700;
`

export const SubText = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  color: #777777;
  padding: 4px 0px;
  text-align: left;
`

export const Row = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  color: #212528;
  margin-top: 18px;
  text-align: center;
  width: 100%;
`

export const ButtonRow = styled.div`
  text-align: right;
  margin: 10px 50px 30px;
`
