import Dialog from "@src/Components/Dialog"
import * as React from "react"

import ChoosePolicyForm from "../ChoosePolicyForm"

interface INewPolicyModal {
  isOpen: boolean
  onClose: () => void
  onCreate: (name: string | null) => void
}

export const NewPolicyModal = ({
  isOpen,
  onClose,
  onCreate,
}: INewPolicyModal) => {
  return (
    <Dialog
      title="New Custom Security Policy"
      className="zeg-dialog"
      isOpen={isOpen}
      actionText="Save"
      onClose={onClose}
      content={
        <ChoosePolicyForm
          onAction={(name) => onCreate(name)}
          onClose={onClose}
        />
      }
    />
  )
}
