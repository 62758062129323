import { Popover, Position } from "@blueprintjs/core"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { VIEW_PATHS, VIEW_PATHS_NAV_MENU } from "@src/Routes/constants"
import * as T from "@src/types"
import { tModules } from "@src/types"
import * as React from "react"
import { useSelector } from "react-redux"
import { matchPath, useLocation } from "react-router-dom"

import {
  CompanyName,
  Divider,
  EmailTooltip,
  HeaderGradient,
  Logo,
  LogoImg,
  MainMenuFooter,
  MainMenuRoot,
  MenuBackDrop,
  MenuGroup,
  MenuItemContainer,
  MenuItemsContainer,
  MenuText,
  StyledCell,
  StyledIconItem,
  StyledMenuItem,
  SubMenuItem,
  UserIcon,
  UserInfo,
  UserMenuButton,
  UserName,
  UserNameContainer,
} from "./styles"

interface IMenuItem {
  viewName: string
  icon?: string
  view?: Partial<keyof tModules>
  status?: string
  submodules?: any[]
  badge?: "BETA" | "NEW"
  isGroup?: boolean
  externalLink?: string
}

interface IMenu {
  height: number
  width: number
  offset?: number
  portalMenu: string
  items: IMenuItem[]
  hidden: boolean
  menuExtensionItems: IMenuItem[]
  setView: (view: string) => void
  isOpen: boolean
  uiRules?: T.tChannelUiRules
  onClose: () => void
}

const Menu: React.FC<IMenu> = ({
  hidden,
  height,
  width,
  offset,
  portalMenu,
  items,
  menuExtensionItems,
  setView,
  isOpen,
  uiRules,
  onClose,
}) => {
  const location = useLocation()
  const pathname = `/${location.pathname.split("/")[1]}`
  const companyProfile = useSelector(
    (store: tState) => store.portal.companyProfile,
  )
  const userProfile = useSelector((store: tState) => store.portal.userProfile)
  const email = userProfile?.email || ""
  const isLongEmail = email.length > 25
  const emailBody = email.split("@")[0]
  const emailTail = `@${email.split("@")[1]}`
  const truncatedEmailBody =
    emailBody.length > 25 ? `${emailBody.slice(0, 25)}...` : emailBody
  const username = [userProfile?.firstName, userProfile?.lastName]
    .filter((name) => name)
    .join(" ")
  const usernameLine1 = username || (isLongEmail ? truncatedEmailBody : email)
  const usernameLine2 = !username && isLongEmail && emailTail
  const { helpCenter } = useSelector((state: tState) => state.brand)

  const getMenuItems = (isExtension?: boolean) => {
    const menuItems = isExtension ? menuExtensionItems : items
    return menuItems.map((item) =>
      item.status !== "NOT_AVAILABLE" ? (
        <div key={item.view}>
          {item.isGroup ? (
            <MenuGroup>{item.viewName}</MenuGroup>
          ) : (
            <>
              <MenuItem
                item={item}
                currentMenuItem={portalMenu}
                setView={setView}
              />
              {item.submodules && (
                <SubMenuItem>
                  {item.submodules.map((submodule) => (
                    <MenuItem
                      key={submodule.view}
                      item={submodule}
                      currentMenuItem={portalMenu}
                      setView={setView}
                    />
                  ))}
                </SubMenuItem>
              )}
            </>
          )}
        </div>
      ) : null,
    )
  }

  const onSupportClick = () => {
    onClose()
    window.open(`http://${helpCenter}/`, "_blank")
  }

  return (
    <>
      <MainMenuRoot
        offset={offset}
        hidden={hidden}
        isOpen={isOpen}
        {...{ height, width }}
      >
        <div>
          <HeaderGradient />
          <UserInfo>
            <Popover
              content={
                <Section w="220px" padding="12 0">
                  <UserMenuButton
                    className="bp5-menu-item bp5-popover-dismiss"
                    selected={pathname === VIEW_PATHS["settings"]}
                    onClick={() => setView("settings")}
                  >
                    <i className="far fa-cog" />
                    <a>Settings</a>
                  </UserMenuButton>
                  <UserMenuButton
                    className="bp5-menu-item bp5-popover-dismiss"
                    selected={pathname === VIEW_PATHS["subscription"]}
                    onClick={() => setView("subscription")}
                  >
                    <i className="far fa-credit-card" />
                    <a>My Plan</a>
                  </UserMenuButton>
                  <UserMenuButton
                    className="bp5-menu-item bp5-popover-dismiss"
                    onClick={() => onSupportClick()}
                  >
                    <i className="far fa-book-open" />
                    <a>Support</a>
                  </UserMenuButton>
                  <Divider />
                  <UserMenuButton
                    className="bp5-menu-item bp5-popover-dismiss"
                    onClick={() => C.Portal.logout()}
                  >
                    <i className="far fa-sign-out" />
                    <a>Sign Out</a>
                  </UserMenuButton>
                </Section>
              }
              position={Position.BOTTOM}
            >
              <UserNameContainer data-tip data-for="full-email-tooltip">
                <UserName>{usernameLine1}</UserName>&nbsp;
                {usernameLine2 && (
                  <>
                    <br />
                    <UserName>{usernameLine2}</UserName>&nbsp;
                  </>
                )}
                <FaIcon className="fas fa-sort-down" verticalAlign="top" />
              </UserNameContainer>
            </Popover>
            {!username && emailBody !== truncatedEmailBody && (
              <Tooltip
                place="top"
                id="full-email-tooltip"
                width="240px"
                offset={{ top: -10 }}
                content={<EmailTooltip>{email}</EmailTooltip>}
              />
            )}
            <CompanyName>{companyProfile?.companyName}</CompanyName>
            <UserIcon className="fas fa-user-circle" />
          </UserInfo>
          <MenuItemsContainer>{getMenuItems()}</MenuItemsContainer>
        </div>
        <MainMenuFooter>
          <Logo id="header-zeguro-logo">
            {uiRules?.marketingLogoPrimary ? (
              <LogoImg isCustom src={uiRules?.marketingLogoPrimary} />
            ) : (
              <LogoImg src="/images/zeguro_logo.svg" />
            )}
          </Logo>
        </MainMenuFooter>
      </MainMenuRoot>
      {!width && isOpen && <MenuBackDrop onClick={onClose} />}
    </>
  )
}

interface IMenuItemC {
  item: IMenuItem
  currentMenuItem: string
  setView: (view: string) => void
}

const MenuItem: React.FC<IMenuItemC> = ({ item, currentMenuItem, setView }) => {
  const location = useLocation()
  const pathname = `/${location.pathname.split("/")[1]}`

  const [itemIsHovered, setItemIsHovered] = React.useState(false)
  const { icon, viewName, view, status, submodules, badge, externalLink } = item
  const submoduleNames = submodules?.map((item) => item.view) || []
  const selected =
    view &&
    (VIEW_PATHS[view] === pathname || submoduleNames.includes(currentMenuItem))
  const isDisabled = status === "INACTIVE"
  const matchProfile = matchPath(location.pathname, {
    path: VIEW_PATHS_NAV_MENU[location.pathname.split("/")[1]],
  })
  const isExact = matchProfile?.isExact

  return (
    <MenuItemContainer>
      <StyledMenuItem
        data-tip
        data-for={isDisabled && `tooltip-${view}`}
        id={"menu-" + view}
        selected={selected}
        isHover={!isExact && selected}
        onMouseOver={() => setItemIsHovered(true)}
        onMouseOut={() => setItemIsHovered(false)}
        isDisabled={isDisabled}
        isExternalLink={!!externalLink}
        onClick={
          externalLink
            ? () => window.open(externalLink, "_blank")
            : view && !isDisabled && (isExact || !selected)
            ? () => setView(view)
            : null
        }
        height={icon ? "54px" : "36px"}
      >
        {icon ? (
          <StyledCell>
            <StyledIconItem
              hidden={!selected && (!itemIsHovered || isDisabled)}
              src={"images/" + icon + "_color.svg"}
            />
            <StyledIconItem
              hidden={selected || (itemIsHovered && !isDisabled)}
              src={"images/" + icon + "_tinted.svg"}
            />
          </StyledCell>
        ) : null}
        <MenuText badge={badge}>
          {viewName}
          {externalLink && (
            <FaIcon
              className="fas fa-external-link"
              margin="0 0 0 4px"
              fontSize="14px"
            />
          )}
        </MenuText>
      </StyledMenuItem>
      <Tooltip
        width="405px"
        offset={{ top: -20, left: 40 }}
        id={`tooltip-${view}`}
        clickable
        content={
          <Section fontSize="16px" padding="4">
            {pathname.includes(VIEW_PATHS.subscription) ? (
              "Subscribe to use"
            ) : (
              <Link onClick={() => setView("subscription")} isUnderline>
                Subscribe to use.
              </Link>
            )}
          </Section>
        }
      />
    </MenuItemContainer>
  )
}

export default Menu
