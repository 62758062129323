import { colors, media } from "@src/theme"
import styled from "styled-components"

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  @media ${media.medium} {
    flex-direction: column-reverse;
    align-items: stretch;
    gap: 24px;
  }
`

export const HeaderLeft = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    align-items: start;
    flex-direction: column;
  }
`

export const HeaderRight = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  flex: 1;
  gap: 8px 0;
  justify-content: flex-end;
  align-items: center;
  @media ${media.medium} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.gray40};
  margin-right: 21px;
  white-space: nowrap;
  @media (max-width: 700px) {
    margin: 5px 0;
  }
`
export const ConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ConfirmText = styled.div`
  font-size: 16px;
  margin: 5px 0;
  max-height: 150px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

export const ConfirmTextBold = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
`

export const InfoIcon = styled.i`
  font-size: 16px;
  color: ${colors.success};
  margin-right: 5px;
`
