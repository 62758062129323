import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { tGlobalModals } from "@src/Model/Model"

export const initialModalsState: tGlobalModals = {
  introModalState: { currentPage: 1, openedFeature: null },
  globalModalState: {
    cybersecurityReleaseNotes: false,
    isCybersecurityRoadBlock: false,
    isContactCyberConcierge: false,
  },
}

export type tGlobalModalSKeys = {
  cybersecurityReleaseNotes: "cybersecurityReleaseNotes"
  isCybersecurityRoadBlock: "isCybersecurityRoadBlock"
  isContactCyberConcierge: "isContactCyberConcierge"
}

export const GLOBAL_MODALS_KEYS = {
  cybersecurityReleaseNotes: "cybersecurityReleaseNotes",
  isCybersecurityRoadBlock: "isCybersecurityRoadBlock",
  isContactCyberConcierge: "isContactCyberConcierge",
}

const globalModalsSlice = createSlice({
  name: "globalModals",
  initialState: initialModalsState,
  reducers: {
    changeIntroModal({ introModalState }, { payload }) {
      introModalState.openedFeature = payload
      if (!payload) {
        introModalState.currentPage = 1
      }
    },
    changeIntroModalPage({ introModalState }, { payload }) {
      introModalState.currentPage = payload
    },
    toggleGlobalModal(
      { globalModalState },
      { payload }: PayloadAction<string>,
    ) {
      globalModalState[payload as keyof tGlobalModalSKeys] =
        !globalModalState[payload as keyof tGlobalModalSKeys]
    },
  },
})

export const { name, actions, reducer } = globalModalsSlice
