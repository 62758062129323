import DataTable from "@src/Components/DataTable"
import { tState } from "@src/Model/Model"
import { IGoToTraineeTab, ITraineeEditCourses, ViewTypes } from "@src/types"
import { TraineeData as ITrainee } from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import ColumnCS from "../ColumnCS"
import ColumnName from "../ColumnName"
import ColumnSpecial from "../ColumnSpecial"
import { CellText, Content, customStyles, Link } from "./styles"

interface ITableTrainees {
  traineesData: ITrainee[]
  goToTraineeTab: (key: IGoToTraineeTab) => void
  setSelection: (arg: Omit<ITraineeEditCourses, "enrollInGap">[]) => void
  goToCourseLibraryTab: () => void
}

const TableTrainees: React.FC<ITableTrainees> = ({
  traineesData,
  goToTraineeTab,
  setSelection,
  goToCourseLibraryTab,
}) => {
  const history = useHistory()
  const subscriptionStatus = useSelector(
    (state: tState) => state.plans.subscriptionStatus,
  )
  const isInTrial = subscriptionStatus === "INTRIAL"
  const longestNameLength = traineesData.reduce(
    (prev, cur) =>
      Math.max(
        (cur.firstName?.length || 0) + (cur.lastName?.length || 0) + 1,
        prev,
      ),
    0,
  )
  const maxNameColumnLength = `${longestNameLength * 8 + 110}px`

  const onSubscribe = () => {
    history.push(ViewTypes.plan)
  }
  const selectRows = (rows: ITrainee[]) => {
    setSelection(
      rows.map((trainee) => {
        const { firstName, lastName, email, courseResults, traineeId } = trainee
        return {
          firstName,
          lastName,
          email,
          courseResults,
          traineeId,
          enrolledCourses: [],
          unEnrolledCourses: [],
        }
      }),
    )
  }

  const rowSelectCriteria = (row: ITrainee) => row.inactive

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: false,
      minWidth: maxNameColumnLength,
      cell: (trainee: ITrainee) => {
        return <ColumnName trainee={trainee} goToTraineeTab={goToTraineeTab} />
      },
    },
    {
      name: <CellText>Cybersecurity Series</CellText>,
      selector: "enrolledTrainingCourses",
      sortable: false,
      cell: (trainee: ITrainee) => {
        return <ColumnCS trainee={trainee} />
      },
    },
    {
      name: (
        <Content>
          <CellText>Specialized Training&nbsp;&nbsp;</CellText>
          {isInTrial && <Link onClick={onSubscribe}>Subscribe to Enroll</Link>}
        </Content>
      ),
      selector: "enrolledTrainingCourses",
      sortable: false,
      cell: (trainee: ITrainee) => {
        return (
          <ColumnSpecial
            trainee={trainee}
            goToCourseLibraryTab={goToCourseLibraryTab}
          />
        )
      },
    },
  ]

  return (
    <DataTable
      columns={columns}
      data={traineesData}
      customStyles={customStyles}
      striped
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => selectRows(selectedRows)}
      selectableRowDisabled={rowSelectCriteria}
    />
  )
}

export default TableTrainees
