import styled from "styled-components"

export const FormContent = styled.div`
  padding: 32px;
`

export const Info = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`

export const ButtonRow = styled.div`
  text-align: right;
  margin-top: 30px;
`
