import { ButtonSmall } from "@src/Components/Button"
import { Content, Heading, PanelContainer } from "@src/Components/Common"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Section } from "@src/Components/StyledUtils"
import { tState } from "@src/Model/Model"
import { CyberSafetyIntroModalContent } from "@src/Portal/OnBoarding/ModuleIntros/CyberSafety/CyberSafetyIntroModalContent"
import { updateCustomerPortalData } from "@src/Sagas"
import { ViewTypes } from "@src/types"
import { OrgGoals as IOrgGoals } from "@zeguro/schema-validator/dist/types/coco/core/combined"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import C from "../../../../Controller"
import OrgGoals from "./OrgGoals"
import {
  BackBtn,
  ButtonsBlock,
  ContentStyle,
  IntroBody,
  IntroImage,
  IntroPanel,
  IntroSubHeading,
} from "./styles"

const CyberSafetyIntroContainer = () => {
  const { clientHeight, isDesktop } = usePortalSize()
  const history = useHistory()
  const dispatch = useDispatch()
  const [orgGoals, setOrgGoals] = React.useState<IOrgGoals>()
  const [currentPage, setCurrentPage] = React.useState(1)
  const [modalContent, setModalContent] = React.useState(
    CyberSafetyIntroModalContent[0],
  )

  const goToDashboard = () => history.push(ViewTypes.dashboard)

  const portal = useSelector((store: tState) => store.portal)
  const introViewed = useSelector(
    (store: tState) => store.customerPortalData?.data?.introViewed,
  )
  const isContentPage = currentPage < CyberSafetyIntroModalContent.length + 1
  const modalHeight = isContentPage || !isDesktop ? 570 : 850
  const modalWidth = isContentPage ? 764 : 850

  const imageHeader = modalContent.heading.imageSrc
  const heading = modalContent.heading.mainHeading?.text
  const headingAlign = modalContent.heading.mainHeading?.align || "center"
  const subHeading = modalContent.heading.subHeading
  const content = modalContent.content
  const numberOfPages = CyberSafetyIntroModalContent.length + 1

  const updateUserData = () => {
    dispatch(
      updateCustomerPortalData({
        introViewed: { dashboard: true },
      }),
    )
    setTimeout(() => {
      C.Portal.editUser(
        portal.cognitoId || "",
        {
          orgId: portal.orgId || undefined,
          ...portal.userProfile,
          orgGoals,
          hasSeenOrgGoals: true,
        },
        () => {},
        true,
      )
    }, 100)
    goToDashboard()
  }

  const onChangePage = (page: number) => {
    if (page <= numberOfPages) {
      setCurrentPage(page)
      if (page <= CyberSafetyIntroModalContent.length) {
        setModalContent(CyberSafetyIntroModalContent[page - 1])
      }
    } else {
      console.log(orgGoals)
      updateUserData()
    }
  }

  React.useEffect(() => {
    if (introViewed?.dashboard) {
      goToDashboard()
    }
  }, [])

  return (
    <Content>
      <PanelContainer height={Math.max(clientHeight, modalHeight + 240)}>
        <IntroPanel width={`${modalWidth}px`} height={`${modalHeight}px`}>
          <IntroBody>
            {isContentPage ? (
              <>
                {imageHeader && <IntroImage src={imageHeader} />}
                <Heading align={headingAlign}>{heading}</Heading>
                {subHeading?.text && (
                  <IntroSubHeading align={subHeading.align} fontSize="28px">
                    {subHeading.text}
                  </IntroSubHeading>
                )}
                <ContentStyle>{content}</ContentStyle>
              </>
            ) : (
              <OrgGoals
                onGoalsChange={(goals: IOrgGoals) => setOrgGoals(goals)}
              />
            )}
          </IntroBody>

          <ButtonsBlock isDesktop={isDesktop}>
            {modalContent.pageNumber > 1 ? (
              <BackBtn
                width={100}
                inverse={false}
                onClick={() => onChangePage(currentPage - 1)}
              >
                Back
              </BackBtn>
            ) : (
              <Section w="100px" />
            )}
            <label>
              {currentPage}&nbsp;of&nbsp;
              {numberOfPages}
            </label>
            <ButtonSmall
              width={100}
              onClick={() => onChangePage(currentPage + 1)}
            >
              Next
            </ButtonSmall>
          </ButtonsBlock>
        </IntroPanel>
      </PanelContainer>
    </Content>
  )
}

export default CyberSafetyIntroContainer
