import { ButtonSecondary } from "@src/Components/Button"
import TextField from "@src/Components/Form/TextField"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { usePersonalDetailsValidation } from "@src/Portal/Settings/validation"
import * as T from "@src/types"
import { useFormik } from "formik"
import * as React from "react"
import { useSelector } from "react-redux"

import { SpinnerStyled } from "../../styles"
import { Form } from "../styles"

interface IPersonalDetailsForm {
  firstName: string
  lastName: string
  email: string
  jobTitle: string
}

const formInitial: IPersonalDetailsForm = {
  firstName: "",
  lastName: "",
  email: "",
  jobTitle: "",
}

export const PersonalDetails = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const userProfile = useSelector((state: tState) => state.portal.userProfile)
  const cognitoId = useSelector((state: tState) => state.portal.cognitoId)
  const orgId = useSelector((state: tState) => state.portal.orgId || "")
  const isZeguroServiceSession = useSelector(
    (state: tState) => state.portal.isZeguroServiceSession,
  )
  const personalDetailsScheme = usePersonalDetailsValidation()
  const formik = useFormik<Partial<T.tUserProfile>>({
    initialValues: userProfile || formInitial,
    enableReinitialize: true,
    validationSchema: personalDetailsScheme,
    onSubmit: (values) => {
      setIsLoading(true)
      C.Portal.editUser(
        cognitoId || "",
        {
          ...userProfile,
          orgId,
          ...values,
        },
        () => setIsLoading(false),
      )
    },
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextField
        label="First Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName || ""}
        name="firstName"
        error={!!(formik.errors.firstName && formik.touched.firstName)}
        errorMsg={formik.errors.firstName}
        margin="0 0 28px 0"
        disabled={isZeguroServiceSession}
      />
      <TextField
        label="Last Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastName || ""}
        name="lastName"
        error={!!(formik.errors.lastName && formik.touched.lastName)}
        errorMsg={formik.errors.lastName}
        margin="0 0 28px 0"
        disabled={isZeguroServiceSession}
      />
      <TextField
        label="Email Address"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email || ""}
        name="email"
        error={!!(formik.errors.email && formik.touched.email)}
        errorMsg={formik.errors.email}
        margin="0 0 28px 0"
        disabled
      />
      <TextField
        label="Job Title (Optional)"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.jobTitle || ""}
        name="jobTitle"
        error={!!(formik.errors.jobTitle && formik.touched.jobTitle)}
        errorMsg={formik.errors.jobTitle}
        margin="0 0 28px 0"
        disabled={isZeguroServiceSession}
      />
      <ButtonSecondary
        id="save-details-button"
        width={226}
        margin="10 0"
        type="submit"
        disabled={!(formik.isValid && formik.dirty)}
      >
        {isLoading ? <SpinnerStyled size={25} /> : "Update Personal Details"}
      </ButtonSecondary>
    </Form>
  )
}
