import { colors, media } from "@src/theme"
import styled from "styled-components"

export const Navbar = styled.div<any>`
  position: absolute;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props: any) => props.height}px;
  border-bottom: ${(props: any) =>
    props.hideBorder ? undefined : "1px solid #ccc"};
  background-color: #fff;
  width: 100vw;
  top: ${({ offsetTop }) => offsetTop || 0}px;
  padding: ${({ padding }) => padding || 0};
`

export const UserNameContainer = styled.div`
  text-align: center;
  cursor: pointer;
`

export const UserName = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 1.4;
  text-align: center;
  word-break: break-word;
`

export const CompanyName = styled.div`
  text-align: center;
  font-size: 15px;
  line-height: 1.4;
`

export const SignOutButton = styled.div`
  &.bp5-menu-item {
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    line-height: 30px;
    padding: 10px;
    cursor: pointer;
    color: ${colors.black};
    a {
      color: black;
      padding: 0 20px;
    }
    &:hover {
      background-color: ${colors.gray2};
      a {
        color: ${colors.link};
        text-decoration: underline;
      }
    }
  }
`

export const UserIcon = styled.div`
  font-size: 48px;
  margin-bottom: 4px;
  color: ${colors.purple};
  @media ${media.medium} {
    font-size: 32px;
    margin: 0 5px 0;
  }
`

export const Logo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const LogoImg = styled.img<any>`
  height: 36px;
  margin: 0 10px;
  @media ${media.medium} {
    height: 30px;
  }
`

export const MenuIcon = styled.i`
  font-size: 32px;
  padding: 0 16px;
`

export const ZeguroSessionBanner = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  background-color: orange;
  padding: 4px 12px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  min-height: 40px;
  font-size: 16px;
  line-height: 1.2;
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.medium} {
    flex-direction: row;
  }
`

export const UserInfoContent = styled.div`
  position: absolute;
  z-index: 6;
  right: 0;
  margin: 10px;
`
