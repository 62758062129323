import { Tabs } from "@blueprintjs/core"
import { Content } from "@src/Components/View"
import { colors, media } from "@src/theme"
import styled from "styled-components"

interface ITabTextProps {
  selected?: boolean
  disabled?: boolean
}

interface ISelectContainer {
  margin?: string
  maxWidth?: string
}

export const Header = styled.div`
  padding: 24px 44px 0 44px;
  border-bottom: solid 1px #e1e1e1;

  @media ${media.medium} {
    padding: 24px 15px 0;
    border-bottom: none;
  }
`

export const ContentStyled = styled(Content)`
  flex: 1;
  @media (max-width: 1000px) {
    padding: 24px 50px;
  }

  @media ${media.medium} {
    padding: 15px;
  }
`

export const TabsStyled = styled(Tabs)`
  .bp5-tab {
    &:not(:last-child) {
      margin-right: 40px;
      @media (max-width: 1000px) {
        margin-right: 30px;
      }
    }
  }
`

export const TabText = styled.div<ITabTextProps>`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ selected }) => (selected ? "#26249e" : "#212528")};
  padding-bottom: 10px;
  cursor: ${({ selected }) => (selected ? "default" : "pointer")};
  opacity: ${({ disabled }) => disabled && 0.7};
  pointer-events: ${({ disabled }) => disabled && "none"};
`

export const SelectContainer = styled.div<ISelectContainer>`
  position: relative;
  margin: ${({ margin }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
  .react-select-container {
    .react-select__control {
      border-color: ${colors.gray30};
      box-shadow: none;
      .react-select__value-container {
        padding: 8px 0 6px 16px;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .react-select__indicators {
      .react-select__indicator {
        padding: 11px;
        svg {
          fill: ${colors.black};
        }
      }
    }
    .react-select__menu {
      z-index: 2;
    }
  }
`
