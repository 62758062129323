import { ProgressBar } from "@blueprintjs/core"
import { BtnSmallResponsive, ButtonDashboard } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { FaIcon } from "@src/Components/FaIcon"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import { getAssessmentDataWatcher, getConfigWatcher } from "@src/Sagas"
import { colors } from "@src/theme"
import { calculatePercentage } from "@src/Utils"
import * as moment from "moment"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"

import { CTA, FaIconHead, FeatureCard, HeadingImg, WarnIcon } from "../styles"
import { EmptyStateCard } from "./EmptyState"
import { FilledStateCard } from "./FilledState"
import {
  ProgressDetails,
  ProgressInfo,
  ProgressText,
  StyledLi,
  StyledUl,
  UpdatedText,
  ZimmHeading,
} from "./styles"

interface IZimmCardCard {
  isActive: boolean
  gridColumn?: string
  setView: (isFinished?: boolean) => void
}

export const ZimmCard: React.FC<IZimmCardCard> = ({
  isActive,
  gridColumn,
  setView,
}) => {
  const dispatch = useDispatch()
  const { isLargeScreen } = usePortalSize()

  React.useEffect(() => {
    dispatch(getAssessmentDataWatcher())
    dispatch(getConfigWatcher())
  }, [])
  const zimmData = useSelector((state: tState) => state.portal.dashboard.zimm)
  const emptyState = !zimmData?.assessmentStatus
  const isFinished = zimmData?.assessmentStatus === "completed"
  const lastUpdated = zimmData?.lastUpdated
  const answeredQuestions =
    useSelector((store: tState) => store.zimm.getAssessmentData.data)?.response
      .questions.length || 0
  const config = useSelector(
    (store: tState) => store.zimm.getConfig.elements,
  ).length
  const progress = calculatePercentage(answeredQuestions, config)
  const progressPercentage = `${progress}%`

  return (
    <FeatureCard
      color={colors.green30}
      isEmpty={emptyState}
      minHeight={isLargeScreen ? "275px" : undefined}
      gridColumn={gridColumn}
    >
      <ZimmHeading marginBottom={isLargeScreen ? "20px" : "40px"}>
        <HeadingImg src="images/zimm/icon_cyber_maturity_lg.svg" />
        <div>{isLargeScreen && "YOUR"} CYBER MATURITY </div>
        {!emptyState && (
          <FaIconHead
            className="fas fa-info-circle"
            data-tip
            data-for="card-title-zimm-tooltip"
          />
        )}
      </ZimmHeading>
      {emptyState ? (
        <EmptyStateCard />
      ) : (
        <>
          <FilledStateCard zimmData={zimmData} isFinished={isFinished} />
          <Tooltip
            id="card-title-zimm-tooltip"
            type="dark"
            content={
              <p>
                <StyledUl>
                  <StyledLi>
                    Get a complete snapshot of your organization’s current
                    cybersecurity stance.
                  </StyledLi>
                  <StyledLi>
                    Discover actionable insights to fortify your organization
                    against bad actors.
                  </StyledLi>
                </StyledUl>
              </p>
            }
          />
        </>
      )}
      <CTA>
        {!isActive ? (
          <ButtonDashboard
            width="60%"
            backgroundColor={colors.white}
            onClick={() => setView()}
          >
            Subscribe to Use
          </ButtonDashboard>
        ) : emptyState || isFinished ? (
          <>
            {isActive && !isFinished ? (
              <BtnSmallResponsive width="60%" onClick={setView}>
                <FaIcon
                  className="fas fa-money-check-edit"
                  margin="0 6px 0 0"
                />
                Start Assessment
              </BtnSmallResponsive>
            ) : (
              <ButtonDashboard
                width="60%"
                backgroundColor={colors.white}
                onClick={() => (isActive ? setView() : setView(isFinished))}
              >
                {!isActive ? "Subscribe to Use" : "Update Responses"}
              </ButtonDashboard>
            )}
          </>
        ) : (
          <>
            <ProgressDetails>
              <ProgressInfo>
                <ProgressText align="start">Questions Answered</ProgressText>
                <UpdatedText>
                  <div>Updated</div>
                  <div>{moment(lastUpdated).format("MMM D, YYYY")}</div>
                </UpdatedText>
                <ProgressText align="end">{progressPercentage}</ProgressText>
              </ProgressInfo>
              <ProgressBar
                value={progress / 100}
                className="bp5-intent-primary bp5-no-stripes"
              />
            </ProgressDetails>
            <BtnSmallResponsive width="60%" onClick={setView}>
              <WarnIcon
                className="fas fas fa-money-check-edit"
                color={colors.white}
              />
              Finish Assessment
            </BtnSmallResponsive>
          </>
        )}
      </CTA>
    </FeatureCard>
  )
}
