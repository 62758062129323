import { scoreToGrade } from "@src/Portal/Training/utils"
import {
  CourseResults as ICourseResults,
  CourseTopics as ICourseTopics,
  LoadTrainingDataResponse as ITrainingData,
} from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"
import { CSVLink } from "react-csv"

import { DownloadCsvIcon, DownloadCSVStyled } from "./styles"

export interface IDownloadCSV {
  data: ITrainingData
}

export type tTransformedData = {
  [key in string]: string
}

const DownloadCSV: React.FC<IDownloadCSV> = ({ data }) => {
  const courseMap = data.courseMap
  const traineesData = !data?.traineesData?.length
    ? data.traineesData
    : data.traineesData.map((trainee) => {
        return {
          ...trainee,
          courseResults: courseMap.map((course) => {
            const {
              assessmentData,
              description,
              subject,
              subjectName,
              inLibrary,
              averageScore,
              ...restCourseData
            } = course
            const findCourseInTrainee = trainee.courseResults.find(
              ({ name }) => name === course.name,
            )
            if (findCourseInTrainee) {
              return { ...findCourseInTrainee }
            } else {
              return { ...restCourseData, subject: subjectName || subject }
            }
          }),
        }
      })
  let transformedData: Array<tTransformedData> = []

  const courseResult = (topic: ICourseTopics, isMultipleCourse?: boolean) => {
    if (isMultipleCourse) {
      return topic?.enrollDate && !topic.grade
        ? "Assigned"
        : topic.grade
        ? topic.grade
        : "Not Assigned"
    }
    return topic?.enrollDate && !topic.grade
      ? "Enrolled"
      : topic.grade
      ? topic.grade
      : "Not Enrolled"
  }
  traineesData?.forEach((trainee) => {
    let localeData = {
      "Trainee Name": `${trainee.firstName} ${trainee.lastName}`,
      "Trainee Email": trainee.email,
    }
    trainee.courseResults.forEach((course: ICourseResults) => {
      const isAssessment = course.subject === "Cybersecurity Series"
      const avgName = `CS ${isAssessment ? course.name : ""} | Average`
      isAssessment
        ? (localeData = {
            ...localeData,
            [avgName]: course?.averageScore
              ? scoreToGrade(course.averageScore)
              : "Enrolled",
          })
        : {
            ...localeData,
          }
      if (course.topics.length > 1) {
        course.topics?.forEach((t: ICourseTopics) => {
          return (localeData = {
            ...localeData,
            [`${t.name} | ${course.name} `]: courseResult(t, true),
          })
        })
      } else {
        localeData = {
          ...localeData,
          [course.name]: courseResult(course?.topics[0]),
        }
      }
    })
    transformedData = [...transformedData, localeData]
  })
  return (
    <CSVLink data={transformedData} filename={"training-report.csv"}>
      <DownloadCSVStyled>
        <DownloadCsvIcon className="fas fa-file-spreadsheet" />
        Download CSV Report
      </DownloadCSVStyled>
    </CSVLink>
  )
}

export default DownloadCSV
