import { ButtonSecondary } from "@src/Components/Button"
import { colors } from "@src/theme"
import { ViewTypes } from "@src/types"
import * as React from "react"
import { useHistory } from "react-router-dom"

import { CrownIcon, SubscribeContainer, SubscribeText } from "./styles"

const SubscribeSection: React.FC = () => {
  const history = useHistory()

  const onSubscribe = () => {
    history.push(ViewTypes.plan)
  }

  return (
    <SubscribeContainer>
      <div>
        <CrownIcon className="fas fa-crown" />
        <SubscribeText>
          Subscribe to enroll team members in specialized courses.
        </SubscribeText>
      </div>
      <ButtonSecondary
        onClick={onSubscribe}
        width={255}
        inverse={false}
        backgroundColor={colors.white}
      >
        Subscribe
      </ButtonSecondary>
    </SubscribeContainer>
  )
}

export default SubscribeSection
