import { FieldInputProps } from "formik"
import * as React from "react"

import { ErrorMessage, Input, InputWrapper, Label, LockIcon } from "./styles"

interface IProps {
  error?: boolean
  placeholder?: string
  isError?: string
  margin?: string
  errorMsg?: string | React.ReactNode
  value: string | number
  label?: string
  disabled?: boolean
  padding?: string
  type?: string
  align?: string
  selectOnFocus?: boolean
}

const useFocus = () => {
  const htmlElRef: React.RefObject<HTMLInputElement> = React.useRef(null)
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus()
  }

  return [htmlElRef, setFocus]
}

const TextField = ({
  onChange,
  value,
  name,
  error,
  errorMsg,
  placeholder,
  margin,
  padding,
  label,
  disabled,
  type = "text",
  align,
  selectOnFocus,
  ...rest
}: IProps & FieldInputProps<unknown>) => {
  const [inputRef, setInputFocus] = useFocus()
  const [activeField, setActiveField] = React.useState("")

  const handleFocus = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (selectOnFocus) {
      setTimeout(() => target.select(), 10)
    }
    setActiveField(target.name)
  }

  return (
    <InputWrapper margin={margin} activeField={activeField}>
      {label && (
        <Label up={activeField || value} error={error} onClick={setInputFocus}>
          {label}
        </Label>
      )}
      <Input
        type={type}
        onChange={onChange}
        onBlur={(e) => {
          setActiveField("")
          rest.onBlur(e)
        }}
        value={value}
        name={name}
        isError={error}
        placeholder={placeholder}
        ref={inputRef}
        onFocus={handleFocus}
        padding={padding}
        disabled={disabled}
        align={align}
      />
      {error && <ErrorMessage>{errorMsg}</ErrorMessage>}
      {disabled && <LockIcon className="fas fa-lock" />}
    </InputWrapper>
  )
}

export default TextField
