import styled from "styled-components"

interface IText {
  fontSize?: string
  color?: string
  fontFamily?: string
}
export const Text = styled.span<IText>`
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize || "21px"};
  line-height: 25px;
  color: ${({ color }) => color || "#4C545A"};
  font-family: ${({ fontFamily }) => fontFamily};
`
