import { actions as ACCOUNTS } from "@src/Actions/Reducers/AccountsReducer"
import { ButtonSecondary } from "@src/Components/Button"
import { Heading, SubHeading } from "@src/Components/Common"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

export const CheckYourEmail = () => {
  const dispatch = useDispatch()
  const email = useSelector(
    (state: tState) => state?.account?.sendResetPasswordMagicLink?.email,
  )

  const clearResetPasswordMagicLink = () => {
    dispatch(ACCOUNTS.sendResetPasswordMagicLinkClear())
  }

  return (
    <Section padding="0 100">
      <Row>
        <Heading align="center" margin="20px 0px">
          Check your email.
        </Heading>
      </Row>
      <Row>
        <SubHeading>
          If an account exists, an email with a recovery link <br />
          will be sent you
          <span
            dangerouslySetInnerHTML={{
              __html:
                email && email !== ""
                  ? ` at <b>${email.toLowerCase()}</b>`
                  : "",
            }}
          />
          . <br />
          Don’t see it? Check your spam folder.
        </SubHeading>
      </Row>
      <Row>
        <Icon src="images/icon_email_verified.svg" />
      </Row>
      <br />
      <Row>
        <HeadingGray>Didn&apos;t receive an email?</HeadingGray>
      </Row>
      <br />
      <Row>
        <ButtonContainer>
          <ButtonSecondary
            onClick={() => {
              C.showSupportModal()
            }}
            id="contact-support-button"
            width={170}
            margin={10}
          >
            Contact Support
          </ButtonSecondary>
        </ButtonContainer>
      </Row>
      <Row>
        <FooterText>
          Or&nbsp;
          <Link
            onClick={() => {
              clearResetPasswordMagicLink()
            }}
          >
            use another email address instead.
          </Link>
        </FooterText>
      </Row>
    </Section>
  )
}

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const Icon = styled.img`
  width: 110px;
`

const FooterText = styled.p`
  margin-top: 20px;
  font-size: 15px;
  line-height: 21px;
`

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`

const HeadingGray = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  color: #6f7379;
  text-align: center;
  width: 100%;
  font-weight: bold;
`
