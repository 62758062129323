import Tooltip from "@src/Components/Tooltip"
import { CatalogMap as ICatalogMap } from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"

import {
  BodyWrap,
  CardContainer,
  CardDescription,
  CardTitle,
  HeadSubject,
  HeadWrap,
  Image,
  LockIcon,
  NameWrap,
} from "./styles"

export interface ICardData {
  name: string
  description: string
  imageUrl: string
  subject: string
  isInfosec?: boolean
}
interface ICardProps {
  data: ICatalogMap
  isLock: boolean
}

const Card: React.FC<ICardProps> = ({
  data: { name, subject, description, imageUrl },
  isLock,
}) => {
  return (
    <CardContainer isLock={isLock}>
      <LockIcon
        isLock={isLock}
        className="fas fa-lock"
        data-tip
        data-for={`${name}-tooltip-lock`}
      />
      <HeadWrap>
        <Image src={imageUrl} alt="Icon" />
        <NameWrap>
          <HeadSubject>
            {subject !== "N/A" && subject !== "Cybersecurity Series"
              ? subject
              : ""}
          </HeadSubject>
          <CardTitle>{name}</CardTitle>
        </NameWrap>
      </HeadWrap>
      <BodyWrap>
        <CardDescription>{description}</CardDescription>
      </BodyWrap>
      <Tooltip
        content={
          <p>
            Only available for the subscribed customers. Subscribe to enroll.
          </p>
        }
        width="220px"
        id={`${name}-tooltip-lock`}
      />
    </CardContainer>
  )
}

export default Card
