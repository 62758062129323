import { colors } from "@src/theme"
import styled from "styled-components"

interface ISectionHeading {
  padding?: string
  color?: string
}
interface IOval {
  color: string
  borderColor: string
}
interface IBox {
  hasBorder?: boolean
  backgroundColor?: string
  color?: string
  borderColor?: string
  noTopThickBorder?: boolean
  padding?: string
}
export const PageHolder = styled.div<{ align?: string }>`
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${({ align }) => align || "center"};
`

export const DateHolder = styled.div`
  font-family: Montserrat;
  color: ${colors.black50};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  @media (max-width: 340px) {
    font-size: 16px;
  }
`

export const Box = styled.div<IBox>`
  border-radius: ${(props) => props.hasBorder && "6px"};
  border: ${({ hasBorder, borderColor }) =>
    hasBorder && `1px solid ${borderColor || "rgba(0, 98, 217, 0.6)"}`};
  border-top: ${({ borderColor, noTopThickBorder, hasBorder }) =>
    hasBorder &&
    !noTopThickBorder &&
    `7px solid ${borderColor || "rgba(105, 162, 229, 1)"}`};
  background-color: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  padding: ${({ padding }) => padding || "13px 26px"};
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${({ color }) => color};
  @media (max-width: 330px) {
    padding: 5px;
  }
`

export const Oval = styled.div<IOval>`
  width: 16px;
  height: 16px;
  border: solid 1px ${({ borderColor }) => borderColor};
  margin-right: 10px;
  background-color: ${({ color }) => color};
  border-radius: 8px;
`

export const SubHeading = styled.div`
  display: flex;
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  color: ${colors.darkBlue};
  padding: 8px 0;
  margin-right: 8px;
`
export const BoxBody = styled.div`
  flex-grow: 1;
  width: 100%;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: normal;
`

export const PageBreak = styled.div`
  width: 100%;
  height: 25px;
`

export const SectionHeading = styled.div<ISectionHeading>`
  font-family: montserrat;
  font-size: 18px;
  line-height: 1.33;
  color: ${({ color }) => color || colors.black50};
  font-weight: bold;
  padding: ${({ padding }) => padding || "0px 0px 10px 0px"};
`

export const ScheduleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1024px) {
    #scan-action {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
export const TargetDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  @media (max-width: 1155px) {
    justify-content: start;
    flex-direction: column;
  }
  @media (min-width: 750px) and (max-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    width: 100%;
    #scan-action {
      margin-top: 10px;
    }
  }
`

export const ScheduleRowElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  b {
    @media (max-width: 1155px) {
      min-width: 98px;
    }
    @media (min-width: 750px) and (max-width: 900px) {
      flex-direction: row;
      justify-content: space-between;
      min-width: auto;
    }
  }
  @media (max-width: 330px) {
    font-size: 14px;
    div {
      font-size: 14px;
    }
  }
`

export const TargetDetail = styled.div`
  font-family: montserrat;
  font-size: 24px;
  line-height: 1;
  color: #26249e;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  h3 {
    font-size: 24px;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  span {
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    color: ${colors.gray45};
  }
`

export const PopoverContent = styled.div`
  padding: 10px 16px;
  max-width: 350px;
  font-size: 16px;
  line-height: 21px;
  font-family: proxima-nova, sans-serif;
`
export const ContactUs = styled.span`
  margin-top: 25px;
`
