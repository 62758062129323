import * as PortalAction from "@src/Actions/PortalActions"
import { VIEW_PATHS } from "@src/Routes/constants"
import * as T from "@src/types"
import { tModules } from "@src/types"
import * as React from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { tState } from "../../Model/Model"
import Menu from "../Menu"

interface IMenuItem {
  viewName: string
  icon?: string
  view?: Partial<keyof tModules>
  status?: string
  submodules?: any[]
  badge?: "NEW" | "BETA"
  isGroup?: boolean
  externalLink?: string
}

const menuExtensionItems: IMenuItem[] = [
  {
    icon: "",
    viewName: "Settings",
    view: "settings",
    status: "ACTIVE",
  },
  {
    icon: "",
    viewName: "My Plan",
    view: "subscription",
    status: "ACTIVE",
  },
]

interface IMenuContainer {
  hidden: boolean
  height: number
  width: number
  offset?: number
  isOpen: boolean
  uiRules?: T.tChannelUiRules
  onClose: () => void
}

const MenuContainer: React.FC<IMenuContainer> = ({
  hidden,
  height,
  width,
  offset,
  isOpen,
  uiRules,
  onClose,
}) => {
  const history = useHistory()
  const pathname = `/${history.location.pathname.split("/")[1]}`

  const dispatch = useDispatch()

  const portalMenu = useSelector((state: tState) => state.portal.portalMenu)
  const modules = useSelector((state: tState) => state.plans.modules)
  const { helpCenter }  = useSelector((state: tState) => state.brand)

  const menuConfig: IMenuItem[] = [
    {
      icon: "Da",
      viewName: "Dashboard",
      view: "dashboard",
      status: "NOT_AVAILABLE",
    },
    {
      icon: "Cm",
      viewName: "Cyber Maturity",
      view: "cybermaturity",
      status: "NOT_AVAILABLE",
    },
    {
      isGroup: true,
      viewName: "TOOLS & SERVICES",
    },
    {
      icon: "concierge",
      viewName: "Cyber Concierge",
      view: "cyberconcierge",
      status: "NOT_AVAILABLE",
      badge: "NEW",
    },
    {
      icon: "Sh",
      viewName: "Security Policies",
      view: "compliance",
      status: "NOT_AVAILABLE",
    },
    {
      icon: "Tr",
      viewName: "Training",
      view: "training",
      status: "NOT_AVAILABLE",
    },
    {
      icon: "Ra",
      viewName: "Monitoring",
      view: "monitoring",
      status: "NOT_AVAILABLE",
      ...(modules.ratings === "ACTIVE"
        ? {
            submodules: [
              {
                icon: "Ws",
                viewName: "Website Scans",
                view: "monitoring",
                status: "ACTIVE",
              },
              {
                icon: "Sr",
                viewName: "Attack Surface",
                view: "attacksurface",
                status: "ACTIVE",
              },
            ],
          }
        : {}),
    },
    {
      icon: "In",
      viewName: "Insurance",
      view: "insurance",
      status: "NOT_AVAILABLE",
    },
    {
      icon: "Ma",
      viewName: "Marketplace",
      view: "marketplace",
      status: "NOT_AVAILABLE",
    },
    {
      isGroup: true,
      viewName: "LEARN",
    },
    {
      icon: "Kb",
      viewName: "Knowledge Base",
      externalLink:  `https://${helpCenter}`
    },
  ]

  const changeView = (view: string) => {
    onClose()
    dispatch(PortalAction.setPortalMenu(view))
    history.push(VIEW_PATHS[view])
  }
  const items = menuConfig.map((item: IMenuItem) => ({
    ...item,
    status: item.view && modules[item.view],
  }))
  React.useEffect(() => {
    if (pathname === "/attacksurface") {
      dispatch(PortalAction.setPortalMenu("monitoring"))
    }
  }, [portalMenu])

  if (hidden) {
    return null
  }

  return (
    <Menu
      hidden={hidden}
      width={width}
      height={height}
      offset={offset}
      portalMenu={portalMenu}
      items={items}
      menuExtensionItems={menuExtensionItems}
      setView={changeView}
      isOpen={isOpen}
      uiRules={uiRules}
      onClose={onClose}
    />
  )
}

interface IMenuPropMap {
  portalMenu: string
  modules: tModules
}

const mapStateToProps = (store: tState): IMenuPropMap => {
  return {
    portalMenu: store.portal.portalMenu,
    modules: store.plans.modules,
  }
}

export default connect(mapStateToProps, null)(MenuContainer)
