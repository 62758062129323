import { Tab } from "@blueprintjs/core"
import { ButtonCancel, ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import ExternalLink from "@src/Components/ExternalLink"
import { Select } from "@src/Components/Form/Select"
import { Link } from "@src/Components/Link"
import ProgressWizard from "@src/Components/ProgressWizard"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import {
  ADD_TARGET_STEPS,
  FREQUENCY_OPTIONS,
} from "@src/Portal/Monitoring/constants"
import { SpinnerStyled } from "@src/Portal/Settings/styles"
import { colors } from "@src/theme"
import { tProbelyScanFrequency, ViewTypes } from "@src/types"
import { FormikProps } from "formik"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { IAddWebAppFormFields } from ".."
import { IEditScheduleFormFields } from "../../EditScheduleForm"
import { Row } from "../styles"
import FrequencyPanel from "./FrequencyPanel"
import { StyledTabs, Title } from "./styles"

interface IAddAppStepThree {
  formik: FormikProps<IAddWebAppFormFields | IEditScheduleFormFields>
  scanLevels: string[][]
  isAddNew?: boolean
  isNewSchedule?: boolean
  isLoading: boolean
  grayBackground?: boolean
  onBack?: () => void
}

const AddAppStepThree = ({
  formik,
  scanLevels,
  isAddNew,
  isNewSchedule,
  isLoading,
  grayBackground,
  onBack,
}: IAddAppStepThree) => {
  const history = useHistory()
  const { clientWidth } = usePortalSize()
  const plans = useSelector((store: tState) => store.plans)
  const { helpCenter } = useSelector((state: tState) => state.brand)
  const planFrequencies = plans.probelyScanFrequency
  // TODO channelFrequencies should be a list of all available frequencies for the channel and must be obtained from kita
  const channelFrequencies = planFrequencies

  const scanLevelsOptions = scanLevels.map((level) => ({
    value: level[1],
    label: level[0],
  }))

  const availableRecurrenceOptions = FREQUENCY_OPTIONS.filter((option) => {
    const frequencies = planFrequencies?.map((frequency) =>
      frequency.slice(0, 1).toLowerCase(),
    )
    return frequencies?.includes(option.value)
  })

  const getFrequency = (value: string) =>
    FREQUENCY_OPTIONS.find((option) => option.value === value)

  const tabOptions = FREQUENCY_OPTIONS.map((option) => {
    const recurrence: tProbelyScanFrequency =
      option.label.toUpperCase() as tProbelyScanFrequency
    return {
      recurrence: recurrence,
      id: option.value,
      title: option.label,
      isHidden: !channelFrequencies?.includes(recurrence),
      isDisabled: !planFrequencies?.includes(recurrence),
    }
  })

  React.useEffect(() => {
    if (isAddNew) {
      formik.setFieldValue("recurrence", availableRecurrenceOptions[0].value)
    }
  }, [])

  return (
    <div>
      <div className="bp5-dialog-body">
        {isAddNew && (
          <Row>
            <ProgressWizard
              steps={ADD_TARGET_STEPS}
              stepsCompleted={2}
              backgroundColor={grayBackground ? colors.gray25 : undefined}
            />
          </Row>
        )}
        <Row>
          <Section flex="flex-start" margin="0 0 30">
            <Section flexBasis="400px">
              <Section margin="0 0 12 0">
                <Title>Scan Level</Title>&nbsp;
                <span>
                  <ExternalLink
                    url={`https://${helpCenter}/hc/en-us/articles/360024578174`}
                    text="Learn more"
                  />
                </span>
              </Section>
              <Select
                label=""
                name="scanLevel"
                placeholder="Choose scan level"
                options={scanLevelsOptions}
                onChange={formik.setFieldValue}
                value={scanLevelsOptions.find(
                  (scanLevelsOption) =>
                    scanLevelsOption.value === formik.values.scanLevel,
                )}
                isSearchable={false}
              />
            </Section>
          </Section>
        </Row>
        <Row>
          <Section margin="0 0 12 0">
            <Title>Scan Frequency</Title> &nbsp;
            <span>
              <ExternalLink
                url={`https://${helpCenter}/hc/en-us/articles/360024578174`}
                text="Learn more"
              />
            </span>
          </Section>
          {clientWidth > 820 ? (
            <>
              <StyledTabs
                id="frequency-tabs"
                selectedTabId={formik.values.recurrence}
                large
                animate
                vertical
                onChange={(tabId) =>
                  formik.setFieldValue(
                    "recurrence",
                    tabId.toString().slice(0, 1),
                  )
                }
              >
                {tabOptions.map((options) =>
                  options.isHidden ? null : (
                    <Tab
                      key={options.id}
                      data-tip
                      data-for={options.isDisabled && "upgrade-tooltip"}
                      disabled={options.isDisabled}
                      id={options.id}
                      title={options.title}
                      panel={
                        <FrequencyPanel
                          formik={formik}
                          isAddNew={isAddNew}
                          isNewSchedule={isNewSchedule}
                        />
                      }
                    />
                  ),
                )}
              </StyledTabs>
              <Tooltip
                clickable
                offset={{ top: -15 }}
                id="upgrade-tooltip"
                type="light"
                content={
                  <p>
                    <Link onClick={() => history.push(ViewTypes.plan)}>
                      Upgrade
                    </Link>
                    &nbsp;to use
                  </p>
                }
              />
            </>
          ) : (
            <>
              <Select
                label=""
                name="recurrence"
                placeholder=""
                options={availableRecurrenceOptions}
                onChange={(name, recurrence) =>
                  formik.setFieldValue(name, recurrence)
                }
                value={getFrequency(formik.values.recurrence)}
                margin="0 0 26px"
                isSearchable={false}
              />
              <FrequencyPanel
                formik={formik}
                isAddNew={isAddNew}
                isNewSchedule={isNewSchedule}
              />
            </>
          )}
        </Row>
      </div>
      <Section flex="flex-end center" margin="0 20">
        <ButtonCancel width={100} onClick={onBack} type="button">
          {isAddNew ? "Back" : "Close"}
        </ButtonCancel>
        <ButtonSmall
          type="submit"
          id="add-web-app-schedule-button"
          width={150}
          disabled={
            (!(formik.isValid && formik.dirty) && !isNewSchedule) || isLoading
          }
        >
          {isLoading ? (
            <SpinnerStyled size={25} />
          ) : (
            `Schedule${isAddNew ? " Scan" : ""}`
          )}
        </ButtonSmall>
      </Section>
    </div>
  )
}

export default AddAppStepThree
