import { IRatingDetails } from "@src/types"

export const NO_COMPANY_URL_DATA: Record<string, IRatingDetails> = {
  server_software: {
    grade: "N/A",
    rating: "N/A",
    name: "Server Software",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 16,
  },
  dnssec: {
    grade: "N/A",
    rating: "N/A",
    name: "DNSSEC",
    category: "Diligence",
    beta: true,
    grade_color: "#495057",
    category_order: 1,
    order: 10,
  },
  mobile_application_security: {
    grade: "N/A",
    rating: "N/A",
    name: "Mobile Application Security",
    category: "Diligence",
    beta: true,
    grade_color: "#495057",
    category_order: 1,
    order: 20,
  },
  dkim: {
    grade: "N/A",
    rating: "N/A",
    name: "DKIM",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 6,
  },
  data_breaches: {
    grade: "N/A",
    rating: "N/A",
    name: "Security Incidents",
    category: "Public Disclosures",
    beta: false,
    grade_color: "#495057",
    category_order: 3,
    order: 19,
  },
  other_disclosures: {
    grade: "N/A",
    rating: "N/A",
    name: "Other Disclosures",
    category: "Public Disclosures",
    beta: true,
    grade_color: "#495057",
    category_order: 3,
    order: 21,
  },
  domain_squatting: {
    grade: "N/A",
    rating: "N/A",
    name: "Domain Squatting",
    category: "Diligence",
    beta: true,
    info: true,
    grade_color: "#495057",
    category_order: 1,
    order: 25,
  },
  exposed_credentials: {
    grade: "N/A",
    rating: "N/A",
    name: "Exposed Credentials",
    category: "User Behavior",
    beta: true,
    info: true,
    grade_color: "#495057",
    category_order: 2,
    order: 15,
  },
  file_sharing: {
    grade: "N/A",
    rating: "N/A",
    name: "File Sharing",
    category: "User Behavior",
    beta: false,
    grade_color: "#495057",
    category_order: 2,
    order: 13,
  },
  spf: {
    grade: "N/A",
    rating: "N/A",
    name: "SPF",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 5,
  },
  desktop_software: {
    grade: "N/A",
    rating: "N/A",
    name: "Desktop Software",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 17,
  },
  botnet_infections: {
    grade: "N/A",
    rating: "N/A",
    name: "Botnet Infections",
    category: "Compromised Systems",
    beta: false,
    grade_color: "#495057",
    category_order: 0,
    order: 0,
  },
  patching_cadence: {
    grade: "N/A",
    rating: "N/A",
    name: "Patching Cadence",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 12,
  },
  application_security: {
    grade: "N/A",
    rating: "N/A",
    name: "Web Application Headers",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 11,
  },
  spam_propagation: {
    grade: "N/A",
    rating: "N/A",
    name: "Spam Propagation",
    category: "Compromised Systems",
    beta: false,
    grade_color: "#495057",
    category_order: 0,
    order: 1,
  },
  unsolicited_comm: {
    grade: "N/A",
    rating: "N/A",
    name: "Unsolicited Communications",
    category: "Compromised Systems",
    beta: false,
    grade_color: "#495057",
    category_order: 0,
    order: 3,
  },
  ssl_configurations: {
    grade: "N/A",
    rating: "N/A",
    name: "SSL Configurations",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 8,
  },
  malware_servers: {
    grade: "N/A",
    rating: "N/A",
    name: "Malware Servers",
    category: "Compromised Systems",
    beta: false,
    grade_color: "#495057",
    category_order: 0,
    order: 2,
  },
  open_ports: {
    grade: "N/A",
    rating: "N/A",
    name: "Open Ports",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 9,
  },
  insecure_systems: {
    grade: "N/A",
    rating: "N/A",
    name: "Insecure Systems",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 15,
  },
  mobile_software: {
    grade: "N/A",
    rating: "N/A",
    name: "Mobile Software",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 18,
  },
  potentially_exploited: {
    grade: "N/A",
    rating: "N/A",
    name: "Potentially Exploited",
    category: "Compromised Systems",
    beta: false,
    grade_color: "#495057",
    category_order: 0,
    order: 4,
  },
  ssl_certificates: {
    grade: "N/A",
    rating: "N/A",
    name: "SSL Certificates",
    category: "Diligence",
    beta: false,
    grade_color: "#495057",
    category_order: 1,
    order: 7,
  },
}

export const DETAILS_DATA = [
  {
    title: "Compromised Systems",
    list: [
      "Compromised Systems are devices or machines in an organization’s network that show symptoms of malicious or unwanted software. These compromises can disrupt daily business operations and can increase an organization’s risk of breach.",
      "Compromised Systems are evaluated based on the number and type of malware, the severity, and the duration. For each risk vector, an overall letter grade is calculated from evaluations of each instance of compromise.",
      'For example, an organization could have an "F" for botnet infections, if they either had many botnets in a short period, or a few persistent botnets over months.',
    ],
    subCategories: [
      {
        title: "Botnet Infections",
        description:
          "Botnet Infection events indicate that devices on a company’s network were observed participating in botnets as either bots or Command and Control servers. Botnets can be used to exfiltrate corporate secrets and sensitive customer information, repurpose company resources for illegal activities, and serve as conduits for other infections.",
        list: [
          "Conduct a thorough security review of the machine (malware & antivirus sweep).",
          "Review services used on the machine, harden firewall rules.",
          "Improve employee computer safety training (phishing, installing unapproved software).",
        ],
      },
      {
        title: "Spam Propagation",
        description:
          "Spam Propagation events are observed when devices on a company’s network are sending unsolicited commercial or bulk email. This type of activity can damage a company’s reputation and cause legitimate company email to be caught in spam filters.",
        list: [
          "Track down infections and conduct a thorough security review of the machine (malware & antivirus sweep).",
          "Review services used on the machine, harden firewall rules.",
          "Improve employee computer safety training (phishing, installing unapproved software).",
        ],
      },
      {
        title: "Malware Servers",
        description:
          "Malware Server events occur when servers are observed engaging in malicious activity, such as hosting phishing, fraud or scam sites. Compromised servers can put employees and customers at risk by infecting devices that connect to company resources.",
        list: [
          "Track down infections and conduct a thorough security review of the machine (malware & antivirus sweep).",
          "Review services used on the machine, harden firewall rules.",
          "Improve employee computer safety training (phishing, installing unapproved software).",
        ],
      },
      {
        title: "Unsolicited Communications",
        description:
          "Unsolicited Communications events occur when devices attempt to communicate with servers that are not hosting any useful services. This type of activity not only shows that a device is compromised, but that it is actively seeking other devices to infect.",
        list: [
          "Track down infections and conduct a thorough security review of the machine (malware & antivirus sweep).",
          "Review services used on the machine, harden firewall rules.",
          "Improve employee computer safety training (phishing, installing unapproved software).",
        ],
      },
      {
        title: "Potentially Exploited",
        description:
          "Potentially Exploited events occur when browsers on a company’s network are infected with malware that is altering the user’s experience, such as adware. These events are often indicative of other infections.",
        list: [
          "Track down infections and conduct a thorough security review of the machine (malware & antivirus sweep).",
          "Review services used on the machine, harden firewall rules.",
          "Improve employee computer safety training (phishing, installing unapproved software).",
        ],
      },
    ],
  },
  {
    title: "Diligence",
    list: [
      "Diligence risk vectors show steps a company has taken to prevent attacks. BitSight currently evaluates SPF, DKIM, TLS/SSL, Open Port and DNSSEC information in assessing a company’s security diligence.",
      "All diligence records are evaluated as one of the following: Good, Fair, Warn, Bad or",
      "Neutral. Records are assessed using industry-standard criteria. For each diligence risk vector, an overall letter grade is calculated using the evaluations of each individual record.",
      "For example, if a company has three domains, and each of them has an effective SPF record, their overall SPF grade would be an “A”. Likewise, if none of the three domains have SPF records, their overall SPF grade would be an “F”.",
      "Records older than 60 days will not affect a company’s Security Rating.",
    ],
    subCategories: [
      {
        title: "SPF",
        description:
          "Properly configured SPF records help ensure that only authorized hosts can send email on behalf of a company by providing receiving mail servers the information they need to reject mail sent by unauthorized hosts. BitSight verifies that a company has SPF records on all domains that are sending or have attempted to send email, and that they are configured in a way that helps prevent email spoofing.",
        list: [
          "Create an SPF record. and conduct a thorough security review of the machine (malware & antivirus sweep).",
          "Check for common mistakes in your SPF record.",
          "All domains should have SPF records, even SMTP servers and those that aren’t configured to send mail. If a company does not intend to send mail from a domain, an attacker can still use that domain to spoof email.",
        ],
      },
      {
        title: "DKIM",
        description:
          "Properly configured DKIM records can help ensure that only authorized hosts can send email on the behalf of a company. BitSight verifies that a company uses DKIM and has configured it in a way that prevents email spoofing.",
        list: [
          "Search for Diligence records and then implement an effective DKIM record if one does not already exist. Please see our comprehensive article on How to create a DKIM record.",
          "Generate a new RSA keypair, specifying a bit strength of 2048 or larger. For elliptic curve keys, a length of 224 bits is recommended. Refer to the recommended key length. We follow NIST recommendations regarding key length.",
          "Refer to the recommended key rotation for how often to generate a new RSA keypair.",
          "Check that your keys are properly stored and the DKIM record has the correct key.",
        ],
      },
      {
        title: "SSL Certificates",
        description:
          "TLS/SSL certificates are used to encrypt traffic over the Internet. BitSight analyzes TLS/SSL certificates and provides information about their effectiveness. Certificates are responsible for verifying the authenticity of your company servers to your associates, clients, and guests, and serve as the basis for establishing cryptographic trust.",
        list: [
          "Review the Certificate Authority Best Practices and implement effective TLS/SSL certificates.",
          "Obtain valid and up-to-date TLS certificates from an industry certificate authority.",
          "Select a stronger signature algorithm (like SHA-256).",
        ],
      },
      {
        title: "SSL Configurations",
        description:
          "Evaluates TLS/SSL server configurations, which includes whether a company's servers have correctly configured security protocol libraries, and support strong encryption standards when making encrypted connections to other machines. Incorrect or weak configurations may make servers vulnerable to certain attacks (POODLE, Heartbleed).",
        list: [
          "Update and keep server implementations of TLS/SSL (OpenSSL, LibreSSL, etc); latest versions are patched against known vulnerabilities and they have countermeasures for other attacks.",
          "Refer to the TLS 1.0 and 1.1 deprecation schedule to see how this risk vector will be affected. Disable SSL v2, SSL v3, TLS 1.0, and TLS 1.1. Migrate to a minimum of TLS 1.2. Migrating to a later version (TLS 1.2 or TLS 1.3) is strongly encouraged.",
          "Regenerate Diffie-Hellman primes to be 2048 bits.",
          "Refer to the Guide to Deploying Diffie- Hellman for TLS to configure TLS securely.",
        ],
      },
      {
        title: "Open Ports",
        description:
          "Open Ports shows which port numbers and services are exposed to the Internet. Certain ports must be open to support normal business functions; however, unnecessary open ports provide ways for attackers to access a company’s network.",
        list: [
          "Embedded in every packet of network communication is the port number for that communication, which can be used to identify and block unwanted attempts to communicate over certain ports or ranges of ports not used by the company. Close unnecessary open ports.",
          "Audit the services running on a particular machine and ensure only vital services are running.",
          "Set up access to required services over a Virtual Private Network (VPN).",
          "Block specific or ranges of ports not used by the company in the company edge\n" +
            "network infrastructure. The port number is embedded in every packet of network communication, which can be used for port identification. View the full list of network ports in the IANA Service Name and Transport Protocol Port Number Registry.",
        ],
      },
      {
        title: "Web Application Headers",
        description:
          "This risk vector analyzes security-related fields in the header section of HTTP request and response messages. If configured correctly, these fields can help provide protection against malicious behavior, such as man-in-the-middle and cross-site scripting attacks. Different types of headers are required for HTTP/1.0, HTTP/1.1, and HTTPS.\n" +
          "See the Knowledge Base for a list of required headers for each protocol.",
        list: [
          "Records that affect a company’s Diligence grades have messages that provides an explanation and remediation. Refer to the Help and Remediation messages for additional details.",
          "Implement the required headers from the list of required headers and refer to the configuration requirements.",
          "Ensure application headers are created correctly and don’t contain misspellings (typos).",
        ],
      },
      {
        title: "Patching Cadence",
        description:
          "This risk vector evaluates how many systems in an organization's network infrastructure are affected by software vulnerabilities and how quickly the company resolved any issues. Vulnerabilities are publicly disclosed holes or bugs in software that can be used by attackers to gain unauthorized access to systems and data. Patches are updates to the affected software that resolve the vulnerability and close that particular avenue of attack.",
        list: [
          "Conduct general housekeeping on company infrastructure. Keep software, hardware, operating systems, and supporting libraries up-to-date. Doing so can make it easier to patch systems in case vulnerabilities appear in the future.",
          "Ensure your operating systems and supporting libraries are up-to-date with the atest patches. Implement automatic updates for critical systems.",
          "Ensure new systems introduced into your corporate network are free of known vulnerabilities. Staying informed on the latest threats is a simple way to be aware of any possible risks your company could acquire when bringing any new devices onto your network.",
          "Find out how quickly your critical vendors are patching vulnerabilities. Your organization’s security posture may be strong, but even one weak link in your supply chain can pose significant risk.",
        ],
      },
      {
        title: "Insecure Systems",
        description:
          "Insecure Systems is an indication of the number of an organization’s endpoints that are communicating with an unintended destination. The software of these endpoints may be outdated, tampered with, or misconfigured. “Endpoints” refer to any desktop computer, server, mobile device, media system, or appliance that has internet access. A system is classified as “insecure system” when these endpoints try to communicate with a web domain that doesn’t yet exist or isn’t registered to anyone.",
        list: [
          "Identify known insecure systems and uninstall or update the firmware of insecure applications (endpoints), as outlined in the remediation instructions for the record.",
        ],
      },
      {
        title: "Server Software",
        description:
          "The Server Software risk type can be used to create a rich picture about the software used by an organization. It helps track security holes created by server software that is no longer supported by its original developers or has become out-of-date (deprecated).",
        list: [
          "Identify out-of-date server software installations and update them.",
          "Ensure the organization has critical server software set to auto-update, if applicable, and if some of the organization’s production applications depend on certain unsupported versions, their software development teams will need to integrate the newer versions into their code base.",
          "Consult your operating system vendors’ software repositories and release notes for more information on supported server software for your organization.",
        ],
      },
      {
        title: "Desktop Software",
        description:
          "Desktop software are laptops, servers, and other non-tablet, non-phone computers in a company's network which access the internet. Outgoing communications from desktop software include metadata about the device's operating system and browser version; we compare the devices' version of OS and browser with currently released versions and software updates available for those systems, and determine whether those systems are supported or out of date.",
        list: [
          "Search for Diligence records and then identify and update unsupported mobile software.",
          "Set up auto-update methods for critical desktop software.",
          "Insufficient information prevents BitSight from identifying unsupported software. The use of software device management systems is recommended, along with integrating human processes that ensures systems in the organization are patched and the software is up-to- date.",
        ],
      },
      {
        title: "Mobile Software",
        description:
          "Mobile Software measures mobile devices, such as smartphones and tablets, that are accessing the Internet from an organization’s network. Outgoing communications from mobile devices include metadata about the device's operating system, browser version, and applications. The version information is compared with the latest and currently available versions in order to determine if the mobile device is running supported or out-of-date software.",
        list: [
          "Search for Diligence records and then identify and update unsupported mobile software.",
          "Set up auto-update methods for critical mobile software.",
          "Insufficient information prevents BitSight from identifying unsupported software. The use of mobile device management (MDM) systems is recommended, along with integrating human processes that ensures systems in the organization are patched and the software is up-to-date.",
        ],
      },
      {
        title: "DNSSEC",
        beta: true,
        description:
          "DNSSEC is a protocol that uses public key encryption to authenticate DNS servers. BitSight verifies whether a company is using DNSSEC and if it is configured effectively.",
        list: [
          "Set up DNSSEC for your domain, including generating the appropriate keys and updating DNS zone records.",
          "Generate a new Zone Signing Key using the RSA or DSA algorithm, with a key of 2048 bits or more.",
          "Download updated trust anchors and set them to be managed automatically.",
          "Add your DNSKEY to your DNS records through your registrar’s management interface.",
        ],
      },
      {
        title: "Mobile Application Security",
        beta: true,
        description:
          "This risk vector analyzes the security aspect of publicly available applications in official mobile marketplaces such as Apple App Store and Google Play.\n" +
          "* Risk Vector does not currently affect Security Ratings",
        list: [
          "Identify mobile applications that are not adhering to application security best practices.",
          "Verify questionnaire data from vendors. For example, to verify claims that their organization is free of a particular operating system.",
          "Understand which, if any, applications at an insured present a risk for known vulnerabilities and other threats.",
          "Verify quality and other contractual agreements with clients or vendors; for example, verify that a client created secure software from a security standpoint and adhered to a policy of keeping end-user operating systems up-to-date.",
          "If your company is developing and supporting apps for third party customers, please ensure your support emails and support URLs reflect the appropriate ownership information.",
        ],
      },
      {
        title: "Domain Squatting",
        info: true,
        description:
          "Domain squatting reports on the presence of registered domains named similarly to those owned by an organization. Attackers set up malicious software served by similar domain names to take advantage of organization visitors' mistyped URLs, and can trick users to opening malicious email attachment if recipients do not carefully check messages' domain names of origin.",
        list: [
          "Assess potential weaknesses in domain coverage. Work to register any potentially at-risk domains and to trademark your brand assets. Increase domain squatting coverage by requesting the addition of a secondary domain that legitimately belongs in your domain map.",
          "Implement a policy for domain squatting threats, including process for issuing takedown requests, taking legal action based on trademark infringement, and implementing firewalls/blocking mechanisms to protect against squatted domains.",
          "Verify completed questionnaires from critical vendors.",
          "Be wary of suspicious domains that are similar to official domains for a vendor, but not registered to their company.",
          "Understand if end users at an insured company are at risk for data loss, email phishing attacks, and other threats.",
        ],
      },
    ],
  },
  {
    title: "User Behavior",
    list: [
      "User Behavior looks at user file sharing activity that may introduce malicious software into a company, for example, by downloading a compromised file.",
      "User behavior records older than 60 days will not affect a company's grade.",
    ],
    subCategories: [
      {
        title: "File Sharing",
        description:
          "File sharing is the exchange of media and software, passed through a centralized server (File Transfer Protocol, email, instant messaging), distributed cloud storage services, or direct peer-to- peer channels such as BitTorrent, Gnutella.\n" +
          "BitSight only tracks file sharing over the BitTorrent protocol, when seen on company infrastructure, and records the sharing of such files as books, music, movies, TV shows, and applications.",
        list: [
          "File Sharing events coming from your company’s infrastructure can be found in the My Company ➔ User Behavior tab. The User Behavior Forensics add-on package provides specific details about File Sharing events.",
          "Use a firewall with Deep Packet Inspection to block torrent activity, as BitTorrent is difficult to block using standard port range rules.",
        ],
      },
      {
        title: "Exposed Credentials",
        info: true,
        description:
          "The Exposed Credentials risk vector indicates if employees of a company had their information disclosed as a result of a publicly disclosed data breach. Exposed Credentials is an informational risk vector and does not affect a company's Security Rating. Many websites do not validate email addresses, which makes it difficult to assert that certain exposed records are associated with a company's employees. Likewise, BitSight does not test that exposed credentials are valid, for example by trying a username and password exposed from a breached site, in order to preserve business confidence and trust.",
        list: [
          "Use Exposed Credentials as an opportunity to create or re-evaluate policies on information reuse, especially requirements concerning password reuse, password complexity, to address the potential risks associated with Exposed Credentials.",
          "Consider using 2-factor authentication as part of your organization’s user account security strategy.",
        ],
      },
    ],
  },
  {
    title: "Public Disclosures",
    list: [
      "Public Disclosure events provide information on breaches, general security incidents, and other disclosures related to possible incidents of undesirable access to a company's data.",
      "Only certain events in this category affect a company's rating and only if they occur, as opposed to having a percentage of the rating dedicated to them.",
    ],
    subCategories: [
      {
        title: "Security Incidents",
        description:
          "Breaches are publicly disclosed events of unauthorized access, often involving data loss or theft. These events are graded based on several factors, including the number of lost or exposed data records.\n" +
          "Note: Breaches have a negative impact on Security Ratings only if they occur and have a 120-day half-life. For instance, the remaining impact of a breach will be fewer than 20 points after 18 months for severe breaches and under 10 points for moderate breaches.",
        list: [
          "General Security Incidents are a diverse range of events related to the undesirable access of a company’s data and are considered more severe than Other Disclosures. Some categories of General Security Incidents are Ratings-impacting, while others are informational only and do not impact the rating. These events are graded based on several factors, including the number of lost or exposed data records.\n" +
            "Note: Ratings-impacting General Security Incidents have a negative impact on Security Ratings only if they occur and have a 120-day half-life. For instance, the remaining impact of a General Security Incident will be fewer than 20 points after 18 months for the most severe incidents and under 10 points for moderate ones.",
        ],
      },
      {
        title: "Other Disclosures",
        beta: true,
        description:
          "Other Disclosures are considered the least severe group of events within Public Disclosures. This category is used for incidents we learn about via public information, and through other means, that are judged to be minimal in their reflection on security posture. All categories of Other Disclosures are informational only and do not impact the rating.",
        list: [],
      },
    ],
  },
]

export const FIND_FULL_RISK_NAME: Record<string, string> = {
  SPF: "SPF Domains",
  DKIM: "DKIM Records",
  "SSL Certificates": "TLS/SSL Certificates",
  "SSL Configurations": "TLS/SSL Configurations",
}
