import { ButtonSmall } from "@src/Components/Button"
import {
  Content,
  FormElement,
  Heading,
  Panel,
  PanelContainer,
  SubHeading,
} from "@src/Components/Common"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import PasswordInput from "@src/Components/PasswordInput"
import { Section } from "@src/Components/StyledUtils"
import { Waiting } from "@src/Components/Waiting"
import { tState } from "@src/Model/Model"
import MessageContainer from "@src/Portal/MessageContainer"
import {
  checkResetPasswordMagicLinkWatcher,
  resetPasswordWatcher,
} from "@src/Sagas"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"

import { SpinnerStyled } from "./styles"

interface IRoutesParams {
  subscriptionId?: string
}

export const ResetPassword = () => {
  const history = useHistory()
  const { linkId }: { linkId: string } = useParams()
  const dispatch = useDispatch()
  const { clientHeight: height } = usePortalSize()
  const pContent = { height }

  React.useEffect(() => {
    dispatch(checkResetPasswordMagicLinkWatcher({ linkId }))
  }, [])

  const isLoadingCheckMagicLink = useSelector(
    (state: tState) => state?.account?.checkResetPasswordMagicLink.isLoading,
  )

  const isLoadingResetPassword = useSelector(
    (state: tState) => state?.account?.resetPassword.isLoading,
  )

  const { authenticated } = useSelector((store: tState) => store.portal)

  React.useEffect(() => {
    if (authenticated) {
      history.push("/")
    }
  }, [authenticated])

  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [confirmPasswordValid, setConfirmPasswordValid] = React.useState(false)
  const [passwordValid, setPasswordValid] = React.useState(false)

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setPassword(value)
    setConfirmPassword("")
    setConfirmPasswordValid(false)
  }

  const onConfirmPasswordChange = (event: any) => {
    const value = event.target.value
    setConfirmPassword(value)
  }
  const match = useRouteMatch<IRoutesParams>([
    "/forgotPassword/:subscriptionId",
  ])
  const subscriptionId = match?.params?.subscriptionId

  const handleResetPassword = () => {
    dispatch(resetPasswordWatcher({ linkId, password, subscriptionId }))
  }

  return isLoadingCheckMagicLink ? (
    <Content {...pContent} backgroundImage="empty">
      <Waiting text="Verifying Link" />
    </Content>
  ) : (
    <Content {...pContent}>
      <MessageContainer />
      <PanelContainer height={height}>
        <Panel>
          <Heading align="left" margin="20px 0px" fontSize="24px">
            Reset your account password.
          </Heading>
          <SubHeading align="left" textAlign="left">
            Choose a strong password. Use it only for this
            <br />
            account.
            {/* <span>
              <ExternalLink url="" text="Learn more" />
            </span> */}
          </SubHeading>
          <SubHeading align="left" textAlign="left">
            You will be signed out of Cyber Safety on your <br />
            other devices.
          </SubHeading>
          <FormElement>
            <PasswordInput
              id="reset-password-password"
              label="Create password"
              value={password}
              onChange={onPasswordChange.bind(this)}
              isValid={(valid: boolean) => {
                setPasswordValid(valid)
              }}
            />
            <PasswordInput
              id="reset-password-confirm-password"
              label="Confirm password"
              passwordToMatch={password}
              value={confirmPassword}
              onChange={onConfirmPasswordChange.bind(this)}
              isValid={(valid: boolean) => {
                setConfirmPasswordValid(valid)
              }}
              noValidation={true}
            />
            <Section w="100%" margin="30 0 30 0">
              <ButtonSmall
                onClick={handleResetPassword.bind(this)}
                id="CyberSafety_Trial_Lead_Button"
                disabled={
                  !(password && passwordValid && confirmPasswordValid) ||
                  isLoadingResetPassword
                }
              >
                {isLoadingResetPassword ? (
                  <SpinnerStyled size={25} />
                ) : (
                  "Set Password"
                )}
              </ButtonSmall>
            </Section>
          </FormElement>
        </Panel>
      </PanelContainer>
    </Content>
  )
}
