import { Link } from "@src/Components/Link"
import { colors, media } from "@src/theme"
import styled from "styled-components"

interface ILinkStyled {
  isButton?: boolean
  padding?: string
  isLastQuestion?: boolean
}

interface INavigationPanel {
  isLastQuestion?: boolean
  maxWidth?: string
}

export const Header = styled.h1`
  margin: 0 0 15px;
  color: ${colors.darkBlue};
`

export const Img = styled.img<any>`
  ${(p) => (p.hidden ? "display: none;" : "")}
  margin:${(p) => p.margin || "0px"};
  cursor: ${(p) => p.cursor || "default"};
  transform: rotate(${(p) => (p.reverse ? "180" : "0")}deg);
  max-width: 100%;
`

export const ProgressPanel = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: #f7fafe; // TODO move to constant
  border: 1px solid #afcdf3; // TODO move to constant
  border-radius: 6px;
  padding: 8px 0;
  @media (max-width: 560px) {
    flex: 1;
  }
`

export const ProgressTabPanel = styled.div<any>`
  display: flex;
  align-items: center;
  padding: 0 15px;
  line-height: 20px;
  text-align: center;
  font-weight: ${(p) => p.selected && "700"};
  color: ${(p) => p.selected && colors.darkBlue};
  overflow: hidden;
  cursor: pointer;
  &:hover {
    color: ${colors.link};
  }
  @media (max-width: 880px) {
    padding: 0 8px;
    font-size: 14px;
  }
  @media (max-width: 460px) {
    padding: 0 2px;
    font-size: 12px;
  }
`

export const Divider = styled.div`
  width: 1px;
  background-color: ${colors.gray40};
`
export const ButtonBack = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50px;
  background-color: ${colors.darkBlue};
  cursor: pointer;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  font-size: 12px;
}
  &:hover {
    background-color: ${colors.violet};
  }
`
export const Filler = styled.div<any>`
  flex-shrink: 0;
  width: ${(p) => p.height / 2}px;
  height: 100%;
  background-color: ${(p) => p.color || "#fff"};
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
`

export const QuestionText = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  @media ${media.medium} {
    font-size: 18px;
    line-height: 28px;
  }
`

export const QuestionPanel = styled.div<{
  selected: boolean
  activeState: boolean
}>`
  width: 100%;
  max-width: 858px;
  border: 1px solid rgba(0, 98, 217, 0.5);
  border-radius: 3px;
  padding: 10px 20px;
  margin-bottom: 16px;
  background-color: ${({ selected, activeState }) =>
    selected || activeState ? "#e5f8ff" : "#fff"};
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      background-color: #e5f8ff; // TODO add a constant
      color: ${({ selected }) => !selected && colors.black};
      box-shadow: 0 0 5px rgba(0, 98, 217, 0.2);
    }
  }
`

export const ContentPanel = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 45px;
  width: 100%;
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1150px) {
    flex-direction: column;
  }
`

export const LinksContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  @media (max-width: 576px) {
    a {
      &:first-child {
        padding-right: 10px;
      }
    }
  }
`

export const LinkStyled = styled(Link)<ILinkStyled>`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.darkBlue};
  &:hover {
    color: ${({ isButton }) => isButton && colors.violet};
    text-decoration: ${({ isButton }) => isButton && "none"};
    cursor: ${({ isLastQuestion }) => isLastQuestion && "auto"};
  }
  i {
    padding: 0 10px;
  }
`

export const NavigationPanel = styled.div<INavigationPanel>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "858px"};
  margin-top: 32px;
  padding: 10px 20px;
  background-color: #f7fafe; // TODO move to constant
  border: 1px solid #afcdf3; // TODO move to constant
  border-radius: 6px;
  @media (max-width: 576px) {
    img {
      border-right: ${({ isLastQuestion }) =>
        !isLastQuestion && "1px solid ${colors.gray40}"};
      padding-right: 20px;
    }
  }
`

export const InterstitialHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 740px;
  font-size: 32px;
  font-weight: 700;
  color: ${colors.darkBlue};
  font-family: montserrat;
`

export const InterstitialBody = styled.div`
  flex-grow: 1;
  max-width: 740px;
  padding: 16px 0;
`
