import * as React from "react"

import { Description, SubHeading } from "../../styles"

export const EmptyStateCard = () => (
  <>
    <SubHeading>Improve your team’s security awareness.</SubHeading>
    <Description>
      Strengthen your first line of defense by providing your team with security
      training. Address each team member’s weakest areas of knowledge first.
    </Description>
  </>
)
