import {
  align,
  css,
  flex,
  height,
  hidden,
  margin,
  padding,
  size,
  width,
} from "@src/Components/StyledUtils"
import * as React from "react"
import styled from "styled-components"

export const BluePanel = styled.div<any>`
  border-radius: 4px;
  border: solid 1px rgba(0, 98, 217, 0.5);
  background-color: rgba(0, 98, 217, 0.05);
  ${margin}
  ${padding}
  ${width}
  ${height}
  ${size}
`
export const Columns = styled.div<any>`
  display: flex;
  adjust-content: left;
  flex-wrap: wrap;
  ${height}
  ${margin}
  ${css}
`

export const Section = styled.div<any>`
  ${margin}
  ${padding}
  ${width}
  ${height}
  ${align}
  ${flex}
  ${hidden};
  ${css}
`

interface IContent {
  margin?: string
  padding?: string
  w?: number | string | undefined
  h?: number | string | undefined
  css?: string | undefined
  children?: any
  title?: any
  footer?: any
  hidden?: boolean
  titleId?: string
}

const SContent = styled.div<any>`
  display: flex;
  flex-direction: column;
  ${margin}
  ${padding}
  ${width}
  ${height}
  ${css}
  ${hidden}
`
const ContentArea = styled.div`
  flex: 1 1 auto;
`

export const Content = (props: IContent) => {
  return (
    <SContent {...props}>
      <ContentTitle id={props.titleId} hidden={!props.title}>
        {props.title}
      </ContentTitle>
      <ContentArea>{props.children}</ContentArea>
      <ContentFooter hidden={!props.footer}>{props.footer}</ContentFooter>
    </SContent>
  )
}

export const ContentFooter = styled.div`
  ${hidden}
`

export const ContentTitle = styled.div<any>`
  font-family: proxima-nova, sans-serif;
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212528;
  margin: 0px 0px 20px 0px;
  ${hidden}
`

export const Heading = styled.div<any>`
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.05;
  letter-spacing: normal;
  color: #212528;
  ${margin}
`

export const CircleNumber = styled.div`
  width: 32px;
  height: 32px;
  border: solid 1px #979797;
  border-radius: 16px;
  background-color: #ffffff;
  text-align: center;
  line-height: 32px;
`

export const Img = styled.img<any>`
  ${width}
  ${height}
  ${size}
  ${hidden}
`

export const InfoText = styled.div<any>`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212528;
  ${margin}
  ${width}
  ${height}
  ${align}
  ${css}
  ${hidden}
`

export const Link = styled.div<any>`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0062d8;
  cursor: pointer;
  ${margin}
  ${padding}
  ${width}
  ${height}
  ${css}
`

export const LinkFooter = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0062d8;
  cursor: pointer;
  text-indent: 30px;
  border-top: solid 1px #d6d6d6;
  line-height: 46px;
  height: 46px;
  width: 100%;
`
