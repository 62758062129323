import C from "@src/Controller"
import { Store } from "redux"

import * as Action from "../Actions/MonitoringActions"
import { serverLog } from "./Logging"
import * as Middletier from "./Middletier"

class Monitoring {
  private store: Store

  constructor(store: Store) {
    this.store = store
  }

  createTarget(
    targetName: string,
    targetUrl: string,
    scanProfile: string,
    recurrence: string,
    callback?: any,
  ) {
    C.Plans.getFeatureUsageProfile()
    Middletier.xhrPost(
      "/monitoring/webapps/createTarget",
      JSON.stringify({ targetName, targetUrl, scanProfile, recurrence }),
      (response: any) => {
        if (response.success) {
          this.loadTargetList(() => {
            C.toastNotification(`Website is successfully created`, "success")
            callback && callback({ success: true, data: response.data })
          })
        } else {
          C.toastNotification(`Create Website failed`, "danger")
          serverLog({
            level: "error",
            message: "Create Target failed",
            endpoint: "/monitoring/webapps/createTarget",
            filename: __filename,
            payload: response,
          })
          callback && callback({ success: false, error: response.error })
        }
      },
    )
  }

  scheduleScan(
    recurrence: string,
    targetId: string,
    dateTime: Date,
    scanLevel?: string,
    callback?: any,
  ) {
    C.Plans.getFeatureUsageProfile()
    const dateTimeIso = dateTime.toISOString().split(".")[0] + "Z"
    Middletier.xhrPost(
      "/monitoring/webapps/scheduleScan",
      JSON.stringify({ recurrence, targetId, dateTime: dateTimeIso }),
      (response: any) => {
        if (response.success) {
          scanLevel
            ? this.editTarget(
                targetId,
                undefined,
                scanLevel,
                recurrence,
                (res: any) => {
                  if (res.success) {
                    this.loadScheduleMap(() => {
                      C.toastNotification(
                        "Scan is successfully scheduled",
                        "success",
                      )
                      callback &&
                        callback({
                          success: true,
                          data: response.data,
                        })
                    })
                  } else {
                    C.toastNotification("Schedule scan failed", "danger")
                    callback &&
                      callback({
                        success: false,
                      })
                  }
                },
              )
            : this.loadScheduleMap(() => {
                C.toastNotification("Scan successfully scheduled", "success")
                callback &&
                  callback({
                    success: true,
                    data: response.data,
                  })
              })
        } else {
          C.toastNotification("Schedule Scan failed", "danger")
          serverLog({
            level: "error",
            message: "Schedule Scan failed",
            endpoint: "/monitoring/webapps/scheduleScan",
            filename: __filename,
            payload: response,
          })
          callback && callback({ success: false })
        }
      },
    )
  }

  syncScans(targetId: string, callback?: any) {
    console.log("Sync scans called")
    C.Plans.getFeatureUsageProfile()
    Middletier.xhrPost(
      "/monitoring/webapps/syncScans",
      JSON.stringify({ targetId }),
      (response: any) => {
        if (response.success) {
          callback &&
            callback({
              success: true,
              data: response.data,
            })
        } else {
          serverLog({
            level: "error",
            message: "Sync Scan failed",
            endpoint: "/monitoring/webapps/syncScans",
            filename: __filename,
            payload: response,
          })
          callback && callback({ success: false })
        }
      },
    )
  }

  updateSchedule(targetId: string, callback?: any) {
    console.log(`Update Schedule called for target ${targetId}`)
    C.Plans.getFeatureUsageProfile()
    Middletier.xhrPost(
      "/monitoring/webapps/probelyUpdateSchedule",
      JSON.stringify({ targetId }),
      (response: any) => {
        if (response.success) {
          callback &&
            callback({
              success: true,
              data: response.data,
            })
        } else {
          serverLog({
            level: "error",
            message: "Sync Scan failed",
            endpoint: "/monitoring/webapps/syncScans",
            filename: __filename,
            payload: response,
          })
          callback && callback({ success: false })
        }
      },
    )
  }

  editTarget(
    targetId: string,
    targetName?: string,
    scanLevel?: string,
    recurrence?: string,
    callback?: (arg?: unknown) => unknown,
  ) {
    C.Plans.getFeatureUsageProfile()
    Middletier.xhrPost(
      "/monitoring/webapps/editTarget",
      JSON.stringify({ targetName, targetId, scanLevel, recurrence }),
      (response: any) => {
        if (response.success) {
          this.loadTargetList(() => {
            if (targetName) {
              C.toastNotification(
                "Website name is successfully updated",
                "success",
              )
            }
            callback && callback({ success: true, data: response.data })
          })
        } else {
          if (targetName) {
            C.toastNotification("Website name update failed", "danger")
          }
          serverLog({
            level: "error",
            message: "Edit Target failed",
            endpoint: "/monitoring/webapps/editTarget",
            filename: __filename,
            payload: response,
          })
          callback && callback({ success: false })
        }
      },
    )
  }

  deleteTarget(targetId: string, targetName: string, callback?: any) {
    C.Plans.getFeatureUsageProfile()
    Middletier.xhrPost(
      "/monitoring/webapps/deleteTarget",
      JSON.stringify({ targetId, targetName }),
      (response: any) => {
        if (response.success) {
          this.loadTargetList(() => {
            C.toastNotification("Website is successfully removed", "success")
            callback && callback({ success: true, data: response.data })
          })
        } else {
          C.toastNotification("Remove Website failed", "danger")
          serverLog({
            level: "error",
            message: "Delete Target failed",
            endpoint: "/monitoring/webapps/deleteTarget",
            filename: __filename,
            payload: response,
          })
          callback && callback({ success: false })
        }
      },
    )
  }

  loadTargetList(callback?: any) {
    C.Plans.getFeatureUsageProfile()
    console.log("loadTargetList called")
    Middletier.xhrPost(
      "/monitoring/webapps/loadTargetList",
      JSON.stringify({}),
      (response: any) => {
        if (response.success) {
          this.store.dispatch(Action.setTargetList(response.data))
          callback && callback({ success: true, data: response.data })
        } else {
          serverLog({
            level: "error",
            message: "Loading Target List Failed",
            endpoint: "/monitoring/webapps/loadTargetList",
            filename: __filename,
            payload: response,
          })
          callback && callback({ success: false })
        }
      },
    )
  }

  loadScheduleMap(callback?: any) {
    C.Plans.getFeatureUsageProfile()
    console.log("loadScheduleMap called")
    Middletier.xhrPost(
      "/monitoring/webapps/loadScheduleMap",
      JSON.stringify({}),
      (response: any) => {
        if (response.success) {
          this.store.dispatch(Action.setScheduleMap(response.data))
          serverLog({
            level: "info",
            message: "Loading Schedule Map Success",
            endpoint: "/monitoring/webapps/loadScheduleMap",
            filename: __filename,
            payload: response.data,
          })
          callback && callback({ success: true, data: response.data })
        } else {
          serverLog({
            level: "error",
            message: "Loading Schedule Map Failed",
            endpoint: "/monitoring/webapps/loadScheduleMap",
            filename: __filename,
            payload: response,
          })
          callback && callback({ success: false })
        }
      },
    )
  }

  loadScanMap(callback?: any) {
    C.Plans.getFeatureUsageProfile()
    console.log("loadScanMap called")
    Middletier.xhrPost(
      "/monitoring/webapps/loadScanMap",
      JSON.stringify({}),
      (response: any) => {
        if (response.success) {
          this.store.dispatch(Action.setScanMap(response.data))
          callback && callback({ success: true, data: response.data })
        } else {
          serverLog({
            level: "error",
            message: "Loading Target List Failed",
            endpoint: "/monitoring/webapps/loadTargetList",
            filename: __filename,
            payload: response,
          })
          callback && callback({ success: false })
        }
      },
    )
  }

  getScanReport(
    reportName: string,
    targetId: string,
    scanId: string,
    reportType: string,
  ) {
    const reportNameEncoded = encodeURIComponent(reportName)
    window.open(
      `/api/monitoring/webapps/getScanReport/${reportNameEncoded};${targetId};${scanId};${reportType}`,
    )
  }

  editSchedule(
    recurrence: string,
    targetId: string,
    dateTime: Date,
    scheduledScanId: string,
    scanLevel?: string,
    callback?: any,
  ) {
    C.Plans.getFeatureUsageProfile()
    const dateTimeIso = dateTime.toISOString().split(".")[0] + "Z"
    Middletier.xhrPost(
      "/monitoring/webapps/editSchedule",
      JSON.stringify({
        recurrence,
        targetId,
        dateTime: dateTimeIso,
        scheduledScanId,
      }),
      (response: any) => {
        if (response.success) {
          scanLevel
            ? this.editTarget(
                targetId,
                undefined,
                scanLevel,
                recurrence,
                (res: any) => {
                  if (res.success) {
                    this.loadScheduleMap(() => {
                      C.toastNotification(
                        "Scan schedule successfully updated",
                        "success",
                      )
                      callback &&
                        callback({
                          success: true,
                          data: response.data,
                        })
                    })
                  } else {
                    C.toastNotification("Scan schedule update failed", "danger")
                    callback &&
                      callback({
                        success: false,
                      })
                  }
                },
              )
            : this.loadScheduleMap(() => {
                C.toastNotification(
                  "Scan schedule successfully updated",
                  "success",
                )
                callback &&
                  callback({
                    success: true,
                    data: response.data,
                  })
              })
        } else {
          C.toastNotification("Scan schedule update failed", "danger")
          serverLog({
            level: "error",
            message: "Schedule Scan failed",
            endpoint: "/monitoring/webapps/scheduleScan",
            filename: __filename,
            payload: response,
          })
          callback && callback({ success: false })
        }
      },
    )
  }
}

export default Monitoring
