import * as React from "react"
import styled from "styled-components"

interface IPagination {
  items: any[]
  onChangePage: (pageOfItems: any[], pageNumber: number) => void
  initialPage: number
  pageSize: number
}

interface IPaginationState {
  pager: {
    totalItems: number
    currentPage: number
    pageSize: number
    totalPages: number
    startPage: number
    endPage: number
    startIndex: number
    endIndex: number
    pages: any[]
  }
}

class Pagination extends React.Component<IPagination, IPaginationState> {
  public state: IPaginationState = {
    pager: {
      totalItems: 0,
      currentPage: 0,
      pageSize: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      startIndex: 0,
      endIndex: 0,
      pages: [],
    },
  }
  UNSAFE_componentWillMount() {
    const { items, pageSize, initialPage } = this.props
    if (this.props.items && this.props.items.length) {
      // get new pager object for specified page
      const pager = this.getPager(items.length, initialPage, pageSize)

      // get new page of items from items array
      const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1)

      // update state
      this.setState({ pager: pager })

      // call change page function in parent component
      this.props.onChangePage(pageOfItems, initialPage)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPagination) {
    if (this.props.items !== nextProps.items) {
      this.setPage(this.props.initialPage)
    }
  }

  setPage(page: number) {
    const { items, pageSize } = this.props
    let pager = this.state.pager

    if (page < 1 || page > pager.totalPages) {
      return
    }

    // get new pager object for specified page
    pager = this.getPager(items.length, page, pageSize)

    // get new page of items from items array
    const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1)

    // update state
    this.setState({ pager: pager })

    // call change page function in parent component
    this.props.onChangePage(pageOfItems, page)
  }

  getPager(totalItems: number, currentPage: number, pageSize: number) {
    // default to first page
    currentPage = currentPage || 1

    // default page size is 10
    pageSize = pageSize || 10

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize)

    let startPage: number, endPage: number
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1
      endPage = totalPages
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1
        endPage = 10
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9
        endPage = totalPages
      } else {
        startPage = currentPage - 5
        endPage = currentPage + 4
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i,
    )

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    }
  }

  render() {
    const pager = this.state.pager

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null
    }

    return (
      <PaginationHolder>
        <Link
          disabled={pager.currentPage === 1}
          onClick={() => this.setPage(1)}
        >
          &lt;&lt;
        </Link>
        <Link
          disabled={pager.currentPage === 1}
          onClick={() => this.setPage(pager.currentPage - 1)}
        >
          &lt;
        </Link>
        {pager.pages.map((page, index) => (
          <Link
            key={`${page}-${index}`}
            active={pager.currentPage === page}
            onClick={() => this.setPage(page)}
          >
            {page}
          </Link>
        ))}
        <Link
          disabled={pager.currentPage === pager.totalPages}
          onClick={() => this.setPage(pager.currentPage + 1)}
        >
          &gt;
        </Link>
        <Link
          disabled={pager.currentPage === pager.totalPages}
          onClick={() => this.setPage(pager.totalPages)}
        >
          &gt;&gt;
        </Link>
      </PaginationHolder>
    )
  }
}

const PaginationHolder = styled.div`
  display: flex;
  flex-direction: row;
`

const Link = styled.div<any>`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  background-color: ${(props: any) => (props.active ? "#0062d8" : "#ffffff")};
  color: ${(props: any) => (props.active ? "#ffffff" : "#6f7379")};
  text-align: center;
  padding: 8px 12px;
  display: ${(props: any) => (props.disabled ? "none" : "flex")};
  box-shadow:
    1px 0 0 0 #d6d6d6,
    0 1px 0 0 #d6d6d6,
    1px 1px 0 0 #d6d6d6,
    1px 0 0 0 #d6d6d6 inset,
    0 1px 0 0 #d6d6d6 inset;
  &:hover {
    background-color: ${(props: any) => (props.active ? "#0062d8" : "#eaeaea")};
    cursor: pointer;
  }
`

export default Pagination
