import { Section } from "@src/Components/StyledUtils"
import { colors, media } from "@src/theme"
import styled from "styled-components"

export const InfoTextStyled = styled.div`
  color: ${colors.gray20};
  font-size: 18px;
  font-family: proxima-nova, sans-serif;
`
export const BodyHead = styled.h3`
  color: ${colors.darkBlue};
  font-size: 24px;
  margin: 0 0 8px 0;
  letter-spacing: 0.72px;
  font-family: montserrat;
  @media ${media.medium} {
    max-width: 500px;
  }
`
export const BodyText = styled.p`
  font-size: 18px;
  margin: 0 0 15px 0;
  font-family: proxima-nova, sans-serif;
`
export const CardBodyStyled = styled.div`
  width: 100%;
  @media (max-width: 500px) {
    text-align: center;
    img {
      display: none;
    }
  }
`
export const CardBodyFooterStyled = styled(Section)`
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    button {
      margin-bottom: 15px;
    }
  }
`
export const CardIcon = styled.img`
  max-width: 85px;
  width: 100%;
  margin-right: 16px;
`
export const Heading = styled.div<any>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 0 24px;
  font-family: montserrat;
  font-size: ${(props: any) => props.fontSize || "32px"};
  font-weight: 600;
  text-align: ${(props: any) => props.align || "left"};
  align-self: ${(props: any) => props.align || "left"};
  justify-content: ${(props: any) => props.align || "left"};
  color: ${(props: any) => props.color || colors.darkBlue};
  line-height: ${(props: any) => props.lineHeight || "38px"};
  letter-spacing: 0.005em;
  align-items: center;
  margin: ${(props: any) => props.margin};
  width: ${(props: any) => props.width};
  text-transform: ${(props: any) => props.uppercase && "uppercase"};
  @media ${media.medium} {
    font-size: ${(props: any) => props.fontSizeMobile || "27px"};
    display: flex;
    align-items: end;
    line-height: inherit;
    margin: 0 0 0 0;
  }
`
