import * as _ from "lodash"

import * as ACTION from "../../Actions/ActionTypes"
import { tPortalState } from "../../Model/Model"
import initial_state from "../../Model/Model"
import * as T from "../../types"

interface tAction {
  type: string
  payload: any
}

function reducer(state: tPortalState, action: tAction) {
  if (!state) {
    state = initial_state.portal
  }
  // NOTE: return state types should match the store definition
  // action.payload type should be resolved

  switch (action.type) {
    case ACTION.SET_AUTHENTICATED: {
      const payload: {
        authenticated: boolean | null
        cognitoId: string | null
        newUser: string | null
        isZeguroServiceSession: boolean
        maintenance: T.IMaintenance
      } = action.payload
      const { cognitoId, authenticated, newUser, isZeguroServiceSession } =
        payload
      return {
        ...state,
        cognitoId,
        authenticated,
        newUser,
        isZeguroServiceSession,
      }
    }
    case ACTION.SET_DASHBOARD_ACTIVITY: {
      const dashboard: T.tDashboard = action.payload
      return { ...state, dashboard: { ...state.dashboard, ...dashboard } }
    }
    case ACTION.SET_QUOTE_TO_PURCHASE: {
      const quoteToPurchase = action.payload
      return { ...state, dashboard: { ...state.dashboard, quoteToPurchase } }
    }
    case ACTION.SET_DASHBOARD_MONITORING: {
      const monitoring = action.payload
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          monitoring: monitoring.data,
          monitoringStatus: monitoring.status,
        },
      }
    }
    case ACTION.SET_PORTAL_MENU: {
      const item: string = action.payload.item
      return { ...state, portalMenu: item }
    }
    case ACTION.SET_ENVIRONMENT:
      return { ...state, environment: action.payload }
    case ACTION.SET_CONFIG:
      return { ...state, config: action.payload }
    case ACTION.SET_PRODUCTION: {
      const {
        isProduction,
        productionMode,
      }: { isProduction: boolean; productionMode: string } = action.payload
      return { ...state, isProduction, productionMode }
    }
    case ACTION.SET_COMPANY_PROFILE: {
      const payload: {
        profile: T.tCompanyProfile
        orgId: string
        companyProfileState: T.tStatus
      } = action.payload
      const { profile, orgId, companyProfileState } = payload
      return {
        ...state,
        companyProfile: profile,
        orgId,
        companyProfileState,
      }
    }
    case ACTION.SET_USER_PROFILE: {
      const userProfile: T.tUserProfile = action.payload
      return { ...state, userProfile }
    }
    case ACTION.SET_SIGNUP_DATA: {
      const signupData: T.ISignupData = action.payload
      return { ...state, signupData }
    }
    case ACTION.SET_USER_EMAIL: {
      const email: string = action.payload
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          email,
        },
      }
    }
    case ACTION.SET_LEGAL_DISCLAIMER: {
      const disclaimer: string = action.payload
      return { ...state, disclaimer }
    }

    case ACTION.SET_ACCOUNTS_PAGE_MODE: {
      const accountsPageMode: string = action.payload
      return { ...state, accountsPageMode }
    }

    case ACTION.SET_PORTAL_ENVIRONMENT: {
      const { ClientId, UserPoolId, maintenance } = action.payload

      const userPool: T.tUserPool = {
        ClientId,
        UserPoolId,
      }
      return { ...state, userPool, maintenance }
    }

    case ACTION.SET_USER_LIST: {
      const userList: T.tUserList = action.payload
      return { ...state, userList }
    }

    case ACTION.SET_USER_INVITE_LIST: {
      const userInviteList: T.tUserInviteList = action.payload
      return { ...state, userInviteList }
    }

    case ACTION.SET_USER_LIST_ERROR: {
      const error = action.payload
      return { ...state, userListError: error }
    }

    case ACTION.SET_USER_INVITE_LIST_ERROR: {
      const error = action.payload
      return { ...state, userInviteListError: error }
    }

    case ACTION.SET_MAGIC_LINK_SIGNUP: {
      let magicLinkSignupFetchResponse: T.tMagicLinkSignupFetchResponse =
        action.payload
      magicLinkSignupFetchResponse = _.omitBy(
        magicLinkSignupFetchResponse,
        _.isUndefined,
      )
      return {
        ...state,
        magicLinkSignup: {
          ...state.magicLinkSignup,
          ...magicLinkSignupFetchResponse,
        },
      }
    }

    default:
      return state
  }
}

export default reducer
