import * as React from "react"
import ReactTooltip from "react-tooltip"

import { TooltipStyled } from "./styles"

interface IOffset {
  top?: number
  right?: number
  left?: number
  bottom?: number
}
interface ITooltipProps {
  id: string
  width?: string
  content: JSX.Element
  clickable?: boolean
  place?: "top" | "right" | "bottom" | "left"
  effect?: "float" | "solid"
  type?: "dark" | "light"
  textAlign?: "right" | "left" | "center"
  offset?: IOffset
  pointerEvents?: string
}

const Tooltip: React.FC<ITooltipProps> = ({
  id,
  content,
  width = "450px",
  place = "top",
  effect = "solid",
  type = "dark",
  textAlign = "left",
  clickable = false,
  pointerEvents,
  offset,
}) => {
  return (
    <TooltipStyled
      width={width}
      type={type}
      textAlign={textAlign}
      pointerEvents={pointerEvents}
    >
      <ReactTooltip
        className="tooltip-content-custom"
        id={id}
        aria-haspopup="true"
        type={type}
        effect={effect}
        place={place}
        clickable={clickable}
        delayHide={300}
        delayShow={150}
        offset={offset}
        overridePosition={({ left, top }, _e, _t, node) => ({
          //Need for fix tooltip position on mobile view
          top,
          left: typeof node === "string" ? left : Math.max(left, 0),
        })}
      >
        {content}
      </ReactTooltip>
    </TooltipStyled>
  )
}

export default Tooltip
