import * as AmazonCognitoIdentity from "amazon-cognito-identity-js"
import { Store } from "redux"

// import AWS from "aws-sdk";
import * as T from "../types"
import { serverLog } from "./Logging"
export let cognitoUser: AmazonCognitoIdentity.CognitoUser

import C from "../Controller"

class Cognito {
  private store: Store

  constructor(store: Store) {
    this.store = store
  }

  getPoolData = () => {
    const state = this.store.getState()
    const poolData: T.tUserPool = state.portal.userPool
    return poolData
  }

  getCognitoUser = (username: string) => {
    const poolData = this.getPoolData()
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const userData = {
      Username: username,
      Pool: userPool,
    }
    return new AmazonCognitoIdentity.CognitoUser(userData)
  }

  resetPassword = (
    Username: string,
    newPassword: string,
    verificationCode: string,
    callback: {
      onSuccess: any
      onFailure: any
    },
  ) => {
    cognitoUser = this.getCognitoUser(Username)

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: callback.onSuccess,
      onFailure: callback.onFailure,
    })
  }

  forgotPassword = (username: string, onSuccess: any, onFailure: any) => {
    cognitoUser = this.getCognitoUser(username)

    cognitoUser.forgotPassword({
      onSuccess: function () {},
      onFailure: function (err) {
        const isWrongEmail =
          err.message === "Username/client id combination not found."
        onFailure(
          isWrongEmail ? "Please enter a valid email address." : err.message,
        )
        serverLog({
          level: "error",
          message: "Forgot Password Failed",
          endpoint: "cognitoUser.forgotPassword",
          filename: __filename,
          stack: err,
        })
      },
      inputVerificationCode(data: any) {
        onSuccess(
          `Verification code has been sent to ${data.CodeDeliveryDetails.Destination}. Please check your email`,
        )
      },
    })
  }

  changePasswordAuthenticate = async (username: string, password: string) => {
    const authenticate = this.authenticate
    const result = await new Promise((resolve, reject) => {
      authenticate(username, password, "", (ret: any, cognitoUser: any) => {
        if (ret.success) {
          resolve({ success: true, cognitoUser })
        } else {
          reject("Authentication Failed for Password Change")
          serverLog({
            level: "error",
            message: "Authentication Failed for Password Change",
            endpoint: "authenticate",
            filename: __filename,
          })
        }
      })
    })
    return result
  }

  changePassword = async (
    cognitoUser: any,
    oldPassword: string,
    newPassword: string,
  ) => {
    await new Promise((resolve, reject) => {
      cognitoUser.changePassword(
        oldPassword,
        newPassword,
        (err: any, result: any) => {
          if (err) {
            reject("Change Password Failed")
            serverLog({
              level: "error",
              message: "Change Password Failed",
              endpoint: "cognitoUser.changePassword",
              filename: __filename,
              stack: err,
            })
          } else {
            cognitoUser.globalSignOut({
              onSuccess: () => {
                resolve(result)
              },
              onFailure: (err: Error) => {
                reject("Sign out failed")
                serverLog({
                  level: "error",
                  message: "Sign Out Failed",
                  endpoint: "cognitoUser.globalSignOut",
                  filename: __filename,
                  stack: err,
                })
              },
            })
          }
        },
      )
    })
  }

  resendConfirmationCode = (username: string, callback: any) => {
    cognitoUser = this.getCognitoUser(username)
    cognitoUser.resendConfirmationCode((err: any) => {
      if (err) {
        console.log(JSON.stringify(err))
        callback({ success: false, error: err })
        serverLog({
          level: "error",
          message: "Resending Confirmation Code Failed",
          endpoint: "cognitoUser.resendConfirmationCode",
          filename: __filename,
          stack: err,
        })
      } else {
        callback({ success: true })
      }
    })
  }

  authenticate = (
    username: string,
    password: string,
    newPassword: string,
    callback: any,
  ) => {
    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails({
        Username: username,
        Password: password,
      })
    // const dispatchEmail = (email: string) => {
    //   this.store.dispatch(PORTAL.setUserEmail(email));
    // };

    cognitoUser = this.getCognitoUser(username)
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result: any) => {
        const token = result.getAccessToken().getJwtToken()
        console.log("Change Password OK", token, result)
        const ret = { success: true, token }
        callback(ret, cognitoUser)
      },
      onFailure: (err: any) => {
        const error =
          err.message === "User does not exist."
            ? "Incorrect username or password."
            : err.message
        serverLog({
          level: "error",
          message: "Authentication Failed",
          endpoint: "cognitoUser.authenticateUser",
          filename: __filename,
          stack: err,
        })
        callback({ success: false, error })
      },
      newPasswordRequired: function () {
        if (!newPassword) {
          console.log("Change Password")
          callback({ success: true, changePassword: true })
          return
        }
        cognitoUser.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess: (result: any) => {
              const token = result.accessToken.jwtToken
              console.log("Change Password OK", token)
              callback({ success: true, token })
            },
            onFailure: (error) => {
              console.log("FAILED OK")
              serverLog({
                level: "error",
                message: "Change Password for Authenticate",
                endpoint: "cognitoUser.completeNewPasswordChallenge",
                filename: __filename,
              })
              callback({ success: false, error })
            },
          },
        )
      },
    })
  }

  CreateNewUser = (
    subscriptionId: string,
    username: string,
    password: string,
    channelId: string,
    planId: string,
    callback: any,
  ) => {
    const poolData = this.getPoolData()
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

    const attributeList = []

    const dataEmail = {
      Name: "email",
      Value: username,
    }

    const dataSubscriptionId = {
      Name: "custom:subscriptionId",
      Value: subscriptionId,
    }

    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
      dataEmail,
    )

    const attributeSubscriptionId =
      new AmazonCognitoIdentity.CognitoUserAttribute(dataSubscriptionId)

    attributeList.push(attributeEmail)
    attributeList.push(attributeSubscriptionId)

    userPool.signUp(
      username,
      password,
      attributeList,
      [],
      function (err, result) {
        if (err) {
          callback({ success: false, error: err })
          return
        }
        C.Portal.sendMarketingAnalytics(
          {
            email: username,
            channel: channelId,
            plan: planId,
          },
          window.location.href,
          "Cybersafty Trial Onboarding",
          "New",
        )
        callback({ success: true, result })
      },
    )
  }

  validateUserCreation = (
    cognitoUser: AmazonCognitoIdentity.CognitoUser,
    verificationCode: string,
    callback: any,
  ) => {
    cognitoUser.confirmRegistration(
      verificationCode,
      true,
      function (err, result) {
        if (err) {
          callback({ success: false, error: err })
          return
        }
        callback({ success: true, result })
        console.log("call result: " + result)
      },
    )
  }

  globalSignOut = () => {
    cognitoUser.globalSignOut({
      onSuccess: () => {
        console.info("Cognito session finished.")
      },
      onFailure: (err: Error) => {
        serverLog({
          level: "error",
          message: "Could not close cognito session",
          filename: __filename,
          stack: err,
        })
      },
    })
  }
}

export default Cognito
