import styled from "styled-components"

export const Row = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  color: #212528;
  width: 100%;
  margin-bottom: 20px;
`

export const Label = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px;
`
export const ButtonContainer = styled.div`
  text-align: right;
`
