import {
  ICancelScan,
  ICheckResetPasswordMagicLink,
  ICheckWebAppUrl,
  IGetAssessmentData,
  IGetConfig,
  IGetCustomerPortalData,
  IGetCyberConciergeAlert,
  IGetCyberConciergeAlerts,
  IGetCyberConciergeConfig,
  IGetInviteLink,
  IInviteLinkLookup,
  IInviteLinkSignUp,
  IRemoveAssessmentData,
  IResetPassword,
  ISaveAssessmentData,
  ISendResetPasswordMagicLinkData,
  IUpdateCustomerPortalData,
} from "@src/Sagas/types"
import { ActionCreator } from "redux"

export const getTargetListWatcher: ActionCreator<any> = () => ({
  type: "GET_TARGET_LIST_WATCHER",
})
export const deleteTargetWatcher: ActionCreator<any> = (payload) => ({
  payload,
  type: "DELETE_TARGET_WATCHER",
})
export const editTargetWatcher: ActionCreator<any> = (payload) => ({
  payload,
  type: "EDIT_TARGET_WATCHER",
})
export const createTargetWatcher: ActionCreator<any> = (payload) => ({
  payload,
  type: "CREATE_TARGET_WATCHER",
})
export const createScheduleScanWatcher: ActionCreator<any> = (payload) => ({
  payload,
  type: "CREATE_SCHEDULE_SCAN_WATCHER",
})
export const updateScheduleScanWatcher: ActionCreator<any> = (
  payload: string,
) => ({
  payload,
  type: "UPDATE_SCHEDULE_SCAN_WATCHER",
})
export const getScheduleMapWatcher: ActionCreator<any> = () => ({
  type: "GET_SCHEDULE_MAP_WATCHER",
})
export const editScheduleScanWatcher: ActionCreator<any> = (payload) => ({
  payload,
  type: "EDIT_SCHEDULE_SCAN_WATCHER",
})
export const syncScansWatcher: ActionCreator<any> = (payload) => ({
  payload,
  type: "SYNC_SCANS_WATCHER",
})
export const getScanMapWatcher: ActionCreator<any> = () => ({
  type: "GET_SCAN_MAP_WATCHER",
})

export const cancelScanWatcher: ActionCreator<ICancelScan> = (payload) => ({
  payload,
  type: "CANCEL_SCAN_WATCHER",
})

export const checkAppUrlWatcher: ActionCreator<ICheckWebAppUrl> = (
  payload,
) => ({
  payload,
  type: "CHECK_APP_URL_WATCHER",
})

export const getConfigWatcher: ActionCreator<IGetConfig> = () => ({
  type: "GET_CONFIG_WATCHER",
})
export const getAssessmentDataWatcher: ActionCreator<IGetAssessmentData> = (
  payload,
) => ({
  payload,
  type: "GET_ASSESSMENT_DATA_WATCHER",
})
export const saveAssessmentDataWatcher: ActionCreator<ISaveAssessmentData> = (
  payload,
) => ({
  payload,
  type: "SAVE_ASSESSMENT_DATA_WATCHER",
})
export const removeAssessmentDataWatcher: ActionCreator<
  IRemoveAssessmentData
> = (payload) => ({
  payload,
  type: "REMOVE_ASSESSMENT_DATA_WATCHER",
})

export const getCustomerPortalData: ActionCreator<
  IGetCustomerPortalData
> = () => ({
  type: "GET_CUSTOMER_PORTAL_DATA_WATCHER",
})

export const updateCustomerPortalData: ActionCreator<
  IUpdateCustomerPortalData
> = (payload) => ({
  payload,
  type: "UPDATE_CUSTOMER_PORTAL_DATA_WATCHER",
})

export const sendResetPasswordMagicLinkWatcher: ActionCreator<
  ISendResetPasswordMagicLinkData
> = (payload) => ({
  payload,
  type: "SEND_RESET_PASSWORD_MAGIC_LINK_WATCHER",
})

export const resetPasswordWatcher: ActionCreator<IResetPassword> = (
  payload,
) => ({
  payload,
  type: "RESET_PASSWORD_WATCHER",
})

export const checkResetPasswordMagicLinkWatcher: ActionCreator<
  ICheckResetPasswordMagicLink
> = (payload) => ({
  payload,
  type: "CHECK_RESET_PASSWORD_MAGIC_LINK_WATCHER",
})

export const triggerCybersecurityRoadBlockModalWatcher: ActionCreator<
  unknown
> = () => ({
  type: "TRIGGER_CYBERSECURITY_ROAD_BLOCK_MODAL_WATCHER",
})

export const getCyberConciergeConfigWatcher: ActionCreator<
  IGetCyberConciergeConfig
> = () => ({
  type: "GET_CYBER_CONCIERGE_CONFIG_WATCHER",
})

export const getCyberConciergeAlertsWatcher: ActionCreator<
  IGetCyberConciergeAlerts
> = (payload) => ({
  payload,
  type: "GET_CYBER_CONCIERGE_ALERTS_WATCHER",
})

export const getCyberConciergeAlertWatcher: ActionCreator<
  IGetCyberConciergeAlert
> = (payload) => ({
  payload,
  type: "GET_CYBER_CONCIERGE_ALERT_WATCHER",
})

export const getInviteLinkWatcher: ActionCreator<IGetInviteLink> = (
  payload,
) => ({
  payload,
  type: "GET_INVITE_LINK_WATCHER",
})

export const inviteLinkSignupWatcher: ActionCreator<IInviteLinkSignUp> = (
  payload,
) => ({
  payload,
  type: "INVITE_LINK_SIGNUP_WATCHER",
})

export const inviteLinkLookupWatcher: ActionCreator<IInviteLinkLookup> = (
  payload,
) => ({
  payload,
  type: "INVITE_LINK_LOOKUP_WATCHER",
})
