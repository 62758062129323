import ExternalLink from "@src/Components/ExternalLink"
import { FaIcon } from "@src/Components/FaIcon"
import * as React from "react"
import { useSelector } from "react-redux"
import { tState } from "@src/Model/Model"
import { LearnMoreLink } from "./styles"
const LearnMoreSection = () => {
  const { helpCenter } = useSelector((state: tState) => state.brand)
  const learnMoreUrl =
    `https://${helpCenter}/hc/en-us/articles/19379702177175-Cyber-Concierge-Overview`

  return (
    <LearnMoreLink>
      <FaIcon className="fas fa-book" />
      <ExternalLink
        text="Learn more about Cyber Concierge"
        url={learnMoreUrl}
      />
    </LearnMoreLink>
  )
}

export default LearnMoreSection
