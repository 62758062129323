import ConfirmationModal from "@src/Components/ConfirmationModal"
import ThreeDotMenu from "@src/Components/ThreeDotMenu"
import C from "@src/Controller"
import { colors } from "@src/theme"
import { IGoToTraineeTab } from "@src/types"
import { TraineeData as ITrainee } from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"

import ModalEdit from "../ModalEdit"
import {
  Badge,
  ConfirmContent,
  ConfirmText,
  ConfirmTextBold,
  ConfirmTextGray,
  Content,
  EmailStyled,
  Menu,
  NameStyled,
  WarnIcon,
} from "./styles"

interface IColumnName {
  trainee: ITrainee
  goToTraineeTab: (key: IGoToTraineeTab) => void
}

const pauseConfirm = `
  All grades and course enrollments will stay,
  but automatic module assignments and email reminders will be paused.
`

const unPauseConfirm = `
  All grades and course tracking will be activated, and module assignments will be enabled.
`

const deleteConfirm = `
  All grades will be removed and trainee will be unenrolled from all courses.
`

const ColumnName: React.FC<IColumnName> = ({ trainee, goToTraineeTab }) => {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [isEditing, setIsEditing] = React.useState(false)
  const [isSuspending, setIsSuspending] = React.useState(false)
  const { firstName, lastName, email, traineeId, inactive, courseResults } =
    trainee
  const fullName = `${firstName} ${lastName}`
  const pauseTitle = inactive ? "Unpause" : "Pause"
  const pauseText = inactive ? unPauseConfirm : pauseConfirm
  const courses = courseResults?.map(({ name }) => name) || []

  const goToTrainee = () => {
    const args = {
      name: fullName,
      email,
      traineeId,
      courseName: courses[0] || "",
    }
    goToTraineeTab(args)
  }

  const onDelete = () => {
    setIsDeleting(false)
    const deleteTraineePayload = { firstName, lastName, email, traineeId }
    C.Training.deleteTrainee(deleteTraineePayload)
  }

  const onSuspend = () => {
    setIsSuspending(false)
    const inactive = pauseTitle === "Pause"
    const { email, lastName, firstName, traineeId } = trainee

    C.Training.editTrainees([
      { email, lastName, firstName, traineeId, inactive },
    ])
  }

  const menuItems = [
    {
      caption: "Edit Details",
      action: () => setIsEditing(true),
      iconClass: "fas fa-pen-alt",
    },
    {
      caption: pauseTitle,
      action: () => setIsSuspending(true),
      iconClass: "fas fa-clock",
    },
    {
      caption: "Remove",
      color: colors.error,
      action: () => setIsDeleting(true),
      iconClass: "fas fa-trash-alt",
    },
  ]

  return (
    <Content>
      <NameStyled paused={!!inactive} onClick={goToTrainee}>
        {fullName}
      </NameStyled>
      <EmailStyled title={email} paused={!!inactive}>
        {email}
      </EmailStyled>
      {inactive && <Badge>Paused</Badge>}
      <Menu>
        <ThreeDotMenu position="right" menuItems={menuItems} title={fullName} />
      </Menu>
      <ConfirmationModal
        isOpen={isDeleting}
        title={`Remove ${fullName} from all training?`}
        type="danger"
        confirmButton="Remove From Training"
        onClose={() => setIsDeleting(false)}
        onConfirm={onDelete}
      >
        <ConfirmContent>
          <ConfirmText>
            <ConfirmTextGray>{deleteConfirm}</ConfirmTextGray>
          </ConfirmText>
          {!!courses?.length && (
            <>
              <ConfirmTextBold>
                <WarnIcon className="fas fa-exclamation-triangle" />
                Courses trainee will be unenrolled from:
              </ConfirmTextBold>
              <ConfirmText>{`${courses.join(", ")}.`}</ConfirmText>
            </>
          )}
          <ConfirmText>
            {`Are you sure you want to remove ${fullName} `}
            <ConfirmTextGray>({email})</ConfirmTextGray> from all training?
          </ConfirmText>
        </ConfirmContent>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={isSuspending}
        title={`${pauseTitle} Training for ${fullName}?`}
        type="alert"
        confirmButton={`${pauseTitle} Training`}
        onClose={() => setIsSuspending(false)}
        onConfirm={onSuspend}
      >
        <ConfirmContent>
          <ConfirmText>{pauseText}</ConfirmText>
          <ConfirmText>
            {`Are you sure you want to ${pauseTitle.toLowerCase()} training for ${fullName} `}
            <ConfirmTextGray>({email})</ConfirmTextGray>?
          </ConfirmText>
        </ConfirmContent>
      </ConfirmationModal>
      <ModalEdit
        isOpen={isEditing}
        trainee={trainee}
        onClose={() => setIsEditing(false)}
      />
    </Content>
  )
}

export default ColumnName
