import { ButtonSmall } from "@src/Components/Button"
import { ConfirmationModalContent } from "@src/Components/ConfirmationModal"
import { Link } from "@src/Components/Link"
import Waiting from "@src/Components/Waiting"
import C from "@src/Controller"
import { ISaveTraineeData } from "@src/types"
import * as React from "react"
import { useDropzone } from "react-dropzone"

import {
  BulkContent,
  BulkFooter,
  ConfirmItem,
  ConfirmList,
  ConfirmSection,
  Container,
  DragDropIcon,
  DragDropInnerText,
  DragDropSection,
  DragDropText,
  FileName,
  Icon,
  InfoText,
  Section,
  TrashIcon,
  UploadFileContainer,
  WaitingWrap,
} from "./styles"

interface IData {
  trainees: ISaveTraineeData[]
  duplicates: ISaveTraineeData[]
  invalidEmails: ISaveTraineeData[]
}

interface IFormBulk {
  seatsRemaining: number
  toggleBulk: () => void
}

const downloadTemplate = (
  <Link
    onClick={() => {
      window.open("/api/training/downloadTemplate/template.csv")
    }}
  >
    <i className="fas fa-file-excel" /> Download a template here.
  </Link>
)

const FormBulk: React.FC<IFormBulk> = ({ seatsRemaining, toggleBulk }) => {
  const [file, setFile] = React.useState<File | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isConfirming, setIsConfirming] = React.useState<IData | null>(null)

  const onDrop = (newFiles: File[]) => {
    setFile(newFiles[0])
  }

  const onSave = async () => {
    setIsLoading(true)
    const result = await C.Training.saveTraineesBulk(file)
    setIsLoading(false)

    if (result.success) {
      setIsConfirming(result.data)
    }
  }

  const onConfirm = () => {
    C.Training.saveTrainees(isConfirming?.trainees || [])
    setIsConfirming(null)
  }

  const onDeleteFile = () => {
    setFile(null)
  }

  const formatAttachmentName = (name: string) => {
    if (name.length < 25) {
      return name
    }
    const arr = name.split(".")
    const extension = arr[arr.length - 1]

    return `${name.slice(0, 20)}... .${extension}`
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Container>
      {!isConfirming ? (
        <Section className="bp5-dialog-body">
          <BulkContent>
            <InfoText>
              You can upload an Excel or CSV file <br />
              <br />
              All trainees will be auto-enrolled on cybersecurity course with no
              ability to unenroll.
              <DragDropInnerText>{downloadTemplate}</DragDropInnerText>
            </InfoText>

            {isLoading ? (
              <WaitingWrap>
                <Waiting text={"Loading..."} height="100%" padding={100} />
              </WaitingWrap>
            ) : file ? (
              <UploadFileContainer>
                <div>
                  <i className="fas fa-file-excel" />
                  <FileName>{formatAttachmentName(file.name)}</FileName>
                </div>
                <TrashIcon
                  className="fas fa-trash-alt"
                  onClick={onDeleteFile}
                />
              </UploadFileContainer>
            ) : (
              <DragDropSection {...getRootProps()}>
                {
                  <>
                    <DragDropIcon className="fas fa-cloud-upload-alt" />
                    <DragDropText>
                      {!isDragActive ? (
                        <div>
                          <DragDropInnerText>
                            <i className="fas fa-file-excel" /> Drag and drop
                            your Excel or CSV file here
                          </DragDropInnerText>
                          <DragDropInnerText>OR</DragDropInnerText>
                          <DragDropInnerText>
                            <ButtonSmall>Select File to Upload</ButtonSmall>
                          </DragDropInnerText>
                        </div>
                      ) : (
                        <DragDropInnerText>
                          <i className="fas fa-file-excel" /> Drag and drop your
                          Excel or CSV file here
                        </DragDropInnerText>
                      )}
                    </DragDropText>
                    <input
                      {...getInputProps()}
                      id="add-attachment"
                      type="file"
                      accept=".csv,.xlsx"
                      multiple={false}
                    />
                  </>
                }
              </DragDropSection>
            )}
          </BulkContent>

          <BulkFooter disabled={seatsRemaining === 0 || isLoading}>
            <Link bold padding="10px 0" onClick={toggleBulk}>
              <Icon className="fas fa-user-plus" />
              Invite Individuals
            </Link>
            {file && (
              <ButtonSmall onClick={onSave} width="180">
                {`Upload Spreadsheet`}
              </ButtonSmall>
            )}
          </BulkFooter>
        </Section>
      ) : (
        <ConfirmationModalContent
          onClose={() => setIsConfirming(null)}
          onConfirm={onConfirm}
          confirmButtonWidth={180}
          confirmButton={
            isConfirming?.trainees.length ? "Add Team Members" : "Got It"
          }
        >
          <ConfirmSection>
            {!!isConfirming?.invalidEmails.length && (
              <ConfirmList>
                These trainees have invalid email addresses, and will not be
                uploaded:
                {isConfirming?.invalidEmails.map(
                  (trainee: ISaveTraineeData) => (
                    <ConfirmItem key={trainee.email}>
                      {trainee.email}
                    </ConfirmItem>
                  ),
                )}
              </ConfirmList>
            )}
            {!!isConfirming?.duplicates.length && (
              <ConfirmList>
                These trainees already exist, and will not be uploaded:
                {isConfirming?.duplicates.map((trainee: ISaveTraineeData) => (
                  <ConfirmItem key={trainee.email}>{trainee.email}</ConfirmItem>
                ))}
              </ConfirmList>
            )}

            {!!isConfirming?.trainees.length && (
              <ConfirmList>
                Are you sure you want to add these new team members?
                {isConfirming?.trainees.map((trainee: ISaveTraineeData) => (
                  <ConfirmItem key={trainee.email}>{trainee.email}</ConfirmItem>
                ))}
              </ConfirmList>
            )}

            {!isConfirming?.trainees?.length && (
              <ConfirmList>
                No new trainees were found in the uploaded file.
              </ConfirmList>
            )}
          </ConfirmSection>
        </ConfirmationModalContent>
      )}
    </Container>
  )
}

export default FormBulk
