export const scoreToGrade = (score: number) => {
  if (score < 10) return "F"
  if (score < 26) return "E"
  if (score < 46) return "D"
  if (score < 66) return "C"
  if (score < 86) return "B"
  return "A"
}

export const getCourseName = (
  name: string,
  subject: string,
  difficulty: number,
) => {
  if (subject === "InfoSec") {
    if (difficulty === 1) return "Knowledge Assessment"
    if (difficulty === 2) return "Beginner"
    if (difficulty === 3) return "Intermediate"
    if (difficulty === 4) return "Advanced"
  }
  return name
}
