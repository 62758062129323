import * as React from "react"
import styled from "styled-components"

interface IExternalLink {
  text: string
  url: string
  id?: string
  margin?: string
  hidden?: boolean
  fontSize?: number
  fontWeight?: string
  color?: string
  wrap?: boolean
  lineHeight?: string
}
const ExternalLink = (props: IExternalLink) => {
  return (
    <Link
      id={props.id}
      hidden={props.hidden}
      margin={props.margin}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      color={props.color}
      wrap={props.wrap}
      lineHeight={props.lineHeight}
      onClick={(e: any) => {
        e.stopPropagation()
        window.open(props.url)
      }}
    >
      {props.text}&nbsp;{""}
      <i
        className="far fa-external-link-square"
        style={{ fontSize: "13px", display: "inline" }}
      />
    </Link>
  )
}
interface ILink {
  hidden?: boolean
  margin?: string
  fontSize?: number
  fontWeight?: string
  color?: string
  wrap?: boolean
  lineHeight?: string
}
const Link = styled.a`
  display: ${(props: ILink) => (props.hidden ? "none" : "inline-block")};
  white-space: ${(props: ILink) => (props.wrap ? "break-spaces" : "nowrap")};
  font-size: ${(props: ILink) => props.fontSize || undefined}px;
  color: ${(props: ILink) => props.color || "#0062d8"};
  margin: ${(props: ILink) => (props.margin ? props.margin : "0px 10px")};
  font-weight: ${(props: ILink) => props.fontWeight || undefined};
  line-height: ${(props: ILink) => props.lineHeight};
  &:hover {
    color: ${(props: ILink) => props.color || "#0062d8"};
  }
`

export default ExternalLink
