import {
  ButtonCancel,
  ButtonSecondary,
  ButtonSmall,
} from "@src/Components/Button"
import { FormElement } from "@src/Components/Common"
import ConfirmationModal from "@src/Components/ConfirmationModal"
import { Dropdown } from "@src/Components/Dropdown"
import FloatingLabelInput from "@src/Components/FloatingLabelInput"
import Waiting from "@src/Components/Waiting"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { tCompanyProfile } from "@src/types"
import { validateUrl } from "@src/Validators"
import { saveAs } from "file-saver"
import * as React from "react"
import { shallowEqual, useSelector } from "react-redux"

import { industries, noOfEmployees } from "../../OnBoarding/OnBoardingData"
import {
  ButtonRow,
  ConfirmContent,
  ConfirmText,
  ConfirmTextBold,
  Content,
  Dialog,
  Panel,
  Row,
  SubText,
} from "./styles"

interface IProps {
  title?: string
  secondary?: boolean
  onClick?: () => void
}

interface IData {
  companyName: string
  industry: string[] | undefined
  numberOfEmployees: string
  companyUrl: string
  phone: string
  webAppUrlError: boolean
  loading: boolean
}

const initData = {
  companyName: "",
  industry: ["", ""],
  numberOfEmployees: "",
  companyUrl: "",
  phone: "",
  webAppUrlError: false,
  loading: false,
}

const getInitData = (company: tCompanyProfile) => {
  return {
    ...initData,
    ...company,
    industry:
      industries.find((item) => item[1] === company?.industry) ||
      initData.industry,
  }
}

const DownloadRatingReport: React.FC<IProps> = ({ title, secondary }) => {
  const Button = secondary ? ButtonSecondary : ButtonSmall

  const [isOpen, setIsOpen] = React.useState(false)
  const [isError, setIsError] = React.useState("")
  const [data, setData] = React.useState<IData>(initData)

  const company: tCompanyProfile = useSelector(
    (state: tState) => state.portal.companyProfile,
    shallowEqual,
  )
  const orgId = useSelector((state: tState) => state.portal.orgId)
  const pdfFile = useSelector((state: tState) => state.ratings.ratingsData?.pdf)
  const pdfUrl = pdfFile ? `data:application/pdf;base64,${pdfFile}` : ""

  React.useEffect(() => {
    if (company) {
      setData(getInitData(company))
    }
  }, [company])

  const savePdf = (url: string) => {
    saveAs(url, `Rating report`)
  }

  const onDownloadReport = () => {
    if (pdfUrl) {
      savePdf(pdfUrl)
    } else {
      setIsOpen(true)
    }
  }

  const onCloseModal = () => {
    setIsOpen(false)
    setData(getInitData(company))
  }

  const isChanged = React.useMemo(() => {
    return (
      data.companyName !== company?.companyName ||
      data.companyUrl !== company?.companyUrl ||
      data.numberOfEmployees !== company?.numberOfEmployees ||
      data.industry?.[1] !== company?.industry
    )
  }, [data, company])

  const onCloseConfirm = () => {
    setIsOpen(false)
    setIsError("")
  }

  const onCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setData({ ...data, companyName: value })
  }

  const onCompanyUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setData({ ...data, companyUrl: value })
  }

  const onIndustrySelect = (item: string[]) => {
    setData({ ...data, industry: item })
  }

  const onNumberOfEmployeeSelect = (item: string[]) => {
    setData({ ...data, numberOfEmployees: item[0] })
  }

  const onSubmit = () => {
    setData({ ...data, loading: true })

    C.Portal.updateCompany(
      {
        companyName: data.companyName,
        companyUrl: data.companyUrl,
        numberOfEmployees: data.numberOfEmployees,
        industry: data.industry?.[1],
      },
      orgId || "",
      (err, data) => {
        if (!err && data?.companyUrl) {
          C.Ratings.loadRatingsData(data.companyUrl, (err, ratingsData) => {
            if (err || ratingsData?.error) {
              const noEventsErr = "No events were found for the provided URL"
              return C.toastNotification(
                (ratingsData?.error === noEventsErr
                  ? "Invalid URL"
                  : ratingsData?.error) || "Error loading data",
                "danger",
              )
            }

            if (ratingsData?.pdf) {
              savePdf(`data:application/pdf;base64,${ratingsData.pdf}`)
              return C.toastNotification(
                "Report Received successfully",
                "success",
                10000,
              )
            }
          })
        } else {
          setData({ ...data, loading: false })
        }
      },
    )
  }

  const validateFormUrl = (url: string) => {
    url = url.replace(new RegExp("^https?://"), "")
    if (validateUrl(url)) {
      setData({ ...data, companyUrl: url, webAppUrlError: false })
      return ""
    } else {
      setData({ ...data, companyUrl: url, webAppUrlError: true })
      return "Please enter a valid URL."
    }
  }

  return (
    <>
      <Button onClick={onDownloadReport} padding="5 15" height="auto">
        {title || "Download Report"}
      </Button>
      <Dialog
        title="We don’t know you"
        className="zeg-dialog"
        isOpen={isOpen}
        onClose={onCloseModal}
        content={
          data.loading ? (
            <Content height={300}>
              <Waiting text="Loading PDF" padding={100} />
            </Content>
          ) : (
            <Content>
              <Panel>
                <ConfirmText>
                  <ConfirmTextBold>{"Why am I seeing this?"}</ConfirmTextBold>
                  {`
                  You will need to provide information below to get a personalized report.
                `}
                </ConfirmText>
                <FormElement>
                  <FloatingLabelInput
                    label="Company name"
                    id="onboarding-company-name"
                    type="text"
                    value={data.companyName}
                    onChange={onCompanyNameChange}
                  />
                  <Row>
                    <Dropdown
                      items={industries}
                      id="onboarding-primary-industry"
                      placeholder={"Select your primary industry"}
                      selectedItem={data.industry}
                      onSelectItem={onIndustrySelect}
                      height={"37px"}
                    />
                  </Row>
                  <Row>
                    <Dropdown
                      items={noOfEmployees}
                      id="onboarding-number-of-team-members"
                      placeholder={"Select your number of team members"}
                      selectedItem={[data.numberOfEmployees]}
                      onSelectItem={onNumberOfEmployeeSelect}
                      height={"37px"}
                    />
                    <SubText>
                      Include all full-time and part-time employees,
                      contractors, and consultants.
                    </SubText>
                  </Row>
                  <FloatingLabelInput
                    label="Primary website address"
                    id="onboarding-primary-website-address"
                    type="text"
                    value={data.companyUrl}
                    onChange={onCompanyUrlChange}
                    onValidate={(url: string) => validateFormUrl(url)}
                  />
                </FormElement>
              </Panel>
              <ButtonRow>
                <ButtonCancel onClick={onCloseModal} width={160}>
                  Cancel
                </ButtonCancel>
                <ButtonSmall
                  onClick={onSubmit}
                  width={160}
                  disabled={!isChanged || data.webAppUrlError || data.loading}
                >
                  Submit
                </ButtonSmall>
              </ButtonRow>
            </Content>
          )
        }
      />

      <ConfirmationModal
        infoOnly
        isOpen={!!isError}
        title="Error Loading Data!"
        type="danger"
        confirmButton="OK"
        onClose={onCloseConfirm}
        onConfirm={onCloseConfirm}
      >
        <ConfirmContent>
          <ConfirmText>{isError}</ConfirmText>
        </ConfirmContent>
      </ConfirmationModal>
    </>
  )
}

export default DownloadRatingReport
