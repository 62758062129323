import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { GRADE_COLOR } from "@src/Portal/Training/constants"
import { scoreToGrade } from "@src/Portal/Training/utils"
import { CourseMap as ICourseMap } from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"

import ProgressBar from "../ProgressBar"
import {
  CardContainer,
  CardsDivider,
  CardTitle,
  CardTitleWrap,
  CourseIcon,
  Grade,
  MultipleCardWrapper,
  PlusIcon,
} from "./styles"
import { DetailsLink } from "./styles"

const defaultCourseIcon = "images/training/default_course.svg"

interface ICardProps {
  course: ICourseMap
  gapCourse?: ICourseMap
  setCurrentCourse: (key: ICourseMap) => void
  courseList: ICourseMap[]
}

interface ISingleCardProps {
  course: ICourseMap
  iconSrc: string
}

const checkBackTooltip =
  "Check back for average grade once team members complete their training."
const avgGradeTooltip = "The average grade for the completed course modules"

const SingleCard: React.FC<ISingleCardProps> = ({ course, iconSrc }) => {
  const courseName = course.name
  const courseSubject = course.subjectName || course.subject

  return (
    <>
      <div
        className="zeg-head-wrapper"
        data-tip
        data-for={`${courseName}-title-tooltip`}
      >
        <CourseIcon src={iconSrc} alt="Icon" />
        <CardTitleWrap>
          {courseSubject && <p>{courseSubject}</p>}
          <CardTitle>{courseName}</CardTitle>
        </CardTitleWrap>
      </div>
      <div className="zeg-body-wrapper">
        <ProgressBar
          valueEnd={course.assessmentData.totalCourses}
          withTopics={course.difficulty > 1 && course.difficulty < 5}
          value={course.assessmentData.completedCourses}
          name={courseName}
        />
        {course.averageScore ? (
          <Grade
            gradeColor={GRADE_COLOR[scoreToGrade(course.averageScore) || ""]}
            data-tip
            data-for={`${courseName}-grade-tooltip`}
          >
            <h3>{scoreToGrade(course.averageScore)}</h3>
            <p>Avg Grade</p>
            <Tooltip
              offset={{ top: -40 }}
              id={`${courseName}-grade-tooltip`}
              content={<p>{avgGradeTooltip}</p>}
            />
          </Grade>
        ) : (
          <Grade
            gradeColor={GRADE_COLOR.PENDING}
            data-tip
            data-for={`${courseName}-grade-tooltip`}
          >
            <p className="pending-grades">
              Grades <br /> Pending
            </p>
            <Tooltip
              offset={{ top: -40 }}
              id={`${courseName}-grade-tooltip`}
              content={<p>{checkBackTooltip}</p>}
            />
          </Grade>
        )}
      </div>
    </>
  )
}

const Card: React.FC<ICardProps> = ({
  course,
  gapCourse,
  setCurrentCourse,
  courseList,
}) => {
  const { isDesktop } = usePortalSize()
  const icon: string = course.imageUrl || defaultCourseIcon
  const goToCourse = () => setCurrentCourse(course)
  const beginnerCourse = courseList.find((c) => c.difficulty === 2)
  return gapCourse ? (
    <MultipleCardWrapper onClick={goToCourse}>
      <div className="zeg-multi-cards-container">
        {!!gapCourse && (
          <CardContainer multiple={!!gapCourse}>
            <SingleCard course={gapCourse} iconSrc={icon} />
          </CardContainer>
        )}
        {beginnerCourse && (
          <CardContainer multiple={!!gapCourse} className="zeg-last">
            <SingleCard course={beginnerCourse} iconSrc={icon} />
          </CardContainer>
        )}
        <PlusIcon className="fas fa-plus" />
      </div>
      <DetailsLink>
        <Link>
          View Details <i className="fas fa-chevron-right" />
        </Link>
      </DetailsLink>
    </MultipleCardWrapper>
  ) : (
    <Section w="100%">
      {!isDesktop && <CardsDivider />}
      <CardContainer multiple={!!gapCourse} onClick={goToCourse}>
        <SingleCard course={course} iconSrc={icon} />
        <DetailsLink>
          <Link>
            View Details <i className="fas fa-chevron-right" />
          </Link>
        </DetailsLink>
      </CardContainer>
    </Section>
  )
}
export default Card
