import { ButtonSecondary } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import ConfirmationModal from "@src/Components/ConfirmationModal"
import Dialog from "@src/Components/Dialog"
import Tooltip from "@src/Components/Tooltip"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { IGoToTraineeTab, ITraineeEditCourses, ViewTypes } from "@src/types"
import {
  CourseMap as ICourseMap,
  TraineeData as ITrainee,
} from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"

import ModalAdd from "./ModalAdd"
import ModalEnrollMultiple from "./ModalEnrollMultiple"
import {
  ConfirmContent,
  ConfirmText,
  ConfirmTextBold,
  Container,
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  InfoIcon,
  Subtitle,
} from "./styles"
import TableTrainees from "./TableTrainees"

interface ITraineeForm {
  numberOfSeats: Record<string, number>
  setCurrentTrainee: (key: ITrainee) => void
  onChangeMode: (id: string) => void
}
export interface ICoursesMT extends ICourseMap {
  isEnrolled: "partially" | boolean
  isFinished: boolean
  traineeList: ITraineeEditCourses[]
}
const ManageTeam: React.FC<ITraineeForm> = ({ numberOfSeats }) => {
  const history = useHistory()
  const { clientWidth } = usePortalSize()
  const [selection, setSelection] = React.useState<
    Omit<ITraineeEditCourses, "enrollInGap">[]
  >([])
  const [trainees, setTrainees] = React.useState<ITraineeEditCourses[]>([])
  const [isMultipleEnrollModal, setIsMultipleEnrollModal] =
    React.useState(false)
  const [isConfirming, setIsConfirming] = React.useState(false)
  const [isDiscard, setIsDiscard] = React.useState(false)

  const { traineesData, courseMap } = useSelector(
    (state: tState) => state.training.trainingData,
  )
  const isHighestPlan = useSelector(
    (state: tState) => state.plans.isHighestPlan,
  )
  const isAddTraineeDisabled = isHighestPlan && numberOfSeats.remaining <= 0

  const goToTraineeTab = ({ courseName, name, traineeId }: IGoToTraineeTab) => {
    history.push(
      `${
        ViewTypes.training
      }/reportcards/${courseName}/${name} ${traineeId.slice(0, 2)}`,
    )
  }

  const goToCourseLibraryTab = () => {
    history.push(`${ViewTypes.training}/library`)
  }
  const onEditTrainees = () => C.Training.editTraineeCourses(trainees)

  const onEnroll = () => {
    setIsConfirming(true)
    setIsMultipleEnrollModal(false)
  }

  const onCloseEnroll = () => {
    setIsMultipleEnrollModal(false)
    setSelection([...selection])
  }

  const onConfirmationClose = () => {
    setIsDiscard(true)
    setIsConfirming(false)
  }

  const onConfirmationBack = () => {
    setIsConfirming(false)
    setIsMultipleEnrollModal(true)
  }

  const onDiscardClose = () => {
    setSelection([...selection])
    setIsDiscard(false)
    setIsConfirming(true)
  }

  const onDiscardAction = () => {
    setSelection([...selection])
    setIsDiscard(false)
  }
  const getAllCourses: ICoursesMT[] = React.useMemo(
    () =>
      courseMap
        ?.filter((course) => course?.subjectName !== "Cybersecurity Series")
        .map((course) => {
          const isEnrolled = selection?.filter(
            ({ courseResults }) =>
              courseResults?.find((c) => c.name === course.name)?.topics[0]
                ?.enrollDate,
          )
          const isFinished = selection?.filter(
            ({ courseResults }) =>
              courseResults?.find(
                (c) => c.name === course.name && c.averageScore,
              ),
          )
          return {
            ...course,
            isEnrolled:
              isEnrolled && isEnrolled.length
                ? isEnrolled.length === selection.length || "partially"
                : false,
            isFinished: isFinished && isFinished.length === selection.length,
            traineeList: isEnrolled,
          }
        }),
    [traineesData, selection],
  )
  const memoConfirmList = React.useMemo(() => {
    const result = selection.filter(
      (s, i) => JSON.stringify(s) !== JSON.stringify(trainees[i]),
    )
    return result.map((item) => <span key={item.email}>{item.email}</span>)
  }, [selection, trainees])

  return (
    <Container>
      <HeaderContainer>
        <HeaderLeft>
          <span data-tip data-for={`edit-specialized-training-tooltip`}>
            <ButtonSecondary
              onClick={() => setIsMultipleEnrollModal(true)}
              width={220}
              inverse={false}
              disabled={!selection.length}
            >
              Edit Specialized Training
            </ButtonSecondary>
          </span>
          {!selection.length && (
            <Tooltip
              id={`edit-specialized-training-tooltip`}
              content={<p>Select team members below.</p>}
              width="290px"
            />
          )}
        </HeaderLeft>
        <HeaderRight>
          <Subtitle>
            {`Using ${numberOfSeats.currentUsage} of ${numberOfSeats.maximumAllowed} seats`}
          </Subtitle>
          <ModalAdd
            numberOfSeats={numberOfSeats}
            isDisabled={isAddTraineeDisabled}
          />
        </HeaderRight>
      </HeaderContainer>
      <ModalEnrollMultiple
        isOpen={isMultipleEnrollModal}
        courses={getAllCourses}
        selection={selection}
        setTrainees={setTrainees}
        onEnroll={onEnroll}
        onClose={onCloseEnroll}
        goToCourseLibraryTab={goToCourseLibraryTab}
      />
      <ConfirmationModal
        isOpen={isConfirming}
        title="Confirm Enrollment Changes"
        isBackButton={true}
        confirmButton={`Update ${clientWidth > 380 ? "Enrollment" : ""}`}
        onClose={onConfirmationClose}
        onBack={onConfirmationBack}
        onConfirm={onEditTrainees}
      >
        <ConfirmContent>
          <ConfirmTextBold>
            <InfoIcon className="fas fa-info-circle" />
            Course enrollment will be updated for:
          </ConfirmTextBold>
          <ConfirmText>{memoConfirmList}</ConfirmText>
        </ConfirmContent>
      </ConfirmationModal>
      <Dialog
        title="Discard Changes?"
        isOpen={isDiscard}
        onAction={onDiscardAction}
        onClose={onDiscardClose}
        actionText="Discard"
        closeText="Cancel"
        closeButtonId="no-subscription-logout"
        className="zeg-danger"
        body={
          <div>
            Are you sure you want to discard your course enrollment updates?
          </div>
        }
      />
      <TableTrainees
        traineesData={traineesData}
        goToTraineeTab={goToTraineeTab}
        setSelection={setSelection}
        goToCourseLibraryTab={goToCourseLibraryTab}
      />
    </Container>
  )
}

export default ManageTeam
