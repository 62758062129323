import { colors } from "@src/theme"
import styled from "styled-components"

interface IColoredCircle {
  large?: boolean
  borderColor?: string
  margin?: string
  cursor?: string
}

export const ColoredCircle = styled.div<IColoredCircle>`
  cursor: ${({ cursor }) => cursor || "default"};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ large }) => (large ? 52 : 32)}px;
  min-width: ${({ large }) => (large ? 52 : 32)}px;
  height: ${({ large }) => (large ? 52 : 32)}px;
  min-height: ${({ large }) => (large ? 52 : 32)}px;
  font-size: ${({ large }) => large && 32}px;
  font-weight: ${({ large }) => large && "700"};
  border-radius: 999px;
  color: ${colors.white};
  background-color: ${({ color }) => color ?? colors.white};
  border: ${({ large, borderColor }) =>
    (large ? "6" : "1") + "px solid " + (borderColor || colors.gray15)};
  margin: ${({ margin }) => margin};
`
