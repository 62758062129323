import DialogComp from "@src/Components/Dialog"
import styled from "styled-components"

export const Dialog = styled(DialogComp)`
  width: 90%;
  max-width: 800px;

  &.zeg-danger {
    max-width: 500px;
  }
`

export const IconUser = styled.i`
  margin-right: 5px;
`
