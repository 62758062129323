import { ButtonSecondary } from "@src/Components/Button"
import PasswordInput from "@src/Components/PasswordInput"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { useChangePasswordAuthValidation } from "@src/Portal/Settings/validation"
import { useFormik } from "formik"
import * as React from "react"
import { useSelector } from "react-redux"

import { SpinnerStyled } from "../../styles"
import { Form, SubTitle } from "../styles"

interface IPersonalDetailsForm {
  oldPassword: string
}

const formInitial: IPersonalDetailsForm = {
  oldPassword: "",
}

interface IPasswordChangeAuthenticate {
  page: string
  setPage: (page: string) => void
  setCognitoUser: (cognitoUser: any) => void
  setOldPassword: (oldPassword: string) => void
}

export const PasswordChangeAuthenticate: React.FC<
  IPasswordChangeAuthenticate
> = ({ page, setPage, setCognitoUser, setOldPassword }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const cognitoId = useSelector((state: tState) => state.portal.cognitoId || "")
  const isZeguroServiceSession = useSelector(
    (state: tState) => state.portal.isZeguroServiceSession,
  )
  const PasswordChangeScheme = useChangePasswordAuthValidation()

  const formik = useFormik<IPersonalDetailsForm>({
    initialValues: formInitial,
    enableReinitialize: true,
    validationSchema: PasswordChangeScheme,
    onSubmit: async ({ oldPassword }) => {
      try {
        setIsLoading(true)
        const authResult: any = await C.Cognito.changePasswordAuthenticate(
          cognitoId,
          oldPassword,
        )
        setCognitoUser(authResult.cognitoUser)
        setIsLoading(false)
        setOldPassword(oldPassword)
        formik.resetForm()
        setPage("2")
      } catch (error) {
        C.serverLog({
          level: "error",
          message: "Old Password Authentication Failed",
          filename: __filename,
          stack: error || "",
        })
        setIsLoading(false)
        return C.toastNotification("Wrong Current Password.", "danger")
      }
    },
  })

  return (
    <>
      {page == "1" ? (
        <Form onSubmit={formik.handleSubmit}>
          <SubTitle>Verify your identity.</SubTitle>
          <PasswordInput
            label="Current password"
            onChange={formik.handleChange}
            value={formik.values.oldPassword || ""}
            id="oldPassword"
            error={Boolean(
              formik.errors.oldPassword && formik.touched.oldPassword,
            )}
            errorMsg={formik.errors.oldPassword}
            margin="0 0 28px 0"
            disabled={isZeguroServiceSession}
            noValidation={true}
          />
          <ButtonSecondary
            id="save-details-button"
            width={226}
            margin="10 0"
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
          >
            {isLoading ? <SpinnerStyled size={25} /> : "Verify Identity"}
          </ButtonSecondary>
        </Form>
      ) : null}
    </>
  )
}
