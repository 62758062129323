import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { FaIcon } from "@src/Components/FaIcon"
import { Markdown } from "@src/Components/Markdown"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import Level from "@src/Portal/Zimm/Level"
import { colors } from "@src/theme"
import { ViewTypes } from "@src/types"
import { getPrivateAsset } from "@src/Utils"
import { CyberMaturityCatalog as ICyberMaturityCatalog } from "@zeguro/schema-validator/dist/types/coco/cyberMaturity/combined"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { textToPath } from "../../../utils"
import {
  AdvanceImage,
  AdvancePanel,
  AdvanceTitle,
  Description,
} from "../styles"
import {
  ActionButtonContainer,
  CongratsImagesContainer,
  ElementIcon,
  FirstCongratsImage,
  LevelContainer,
  LevelUpActionContainer,
  SecondCongratsImage,
} from "./styles"
interface IAdvanceToNextLevel {
  level?: number
  element: ICyberMaturityCatalog["elements"][number]
}

const AdvanceToNextLevel = ({ level, element }: IAdvanceToNextLevel) => {
  const history = useHistory()
  const { isDesktop } = usePortalSize()
  const plans = useSelector((state: tState) => state.plans)

  const levelKey = (level || 0).toString() as "0" | "1" | "2" | "3" | "4" | "5"
  const hasTraining = plans.subscribedPlan.training === "ACTIVE"
  const levelUpAction =
    hasTraining || !element.levels[levelKey].levelUpAction?.withoutTraining
      ? element.levels[levelKey].levelUpAction?.default
      : element.levels[levelKey].levelUpAction?.withoutTraining

  const handlerOnCLick = (link?: string) => {
    if (!link) {
      return
    }
    const linkUrl = String(link)
    if (linkUrl.startsWith("https://zeguro-private-assets")) {
      getPrivateAsset(linkUrl)
    } else if (linkUrl.startsWith("http")) {
      window.open(linkUrl, "_blank")
    } else if (linkUrl !== "tooltip") {
      history.push(linkUrl)
    }
  }

  return (
    <AdvancePanel>
      {typeof level !== "number" ? (
        <LevelContainer>
          <Section flex="flex-start">
            <Section padding="0 14 16">
              <img
                src={
                  "https://dq7ss205qk0vh.cloudfront.net/zimmQuestionnaire/illo_see_zimm.svg"
                }
              />
            </Section>
            <div style={{ flex: "1" }}>
              <AdvanceTitle>See Your Cyber Maturity Level</AdvanceTitle>
              <Description>
                Answer this quick question to see your Cyber Maturity level for
                this element.
              </Description>
              <ButtonSmall
                id="cm-answer-question"
                width={180}
                margin="20 0 0 0"
                onClick={() =>
                  history.push(
                    `${ViewTypes.zimm}/assessment/${textToPath(
                      element.elementName,
                    )}`,
                  )
                }
              >
                Answer Question
              </ButtonSmall>
            </div>
          </Section>
          <Section flex="center flex-end">
            <ElementIcon
              src={element.elementImageUrl}
              alt={element.elementName}
            />
          </Section>
        </LevelContainer>
      ) : level === 5 ? (
        <>
          <LevelContainer>
            <Section flex="left" padding="16 16 0 16">
              <Level level={level} margin="0 10px 0 0" />
              <div>
                <AdvanceTitle>Yippie! You’ve reached Level 5!</AdvanceTitle>
                <Markdown>
                  {element.levels[levelKey]?.levelDescription}
                </Markdown>
              </div>
            </Section>
            <Section flex="center flex-end">
              <CongratsImagesContainer>
                <FirstCongratsImage src="images/congrats_outcry.svg" />
                <SecondCongratsImage src="images/cockadont_happy.svg" />
              </CongratsImagesContainer>
            </Section>
          </LevelContainer>
        </>
      ) : (
        <>
          <Section flex="left" padding={isDesktop ? "32" : "20"}>
            <Level level={level} margin="0 10px 0 0" />
            <div>
              <AdvanceTitle>Current Level</AdvanceTitle>
              <Markdown>{element.levels[levelKey]?.levelDescription}</Markdown>
            </div>
          </Section>
          <Section flex="left" padding={isDesktop ? "0 32 22" : "0 20 20"}>
            <Section margin="0 10 0 0">
              <AdvanceImage
                src={
                  "https://dq7ss205qk0vh.cloudfront.net/zimmQuestionnaire/illo_lightbulb.svg"
                }
              />
            </Section>
            <div>
              <AdvanceTitle>
                <>
                  Advance to Level {level + 1}
                  {element.levels[levelKey].levelUpRecommendationPopover && (
                    <FaIcon
                      data-tip
                      data-for="level-up-tooltip"
                      className="fas fa-info-circle"
                      color={colors.darkBlue}
                      margin="0 0 0 8px"
                    />
                  )}
                </>
                <Tooltip
                  clickable
                  place="top"
                  id="level-up-tooltip"
                  content={
                    <Markdown>
                      {element.levels[levelKey].levelUpRecommendationPopover ||
                        ""}
                    </Markdown>
                  }
                />
              </AdvanceTitle>
              <Markdown>
                {element.levels[levelKey].levelUpRecommendation || ""}
              </Markdown>
            </div>
          </Section>
          {levelUpAction && (
            <LevelUpActionContainer>
              <AdvanceTitle>{levelUpAction?.actionHeader}</AdvanceTitle>
              {levelUpAction?.actionDetails && (
                <Markdown margin="0 0 12px">
                  {levelUpAction?.actionDetails}
                </Markdown>
              )}
              <Section flex="start" gap="12px 24px" flexWrap="wrap">
                {levelUpAction?.actionButton && (
                  <ActionButtonContainer>
                    {levelUpAction?.actionButton?.buttonType === "PRIMARY" ? (
                      <ButtonSmall
                        padding="0 16"
                        onClick={() =>
                          handlerOnCLick(
                            levelUpAction?.actionButton?.buttonLink,
                          )
                        }
                      >
                        {levelUpAction?.actionButton?.buttonText}
                      </ButtonSmall>
                    ) : (
                      <ButtonSecondary
                        padding="0 16"
                        backgroundColor={colors.white}
                        onClick={() =>
                          handlerOnCLick(
                            levelUpAction?.actionButton?.buttonLink,
                          )
                        }
                      >
                        {levelUpAction?.actionButton?.buttonText}
                      </ButtonSecondary>
                    )}
                  </ActionButtonContainer>
                )}
                <Section flexBasis="309px" flexGrow="1">
                  {levelUpAction?.actionNote && (
                    <Markdown>{levelUpAction?.actionNote}</Markdown>
                  )}
                </Section>
              </Section>
            </LevelUpActionContainer>
          )}
        </>
      )}
    </AdvancePanel>
  )
}

export default AdvanceToNextLevel
