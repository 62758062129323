import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import * as moment from "moment"
import * as React from "react"
import { Line } from "react-chartjs-2"
import { shallowEqual, useSelector } from "react-redux"

import { Content, HiLo, HiLoWrap, Score, Text, Title } from "./styles"

interface IRating {
  rating: number
  rating_date: string
  rating_color: string
}

const LineChart: React.FC = () => {
  const ratingsData = useSelector(
    (state: tState) => state.ratings.ratingsData?.data,
    shallowEqual,
  )
  const ratings: IRating[] = [...(ratingsData?.ratings || [])].reverse()
  const industryAvg = ratingsData?.industry_grade?.average_rating || null

  const highRating =
    [...ratings].sort((a, b) => (a.rating < b.rating ? 1 : -1))[0] || {}
  const lowRating =
    [...ratings].sort((a, b) => (a.rating > b.rating ? 1 : -1))[0] || {}

  const getExtraValues = (value: number) => {
    return [
      ...ratings.map(() => null).slice(0, -1),
      value,
      value,
      ratings.length < 150 ? null : value,
    ]
  }

  const dataset = React.useMemo(
    () => ({
      labels: [
        ...ratings.map((item) => item.rating_date),
        "",
        ...(ratings.length < 150 ? [] : [""]),
      ],
      datasets: [
        {
          label: "Rating",
          fill: false,
          borderColor: colors.bitsight.blue,
          borderWidth: 2,
          hitRadius: 10,
          pointRadius: 0,
          data: [...ratings.map((item) => item.rating)],
        },
        {
          label: "Industry Average",
          fill: false,
          borderColor: colors.bitsight.red,
          borderWidth: 1,
          hitRadius: 10,
          pointRadius: 0,
          data: [...ratings.map(() => industryAvg)],
        },
        // Additional datasets to display a bar on the right
        ...(ratings.length > 100
          ? [
              {
                label: "basic",
                borderColor: colors.bitsight.red,
                backgroundColor: colors.bitsight.red,
                borderWidth: 1,
                hitRadius: 0,
                pointRadius: 0,
                data: getExtraValues(640),
              },
              {
                label: "intermediate",
                // type: "line",
                borderColor: colors.bitsight.yellow,
                backgroundColor: colors.bitsight.yellow,
                borderWidth: 1,
                hitRadius: 0,
                pointRadius: 0,
                data: getExtraValues(740),
              },
              {
                label: "advanced",
                borderColor: colors.bitsight.blue,
                backgroundColor: colors.bitsight.blue,
                borderWidth: 1,
                hitRadius: 0,
                pointRadius: 0,
                data: getExtraValues(850),
              },
            ]
          : []),
        // Additional dataset to limit the range between 300 and 850
        {
          label: "hidden",
          fill: false,
          borderColor: "transparent",
          backgroundColor: "transparent",
          borderWidth: 0,
          hitRadius: 0,
          pointRadius: 0,
          data: [...ratings.map(() => null), 300, 850],
        },
      ],
    }),
    [ratingsData],
  )

  const options = React.useMemo(
    () => ({
      legend: {
        display: false,
      },
      tooltips: {
        mode: "index",
        intersect: true,
        xPadding: 15,
        yPadding: 10,
        backgroundColor: colors.white,
        titleFontColor: colors.gray50,
        bodyFontColor: colors.gray50,
        borderColor: colors.gray50,
        bodySpacing: 5,
        borderWidth: 1,
        displayColors: false,
      },
      scales: {
        x: {
          gridLines: {
            drawOnChartArea: false,
            tickMarkLength: 5,
            color: colors.gray50,
            zeroLineColor: colors.gray50,
          },
          ticks: {
            maxRotation: 0,
            autoSkipPadding: 25,
            fontColor: colors.gray30,
            fontSize: 10,
            padding: 5,
          },
        },
        y: {
          gridLines: {
            drawOnChartArea: false,
            tickMarkLength: 5,
            color: colors.gray50,
            zeroLineColor: colors.gray50,
          },
          ticks: {
            fontColor: colors.gray30,
            fontSize: 10,
            padding: 5,
          },
        },
      },
    }),
    [],
  )

  return (
    <Content>
      <Title>Security Ratings</Title>
      <HiLoWrap>
        <HiLo>
          <Score color={highRating.rating_color}>{highRating.rating}</Score>
          <Text>
            Highest on {moment(highRating.rating_date).format("D MMM YYYY")}
          </Text>
        </HiLo>
        <HiLo>
          <Score color={lowRating.rating_color}>{lowRating.rating}</Score>
          <Text>
            Lowest on {moment(lowRating.rating_date).format("D MMM YYYY")}
          </Text>
        </HiLo>
      </HiLoWrap>
      <Line data={dataset} options={options} />
    </Content>
  )
}

export default LineChart
