import { colors, media } from "@src/theme"
import styled from "styled-components"

interface IFormProps {
  isColumn: boolean
}

export const Form = styled.div<IFormProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  margin-right: ${({ isColumn }) => (isColumn ? "10px" : "0")};
  margin-bottom: 20px;
  &:not(:first-child) {
    border-top: 1px solid ${colors.blueBorderGradient2};
    margin-top: 25px;
    padding-top: 15px;
  }
  @media ${media.medium} {
    flex-direction: column;
    margin: 15px 0;
  }
`

export const InputWrap = styled.div`
  width: 100%;
  margin: 0 5px;

  @media ${media.medium} {
    margin: 0;
  }
`
