import { Spinner } from "@blueprintjs/core"
import { IHistoryRecord } from "@src/Model/Model"
import * as T from "@src/types"
import * as moment from "moment"
import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import styled from "styled-components"

export interface IHistory {
  height: number
  historyList: IHistoryRecord[]
  policyType: string
  policyName: string
  request: T.IRequestStatus
  onVersion: (policyType: string, version: number) => void
  onDownload: (policyType: string, version: number) => void
  onClose: () => void
}

class History extends React.Component<IHistory & RouteComponentProps, object> {
  render() {
    const onClose = this.props.onClose

    const historyList = this.props.historyList
      .map((e: any, i: number) => {
        const dt = new Date(e.ts)
        const dts = moment(dt).format("MMM D, YYYY")
        return (
          <Row key={"b" + i}>
            <CellVersion>v{e.version}</CellVersion>
            <CellInfo>
              <Approver> Approved by {e.approver}</Approver>
              <Approver> on {dts}</Approver>
              <div>
                <View
                  onClick={() => {
                    this.props.history.push(
                      `${T.ViewTypes.compliance}/${this.props.policyType}/${e.version}`,
                    )
                  }}
                >
                  VIEW
                </View>
                <View
                  onClick={() => {
                    this.props.onDownload(this.props.policyType, e.version)
                  }}
                >
                  DOWNLOAD
                </View>
              </div>
            </CellInfo>
          </Row>
        )
      })
      .reverse()

    const isLoading =
      this.props.request.target === "history" &&
      this.props.request.status === "LOADING"

    return (
      <Pane>
        <PaneHeader>
          <TitlePanel>
            <Title>Policy History</Title>
            <SubTitle>{this.props.policyName}</SubTitle>
          </TitlePanel>
          <Close onClick={() => onClose()}>
            <img src="/images/Close.svg" />
          </Close>
        </PaneHeader>
        <Content>
          <LoadingPanel hidden={!isLoading}>
            <Spinner size={25} />
          </LoadingPanel>
          <VersionList>{historyList}</VersionList>
        </Content>
      </Pane>
    )
  }
}

interface IStyle {
  hidden?: boolean
  status?: string
  flex?: string
  color?: string
}

const LoadingPanel = styled.div`
  display: ${(p: IStyle) => (p.hidden ? "none" : "flex")};
  height: 100px;
  align-items: center;
  justify-content: center;
`

const Pane = styled.div`
  font-family: proxima-nova, sans-serif;
  position: fixed;
  top: 0px;
  right: 0;
  width: min(100%, 450px);
  height: 100%;
  box-shadow: 0 0 4px rgb(0 0 0 / 25%);
  background-color: #f3f3f3;
  z-index: 5;
`

const PaneHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  color: #080d7e;
  font-size: 28px;
  line-height: 100px;
  padding-left: 46px;
`

const TitlePanel = styled.div`
  display: inline-block;
`

const Title = styled.div`
  font-family: montserrat;
  font-weight: 600;
  font-size: 28px;
  line-height: 60px;
  height: 60px;
`

const SubTitle = styled.div`
  font-size: 18px;
  line-height: 40px;
  height: 40px;
`

const Close = styled.div`
  height: 100px;
  float: right;
  padding: 15px;
  cursor: pointer;
`

const Content = styled.div<any>`
  height: calc(100vh - 100px);
  overflow: auto;
  padding-bottom: 120px;
`

const Approver = styled.div`
  padding: 5px;
  color: #4a4a4a;
  font-size: 16px;
  line-height: 20px;
`

const View = styled.span`
  cursor: pointer;
  padding: 5px;
`

const Row = styled.div`
  height: 120px;
  background-color: #fff;
  display: flex;
  margin-bottom: 8px;
`

const VersionList = styled.div`
  border-spacing: 0px 8px;
`

const CellVersion = styled.div`
  flex: 1 1 150px;
  line-height: 120px;
  color: #080d7e;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
`

const CellInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 300px;
  color: #080d7e;
`

export default withRouter(History)
