import * as ACTION from "@src/Actions/ActionTypes"
import * as POLICY from "@src/Actions/PolicyActions"
import C, { sagaMiddleware } from "@src/Controller"
import { ISecurityPolicy } from "@src/Model/Model"
import { axiosInstance } from "@src/Tools/AxiosInstance"
import { formatCustomValue } from "@src/Utils"
import axios from "axios"
import * as _ from "lodash"
import * as Mustache from "mustache"
import { put, takeEvery } from "redux-saga/effects"
import { v4 as uuid } from "uuid"

const encodeHtml = (html: string) => {
  encodeURIComponent(html)
  const encodedHtml = []
  for (let i = 0; i < html.length; i++) {
    encodedHtml.push(html.charCodeAt(i))
  }
  return encodedHtml
}

export function* watchCompliance() {
  yield takeEvery(ACTION.COMPLIANCE_REQ_LIST, requestList)
  yield takeEvery(ACTION.COMPLIANCE_REQ_HISTORY, requestHistory)
  yield takeEvery(ACTION.COMPLIANCE_REQ_POLICY, requestPolicy)
  yield takeEvery(ACTION.COMPLIANCE_REQ_SAVE_POLICY, requestSavePolicy)
  yield takeEvery(ACTION.COMPLIANCE_REQ_DELETE_POLICY, requestDeletePolicy)
}

function* requestList(): any {
  try {
    yield put(
      POLICY.openPolicyHistory({
        historyOpen: false,
      }),
    )
    yield put(POLICY.updateRequestStatus("list", "LOADING"))
    const response = yield axiosInstance.get(`/api/policy/policyList`)
    const list = response.data.data
    const outOfBoxPolicyList = response.data.outOfBoxPolicyList
    yield put(POLICY.updatePolicyList(list))
    yield put(POLICY.updateOutOfBoxList(outOfBoxPolicyList))

    yield put(POLICY.updateRequestStatus("list", "SUCCESS"))
  } catch (e) {
    console.log(e)
  }
}

function* requestSavePolicy(action: any): any {
  try {
    const {
      policy,
      policyAction,
    }: { policy: ISecurityPolicy; policyAction: string } = action.payload
    const env: any = { ...C.getEnvironment() }
    const firstName = C.getEnvParam("firstName")
    const lastName = C.getEnvParam("lastName")
    const approver = `${firstName} ${lastName}`
    const version = policy.version
    const status = policy.status

    const editMode = policyAction === "save" && status === "Active"
    const increment =
      (policyAction === "approve" && status !== "Draft") || editMode

    const newVersion = increment ? version + 1 : version

    const policyToSave = {
      ...policy,
      customPolicy:
        typeof policy.customPolicy === "string"
          ? encodeHtml(policy.customPolicy)
          : policy.customPolicy,
      approver,
      status: policyAction === "approve" ? "Active" : "Draft",
      version: newVersion,
      text: undefined,
    }

    const payload = {
      policy: policyToSave,
      env,
    }

    yield put(POLICY.updateRequestStatus("policySave", "LOADING"))
    const res = yield axios({
      method: "post",
      url: `/api/policy/policySave`,
      data: payload,
    })

    const target = policyAction === "save" ? "Draft" : "Policy"
    const saveAction = policyAction === "save" ? "saved" : "approved"

    if (res.data.success) {
      C.toastNotification(`${target} ${saveAction}.`, "success", 5000)

      const response = yield axios.get(`/api/policy/policyList`)
      const list = response.data.data
      yield put(POLICY.updatePolicyList(list))
    } else {
      C.toastNotification("Saving Policy Failed.", "danger", 5000)
    }

    yield put(POLICY.updateRequestStatus("policySave", "SUCCESS"))
    yield put(POLICY.requestPolicy(policyToSave))
  } catch (e) {
    console.log(e)
  }
}

function* requestDeletePolicy(action: any) {
  try {
    const { policyId, version } = action.payload
    yield put(POLICY.updateRequestStatus("policyDelete", "LOADING"))
    yield axios({
      method: "get",
      url: `/api/policy/policyDelete/${policyId};${version}`,
    })
    yield put(POLICY.updateRequestStatus("policyDelete", "SUCCESS"))
    C.toastNotification(`Draft deleted.`, "success", 5000)
  } catch (e) {
    console.log(e)
  }
}

function* requestPolicy(action: any): any {
  try {
    yield put(POLICY.updatePolicy(null))
    yield put(POLICY.updateRequestStatus("policy", "LOADING"))

    const entry = action.payload
    const response = yield axios({
      method: "post",
      url: `/api/policy/policyLoad`,
      data: { policyType: entry.id, version: entry.version },
    })

    const policy: ISecurityPolicy = response.data.data
    const storageList: any = response.data.storageList
    const { name, approvalDate, customValues, template } = policy

    const policyCustomText = expandPolicyText(
      name,
      approvalDate || "",
      customValues,
      template,
      {},
      "custom",
      entry.lockEdit,
    )

    yield put(POLICY.updatePolicy(policy))
    yield put(POLICY.updateStorageList(storageList))
    yield put(POLICY.updatePolicyText(policyCustomText))
    yield put(POLICY.updateRequestStatus("policy", "SUCCESS"))
  } catch (e) {
    console.log("Error:", e)
  }
}

function* requestHistory(action: any): any {
  try {
    const { policyType, policyName } = action.payload
    yield put(
      POLICY.openPolicyHistory({
        historyOpen: true,
        policyType,
        policyName,
        data: [],
      }),
    )

    yield put(POLICY.updateRequestStatus("history", "LOADING"))
    const response = yield axios({
      method: "post",
      url: "/api/policy/policyHistory",
      data: { policyType },
    })
    const data = response.data.data
    yield put(POLICY.updateRequestStatus("history", "SUCCESS"))
    yield put(
      POLICY.openPolicyHistory({
        historyOpen: true,
        policyType,
        policyName,
        data,
      }),
    )
  } catch (e) {
    console.log(e)
  }
}

export function downloadPolicy(policyType: string, policyVersion: number) {
  window.open(
    `/api/policy/policyDownload/${policyType};${policyVersion}/${policyType}_v${policyVersion}.pdf?s=${uuid()}`,
  )
}

const showCustomDialog = (id: string, offset: number) => {
  function* show() {
    try {
      yield put(POLICY.showCustomDialog({ id, offset }))
    } catch (e) {
      console.log(e)
    }
  }
  sagaMiddleware.run(show)
}

const getPolicyCustomization = (
  policyName: string,
  approvalDate: string,
  customValues: any,
  wrapFn: (text: string, id: string, custom?: any, lock?: boolean) => string,
  lockEdit?: boolean,
) => {
  const custom = customValues || {}
  const env: any = { ...C.getEnvironment() }

  env.PolicyName = policyName
  env.Policy_Approval_Date = approvalDate

  _.forEach(custom, (value, k) => {
    const config = C.window.securityPolicyConfig[k]
    const fmtValue =
      config && config.type === "section"
        ? value
        : formatCustomValue(value, k, config)
    const buttonText = config ? config.buttonText : null

    env[k] = () =>
      wrapFn(
        `${fmtValue}`,
        k,
        {
          buttonText,
          displayOnly: config && config.type === "display",
        },
        lockEdit,
      )
  })
  return env
}

export function clearCustomPolicy() {
  function* newPolicy() {
    const entry: ISecurityPolicy = {
      id: "",
      name: "",
      version: 0,
      saved: false,
      custom: true,
      status: "Draft",
      approver: "",
      approvalDate: "",
      text: "",
      template: "",
      customPolicy: "",
      customValues: {},
    }

    yield put(POLICY.updatePolicy(entry))
    yield put(POLICY.updateStorageList([]))
    yield put(POLICY.updatePolicyText(""))
  }
  sagaMiddleware.run(newPolicy)
}

export function newCustomPolicy(id: string, name: string, version: number) {
  function* newPolicy() {
    const entry: ISecurityPolicy = {
      id,
      name,
      version,
      saved: false,
      custom: true,
      status: "Draft",
      approver: "",
      approvalDate: "",
      text: "",
      template: "",
      customPolicy: "",
      customValues: {},
    }

    yield put(POLICY.updatePolicy(entry))
    yield put(POLICY.updateStorageList([]))
    yield put(POLICY.updatePolicyText(""))
  }
  sagaMiddleware.run(newPolicy)
}

export const expandPolicyText = (
  policyName: string,
  approvalDate: string,
  customValues: any,
  template: string,
  metadata: any,
  wrapType: string,
  lockEdit?: boolean,
) => {
  C.window.customize = (id: string): void => {
    showCustomDialog(id, 100)
  }

  function wrapCustom(
    text: string,
    id: string,
    options: any,
    lockEdit?: boolean,
  ) {
    if (options.displayOnly) {
      return text
    }

    if (options.buttonText && lockEdit) {
      return text &&
        text.trim().length > 0 &&
        !["Yes", "No"].includes(text.trim())
        ? `<div style="padding-top: 20px;padding-bottom: 20px;"/>${text}</div>`
        : ""
    }

    if (options.buttonText) {
      return (
        `<div id="${id}" data-security-policy-id="security policy custom field" style="background-color: #f1ccff; 
      margin-top: 10px; 
      margin-bottom: 10px; 
      cursor:pointer"
      onclick="customize(this.id)">${options.buttonText} <span style="font-family:edit">&#xe827;</span>
      </div>` +
        (text && text.trim().length > 0
          ? `<div style="padding-top: 20px;padding-bottom: 20px;border-top: 1px dashed #000;border-bottom:1px dashed #000"/>${text}</div>`
          : "")
      )
    }

    if (lockEdit) {
      return `<span id="${id}" data-security-policy-id="security policy custom field" style="border-radius: 4px;">${text}</span>`
    }

    const button = '<span style="font-family:edit">&#xe827;</span>'
    return `<span id="${id}" data-security-policy-id="security policy custom field" style="background-color: #f1ccff; border-radius: 4px; cursor:pointer" onclick="customize(this.id)">${text} ${button}</span>`
  }

  const customization = getPolicyCustomization(
    policyName,
    approvalDate,
    customValues,
    wrapCustom,
    lockEdit,
  )
  return Mustache.render(template, customization)
}
