import { usePortalSize } from "@src/Components/Common/usePortalSize"
import * as React from "react"

import { PasswordChange } from "./PasswordChange"
import { PersonalDetails } from "./PersonalDetails"
import { Container, FaIcon, Hr, Title } from "./styles"

const UserDetails = () => {
  const { isDesktop } = usePortalSize()

  return (
    <>
      <Title>
        {!isDesktop && <FaIcon className="fas fa-user-circle" />}
        Personal Details
      </Title>
      <Container>
        {isDesktop && <FaIcon large className="fas fa-user-circle" />}
        <PersonalDetails />
      </Container>
      <Hr />
      <Title>
        {!isDesktop && <FaIcon className="fas fa-lock" />}
        Change Your Password
      </Title>
      <Container>
        {isDesktop && <FaIcon large className="fas fa-lock" />}
        <PasswordChange />
      </Container>
    </>
  )
}
export default UserDetails
