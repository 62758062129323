import { Spinner } from "@blueprintjs/core"
import * as React from "react"
import styled from "styled-components"

interface IWaiting {
  text: string
  hidden?: boolean
  padding?: number
  height?: string
}

const Text = styled.div`
  margin-top: 10px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
`

export class Waiting extends React.Component<IWaiting, object> {
  render() {
    if (this.props.hidden) {
      return null
    }

    return (
      <WaitingContainer height={this.props.height}>
        <Spinner size={25} />
        <Text>{this.props.text}</Text>
      </WaitingContainer>
    )
  }
}

export class Failed extends React.Component<IWaiting, object> {
  render() {
    if (this.props.hidden) {
      return null
    }
    return (
      <div
        style={{
          height: "100%",
          color: "red",
          paddingTop: this.props.padding || 200,
          textAlign: "center",
          fontSize: 16,
        }}
      >
        {this.props.text}
      </div>
    )
  }
}

interface IWaitingContainer {
  height?: string
}

const WaitingContainer = styled.div<IWaitingContainer>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || "100vh"};
`

export default Waiting
