import { Button, ButtonGroup } from "@blueprintjs/core"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Link } from "@src/Components/Link"
import PageHeaderCard from "@src/Components/PageHeaderCard"
import { Section } from "@src/Components/StyledUtils"
import { Content, ContentArea, Panel } from "@src/Components/View"
import { colors, media } from "@src/theme"
import * as React from "react"
import { createRef, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { tState } from "@src/Model/Model"

import PartnerCard from "./PartnerCard"

interface IMarketplace {
  subscriptionStatus: "subscribed" | "nonSubscribed"
  marketingData: any
}

export const Marketplace = (props: IMarketplace) => {
  const elRefs = useRef<Array<HTMLDivElement | null>>([])
  const [selectedRef, setSelectedRef] = useState<string>("")
  const [visibleRatio, setVisibleRatio] = useState<{ [key: string]: any }>({})
  const { isDesktop } = usePortalSize()
  const { emailDomain } = useSelector((state: tState) => state.brand)

  useEffect(() => {
    if (elRefs.current.length !== props.marketingData.length) {
      elRefs.current = Array(props.marketingData.length).map(
        (_, i) => elRefs.current[i] || createRef<HTMLDivElement>().current,
      )
    }
  }, [props.marketingData])

  const handleClick = (refIndex: number) => {
    const yOffset = -90
    const element = elRefs!.current[refIndex]
    const y =
      element!.getBoundingClientRect().top + window.pageYOffset + yOffset
    console.log(y)
    window.scrollTo({ top: y, behavior: "smooth" })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleRatioNew: { [key: string]: number } = visibleRatio || {}
        entries.forEach((entry) => {
          visibleRatioNew[entry!.target!.id] = entry.intersectionRatio
        })
        let maxRatio = -1
        let maxRatioEntry = null
        for (const [key, value] of Object.entries(visibleRatioNew)) {
          if (value > maxRatio) {
            maxRatio = value
            maxRatioEntry = key
          }
        }
        maxRatioEntry && setSelectedRef(maxRatioEntry)
        setVisibleRatio(visibleRatioNew)
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
      },
    )
    if (elRefs.current) {
      elRefs.current.forEach((ref) => ref && observer.observe(ref))
    }
  }, [elRefs])

  return (
    <ContentArea>
      <PageHeaderCard
        heading="Marketplace"
        id="training-heading"
        cardIconUrl="images/marketplace/icon-marketplace.svg"
        cardBody={
          <div>
            Cyber Safety empowers organizations with a powerful and flexible
            ecosystem of tools and services from our trusted partners.
          </div>
        }
        maxGrow={1638}
        isStickyOnMobile
      />
      <Panel maxGrow={1638} borderColor={colors.warning}>
        <Content>
          <StickySection>
            <ButtonGroupContainer>
              <ButtonGroup>
                {props.marketingData.map((section: any, index: number) => {
                  return (
                    <StyledButton
                      className={
                        `section-${index}` === selectedRef
                          ? "selected"
                          : undefined
                      }
                      text={section.partnerType}
                      onClick={() => handleClick(index)}
                      key={index}
                      style={{ fontFamily: "montserrat" }}
                    />
                  )
                })}
              </ButtonGroup>
            </ButtonGroupContainer>
          </StickySection>
          {props.marketingData.map((data: any, categoryIndex: number) => (
            <Section
              flex="start start column"
              padding="0 0 32 0"
              width="100%"
              key={categoryIndex}
            >
              <SectionContainer
                id={`section-${categoryIndex}`}
                ref={(el) => (elRefs.current[categoryIndex] = el)}
              >
                <Section
                  flex="start start column"
                  style={{ minHeight: "min-content", width: "100%" }}
                >
                  {isDesktop && (
                    <SectionHeading>
                      {data.partnerType.toUpperCase()}
                    </SectionHeading>
                  )}
                  <CardContainer>
                    {data.partners.map((partner: any, index: number) => (
                      <PartnerCard
                        introduction={partner.introduction}
                        name={partner.name}
                        key={index}
                      />
                    ))}
                    <EmptySection />
                  </CardContainer>
                </Section>
              </SectionContainer>
            </Section>
          ))}
          <Section align="start">
            Having trouble? Contact us at{" "}
            <Link href={`mailto:support@${emailDomain}`} isUnderline>
              support@{emailDomain}
            </Link>
            .
          </Section>
        </Content>
      </Panel>
    </ContentArea>
  )
}

const SectionContainer = styled.div`
  scrollmargin: 70px;
  width: 100%;
`

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const StickySection = styled.div`
  min-height: 50px;
  position: sticky;
  top: 0px;
  align-self: flex-start;
  padding: 25px 44px;
  background: #fff;
  margin: -12px -44px;
  width: calc(100% + 88px);
  font-family: montserrat;

  @media ${media.medium} {
    top: 142px;
    margin: -12px;
    padding: 0 12px 25px;
    width: calc(100% + 24px);
    z-index: 5;
  }

  button {
    min-width: 100px;
    padding-right: 19px;
    padding-left: 19px;
    margin-left: 0;
  }
`

const CardContainer = styled.div`
  padding-top: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  width: 100%;
  gap: 40px 46px;
  @media ${media.medium} {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`

const EmptySection = styled.div`
  flex: 1 1 400px;
  @media (max-width: 400px) {
    flex-basis: 270px;
  }
`

const SectionHeading = styled.div`
  display: flex;
  font-family: montserrat;
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  align-self: left;
  justify-content: left;
  color: ${colors.darkBlue};
  align-items: center;
  border-bottom: 0.5px solid rgba(94, 0, 129, 0.3);
  padding: 15px 0px 10px 0px;
  width: 100%;
`

const StyledButton = styled(Button)`
  &.bp5-button {
    font-weight: 500;
    background-color: white;
    &.selected {
      font-weight: 600;
      background-color: rgb(207, 224, 245);
      :hover {
        background-color: rgb(207, 224, 245);
      }
    }
    :hover {
      background-color: white;
    }
  }
`

export default Marketplace
