import { colors } from "@src/theme"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import styled from "styled-components"

interface IProgressProps {
  value: number
}

export const TooltipColumn = styled.div`
  display: inline-block;
  font-size: 16px;
`

export const TooltipLineLabel = styled.div`
  text-align: end;
  margin-right: 4px;
  color: ${colors.white};
`

export const TooltipLineValue = styled.div`
  text-align: start;
  font-weight: 300;
  color: ${colors.white};
`

export const CircularProgressbarContainer = styled.div<IProgressProps>`
  width: 108px;
  height: 108px;

  svg {
    border: ${({ value }) => value === 0 && `1px solid ${colors.gray5}`};
    border-radius: 50%;
  }

  .progress-body-wrapper {
    height: ${({ value }) => (value === 0 ? "30px" : "30px")};
  }

  @media (max-width: 1000px) {
    width: 90px;
    height: 90px;
    margin: 0 2px;
  }
  @media (min-width: 769px) and (max-width: 830px) {
    width: 70px;
    height: 70px;
    margin: 0 2px;
  }

  .tooltip-content-custom {
    h1 {
      margin: 0;
      color: ${colors.black50};
      font-size: 24px;
      line-height: 29px;
    }
    p {
      margin: 0;
      color: ${colors.white};
      font-size: 16px;
      line-height: 19px;
    }
  }

  h3 {
    font-size: 18px;
    line-height: 17px;
    font-weight: 700;
    // color: ${(props) =>
      props.value === 0 ? colors.gray20 : colors.black50};
    margin: 0;
    margin-top: -10px;
  }

  p {
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    // color: ${(props) =>
      props.value === 0 ? colors.gray20 : colors.black50};
    margin: 0;

    @media (max-width: 1000px) {
      font-size: 12px;
    }
  }
`

export const StyledCircularProgressbar = styled(
  CircularProgressbarWithChildren,
)`
  transform: rotate(90deg);
  transform-origin: center center;
`
