import { BreadcrumbProps, Breadcrumbs } from "@blueprintjs/core"
import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import ExternalLink from "@src/Components/ExternalLink"
import { height, margin, padding } from "@src/Components/StyledUtils"
import { View } from "@src/Components/View"
import { colors } from "@src/theme"
import * as React from "react"
import styled from "styled-components"

interface IPartnerPage {
  subscriptionStatus: "subscribed" | "nonSubscribed"
  onSubscribe: () => void
  partnerData: any
  goToMarketplace: () => void
}

const PartnerPage: React.FC<IPartnerPage> = ({
  subscriptionStatus,
  onSubscribe,
  partnerData,
  goToMarketplace,
}) => {
  const myRef = React.useRef<HTMLElement | null>(null)

  React.useEffect(() => {
    myRef?.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    })
  }, [partnerData])

  const { detail, name, iconUrl } = partnerData

  const BREADCRUMBS: BreadcrumbProps[] = [
    { text: "Marketplace", onClick: goToMarketplace },
    { text: name || "Partner" },
  ]

  const subscribeText = detail.subHeadingMessage[subscriptionStatus]
  const subscriptionLink = detail.subscribeLink[subscriptionStatus]
  const subscriptionLinkText =
    detail.subscribeLinkText[subscriptionStatus] || `Get Started With ${name}`

  const createDetailMarkup = (html: string) => ({ __html: html })

  const externalLink = (link: string) => {
    if (link.slice(0, 7) === "http://" || link.slice(0, 8) === "https://") {
      return link
    }
    return `https://${link}`
  }

  return (
    <View ref={myRef} id="partner-page">
      <StyledBreadcrumbs items={BREADCRUMBS} />
      <Heading align="left">
        <CoboltLogo>
          <Logo height={44} src={iconUrl} />
        </CoboltLogo>
      </Heading>

      <Columns>
        <LeftColumn>
          <Heading>{detail.heading}</Heading>
          <br />
          {subscribeText && (
            <SubscribeSection>
              <i className={detail.subHeadingIconName} /> {subscribeText}
            </SubscribeSection>
          )}

          {detail.body.map((entry: any, index: number) => (
            <BodyEntry key={index}>
              {entry.heading ? (
                <SectionHeader>{entry.heading}</SectionHeader>
              ) : null}
              {entry.description ? (
                <SectionContent
                  dangerouslySetInnerHTML={createDetailMarkup(
                    entry.description,
                  )}
                />
              ) : null}
              {entry.externalLinkUrl ? (
                <SectionContent>
                  <ExternalLink
                    margin="0px"
                    text={entry.externalLinkText}
                    url={externalLink(entry.externalLinkUrl)}
                  />
                </SectionContent>
              ) : null}
            </BodyEntry>
          ))}

          <Section margin="20 0 20 0">
            {subscriptionLink ? (
              <ButtonSmall
                margin="0 30 0 0"
                width={300}
                height="auto"
                onClick={() => {
                  window.open(externalLink(subscriptionLink), "_blank")
                }}
              >
                {subscriptionLinkText}
              </ButtonSmall>
            ) : null}
            <ButtonSecondary
              onClick={onSubscribe}
              hidden={subscriptionStatus === "subscribed"}
              width={300}
            >
              Subscribe to Cyber Safety
            </ButtonSecondary>
          </Section>
        </LeftColumn>
        <RightColumn>
          <Image src={detail.screenshotImgUrl} />
          <ImageText>{detail.screenshotImgFooter}</ImageText>
        </RightColumn>
      </Columns>
    </View>
  )
}

const CoboltLogo = styled.div`
  margin: 24px 0px 24px 0px;
  display: flex;
  align-items: center;
`

const BodyEntry = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const Section = styled.div<any>`
  ${margin}
  ${padding}
  display: flex;
`

const Logo = styled.img<any>`
  ${height}
  ${margin}
  ${padding}
`

const Columns = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
`
const LeftColumn = styled.div<any>`
  flex: 1 1 400px;
  min-width: 0;
`

const RightColumn = styled.div`
  flex: 1 1 400px;
  min-width: 0;
  text-align: center;
`

const SubscribeSection = styled.div`
  margin-bottom: 16px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.success};
  background: rgba(0, 98, 216, 0.05);
  border: 1px solid rgba(0, 98, 216, 0.5);
  border-radius: 6px;
  padding: 10px 14px;

  i {
    color: ${colors.success};
    margin-right: 5px;
  }
`

const SectionHeader = styled.div`
  margin-bottom: 12px;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #291c9e;
`

const ImageText = styled.div`
  padding-top: 10px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  color: #6f7379;
  text-align: center;
`

const SectionContent = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  color: #212528;
`

const Heading = styled.div<any>`
  font-family: proxima-nova, sans-serif;
  font-size: 32px;
  font-weight: 600;
  text-align: ${(props: any) => props.align || "left"};
  color: #212294;
  margin-bottom: 3px;
`

export const Image = styled.img`
  border: 1px solid #979797;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
`

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  font-family: proxima-nova, sans-serif;
`

export default PartnerPage
