import { actions as CUSTOMER_PORTAL_DATA } from "@src/Actions/Reducers/CustomerPortalDataReducer"
import history from "@src/browserHistory"
import { VIEW_PATHS } from "@src/Routes/constants"
import axios from "axios"
import { all, put, takeLeading } from "redux-saga/effects"

import { IUpdateCustomerPortalData } from "../types"

function* getCustomerPortalData() {
  try {
    yield put(CUSTOMER_PORTAL_DATA.getCustomerPortalDataRequest())
    const { data } = yield axios.get(`/api/profile/getCustomerPortalData`)
    if (data.success) {
      const isDashboard = data.data.introViewed?.dashboard
      const welcomeEmailSent = data.data?.welcomeEmailSent
      if (!isDashboard && welcomeEmailSent) {
        history.push(VIEW_PATHS["intro"])
      }
      yield put(CUSTOMER_PORTAL_DATA.getCustomerPortalDataSuccess(data.data))
    }
  } catch (e) {
    yield put(CUSTOMER_PORTAL_DATA.getCustomerPortalDataFailure(e))
  }
}

function* updateCustomerPortalData(action: IUpdateCustomerPortalData) {
  try {
    yield put(CUSTOMER_PORTAL_DATA.updateCustomerPortalDataRequest())
    const { data } = yield axios.post(
      `/api/profile/updateCustomerPortalData`,
      action.payload,
    )
    if (data.success) {
      yield put(CUSTOMER_PORTAL_DATA.updateCustomerPortalDataSuccess(data.data))
    }
  } catch (e) {
    yield put(CUSTOMER_PORTAL_DATA.updateCustomerPortalDataFailure(e))
  }
}

function* monitoringWatcherSaga() {
  yield all([
    takeLeading("GET_CUSTOMER_PORTAL_DATA_WATCHER", getCustomerPortalData),
    takeLeading(
      "UPDATE_CUSTOMER_PORTAL_DATA_WATCHER",
      updateCustomerPortalData,
    ),
  ])
}

export default monitoringWatcherSaga
