import { ButtonSecondary } from "@src/Components/Button"
import { colors, media } from "@src/theme"
import * as React from "react"
import styled from "styled-components"

interface IHeader {
  trialBannerheight: number
  cyberSecurityMonthBannerHeight: number
  width: number
  offsetLeft?: number
  OnSubscriptionPage: boolean
  goToSubscription: () => void
  hasTrialExpired: boolean
  cyberSecurityMonth: boolean
}

const Header: React.FC<IHeader> = ({
  trialBannerheight,
  cyberSecurityMonthBannerHeight,
  width,
  offsetLeft,
  OnSubscriptionPage,
  goToSubscription,
  hasTrialExpired,
  cyberSecurityMonth,
}) => {
  return (
    <span>
      {hasTrialExpired ? (
        <Navbar
          height={trialBannerheight}
          width={width}
          marginLeft={offsetLeft}
          backgroundColor={"#9a002a"}
          color={"#ffffff"}
        >
          <span>
            <SpanTrial style={{ padding: "10px 0px" }}>
              Your free trial has expired.&nbsp;
            </SpanTrial>
            {OnSubscriptionPage ? (
              <SpanTrial>Select a plan below.</SpanTrial>
            ) : (
              <ButtonSecondary
                width={170}
                backgroundColor="#ffffff"
                onClick={goToSubscription}
                fontFamily="montserrat"
                fontSize="18px"
              >
                Subscribe Now
              </ButtonSecondary>
            )}
          </span>
        </Navbar>
      ) : null}

      {cyberSecurityMonth ? (
        <Navbar
          height={cyberSecurityMonthBannerHeight}
          width={width}
          marginLeft={offsetLeft}
          top={hasTrialExpired && trialBannerheight}
          backgroundColor={"#88e2ff"}
          color={"#333333"}
        >
          <span>
            <b>Happy National Cybersecurity Awareness Month!</b>
            <br />
            Get <b>20% off</b> our Cyber Safety plans for 6 months with promo
            code <b>NCSAM2020</b>. Offer ends October 31st.
          </span>
        </Navbar>
      ) : null}
    </span>
  )
}

const Navbar = styled.div<any>`
  position: fixed;
  display: ${(props: any) => (props.hidden ? "none" : "flex")};
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: ${(props: any) => props.height}px;
  width: ${(props: any) => props.width}px;
  border-bottom: 1px solid #ccc;
  background: ${(props: any) => props.backgroundColor || "#9a002a"};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  color: ${(props: any) => props.color || "#333333"};
  padding: 10px 20px;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  text-align: center;
  top: ${({ top }) => top || 0}px;
  z-index: 5;
  @media ${media.medium} {
    font-size: 16px;
    padding: 5px 10px;
  }
`

const SpanTrial = styled.span`
  @media ${media.medium} {
    line-height: 1.5;
  }
  a {
    color: ${colors.white};
    text-decoration: underline;
    cursor: pointer;
  }
  i {
    margin-right: 8px;
  }
  font-family: Montserrat;
`

export default Header
