import {
  ButtonCancel,
  ButtonSecondary,
  ButtonSmall,
} from "@src/Components/Button"
import { SpinnerStyled } from "@src/Portal/Settings/styles"
import * as React from "react"

import {
  ButtonsWrap,
  ConfirmationText,
  Dialog,
  LinkStyled,
  Section,
} from "./styles"

interface IConfirmationModal {
  isOpen: boolean
  title: string
  text?: string
  type?: "success" | "danger" | "alert"
  disabled?: boolean
  isLoading?: boolean
  children?: React.ReactNode
  isBackButton?: boolean
  confirmButton?: string
  infoOnly?: boolean
  onClose: () => void
  onConfirm: () => void
  onBack?: () => void
  confirmButtonWidth?: number
  linkText?: string | React.ReactNode
  linkUrl?: string
  contentMargin?: string
}

const ConfirmationModal: React.FC<IConfirmationModal> = ({
  isOpen,
  title,
  text,
  type,
  isLoading,
  disabled,
  children,
  confirmButton = "Confirm",
  infoOnly,
  isBackButton = false,
  onClose,
  onConfirm,
  onBack,
  linkText,
  linkUrl,
  confirmButtonWidth,
  contentMargin,
}) => {
  return (
    <Dialog
      title={title}
      className={`zeg-dialog zeg-${type}`}
      isOpen={isOpen}
      onClose={onClose}
      content={
        <ConfirmationModalContent
          {...{
            text,
            disabled,
            children,
            confirmButton,
            infoOnly,
            isBackButton,
            onClose,
            onConfirm,
            onBack,
            confirmButtonWidth,
            isLoading,
            linkText,
            linkUrl,
            contentMargin,
          }}
        />
      }
    />
  )
}

export const ConfirmationModalContent: React.FC<
  Partial<IConfirmationModal>
> = ({
  text,
  disabled,
  children,
  confirmButton = "Confirm",
  infoOnly,
  isLoading,
  isBackButton = false,
  onClose,
  onConfirm,
  onBack,
  confirmButtonWidth = 220,
  linkText,
  linkUrl,
  contentMargin,
}) => {
  return (
    <Section>
      <ConfirmationText contentMargin={contentMargin}>
        {text || children || ""}
      </ConfirmationText>
      <ButtonsWrap>
        {linkUrl && <LinkStyled href={linkUrl}>{linkText}</LinkStyled>}
        {!infoOnly && (
          <ButtonCancel width={120} onClick={onClose}>
            Cancel
          </ButtonCancel>
        )}
        {isBackButton && (
          <ButtonSecondary
            width={85}
            margin="0 15 0 0"
            inverse={false}
            onClick={onBack}
          >
            Back
          </ButtonSecondary>
        )}
        <ButtonSmall
          width={confirmButtonWidth}
          onClick={onConfirm}
          disabled={disabled}
        >
          {isLoading ? <SpinnerStyled size={25} /> : confirmButton}
        </ButtonSmall>
      </ButtonsWrap>
    </Section>
  )
}

export default ConfirmationModal
