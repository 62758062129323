import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Section } from "@src/Components/StyledUtils"
import { Content } from "@src/Components/View"
import { colors } from "@src/theme"
import * as React from "react"

import PageHeader from "../PageHeader"
import { BodyWrapper, CardBodyWrapper, CardIcon, PanelStyled } from "./styles"

interface IPageHeaderCard {
  heading?: string | JSX.Element
  infoText?: string | JSX.Element
  showButton?: boolean
  onClickButton?: () => void
  buttonType?: "primary" | "secondary"
  buttonText?: string
  buttonWidth?: number
  showHelpCenter?: boolean
  helpCenterLinkUrl?: string
  showViewIntro?: boolean
  onClickViewIntro?: () => void
  id?: string
  cardIconUrl?: string
  cardBody?: JSX.Element
  color?: string
  maxGrow?: number
  maxWidth?: number
  imageWidth?: string
  contentWidth?: string
  hidden?: boolean
  maxHeight?: number
  minHeight?: number
  bodyWidth?: string
  borderColor?: string
  isStickyOnMobile?: boolean
}

const PageHeaderCard = (props: IPageHeaderCard) => {
  const {
    contentWidth,
    imageWidth,
    cardIconUrl,
    cardBody,
    maxGrow,
    maxWidth,
    minHeight,
    borderColor = colors.warning,
    isStickyOnMobile,
  } = props
  const { isDesktop, clientWidth } = usePortalSize()

  return (
    <PanelStyled
      maxWidth={maxWidth}
      maxGrow={maxGrow}
      flexGrow={0}
      margin={`0px 0px ${isDesktop ? 32 : 0}px 0px`}
      minHeight={minHeight || (isDesktop && 200) || undefined}
      borderColor={borderColor}
      isTopBorder
      isStickyOnMobile={isStickyOnMobile}
    >
      <Content padding="34px 44px 25px 44px">
        <PageHeader {...props} />
        <Section flex="start start">
          {cardIconUrl && clientWidth >= 1020 && (
            <CardIcon width="75px" src={cardIconUrl} />
          )}
          <BodyWrapper>
            <CardBodyWrapper width={contentWidth}>{cardBody}</CardBodyWrapper>
          </BodyWrapper>
        </Section>
      </Content>
    </PanelStyled>
  )
}

export default PageHeaderCard
