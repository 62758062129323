import ZegPanel from "@src/Components/Common/ZegPanel"
import ExternalLink from "@src/Components/ExternalLink"
import { Link } from "@src/Components/Link"
import PageHeaderCard from "@src/Components/PageHeaderCard"
import { Section } from "@src/Components/StyledUtils"
import { ContentArea } from "@src/Components/View"
import { tState } from "@src/Model/Model"
import {
  TRAINING_TABS,
  TRAINING_TABS_WITHOUT_TRAINEES,
} from "@src/Portal/Training/constants"
import { colors } from "@src/theme"
import * as T from "@src/types"
import {
  CourseResults as ICourseResults,
  TraineeData as ITrainee,
} from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"

import CourseLibrary from "../CourseLibrary"
import ManageTeam from "../ManageTeam"
import ReportCard from "../ReportCard"
import StartPage from "../StartPage"

interface ITraining {
  mode: "reportCard" | "manageTrainee" | "courseLibrary" | "getStarted"
  companyName: string
  hasTrainees: boolean
  trainingNumberOfSeats: T.tPlanCount
  onChangeMode: (id: string) => void
  onIntro: () => void
  isHighestPlan?: boolean
}

interface ITab {
  id: string
  text: string
  path: string
}

interface ITrainingNavParams {
  tab: string
  course: string
  trainee: string
}

const Training: React.FC<ITraining> = ({
  mode,
  companyName,
  hasTrainees,
  trainingNumberOfSeats,
  onChangeMode,
  onIntro,
  isHighestPlan,
}) => {
  const history = useHistory()
  const match = useRouteMatch<ITrainingNavParams>()

  const { traineesData, courseMap } = useSelector(
    (state: tState) => state.training.trainingData,
  )

  const [tab, setTab] = React.useState<ITab>()
  const [stepTab, setStepTab] = React.useState<
    "company" | "course" | "trainee"
  >("company")
  const [currentCourse, setCurrentCourse] = React.useState<ICourseResults>()
  const [currentTrainee, setCurrentTrainee] = React.useState<ITrainee>()

  const tabsData = hasTrainees ? TRAINING_TABS : TRAINING_TABS_WITHOUT_TRAINEES

  const tabParam = match.params.tab
  const courseParam = match.params.course
  const traineeParam = match.params.trainee

  const { helpCenter, emailDomain } = useSelector(
    (state: tState) => state.brand,
  )
  const learnMoreUrl = `https://${helpCenter}/hc/en-us/articles/360034056413`

  React.useEffect(() => {
    if (traineeParam) {
      const trainee = traineesData.find((e) => {
        return (
          traineeParam ===
          `${e.firstName} ${e.lastName} ${e.traineeId.slice(0, 2)}`
        )
      })
      if (trainee) {
        setCurrentTrainee(trainee)
      } else {
        history.push("/404")
      }
    } else {
      setCurrentTrainee(undefined)
    }
  }, [traineeParam])

  React.useEffect(() => {
    if (courseParam) {
      const course = courseMap.find((c) => c.name === courseParam)
      if (course) {
        setCurrentCourse(course)
      } else {
        history.push("/404")
      }
    } else {
      setCurrentCourse(undefined)
    }
  }, [courseParam])

  React.useEffect(() => {
    if (tabParam) {
      const tab = tabsData.find((t) => t.path === tabParam)
      if (tab) {
        setTab(tab)
      } else {
        history.replace(T.ViewTypes.training)
      }
    } else {
      history.replace(
        `${T.ViewTypes.training}/${hasTrainees ? "reportcards" : "getstarted"}`,
      )
    }
  }, [tabParam])

  React.useEffect(() => {
    if (tab) {
      if (currentCourse && currentTrainee) {
        setStepTab("trainee")
      } else if (currentCourse) {
        setStepTab("course")
      } else {
        setStepTab("company")
      }
      onChangeMode(tab.id)
    }
  }, [tab, currentCourse, currentTrainee])

  const numberOfSeats = trainingNumberOfSeats || {
    maximumAllowed: 0,
    currentUsage: 0,
    remaining: 0,
  }

  const onTabChange = (id: string) => {
    const path = tabsData.find((tab) => tab.id === id)?.path
    history.push(`${T.ViewTypes.training}/${path}`)
  }

  return (
    <ContentArea>
      <PageHeaderCard
        heading="Training"
        id="training-heading"
        showViewIntro={true}
        onClickViewIntro={onIntro}
        showHelpCenter={true}
        helpCenterLinkUrl={learnMoreUrl}
        buttonText="Upgrade"
        buttonType="secondary"
        showButton={numberOfSeats.remaining <= 0 && !isHighestPlan}
        onClickButton={() => history.push(T.ViewTypes.plan)}
        cardIconUrl="images/icon_Training_color.svg"
        color="#9C1E9D"
        borderColor={colors.link}
        cardBody={
          <div>
            Educating your team members is key to a strong security posture.
            Cybersecurity awareness training teaches team members to be
            security-first with the goal of reducing the human risk.&nbsp;
            <ExternalLink
              url={learnMoreUrl}
              text="More"
              margin="0px 40px 0px 0px"
            />
          </div>
        }
      />
      <ZegPanel
        tabs={tabsData}
        onTabChange={onTabChange}
        selectedTabId={mode}
        borderColor={colors.link}
      >
        {mode === "getStarted" && (
          <StartPage
            onChangeMode={() => {
              history.push(`${T.ViewTypes.training}/getstarted`)
            }}
            numberOfSeats={numberOfSeats}
            modalIsOpen={mode === "getStarted"}
          />
        )}

        {mode === "manageTrainee" && (
          <ManageTeam
            numberOfSeats={numberOfSeats}
            setCurrentTrainee={setCurrentTrainee}
            onChangeMode={onChangeMode}
          />
        )}
        {mode === "reportCard" && (
          <ReportCard
            companyName={companyName}
            stepTab={stepTab}
            currentCourse={currentCourse}
            currentTrainee={currentTrainee}
            setCurrentTrainee={(trainee) => {
              history.push(
                `/training/reportcards/${courseParam}/${trainee.name} ${
                  trainee.lastName
                } ${trainee.traineeId.slice(0, 2)}`,
              )
            }}
          />
        )}
        {mode === "courseLibrary" && (
          <CourseLibrary
            onChangeMode={() => {
              history.push(`${T.ViewTypes.training}/members`)
            }}
          />
        )}
        <Section align="start" margin="44 0 0 0">
          Having trouble? Contact us at{" "}
          <Link href={`mailto:support@${emailDomain}`} isUnderline>
            support@{emailDomain}
          </Link>
          .
        </Section>
      </ZegPanel>
    </ContentArea>
  )
}

export default React.memo(Training)
