import { ButtonSmall } from "@src/Components/Button"
import {
  Content,
  Divider,
  FormElement,
  Heading,
  LinkWrapper,
  Panel,
  PanelContainer,
  SubHeading,
} from "@src/Components/Common"
import FloatingLabelInput from "@src/Components/FloatingLabelInput"
import { Link } from "@src/Components/Link"
import PasswordInput from "@src/Components/PasswordInput"
import { Section } from "@src/Components/StyledUtils"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import MessageContainer from "@src/Portal/MessageContainer"
import { inviteLinkLookupWatcher, inviteLinkSignupWatcher } from "@src/Sagas"
import * as T from "@src/types"
import * as _ from "lodash"
import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { FooterIcon } from "./FooterIcon"

export type tOnSubmit = (data: any) => void

interface ISignUp {
  height: number
  width: number
  subscriptionId: string
  recaptchaRef: any
  onSignUp: (
    subscriptionId: string,
    username: string,
    password: string,
    callback: any,
  ) => void
  redirectToLogin: () => void
  executeCaptcha: () => void
  verifyToken: (token: any, callback: any) => void
  resetCaptcha: () => void
  uiRules?: T.tChannelUiRules
}

const SignUp = ({
  height,
  subscriptionId,
  recaptchaRef,
  onSignUp,
  redirectToLogin,
  executeCaptcha,
  verifyToken,
  resetCaptcha,
  uiRules,
}: ISignUp) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [passwordValid, setPasswordValid] = React.useState(false)
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [confirmPasswordValid, setConfirmPasswordValid] = React.useState(false)

  const inviteLinkLookup = useSelector(
    (state: tState) => state.account.inviteLinkLookup,
  )

  const clearState = () => {
    setEmail("")
    setPassword("")
  }

  const submitSignUp = () => {
    if (email.trim().length > 0 && password.trim().length > 0) {
      onSignUp(
        subscriptionId,
        _.toLower(email.trim()),
        password.trim(),
        (ret: any) => {
          if (!ret.success) {
            if (ret.error.code === "UsernameExistsException") {
              C.toastNotification(
                <div>
                  Looks like you already have an account.{" "}
                  <span
                    style={{
                      color: "#fffff",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={goToLoginScreen}
                  >
                    Sign in now
                  </span>
                </div>,
                "danger",
                20000,
              )
            } else {
              C.toastNotification(ret.error.message || ret.error, "danger")
            }
          }
        },
      )
    } else {
      C.toastNotification(
        "Form Incomplete. Please fill empty fields.",
        "danger",
      )
      resetCaptcha()
    }
  }

  const onEmailChange = (event: any) => {
    const value = event.target.value
    setEmail(value)
  }

  const onPasswordChange = (event: any) => {
    const value = event.target.value
    setPassword(value)
    setConfirmPassword("")
    setConfirmPasswordValid(false)
  }

  const onConfirmPasswordChange = (event: any) => {
    const value = event.target.value
    setConfirmPassword(value)
  }

  const onChange = (value: any) => {
    if (value !== null) {
      verifyToken(value, (response: any) => {
        if (response.success) {
          dispatch(inviteLinkLookupWatcher(encodeURIComponent(email)))
        } else {
          C.toastNotification("You smell like a robot.", "danger")
        }
      })
    } else {
      console.log("CAPTCHA ERROR")
    }
  }

  const goToLoginScreen = () => {
    clearState()
    redirectToLogin()
  }

  const pContent = {
    height,
  }

  React.useEffect(() => {
    const linkId = inviteLinkLookup.data?.linkId
    if (inviteLinkLookup.data) {
      if (linkId) {
        C.heapIdentify(email || "")
        C.partnerStackIdentify(email || "", () =>
          dispatch(inviteLinkSignupWatcher({ linkId, password })),
        )
      } else {
        submitSignUp()
      }
    }
  }, [inviteLinkLookup])

  return (
    <Content {...pContent}>
      <MessageContainer />
      <PanelContainer height={height}>
        <Panel>
          <Section flex="center center column">
            <Heading align="center" margin="20px 0px">
              Create your free account.
            </Heading>
          </Section>
          <Section flex="center center column">
            <SubHeading>
              Simplify monitoring, training, and compliance for your
              <br />
              organization with Cyber Safety.
            </SubHeading>
            {uiRules?.isTrialDisabled ? null : (
              <FormElement flexDirection="row" marginBottom="10px">
                <FooterIcon margin={true} text="Free 30 day trial" />
                <FooterIcon margin={true} text="No credit card" />
                <FooterIcon text="Cancel any time" />
              </FormElement>
            )}
            <FormElement>
              <FloatingLabelInput
                id="signup-email"
                label="Your work email address"
                type="text"
                value={email}
                onChange={onEmailChange}
              />
              <PasswordInput
                id="signup-password"
                label="Create password"
                value={password}
                onChange={onPasswordChange}
                isValid={(valid: boolean) => {
                  setPasswordValid(valid)
                }}
              />
              <PasswordInput
                id="signup-confirm-password"
                label="Confirm password"
                passwordToMatch={password}
                value={confirmPassword}
                onChange={onConfirmPasswordChange}
                isValid={(valid: boolean) => {
                  setConfirmPasswordValid(valid)
                }}
                noValidation={true}
              />
              <Section flex="center center column" w="100%">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lfi57UUAAAAACcMby1mAPrHTNZVGG_B2t_7KDDN"
                  size="invisible"
                  onChange={onChange}
                />
              </Section>
              <Section w="100%" margin="30 0 0 0">
                <ButtonSmall
                  onClick={() => {
                    setEmail(email.trim()), executeCaptcha()
                  }}
                  id="CyberSafety_Trial_Lead_Button"
                  disabled={
                    !(password && passwordValid && confirmPasswordValid) ||
                    inviteLinkLookup.isLoading
                  }
                >
                  {uiRules?.isTrialDisabled
                    ? "Create Account"
                    : "Start Free Trial"}
                </ButtonSmall>
              </Section>
              <LinkWrapper>
                <p>
                  Already have an account? <br />
                  <Link onClick={goToLoginScreen}>Sign in</Link>
                </p>
              </LinkWrapper>
              <Divider />
              <LinkWrapper fontWeight="400">
                <p>
                  By continuing, you agree to Cyber Safety&nbsp;
                  <Link
                    isUnderline
                    onClick={() => {
                      window.open(
                        "https://www.munichre.com/hsb/en/general/legal/terms-of-use.html",
                      )
                    }}
                  >
                    Terms&nbsp;of&nbsp;Service
                  </Link>
                  &nbsp;and
                  <br />
                  <Link
                    isUnderline
                    onClick={() => {
                      window.open(
                        "https://www.munichre.com/hsb/en/general/legal/privacy-statement.html",
                      )
                    }}
                  >
                    Privacy&nbsp;Policy
                  </Link>
                  .
                </p>
              </LinkWrapper>
            </FormElement>
          </Section>
        </Panel>
      </PanelContainer>
    </Content>
  )
}

export default SignUp
