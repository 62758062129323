import { Store } from "redux"

import * as T from "../types"
import { serverLog } from "./Logging"
import * as Middletier from "./Middletier"

class Recaptcha {
  private store: Store
  public window: T.tExtWindow & Window = window

  constructor(store: Store) {
    this.store = store
  }

  //  Function not used right now - Might need if we switch to Recaptcha v3
  loadScriptv3() {
    const url = "https://www.google.com/recaptcha/api.js"
    const queryString = "?render=6Ld7i7UUAAAAAGqAxem48q-8sMeDtYMp5f6BpEFo"
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = url + queryString
    script.async = true
    script.defer = true

    document.body.appendChild(script)
  }

  loadScriptv2() {
    const url = "https://www.google.com/recaptcha/api.js"
    const queryString = "?onload=onloadCallback&render=explicit"
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = url + queryString
    script.async = true
    script.defer = true

    document.body.appendChild(script)
  }

  verifyToken(token: any, callback?: any) {
    Middletier.xhrPost(
      `/recaptcha/verify`,
      JSON.stringify({ token }),
      (response: any) => {
        callback && callback(response)
        if (response.success) {
          //TODO: Maybe do something here later.
        } else {
          serverLog({
            level: "error",
            message: "Recaptcha failed",
            endpoint: "/recaptcha/verify",
            filename: __filename,
            payload: response,
          })
        }
      },
    )
  }

  // Function not used right now - Might need if we switch to Recaptcha v3
  executeAction(action?: string, callback?: any) {
    if (this.window.grecaptcha) {
      this.window.grecaptcha.ready(function () {
        this.window.grecaptcha
          .execute("6Ld7i7UUAAAAAGqAxem48q-8sMeDtYMp5f6BpEFo", {
            action: action,
          })
          .then(function (token: any) {
            this.verifyToken(token, callback)
          })
      })
    }
  }
}
export default Recaptcha
