import { tState } from "@src/Model/Model"
import { EmptyState } from "@src/Portal/Zimm/EmptyState"
import { ViewTypes } from "@src/types"
import * as _ from "lodash"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"

import Questionnaire from "../Questionnaire"
import { textToPath } from "../utils"

export interface ICategory {
  startIndex: number
  elementCategory: string
}

const Assessment = () => {
  const history = useHistory()
  const { params } = useRouteMatch<{ id?: string }>()
  const config = useSelector((store: tState) => store.zimm.getConfig.elements)
  const assessmentData = useSelector(
    (store: tState) => store.zimm.getAssessmentData.data,
  )
  const interstitials = useSelector(
    (store: tState) => store.zimm.getConfig.interstitials,
  )

  const categories = config
    .map(({ elementCategory }, index) => ({
      elementCategory,
      startIndex: index + 1,
    }))
    .filter(
      (element, index, arr) =>
        arr.findIndex(
          (el) => el.elementCategory === element.elementCategory,
        ) === index,
    )

  if (_.isEmpty(assessmentData)) {
    if (!params?.id) {
      return (
        <EmptyState
          assesmentItem={textToPath(interstitials[0].elementCategory)}
        />
      )
    }
    if (params?.id === "start") {
      history.push(
        `${ViewTypes.zimm}/assessment/${textToPath(
          interstitials[0].elementCategory,
        )}`,
      )
      return null
    }
  }
  if (!params.id || params?.id === "start") {
    history.push(`${ViewTypes.zimm}/report`)
    return null
  }

  return (
    <Questionnaire
      categories={categories}
      config={config}
      interstitials={interstitials}
      response={assessmentData?.response}
    />
  )
}
export default Assessment
