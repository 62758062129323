import { ButtonCancel, ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Link } from "@src/Components/Link"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import { ICoursesMT } from "@src/Portal/Training/ManageTeam"
import { ITraineeEditCourses } from "@src/types"
import * as React from "react"
import { useSelector } from "react-redux"

import IndeterminateCheckbox from "../IndeterminateCheckbox"
import SubscribeSection from "../SubscribeSection"
import {
  ButtonWrap,
  CheckIcon,
  CheckLabel,
  CheckWrap,
  Content,
  CourseItem,
  CourseList,
  Dialog,
  Footer,
  Section,
  TollTipContentList,
} from "./styles"

interface IModalEnrollMultiple {
  isOpen: boolean
  courses: ICoursesMT[]
  selection: Omit<ITraineeEditCourses, "enrollInGap">[]
  setTrainees: (arg: ITraineeEditCourses[]) => void
  onClose: () => void
  onEnroll: (arg: boolean) => void
  goToCourseLibraryTab: () => void
}

const ModalEnrollMultiple: React.FC<IModalEnrollMultiple> = ({
  isOpen,
  courses,
  selection,
  setTrainees,
  onClose,
  onEnroll,
  goToCourseLibraryTab,
}) => {
  const subscriptionStatus = useSelector(
    (state: tState) => state.plans.subscriptionStatus,
  )
  const isInTrial = subscriptionStatus === "INTRIAL"

  const { isDesktop } = usePortalSize()

  const [data, setData] = React.useState<ICoursesMT[]>(courses)
  const [selectionData, setSelectionData] = React.useState(selection)
  React.useEffect(() => {
    setData(courses)
  }, [courses, selection])

  React.useEffect(() => {
    setSelectionData(selection)
  }, [selection])
  const onChange = (name: string, index: number, courseId?: string) => {
    const newData = data.map((item: ICoursesMT) => ({ ...item }))

    let result = []
    const findCourse = selection.filter(
      (s) =>
        s.courseResults?.find((e) => e.name === name)?.averageScore === null,
    )
    const isTraineeHasCourse = (t: ITraineeEditCourses | undefined) =>
      t?.courseResults?.find((course) => {
        return course?.topics[0]?.id === courseId
      })
    const findIsCourseFinished = selection.filter(
      (s) => s.courseResults?.find((c) => c.name === name)?.averageScore,
    )
    if (newData[index].isEnrolled === "partially") {
      newData[index].isEnrolled = true
      result = selectionData.map((el) =>
        isTraineeHasCourse(el)
          ? el
          : {
              ...el,
              enrolledCourses: Array.from(
                new Set([...(el?.enrolledCourses || []), courseId]),
              ),
              unEnrolledCourses: el?.unEnrolledCourses.filter(
                (id: string) => id !== courseId,
              ),
            },
      )
    } else if (newData[index].isEnrolled) {
      result = selectionData.map((el) =>
        isTraineeHasCourse(el)
          ? {
              ...el,
              unEnrolledCourses: Array.from(
                new Set([...el.unEnrolledCourses, courseId]),
              ),
            }
          : {
              ...el,
              unEnrolledCourses: el?.unEnrolledCourses.filter(
                (id: string) => id !== courseId,
              ),
              enrolledCourses: el?.enrolledCourses.filter(
                (id: string) => id !== courseId,
              ),
            },
      )

      if (!findCourse.length && findIsCourseFinished.length) {
        newData[index].isEnrolled = "partially"
      } else {
        newData[index].isEnrolled = false
      }
    } else if (
      courses[index].isEnrolled === "partially" &&
      !newData[index].isEnrolled
    ) {
      newData[index].isEnrolled = "partially"
      result = selectionData.map((el) => {
        const emp = selection.find((s) => s.email === el.email)
        if (isTraineeHasCourse(emp)) {
          return {
            ...el,
            enrolledCourses: el?.enrolledCourses.filter(
              (id: string) => id !== courseId,
            ),
            unEnrolledCourses: el?.unEnrolledCourses.filter(
              (id: string) => id !== courseId,
            ),
          }
        }
        return el
      })
    } else {
      newData[index].isEnrolled = true
      result = selectionData.map((el) => ({
        ...el,
        enrolledCourses: Array.from(
          new Set([...(el?.enrolledCourses || []), courseId]),
        ),
      }))
    }
    const traineesData = result.map((trainee) => {
      const { courseResults, ...restTraineeData } = trainee
      return restTraineeData
    })
    setData(newData)
    setSelectionData(result)
    setTrainees(traineesData)
  }

  const isDisabled = JSON.stringify(courses) === JSON.stringify(data)
  const goToCourseTab = () => {
    goToCourseLibraryTab()
    onClose()
  }

  return (
    <Dialog
      title={`Edit Specialized Training`}
      className="zeg-dialog"
      isOpen={isOpen}
      onClose={onClose}
      content={
        <Section className="bp5-dialog-body">
          <Content>
            {isInTrial && <SubscribeSection />}
            <h3>
              Update specialized training enrollment for
              <span data-tip data-for="team-members-tooltip">
                {selection.length} team member{selection.length > 1 ? "s" : ""}.
              </span>
            </h3>
            <Tooltip
              id="team-members-tooltip"
              width="300px"
              pointerEvents="auto"
              content={
                <TollTipContentList height="150px" isScrollable>
                  {selection.map((el: ITraineeEditCourses) => (
                    <span key={el.email}>{el.email}</span>
                  ))}
                </TollTipContentList>
              }
            />
            <CourseList>
              {data.map((course, index: number) => (
                <CourseItem key={`${course.name}-${index}`}>
                  {course.isEnrolled === "partially" && (
                    <Tooltip
                      width="300px"
                      id={`team-members-partial-tooltip-${course.name}`}
                      content={
                        <TollTipContentList>
                          <h5>Already Enrolled</h5>
                          {course.traineeList?.map((el) => (
                            <span key={el.email}>
                              {el.email?.length > 24
                                ? `${el.email.slice(0, 24)}...`
                                : el.email}
                            </span>
                          ))}
                        </TollTipContentList>
                      }
                    />
                  )}
                  {course.isFinished ? (
                    <CheckWrap>
                      <CheckIcon className="fas fa-check-circle" />
                      <CheckLabel>{course.name}</CheckLabel>
                    </CheckWrap>
                  ) : (
                    <div
                      data-tip
                      data-for={`team-members-partial-tooltip-${course.name}`}
                    >
                      <IndeterminateCheckbox
                        key={`${course.name}-${index}`}
                        name={course.name}
                        value={course.isEnrolled}
                        disabled={isInTrial}
                        onChange={() =>
                          onChange(course.name, index, course?.id)
                        }
                      />
                    </div>
                  )}
                </CourseItem>
              ))}
            </CourseList>
          </Content>
          <Footer>
            <p>
              Learn more about courses in the{" "}
              <Link onClick={goToCourseTab}>Course Library.</Link>
            </p>
            <ButtonWrap>
              <ButtonCancel
                width={120}
                onClick={onClose}
                className="cancel-button-enroll"
              >
                Cancel
              </ButtonCancel>
              <ButtonSmall
                width={isDesktop ? 220 : 160}
                onClick={onEnroll}
                disabled={isDisabled}
              >
                Next
              </ButtonSmall>
            </ButtonWrap>
          </Footer>
        </Section>
      }
    />
  )
}

export default ModalEnrollMultiple
