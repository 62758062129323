import { Tabs } from "@blueprintjs/core"
import { colors } from "@src/theme"
import styled from "styled-components"

export const StyledTabs = styled(Tabs)`
  &.bp5-tabs.bp5-vertical {
    .bp5-tab-list.bp5-large {
      .bp5-tab {
        text-transform: uppercase;
        line-height: 52px;
        padding: 0 18px;
        &[aria-selected="true"] {
          font-weight: 700;
          color: ${colors.darkBlue};
        }
        &:not([aria-selected="true"]):not([aria-disabled="true"]):hover {
          background-color: ${colors.lightBlue3} !important;
        }
      }
      .bp5-tab-indicator-wrapper {
        .bp5-tab-indicator {
          background-color: ${colors.white};
        }
      }
    }
    .bp5-tab-panel {
      flex: 1;
      background-color: ${colors.white};
      padding: 18px 32px;
    }
  }
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
`
