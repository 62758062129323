import { usePortalSize } from "@src/Components/Common/usePortalSize"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { ZimmCard } from "@src/Portal/Dashboard/ZimmCard"
import * as T from "@src/types"
import * as React from "react"
import { useSelector } from "react-redux"

import { CardWrapper } from "./CardWrapper"
import { CyberConciergeCard } from "./CyberConciergeCard"
import { MarketplaceCard } from "./MarketplaceCard"
import { MonitoringCard } from "./MonitoringCard"
import { SecPolCard } from "./SecPolicyCard"
import { CardContainer, DashboardContainerStyled } from "./styles"
import { TrainingCard } from "./TrainingCard"

interface IDashboard {
  modules: T.tModules
  changeView: (e: string) => void
}

const Dashboard: React.FC<IDashboard> = ({ modules, changeView }) => {
  const { isLargeScreen } = usePortalSize()

  const status: T.tStatus = useSelector(
    (state: tState) => state.portal.dashboard.status,
  )
  const zimmNextQuestion = useSelector(
    (state: tState) => state.portal.dashboard.zimm?.nextQuestion,
  )
  const monitoringStatus: T.tStatus = useSelector(
    (state: tState) => state.portal.dashboard.monitoringStatus || "READY",
  )
  const isLoading = status === "LOADING" || status === "READY"
  const isLoadingMonitoring =
    monitoringStatus === "LOADING" || monitoringStatus === "READY"
  React.useEffect(() => {
    C.heapTrack("Viewed Home Page")
  }, [])
  const availableToolsModules = [
    modules.compliance,
    modules.training,
    modules.monitoring,
  ].filter((module) => module !== "NOT_AVAILABLE")

  const gridColumn = {
    cybermaturity: "span 6",
    cyberConcierge:
      modules.cybermaturity === "NOT_AVAILABLE" ? "span 12" : "span 6",
    marketplace: "span 12",
    default:
      availableToolsModules.length === 1
        ? "4/10"
        : availableToolsModules.length === 2
        ? "span 6"
        : "span 4",
  }

  return (
    <DashboardContainerStyled>
      <CardContainer>
        <CardWrapper
          isLoading={modules.cybermaturity !== "NOT_AVAILABLE" && isLoading}
          isNotAvailable={modules.cybermaturity === "NOT_AVAILABLE"}
          height={isLargeScreen ? 275 : undefined}
          gridColumn={gridColumn.cybermaturity}
          content={
            <ZimmCard
              isActive={modules.cybermaturity === "ACTIVE"}
              gridColumn={gridColumn.cybermaturity}
              setView={(isFinished?: boolean) => {
                modules.cybermaturity === "ACTIVE"
                  ? zimmNextQuestion
                    ? changeView(
                        `${T.ViewTypes.zimm}/assessment/${zimmNextQuestion}`,
                      )
                    : changeView(
                        `${T.ViewTypes.zimm}${
                          isFinished ? "/report" : "/assessment/start"
                        }`,
                      )
                  : changeView(T.ViewTypes.plan)
              }}
            />
          }
        />
        <CardWrapper
          isLoading={isLoading}
          isNotAvailable={false}
          height={isLargeScreen ? 275 : undefined}
          gridColumn={gridColumn.cyberConcierge}
          content={
            <CyberConciergeCard
              gridColumn={gridColumn.cyberConcierge}
              setView={(isEmptyState) =>
                changeView(
                  `${T.ViewTypes.cyberConcierge}${
                    isEmptyState ? "" : "/alerts"
                  }`,
                )
              }
            />
          }
        />
        <CardWrapper
          isLoading={modules.compliance !== "NOT_AVAILABLE" && isLoading}
          isNotAvailable={modules.compliance === "NOT_AVAILABLE"}
          gridColumn={gridColumn.default}
          content={
            <SecPolCard
              isActive={modules.compliance === "ACTIVE"}
              gridColumn={gridColumn.default}
              setView={() => {
                modules.compliance === "ACTIVE"
                  ? changeView(T.ViewTypes.compliance)
                  : changeView(T.ViewTypes.plan)
              }}
            />
          }
        />
        <CardWrapper
          isLoading={modules.training !== "NOT_AVAILABLE" && isLoading}
          isNotAvailable={modules.training === "NOT_AVAILABLE"}
          gridColumn={gridColumn.default}
          content={
            <TrainingCard
              isActive={modules.training === "ACTIVE"}
              gridColumn={gridColumn.default}
              setView={() => {
                modules.training === "ACTIVE"
                  ? changeView(T.ViewTypes.training)
                  : changeView(T.ViewTypes.plan)
              }}
            />
          }
        />
        <CardWrapper
          isLoading={
            modules.monitoring !== "NOT_AVAILABLE" && isLoadingMonitoring
          }
          isNotAvailable={modules.monitoring === "NOT_AVAILABLE"}
          gridColumn={gridColumn.default}
          content={
            <MonitoringCard
              isActive={modules.monitoring === "ACTIVE"}
              gridColumn={gridColumn.default}
              setView={() => {
                modules.monitoring === "ACTIVE"
                  ? changeView(T.ViewTypes.monitoring)
                  : changeView(T.ViewTypes.plan)
              }}
            />
          }
        />
        <CardWrapper
          isLoading={modules.marketplace !== "NOT_AVAILABLE" && isLoading}
          isNotAvailable={modules.marketplace === "NOT_AVAILABLE"}
          height={isLargeScreen ? 220 : undefined}
          gridColumn={gridColumn.marketplace}
          content={
            <MarketplaceCard
              isActive={modules.marketplace === "ACTIVE"}
              gridColumn={gridColumn.marketplace}
              setView={() => {
                modules.marketplace === "ACTIVE"
                  ? changeView(T.ViewTypes.marketplace)
                  : changeView(T.ViewTypes.plan)
              }}
            />
          }
        />
      </CardContainer>
    </DashboardContainerStyled>
  )
}

export default Dashboard
