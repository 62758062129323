import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import * as React from "react"
import { buildStyles } from "react-circular-progressbar"
import { useSelector } from "react-redux"

import {
  CircularProgressbarContainer,
  StyledCircularProgressbar,
  TooltipColumn,
  TooltipLineLabel,
  TooltipLineValue,
} from "./styles"

interface IProps {
  value: number
  valueEnd: number
  name: string
  withTopics: boolean
}

const ProgressBar: React.FC<IProps> = ({
  value,
  valueEnd,
  name,
  withTopics,
}) => {
  const traineesData = useSelector(
    (state: tState) => state.training.trainingData.traineesData,
  )
  const completedModules = traineesData.filter((trainee) => {
    const findCourse = trainee?.courseResults?.find(
      (course) => course.name === name,
    )
    const findTopicWithScore = findCourse?.topics.find((topic) => topic.score)
    return findTopicWithScore
  })?.length

  const valueFormatted =
    valueEnd === 0 ? 0 : Math.round((value / valueEnd) * 100)
  const mainColor = () => {
    if (valueFormatted === 0) {
      return colors.gray20
    } else if (valueFormatted < 34) {
      return colors.error
    } else if (valueFormatted < 67) {
      return colors.orange
    } else {
      return colors.success
    }
  }

  const trailColor = valueFormatted === 0 ? "transparent" : colors.gray5

  return (
    <CircularProgressbarContainer value={valueFormatted}>
      <div data-tip data-for={`${name}-progress-tooltip`}>
        <StyledCircularProgressbar
          value={value}
          maxValue={valueEnd === 0 ? undefined : valueEnd}
          styles={buildStyles({
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Text size
            textSize: "16px",
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,
            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: mainColor(),
            // textColor: '#f88',
            trailColor,
          })}
        >
          <div className="progress-body-wrapper">
            <h3 style={{ color: mainColor() }}>{valueFormatted}%</h3>
            <p style={{ color: mainColor() }}>Complete</p>
          </div>
        </StyledCircularProgressbar>
      </div>
      {(value > 0 || completedModules > 0) && (
        <Tooltip
          id={`${name}-progress-tooltip`}
          offset={{ top: -40 }}
          content={
            withTopics ? (
              <>
                <TooltipColumn>
                  <TooltipLineLabel>Courses:</TooltipLineLabel>
                  <TooltipLineLabel>Trainees:</TooltipLineLabel>
                  <TooltipLineLabel>Total modules:</TooltipLineLabel>
                </TooltipColumn>
                <TooltipColumn>
                  <TooltipLineValue>12 modules</TooltipLineValue>
                  <TooltipLineValue>{traineesData.length}</TooltipLineValue>
                  <TooltipLineValue>
                    {12 * traineesData.length} modules
                  </TooltipLineValue>
                </TooltipColumn>
              </>
            ) : (
              <p>
                Completed by {value} of {valueEnd} enrolled team members.
              </p>
            )
          }
        />
      )}
    </CircularProgressbarContainer>
  )
}

export default ProgressBar
