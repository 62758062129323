import { colors } from "@src/theme"
import styled from "styled-components"

export const DownloadCsvIcon = styled.i`
  font-size: 16px;
  margin-right: 6px;
`
export const DownloadCSVStyled = styled.button`
  width: 190px;
  font-size: 16px;
  border: none;
  background: transparent;
  color: ${colors.link};
  cursor: pointer;
  font-family: proxima-nova, sans-serif;
  &:hover {
    color: ${colors.violet};
  }
`
