import { ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Link } from "@src/Components/Link"
import { tState } from "@src/Model/Model"
import { ViewTypes } from "@src/types"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"

import Card from "./Card"
import {
  CourseLibraryContainer,
  HeadContainer,
  HeadNavigationText,
  HeadSubTitle,
  HeadTitle,
  HeadWrap,
} from "./styles"

interface ICourseLibrary {
  onChangeMode: () => void
}

const CourseLibrary: React.FC<ICourseLibrary> = ({ onChangeMode }) => {
  const history = useHistory()
  const { isDesktop } = usePortalSize()
  const catalog = useSelector(
    (state: tState) => state.training.trainingData.catalogMap,
  )
  const { traineesData } = useSelector(
    (store: tState) => store.training.trainingData,
  )
  const subscriptionStatus = useSelector(
    (state: tState) => state.plans.subscriptionStatus,
  )

  const isInTrial = subscriptionStatus === "INTRIAL"

  const onSubscribe = () => {
    history.push(ViewTypes.plan)
  }

  return (
    <CourseLibraryContainer>
      {isDesktop && (
        <HeadContainer>
          <HeadWrap>
            <HeadTitle>Course Library</HeadTitle>
            {isInTrial && (
              <HeadSubTitle>
                Unlock the full library when you subscribe.
              </HeadSubTitle>
            )}
          </HeadWrap>
          <HeadNavigationText>
            {!isInTrial ? (
              <>
                <i className="fas fa-users" />
                <Link onClick={onChangeMode}>
                  {traineesData.length
                    ? "Update Team Member Enrollment"
                    : "Add Team Members to Training"}
                </Link>
              </>
            ) : (
              <ButtonSmall onClick={onSubscribe} width={120}>
                Subscribe
              </ButtonSmall>
            )}
          </HeadNavigationText>
        </HeadContainer>
      )}
      <div className="cards-container">
        {catalog.map((c) => (
          <Card
            key={c.name}
            data={c}
            isLock={c.name !== "Cybersecurity Series" && isInTrial}
          />
        ))}
      </div>
    </CourseLibraryContainer>
  )
}
export default CourseLibrary
