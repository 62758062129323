import * as React from "react"

import { ColoredCircle } from "./styles"

export const LEVEL_COLORS = {
  "0": { color: "#5F0000", border: "#AF8080" },
  "1": { color: "#9A002A", border: "#CC8094" },
  "2": { color: "#CD3C15", border: "#E69D8A" },
  "3": { color: "#FF7800", border: "#FFBC30" },
  "4": { color: "#8D7B18", border: "#C6BD8B" },
  "5": { color: "#1C7F30", border: "#8DBF97" },
}

interface ILevel {
  level?: number
  large?: boolean
  margin?: string
  dataFor?: string
  cursor?: string
  showTooltip?: boolean
}

const Level = ({
  level,
  large,
  margin,
  dataFor,
  cursor,
  showTooltip = true,
}: ILevel) => {
  const levelKey = (level || 0).toString() as "0" | "1" | "2" | "3" | "4" | "5"
  const color =
    typeof level === "number" && level >= 0 && level <= 5
      ? LEVEL_COLORS[levelKey].color
      : undefined
  const borderColor =
    typeof level === "number" && level >= 0 && level <= 5
      ? LEVEL_COLORS[levelKey].border
      : undefined

  return (
    <ColoredCircle
      data-tip
      data-for={
        showTooltip ? dataFor || `tooltip-Level-info-${level}` : undefined
      }
      color={color}
      borderColor={!large && color ? "transparent" : borderColor}
      large={large}
      margin={margin}
      cursor={cursor}
    >
      {level}
    </ColoredCircle>
  )
}

export default Level
