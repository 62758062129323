import { Section, Select } from "@zeguro/component-library"
import * as React from "react"

import { StyledInput, TimeContainer } from "./styles"

interface ITimePicker {
  value?: Date
  onChange: (time: Date) => void
}

const APPM_OPTIONS = [
  { value: "am", label: "AM" },
  { value: "pm", label: "PM" },
]

const TimePicker = ({ value, onChange }: ITimePicker) => {
  const [currentTime, setCurrentTime] = React.useState(value || new Date())
  const [hours, setHours] = React.useState(
    (currentTime.getHours() % 12 || 12).toString(),
  )
  const formatMinutes = (minutes: string) => `0${minutes}`.slice(-2)
  const [minutes, setMinutes] = React.useState(
    formatMinutes(currentTime.getMinutes().toString()),
  )
  const [ampm, setAmpm] = React.useState(
    currentTime.getHours() >= 12 ? "pm" : "am",
  )
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    event.target.select()

  const setTime = () => {
    const currentHours = currentTime.getHours()
    const currentMinutes = currentTime.getMinutes()
    const hoursNumber = Number(hours)
    const minutesNumber = Number(minutes)
    const updatedHours =
      !isNaN(hoursNumber) && hoursNumber >= 1 && hoursNumber <= 12
        ? Number(hours)
        : currentHours

    const updatedMinutes =
      !isNaN(minutesNumber) && minutesNumber >= 0 && minutesNumber <= 59
        ? Number(minutes)
        : currentMinutes

    setHours(updatedHours.toString())
    setMinutes(formatMinutes(updatedMinutes.toString()))
    const updatedTime = new Date(currentTime)
    updatedTime.setHours(
      ampm === "pm" ? (updatedHours % 12) + 12 : updatedHours % 12,
      updatedMinutes,
    )
    setCurrentTime(updatedTime)
    onChange(updatedTime)
  }

  const onChangeAmpm = (value: string) => {
    const currentHours = currentTime.getHours() % 12
    const updatedTime = new Date(currentTime)
    updatedTime.setHours(
      value === "pm" ? (currentHours % 12) + 12 : currentHours % 12,
    )
    setAmpm(value)
    onChange(updatedTime)
  }

  return (
    <Section flexLayout="flex-start">
      <TimeContainer>
        <StyledInput
          name={"hour"}
          defaultValue="12"
          value={hours}
          maxLength={2}
          onChange={(event) => setHours(event.target.value)}
          onBlur={setTime}
          onFocus={handleFocus}
        />
        :
        <StyledInput
          name={"minute"}
          defaultValue="00"
          value={minutes}
          maxLength={2}
          onChange={(event) => setMinutes(event.target.value)}
          onBlur={setTime}
          onFocus={handleFocus}
        />
      </TimeContainer>
      <Select
        name={"ampm"}
        margin="0"
        options={APPM_OPTIONS}
        defaultValue={APPM_OPTIONS[0]}
        value={APPM_OPTIONS.find(({ value }) => value === ampm)}
        onChange={({ value }: { value: string; label: string }) =>
          onChangeAmpm(value)
        }
      />
    </Section>
  )
}

export { TimePicker }
