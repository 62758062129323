import {
  actions as GLOBAL_MODALS,
  GLOBAL_MODALS_KEYS,
} from "@src/Actions/Reducers/GlobalModalsReducer"
import { actions as ZIMM } from "@src/Actions/Reducers/ZimmReducer"
import { tState } from "@src/Model/Model"
import { getAssessmentDataWatcher, getConfigWatcher } from "@src/Sagas"
import { all, put, select, take, takeLeading } from "redux-saga/effects"

function* triggerCybersecurityRoadBlockModal() {
  try {
    const isCybersecurityReleaseNotes: boolean = yield select(
      (store: tState) =>
        store.globalModals.globalModalState.cybersecurityReleaseNotes,
    )
    const dontShowRoadBlock: boolean = yield select(
      (store: tState) =>
        store.customerPortalData.data?.cybermaturity?.dontShowRoadBlock,
    )

    // Check conditions to proceed
    if (!isCybersecurityReleaseNotes && !dontShowRoadBlock) {
      // Start both getConfigWatcher and getAssessmentDataWatcher concurrently
      yield all([put(getConfigWatcher()), put(getAssessmentDataWatcher())])

      // Wait for both getConfigSuccess and getAssessmentDataSuccess actions
      yield all([
        take(ZIMM.getConfigSuccess),
        take(ZIMM.getAssessmentDataSuccess),
      ])

      const assessmentStatus: string = yield select(
        (store: tState) =>
          store.zimm.getAssessmentData.data?.assessment?.assessmentStatus,
      )

      if (assessmentStatus !== "completed") {
        yield put(
          GLOBAL_MODALS.toggleGlobalModal(
            GLOBAL_MODALS_KEYS.isCybersecurityRoadBlock,
          ),
        )
      }
    }
  } catch (e) {
    console.error("trigger road block error", e)
  }
}

export function* globalModalsWatcherSaga() {
  yield takeLeading(
    "TRIGGER_CYBERSECURITY_ROAD_BLOCK_MODAL_WATCHER",
    triggerCybersecurityRoadBlockModal,
  )
}
