import { ButtonSecondary } from "@src/Components/Button"
import { Heading, Panel } from "@src/Components/Common"
import { colors, media } from "@src/theme"
import styled from "styled-components"

interface IGoalsOptions {
  withComment?: boolean
  isSelected?: boolean
}

interface IButtonsBlock {
  isDesktop?: boolean
}

export const BackBtn = styled(ButtonSecondary)`
  display: ${(props) => (props.hidden ? "none" : "block")};
`

export const IntroImage = styled.img<any>`
  width: 108px;
  height: 108px;
  src: ${(props) => props.src};
  margin: 0 auto;
`

export const IntroListStyle = styled.ul<any>`
  text-align: initial;
  > li {
    padding: 10px 5px;
  }
`

export const IntroSubHeading = styled(Heading)`
  font-size: 28px;
  margin-top: 10px;
  text-align: ${(props) => props.align};
`

export const ContentStyle = styled.div`
  font-size: 18px;
  margin-left: 25px;
`

export const IntroArticle = styled.article`
  text-align: left;
  margin: 0 0 25px 0;

  section {
    margin: 20px 0;
    display: grid;
    grid-template-columns: 20px 1fr;

    > div {
      display: block;
      text-align: center;
      > img {
        width: 36px;
        height: 36px;
        marrgin-top: 4px;
      }
    }
  }

  article {
    margin-left: 25px;
  }
  h2 {
    font-size: 28px;
    margin: 0 0 5px 0;
  }
`

export const IntroSection = styled.section`
  margin: 25px 0 10px;
  > * {
    text-align: left;
  }
  p {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 10px;
    }
  }
  ul {
    margin: 5px;
    > li {
      padding: 5px 0;
    }
  }
`

export const IntroPanel = styled(Panel)`
  height: ${(props) => props.height};
  position: relative;
  label {
    font-size: 15px;
  }
  @media${media.medium} {
    margin-top: 70px;
    padding-bottom: 8px;
    border-radius: 0;
    box-shadow: none;
  }
`

export const IntroBody = styled.div`
  flex-grow: 1;
  margin: 0 -15px; //required to correctly display the box-shadow
  padding: 0 15px; //on the goals selection buttons
  @media${media.medium} {
    margin-top: 50px;
  }
`

export const GoalOptionContainer = styled.div<IGoalsOptions>`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 16px;
  font-size: 18px;
  color: ${colors.darkBlue};
  padding: ${({ isSelected }) => (isSelected ? "12.5px 29.5px" : "13px 30px")};
  border-style: solid;
  border-width: ${({ isSelected }) => (isSelected ? "1.5px" : "1px")};
  border-color: ${({ isSelected }) =>
    isSelected ? colors.darkBlue : colors.blue5};
  border-radius: ${({ withComment }) => (withComment ? "16px" : "30px")};
  box-shadow: ${({ isSelected }) =>
    isSelected
      ? "0px 0px 8px rgba(0, 0, 0, 0.35)"
      : "0px 0px 10px rgba(0, 0, 0, 0.25)"};
  &:hover {
    border-color: ${colors.link};
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  @media ${media.medium} {
    font-size: 16px;
    padding: ${({ isSelected }) =>
      isSelected ? "12.5px 21.5px" : "13px 22px"};
    box-shadow: ${({ isSelected }) =>
      isSelected ? "0px 0px 8px rgba(0, 0, 0, 0.35)" : "none"};
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`

export const CommentTextArea = styled.textarea`
  min-width: 100%;
  max-width: 100%;
  height: 45px;
  min-height: 45px;
  max-height: 65px;
  font-size: 16px;
  line-height: 19px;
  padding: 12px 15px;
  border-radius: 3px;
  margin-bottom: 20px;
  color: ${colors.black50};
  border: 1px solid ${colors.gray50};
  &::placeholder {
    color: ${colors.gray42};
  }
  @media ${media.medium} {
    resize: none;
    height: 69px;
    max-height: none;
    padding: 24px 13px;
    margin-bottom: 11px;
  }
`

export const ButtonsBlock = styled.div<IButtonsBlock>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: ${({ isDesktop }) => !isDesktop && "sticky"};
  bottom: 0;
  padding: 8px 0;
  background: ${colors.white};
`
