import ConfirmationModal from "@src/Components/ConfirmationModal"
import C from "@src/Controller"
import { colors } from "@src/theme"
import { TraineeData as ITrainee } from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"

import {
  Badge,
  ConfirmContent,
  ConfirmText,
  ConfirmTextBold,
  ConfirmTextGray,
  Content,
  InfoIcon,
  WarnIcon,
} from "./styles"

interface IColumnCS {
  trainee: ITrainee
}

const deleteConfirm = `All grades from unenrolled courses will be removed.`

const ColumnCS: React.FC<IColumnCS> = ({ trainee }) => {
  const [isEnrolling, setIsEnrolling] = React.useState(false)

  const { firstName, lastName, email, inactive, courseResults, traineeId } =
    trainee
  const fullName = `${firstName} ${lastName}`
  const isEnrolled = courseResults?.find((course) => course.difficulty < 5)
  const isFinished =
    courseResults?.filter(
      (course) =>
        course.difficulty > 1 &&
        course.difficulty < 5 &&
        course?.topics.filter(({ score }) => score).length === 12,
    ).length === 3

  const onConfirm = () => {
    setIsEnrolling(false)
    C.Training.editTraineeCourses([
      {
        firstName,
        lastName,
        email,
        traineeId,
        enrollInGap: true,
        enrolledCourses: [],
        unEnrolledCourses: [],
      },
    ])
  }

  return (
    <Content paused={!!inactive}>
      {isFinished ? (
        <Badge color={colors.success}>Finished</Badge>
      ) : (
        <Badge color={colors.green30}>Enrolled</Badge>
      )}
      <ConfirmationModal
        isOpen={!inactive && isEnrolling}
        title={`Update Enrollment for ${fullName}?`}
        confirmButton="Update Enrollment"
        onClose={() => setIsEnrolling(false)}
        onConfirm={onConfirm}
      >
        <ConfirmContent>
          <ConfirmText>
            {`Please confirm your training enrollment changes for ${fullName} `}
            <ConfirmTextGray>({email})</ConfirmTextGray>?
          </ConfirmText>
          <ConfirmTextBold>
            {isEnrolled ? (
              <WarnIcon className="fas fa-exclamation-triangle" />
            ) : (
              <InfoIcon className="fas fa-info-circle" />
            )}
            Courses they will be{" "}
            {isEnrolled ? "unenrolled from" : "enrolled in"}:
          </ConfirmTextBold>
          <ConfirmText>Cybersecurity Series</ConfirmText>
          {isEnrolled && (
            <ConfirmText>
              <ConfirmTextGray>{deleteConfirm}</ConfirmTextGray>
            </ConfirmText>
          )}
        </ConfirmContent>
      </ConfirmationModal>
    </Content>
  )
}

export default ColumnCS
