import { ButtonSmall } from "@src/Components/Button"
import { FormElement } from "@src/Components/Common";
import styled from "styled-components"

export const ResetPasswordButton = styled(ButtonSmall)`
  width: 100%;
  max-width: 472px;
`
export const FormElementStyled = styled(FormElement)`
  width: 100%;
  max-width: 472px;
`
