import { media } from "@src/theme"
import styled from "styled-components"

export const LevelContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`

export const CongratsImagesContainer = styled.div`
  height: 190px;
  width: 270px;
  position: relative;
  margin: 10px 20px;
  @media (max-width: 1100px) {
    margin: 0;
  }
  @media (max-width: 420px) {
    height: 220px;
    width: 200px;
  }
`

export const FirstCongratsImage = styled.img`
  left: 0;
  bottom: 0;
  position: absolute;
`

export const SecondCongratsImage = styled.img`
  right: 0;
  top: 0;
  position: absolute;
`

export const ElementIcon = styled.img`
  max-height: 100px;
  max-width: 100%;
  margin: 0 30px;
  @media (max-width: 1100px) {
    margin: 0;
  }
`

export const LevelUpActionContainer = styled.div`
  background-color: #f2f7fd;
  padding: 24px 32px 32px;
  @media ${media.medium} {
    padding: 20px 24px 24px;
  }
`

export const ActionButtonContainer = styled.div`
  flex-basis: 100px;
  white-space: nowrap;
`
