import * as React from "react"

import { PasswordChangeAuthenticate } from "./PasswordChangeAuthenticate"
import { PasswordChangePage } from "./PasswordChangePage"

export const PasswordChange = () => {
  const [cognitoUser, setCognitoUser] = React.useState({})
  const [oldPassword, setOldPassword] = React.useState("")
  const [page, setPage] = React.useState("1")
  return (
    <>
      <PasswordChangeAuthenticate
        setCognitoUser={setCognitoUser}
        page={page}
        setPage={setPage}
        setOldPassword={setOldPassword}
      />
      <PasswordChangePage
        cognitoUser={cognitoUser}
        page={page}
        setPage={setPage}
        oldPassword={oldPassword}
      />
    </>
  )
}
