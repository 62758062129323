import styled from "styled-components"

export const ConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ConfirmText = styled.div`
  font-size: 16px;
  margin: 5px 0;
`

export const ConfirmTextBold = styled.span`
  font-weight: 700;
`
