import * as React from "react"

import { CheckDiv, TickIcon } from "./styles"

interface FooterProps {
  text: string
  margin?: boolean
}

const FooterIcon = ({ margin, text }: FooterProps) => {
  return (
    <CheckDiv margin={margin}>
      <TickIcon className="fas fa-check" /> {text}
    </CheckDiv>
  )
}

export { FooterIcon }
