import { Switch } from "@blueprintjs/core"
import { colors } from "@src/theme"
import * as React from "react"
import styled from "styled-components"

const SwitchStyled = styled(Switch)`
  &.zeg-switch {
    margin: 0;

    &.bp5-switch input ~ .bp5-control-indicator,
    &.bp5-switch:hover input ~ .bp5-control-indicator,
    &.bp5-switch input:not(:disabled):active ~ .bp5-control-indicator {
      background: ${colors.gray20};

      &:hover {
        opacity: 0.8;
      }
    }

    &.bp5-switch input:checked ~ .bp5-control-indicator,
    &.bp5-switch input:checked:not(:disabled):active ~ .bp5-control-indicator {
      background: ${colors.green30};

      &:hover {
        opacity: 0.8;
      }
    }

    .bp5-control-indicator {
      &:before {
        box-shadow: none;
      }
    }
  }
`

const SwitchComp: React.FC<any> = (props) => {
  return <SwitchStyled {...props} className="zeg-switch" />
}

export default SwitchComp
