import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { initialAccountsState } from "@src/Model/Model"

export const AccountsSlice = createSlice({
  name: "account",
  initialState: initialAccountsState,
  reducers: {
    sendResetPasswordMagicLinkRequest({ sendResetPasswordMagicLink }) {
      sendResetPasswordMagicLink.isLoading = true
      sendResetPasswordMagicLink.error = undefined
    },
    sendResetPasswordMagicLinkSuccess(
      { sendResetPasswordMagicLink },
      { payload }: PayloadAction<{ email: string }>,
    ) {
      sendResetPasswordMagicLink.isLoading = false
      sendResetPasswordMagicLink.error = undefined
      sendResetPasswordMagicLink.success = true
      sendResetPasswordMagicLink.email = payload?.email
    },
    sendResetPasswordMagicLinkFailure(
      { sendResetPasswordMagicLink },
      { payload }: PayloadAction<Error>,
    ) {
      sendResetPasswordMagicLink.error = payload
      sendResetPasswordMagicLink.isLoading = false
      sendResetPasswordMagicLink.success = false
      sendResetPasswordMagicLink.email = undefined
    },
    getInviteLinkRequest({ getInviteLink }) {
      getInviteLink.isLoading = true
      getInviteLink.error = undefined
    },
    getInviteLinkSuccess(
      { getInviteLink },
      { payload }: PayloadAction<{ data: any }>,
    ) {
      getInviteLink.isLoading = false
      getInviteLink.error = undefined
      getInviteLink.success = true
      getInviteLink.data = payload?.data
    },
    getInviteLinkFailure({ getInviteLink }, { payload }: PayloadAction<Error>) {
      getInviteLink.error = payload
      getInviteLink.isLoading = false
    },
    inviteLinkSignupRequest({ inviteLinkSignup }) {
      inviteLinkSignup.isLoading = true
      inviteLinkSignup.error = undefined
    },
    inviteLinkSignupSuccess({ inviteLinkSignup }) {
      inviteLinkSignup.isLoading = false
      inviteLinkSignup.error = undefined
      inviteLinkSignup.success = true
    },
    inviteLinkSignupFailure(
      { inviteLinkSignup },
      { payload }: PayloadAction<Error>,
    ) {
      inviteLinkSignup.error = payload
      inviteLinkSignup.isLoading = false
    },
    inviteLinkLookupRequest({ inviteLinkLookup }) {
      inviteLinkLookup.isLoading = true
      inviteLinkLookup.error = undefined
    },
    inviteLinkLookupSuccess(
      { inviteLinkLookup },
      { payload }: PayloadAction<{ data: { linkId: string } }>,
    ) {
      inviteLinkLookup.isLoading = false
      inviteLinkLookup.error = undefined
      inviteLinkLookup.success = true
      inviteLinkLookup.data = payload.data
    },
    inviteLinkLookupFailure(
      { inviteLinkLookup },
      { payload }: PayloadAction<Error>,
    ) {
      inviteLinkLookup.error = payload
      inviteLinkLookup.isLoading = false
    },
    sendResetPasswordMagicLinkClear({ sendResetPasswordMagicLink }) {
      sendResetPasswordMagicLink.error = undefined
      sendResetPasswordMagicLink.isLoading = false
      sendResetPasswordMagicLink.success = false
      sendResetPasswordMagicLink.email = undefined
    },
    checkResetPasswordMagicLinkRequest({ checkResetPasswordMagicLink }) {
      checkResetPasswordMagicLink.isLoading = true
      checkResetPasswordMagicLink.error = undefined
    },
    checkResetPasswordMagicLinkSuccess(
      { checkResetPasswordMagicLink },
      { payload }: PayloadAction<{ isValidLink: boolean }>,
    ) {
      checkResetPasswordMagicLink.isLoading = false
      checkResetPasswordMagicLink.success = true
      checkResetPasswordMagicLink.error = undefined
      checkResetPasswordMagicLink.isValidLink = payload?.isValidLink
    },
    checkResetPasswordMagicLinkFailure(
      { checkResetPasswordMagicLink },
      { payload }: PayloadAction<Error>,
    ) {
      checkResetPasswordMagicLink.error = payload
      checkResetPasswordMagicLink.success = false
      checkResetPasswordMagicLink.isLoading = false
      checkResetPasswordMagicLink.isValidLink = false
    },
    checkResetPasswordMagicLinkClear({ checkResetPasswordMagicLink }) {
      checkResetPasswordMagicLink.error = undefined
      checkResetPasswordMagicLink.success = undefined
      checkResetPasswordMagicLink.isLoading = false
      checkResetPasswordMagicLink.isValidLink = undefined
    },
    resetPasswordRequest({ resetPassword }) {
      resetPassword.isLoading = true
      resetPassword.error = undefined
    },
    resetPasswordSuccess({ resetPassword }) {
      resetPassword.isLoading = false
      resetPassword.error = undefined
      resetPassword.success = true
    },
    resetPasswordFailure({ resetPassword }, { payload }: PayloadAction<Error>) {
      resetPassword.error = payload
      resetPassword.isLoading = false
      resetPassword.success = false
    },
    resetPasswordClear({ resetPassword }) {
      resetPassword.error = undefined
      resetPassword.success = undefined
      resetPassword.isLoading = false
    },
  },
})

export const actions = AccountsSlice.actions

export default AccountsSlice.reducer
