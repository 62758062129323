export const FREQUENCY_OPTIONS = [
  { value: "q", label: "Quarterly" },
  { value: "m", label: "Monthly" },
  { value: "w", label: "Weekly" },
  { value: "d", label: "Daily" },
]

export const FREQUENCY_BASED_TEXT: Record<string, string> = {
  q: "three months",
  m: "month",
  w: "",
  d: "day",
}

export const SCHEDULED_DAYS_OF_WEEK: { value: number; label: string }[] = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 7, label: "Sunday" },
]

export const WEEK_INDEXES: { value: string; label: string }[] = [
  { value: "first", label: "First" },
  { value: "second", label: "Second" },
  { value: "third", label: "Third" },
  { value: "fourth", label: "Fourth" },
  { value: "last", label: "Last" },
]

export const ADD_TARGET_STEPS: string[] = [
  "Specify Website",
  "Confirm URL",
  "Schedule Scan",
]

export const WEEK_INDEXES_TO_TEXT: Record<number, string> = {
  0: "first",
  1: "second",
  2: "third",
  3: "fourth",
  4: "last",
}

export const TEXT_TO_WEEK_INDEX: Record<string, number> = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  last: 5,
}

export const FREQUENCY_ITEMS: string[][] = [
  ["Once a day", "d", "DAILY"],
  ["Once a week", "w", "WEEKLY"],
  ["Once a month", "m", "MONTHLY"],
  ["Once a quarter", "q", "QUARTERLY"],
]

export const SCAN_LEVELS: string[][] = [
  ["Safe", "safe"],
  ["Lightning", "lightning"],
  ["Normal", "normal"],
  ["Full", "full"],
  ["Log4j Only", "log4shell"],
  ["Sandbox Lightning", "sandbox_lightning"],
  ["Sandbox Normal", "sandbox_normal"],
]
