import ZegPanel from "@src/Components/Common/ZegPanel"
import { ContentArea } from "@src/Components/View"
import { IFastRatingsData, tCompanyProfile, tRatingsTabs } from "@src/types"
import * as React from "react"

import { NO_COMPANY_URL_DATA } from "./constants"
import TabDetails from "./TabDetails"
import TabOverview from "./TabOverview"
import TabReports from "./TabReports"
import ZegHeader from "./ZegHeader"

interface IProps {
  data: IFastRatingsData
  companyProfile: tCompanyProfile
  selectedTab: tRatingsTabs
  setSelectedTab: (key: tRatingsTabs) => void
}

export const TabsContext = React.createContext({})

const Ratings: React.FC<IProps> = ({
  data,
  companyProfile,
  selectedTab,
  setSelectedTab,
}) => {
  const [scrollTo, setScrollTo] = React.useState<string>("")

  const ratingDetails = {
    ...NO_COMPANY_URL_DATA,
    ...((companyProfile?.companyUrl && data?.rating_details) || {}),
  }

  return (
    <ContentArea>
      <ZegHeader />
      <ZegPanel
        tabs={[
          { id: "overview", text: "Overview" },
          { id: "details", text: "Individual Analysis" },
          { id: "reports", text: "Reports" },
        ]}
        onTabChange={setSelectedTab}
        selectedTabId={selectedTab}
      >
        <TabsContext.Provider
          value={{ scrollTo, setScrollTo, selectedTab, setSelectedTab }}
        >
          {selectedTab === "overview" && (
            <TabOverview
              data={data}
              ratingDetails={ratingDetails}
              companyUrl={companyProfile?.companyUrl}
            />
          )}
          {selectedTab === "details" && (
            <TabDetails
              data={data}
              ratingDetails={ratingDetails}
              companyProfile={companyProfile}
            />
          )}
          {selectedTab === "reports" && (
            <TabReports companyProfile={companyProfile} />
          )}
        </TabsContext.Provider>
      </ZegPanel>
    </ContentArea>
  )
}

export default Ratings
