import { Spinner } from "@blueprintjs/core"
import { tState } from "@src/Model/Model"
import { getInviteLinkWatcher, inviteLinkSignupWatcher } from "@src/Sagas"
import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import C from "../../../Controller"
import MagicLinkSignUp from "./MagicLinkSignUp"

interface IInviteLinkNavParams {
  linkId: string
}

const OrgUserMagicLinkInviteSignup = () => {
  const app = document.getElementById("portal")
  const dispatch = useDispatch()
  const history = useHistory()
  const { linkId }: IInviteLinkNavParams = useParams()
  const recaptchaRef = React.createRef<ReCAPTCHA>()
  const [isLoadingCaptcha, setIsLoadingCaptcha] = React.useState(false)

  const { authenticated } = useSelector((store: tState) => store.portal)
  const { isLoading: getInviteLinkLoading, data } = useSelector(
    (store: tState) => store.account.getInviteLink,
  )
  const { isLoading: signupLoading } = useSelector(
    (store: tState) => store.account.inviteLinkSignup,
  )
  const onMagicLinkSignUp = (password: string) => {
    C.heapIdentify(data.createdFor?.email || "")
    C.partnerStackIdentify(data.createdFor?.email || "", () =>
      dispatch(inviteLinkSignupWatcher({ linkId, password })),
    )
  }

  const executeCaptcha = () => {
    setIsLoadingCaptcha(true)
    const current: any = recaptchaRef.current
    current.execute()
  }

  const resetCaptcha = () => {
    const current: any = recaptchaRef.current
    current.reset()
  }

  const verifyToken = (token: any, callback: any) => {
    C.Recaptcha.verifyToken(token, callback)
  }

  React.useEffect(() => {
    C.initEnvironment()
    dispatch(getInviteLinkWatcher(linkId))
    C.Recaptcha.loadScriptv2() //Load Recaptcha v2
    C.Portal.checkSession("SignUpContainer")
  }, [])
  React.useEffect(() => {
    if (authenticated) {
      history.push("/")
    }
  }, [authenticated])

  if (authenticated === null || getInviteLinkLoading) {
    return (
      <div style={{ height: "100%", paddingTop: 200, textAlign: "center" }}>
        <Spinner size={25} /> Loading Cyber Safety Portal...
      </div>
    )
  }

  return (
    <MagicLinkSignUp
      data={data}
      height={app?.clientHeight || 0}
      onMagicLinkSignUp={onMagicLinkSignUp}
      recaptchaRef={recaptchaRef}
      executeCaptcha={executeCaptcha}
      verifyToken={verifyToken}
      resetCaptcha={resetCaptcha}
      setIsLoadingCaptcha={setIsLoadingCaptcha}
      isLoading={isLoadingCaptcha || signupLoading}
    />
  )
}

export default OrgUserMagicLinkInviteSignup
