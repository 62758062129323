import { colors } from "@src/theme"
import styled from "styled-components"

interface ICheckboxStyled {
  disabled?: boolean
}

export const CheckboxStyled = styled.label<ICheckboxStyled>`
  font-size: 16px;
  color: ${({ disabled }) => disabled && colors.gray30};
  input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`
