import { colors } from "@src/theme"
import styled from "styled-components"

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
`

export const HeadTitle = styled.h1`
  font-family: montserrat;
  font-size: 18px;
  color: ${colors.darkBlue};
  margin: 0;
`
