import { colors } from "@src/theme"
import styled from "styled-components"

export const StartAssesmentPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 36px;
  background: ${colors.lightBlue2};
  border: 1px solid rgba(0, 98, 217, 0.6);
  box-sizing: border-box;
  border-radius: 6px;
`

export const Title = styled.div`
  margin-top: 32px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #26249e;
  letter-spacing: 0.015em;
  font-family: montserrat;
`

export const SubTitle = styled.div`
  font-family: proxima-nova, sans-serif;
  margin-top: 16px;
  font-size: 16px;
  margin-bottom: 32px;
`
