import { actions as ACCOUNTS } from "@src/Actions/Reducers/AccountsReducer"
import history from "@src/browserHistory"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import {
  ICheckResetPasswordMagicLink,
  IGetInviteLink,
  IInviteLinkLookup,
  IInviteLinkSignUp,
  IResetPassword,
  ISendResetPasswordMagicLinkData,
} from "@src/Sagas/types"
import axios from "axios"
import { put, select, takeLeading } from "redux-saga/effects"

function* sendResetPasswordMagicLink(
  action: ISendResetPasswordMagicLinkData,
): any {
  const { emailDomain } = yield select((state: tState) => state.brand)
  try {
    yield put(ACCOUNTS.sendResetPasswordMagicLinkRequest())
    yield axios.post(
      `api/accounts/sendResetPasswordMagicLink`,
      JSON.stringify({
        ...action.payload,
        url: window.location.hostname,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    yield put(
      ACCOUNTS.sendResetPasswordMagicLinkSuccess({
        email: action.payload.email,
      }),
    )
  } catch (e) {
    C.toastNotification(
      `Something went wrong while verifying email. Try again, or email support@${emailDomain} for help.`,
      "danger",
    )
    yield put(ACCOUNTS.sendResetPasswordMagicLinkFailure(e))
  }
}

function* checkResetPasswordMagicLink(
  action: ICheckResetPasswordMagicLink,
): any {
  const { emailDomain } = yield select((state: tState) => state.brand)
  try {
    yield put(ACCOUNTS.checkResetPasswordMagicLinkRequest())
    const { data } = yield axios.post(
      `api/accounts/checkResetPasswordMagicLink`,
      JSON.stringify({
        ...action.payload,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    if (data.success) {
      const isValidLink = data?.isValidLink
      if (!isValidLink) {
        history.push("/signin")
        C.toastNotification(
          `The link is invalid. Request another link, or email support@${emailDomain} for help.`,
          "danger",
        )
      }
      yield put(ACCOUNTS.checkResetPasswordMagicLinkSuccess({ isValidLink }))
    } else {
      throw Error("Unable to verify link")
    }
  } catch (e) {
    history.push("/signin")
    C.toastNotification(
      `Something went wrong while verifying the link. Try again, or email support@${emailDomain} for help.`,
      "danger",
    )
    yield put(ACCOUNTS.checkResetPasswordMagicLinkFailure(e))
  }
}

function* resetPassword(action: IResetPassword): any {
  const { emailDomain } = yield select((state: tState) => state.brand)
  try {
    yield put(ACCOUNTS.resetPasswordRequest())
    const { data } = yield axios.post(
      `api/accounts/resetPassword`,
      JSON.stringify({
        ...action.payload,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    if (data.success) {
      yield put(ACCOUNTS.resetPasswordSuccess())
      history.push(
        action?.payload?.subscriptionId
          ? `/signin/${action?.payload?.subscriptionId}`
          : "/signin",
      )
      C.toastNotification(
        "Your password was successfully reset. You will be redirected to the sign in page.",
        "success",
      )
    } else {
      throw Error("Unable to reset password")
    }
  } catch (e) {
    yield put(ACCOUNTS.resetPasswordFailure(e))
    C.toastNotification(
      `Something went wrong while resetting password. Try again, or email support@${emailDomain} for help.`,
      "danger",
    )
  }
}

function* getInviteLink(action: IGetInviteLink) {
  try {
    yield put(ACCOUNTS.getInviteLinkRequest())
    const { data } = yield axios.get(
      `api/accounts/inviteLink?linkId=${action.payload}`,
    )
    if (data?.success) {
      yield put(ACCOUNTS.getInviteLinkSuccess(data))
      if (data.data.status === "used") {
        history.push("/signin")
        C.toastNotification(
          `You’ve already created an account (${data.data.createdFor.email}). Please sign in.`,
          "warning",
        )
      }
    } else {
      history.push("/link-error-page")
      throw Error("Unable to get invite link")
    }
  } catch (e) {
    yield put(ACCOUNTS.getInviteLinkFailure(e))
  }
}

function* inviteLinkSignup(action: IInviteLinkSignUp) {
  try {
    yield put(ACCOUNTS.inviteLinkSignupRequest())
    const { data } = yield axios.post(`api/accounts/inviteLink`, action.payload)
    if (data.success) {
      yield put(ACCOUNTS.inviteLinkSignupSuccess(data))
      history.push("/verify", {
        email: data.data.email,
        subscriptionId: data.data.subscriptionId,
      })
    } else {
      throw Error("Unable to signup with invite link")
    }
  } catch (e) {
    yield put(ACCOUNTS.inviteLinkSignupFailure(e))
  }
}

function* inviteLinkLookup(action: IInviteLinkLookup) {
  const { emailDomain } = yield select((state: tState) => state.brand)
  try {
    yield put(ACCOUNTS.inviteLinkLookupRequest())
    const { data } = yield axios.get(
      `api/accounts/inviteLinkLookup?email=${action.payload}`,
    )
    if (data.success) {
      yield put(ACCOUNTS.inviteLinkLookupSuccess(data))
    } else {
      throw Error("Unable to search for user invite link")
    }
  } catch (e) {
    C.toastNotification(
      `Something went wrong while checking the provided data. Try again, or email support@${emailDomain} for help.`,
      "danger",
    )
    yield put(ACCOUNTS.inviteLinkLookupFailure(e))
  }
}

export function* accountsWatcherSaga() {
  yield takeLeading(
    "SEND_RESET_PASSWORD_MAGIC_LINK_WATCHER",
    sendResetPasswordMagicLink,
  )
  yield takeLeading(
    "CHECK_RESET_PASSWORD_MAGIC_LINK_WATCHER",
    checkResetPasswordMagicLink,
  )
  yield takeLeading("RESET_PASSWORD_WATCHER", resetPassword)
  yield takeLeading("GET_INVITE_LINK_WATCHER", getInviteLink)
  yield takeLeading("INVITE_LINK_SIGNUP_WATCHER", inviteLinkSignup)
  yield takeLeading("INVITE_LINK_LOOKUP_WATCHER", inviteLinkLookup)
}
