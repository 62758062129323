import { colors } from "@src/theme"
import styled from "styled-components"

interface IPausedProps {
  paused: boolean
}

export const Content = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  gap: 6px;
`

export const NameStyled = styled.span<IPausedProps>`
  font-size: 16px;
  color: ${colors.link};
  cursor: pointer;
  opacity: ${({ paused }) => (paused ? 0.7 : 1)};
  white-space: nowrap;
  max-width: 100%;

  &:hover {
    color: ${colors.violet};
    opacity: 0.8;
  }
`

export const EmailStyled = styled.span<IPausedProps>`
  font-size: 16px;
  color: ${colors.gray40};
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${({ paused }) => (paused ? 0.7 : 1)};
  max-width: 100%;
`

export const Menu = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  width: 25px;
  cursor: pointer;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }
`

export const ConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ConfirmText = styled.div`
  font-size: 16px;
  margin: 5px 0;
`

export const ConfirmTextBold = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
`

export const ConfirmTextGray = styled.span`
  font-size: 16px;
  color: ${colors.gray40};
`

export const WarnIcon = styled.i`
  font-size: 16px;
  color: ${colors.error};
  margin-right: 5px;
`

export const Badge = styled.span`
  font-size: 13px;
  line-height: 24px;
  color: ${colors.white};
  background: ${colors.black50};
  padding: 0 8px;
  border-radius: 3px;
  white-space: nowrap;

  position: absolute;
  top: 8px;
  right: 24px;
  opacity: 0.5;
`
