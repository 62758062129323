import { colors } from "@src/theme"
import styled from "styled-components"

interface IContentProps {
  paused: boolean
  noCourses?: boolean
}

export const Content = styled.div<IContentProps>`
  width: 100%;
  display: flex;
  align-items: center;
  opacity: ${({ paused }) => (paused ? 0.5 : 1)};
  pointer-events: ${({ paused }) => paused && "none"};
`

export const ConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ConfirmText = styled.div`
  font-size: 16px;
  margin: 5px 0;
`

export const ConfirmTextBold = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
`

export const ConfirmTextGray = styled.span`
  font-size: 16px;
  color: ${colors.gray40};
`

export const WarnIcon = styled.i`
  font-size: 16px;
  color: ${colors.error};
  margin-right: 5px;
`

export const InfoIcon = styled.i`
  font-size: 16px;
  color: ${colors.success};
  margin-right: 5px;
`

export const EditIcon = styled.i`
  font-size: 16px;
  color: ${colors.link};
  position: absolute;
  right: 12px;
  top: 16px;
  cursor: pointer;
`
