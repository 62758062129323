import { actions as GLOBAL_MODALS } from "@src/Actions/Reducers/GlobalModalsReducer"
import {
  createScheduleScanWatcher,
  createTargetWatcher,
  deleteTargetWatcher,
  editScheduleScanWatcher,
  editTargetWatcher,
  getScanMapWatcher,
  getScheduleMapWatcher,
  getTargetListWatcher,
  syncScansWatcher,
  updateScheduleScanWatcher,
} from "@src/Sagas"
import {
  ProbelyCreateTargetRequest,
  ProbelyEditScheduledScanRequest,
  ProbelyEditTargetRequest,
  ProbelyScheduleScanRequest,
} from "@zeguro/schema-validator/dist/types/coco/probely/combined"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"

import Waiting from "../../Components/Waiting"
import C from "../../Controller"
import { tState } from "../../Model/Model"
import * as T from "../../types"
import { ViewTypes } from "../../types"
import Monitoring from "./Monitoring"

const MonitoringContainer = () => {
  const { params } = useRouteMatch<{ id?: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const { getTargetList, getScanMap, getScheduleMap } = useSelector(
    (state: tState) => state.monitoring,
  )
  const probelyNumberOfTargets = useSelector(
    (state: tState) => state.plans.probelyNumberOfTargets,
  )
  const probelyScanProfile = useSelector(
    (state: tState) => state.plans.probelyScanProfile,
  )
  const isHighestPlan = useSelector(
    (state: tState) => state.plans.isHighestPlan,
  )

  const companyName = useSelector(
    (state: tState) => state.portal.companyProfile.companyName,
  )

  const getReportName = (targetName: string, reportType: string) => {
    const currentDate = new Date()
    const currentMonthFormatted = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")
    const currentDayFormatted = currentDate
      .getDate()
      .toString()
      .padStart(2, "0")
    return `${
      companyName.split(" ")[0]
    }_${targetName}_${reportType}_${currentDate.getFullYear()}_${currentMonthFormatted}_${currentDayFormatted}`
  }

  const updateActiveTarget = () => {
    dispatch(getScanMapWatcher())
    dispatch(getScheduleMapWatcher())
  }

  const syncScanAndSchedule = (targetId: string) => {
    dispatch(syncScansWatcher({ targetId }))
    dispatch(updateScheduleScanWatcher({ targetId }))
  }

  const addWebApp = (payload: ProbelyCreateTargetRequest) => {
    dispatch(createTargetWatcher(payload))
  }
  const editSchedule = (payload: ProbelyEditScheduledScanRequest) => {
    dispatch(editScheduleScanWatcher(payload))
  }
  const editTarget = (payload: ProbelyEditTargetRequest) => {
    dispatch(editTargetWatcher(payload))
  }
  const createSchedule = (payload: ProbelyScheduleScanRequest) => {
    dispatch(createScheduleScanWatcher(payload))
  }
  const showUpgradeButton = Boolean(
    probelyNumberOfTargets &&
      probelyNumberOfTargets.remaining <= 0 &&
      !isHighestPlan,
  )

  React.useEffect(() => {
    dispatch(getTargetListWatcher())
    dispatch(getScheduleMapWatcher())
  }, [])
  React.useEffect(() => {
    if (params?.id && getTargetList.data.length) {
      const findScan = getTargetList.data.find((s) => s.name === params?.id)
      !findScan && history.replace(ViewTypes.monitoring)
    }
  }, [params?.id, getTargetList.data.length])

  if (getTargetList.isLoading && getScheduleMap.isLoading) {
    return <Waiting text="Loading your Website scans." />
  }

  return (
    <Monitoring
      addWebApp={addWebApp}
      editTarget={editTarget}
      deleteTarget={(targetId, targetName) =>
        dispatch(deleteTargetWatcher({ targetId, targetName }))
      }
      getScanReport={(targetName, targetId, scanId, reportType) => {
        C.Monitoring.getScanReport(
          getReportName(targetName, reportType),
          targetId,
          scanId,
          reportType,
        )
      }}
      editSchedule={editSchedule}
      createSchedule={createSchedule}
      showSubscriptionPage={() => history.push(T.ViewTypes.plan)}
      numberOfTargets={probelyNumberOfTargets}
      targetList={getTargetList.data}
      scanMap={getScanMap.data}
      scheduleMap={getScheduleMap.data}
      scansLoaded={getScanMap.isLoading}
      updateActiveTarget={updateActiveTarget}
      syncScanAndSchedule={syncScanAndSchedule}
      onIntro={() => {
        dispatch(GLOBAL_MODALS.changeIntroModal("monitoring"))
      }}
      showUpgradeButton={showUpgradeButton}
      scanProfile={probelyScanProfile}
      isHighestPlan={isHighestPlan}
    />
  )
}

export default MonitoringContainer
