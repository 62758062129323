import { DateInput3 } from "@blueprintjs/datetime2"
import useDebounce from "@src/Components/Common/useDebounce"
import { Select } from "@src/Components/Form/Select"
import TextField from "@src/Components/Form/TextField"
import { Section } from "@src/Components/StyledUtils"
import {
  FREQUENCY_BASED_TEXT,
  SCHEDULED_DAYS_OF_WEEK,
  TEXT_TO_WEEK_INDEX,
  WEEK_INDEXES,
  WEEK_INDEXES_TO_TEXT,
} from "@src/Portal/Monitoring/constants"
import { IEditScheduleFormFields } from "@src/Portal/Monitoring/EditScheduleForm"
import {
  getAllDaysInMonth,
  getNumberEnding,
} from "@src/Portal/Monitoring/helpers"
import { addWeeks, getDay, nextDay, setDay, startOfMonth } from "date-fns"
import { FormikProps } from "formik"
import * as moment from "moment"
import * as React from "react"

import { IAddWebAppFormFields } from "../.."
import SetTimeTooltip from "./SetTimeTooltip"
import { InfoPanel, RepeatScanText, StyledRadio } from "./styles"

interface IFrequencyPanel {
  formik: FormikProps<IAddWebAppFormFields | IEditScheduleFormFields>
  isAddNew?: boolean
  isNewSchedule?: boolean
}

const getWeekIndex = (index?: string | null) =>
  WEEK_INDEXES.find((weekIndex) => weekIndex.value === index)?.label
const getDayOfWeek = (index?: number | null) =>
  SCHEDULED_DAYS_OF_WEEK.find(
    (scheduledDayOfWeek) => scheduledDayOfWeek.value === index,
  )?.label

const FrequencyPanel = ({
  formik,
  isAddNew,
  isNewSchedule,
}: IFrequencyPanel) => {
  const maxScheduleDate = new Date()
  maxScheduleDate.setFullYear(new Date().getFullYear() + 5)
  maxScheduleDate.setMonth(11)
  maxScheduleDate.setDate(31)
  const recurrence = formik.values.recurrence
  let setWeekIndexCount = 0
  const debouncedDayOfMonth = useDebounce<number>(
    formik.values.dayOfMonth,
    1000,
  )
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  const scheduleDate = formik.values.scheduleDate
  const scheduleYear = scheduleDate.getFullYear()
  const scheduleMonth = scheduleDate.getMonth()
  const currDay = new Date().getDate()
  const lastDayOfScheduleMonth = new Date(
    scheduleYear,
    scheduleMonth + 1,
    0,
  ).getDate()

  const handleDayOfMonthChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(value) || 0
    const day = Math.floor(
      val > lastDayOfScheduleMonth ? lastDayOfScheduleMonth : val,
    ).toString()
    formik.setFieldValue("dayOfMonth", day)
    formik.setFieldValue("runOnDayOfWeek", false)
  }

  const getInfo = (
    <>
      <i className="fas fa-info-circle" />
      &nbsp;Subsequent scans will run&nbsp;
      <b>every&nbsp;{FREQUENCY_BASED_TEXT[recurrence]}</b>
      {recurrence === "w" && (
        <>
          <b>{getDayOfWeek(formik.values.scheduledDayOfWeek)}</b>
        </>
      )}
      {["q", "m"].includes(recurrence) && (
        <>
          &nbsp;on the&nbsp;
          <b>
            {formik.values.runOnDayOfWeek
              ? getWeekIndex(formik.values.weekIndex) +
                " " +
                getDayOfWeek(formik.values.scheduledDayOfWeek)
              : formik.values.dayOfMonth +
                getNumberEnding(formik.values.dayOfMonth)}
          </b>
        </>
      )}
      &nbsp;at&nbsp;<b>{moment(formik.values.scheduleTime).format("h:mm A")}</b>
    </>
  )

  const setScheduleDate = (date: Date, isInitialSet?: boolean) => {
    const dayIndex = date.getDay() === 0 ? 7 : date.getDay()
    formik.setFieldValue("scheduleDate", date)
    if (!formik.values.runOnDayOfWeek && !isInitialSet) {
      formik.setFieldValue("dayOfMonth", date.getDate() || 0)
      if (formik.values.recurrence === "w") {
        formik.setFieldValue("scheduledDayOfWeek", dayIndex)
      }
      return
    }
    if (formik.values.runOnDayOfWeek || formik.values.recurrence !== "d") {
      const allDays = getAllDaysInMonth(date.getDay(), date)
      const findCurrentWeek =
        allDays?.find((dayDate) => {
          return dayDate === date.getDate()
        }) || 0
      const weekIndex = allDays.indexOf(findCurrentWeek)
      formik.setFieldValue("weekIndex", WEEK_INDEXES_TO_TEXT[weekIndex])
      formik.setFieldValue("scheduledDayOfWeek", dayIndex)
    }
  }

  const setScheduledDayOfWeek = (name: string, dayIndex: number) => {
    formik.setFieldValue(name, dayIndex)
    const weekIndex = formik.values.weekIndex
    const allDays = getAllDaysInMonth(
      dayIndex === 7 ? 0 : dayIndex,
      formik.values.scheduleDate,
    )
    const weekIndexResult =
      formik.values.weekIndex === "last" && allDays.length !== 5
        ? "fourth"
        : weekIndex
    setWeekIndex("weekIndex", weekIndexResult || "first", dayIndex)
  }

  const setWeekIndex = (
    name: string,
    week: string,
    dayIndex?: number,
    date?: Date,
  ) => {
    const scheduledDayOfWeek = dayIndex || formik.values.scheduledDayOfWeek || 1
    const scheduleDate = date || new Date(formik.values.scheduleDate)
    const weekDayIndex = (
      scheduledDayOfWeek === 7 ? 0 : scheduledDayOfWeek
    ) as Day
    const monthStartDate = startOfMonth(scheduleDate)
    const allDays = getAllDaysInMonth(weekDayIndex, scheduleDate)
    let weekIndex = TEXT_TO_WEEK_INDEX[week]
    let firstDayOfWeek = setDay(monthStartDate, weekDayIndex, {
      weekStartsOn: getDay(monthStartDate),
    })
    let currentWeekDate: Date = addWeeks(firstDayOfWeek, weekIndex - 1)
    formik.setFieldValue(name, week)
    if (formik.values.recurrence === "w") {
      if (
        nextDay(scheduleDate, weekDayIndex).getDay() !== scheduleDate.getDay()
      ) {
        formik.setFieldValue(
          "scheduleDate",
          nextDay(scheduleDate, weekDayIndex),
        )
      }
      return
    }
    if (weekIndex === 5 && allDays.length === 4) {
      weekIndex = 4
      currentWeekDate = addWeeks(firstDayOfWeek, weekIndex - 1)
    }
    if (currentWeekDate.getMonth() < scheduleDate.getMonth()) {
      currentWeekDate = addWeeks(firstDayOfWeek, weekIndex)
    }
    if (
      currentWeekDate.getDate() < new Date().getDate() &&
      currentWeekDate.getMonth() === new Date().getMonth() &&
      setWeekIndexCount === 0
    ) {
      setWeekIndexCount = 1
      setWeekIndex(
        "weekIndex",
        week || "first",
        undefined,
        new Date(currentWeekDate.setMonth(currentWeekDate.getMonth() + 1)),
      )
      return
    }
    if (
      currentWeekDate.getMonth() > scheduleDate.getMonth() &&
      addWeeks(firstDayOfWeek, weekIndex - 2).getDate() >
        addWeeks(firstDayOfWeek, weekIndex - 1).getDate() &&
      !setWeekIndexCount
    ) {
      firstDayOfWeek = setDay(
        startOfMonth(currentWeekDate),
        Number(weekDayIndex),
        {
          weekStartsOn: getDay(startOfMonth(currentWeekDate)),
        },
      )
      currentWeekDate = addWeeks(firstDayOfWeek, weekIndex - 2)
    }
    formik.setFieldValue("scheduleDate", currentWeekDate)
  }
  const setTime = (date: Date) => {
    const newDate = new Date(date)
    const scheduleDate = new Date(formik.values.scheduleDate)
    scheduleDate.setHours(newDate.getHours())
    scheduleDate.setMinutes(newDate.getMinutes())
    scheduleDate.setSeconds(newDate.getSeconds())
    formik.setFieldValue("scheduleTime", date)
    formik.setFieldValue("scheduleDate", scheduleDate)
  }

  React.useEffect(() => {
    if (debouncedDayOfMonth > 0) {
      debouncedDayOfMonth < currDay &&
        currentMonth === scheduleMonth &&
        currentYear === scheduleYear &&
        formik.setFieldValue(
          "scheduleDate",
          new Date(formik.values.scheduleDate.setMonth(currentMonth + 1)),
        )
      formik.setFieldValue(
        "scheduleDate",
        new Date(formik.values.scheduleDate.setDate(debouncedDayOfMonth)),
      )
    }
  }, [debouncedDayOfMonth])

  React.useEffect(() => {
    //set initial values for week and day selectors
    if (isAddNew) {
      setScheduleDate(formik.values.scheduleDate, true)
    }
  }, [])

  return (
    <>
      <Section fontSize="16px" fontWeight="700" margin="0 0 12 0">
        {isAddNew || isNewSchedule ? "Start first scan on" : "Next Scan On"}
      </Section>
      <Section flex="flex-start center" margin="0 0 32 0">
        <Section w="120px">
          <DateInput3
            formatDate={(date) =>
              date
                ? date.toLocaleString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                : ""
            }
            onChange={(scheduleDate) =>
              scheduleDate && setScheduleDate(new Date(scheduleDate))
            }
            parseDate={(str) => new Date(str)}
            placeholder="M/D/YYYY"
            value={formik.values.scheduleDate.toISOString()}
            canClearSelection={false}
            minDate={new Date()}
            maxDate={maxScheduleDate}
          />
        </Section>
        <Section lineHeight="36px" margin="0 12">
          at
        </Section>
        <SetTimeTooltip formik={formik} setTime={setTime} />
      </Section>
      <Section fontSize="16px" fontWeight="700" margin="0 0 12 0">
        Repeat scan on
      </Section>
      <Section
        hidden={!["q", "m"].includes(recurrence)}
        flex="flex-start center"
        flexWrap="wrap"
        margin="0 0 18 0"
      >
        <StyledRadio
          checked={!formik.values.runOnDayOfWeek}
          onChange={() =>
            formik.setFieldValue(
              "runOnDayOfWeek",
              !formik.values.runOnDayOfWeek,
            )
          }
        />
        The&nbsp;&nbsp;
        <Section flex="flex-start center" w="46px" margin="0 2 0 0">
          <TextField
            type="number"
            padding="10px 12px"
            align="end"
            value={formik.values.dayOfMonth}
            name="dayOfMonth"
            onChange={handleDayOfMonthChange}
            selectOnFocus
            onBlur={formik.handleBlur}
            error={!!formik.touched.dayOfMonth && !!formik.errors.dayOfMonth}
            errorMsg={formik.errors.dayOfMonth}
          />
        </Section>
        &nbsp;{getNumberEnding(formik.values.dayOfMonth)},
        <RepeatScanText>
          &nbsp;every&nbsp;
          {recurrence === "q" ? "three months" : "month"}&nbsp;at&nbsp;
          <SetTimeTooltip formik={formik} setTime={setTime} />
        </RepeatScanText>
      </Section>
      <Section
        hidden={!["q", "m", "w"].includes(recurrence)}
        flex="flex-start center"
        flexWrap="wrap"
        margin="0 0 18 0"
      >
        {["q", "m"].includes(recurrence) && (
          <StyledRadio
            checked={formik.values.runOnDayOfWeek}
            onChange={() =>
              formik.setFieldValue(
                "runOnDayOfWeek",
                !formik.values.runOnDayOfWeek,
              )
            }
          />
        )}
        {["q", "m"].includes(recurrence) ? "The" : "Every"}&nbsp;&nbsp;
        {["q", "m"].includes(recurrence) && (
          <Section w="125px">
            <Select
              label=""
              name="weekIndex"
              placeholder=""
              options={WEEK_INDEXES}
              onChange={(name, week: string) => {
                setWeekIndexCount = 0
                setWeekIndex(name, week)
                formik.setFieldValue("runOnDayOfWeek", true)
              }}
              value={WEEK_INDEXES.find(
                (weekIndex) => weekIndex.value === formik.values.weekIndex,
              )}
              margin="6px 14px 6px 0"
              isSearchable={false}
            />
          </Section>
        )}
        <Section w="150px">
          <Select
            label=""
            name="scheduledDayOfWeek"
            placeholder=""
            options={SCHEDULED_DAYS_OF_WEEK}
            onChange={(name, dayIndex) => {
              setScheduledDayOfWeek(name, Number(dayIndex))
              formik.setFieldValue("runOnDayOfWeek", true)
            }}
            value={SCHEDULED_DAYS_OF_WEEK.find(
              (scheduledDayOfWeek) =>
                scheduledDayOfWeek.value === formik.values.scheduledDayOfWeek,
            )}
            margin="6px 8px 6px 0"
            isSearchable={false}
          />
        </Section>
        {recurrence !== "w" && "every "}
        {FREQUENCY_BASED_TEXT[recurrence]}
        &nbsp;at&nbsp;
        <SetTimeTooltip formik={formik} setTime={setTime} />
      </Section>
      <InfoPanel>{getInfo}</InfoPanel>
    </>
  )
}

export default FrequencyPanel
