import * as React from "react"

import { Description, SubHeading } from "../../styles"

export const EmptyStateCard = () => (
  <>
    <SubHeading>Find and fix security vulnerabilities.</SubHeading>
    <Description>
      Scan your websites to identify threats. Proactively address issues that
      can be exploited by cyber criminals.
    </Description>
  </>
)
