import { colors } from "@src/theme"
import styled from "styled-components"

export const StartPageContainer = styled.div`
  background: rgba(0, 98, 217, 0.05);
  border: 2px solid rgba(0, 98, 217, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 37px 0;
`

export const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  line-height: 44px;
  color: ${colors.darkBlue};
  text-align: center;
  padding: 18px 18px;
  font-family: montserrat;
  @media (max-width: 500px) {
    font-size: 24px;
    line-height: initial;
  }
`

export const Subtitle = styled.h3`
  margin: 0;
  line-height: 32px;
  font-size: 21px;
`

export const List = styled.ul`
  margin: 0 0 20px 0;
  padding-left: 15px;
`

export const Image = styled.img`
  width: 146px;
`
