import { ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import {
  HeadingStatus,
  HeadingWrapper,
} from "@src/Portal/Zimm/HeaderCard/styles"
import { ViewTypes } from "@src/types"
import * as React from "react"
import { useHistory } from "react-router-dom"

import { StartAssesmentPanel, SubTitle, Title } from "./styles"

interface IEmptyState {
  assesmentItem?: string
}
export const EmptyState = ({ assesmentItem }: IEmptyState) => {
  const history = useHistory()
  const { isDesktop } = usePortalSize()

  return (
    <>
      {!isDesktop && (
        <HeadingWrapper margin="0 0 32px 0">
          <img
            height="44px"
            src="images/zimm/icon_cyber_maturity_lg.svg"
            alt="head"
          />
          Cyber Maturity<HeadingStatus>Beta</HeadingStatus>
        </HeadingWrapper>
      )}
      <StartAssesmentPanel>
        <img src="images/zimm/illo_start_questionnaire.svg" />
        <Title>Start Your Cyber Maturity Assessment</Title>
        <SubTitle>Discover Your Organization’s Security Maturity</SubTitle>
        <ButtonSmall
          margin="0 0 10"
          width={180}
          onClick={() =>
            history.push(`${ViewTypes.zimm}/assessment/${assesmentItem}`)
          }
        >
          Start Assessment
        </ButtonSmall>
      </StartAssesmentPanel>
    </>
  )
}
