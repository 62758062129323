import * as React from "react"

import { CustomNameHeadStyled } from "./styles"

interface ICustomName {
  name: string
}

const CustomNameHead: React.FC<ICustomName> = ({ name }) => {
  return (
    <CustomNameHeadStyled>
      <span>{name}</span>
    </CustomNameHeadStyled>
  )
}

export default CustomNameHead
