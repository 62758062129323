import { colors } from "@zeguro/component-library"
import styled from "styled-components"

export const TimeContainer = styled.div`
  border-radius: 3px;
  border: 1px solid ${colors.tertiary.gray_3};
  text-align: end;
  background-color: transparent;
  padding: 11px 0;
  margin: 0 16px 1px 0;
`

export const StyledInput = styled.input`
  border: none;
  font-size: 16px;
  line-height: 20px;
  width: 50px;
  padding: 0 12px;
  text-align: center;
`
