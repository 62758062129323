import {
  Content,
  FormElement,
  Heading,
  Panel,
  PanelContainer,
} from "@src/Components/Common"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Markdown } from "@src/Components/Markdown"
import { tState } from "@src/Model/Model"
import * as React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

export type tOnSubmit = (data: any) => void

const Maintenance = () => {
  const { clientHeight, isDesktop } = usePortalSize()
  const { maintenance } = useSelector((state: tState) => {
    return state.portal
  })

  React.useEffect(() => {
    if (!maintenance || maintenance?.enabled === false) {
      window.location.replace("/")
    }
  })

  if (!maintenance || maintenance?.enabled === false) {
    return null
  }

  return (
    <Content height={clientHeight} backgroundImage="empty">
      <PanelContainer height={clientHeight}>
        <PanelStyled height={clientHeight}>
          <FormElement>
            <Image
              height="44px"
              src="https://images.zeguro.com/Brand+Logos/logo_cyber_safety_brand_agnostic_hor.svg"
              alt="logo"
              margin="40px 0 20px 0"
            />
            <Heading align="center" margin="20px 0px" fontSize="30px">
              {maintenance?.title}
            </Heading>
            <Image
              height="100%"
              src={
                isDesktop
                  ? "images/maintenance/DontSupport_Final.png"
                  : "images/maintenance/DontSupport_Final_mobile.png"
              }
              margin={isDesktop ? "" : "25px 0 0 0"}
              alt="don't support"
            />
            <Heading align="center" margin="20px 0px" fontSize="24px">
              {maintenance?.subHead}
            </Heading>
            {maintenance?.content && (
              <TextPanel>
                <Markdown>{maintenance?.content}</Markdown>
              </TextPanel>
            )}
          </FormElement>
        </PanelStyled>
      </PanelContainer>
    </Content>
  )
}
export const PanelStyled = styled(Panel)<{ height?: number }>`
  position: relative;
  overflow: hidden;
  max-width: 900px;
  width: 100%;
  padding: 20px 120px;
  div {
    font-family: proxima-nova, sans-serif;
  }
  @media (max-width: 900px) {
    padding: 20px 20px;
    border-radius: 0;
    height: ${({ height }) => `${height}px`};
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    background: radial-gradient(
      100.68% 275.48% at 1.14% 98.12%,
      #ff04a1 0%,
      rgba(0, 98, 217, 0.964108) 100%
    );
    top: 0;
    left: 0;
  }
`

export const TextPanel = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  color: #212528;
  text-align: left;
  margin: 0 0 20px 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`
export const Image = styled.img<{ margin?: string; height?: string }>`
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  @media (max-width: 450px) {
    width: 100%;
  }
`

export default Maintenance
