import styled from "styled-components"

export const CardIcon = styled.img<any>`
  width: ${(p) => p.width || "75px"};
  margin-right: 32px;
  @media (max-width: 900px) {
    margin-right: 16px;
  }
`
export const CardBodyWrapper = styled.div<any>`
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 0 0 20px 0;
  font-size: 18px;
  width: ${(p) => p.width || "70%"};
  line-height: 1.5;
  div {
    color: #212528;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`
export const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`
