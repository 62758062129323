import styled from "styled-components"

interface FaIcon {
  color?: string
  padding?: string
  margin?: string
  fontSize?: string
  lineHeight?: string
  verticalAlign?: string
  cursor?: string
}

export const FaIcon = styled.i<FaIcon>`
  color: ${({ color }) => color};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  vertical-align: ${({ verticalAlign }) => verticalAlign};
  cursor: ${({ cursor }) => cursor};
`
