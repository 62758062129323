import { colors, media } from "@src/theme"
import styled from "styled-components"

interface IScanInfo {
  width?: string
}
interface ICircle {
  width?: string
}
interface IMain {
  border?: string
}
export const Main = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  hr {
    height: 64px;
    border: none;
    background-color: #d6d6d6;
    width: 1px;
    margin: 15px 10px 0 10px;
  }
`
export const MainSecondSlide = styled.div<IMain>`
  display: flex;
  justify-content: center;
  border: 1px solid #d6d6d6;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  height: 105px;
  margin-bottom: 9px;
  @media ${media.medium} {
    width: auto;
  }
  @media (max-width: 400px) {
    width: calc(100% + 20px);
  }
`
export const SliderCardHead = styled.h3`
  font-weight: bold;
  font-size: 18px;
  color: ${colors.orange90};
  margin: 0;
  position: relative;
  top: -1px;
`
export const ScanInfo = styled.div<IScanInfo>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ width }) => width || "150px"};
  text-align: center;
`
export const ScanInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 65px;
`
export const MainHead = styled.p`
  font-weight: 600;
  font-size: 15px;
  color: #858585;
  margin: 0;
`
export const Circle = styled.i<ICircle>`
  color: ${({ color }) => color};
`

export const ScanNumber = styled.p`
  color: #26249e;
  font-size: 32px;
  font-weight: 800;
  margin: 0 0 5px 0;
  line-height: 1;
`
