import * as React from "react"
import { Link } from "react-router-dom"

const PageNotFound: React.FunctionComponent = () => (
  <div
    style={{
      textAlign: "center",
      padding: 40,
      fontSize: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}
  >
    <img src="/images/cockatoo.svg" style={{ height: "500px" }} />
    <b>404</b> Page not Found.
    <br />
    <Link to="/">Go back to home page.</Link>
  </div>
)

export default PageNotFound
