import * as Action from "@src/Actions/RatingsActions"
import { IFastRatings } from "@src/types"
import { Store } from "redux"

import { serverLog } from "./Logging"
import * as Middletier from "./Middletier"

class Ratings {
  private store: Store

  constructor(store: Store) {
    this.store = store
  }

  loadRatingsData(
    url: string,
    callback?: (success: boolean, data?: IFastRatings) => void,
  ) {
    this.store.dispatch(Action.setRatingsStatus("LOADING"))
    const endpoint = "/ratings/fastRatings"
    Middletier.xhrPost(
      endpoint,
      JSON.stringify({ url }),
      (response: any) => {
        if (response.success) {
          const responseData = response.data
          this.store.dispatch(Action.setRatingsData(responseData))
          callback && callback(false, responseData)
        } else {
          this.store.dispatch(Action.setRatingsStatus("FAILED"))
          serverLog({
            level: "error",
            message: response.message || "Ratings Data load failed",
            endpoint,
            filename: __filename,
            payload: response,
          })
          callback && callback(true, response)
        }
      },
      () => {
        this.store.dispatch(Action.setRatingsStatus("FAILED"))
      },
    )
  }
}

export default Ratings
