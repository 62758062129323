import {
  actions as MONITORING,
  MONITORING_MODALS_KEYS,
} from "@src/Actions/Reducers/MonitoringReducer"
import history from "@src/browserHistory"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import {
  createScheduleScanWatcher,
  editTargetWatcher,
  getScanMapWatcher,
  getScheduleMapWatcher,
  getTargetListWatcher,
} from "@src/Sagas"
import {
  ICancelScan,
  ICheckWebAppUrl,
  ICreateScheduleScan,
  ICreateTarget,
  IDeleteTarget,
  IEditScheduleScan,
  IEditTarget,
} from "@src/Sagas/types"
import { axiosInstance } from "@src/Tools/AxiosInstance"
import { ViewTypes } from "@src/types"
import { all, call, put, select, takeLeading } from "redux-saga/effects"

export function* getTargetList() {
  C.Plans.getFeatureUsageProfile()
  try {
    yield put(MONITORING.getTargetListRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/loadTargetList",
    )
    if (data.success) {
      yield put(MONITORING.getTargetListSuccess(data))
    }
  } catch (e) {
    yield put(MONITORING.getTargetListFailure(e))
  }
}
export function* createTarget({ payload }: ICreateTarget) {
  C.Plans.getFeatureUsageProfile()
  const {
    date_time,
    recurrence,
    run_on_day_of_week,
    scheduled_day_of_week,
    week_index,
    ...restData
  } = payload
  try {
    yield put(MONITORING.createTargetRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/createTarget",
      { ...restData, recurrence },
    )
    if (data.success) {
      yield put(
        createScheduleScanWatcher({
          recurrence,
          zeguroTargetId: data.data.targetId,
          date_time,
          run_on_day_of_week,
          scheduled_day_of_week,
          week_index,
        }),
      )
      yield put(getTargetListWatcher())
      C.toastNotification("Website is successfully created", "success")
      yield put(MONITORING.createTargetSuccess())
    } else {
      C.toastNotification("Create Website failed", "danger")
      yield put(MONITORING.createTargetFailure(Error("Create Website failed")))
    }
  } catch (e) {
    yield put(MONITORING.createTargetFailure(e))
    C.toastNotification("Create Website failed", "danger")
  }
}
export function* editTarget({ payload }: IEditTarget) {
  try {
    yield put(MONITORING.editTargetRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/editTarget",
      payload,
    )
    if (data.success) {
      yield put(getTargetListWatcher())
      C.toastNotification(
        `Website ${payload.appName ? "name" : ""} is successfully updated`,
        "success",
      )
      yield put(MONITORING.editTargetSuccess())
    } else {
      yield put(MONITORING.editTargetFailure("Website name update failed"))
      C.toastNotification(
        `Website ${payload.appName ? "name" : ""} update failed`,
        "danger",
      )
    }
    if (payload.appName) {
      const isEditAppModal: boolean = yield select(
        (store: tState) => store.monitoring.toggleModal.isEditAppModal,
      )
      if (isEditAppModal) {
        yield put(MONITORING.toggleModal(MONITORING_MODALS_KEYS.isEditAppModal))
      }
    }
  } catch (e) {
    yield put(MONITORING.editTargetFailure(e))
    C.toastNotification(
      `Website ${payload.appName ? "name" : ""} update failed`,
      "danger",
    )
  }
}
export function* deleteTarget({ payload }: IDeleteTarget) {
  try {
    yield put(MONITORING.deleteTargetRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/deleteTarget",
      payload,
    )
    if (data.success) {
      yield put(getTargetListWatcher())
      C.toastNotification("Website is successfully removed", "success")
      history.push(ViewTypes.monitoring)
      yield put(MONITORING.deleteTargetSuccess())
    } else {
      C.toastNotification("Remove Website failed", "danger")
    }
    yield put(MONITORING.toggleModal(MONITORING_MODALS_KEYS.isDeleteAppConfirm))
  } catch (e) {
    yield put(MONITORING.deleteTargetFailure(e))
    C.toastNotification("Remove Website failed", "danger")
  }
}
export function* getScheduleMap() {
  try {
    yield put(MONITORING.getScheduleMapRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/loadScheduleMap",
    )
    if (data.success) {
      yield put(MONITORING.getScheduleMapSuccess(data))
    }
  } catch (e) {
    yield put(MONITORING.getScheduleMapFailure(e))
  }
}
export function* createScheduleScan({ payload }: ICreateScheduleScan) {
  try {
    yield put(MONITORING.createScheduleScanRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/scheduleScan",
      payload,
    )
    if (data.success) {
      if (payload.scan_profile) {
        yield put(editTargetWatcher({ payload }))
      }
      yield put(getScheduleMapWatcher())
      yield put(MONITORING.createScheduleScanSuccess())
      C.toastNotification("Scan successfully scheduled", "success")
    } else {
      C.toastNotification("Schedule Scan failed", "danger")
      yield put(MONITORING.createScheduleScanFailure(data?.error))
    }
    const isAddAppModal: boolean = yield select(
      (store: tState) => store.monitoring.toggleModal.isAddAppModal,
    )
    if (isAddAppModal) {
      yield put(MONITORING.toggleModal(MONITORING_MODALS_KEYS.isAddAppModal))
    }
    const isEditScheduleModal: boolean = yield select(
      (store: tState) => store.monitoring.toggleModal.isEditScheduleModal,
    )
    if (isEditScheduleModal) {
      yield put(
        MONITORING.toggleModal(MONITORING_MODALS_KEYS.isEditScheduleModal),
      )
    }
  } catch (e) {
    yield put(MONITORING.createScheduleScanFailure(e))
    C.toastNotification(e || "Schedule Scan failed", "danger")
  }
}
export function* editScheduleScan({ payload }: IEditScheduleScan) {
  const { scan_profile, ...scheduleData } = payload
  try {
    yield put(MONITORING.editScheduleScanRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/editSchedule",
      { ...scheduleData },
    )
    if (data.success) {
      if (scan_profile) {
        yield put(
          editTargetWatcher({
            zeguroTargetId: payload.zeguroTargetId,
            scan_profile,
            recurrence: payload.recurrence,
          }),
        )
      }
      yield put(getScheduleMapWatcher())
      yield put(MONITORING.editScheduleScanSuccess())
      C.toastNotification("Scan schedule successfully updated", "success")
      yield put(
        MONITORING.toggleModal(MONITORING_MODALS_KEYS.isEditScheduleModal),
      )
    } else {
      C.toastNotification("Scan schedule update failed", "danger")
      yield put(MONITORING.editScheduleScanFailure(data?.error))
    }
  } catch (e) {
    yield put(MONITORING.editScheduleScanFailure(e))
    C.toastNotification("Scan schedule update failed", "danger")
  }
}
export function* updateScheduleScan({ payload }: ICreateScheduleScan) {
  try {
    yield put(MONITORING.updateScheduleScanRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/probelyUpdateSchedule",
      { targetId: payload.targetId },
    )
    if (data.success) {
      yield put(MONITORING.updateScheduleScanSuccess())
    }
  } catch (e) {
    yield put(MONITORING.updateScheduleScanFailure(e))
  }
}
export function* syncScans({ payload }: any) {
  try {
    yield put(MONITORING.syncScansRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/syncScans",
      payload,
    )
    if (data.success) {
      yield put(getScanMapWatcher())
      yield put(MONITORING.syncScansSuccess())
    }
  } catch (e) {
    yield put(MONITORING.syncScansFailure(e))
  }
}
export function* getScanMap() {
  try {
    yield put(MONITORING.getScanMapRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/loadScanMap",
    )
    if (data.success) {
      yield put(MONITORING.getScanMapSuccess(data))
    }
  } catch (e) {
    yield put(MONITORING.getScanMapFailure(e))
  }
}
export function* cancelScan({ payload }: ICancelScan) {
  try {
    const { nextScan, ...restPayload } = payload
    yield put(MONITORING.cancelScanRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/cancelScan",
      restPayload,
    )
    if (data.success) {
      yield put(MONITORING.cancelScanSuccess())
      yield put(getScheduleMapWatcher())
      yield put(
        MONITORING.toggleModal(MONITORING_MODALS_KEYS.isWebScanCancelConfirm),
      )
      C.toastNotification(
        `Your website scan has been canceled. Your next scan is scheduled to run on ${nextScan}.`,
        "success",
      )
    } else {
      yield put(MONITORING.cancelScanFailure(data?.message))
      C.toastNotification(data?.message, "danger")
    }
  } catch (e) {
    yield put(MONITORING.cancelScanFailure(e?.message || e))
    C.toastNotification(e?.message, "danger")
  }
}
export function* checkAppUrl({ payload }: ICheckWebAppUrl) {
  try {
    yield put(MONITORING.checkAppUrlRequest())
    const { data } = yield call(
      axiosInstance.post,
      "/api/monitoring/webapps/checkAppUrl",
      payload,
    )
    if (data.success) {
      yield put(MONITORING.checkAppUrlSuccess(data))
    } else {
      yield put(MONITORING.checkAppUrlFailure(data?.message))
      C.toastNotification(data?.message, "danger")
    }
  } catch (e) {
    yield put(MONITORING.checkAppUrlFailure(e?.message || e))
    C.toastNotification(e?.message, "danger")
  }
}

function* monitoringWatcherSaga() {
  yield all([
    takeLeading("GET_TARGET_LIST_WATCHER", getTargetList),
    takeLeading("CREATE_TARGET_WATCHER", createTarget),
    takeLeading("EDIT_TARGET_WATCHER", editTarget),
    takeLeading("DELETE_TARGET_WATCHER", deleteTarget),
    takeLeading("GET_SCHEDULE_MAP_WATCHER", getScheduleMap),
    takeLeading("CREATE_SCHEDULE_SCAN_WATCHER", createScheduleScan),
    takeLeading("EDIT_SCHEDULE_SCAN_WATCHER", editScheduleScan),
    takeLeading("SYNC_SCANS_WATCHER", syncScans),
    takeLeading("GET_SCAN_MAP_WATCHER", getScanMap),
    takeLeading("UPDATE_SCHEDULE_SCAN_WATCHER", updateScheduleScan),
    takeLeading("CANCEL_SCAN_WATCHER", cancelScan),
    takeLeading("CHECK_APP_URL_WATCHER", checkAppUrl),
  ])
}

export default monitoringWatcherSaga
