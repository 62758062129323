import styled from "styled-components"

type tPageContent = {
  height: number
  width: number
  paddingTop?: number
  marginLeft?: number
}

type tZeguroSessionBanner = {
  offset?: number
}

export const PageContent = styled.div<tPageContent>`
  margin-top: 0;
  min-height: min(100vh, 100dvh);
  width: ${({ width }) => width}px;
  padding-top: ${({ paddingTop }) => paddingTop}px;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  background-color: #eaeaea;
`

export const ZeguroSessionBanner = styled.div<tZeguroSessionBanner>`
  position: fixed;
  transform: translateX(
    calc(50vw - 50% + ${({ offset }) => (offset ? offset / 2 : 0)}px)
  );
  margin-top: 8px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  background-color: orange;
  padding: 4px 12px;
  font-weight: bold;
  min-height: 40px;
  font-size: 16px;
  line-height: 1.2;
`
