import * as Sentry from "@sentry/react"
import { getCSRFToken } from "@src/ControllerModules/Middletier"
import axios from "axios"

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  async (config) => {
    const transactionId = Math.random().toString(36).substr(2, 9)
    const csrfToken = await getCSRFToken()
    config.headers["X-Transaction-ID"] = transactionId
    config.headers["X-CSRF-Token"] = csrfToken
    Sentry.configureScope((scope) => {
      scope.setTag("transaction_id", transactionId)
    })
    return config
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.replace("/")
    }
    return Promise.reject(error)
  },
)
