import {
  Content,
  Heading,
  LinkWrapper,
  Panel,
  PanelContainer,
} from "@src/Components/Common"
import FloatingLabelInput from "@src/Components/FloatingLabelInput"
import { Link } from "@src/Components/Link"
import PasswordInput from "@src/Components/PasswordInput"
import { StyledSpinner } from "@src/Components/StyledSinner"
import { Section } from "@src/Components/StyledUtils"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import {
  FormElementStyled,
  ResetPasswordButton,
} from "@src/Portal/Accounts/OrgUserMagicLinkInviteSignup/styles"
import MessageContainer from "@src/Portal/MessageContainer"
import { colors } from "@src/theme"
import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useSelector } from "react-redux"

export type tOnSubmit = (data: any) => void

interface IMagicLinkSignUp {
  data: any
  height: number
  recaptchaRef: React.RefObject<ReCAPTCHA>
  onMagicLinkSignUp: (password: string) => void
  executeCaptcha: () => void
  verifyToken: (token: any, callback: any) => void
  setIsLoadingCaptcha: (isLoading: boolean) => void
  resetCaptcha: () => void
  isLoading?: boolean
}

const MagicLinkSignUp = ({
  data,
  height,
  recaptchaRef,
  onMagicLinkSignUp,
  executeCaptcha,
  verifyToken,
  resetCaptcha,
  isLoading,
  setIsLoadingCaptcha,
}: IMagicLinkSignUp) => {
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [newPasswordValid, setNewPasswordValid] = React.useState(false)
  const [confirmPasswordValid, setConfirmPasswordValid] = React.useState(false)
  const { emailDomain } = useSelector((state: tState) => state.brand)

  const pContent = {
    height,
  }

  const NAV_BAR_HEIGHT = 76
  const COMPONENT_WIDTH = 472

  const clearState = () => {
    setPassword("")
    setConfirmPassword("")
    setIsLoadingCaptcha(false)
  }

  const submitSignUp = () => {
    if (password.trim() !== confirmPassword.trim()) {
      C.toastNotification(
        "New password does not match the confirm password.",
        "danger",
      )
      resetCaptcha()
    } else if (password.trim().length > 0) {
      onMagicLinkSignUp(password.trim())
      resetCaptcha()
    } else {
      C.toastNotification(
        "Form Incomplete. Please fill empty fields.",
        "danger",
      )
      resetCaptcha()
    }
    clearState()
  }

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value)
    setConfirmPassword("")
    setConfirmPasswordValid(false)
  }

  const onConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value)
  }

  const onChange = (value: any) => {
    if (value !== null) {
      verifyToken(value, (response: any) =>
        response.success
          ? submitSignUp()
          : C.toastNotification("You smell like a robot.", "danger"),
      )
    } else {
      console.log("CAPTCHA ERROR")
    }
  }

  return (
    <Content {...pContent}>
      <MessageContainer />
      <PanelContainer height={height - NAV_BAR_HEIGHT}>
        <Panel>
          <Section flex="center center column">
            <Heading margin="20px 0px">Welcome to Cyber Safety</Heading>
          </Section>
          <Section flex="center center column">
            <Section
              fontSize="21px"
              fontColor={colors.darkBlue}
              fontWeight="400"
              margin="0 0 10 0"
            >
              Create a password and get started.
            </Section>
            <FormElementStyled>
              <Section fontSize={20} w="100%">
                <FloatingLabelInput
                  id="signup-email"
                  label="Your work email address"
                  type="email"
                  value={data?.createdFor?.email}
                  onChange={() => {}}
                  disabled
                />
              </Section>
              <Section fontSize={20} w="100%">
                <PasswordInput
                  id="signup-password"
                  label="Create password"
                  value={password}
                  onChange={onPasswordChange}
                  isValid={(valid: boolean) => {
                    setNewPasswordValid(valid)
                  }}
                />
              </Section>
              <Section fontSize={20} w="100%">
                <PasswordInput
                  id="signup-confirm-password"
                  label="Confirm password"
                  passwordToMatch={password}
                  value={confirmPassword}
                  onChange={onConfirmPasswordChange}
                  isValid={(valid: boolean) => {
                    setConfirmPasswordValid(valid)
                  }}
                />
              </Section>
              <Section flex="center center column">
                <ReCAPTCHA
                  ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                  sitekey="6Lfi57UUAAAAACcMby1mAPrHTNZVGG_B2t_7KDDN"
                  size="invisible"
                  onChange={(value) => onChange(value)}
                />
              </Section>
              <Section w="100%" margin="30 0 0 0">
                <ResetPasswordButton
                  onClick={() => {
                    executeCaptcha()
                  }}
                  width="100%"
                  id="CyberSafety_Trial_Lead_Button"
                  disabled={
                    !(
                      newPasswordValid &&
                      confirmPasswordValid &&
                      password &&
                      confirmPassword
                    ) || isLoading
                  }
                >
                  {isLoading ? (
                    <StyledSpinner size={24} color={colors.gray2} />
                  ) : (
                    "Set Password"
                  )}
                </ResetPasswordButton>
              </Section>
              <LinkWrapper fontWeight="400" column>
                <p>
                  By continuing, you agree to Cyber Safety&nbsp;
                  <Link
                    isUnderline
                    onClick={() => {
                      window.open(
                        "https://www.munichre.com/hsb/en/general/legal/terms-of-use.html",
                      )
                    }}
                  >
                    Terms&nbsp;of&nbsp;Service
                  </Link>
                  &nbsp;and
                  <br />
                  <Link
                    isUnderline
                    onClick={() => {
                      window.open(
                        "https://www.munichre.com/hsb/en/general/legal/privacy-statement.html",
                      )
                    }}
                  >
                    Privacy&nbsp;Policy
                  </Link>
                  .
                </p>
                <p>
                  Need help? Contact&nbsp;
                  <Link href={`mailto:support@${emailDomain}`}>
                    support@{emailDomain}
                  </Link>
                  .
                </p>
              </LinkWrapper>
            </FormElementStyled>
          </Section>
        </Panel>
      </PanelContainer>
    </Content>
  )
}

export default MagicLinkSignUp
