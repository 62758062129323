import Dialog from "@src/Components/Dialog"
import { colors } from "@src/theme"
import styled from "styled-components"

export const StyledDialog = styled(Dialog)`
  max-width: 865px;
  width: 100%;
  @media (max-width: 960px) {
    max-width: 865px;
  }
  @media (max-width: 900px) {
    max-width: 800px;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.blue6};
  padding: 0 28px 0 32px;
  font-size: 21px;
  font-weight: 700;
  line-height: 40px;
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  @media (max-width: 900px) {
    font-size: 21px;
  }
`

export const SubTitle = styled.div`
  font-size: 21px;
  @media (max-width: 900px) {
    font-size: 18px;
  }
  @media (max-width: 480px) {
    font-size: 16px;
  }
`

export const ConciergeIcon = styled.img`
  height: 62px;
  padding-right: 20px;
  @media (max-width: 660px) {
    display: none;
  }
`

export const ActionsBlockHeader = styled.div`
  font-size: 15px;
  border-radius: 15px;
  color: ${colors.darkBlue};
  background-color: ${colors.lightBlue8};
  padding: 0 26px;
  line-height: 29px;
  transform: translateY(50%);
`

export const ActionsBlock = styled.div`
  display: flex;
  border: 1px solid ${colors.lightBlue9};
  border-radius: 6px;
  width: 100%;
  margin-bottom: 18px;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }
`

export const ActionsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 20px 0 30px;
  @media (max-width: 480px) {
    padding: 0;
  }
`

export const Divider = styled.div`
  background-color: ${colors.lightBlue9};
  width: 1px;
  margin: 48px 0 36px;
  @media (max-width: 480px) {
    width: 135px;
    margin: 0;
    flex-basis: 1px;
  }
`

export const StyledLi = styled.li`
  padding: 4px;
`
