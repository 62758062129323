import {
  actions as GLOBAL_MODALS,
  GLOBAL_MODALS_KEYS,
} from "@src/Actions/Reducers/GlobalModalsReducer"
import { ButtonSecondary } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import { Markdown } from "@src/Components/Markdown"
import { Section } from "@src/Components/StyledUtils"
import { colors } from "@src/theme"
import { ViewTypes } from "@src/types"
import { CyberMaturityCatalog as ICyberMaturityCatalog } from "@zeguro/schema-validator/dist/types/coco/cyberMaturity/combined"
import * as React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { textToPath } from "../../../utils"
import AdvanceToNextLevel from "../AdvanceToNextLevel"
import {
  ElementFooter,
  ElementFooterLeftSection,
  ElementIcon,
  ElementPanel,
  NeedHelpSection,
  OverviewContent,
} from "../styles"

interface IElement {
  level?: number
  hidden: boolean
  element: ICyberMaturityCatalog["elements"][number]
}

const Element = ({ level, hidden, element }: IElement) => {
  const { isDesktop } = usePortalSize()
  const history = useHistory()
  const dispatch = useDispatch()

  const openContactModal = () => {
    dispatch(
      GLOBAL_MODALS.toggleGlobalModal(
        GLOBAL_MODALS_KEYS.isContactCyberConcierge,
      ),
    )
  }

  if (hidden) {
    return null
  }

  return (
    <ElementPanel>
      <Section>
        <OverviewContent>
          {typeof level === "number" && isDesktop && (
            <ElementIcon
              src={element.elementImageUrl}
              alt={element.elementName}
            />
          )}
          <Markdown>{element.elementDescription}</Markdown>
        </OverviewContent>
        <AdvanceToNextLevel level={level} element={element} />
        <ElementFooter
          flex="space-between normal"
          flexWrap="wrap"
          padding="0 24 15 24"
          width="100%"
          gap="20px"
        >
          <ElementFooterLeftSection flex="center normal column">
            <NeedHelpSection margin="0 0 16 0" flex="normal center">
              <span>
                Need help?{" "}
                <Link onClick={openContactModal} isUnderline withIcon>
                  Contact Cyber Concierge (included in your subscription)
                </Link>
              </span>
            </NeedHelpSection>
            <Section flex="normal center">
              <FaIcon
                className="fas fa-book"
                color={colors.darkBlue}
                fontSize="12px"
                margin="0 6px 0 0"
              />
              <Link
                href={element.elementKnowledgeBaseLink}
                id="cm-learn-more-about-link"
                isUnderline
                withIcon
              >
                Learn more {isDesktop && `about ${element.elementName}`}
              </Link>
            </Section>
          </ElementFooterLeftSection>
          {typeof level === "number" && (
            <div>
              <ButtonSecondary
                backgroundColor={colors.white}
                width="174"
                margin="0 0 8"
                onClick={() =>
                  history.push(
                    `${ViewTypes.zimm}/assessment/${textToPath(
                      element.elementName,
                    )}`,
                  )
                }
              >
                Update Response
              </ButtonSecondary>
            </div>
          )}
        </ElementFooter>
      </Section>
    </ElementPanel>
  )
}

export default Element
