import * as Sentry from "@sentry/react"
import axios from "axios"

import C from "../Controller"

export const getCSRFToken = async () => {
  const response = await axios.get("/getCSRFToken")
  axios.defaults.headers.post["X-CSRF-Token"] = response.data.CSRFToken
  return response.data.CSRFToken
}

export const xhrPost = (
  url: string,
  payload: string,
  callback: (reponse: any) => void,
  onError?: any,
) => {
  const transactionId = Math.random().toString(36).substr(2, 9)
  Sentry.configureScope((scope) => {
    scope.setTag("transaction_id", transactionId)
  })
  axios({
    method: "post",
    url: `/api${url}`,
    headers: {
      "Content-Type": "application/json",
      "X-Transaction-ID": transactionId,
    },
    data: payload,
  })
    .then((response) => {
      if (callback) {
        callback(response.data)
      }
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        window.location.replace("/")
      }
      if (onError) {
        onError(error)
      }
      C.serverLog({
        level: "error",
        message: `Middletier request error: ${error}`,
        filename: __filename,
        endpoint: `${url}`,
        payload,
      })
    })
}

/* Generic Request */

export const fetchData = (
  fileName: string,
  callback: (success: boolean, data: string) => void,
) => {
  fetch(fileName)
    .then((res) => {
      if (res.status === 401) {
        window.location.replace("/")
      }
      return res.text()
    })
    .then(
      (data) => {
        callback(true, data)
      },
      (error) => {
        callback(false, error)
        C.serverLog({
          level: "error",
          message: "Fetch Data Failed",
          filename: __filename,
          endpoint: `${fileName}`,
          payload: error,
        })
      },
    )
    .catch((reason: any) => {
      callback(false, reason)
      C.serverLog({
        level: "error",
        message: "Fetch Data Failed",
        filename: __filename,
        endpoint: `${fileName}`,
        payload: reason,
      })
    })
}

export const fetchJson = (
  url: string,
  callback: (success: boolean, data: any) => void,
) => {
  fetch(`/api${url}`, {})
    .then((res) => {
      if (res.status === 401) {
        window.location.replace("/")
      }
      return res.json()
    })
    .then(
      (data) => {
        callback(true, data)
      },
      (error) => {
        callback(false, error)
        C.serverLog({
          level: "error",
          message: "Middletier request error",
          filename: __filename,
          endpoint: `${url}`,
          stack: `${error}`,
        })
      },
    )
    .catch((reason: any) => {
      callback(false, reason)
      C.serverLog({
        level: "error",
        message: "Fetch Data Failed",
        filename: __filename,
        endpoint: `${url}`,
        payload: reason,
      })
    })
}
