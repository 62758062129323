import { Spinner } from "@blueprintjs/core"
import { Section } from "@src/Components/StyledUtils"
import { tState } from "@src/Model/Model"
import * as React from "react"
import { useSelector } from "react-redux"

import LearnMoreSection from "../../LearnMoreSection"
import AlertsList from "./AlertsList"
import EmptyState from "./EmptyState"

const Alerts = () => {
  const alerts = useSelector(
    (state: tState) => state.cyberConcierge.getCyberConciergeAlerts.alerts,
  )
  const isLoading = useSelector(
    (state: tState) => state.cyberConcierge.getCyberConciergeAlerts.isLoading,
  )

  if (isLoading) {
    return <Spinner size={25} />
  }

  return (
    <Section w="100%" flexGrow="1" flex="space-between center column">
      {alerts.length ? <AlertsList alerts={alerts} /> : <EmptyState />}
      <LearnMoreSection />
    </Section>
  )
}

export default Alerts
