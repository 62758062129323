import { colors } from "@src/theme"
import styled from "styled-components"

interface IRatingBadge {
  color: string
}
interface ICompanyUrl {
  companyUrl: boolean
  color?: string
}

export const Content = styled.div`
  display: grid;
  grid-template-columns: 65% 35%;

  h4 {
    margin-bottom: 10px;
  }

  .rating-badges-wrapper {
    display: flex;
    margin: 20px 0;

    @media (max-width: 1250px) {
      flex-direction: column;
      height: 140px;
      justify-content: space-around;
    }
  }
`

export const RatingBadge = styled.div<IRatingBadge>`
  display: flex;
  align-items: center;
  margin-right: 15px;

  .rating-badge-title {
    color: ${colors.white};
    background: ${({ color }) => color};
    padding: 5px;
    min-width: 122px;
    border-radius: 15px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
  }

  span {
    color: ${({ color }) => color};
    margin-left: 15px;
    font-size: 12px;
    font-weight: 700;
  }
`

export const RatingBadgeCircle = styled.div<IRatingBadge>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  background: ${({ color }) => (color === "#495057" ? colors.white : color)};
  color: ${({ color }) => (color === "#495057" ? colors.black : colors.white)};
  border: ${({ color }) => color === "#495057" && `1px solid ${color}`};
  border-radius: 100px;
  width: 50px;
  height: 50px;
`

export const LeftContainer = styled.div<ICompanyUrl>`
  overflow: auto;
  height: 100vh;
  margin-right: 20px;

  .rating-category-title {
    margin-top: 50px;
  }

  .rating-risk-title {
    display: flex;
    align-items: center;
    margin: 50px 0 20px;

    h3 {
      margin: 20px;
    }
  }

  filter: ${({ companyUrl }) => !companyUrl && "blur(4px)"};
`

export const RightContainer = styled.div`
  background: ${colors.gray3};
  padding: 20px;
  margin: 0 -10px;
  overflow-y: scroll;
  height: 100vh;
  padding-top: 0;

  h2,
  h3 {
    margin: 0;
  }
`

export const RiskRatingHead = styled.div<ICompanyUrl>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid ${colors.black};
  padding-bottom: 5px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #f9fafb;
  padding-top: 20px;

  h2 {
    margin-bottom: 10px;
  }

  .risk-rating-number {
    border: 1px solid ${colors.gray50};
    border-radius: 10px;
    flex-direction: column;
    padding: 10px;
    display: flex;
    filter: ${({ companyUrl }) => !companyUrl && "blur(4px)"};

    p {
      color: ${({ color }) => color || colors.blue50};
      font-size: 32px;
      margin: 0;
      line-height: 26px;
      font-weight: 700;
      padding-bottom: 3px;
    }

    span {
      font-size: 9px;
      font-weight: 700;
      color: ${colors.gray50};
      white-space: nowrap;
    }
  }
`
