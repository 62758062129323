import { colors } from "@src/theme"
import styled from "styled-components"

export const CategoryListStyled = styled.div`
  ul {
    padding: 0;
    margin-top: 0;
  }

  .head-category-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    i {
      cursor: pointer;
    }
  }
`
export const LinkOverview = styled.h4`
  color: ${colors.link};
  cursor: pointer;
  margin-top: 0;

  &:hover {
    text-decoration: underline;
  }
`
