import { ButtonCancel, ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Link } from "@src/Components/Link"
import Switch from "@src/Components/Switch"
import { tState } from "@src/Model/Model"
import * as React from "react"
import { useSelector } from "react-redux"

import { ICoursesCS } from "../ColumnSpecial"
import SubscribeSection from "../SubscribeSection"
import {
  ButtonWrap,
  CheckIcon,
  CheckLabel,
  CheckWrap,
  Content,
  CourseItem,
  CourseList,
  Dialog,
  Footer,
  Section,
} from "./styles"

interface IModalEnroll {
  isOpen: boolean
  fullName: string
  courses: ICoursesCS[]
  onEnroll: (key: ICoursesCS[]) => void
  onClose: () => void
  goToCourseTab: () => void
}
const ModalEnroll: React.FC<IModalEnroll> = ({
  isOpen,
  fullName,
  courses,
  onEnroll,
  onClose,
  goToCourseTab,
}) => {
  const { isDesktop } = usePortalSize()
  const [data, setData] = React.useState<ICoursesCS[]>(courses)

  const subscriptionStatus = useSelector(
    (state: tState) => state.plans.subscriptionStatus,
  )
  const isInTrial = subscriptionStatus === "INTRIAL"

  React.useEffect(() => {
    setData(courses)
  }, [courses, isOpen])

  const onChange = (index: number) => {
    const newData = data.map((item) => ({ ...item }))
    newData[index].isEnrolled = !data[index].isEnrolled
    setData(newData)
  }

  const isDisabled = JSON.stringify(courses) === JSON.stringify(data)

  return (
    <Dialog
      title={`Change Enrollment in Specialized Courses for ${fullName}`}
      className="zeg-dialog"
      isOpen={isOpen}
      onClose={onClose}
      content={
        <Section className="bp5-dialog-body">
          <Content>
            {isInTrial && <SubscribeSection />}
            <CourseList>
              {data.map((course, index: number) => (
                <CourseItem key={course.name}>
                  {course.isFinished ? (
                    <CheckWrap>
                      <CheckIcon className="fas fa-check-circle" />
                      <CheckLabel>{course.name}</CheckLabel>
                    </CheckWrap>
                  ) : (
                    <Switch
                      checked={course.isEnrolled}
                      label={course.name}
                      onChange={() => onChange(index)}
                      disabled={isInTrial}
                    />
                  )}
                </CourseItem>
              ))}
            </CourseList>
          </Content>
          <Footer>
            <p>
              Learn more about courses in the{" "}
              <Link onClick={goToCourseTab}>Course Library.</Link>
            </p>
            <ButtonWrap>
              <ButtonCancel
                width={120}
                onClick={onClose}
                className="cancel-button-enroll"
              >
                Cancel
              </ButtonCancel>
              <ButtonSmall
                width={isDesktop ? 220 : 160}
                onClick={() => onEnroll(data)}
                disabled={isDisabled}
              >
                Next
              </ButtonSmall>
            </ButtonWrap>
          </Footer>
        </Section>
      }
    />
  )
}

export default ModalEnroll
