import { tState } from "@src/Model/Model"
import {
  ProbelyEditScheduledScanRequest,
  ProbelyEditTargetRequest,
} from "@zeguro/schema-validator/dist/types/coco/probely/combined"
import { useFormik } from "formik"
import * as moment from "moment"
import * as React from "react"
import { useSelector } from "react-redux"

import AddAppStepThree from "../AddWebAppForm/AddAppStepThree"
import { WEEK_INDEXES_TO_TEXT } from "../constants"
import { getAllDaysInMonth } from "../helpers"
import { useEditScheduleValidation } from "./validation"

export interface IEditScheduleFormFields {
  scanLevel: string
  scheduleDate: Date
  scheduleTime: Date
  recurrence: string
  dayOfMonth: number
  scheduledDayOfWeek: number
  weekIndex: string
  runOnDayOfWeek: boolean
}

type IEditSchedule = Omit<
  ProbelyEditScheduledScanRequest & ProbelyEditTargetRequest,
  "orgId"
>

interface IEditScheduleForm {
  onClose?: () => void
  editSchedule: (values: IEditSchedule) => void
  targetId: string
  scheduledScanId?: string
  createSchedule?: (values: IEditSchedule) => void
  scanLevels: string[][]
  hasSchedule: boolean
}

const firstScanDate = moment(new Date())
  .add(1, "days")
  .set("hour", 1)
  .set("minute", 0)
  .set("second", 0)
  .toDate()

const EditScheduleForm = ({
  scanLevels,
  targetId,
  scheduledScanId,
  hasSchedule,
  editSchedule,
  createSchedule,
  onClose,
}: IEditScheduleForm) => {
  const getTargetList = useSelector(
    (state: tState) => state.monitoring.getTargetList,
  )
  const getScheduleMap = useSelector(
    (state: tState) => state.monitoring.getScheduleMap,
  )
  const editScanIsLoading = useSelector(
    (state: tState) => state.monitoring.editScheduleScan.isLoading,
  )
  const createScanIsLoading = useSelector(
    (state: tState) => state.monitoring.createScheduleScan.isLoading,
  )

  const target = getTargetList.data.find(
    (target) => target.targetId === targetId,
  )
  const scan = getScheduleMap.data[targetId]?.[0]

  const editScheduleSchema = useEditScheduleValidation()

  const onSubmit = () => {
    const {
      scanLevel,
      scheduleDate,
      scheduleTime,
      recurrence,
      runOnDayOfWeek,
      scheduledDayOfWeek,
      weekIndex,
    } = formik.values
    const currentDate: Date = scheduleDate
    currentDate.setHours(scheduleTime.getHours())
    currentDate.setMinutes(scheduleTime.getMinutes())
    const partOfPayload = {
      zeguroTargetId: targetId,
      recurrence: recurrence,
      date_time: currentDate.toISOString().split(".")[0] + "Z",
      run_on_day_of_week: ["q", "m"].includes(recurrence) && runOnDayOfWeek,
    }
    const payload =
      ["q", "m"].includes(recurrence) && runOnDayOfWeek
        ? {
            ...partOfPayload,
            scheduled_day_of_week: scheduledDayOfWeek,
            week_index: weekIndex,
          }
        : partOfPayload
    if (hasSchedule && scheduledScanId) {
      editSchedule({
        zeguroScheduledScanId: scheduledScanId,
        scan_profile: scanLevel,
        ...payload,
      })
    } else if (createSchedule) {
      createSchedule(payload)
    }
  }

  const dateTime = scan?.dateTime ? new Date(scan?.dateTime) : firstScanDate
  const dayIndex = dateTime.getDay() === 0 ? 7 : dateTime.getDay()
  const allDays = getAllDaysInMonth(dateTime.getDay(), dateTime)
  const findCurrentWeek =
    allDays?.find((dayDate) => {
      return dayDate === dateTime.getDate()
    }) || 0
  const weekIndex = allDays.indexOf(findCurrentWeek)

  const formik = useFormik<IEditScheduleFormFields>({
    initialValues: {
      scanLevel: target?.scan_profile || "",
      scheduleDate: dateTime,
      scheduleTime: dateTime,
      recurrence: scan?.recurrence || "q",
      dayOfMonth: dateTime.getDate(),
      scheduledDayOfWeek: scan?.scheduledDayOfWeek || dayIndex,
      weekIndex: scan?.weekIndex || WEEK_INDEXES_TO_TEXT[weekIndex],
      runOnDayOfWeek: scan?.runOnDayOfWeek,
    },
    validationSchema: editScheduleSchema,
    onSubmit: onSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <AddAppStepThree
        formik={formik}
        scanLevels={scanLevels}
        isLoading={editScanIsLoading || createScanIsLoading}
        isNewSchedule={!hasSchedule}
        onBack={onClose}
      />
    </form>
  )
}

export default EditScheduleForm
