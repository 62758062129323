import { actions as ACCOUNTS } from "@src/Actions/Reducers/AccountsReducer"
import { Content, Panel, PanelContainer } from "@src/Components/Common"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { tState } from "@src/Model/Model"
import MessageContainer from "@src/Portal/MessageContainer"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { CheckYourEmail } from "./CheckYourEmail"
import { VerifyIdentity } from "./VerifyIdentity"

export const ForgotPassword = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { clientHeight: height } = usePortalSize()
  const pContent = { height }

  React.useEffect(() => {
    dispatch(ACCOUNTS.sendResetPasswordMagicLinkClear())
  }, [])

  const resetPasswordSuccess = useSelector(
    (state: tState) => state?.account?.sendResetPasswordMagicLink?.success,
  )

  const { authenticated } = useSelector((store: tState) => store.portal)

  React.useEffect(() => {
    if (authenticated) {
      history.push("/")
    }
  }, [authenticated])

  return (
    <Content {...pContent}>
      <MessageContainer />
      <PanelContainer height={height}>
        <Panel>
          {resetPasswordSuccess ? <CheckYourEmail /> : <VerifyIdentity />}
        </Panel>
      </PanelContainer>
    </Content>
  )
}
