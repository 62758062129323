import styled from "styled-components"

interface IDivProps {
  color?: string
}

export const Content = styled.div`
  canvas {
    width: 100% !important;
  }
`

export const Title = styled.div`
  font-weight: 600;
`

export const HiLoWrap = styled.div`
  display: flex;
`

export const HiLo = styled.div`
  text-align: center;
  flex: 1;
  padding: 20px;
`

export const Score = styled.div<IDivProps>`
  font-size: 20px;
  font-weight: 700;
  color: ${({ color }) => color};
`

export const Text = styled.div`
  font-size: 14px;
`
