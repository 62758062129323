import { DateInfo } from "@src/Portal/Dashboard/TrainingCard/styles"
import { IComplianceDashboardData } from "@src/types"
import * as moment from "moment"
import * as React from "react"

import { Description, InfoMain, InfoName, InfoNumber } from "../styles"

export const FilledStateCard: React.FC<IComplianceDashboardData> = ({
  active,
  inactive,
  draft,
  lastUpdated,
}) => (
  <>
    <InfoMain>
      <InfoName>
        <InfoNumber>{draft}</InfoNumber>
        <Description>
          Draft
          <br />
          {draft > 1 ? "Policies" : "Policy"}
        </Description>
      </InfoName>
      <hr />
      <InfoName>
        <InfoNumber>{inactive}</InfoNumber>
        <Description>
          {" "}
          Inactive
          <br />
          {inactive > 1 ? "Policies" : "Policy"}
        </Description>
      </InfoName>
      <hr />
      <InfoName>
        <InfoNumber>{active}</InfoNumber>
        <Description>
          {" "}
          {active > 1 ? "Policies" : "Policy"}
          <br />
          in Effect
        </Description>
      </InfoName>
    </InfoMain>
    {lastUpdated ? (
      <DateInfo>Updated {moment(lastUpdated).format("MMM D, YYYY")}</DateInfo>
    ) : null}
  </>
)
