import { actions as CyberConcierge } from "@src/Actions/Reducers/CyberConciergeReducer"
import history from "@src/browserHistory"
import C from "@src/Controller"
import { IGetCyberConciergeAlert } from "@src/Sagas/types"
import { ViewTypes } from "@src/types"
import axios from "axios"
import { put, takeLeading } from "redux-saga/effects"

import { IGetCyberConciergeAlerts } from "../types"

function* getCyberConciergeConfig(): any {
  try {
    yield put(CyberConcierge.getCyberConciergeConfigRequest())
    const { data } = yield axios.get(`/api/cyberconcierge/cyberConciergeConfig`)
    if (data.success) {
      yield put(CyberConcierge.getCyberConciergeConfigSuccess(data))
    }
  } catch (e) {
    yield put(CyberConcierge.getCyberConciergeConfigFailure(e))
  }
}
function* getCyberConciergeAlerts(action: IGetCyberConciergeAlerts): any {
  try {
    yield put(CyberConcierge.getCyberConciergeAlertsRequest())
    const { data } = yield axios.get(`/api/cyberconcierge/cyberConciergeAlerts`)
    if (data.success) {
      yield put(CyberConcierge.getCyberConciergeAlertsSuccess(data.alerts))
      if (!action.payload.tabId) {
        if (action.payload.tabId !== "alerts" && data.alerts.length) {
          history.push(`${ViewTypes.cyberConcierge}/alerts`)
        } else if (action.payload.tabId !== "about") {
          history.push(`${ViewTypes.cyberConcierge}/about`)
        }
      }
    }
  } catch (e) {
    yield put(CyberConcierge.getCyberConciergeAlertsFailure(e))
  }
}
function* getCyberConciergeAlert(action: IGetCyberConciergeAlert): any {
  try {
    yield put(CyberConcierge.getCyberConciergeAlertRequest())
    const { data } = yield axios.get(
      `/api/cyberconcierge/cyberConciergeAlert?conciergeMessageId=${action.payload}`,
    )
    if (data.success) {
      yield put(CyberConcierge.getCyberConciergeAlertSuccess(data))
    } else {
      throw new Error(data.error)
    }
  } catch (e) {
    yield put(CyberConcierge.getCyberConciergeAlertSuccess(e))
    C.toastNotification("Something went wrong, please try again.", "danger")
    history.push(`${ViewTypes.cyberConcierge}/alerts`)
  }
}
export function* cyberConciergWatcherSaga() {
  yield takeLeading(
    "GET_CYBER_CONCIERGE_CONFIG_WATCHER",
    getCyberConciergeConfig,
  )
  yield takeLeading(
    "GET_CYBER_CONCIERGE_ALERTS_WATCHER",
    getCyberConciergeAlerts,
  )
  yield takeLeading("GET_CYBER_CONCIERGE_ALERT_WATCHER", getCyberConciergeAlert)
}
