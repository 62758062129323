import { actions as MONITORING } from "@src/Actions/Reducers/MonitoringReducer"
import { ButtonSmall } from "@src/Components/Button"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"

import { BluePanel } from "../../Training/Styles"
import { Text } from "./styles"

const ScansOnboarding = () => {
  const dispatch = useDispatch()
  const { emailDomain } = useSelector((state: tState) => state.brand)

  return (
    <React.Fragment>
      <BluePanel margin="20 0">
        <Section flex="start center column" padding="45 45" align="center">
          <img
            height="100px"
            src="/images/intro/icon_cloud_lock_shield.svg"
            style={{ marginBottom: "10px" }}
          />
          <Text fontFamily="montserrat">Scan Your First Website</Text>
          <Text fontSize="18px" color={colors.gray50}>
            Identify security issues so you can fix them.
          </Text>
          <ButtonSmall
            width={170}
            onClick={() => dispatch(MONITORING.toggleModal("isAddAppModal"))}
            id="scan-onboarding-button"
            margin="10 0 0 0"
          >
            <FaIcon className="fas fa-plus" />
            &nbsp;Add a Website
          </ButtonSmall>
        </Section>
      </BluePanel>
      <Section align="start">
        Need help with your website scans? Contact us at&nbsp;
        <Link href={`mailto:support@${emailDomain}`} isUnderline>
          support@{emailDomain}
        </Link>
        .
      </Section>
    </React.Fragment>
  )
}

export default ScansOnboarding
