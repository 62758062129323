import { colors } from "@src/theme"
import { TraineeData as ITrainee } from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as _ from "lodash"
import * as React from "react"
import DataTableComponent from "react-data-table-component"

const borderStyle = "1px solid rgba(173, 173, 173, 0.5)"

export const defaultCustomStyles: any = {
  headRow: {
    style: {
      borderTop: borderStyle,
    },
  },
  rows: {
    style: {
      "&:last-child": {
        borderBottom: borderStyle,
      },
    },
  },
  tableWrapper: {
    style: {
      borderLeft: borderStyle,
    },
  },
  headCells: {
    style: {
      fontSize: 16,
      fontWeight: "bold",
      color: colors.black50,
      backgroundColor: colors.lightBlue4,
      borderRight: borderStyle,

      "&:hover > div": {
        color: colors.black50,
      },
    },
  },
  cells: {
    style: {
      fontSize: 16,
      borderRight: borderStyle,

      "& > span": {
        fontWeight: 500,
        width: "100%",
      },
    },
  },
}

export const stripedStyles: any = {
  rows: {
    style: {
      ":nth-child(2n)": {
        backgroundColor: colors.lightBlue7,
      },
    },
  },
}

interface IDataTable {
  data: any[]
  columns: any[]
  customStyles?: any
  conditionalRowStyles?: any
  striped?: boolean
  noTableHead?: boolean
  selectableRows?: boolean
  selectableRowDisabled?: ((row: any) => boolean) | null | undefined
  onSelectedRowsChange?:
    | ((selected: {
        allSelected: boolean
        selectedCount: number
        selectedRows: ITrainee[]
      }) => void)
    | undefined
}

const DataTable: React.FC<IDataTable> = ({
  data,
  columns,
  customStyles = {},
  conditionalRowStyles = {},
  striped,
  noTableHead,
  selectableRows,
  onSelectedRowsChange,
  selectableRowDisabled,
}) => {
  const styles = _.merge(
    {},
    defaultCustomStyles,
    striped && stripedStyles,
    customStyles,
  )

  return (
    <DataTableComponent
      columns={columns}
      data={data}
      customStyles={styles}
      conditionalRowStyles={conditionalRowStyles}
      noTableHead={noTableHead}
      selectableRows={selectableRows}
      onSelectedRowsChange={onSelectedRowsChange}
      selectableRowDisabled={selectableRowDisabled}
    />
  )
}

export default DataTable
