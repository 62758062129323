import { IProbelyTarget } from "@src/types"
import * as Yup from "yup"

type editWebAppFormValidation = Yup.AnyObjectSchema
interface IValidationData {
  targetMap?: IProbelyTarget[]
}

export const useEditWebAppFormValidation = (
  validationData: IValidationData,
): editWebAppFormValidation => {
  return Yup.object({
    appName: Yup.string()
      .required("Please enter a name.")
      .default("")
      .max(255, "Max 255 symbols.")
      .test(
        "urlAlreadyExists",
        "This name already exists, choose another.",
        (value) =>
          !validationData?.targetMap?.find(
            (target) => target.name.toLowerCase() === value.toLowerCase(),
          ),
      ),
  })
}
