import { ButtonSmall } from "@src/Components/Button"
import Tooltip from "@src/Components/Tooltip"
import * as T from "@src/types"
import { PlanColors as IPlanColors } from "@zeguro/schema-validator/dist/types/coco/saasPlan/combined"
import * as React from "react"
import styled from "styled-components"

interface IplanCard {
  name?: string
  numOfCards?: number
  teamMembers?: number
  price?: number
  numWebApps?: number
  scanFrequency?: T.tProbelyScanFrequency[] | null
  planColors?: IPlanColors
  chargebeePlanId?: string
  planId?: string
  onSubscribe?: (
    chargebeePlanId: string,
    planId: string,
    couponCode?: string,
  ) => void
  onClickUpgrade: (
    planId: string,
    planName: string,
    price: number,
    chargebeePlanId?: string,
    couponCode?: string,
    discountedPrice?: number,
    planDiscountMessage?: string,
  ) => void
  isSubscribed?: boolean
  chargebeeCoupon?: T.IChargebeeCoupon
  monitoring?: boolean
  training?: boolean
  securityPolicies?: boolean
  cybermaturity?: boolean
  planActionType: "subscribe" | "current" | "downgrade" | "upgrade"
  planDiscountMessage?: string
}

const PlanCard = (props: IplanCard) => {
  const {
    name,
    numOfCards,
    teamMembers,
    price,
    numWebApps,
    planColors,
    onSubscribe,
    onClickUpgrade,
    planId,
    chargebeePlanId,
    planActionType,
    isSubscribed,
    chargebeeCoupon,
    monitoring,
    training,
    securityPolicies,
    scanFrequency,
    planDiscountMessage,
    cybermaturity,
  } = props

  let discountedPrice: number | undefined
  if (chargebeeCoupon && chargebeeCoupon.discountType && price) {
    if (
      chargebeeCoupon.discountType == "fixed_amount" &&
      chargebeeCoupon.discountAmount
    ) {
      discountedPrice = Math.round(price - chargebeeCoupon.discountAmount / 100)
    } else if (
      chargebeeCoupon.discountType == "percentage" &&
      chargebeeCoupon.discountPercentage
    ) {
      discountedPrice = Math.round(
        price - (chargebeeCoupon.discountPercentage / 100) * price,
      )
    }

    if (discountedPrice && discountedPrice < 0) {
      discountedPrice = 0
    }
  }

  return (
    <CardContent color={planColors?.border} numOfCards={numOfCards}>
      <HeadSection
        padding={planActionType === "current" ? "15px 0px 0px 0px" : undefined}
      >
        <Row size={28} color="#9c1e9d" bold>
          <PlanName>{name}</PlanName>
        </Row>
        {price === 0 ? (
          <Row size={18} color="#8C8C8C">
            (Default Plan)
          </Row>
        ) : discountedPrice !== undefined && discountedPrice >= 0 && price ? (
          <Row size={18} color="#8C8C8C" lineThrough>
            ${price}
          </Row>
        ) : null}
        {price === 0 ? (
          <Row size={28} color="#1C7F30" bold>
            Free
          </Row>
        ) : (
          <Row size={28} color="#9c1e9d" bold>
            <span>
              ${discountedPrice !== undefined ? discountedPrice : price}/mo
              <sup style={{ fontSize: "15px" }}>+</sup>
            </span>
          </Row>
        )}

        {planActionType === "current" ? (
          <div>
            <YourPlanRow backgroundColor={planColors?.button}>
              Your current plan
            </YourPlanRow>
            <YourPlanRow
              backgroundColor="transparent"
              padding="0px"
              justifyContent="space-between"
            >
              <TriangleTopRight color={planColors?.shadow} />
              <TriangleTopLeft color={planColors?.shadow} />
            </YourPlanRow>
          </div>
        ) : null}

        {planActionType === "downgrade" ? (
          <ButtonRow size={16} color="#9C1E9D" paddingTop="13px">
            Contact us
          </ButtonRow>
        ) : null}

        {planActionType === "subscribe" ? (
          <ButtonRow>
            <ButtonSmall
              width={158}
              onClick={() => {
                !isSubscribed &&
                  chargebeePlanId &&
                  planId &&
                  onSubscribe &&
                  onSubscribe(
                    chargebeePlanId,
                    planId,
                    chargebeeCoupon?.couponCode,
                  )
              }}
            >
              Subscribe
            </ButtonSmall>
          </ButtonRow>
        ) : null}

        {planActionType === "upgrade" ? (
          <ButtonRow>
            <ButtonSmall
              width={158}
              onClick={() => {
                planId &&
                  price &&
                  onClickUpgrade(
                    planId,
                    name || "",
                    price,
                    chargebeePlanId,
                    chargebeeCoupon?.couponCode,
                    discountedPrice,
                    planDiscountMessage,
                  )
              }}
            >
              Upgrade
            </ButtonSmall>
          </ButtonRow>
        ) : null}
      </HeadSection>

      {planDiscountMessage ? (
        <React.Fragment>
          <Section padding="5px 0px 15px 0px">
            <Row size={15}>
              <i
                className="fas fa-tag"
                style={{
                  color: "#2A6A37",
                  paddingRight: "8px",
                  fontSize: "18px",
                }}
              ></i>
              {planDiscountMessage}
            </Row>
          </Section>
          <Divider />
        </React.Fragment>
      ) : null}

      {cybermaturity ? (
        <React.Fragment>
          <Section>
            <Row size={18} bold>
              Cyber Maturity
            </Row>
            <Row size={15} color="#6f7379">
              Discover your organization&apos;s <br />
              security maturity level
            </Row>
          </Section>
        </React.Fragment>
      ) : null}

      {securityPolicies ? (
        <React.Fragment>
          <Divider />
          <Section>
            <Row size={18} bold>
              Security Policies
            </Row>
            <Row size={15} color="#6f7379">
              Customize templates <br />
              or add your own
            </Row>
          </Section>
        </React.Fragment>
      ) : null}

      {training ? (
        <React.Fragment>
          <Divider />
          <Section>
            <Row size={18} bold>
              Training
            </Row>
            <Row size={15} color="#6f7379">
              Up to {teamMembers} team members
            </Row>
          </Section>
        </React.Fragment>
      ) : null}

      {monitoring ? (
        <React.Fragment>
          <Divider />
          <Section>
            <Row size={18} bold>
              Scan {numWebApps} Website{numWebApps && numWebApps > 1 && "s"}
              &nbsp;
              <Tooltip
                id="plan-info-tooltip"
                textAlign="left"
                content={
                  <p>
                    Websites and websites (software like GSuite or Microsoft
                    365) can contain security vulnerabilities. Hackers can
                    exploit these to steal your company&apos;s data or disrupt
                    your operations. Scanning helps you find these weaknesses
                    before hackers do, so you can fix them.
                  </p>
                }
              />
              <i
                data-tip
                data-for="plan-info-tooltip"
                className="fas fa-info-circle"
                style={{
                  textIndent: 0,
                  color: "#adadad",
                }}
              />
            </Row>
            <Row size={15} color="#6f7379">
              {scanFrequency
                ? scanFrequency.includes("DAILY")
                  ? "Once a day"
                  : scanFrequency.includes("WEEKLY")
                  ? "Once a week"
                  : scanFrequency.includes("MONTHLY")
                  ? "Once a month"
                  : scanFrequency.includes("QUARTERLY")
                  ? "Once a quarter"
                  : null
                : null}
            </Row>
          </Section>
        </React.Fragment>
      ) : null}
      <React.Fragment>
        <Divider />
        <Section>
          <Row size={18} bold>
            Marketplace
          </Row>
          <Row size={15} color="#6f7379">
            Get discounts on Cyber Safety
            <br />
            world of tools and services
          </Row>
        </Section>
      </React.Fragment>
    </CardContent>
  )
}

export default PlanCard

const CardContent = styled.div<any>`
  flex: 1 0 195px;
  font-family: proxima-nova, sans-serif;
  max-width: 300px;
  padding: 15px 0px;
  border-radius: 4px;
  border: solid 2px ${(props: any) => props.color};
  border-top: solid 8px ${(props: any) => props.color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 5px 10px;
  max-height: 785px;
  min-width: 0px;

  @media (min-width: 458px) and (max-width: 642px),
    (min-width: 901px) and (max-width: 1016px) {
    flex: ${({ numOfCards }) => numOfCards === 3 && "0 1 605px"};
    max-width: ${({ numOfCards }) => numOfCards === 3 && "605px"};
    margin-bottom: 30px;
  }

  @media (min-width: 1017px) and (max-width: 1022px) {
    max-width: ${({ numOfCards }) => numOfCards === 4 && "295px"};
  }

  @media (min-width: 643px) and (max-width: 867px),
    (min-width: 1017px) and (max-width: 1221px) {
    flex: ${({ numOfCards }) => numOfCards === 4 && "0 1 405px"};
  }

  @media (max-width: 457px) {
    flex: 0 1 605px;
    max-width: 605px;
    margin-bottom: 30px;
  }
`

const Section = styled.div<any>`
  padding: ${(props: any) => props.padding || "15px 0px"};
  display: flex;
  width: 90%;
  flex-direction: column;
`

const HeadSection = styled(Section)`
  width: 100%;
  font-family: montserrat;
`

const Divider = styled.div`
  height: 1px;
  width: 80%;
  background-color: #d8d8d8;
`

const Row = styled.div<any>`
  font-size: ${(props: any) => props.size || 28}px;
  text-align: center;
  color: ${(props: any) => props.color || "#333333"};
  font-weight: ${(props: any) =>
    props.bold ? "bold" : props.fontWeight || "normal"};
  display: flex;
  background-color: ${(props: any) => props.backgroundColor || "#ffffff"};
  align-items: center;
  justify-content: center;
  padding: ${(props: any) => props.paddingTop || "3px"} 3px;
  text-decoration: ${(props: any) =>
    props.lineThrough ? "line-through" : undefined};
`

const ButtonRow = styled(Row)`
  margin-top: 8px;
`

const YourPlanRow = styled.div<any>`
  font-size: ${24}px;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  display: flex;
  background-color: ${(props: any) => props.backgroundColor || "#eaeaea"};
  align-items: center;
  justify-content: ${(props: any) => props.justifyContent || "center"};
  padding: ${(props: any) => props.padding || "8px 3px"};
  left: 50%;
  width: calc(100% + 20px);
  position: relative;
  transform: translateX(-50%);
`

const TriangleTopLeft = styled.div<any>`
  width: 0;
  height: 0;
  border-top: 15px solid ${(props: any) => props.color || "#eaeaea"};
  border-right: 10px solid transparent;
`

const TriangleTopRight = styled.div<any>`
  width: 0;
  height: 0;
  border-top: 15px solid ${(props: any) => props.color || "#eaeaea"};
  border-left: 10px solid transparent;
`

const PlanName = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`
