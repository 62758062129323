import * as React from "react"

import { Description, SubHeading } from "../styles"

export const EmptyStateCard = () => (
  <>
    <SubHeading>Set rules & guidelines for your team.</SubHeading>
    <Description>
      Provide governance for your organization. Meet legal and contractual
      obligations. Use our templates, or create custom policies.
    </Description>
  </>
)
