export const colors = {
  white: "#ffffff",
  black: "#000000",
  black80: "#333333",
  black50: "#212528",

  gray2: "#F4F2F2",
  gray25: "#F7F7F7",
  gray3: "#F9FAFB",
  gray5: "#D6D6D6",
  gray10: "#CBCBCB",
  gray15: "#ccc",
  gray20: "#ADADAD",
  gray30: "#9A9A9A",
  gray40: "#858585",
  gray42: "#808080",
  gray45: "#7A7A7A",
  gray50: "#767676",
  gray60: "#666666",
  gray70: "#555",

  blue5: "#39CEFF",
  blue6: "#87e2ff",
  blue10: "#79ACEA",
  splashingWave: "#39CEFF",
  unitedNationsBlue: "#66A1E8",
  navyBlue: "#0062d8",
  blue50: "#2c4d7f",
  darkBlue: "#26249E",
  lightBlue: "#F2F5F8",
  lightBlue2: "#F2F7FD",
  lightBlue3: "#d7f5ff",
  lightBlue4: "rgba(199, 219, 239, 0.5)",
  lightBlue5: "#cff3ff",
  lightBlue6: "#f9fdff",
  lightBlue7: "rgba(242, 245, 248, 1)",
  lightBlue8: "#daf3fe",
  lightBlue9: "#c2ebfd",
  blueGradient: "rgba(57, 206, 255, 0.1)",
  blueGradient2: "rgba(0, 98, 216, 0.05)",
  blueBorderGradient2: "rgba(0, 98, 216, 0.5)",
  purple: "#6563D3",

  red: "#FF002A",
  crimson: "#C64600",

  gold: "#D59230",
  orangeLight: "#EAA800",

  lightOrange: "#ffad66",
  orange: "#F39440",
  orange90: "#FF7800",

  violet: "#9C1E9D",
  violet50: "#CE90CE",

  green: "#006400",
  green30: "#87BC30",

  ghostWhite: "#f9fafb",

  primary: "#2185D0",
  primaryLight: "#4193D0",

  link: "#0062D9",

  pink: "#FF04A1",

  success: "#1C7F30",
  error: "#9A002A",
  warning: "#FFBC30",

  bitsight: {
    blue: "#2c4d7f",
    yellow: "#e8a951",
    red: "#b24053",
  },
}

// Picked 1499 as large since Monitoring card shows slick sider which needs minimum card width of 375px.
// And 3 card alignment works best at 1499 and above
export const media = {
  medium: "(max-width: 900px)",
  large: "(min-width: 1499px)",
}

// Latest media sizes
export const mediaWidth = {
  "x-small": "375px",
  small: "480px",
  medium: "760px",
  large: "900px", // 960 px is as per design but navbar is shown at 901px; Update once css is updated for all all application
  "x-large": "1200px",
  "xx-large": "1600px",
}

export const shadows = {
  panel: "0 0 4px rgba(0, 0, 0, 0.25)",
  hoveredPanel: "0 0 8px rgba(0, 0, 0, 0.25)",
}
