import { DateInfo } from "@src/Portal/Dashboard/TrainingCard/styles"
import { ITrainingDashboardData } from "@src/types"
import * as moment from "moment"
import * as React from "react"

import { Description, InfoMain, InfoName, InfoNumber } from "../../styles"

export const FilledStateCard = ({
  teamMembers = 0,
  isFinished = 0,
  isNotFinished = 0,
  lastUpdated,
}: Partial<ITrainingDashboardData>) => (
  <>
    <InfoMain hrHeight="88px">
      <InfoName>
        <InfoNumber>{teamMembers}</InfoNumber>
        <Description>
          Team
          <br />
          {teamMembers > 1 ? "Members" : "Member"}
          <br />
        </Description>
      </InfoName>
      <hr />
      <InfoName>
        <InfoNumber>{isFinished}</InfoNumber>
        <Description>Finished Assigned Modules</Description>
      </InfoName>
      <hr />
      <InfoName>
        <InfoNumber>{isNotFinished}</InfoNumber>
        <Description>With Incomplete Modules</Description>
      </InfoName>
    </InfoMain>
    <DateInfo>Updated {moment(lastUpdated).format("MMM D, YYYY")}</DateInfo>
  </>
)
