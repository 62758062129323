import { tState } from "@src/Model/Model"
import SamplePdf from "@src/Portal/Ratings/SamplePdf"
import { tCompanyProfile } from "@src/types"
import * as React from "react"
import { shallowEqual, useSelector } from "react-redux"

import DownloadDetailedReport from "../DownloadDetailedReport"
import DownloadRatingReport from "../DownloadRatingReport"
import LineChart from "../LineChart"
import {
  ButtonsRow,
  ButtonWrap,
  Content,
  Description,
  DescriptionTop,
  LeftContent,
  NoDataAvailable,
  RatingHead,
  RatingNumber,
  RatingNumberWrap,
  RatingSubTitle,
  RatingText,
  RatingTitle,
  RightContent,
  TrendTitle,
} from "./styles"

interface IProps {
  companyProfile: tCompanyProfile
}

const description = `
  Reports Panel enables you to download the most important reports for
  Your business. Click on the report you want to see to get a full picture of
  your security rating and performance.
`

const snapshotDesc = "One page summary of your security rating"
const detailedDesc =
  "Full report of your security rating and industry comparison"

const TabReports: React.FC<IProps> = ({ companyProfile }) => {
  const { companyUrl, companyName } = companyProfile || {}
  const ratingsData = useSelector(
    (state: tState) => state.ratings.ratingsData?.data,
    shallowEqual,
  )
  const currentRating = ratingsData?.ratings?.[0]
  const hasData = companyUrl && ratingsData

  return (
    <Content>
      <LeftContent>
        <DescriptionTop>{description}</DescriptionTop>

        <ButtonsRow>
          <ButtonWrap>
            <DownloadRatingReport title="Snapshot Report" />
          </ButtonWrap>
          <Description>{snapshotDesc}</Description>
        </ButtonsRow>
        <ButtonsRow>
          <ButtonWrap>
            <DownloadDetailedReport title="Detailed Report" />
          </ButtonWrap>
          <Description>{detailedDesc}</Description>
        </ButtonsRow>

        <TrendTitle>
          {hasData ? "Your attack surface trend" : "Sample security trend"}
        </TrendTitle>
        {hasData ? (
          <LineChart />
        ) : (
          <NoDataAvailable>
            No data available. Please enter information about your company to
            see your security trend.
          </NoDataAvailable>
        )}
      </LeftContent>

      <RightContent>
        <RatingHead>
          <RatingTitle>Rating Details for {companyName}</RatingTitle>
          <RatingNumberWrap companyUrl={hasData}>
            <RatingNumber color={currentRating?.rating_color}>
              {currentRating?.rating || "490"}
            </RatingNumber>
            <RatingText>Security Rating</RatingText>
          </RatingNumberWrap>
        </RatingHead>
        <RatingSubTitle>Sample Detailed Security Rating Report</RatingSubTitle>
        <SamplePdf
          pdfUrl={
            hasData
              ? "images/bitsight/sampleWithUrl.pdf"
              : "/images/bitsight/sample.pdf"
          }
        />
      </RightContent>
    </Content>
  )
}

export default TabReports
