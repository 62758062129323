import C from "@src/Controller"
import { Store } from "redux"

import * as T from "../types"

class Chargebee {
  private store: Store
  public window: T.tExtWindow & Window = window

  constructor(store: Store) {
    this.store = store
  }

  loadScript() {
    const state = this.store.getState()
    this.window.Chargebee.init({
      site: state.portal.config.chargebeeSite,
      isItemsModel: true,
    })
  }

  handleSubscribe = (
    chargebeePlanId: string,
    planId: string,
    couponCode?: string,
  ) => {
    const cbInstance = this.window.Chargebee.getInstance()
    const cart = cbInstance.getCart()
    const product = cbInstance.initializeProduct(chargebeePlanId, 1)
    couponCode ? product.addCoupon(couponCode) : null
    const state = this.store.getState()
    const customer = {
      email: "",
      first_name: state.portal.userProfile.firstName || "",
      last_name: state.portal.userProfile.lastName || "",
      billing_address: {
        first_name: state.portal.userProfile.firstName || "",
        last_name: state.portal.userProfile.lastName || "",
        company: "",
        phone: "",
        line1: "",
        line2: "",
      },
      cf_zeguroorgid: state.portal.orgId,
    }

    cbInstance.setCheckoutCallbacks(function () {
      return {
        success: () => {
          C.Plans.subscribePlan(planId)
        },
      }
    })

    cart.setCustomer(customer)
    cart.replaceProduct(product)
    cart.proceedToCheckout()
  }
}
export default Chargebee
