import { ButtonSmall, ButtonTertiary } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import ConfirmationModal from "@src/Components/ConfirmationModal"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import * as React from "react"
import { useSelector } from "react-redux"

import FormAdd from "../FormAdd"
import { Dialog, IconUser } from "./styles"

interface IModalAdd {
  numberOfSeats: Record<string, number>
  title?: string
  modalIsOpen?: boolean
  isDisabled?: boolean
}

interface IData {
  firstName: string
  lastName: string
  email: string
  noGapEnrollment: boolean
  withError?: boolean
}

const initData = {
  firstName: "",
  lastName: "",
  email: "",
  noGapEnrollment: false,
  withError: false,
}

const ModalAdd: React.FC<IModalAdd> = ({
  numberOfSeats,
  title,
  modalIsOpen,
  isDisabled,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isChanged, setIsChanged] = React.useState(false)
  const [data, setData] = React.useState<IData[]>([initData])
  const { isDesktop } = usePortalSize()
  const { emailDomain } = useSelector((state: tState) => state.brand)

  const onOpenModal = () => {
    setIsOpen(true)
  }

  const onCloseModal = () => {
    let changed = false
    data.forEach((item) => {
      const { firstName, lastName, email } = item
      if (firstName || lastName || email) {
        changed = true
      }
    })
    if (changed) {
      setIsChanged(true)
    } else {
      setIsOpen(false)
    }
  }

  const onCloseCancel = () => {
    setIsChanged(false)
  }

  const onCloseConfirm = () => {
    setIsOpen(false)
    setIsChanged(false)
    setData([initData])
  }

  React.useEffect(() => {
    if (modalIsOpen) {
      onOpenModal()
    }
  }, [modalIsOpen])

  return (
    <div>
      <span data-tip data-for={`add-team-members-training-tooltip`}>
        {isDesktop ? (
          <ButtonSmall
            id="add-team-members-training-button"
            onClick={onOpenModal}
            padding="5 15"
            height="auto"
            width={title ? 300 : 211}
            disabled={isDisabled}
          >
            <IconUser className="fas fa-users" />
            {title || "Add Team Members"}
          </ButtonSmall>
        ) : (
          <ButtonTertiary
            id="add-team-members-training-button"
            onClick={onOpenModal}
            noBorder
          >
            <IconUser className="fas fa-plus-square" />
            {title || "Add Team Members"}
          </ButtonTertiary>
        )}
      </span>
      {isDisabled && (
        <Tooltip
          id={`add-team-members-training-tooltip`}
          content={
            <p>
              You&apos;ve reached the maximum of {numberOfSeats.maximumAllowed}{" "}
              trainees.
              <br />
              <>
                Need to add more trainees? <br />
                <>
                  Contact us at&nbsp;
                  <a
                    style={{ color: "#0062d9" }}
                    href={`mailto:support@${emailDomain}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    support@{emailDomain}
                  </a>
                  .
                </>
                <br />
              </>
            </p>
          }
          width="390px"
          clickable
        />
      )}
      <Dialog
        title="Add Team Members to Training"
        className="zeg-dialog wide"
        isOpen={isOpen}
        onClose={onCloseModal}
        content={
          <FormAdd
            numberOfSeats={numberOfSeats}
            data={data}
            setData={setData}
          />
        }
      />
      <ConfirmationModal
        isOpen={isChanged}
        title="Add Team Members to Training"
        text="The trainees have not been added yet. Are you sure you want to exit?"
        type="danger"
        onClose={onCloseCancel}
        onConfirm={onCloseConfirm}
      />
    </div>
  )
}

export default ModalAdd
