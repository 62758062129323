import { ButtonSecondary } from "@src/Components/Button"
import { FaIcon } from "@src/Components/FaIcon"
import { Section } from "@src/Components/StyledUtils"
import * as React from "react"
import { useSelector } from "react-redux"
import { tState } from "@src/Model/Model"
import { StartAssessmentPanel } from "../../../styles"

const EmptyState = () => {
  const { helpCenter } = useSelector((state: tState) => state.brand)
  const generalAlertsUrl =
    `https://${helpCenter}/hc/en-us/articles/21179461949591-General-Alerts-for-Cybersecurity-Vulnerabilities`

  return (
    <Section w="100%" flexGrow="1" flex="start start column">
      <StartAssessmentPanel>
        <p>
          <FaIcon className="far fa-info-circle" /> General alerts affect many
          organizations, and are not specific to yours. We keep a running list
          of these alerts for you, along with recommendations for remediating
          them.
        </p>
      </StartAssessmentPanel>
      <ButtonSecondary
        margin="30 0 0 0"
        width={210}
        onClick={() => window.open(generalAlertsUrl, "_blank")}
      >
        View General Alerts <FaIcon className="far fa-external-link-square" />
      </ButtonSecondary>
    </Section>
  )
}

export default EmptyState
