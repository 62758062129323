import { ButtonCancel, ButtonSmall } from "@src/Components/Button"
import { supportMessage } from "@src/Components/Common/Toast"
import TextField from "@src/Components/Form/TextField"
import C from "@src/Controller"
import { usePersonalDetailsValidation } from "@src/Portal/Settings/validation"
import { useFormik } from "formik"
import * as React from "react"
import { useSelector } from "react-redux"

import { ButtonWrapper, Form } from "./ManageAdmins/styles"
import { SpinnerStyled } from "./styles"

interface IProps {
  onClose: () => void
}
interface onInviteUserForm {
  email: string
  firstName: string
  lastName: string
  jobTitle: string
}

const formInitial: onInviteUserForm = {
  firstName: "",
  lastName: "",
  email: "",
  jobTitle: "",
}

export const InviteUserForm = ({ onClose }: IProps) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const personalDetailsScheme = usePersonalDetailsValidation()
  const { emailDomain } = useSelector((state: any) => state.brand)
  const formik = useFormik<onInviteUserForm>({
    initialValues: formInitial,
    enableReinitialize: true,
    validationSchema: personalDetailsScheme,
    onSubmit: ({ firstName, lastName, email, jobTitle }) => {
      setIsLoading(true)
      const trimmedEmail = email.trim()
      formik.setFieldValue("email", trimmedEmail)
      C.Portal.inviteUser(
        trimmedEmail,
        firstName,
        lastName,
        jobTitle,
        (res: { success: boolean; error: string }) => {
          setIsLoading(false)
          if (!res.success) {
            return C.toastNotification(
              supportMessage("Invitation failed.", emailDomain),
              "danger",
            )
          }
          onClose()
          C.toastNotification(
            `${trimmedEmail} successfully invited.`,
            "success",
            5000,
          )
        },
      )
    },
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextField
        label="First Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName || ""}
        name="firstName"
        error={Boolean(formik.errors.firstName && formik.touched.firstName)}
        errorMsg={formik.errors.firstName}
        margin="0 0 15px 0"
      />
      <TextField
        label="Last Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastName || ""}
        name="lastName"
        error={Boolean(formik.errors.lastName && formik.touched.lastName)}
        errorMsg={formik.errors.lastName}
        margin="0 0 15px 0"
      />
      <TextField
        label="Work Email Address"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email || ""}
        name="email"
        error={Boolean(formik.errors.email && formik.touched.email)}
        errorMsg={formik.errors.email}
        margin="0 0 15px 0"
      />
      <TextField
        label="Job Title (Optional)"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.jobTitle || ""}
        name="jobTitle"
        error={Boolean(formik.errors.jobTitle && formik.touched.jobTitle)}
        errorMsg={formik.errors.jobTitle}
        margin="0 0 15px 0"
      />
      <ButtonWrapper>
        <ButtonCancel
          width={100}
          onClick={onClose}
          id="settings-edit-user-cancel"
          type="button"
        >
          Cancel
        </ButtonCancel>
        <ButtonSmall
          width={150}
          type="submit"
          disabled={!(formik.isValid && formik.dirty)}
          id="settings-invite-admin-save"
        >
          {isLoading ? <SpinnerStyled size={25} /> : "Invite Admin"}
        </ButtonSmall>
      </ButtonWrapper>
    </Form>
  )
}
