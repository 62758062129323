import DataTable from "@src/Components/DataTable"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import { ISetCurrentTrainee } from "@src/Portal/Training/ReportCard"
import { scoreToGrade } from "@src/Portal/Training/utils"
import { colors } from "@src/theme"
import {
  CourseResults as ICourseResults,
  TraineeData as ITrainee,
} from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { GRADE_COLOR, TOOLTIP_INFO_COURSES } from "../../constants"
import CustomNameHead from "../CustomNameHead"
import { DateTime } from "../styles"
import {
  Badge,
  conditionalRowStyles,
  customStyles,
  Grade,
  GradeText,
  HeadCardDetails,
  HeadContainer,
  HeadSubTitle,
  HeadTitle,
  Image,
  TeamAveragePanel,
} from "./styles"

const defaultCourseIcon = "images/training/default_course.svg"

interface ICardDetailsProps {
  courseResultsList: ITrainee[]
  date: string
  currentCourse: ICourseResults & { subjectName?: string }
  setCurrentTrainee: (arg: ISetCurrentTrainee) => void
}

const CourseTab: React.FC<ICardDetailsProps> = ({
  courseResultsList,
  date,
  currentCourse,
  setCurrentTrainee,
}) => {
  const history = useHistory()
  const courseMap = useSelector(
    (state: tState) => state.training.trainingData.courseMap,
  )
  const courseKey = currentCourse?.name || ""
  const averageScore = currentCourse?.averageScore || null
  const isCourseWithModules = currentCourse.difficulty < 5
  const isGap = currentCourse.difficulty === 1
  const iconSrc: string = currentCourse?.imageUrl || defaultCourseIcon
  const courseKeyName =
    courseKey === "Beginner" ? "Knowledge Assessment" : courseKey
  const courseColumnKey =
    courseKey === "Knowledge Assessment" ? "Beginner" : courseKey
  const enrolledTrainees = courseResultsList
    .filter(
      ({ courseResults }) =>
        courseResults?.find((cR) => cR.name === courseKeyName),
    )
    .sort((tr1, tr2) =>
      tr1.lastName.toUpperCase() > tr2.lastName.toUpperCase() ? 1 : -1,
    )
  const avgGradeGap = courseMap.find((c) => c.difficulty === 1)?.averageScore
  const gapGrade = avgGradeGap ? scoreToGrade(avgGradeGap) : "PENDING"
  const avgBeginnerGradeGap = courseMap.find((c) => c.difficulty === 2)
    ?.averageScore
  const beginnerGrade = avgBeginnerGradeGap
    ? scoreToGrade(avgBeginnerGradeGap)
    : "PENDING"

  const courseAvgGrade =
    typeof averageScore === "number" ? scoreToGrade(averageScore) : "PENDING"
  const goToTraineeTab = (
    name: string,
    lastName: string,
    traineeId: string,
  ) => {
    setCurrentTrainee({ name, lastName, traineeId })
  }
  const completedByTrainee = (courses: ICourseResults[]) => {
    const topics = courses.find((c) => c.name === courseColumnKey)?.topics || []
    return topics.filter((c) => c?.score)
  }

  const getTraineeGrade = (email: string, isGapCourse: boolean = false) => {
    const trainee = enrolledTrainees.find((t) => t.email === email)
    const course = trainee?.courseResults?.find(
      (c) =>
        c.name === (isGapCourse ? "Knowledge Assessment" : courseColumnKey),
    )
    if (typeof course?.averageScore === "number") {
      return scoreToGrade(course.averageScore)
    }
    return undefined
  }

  const renderGradeTooltip = (
    tooltipName: string,
    courses: ICourseResults[],
  ) => (
    <Tooltip
      width="232px"
      id={tooltipName}
      content={
        <p>
          <span>
            {completedByTrainee(courses).length} of 12 modules completed
          </span>
        </p>
      }
    />
  )

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: false,
      cell: ({
        firstName,
        lastName,
        email,
        traineeId,
        name,
      }: ITrainee & { name: string }) => (
        <Link
          onClick={() => {
            name !== "Team Average" &&
              goToTraineeTab(firstName, lastName, traineeId)
          }}
        >
          {name || `${firstName} ${lastName}` || email}
        </Link>
      ),
    },
    ...(isGap
      ? [
          {
            name: <CustomNameHead name="Knowledge Assessment" />,
            selector: "traineeAverageScores",
            sortable: false,
            cell: ({ email }: ITrainee, index: number) => {
              return (
                <Section
                  flex="center center"
                  data-tip
                  data-for={"Knowledge Assessment-tooltip" + index}
                >
                  {getTraineeGrade(email, true) ? (
                    <GradeText>{getTraineeGrade(email, true)}</GradeText>
                  ) : (
                    <Badge>
                      {getTraineeGrade(email, true) ? "Pending" : "Assigned"}
                    </Badge>
                  )}
                </Section>
              )
            },
          },
        ]
      : []),
    {
      name: <CustomNameHead name={isGap ? courseColumnKey : "Grade"} />,
      selector: "traineeAverageScores",
      sortable: false,
      cell: ({ firstName, lastName, email, courseResults }: ITrainee) => (
        <Section
          flex="center center"
          data-tip
          data-for={`${firstName}-${lastName}-tooltip`}
        >
          {getTraineeGrade(email) ? (
            <>
              <GradeText isGrade={isCourseWithModules}>
                {getTraineeGrade(email)}
              </GradeText>
              {isCourseWithModules &&
                renderGradeTooltip(
                  `${firstName}-${lastName}-tooltip`,
                  courseResults,
                )}
            </>
          ) : (
            <Badge>Enrolled</Badge>
          )}
        </Section>
      ),
    },
  ]

  const courseTooltip = (courseName: string) => (
    <>
      {TOOLTIP_INFO_COURSES[courseName].map((text, i) => (
        <p key={i}>
          {text}
          {i < TOOLTIP_INFO_COURSES[courseName].length - 1 && (
            <>
              <br />
              <br />
            </>
          )}
        </p>
      ))}
    </>
  )

  return (
    <>
      <HeadContainer>
        <Link
          bold
          color={colors.darkBlue}
          onClick={() => history.push("/training/reportcards")}
        >
          <FaIcon fontSize="13px" className="fas fa-chevron-left" />
          &nbsp;Back
        </Link>
      </HeadContainer>
      <HeadContainer>
        <HeadCardDetails>
          <Image src={iconSrc} alt="card type" />
          <HeadTitle>
            {currentCourse?.subject && currentCourse.subject !== "N/A" && (
              <HeadSubTitle>
                {currentCourse.subjectName || currentCourse.subject}
              </HeadSubTitle>
            )}
            {isGap ? "Knowledge Assessment + Beginner" : currentCourse?.name}
            &nbsp;
            {TOOLTIP_INFO_COURSES[courseKeyName] && (
              <>
                <FaIcon
                  data-tip
                  data-for={`${courseKeyName}-course-tooltip`}
                  className="fas fa-info-circle"
                  color={colors.gray40}
                  fontSize="16px"
                />
                <Tooltip
                  width="600px"
                  id={`${courseKeyName}-course-tooltip`}
                  content={courseTooltip(courseKeyName)}
                />
              </>
            )}
          </HeadTitle>
        </HeadCardDetails>
      </HeadContainer>
      <TeamAveragePanel>
        <Section
          fontSize="18px"
          fontWeight="700"
          fontColor={colors.darkBlue}
          flexBasis="120px"
          flexShrink="0"
          margin="0 0 4"
        >
          Team Average
        </Section>
        {isGap ? (
          <Section flex="center" gap="55px" flexGrow="1">
            <Section flex="center center column" gap="12px">
              {gapGrade === "PENDING" ? (
                <Badge>Pending</Badge>
              ) : (
                <Grade color={GRADE_COLOR[gapGrade]}>{gapGrade}</Grade>
              )}
              Knowledge Assessment
            </Section>
            <Section flex="center center column" gap="12px">
              {beginnerGrade === "PENDING" ? (
                <Badge>Pending</Badge>
              ) : (
                <Grade color={GRADE_COLOR[beginnerGrade]}>
                  {beginnerGrade}
                </Grade>
              )}
              Beginner
            </Section>
          </Section>
        ) : (
          <Section flex="center center column" gap="12px">
            {courseAvgGrade === "PENDING" ? (
              <Badge>Pending</Badge>
            ) : (
              <Grade color={GRADE_COLOR[courseAvgGrade]}>
                {courseAvgGrade}
              </Grade>
            )}
          </Section>
        )}
        <Section flexBasis="120px" flexShrink="0" />
      </TeamAveragePanel>
      <DataTable
        columns={columns}
        data={enrolledTrainees}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        striped
      />
      <Section padding="8 0">
        <DateTime>Updated {date}</DateTime>
      </Section>
    </>
  )
}

export default React.memo(CourseTab)
