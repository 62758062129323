import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Section } from "@src/Components/StyledUtils"
import { colors } from "@src/theme"
import { OrgGoals as IOrgGoalsSchema } from "@zeguro/schema-validator/dist/types/coco/core/combined"
import * as React from "react"

import GoalOption from "./GoalOption"

interface IOrgGoals {
  onGoalsChange: (goals: IOrgGoalsSchema) => void
}

export interface IGoalsOption {
  key: string
  label: string | JSX.Element
  icon: string
  labelMobile?: string | JSX.Element
  tooltip?: string | JSX.Element
}

const goalsOptions: IGoalsOption[] = [
  {
    key: "meetContractualObligations",
    label: (
      <>
        Meet <b>contractual obligations</b>
      </>
    ),
    icon: "list",
  },
  {
    key: "meetRegulatoryRequirements",
    label: (
      <>
        Meet <b>regulatory requirements</b>
      </>
    ),
    icon: "gavel",
  },
  {
    key: "proactivelyProtectMyOrganization",
    label: (
      <>
        Proactively <b>protect my organization</b>
      </>
    ),
    labelMobile: (
      <>
        <b>Protect</b> my organization
      </>
    ),
    icon: "shield",
  },
  {
    key: "learnMoreAboutCyberSecurity",
    label: (
      <>
        <b>Learn</b> more about <b>cybersecurity</b>
      </>
    ),
    icon: "user-graduate",
  },
  {
    key: "hasOtherGoals",
    label: <>Other</>,
    icon: "wrench",
  },
]

const OrgGoals = ({ onGoalsChange }: IOrgGoals) => {
  const { isDesktop } = usePortalSize()
  const [orgGoals, setOrgGoals] = React.useState<{
    [key: string]: boolean | string
  }>({})

  const onSelectOption = (option: string) => {
    setOrgGoals((prevOptions) => {
      const options = { ...prevOptions }
      if (options[option]) {
        delete options[option]
        if (option === "hasOtherGoals") {
          delete options.otherGoals
        }
      } else {
        options[option] = true
      }
      onGoalsChange(options)
      return options
    })
  }

  const onChangeOtherGoal = (val: string) => {
    setOrgGoals((prevOptions) => {
      const options = { ...prevOptions }
      if (options.otherGoals && !val) {
        delete options.otherGoals
      }
      if (val) {
        options.otherGoals = val
        if (!options.hasOtherGoals) {
          options.hasOtherGoals = true
        }
      }
      onGoalsChange(options)
      return options
    })
  }

  const mainGoalsOptions = goalsOptions.slice(0, -1)
  const otherGoalOption = goalsOptions[goalsOptions.length - 1]

  return (
    <Section flex="flex-start stretch column" align="start" margin="0 0 16">
      <Section
        fontSize="32px"
        fontWeight="700"
        fontFamily="montserrat"
        fontColor={colors.darkBlue}
        margin="24 0 8"
      >
        Your Goals
      </Section>
      <Section
        fontSize={isDesktop ? "32px" : "18px"}
        fontWeight="500"
        fontFamily="montserrat"
        margin="0 0 28"
      >
        What do you want help with?
      </Section>
      {isDesktop && (
        <Section flex="space-between flex-end" margin="0 0 24">
          <Section fontSize="24px">I want to:</Section>
          <Section fontSize="18px">Select all that apply (optional)</Section>
        </Section>
      )}
      {mainGoalsOptions.map((goal) => (
        <GoalOption
          key={goal.key}
          goal={goal}
          isSelected={!!orgGoals[goal.key]}
          onSelect={() => onSelectOption(goal.key)}
        />
      ))}
      <GoalOption
        key={otherGoalOption.key}
        goal={otherGoalOption}
        isSelected={!!orgGoals[otherGoalOption.key]}
        onSelect={() => onSelectOption(otherGoalOption.key)}
        withText
        onChangeText={onChangeOtherGoal}
      />
    </Section>
  )
}

export default OrgGoals
