export const SET_PORTAL_MENU = "SET_PORTAL_MENU"
export const SET_HANDLER = "SET_HANDLER"
export const REMOVE_HANDLER = "REMOVE_HANDLER"
export const JUST_ACTION = "JUST_ACTION"
export const SET_ENVIRONMENT = "SET_ENVIRONMENT"
export const SET_COMPANY_PROFILE = "SET_COMPANY_PROFILE"
export const SET_USER_PROFILE = "SET_USER_PROFILE"
export const SET_SIGNUP_DATA = "SET_SIGNUP_DATA"
export const SET_USER_EMAIL = "SET_USER_EMAIL"
export const SET_AUTHENTICATED = "SET_AUTHENTICATED"
export const SET_PRODUCTION = "SET_PRODUCTION"
export const SET_LEGAL_DISCLAIMER = "SET_LEGAL_DISCLAIMER"
export const SET_ACCOUNTS_PAGE_MODE = "SET_ACCOUNTS_PAGE_MODE"
export const SET_PORTAL_ENVIRONMENT = "SET_PORTAL_ENVIRONMENT"
export const SET_CONFIG = "SET_CONFIG"
export const SET_DASHBOARD_ACTIVITY = "SET_DASHBOARD_ACTIVITY"
export const SET_DASHBOARD_MONITORING = "SET_DASHBOARD_MONITORING"
export const SET_QUOTE_TO_PURCHASE = "SET_QUOTE_TO_PURCHASE"

export const COMPLIANCE_REQ_LIST = "COMPLIANCE_REQ_LIST"
export const COMPLIANCE_REQ_POLICY = "COMPLIANCE_REQ_POLICY"
export const COMPLIANCE_REQ_HISTORY = "COMPLIANCE_REQ_HISTORY"
export const COMPLIANCE_REQ_VERSION = "COMPLIANCE_REQ_VERSION"
export const COMPLIANCE_REQ_SAVE_POLICY = "COMPLIANCE_REQ_SAVE_POLICY"
export const COMPLIANCE_REQ_DELETE_POLICY = "COMPLIANCE_REQ_DELETE_POLICY"

export const COMPLIANCE_REQ_STATUS = "COMPLIANCE_REQ_STATUS"

export const UPDATE_POLICY = "UPDATE_POLICY"
export const UPDATE_POLICY_TEXT = "UPDATE_POLICY_TEXT"
export const SELECT_MENU = "SELECT_MENU"
export const POLICY_SORT_DIR = "POLICY_SORT_DIR"
export const CHANGE_POLICY_MODE = "CHANGE_POLICY_MODE"
export const CHANGE_DRAFT_MODE = "CHANGE_DRAFT_MODE"
export const SHOW_CUSTOM_DIALOG = "SHOW_CUSTOM_DIALOG"
export const UPDATE_CUSTOM_VALUES = "UPDATE_CUSTOM_PARAM"
export const CANCEL_CUSTOM_FORM = "CANCEL_CUSTOM_FORM"
export const NOTIFY_APPROVER = "NOTIFY_APPROVER"
export const OPEN_POLICY_HISTORY = "OPEN_POLICY_HISTORY"
export const UPDATE_POLICY_LIST = "UPDATE_POLICY_LIST"
export const UPDATE_OUT_OF_BOX_LIST = "UPDATE_OUT_OF_BOX_LIST"
export const UPDATE_STORAGE_LIST = "UPDATE_STORAGE_LIST"

export const SET_TRAINING_STATUS = "SET_TRAINING_STATUS"
export const SET_TRAINING_TARGET = "SET_TRAINING_TARGET"
export const SET_TRAINING_DATA = "SET_TRAINING_DATA"

export const SET_RATINGS_STATUS = "SET_RATINGS_STATUS"
export const SET_RATINGS_DATA = "SET_RATINGS_DATA"

export const SET_USER_LIST = "GET_USER_LIST"
export const SET_USER_INVITE_LIST = "GET_USER_INVITE_LIST"
export const SET_USER_LIST_ERROR = "SET_USER_LIST_ERROR"
export const SET_USER_INVITE_LIST_ERROR = "SET_USER_INVITE_LIST_ERROR"

export const SET_PROBELY_TARGET_LIST = "SET_PROBELY_TARGET_LIST"
export const SET_PROBELY_SCAN_MAP = "SET_PROBELY_SCAN_MAP"
export const SET_PROBELY_SCHEDULE_MAP = "SET_PROBELY_SCHEDULE_MAP"

export const SET_VULNERABILITY_REPORT_STATUS = "SET_VULNERABILITY_REPORT_STATUS"

export const SET_SAAS_PLANS = "SET_SAAS_PLANS"
export const SET_SAAS_PLANS_STATUS = "SET_SAAS_PLANS_STATUS"
export const SET_ELIGIBLE_PLANS = "SET_ELIGIBLE_PLANS"
export const SET_CHARGEBEE_SUBSCRIPTION_STATUS =
  "SET_CHARGEBEE_SUBSCRIPTION_STATUS"
export const SET_ELIGIBLE_PLANS_STATUS = "SET_ELIGIBLE_PLANS_STATUS"
export const SET_UPDATE_PLANS_STATUS = "SET_UPDATE_PLANS_STATUS"
export const SET_UI_RULES = "SET_UI_RULES"

export const CLOSE_INTRO_DIALOG = "CLOSE_INTRO_DIALOG"
export const OPEN_INTRO_DIALOG = "OPEN_INTRO_DIALOG"
export const OPEN_FEATURE_MODAL = "OPEN_FEATURE_MODAL"
export const CHANGE_INTRO_PAGE = "CHANGE_INTRO_PAGE"

export const SET_MAGIC_LINK_SIGNUP = "SET_MAGIC_LINK_SIGNUP"

export const SET_MARKETPLACE_STATUS = "SET_MARKETPLACE_STATUS"
export const SET_MARKETPLACE_JSON = "SET_MARKETPLACE_JSON"

export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA"
export const SET_ONBOARDING_FORM = "SET_ONBOARDING_FORM"
