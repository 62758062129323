import { FaIcon } from "@src/Components/FaIcon"
import { Text } from "@src/Components/Text"
import { colors, media, mediaWidth } from "@src/theme"
import styled from "styled-components"

export const SectionColor = `#39Ceff`

export const Img = styled.img`
  height: 27px;
  margin-right: 15px;
  @media (min-width: ${mediaWidth["x-large"]}) {
    height: 57px;
  }
`
export const Heading = styled.h3`
  color: ${colors.violet};
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  font-family: Montserrat, sans-serif;
  @media (min-width: ${mediaWidth["x-large"]}) {
    padding: 0 4%;
    padding-top: 20px;
  }
`
export const TabItem = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
  i {
    margin-right: 5px;
  }
  @media ${media.medium} {
    img,
    i {
      display: none;
    }
  }
`
export const Section = styled.section`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  @media (min-width: ${mediaWidth.small}) {
    padding: 25px 30px 20px;
    border: 1px solid ${SectionColor};
    border-radius: 8px;
  }
`

export const SectionHeading = styled.h4`
  margin-top: 5px;
  color: ${colors.violet};
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`

export const SectionContent = styled.p`
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  > ul {
    padding-left: 0px;
    > li {
      margin-bottom: 10px;
    }
  }
`

export const FaIconCustom = styled(FaIcon)`
  max-width: 50px;
  width: 100%;
  font-size: 28px;
  font-weight: 900;
  color: ${SectionColor};
  @media (min-width: ${mediaWidth["x-large"]}) {
    font-size: 42px;
    max-width: 70px;
  }
`
export const StartAssessmentPanel = styled.div`
  font-family: proxima-nova, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  background: ${colors.lightBlue2};
  color: ${colors.black50};
  border: 1px solid rgba(0, 98, 217, 0.6);
  box-sizing: border-box;
  border-radius: 6px;
`
export const TextStyled = styled(Text)`
  font-family: proxima-nova, sans-serif;
  margin: ${({ margin }) => margin};
`
