import * as T from "@src/types"
import * as React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"

interface IPrivateRoute {
  component: React.ElementType
  isAuthenticated: boolean
  companyProfile: T.tCompanyProfile
  webDomain: string
}

export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  location,
  companyProfile,
  webDomain,
  ...rest
}: IPrivateRoute & RouteProps) => {
  if (!isAuthenticated) {
    const redirectUrl = window.location.href
    const pathname = window.location.pathname
    if (pathname !== "/") {
      document.cookie = `redirectURL=${redirectUrl}; path=/`
    }
    return (
      <Redirect
        to={{
          pathname: "/signin",
          state: { from: location },
        }}
      />
    )
  }

  const host = window.location.host
  if (!host.includes(webDomain) && !host.includes("localhost")) {
    return (
      <Redirect
        to={{ pathname: "/incorrect-url", state: { from: location } }}
      />
    )
  }

  if (!companyProfile) {
    return (
      <Redirect to={{ pathname: "/onboarding", state: { from: location } }} />
    )
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />
}
