import { colors } from "@src/theme"
import styled from "styled-components"

interface ILink {
  color?: string
  disabled?: boolean
  isUnderline?: boolean
  margin?: string
  padding?: string
  fontSize?: string
  bold?: boolean
  lineHeight?: string
}

export const StyledLink = styled.a<ILink>`
  color: ${({ color }) => color || colors.link};
  opacity: ${({ disabled }) => disabled && "0.7"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed!important" : "pointer")};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ bold }) => bold && "700"};
  line-height: ${({ lineHeight }) => lineHeight};
  i {
    cursor: ${({ disabled }) =>
      disabled ? "not-allowed!important" : "pointer"};
  }
  &:hover {
    font-weight: ${({ bold }) => bold && "700"};
    color: ${({ isUnderline }) => (isUnderline ? colors.link : colors.violet)};
    opacity: ${({ disabled }) => disabled && "0.7"};
    text-decoration: ${({ isUnderline }) =>
      isUnderline ? "underline" : "none"};
  }
`

export const LinkIcon = styled.i`
  font-size: 14px;
  margin-left: 6px;
`
