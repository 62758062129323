import styled from "styled-components"

import { css, hidden, margin, padding } from "./StyledUtils"

interface IButtonDashboard {
  disabled?: boolean
  backgroundColor?: string
  width?: string
  fontSize?: string
  hidden?: boolean
  margin?: string
}

export const ButtonSmall = styled.button<any>`
  color: #ffffff;
  background-color: ${(props: any) => {
    if (!props.disabled) {
      return props.inverse ? "#26249f" : "#ff04a1"
    } else {
      return props.inverse ? "rgba(38, 36, 158, 0.4)" : "rgba(255, 4, 161, 0.4)"
    }
  }};
  border: 0px;
  width: ${(props: any) => (props.width ? `${props.width}px` : "100%")};
  min-height: 32px;
  border-radius: ${(props: any) =>
    props.borderRadius ? `${props.borderRadius}px` : "16px"};
  height: ${(props: any) => (props.height ? `${props.height}` : "32px")};
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: ${(props: any) => !props.hidden && `${props.display}!important`};
  -webkit-box-pack: center;
  &:hover {
    background-color: ${(props: any) => {
      if (props.disabled) {
        return props.inverse
          ? "rgba(38, 36, 158, 0.4)"
          : "rgba(255, 4, 161, 0.4)"
      } else {
        return props.inverse ? "rgba(38, 36, 158, 0.8)" : "#E2008E"
      }
    }};
    cursor: ${(props: any) => (props.disabled ? "not-allowed" : "pointer")};
  }
  ${hidden}
  ${css}
  ${margin}
  ${padding}
`

export const ButtonTertiary = styled.button<any>`
  display: ${(props: any) => (props.hidden ? "none" : undefined)};
  color: #333333;
  border: ${({ noBorder }) => (noBorder ? "none" : "solid 1px #979797")};
  background-color: #ffffff;
  width: ${(props: any) => (props.width ? `${props.width}px` : "100%")};
  height: 32px;
  border-radius: 4px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  text-align: center;
  &:hover {
    background-color: #eaeaea;
    cursor: ${(props: any) => (props.disabled ? "not-allowed" : "pointer")};
  }
`

export const ButtonCancel = styled.button<any>`
  color: #0062d8;
  border: none;
  background: none;
  width: ${(props: any) => (props.width ? `${props.width}px` : "100%")};
  height: 32px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  text-align: center;
  &:hover {
    color: ${(props: any) => (props.disabled ? "#050" : "#002cd8")};
    cursor: ${(props: any) => (props.disabled ? "not-allowed" : "pointer")};
  }
  ${hidden}
`

export const ButtonSecondary = styled.button<any>`
  color: ${(props: any) =>
    props.disabled
      ? "rgba(38, 36, 158, 0.5)"
      : props.inverse
      ? "#FFFFFF"
      : "#26249E"};
  background: ${(props: any) =>
    props.disabled
      ? "transparent"
      : props.inverse
      ? "#26249E"
      : props.backgroundColor || "transparent"};
  width: ${(props: any) => (props.width ? `${props.width}px` : "100%")};
  min-height: 32px;
  border-radius: 16px;
  border: solid 1px
    ${(props: any) => (props.disabled ? "rgba(38, 36, 158, 0.5)" : "#26249e")};
  font-family: ${(props: any) =>
    props.fontFamily || "proxima-nova, sans-serif"};
  font-size: ${(props: any) => props.fontSize || "16px"};
  text-align: center;
  font-weight: 600;
  &:hover {
    background: ${(props: any) =>
      props.disabled ? undefined : "rgba(38, 36, 158, 0.1)"};
    color: ${(props: any) => (props.disabled ? undefined : "#26249E")};
    cursor: ${(props: any) => (props.disabled ? "not-allowed" : "pointer")};
  }
  ${hidden}
  ${margin}
  ${padding}
`

export const BtnSmallResponsive = styled(ButtonSmall)`
  width: ${(props: any) => (props.width ? `${props.width}` : "100%")};
`

export const ButtonDashboard = styled.button<IButtonDashboard>`
  color: ${({ disabled }) => (disabled ? "rgba(38, 36, 158, 0.5)" : "#26249E")};
  background: ${({ disabled, backgroundColor }) =>
    disabled ? "transparent" : backgroundColor || "transparent"};
  width: ${({ width }) => (width ? width : "100%")};
  min-height: 32px;
  border-radius: 16px;
  border: solid 1px
    ${({ disabled }) => (disabled ? "rgba(38, 36, 158, 0.5)" : "#26249e")};
  font-family: proxima-nova, sans-serif;
  font-size: ${({ fontSize }) => fontSize || "16px"};
  text-align: center;
  font-weight: 600;
  transition:
    color 250ms,
    background 250ms;
  &:hover {
    background: ${({ disabled }) =>
      disabled ? undefined : "rgba(38, 36, 158, 0.1)"};
    color: ${({ disabled }) => (disabled ? undefined : "#26249E")};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
  ${hidden}
  ${margin}
`
