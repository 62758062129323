import { ButtonSecondary } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import { TextStyled } from "@src/Portal/CyberConcierge/styles"
import { colors } from "@src/theme"
import { ICyberConciergeAlert, ViewTypes } from "@src/types"
import { compareDesc } from "date-fns"
import * as moment from "moment"
import * as React from "react"
import { useHistory } from "react-router-dom"

import {
  Alert,
  AlertsContainer,
  AlertsTable,
  Badge,
  WarningSection,
} from "./styles"

interface IAlertsList {
  alerts: ICyberConciergeAlert[]
}

const AlertsList = ({ alerts }: IAlertsList) => {
  const history = useHistory()
  const { clientWidth } = usePortalSize()
  const sortedAlerts = [...alerts].sort((a1, a2) =>
    compareDesc(new Date(a1.messageDate), new Date(a2.messageDate)),
  )
  const [alertsList, setAlertsList] = React.useState(sortedAlerts.slice(0, 5))

  const loadMore = () => {
    const listLength = alertsList.length
    setAlertsList([
      ...alertsList,
      ...sortedAlerts.slice(listLength, listLength + 5),
    ])
  }

  return (
    <Section w="100%" align="left">
      <Section fontWeight="600" fontSize="24px" w="100%" margin="0 0 12 0">
        Security Alerts
      </Section>
      <WarningSection>
        <FaIcon
          className="far fa-exclamation-triangle"
          color={colors.red}
          margin="0 6px 0 0"
        />
        We have identified security alerts specific to your organization. Click
        on the alerts below to learn more details about the event and
        recommended resolution details.
      </WarningSection>
      <AlertsContainer>
        {alertsList?.map((alert) => {
          const messageSeverity = alert.messageSeverity
            ? alert.messageSeverity
            : "Info"
          const messageStatus = alert.messageStatus
            ? alert.messageStatus
            : "New"
          return (
            <Alert key={`${alert.messageTitle}${alert.updatedOn}`}>
              <AlertsTable>
                <div>
                  <Badge color={messageSeverity}>{messageSeverity}</Badge>
                </div>
                <div>
                  <Link
                    fontSize="18px"
                    bold
                    onClick={() =>
                      history.push(
                        `${ViewTypes.cyberConcierge}/alerts/${alert.conciergeMessageId}`,
                      )
                    }
                  >
                    {alert.messageTitle}
                  </Link>
                  <Section>
                    <TextStyled fontSize="16px">
                      {alert.messageDescription}
                    </TextStyled>
                  </Section>
                  {clientWidth >= 700 ? (
                    <Section>
                      <TextStyled
                        fontWeight="700"
                        fontSize="16px"
                        margin="0 20px 0 0"
                      >
                        Status: {messageStatus}
                      </TextStyled>{" "}
                      |{" "}
                      <TextStyled
                        fontWeight="700"
                        fontSize="16px"
                        margin="0 20px"
                      >
                        Alert date:{" "}
                        {moment(alert.messageDate).format("MMM D, YYYY")}
                      </TextStyled>
                      {!moment(alert.messageDate).isSame(
                        alert.updatedOn,
                        "day",
                      ) && (
                        <>
                          {" "}
                          |{" "}
                          <TextStyled
                            fontWeight="700"
                            fontSize="16px"
                            margin="0 0 0 20px"
                          >
                            Updated On:{" "}
                            {moment(alert.updatedOn).format("MMM D, YYYY")}
                          </TextStyled>
                        </>
                      )}
                    </Section>
                  ) : (
                    <>
                      <div>
                        <TextStyled
                          fontWeight="700"
                          fontSize="16px"
                          margin="0 20px 0 0"
                        >
                          Status: {messageStatus}
                        </TextStyled>
                      </div>
                      <div>
                        <TextStyled fontWeight="700" fontSize="16px">
                          Alert date:{" "}
                          {moment(alert.messageDate).format("MMM D, YYYY")}
                        </TextStyled>
                      </div>

                      {!moment(alert.messageDate).isSame(
                        alert.updatedOn,
                        "day",
                      ) && (
                        <div>
                          <TextStyled fontWeight="700" fontSize="16px">
                            Updated date:{" "}
                            {moment(alert.updatedOn).format("MMM D, YYYY")}
                          </TextStyled>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </AlertsTable>
            </Alert>
          )
        })}
        {alertsList.length < sortedAlerts.length && (
          <ButtonSecondary margin="10 0 0" onClick={loadMore}>
            Load More
          </ButtonSecondary>
        )}
      </AlertsContainer>
    </Section>
  )
}

export default AlertsList
