import { Section } from "@src/Components/StyledUtils"
import { colors, media } from "@src/theme"
import styled from "styled-components"

export const TopSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const IconsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`
export const SectionStyled = styled(Section)`
  padding: 38px 32px 20px;
  @media ${media.medium} {
    padding: 32px 32px 20px;
  }
`
export const HeadLevelDescrText = styled.span`
  font-size: 16px;
`
export const CompanyName = styled.div`
  font-size: 32px;
  font-family: montserrat;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  vertical-align: sub;
  color: ${colors.darkBlue};
  padding: 0 110px 10px;
  @media (max-width: 1100px) {
    padding: 40px 0 10px;
  }
  & img {
    transform: translateY(8px);
  }
`
