import { Link } from "@src/Components/Link"
import { colors } from "@src/theme"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

interface IStyledMarkdown {
  margin?: string
  pMaxWidth?: string
}

export const StyledMarkdown = styled(ReactMarkdown)<IStyledMarkdown>`
  margin: ${({ margin }) => margin};
  line-height: 1.5;
  a {
    word-break: break-word;
    &[href]:hover {
      color: ${colors.link};
      text-decoration: underline;
    }
  }
  p {
    word-break: break-word;
    max-width: ${({ pMaxWidth }) => pMaxWidth};
  }
`
export const LinkStyled = styled(Link)`
  display: inline-block;
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-underline-offset: 2px;
  font-weight: 600;
  cursor: help;
  &:hover {
    cursor: help;
    text-decoration-line: underline;
    text-decoration-style: dashed;
    text-underline-offset: 2px;
    font-weight: 600;
    color: ${colors.link};
  }
`
