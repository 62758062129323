import { colors, mediaWidth } from "@src/theme"
import styled from "styled-components"

export const Heading = styled.h3`
  color: ${colors.violet};
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  margin: 0 0 20px 0;
  font-family: montserrat;
  p {
    font-family: montserrat;
  }
`
export const OfferingSection = styled.div`
  @media (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 30px;
  }
`
export const OfferingRow = styled.section`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  @media (min-width: ${mediaWidth.small}) {
    //padding: 25px 30px 20px;
    padding: 0.875rem 1.4375rem;
    border: 1px solid ${colors.blue5};
    border-radius: 8px;
  }
`
