import { colors } from "@src/theme"
import * as React from "react"
import styled from "styled-components"

interface IHeader {
  name: string
  sort?: string | undefined
  width: string
  caption: string
  render?: (b: object) => void
  style?: any
  headerStyle?: any
}

interface ISortedTable {
  headers: IHeader[]
  data: any
  onSort?: any
  onSelect?: any
  hidden?: boolean
  withoutHover?: boolean
}

class SortedTable extends React.Component<ISortedTable, object> {
  render() {
    if (this.props.hidden) {
      return null
    }

    const headers: IHeader[] = this.props.headers
    const data = this.props.data
    const onSelect = this.props.onSelect

    const ncol = headers.length
    const nrows = data.length
    const columns = []
    const rows = []

    for (let i = 0; i < ncol; i++) {
      const h = headers[i]
      const sort = h.sort ? <SortDir sort={h.sort} /> : undefined
      columns.push(
        <Th width={h.width} key={i} style={h.headerStyle}>
          <Caption
            sort={h.sort}
            onClick={() => (sort ? this.props.onSort(h.name) : null)}
          >
            {h.caption} {sort}
          </Caption>
        </Th>,
      )
    }

    for (let i = 0; i < nrows; i++) {
      const cells = []
      for (let j = 0; j < ncol; j++) {
        const colName = headers[j].name
        const render = headers[j].render
        const style = headers[j].style || {}
        cells.push(
          <Td
            key={"c" + j}
            onClick={() => (onSelect ? onSelect(colName, i, data[i]) : null)}
            style={style}
          >
            {render ? render(data[i]) : data[i][colName]}
          </Td>,
        )
      }
      rows.push(
        <Tr
          n={i}
          key={"r" + i}
          cursor={onSelect ? "pointer" : "inherit"}
          withoutHover={this.props.withoutHover}
        >
          {cells}
        </Tr>,
      )
    }

    return (
      <Table>
        <Thead>
          <tr>{columns}</tr>
        </Thead>
        <tbody>{rows}</tbody>
      </Table>
    )
  }
}

interface ISort {
  sort: string
}

class SortDir extends React.Component<ISort, object> {
  render() {
    return (
      <StyledSortDir>
        {String.fromCharCode(this.props.sort === "asc" ? 0x25b2 : 0x25bc)}
      </StyledSortDir>
    )
  }
}

const Thead = styled.thead`
  tr {
    height: 54px;
  }
`

////////////////////////////////////////////////////////

const Tr = styled.tr<any>`
  cursor: ${(props: any) => props.cursor};
  background-color: ${(props: any) =>
    props.n % 2 ? colors.lightBlue7 : "none"};
  &:hover {
    background-color: ${({ withoutHover }) => !withoutHover && "#f5f5f5"};
  }
`

////////////////////////////////////////////////////////

const Th = styled.th<any>`
  width: ${(props: any) => props.width};
  text-align: left;
  padding: 2px 10px;
  border: solid 1px #d6d6d6;
  background-color: ${colors.lightBlue4};
  vertical-align: middle;
`
////////////////////////////////////////////////////////

const Td = styled.td`
  font-weight: normal;
  // height: 47px;
  text-align: left;
  padding: 2px 10px;
  border: solid 1px #d6d6d6;
`

interface ICapture {
  sort?: string
}

const Caption = styled.span`
  cursor: ${(props: ICapture) => (props.sort ? "pointer" : "default")};
  &:hover {
    color: ${(props: ICapture) => (props.sort ? "#26249E" : "default")};
  }
`

const Table = styled.table`
  table-layout: fixed;
  margin-top: 10px;
  background-color: #ffffff;
  border-spacing: 0px;
  border-collapse: collapse;
  font-size: 16px;
`

const StyledSortDir = styled.span`
  cursor: pointer;
  font-size: 10px;
`

export default SortedTable
