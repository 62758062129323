import Waiting from "@src/Components/Waiting"
import SettingsContainer from "@src/Portal/Settings/SettingsContainer"
import { ViewTypes } from "@src/types"
import * as React from "react"
import { Suspense } from "react"
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"

const UserDetails = React.lazy(() => import("@src/Portal/Settings/UserDetails"))
const OrganizationsDetails = React.lazy(
  () => import("@src/Portal/Settings/OrganizationDetails"),
)
const ManageAdmins = React.lazy(
  () => import("@src/Portal/Settings/ManageAdmins"),
)

export const SettingsRouter = ({ match }: RouteComponentProps) => (
  <SettingsContainer>
    <Suspense fallback={<Waiting text="" />}>
      <Switch>
        <Route exact path={ViewTypes.settings}>
          <Redirect to={`${match.path}/you`} />
        </Route>
        <Route exact path={`${match.path}/you`} component={UserDetails} />
        <Route
          exact
          path={`${match.path}/organization`}
          component={OrganizationsDetails}
        />
        <Route exact path={`${match.path}/admins`} component={ManageAdmins} />

        <Redirect exact to="/404" />
      </Switch>
    </Suspense>
  </SettingsContainer>
)
