import { tTrainingState } from "@src/Model/Model"

import * as ACTION from "../../Actions/ActionTypes"
import initial_state from "../../Model/Model"

interface tAction {
  type: string
  payload: any
}

function reducer(state: tTrainingState, action: tAction) {
  if (!state) {
    state = initial_state.training
  }

  switch (action.type) {
    case ACTION.SET_TRAINING_STATUS: {
      const status: string = action.payload
      return { ...state, status }
    }
    case ACTION.SET_TRAINING_TARGET: {
      const target: string = action.payload
      return { ...state, target }
    }
    case ACTION.SET_TRAINING_DATA: {
      const trainingData: string[][] = action.payload
      const status = "READY"
      return { ...state, trainingData, status }
    }
    default:
      return state
  }
}

export default reducer
