import {
  actions as GLOBAL_MODALS,
  GLOBAL_MODALS_KEYS,
} from "@src/Actions/Reducers/GlobalModalsReducer"
import { ButtonSmall } from "@src/Components/Button"
import Dialog from "@src/Components/Dialog"
import { Link } from "@src/Components/Link"
import { StyledSpinner } from "@src/Components/StyledSinner"
import { Section } from "@src/Components/StyledUtils"
import { tState } from "@src/Model/Model"
import { VIEW_PATHS } from "@src/Routes/constants"
import { getAssessmentDataWatcher, updateCustomerPortalData } from "@src/Sagas"
import { colors } from "@src/theme"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Body, Container, Header, HeaderImg, Text, Title } from "./styles"

interface ICybersecurityReleaseNotes {
  isOpen: boolean
}

const CybersecurityReleaseNotes = ({ isOpen }: ICybersecurityReleaseNotes) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isZeguroServiceSession = useSelector(
    (state: tState) => state.portal.isZeguroServiceSession,
  )
  const zimmdData = useSelector((state: tState) => state.zimm.getAssessmentData)
  const isZimmDataLoading = !zimmdData.success && !zimmdData.error
  const hasSeenReleaseNotes = useSelector(
    (store: tState) =>
      store.customerPortalData.data?.cybermaturity?.hasSeenReleaseNotes,
  )

  const onClose = () => {
    dispatch(
      GLOBAL_MODALS.toggleGlobalModal(
        GLOBAL_MODALS_KEYS.cybersecurityReleaseNotes,
      ),
    )
  }

  const onStart = () => {
    onClose()
    history.push(
      `${VIEW_PATHS.cybermaturity}/assessment/${
        zimmdData.nextQuestion?.elementPath || "start"
      }`,
    )
  }

  React.useEffect(() => {
    dispatch(getAssessmentDataWatcher())
    if (!isZeguroServiceSession && !hasSeenReleaseNotes) {
      dispatch(
        updateCustomerPortalData({
          cybermaturity: { hasSeenReleaseNotes: true },
        }),
      )
    }
  }, [])

  return (
    <Dialog
      className="zeg-intro"
      isOpen={isOpen}
      onClose={onClose}
      content={
        <Body>
          <Header>
            <HeaderImg src="/images/Cm_color.svg" />
          </Header>
          <Container>
            <Title>We&apos;ve Made Some Changes</Title>
            <Text>
              <div>
                <Section
                  fontSize="28px"
                  fontColor={colors.link}
                  fontWeight="700"
                  margin="0 0 20"
                >
                  Introducing Cyber Maturity.
                </Section>
                <Section>
                  Cyber Maturity is an assessment and guide to help you measure,
                  understand, and improve your organization&apos;s cyber
                  posture.
                </Section>
                <ul>
                  <li>
                    Get a complete snapshot of your organization’s current
                    cybersecurity stance.
                  </li>
                  <li>
                    Discover actionable insights to fortify your organization
                    against bad actors.
                  </li>
                </ul>
                <Section margin="0 0 34">
                  The dashboard has a new look as well, to help you better find
                  Cyber Maturity, and the tools you already know and love.
                </Section>
              </div>
            </Text>
            <Section flex={"space-between center"}>
              <Link onClick={onClose}>Skip</Link>
              <ButtonSmall
                width={195}
                onClick={onStart}
                disabled={isZimmDataLoading}
              >
                {!isZimmDataLoading ? (
                  "Go to Assessment"
                ) : (
                  <StyledSpinner size={25} color={colors.gray2} />
                )}
              </ButtonSmall>
            </Section>
          </Container>
        </Body>
      }
    />
  )
}

export default CybersecurityReleaseNotes
