import { colors, media } from "@src/theme"
import styled from "styled-components"

export const SelectHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-family: proxima-nova, sans-serif;
  align-items: center;
  @media ${media.medium} {
    #add-another-web-app {
      border-radius: initial;
    }
  }
  @media (max-width: 1165px) {
    #add-another-web-app {
      width: auto;
      font-size: 0;
      border: none;
      i {
        font-size: 26px;
        margin-bottom: 1px;
      }
    }
    #select-web-app {
      padding: 6px 16px;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: start;

    #add-another-web-app {
      width: auto;
      font-size: 0;
      border: none;
    }
  }
`

export const TabHeading = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 2px;
  @media ${media.medium} {
    font-size: 16px;
  }
  @media (min-width: 600px) {
    margin-right: 10px;
  }
`

export const SelectApp = styled.div<{ width?: string }>`
  width: ${({ width }) => width};
`

export const TabPopover = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  line-height: 1.5;
  color: ${({ color }) => color || colors.white};
`

export const PageHolder = styled.div<{ align?: string }>`
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: left;
  align-items: ${({ align }) => align || "left"};
`

export const Padding = styled.div`
  width: 20px;
  height: 2px;
  @media (min-width: 769px) and (max-width: 995px) {
    width: 10px;
  }
  @media (max-width: 600px) {
    width: 10px;
  }
`
export const SelectAppWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const DisabledButton = styled.div`
  cursor: not-allowed;

  button {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.5);
    background: transparent;
    border: solid 1px rgba(0, 0, 0, 0.5);
  }
`

export const HorizontalRule = styled.hr`
  border: none;
  border-bottom: 1px solid ${colors.gray5};
  margin: 15px -44px 0;

  @media (max-width: 768px) {
    margin: 15px -12px 0;
  }
`
