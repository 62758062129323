import { Classes, Popover2 } from "@blueprintjs/popover2"
import * as _ from "lodash"
import * as React from "react"
import styled from "styled-components"

import FloatingLabelInput from "./FloatingLabelInput"

interface IPasswordInput {
  id: string
  label: string
  value: string
  margin?: string
  noValidation?: boolean
  passwordToMatch?: string
  noIcon?: boolean
  isValid?: (valid: boolean) => void
  onChange?: (e: any) => void
  onBlur?: () => void
  onFocus?: () => void
  [x: string]: any
}

interface PasswordInputState {
  popoverOpen: boolean
  lowCase: boolean
  upCase: boolean
  lengthCheck: boolean
  numCheck: boolean
  icon: string
  type: string
  matchPassword: boolean | null
}

export default class PasswordInput extends React.Component<
  IPasswordInput,
  PasswordInputState
> {
  public readonly state: PasswordInputState = {
    popoverOpen: false,
    lowCase: false,
    upCase: false,
    lengthCheck: false,
    numCheck: false,
    icon: "far fa-eye",
    type: "password",
    matchPassword: this.props.passwordToMatch ? false : null,
  }

  handleFocus = () => {
    this.props.noValidation || this.setState({ popoverOpen: true })
    this.props.onFocus && this.props.onFocus()
  }

  handleBlur = () => {
    const { onBlur } = this.props
    this.props.noValidation || this.setState({ popoverOpen: false })
    onBlur && onBlur()
  }

  handleChange = (e: any) => {
    const { onChange } = this.props
    this.validatePassword(e.target.value)
    onChange && onChange(e)
  }

  toggleShowPassword = () => {
    if (this.state.type === "password") {
      this.setState({ type: "text", icon: "far fa-eye-slash" })
    } else {
      this.setState({ type: "password", icon: "far fa-eye" })
    }
  }

  getCheckIcon = (isCompleted: boolean) => {
    return isCompleted ? (
      <CheckIcon color="green" className="fas fa-check-circle" />
    ) : (
      <CheckIcon color="red" className="fas fa-times-circle" />
    )
  }

  validatePassword = (password: string) => {
    const { passwordToMatch, isValid, noValidation } = this.props
    if (noValidation) {
      const lengthCheck = password.length > 0
      const matchPassword = passwordToMatch
        ? _.isEqual(passwordToMatch, password)
        : null
      let isPwdValid = lengthCheck
      if (matchPassword !== null) {
        isPwdValid = isPwdValid && matchPassword
      }
      isValid && isValid(isPwdValid)
      this.setState({
        lengthCheck,
        matchPassword,
      })
    } else {
      const upCase = password.replace(/[^A-Z]/g, "").length > 0
      const lowCase = password.replace(/[^a-z]/g, "").length > 0
      const numCheck = password.replace(/[^0-9]/g, "").length > 0
      const lengthCheck = password.length >= 8
      const matchPassword = passwordToMatch
        ? _.isEqual(passwordToMatch, password)
        : null
      let isPwdValid = upCase && lowCase && numCheck && lengthCheck
      if (matchPassword !== null) {
        isPwdValid = isPwdValid && matchPassword
      }
      isValid && isValid(isPwdValid)
      this.setState({
        upCase,
        lowCase,
        numCheck,
        lengthCheck,
        matchPassword,
      })
    }
  }

  validationNoPopup = (value: string): string => {
    // console.info("validationValue", value)
    if (this.props.passwordToMatch && !this.state.matchPassword) {
      return "Passwords don't match"
    } else {
      return ""
    }
  }

  getPasswordCheckList = () => {
    return (
      <ChecklistContainer>
        <ChecklistHeading> Your password must </ChecklistHeading>
        <Check>
          <span>{this.getCheckIcon(this.state.lowCase)}</span>
          Have at least one lower case letter
        </Check>
        <Check>
          <span>{this.getCheckIcon(this.state.upCase)}</span>
          Have at least one upper case letter
        </Check>
        <Check>
          <span>{this.getCheckIcon(this.state.numCheck)}</span>
          Have at least one number
        </Check>
        <Check>
          <span>{this.getCheckIcon(this.state.lengthCheck)}</span>
          Be at least 8 characters
        </Check>
        {this.state.matchPassword !== null ? (
          <Check>
            <span>{this.getCheckIcon(this.state.matchPassword)}</span>
            Match new password
          </Check>
        ) : null}
      </ChecklistContainer>
    )
  }

  render() {
    const { onChange, onBlur, onFocus, noIcon, margin, noValidation, ...rest } =
      this.props
    const { type } = this.state
    const iconProps = noIcon
      ? {}
      : { inputIcon: this.state.icon, onClickIcon: this.toggleShowPassword }
    return (
      <div style={{ width: "100%", margin: margin }}>
        <Popover2
          content={this.getPasswordCheckList()}
          popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
          isOpen={this.state.popoverOpen}
          position={"bottom-left"}
          targetTagName="div"
          usePortal={true}
          interactionKind="hover-target"
          autoFocus={false}
        >
          <FloatingLabelInput
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            type={type}
            onValidate={noValidation ? () => "" : this.validationNoPopup}
            {...iconProps}
            {...rest}
          />
        </Popover2>
      </div>
    )
  }
}

const CheckIcon = styled.i`
  margin-right: 5px;
  color: ${(props: any) => props.color};
`

const ChecklistContainer = styled.div`
  font-family: proxima-nova, sans-serif;
  height: ${(props: any) => props.height}px;
  overflow: hidden;
`

const ChecklistHeading = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 5px;
`

const Check = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
`
