import { ButtonDashboard } from "@src/Components/Button"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import * as React from "react"
import { useSelector } from "react-redux"

import {
  CTA,
  DashboardCardHeading,
  FaIconHead,
  FeatureCard,
  HeadingImg,
} from "../styles"
import { EmptyStateCard } from "./EmptyState"
import { FilledStateCard } from "./FilledState"

interface ISecPolCard {
  isActive: boolean
  gridColumn: string
  setView: () => void
}

export const SecPolCard: React.FC<ISecPolCard> = ({
  isActive,
  gridColumn,
  setView,
}) => {
  const secPolicyData = useSelector(
    (state: tState) => state.portal.dashboard.compliance,
  )
  const emptyState = secPolicyData?.active === 0 && secPolicyData?.draft === 0
  const hasDrafts = secPolicyData?.draft && secPolicyData?.draft > 0
  const allDone = secPolicyData?.draft === 0 && secPolicyData?.inactive === 0
  return (
    <FeatureCard
      color={colors.blue5}
      isEmpty={emptyState}
      gridColumn={gridColumn}
    >
      <DashboardCardHeading>
        <HeadingImg src="images/icon_sec_pols_dash.svg" /> SECURITY POLICIES{" "}
        {!emptyState && (
          <FaIconHead
            className="fas fa-info-circle"
            data-tip
            data-for="card-title-compliance-tooltip"
          />
        )}
      </DashboardCardHeading>
      {emptyState ? (
        <EmptyStateCard />
      ) : (
        <FilledStateCard
          {...secPolicyData}
          draft={secPolicyData?.draft || 0}
          inactive={secPolicyData?.inactive || 0}
          active={secPolicyData?.active || 0}
          lastUpdated={secPolicyData?.lastUpdated || null}
        />
      )}

      <CTA>
        <ButtonDashboard
          width="60%"
          backgroundColor="#ffffff"
          onClick={setView}
        >
          {isActive
            ? emptyState
              ? "Activate Policies"
              : hasDrafts
              ? "Finish Draft Policies"
              : allDone
              ? "Add Custom Policies"
              : "Review Your Policies"
            : "Subscribe to Use"}
        </ButtonDashboard>
      </CTA>
      <Tooltip
        id="card-title-compliance-tooltip"
        content={
          <>
            <h3>Set rules & guidelines for your team. </h3>
            <p>
              Provide governance for your organization. Meet legal and
              contractual obligations. Use our templates, or create custom
              policies.
            </p>
          </>
        }
      />
    </FeatureCard>
  )
}
