import { colors, media } from "@src/theme"
import { ICourse } from "@src/types"
import styled from "styled-components"

interface IGradeTextProps {
  isGrade?: boolean
  isEnrolled?: boolean
}

export const GradeText = styled.span<IGradeTextProps>`
  font-size: 16px;
  color: ${({ isEnrolled }) => (isEnrolled ? colors.gray40 : colors.black50)};
  cursor: ${({ isGrade }) => isGrade && "help"};
  text-decoration: ${({ isGrade }) => isGrade && "underline from-font dotted"};
  text-underline-offset: 2px;
`

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const HeadCardDetails = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const Image = styled.img`
  height: 32px;
  margin-right: 11px;
`

export const HeadTitle = styled.h1`
  font-family: montserrat;
  font-size: 21px;
  line-height: 24px;
  color: ${colors.darkBlue};
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: 700;
`

export const HeadSubTitle = styled.div`
  font-family: montserrat;
  font-size: 18px;
  font-weight: 600;
  color: ${colors.darkBlue};
`

export const TeamAveragePanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: rgba(0, 98, 216, 0.05);
  border: 1px solid rgba(0, 98, 216, 0.5);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 14px 20px;
  @media ${media.medium} {
    background-color: inherit;
    border: none;
  }
`

export const Grade = styled.div<{ color?: string }>`
  line-height: 44px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  width: 44px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: ${colors.white};
`

export const Badge = styled.div`
  background-color: ${colors.gray20};
  color: ${colors.white};
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  padding: 4px 8px;
  margin: 10px 0;
  border-radius: 3px;
`

export const conditionalRowStyles: any = [
  {
    when: ({ name }: ICourse) => name === "Team Average",
    style: {
      background: "rgba(255, 188, 48, 0.2)",

      "& span": {
        fontWeight: "bold",
      },
    },
  },
]

export const customStyles: any = {
  headCells: {
    style: {
      "&.rdt_TableCol": {
        minWidth: "150px",
      },
      "&:not(:first-of-type)": {
        justifyContent: "center",
      },
    },
  },
  cells: {
    style: {
      "&.rdt_TableCell": {
        minWidth: "150px",
      },
      "&:not(:first-of-type)": {
        justifyContent: "center",
      },
    },
  },
}
