import * as Yup from "yup"

type personalDetailsValidation = Yup.AnyObjectSchema
type companyDetailsValidation = Yup.AnyObjectSchema

export const usePersonalDetailsValidation = (): personalDetailsValidation => {
  return Yup.object({
    firstName: Yup.string().notRequired().default(""),
    lastName: Yup.string().notRequired().default(""),
    email: Yup.string()
      .trim()
      .email()
      .required("This field required")
      .default(""),
    jobTitle: Yup.string().notRequired().default(""),
  })
}

export const useCompanyDetailsValidation = (): companyDetailsValidation => {
  return Yup.object({
    companyName: Yup.string().required("This field required").default(""),
    companyUrl: Yup.string()
      .trim()
      .matches(
        new RegExp(
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i",
        ),
        { message: "Must be a valid URL", excludeEmptyString: true },
      )
      .default(""),
  })
}

export const useChangePasswordAuthValidation =
  (): personalDetailsValidation => {
    return Yup.object({
      oldPassword: Yup.string().required("This field required").default(""),
    })
  }

export const useChangePasswordValidation = (): personalDetailsValidation => {
  return Yup.object({
    newPassword: Yup.string()
      .required("This field required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*\d]{8,}/,
        "Must contain, one uppercase, one lowercase and one number",
      )
      .default(""),
    confirmNewPassword: Yup.string()
      .required("This field required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match")
      .default(""),
  })
}
