import { ButtonSmall } from "@src/Components/Button"
import { Heading, LinkWrapper, SubHeading } from "@src/Components/Common"
import { FaIcon } from "@src/Components/FaIcon"
import TextField from "@src/Components/Form/TextField"
import { Link } from "@src/Components/Link"
import { tState } from "@src/Model/Model"
import { useVerifyIdentityFormValidation } from "@src/Portal/Accounts/ForgotPassword/validation"
import { sendResetPasswordMagicLinkWatcher } from "@src/Sagas"
import { ViewTypes } from "@src/types"
import { useFormik } from "formik"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router"

import { SpinnerStyled } from "../styles"

interface IRoutesParams {
  subscriptionId?: string
}

interface IFormFields {
  workEmail: string
}

export const VerifyIdentity = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const validationSchema = useVerifyIdentityFormValidation()

  const isLoading = useSelector(
    (state: tState) => state?.account?.sendResetPasswordMagicLink?.isLoading,
  )

  const match = useRouteMatch<IRoutesParams>([
    "/forgotPassword/:subscriptionId",
  ])

  const redirectToSignIn = () => {
    history.push(ViewTypes.signin + "/" + (match?.params?.subscriptionId || ""))
  }

  const onSubmit = () => {
    const trimmedEmail = formik.values.workEmail.trim()
    formik.setFieldValue("workEmail", trimmedEmail)
    dispatch(
      sendResetPasswordMagicLinkWatcher({
        email: trimmedEmail,
        subscriptionId: match?.params?.subscriptionId || undefined,
      }),
    )
  }

  const formik = useFormik<IFormFields>({
    initialValues: { workEmail: "" },
    validationSchema,
    onSubmit,
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Heading align="left" margin="20px 0px">
          Reset your account password.
        </Heading>
        <SubHeading margin="0 0 10px 0" display="flex">
          Verify your identity.
        </SubHeading>
        <TextField
          label="Work email address"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.workEmail || ""}
          name="workEmail"
          error={!!(formik.errors.workEmail && formik.touched.workEmail)}
          errorMsg={formik.errors.workEmail}
          disabled={isLoading}
        />
        <ButtonSmall
          margin="30 0 0 0"
          id="verify-account-request-button"
          onClick={onSubmit}
          disabled={!(formik.isValid && formik.dirty) || isLoading}
        >
          {isLoading ? <SpinnerStyled size={25} /> : " Verify Email"}
        </ButtonSmall>
      </form>
      <LinkWrapper>
        <p>
          <Link onClick={redirectToSignIn} id="back-link">
            <FaIcon
              className="far fa-chevron-left"
              fontSize="12px"
              margin="0 4px 0"
            />
            Back
          </Link>
        </p>
      </LinkWrapper>
    </>
  )
}
