import Dialog from "@src/Components/Dialog"
import { colors } from "@src/theme"
import styled from "styled-components"

export const ButtonClose = styled.button`
  color: ${colors.gray20};
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: end;
`
export const ModalContent = styled.div`
  padding: 0 11px;
  text-align: center;
  h1 {
    margin: 0 0 15px;
    font-size: 28px;
    color: ${colors.darkBlue};
    font-weight: 700;
    font-family: montserrat;
    img {
      margin-right: 20px;
    }
  }
  h3 {
    margin: 0 0 15px;
    font-size: 21px;
    color: ${colors.darkBlue};
    font-weight: 700;
    font-family: montserrat;
  }
  @media (max-width: 550px) {
    h1 {
      max-width: 227px;
      font-size: 21px;
      margin: 0 auto 15px auto;
      img {
        display: block;
        margin: 0 auto 8px auto;
      }
    }
    h3 {
      font-size: 18px;
      max-width: 265px;
      margin: 0 auto 25px auto;
    }
    button {
      margin: 30px 0 0 0;
    }
  }
`
export const StyledModal = styled(Dialog)`
  max-width: 850px;
  width: 100%;
  padding: 14px 24px;
  @media (max-width: 768px) {
    max-width: 90%;
  }
`
export const NotStartedInfoBlock = styled.ul`
  margin: 0 auto 30px auto;
  width: fit-content;
  li {
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 12px;
    text-align: left;
  }
  @media (max-width: 550px) {
    margin: 0;
    padding-left: 25px;
    li {
      font-size: 15px;
    }
  }
`
export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 27px;
  @media (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
    a {
      margin-top: 18px;
    }
  }
`
export const EmptyProgressInfoBlock = styled.div<{ isEmptyState: boolean }>`
  padding: 11px 17px;
  border-radius: 6px;
  border: ${({ isEmptyState }) =>
    isEmptyState && "1px solid rgba(0, 98, 216, 0.5)"};
  background: ${({ isEmptyState }) => isEmptyState && "rgba(0, 98, 216, 0.05)"};
  font-family: proxima-nova, sans-serif;
  span {
    text-align: left;
    font-size: 15px;
    color: ${colors.darkBlue};
    display: block;
    margin-bottom: 15px;
  }
  ul {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 0;
    justify-content: space-around;
    li {
      list-style: none;
      font-size: 21px;
      font-weight: 600;
      display: flex;
      align-items: center;
      span {
        color: ${colors.darkBlue};
        margin: 0 5px 0 10px;
        font-size: 21px;
      }
    }
  }
  @media (max-width: 550px) {
    display: none;
  }
`
export const ProgressInfoBlock = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(0, 98, 216, 0.5);
  background: rgba(0, 98, 216, 0.05);
  padding: 19px 24px;
  p {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  @media (max-width: 550px) {
    border: none;
    background: transparent;
    padding: 0;
    p {
      font-size: 16px;
    }
  }
`
export const TooltipText = styled.p`
  font-size: 16px;
`
