import * as T from "../types"
import * as ACTION from "./ActionTypes"

export const setTargetList = (targetList: T.IProbelyTarget[]) => ({
  type: ACTION.SET_PROBELY_TARGET_LIST,
  payload: targetList,
})

export const setScanMap = (scanMap: {
  [targetId: string]: T.IProbelyScan[]
}) => ({
  type: ACTION.SET_PROBELY_SCAN_MAP,
  payload: scanMap,
})

export const setScheduleMap = (scheduleMap: {
  [targetId: string]: T.IProbelySchedule[]
}) => ({
  type: ACTION.SET_PROBELY_SCHEDULE_MAP,
  payload: scheduleMap,
})
