import { Link } from "@src/Components/Link"
import {
  IFastRatingsData,
  IRatingDetails,
  IRatingDetailsData,
  ITabsContext,
  tCompanyProfile,
} from "@src/types"
import * as React from "react"

import CategoryListCollapse from "../CategoryListCollapse"
import { DETAILS_DATA, FIND_FULL_RISK_NAME } from "../constants"
import { TabsContext } from "../Ratings"
import {
  Content,
  LeftContainer,
  RatingBadge,
  RatingBadgeCircle,
  RightContainer,
  RiskRatingHead,
} from "./styles"

interface IProps {
  data: IFastRatingsData
  companyProfile: tCompanyProfile
  ratingDetails: IRatingDetailsData
}

const calcLink =
  "https://www.bitsight.com/hubfs/guides/How%20BitSight%20Calculates%20Security%20Ratings%201_2.pdf?hsCtaTracking=8a6b00a7-4510-454f-9d53-fbda79148697%7Ccc70dab7-b414-4e63-8b5c-cbcb51a9271a"

const TabDetails: React.FC<IProps> = ({
  data,
  ratingDetails,
  companyProfile,
}) => {
  const { companyUrl, companyName } = companyProfile || {}

  const riskRef = React.useRef<HTMLDivElement | null>(null)
  const { scrollTo, setScrollTo } = React.useContext(
    TabsContext,
  ) as ITabsContext

  const ratingDetailsMemo = React.useMemo(() => {
    return Object.keys(ratingDetails).map((key) => ({
      ...ratingDetails[key],
      id: key,
    }))
  }, [ratingDetails])

  const filterRisk = (name: string) =>
    ratingDetailsMemo.find((risk: IRatingDetails) => risk.name === name)

  React.useEffect(() => {
    const { current } = riskRef
    if (scrollTo) {
      current !== null &&
        current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      setScrollTo("")
    }
  }, [riskRef.current, scrollTo])

  return (
    <Content>
      <LeftContainer companyUrl={!!(companyUrl && data)}>
        <h2>BitSight Security Rating</h2>
        <p>
          BitSight Security Ratings range from 250 to 900 and indicate a
          company’s relative security effectiveness. Each organization can
          receive one of the three following ratings: Basic, Intermediate, or
          Advanced. Highly rated organizations have strong security postures
          historically and provide the lowest risk.
        </p>
        <p>
          Ratings are calculated based on risk vectors, described below. Risk
          vectors do not contribute evenly to a company’s overall Security
          Rating.
          <br />
          <Link href={calcLink}>How are ratings calculated?</Link>
        </p>
        <p>
          Note: Industry averages are calculated from similarly sized companies.
          All Companies refers to companies of similar size.
        </p>

        <div className="rating-badges-wrapper">
          <RatingBadge color={"#b24053"}>
            <div className="rating-badge-title">BASIC</div>
            <span>250-640</span>
          </RatingBadge>
          <RatingBadge color={"#e8a951"}>
            <div className="rating-badge-title">INTERMEDIATE</div>
            <span>640-740</span>
          </RatingBadge>
          <RatingBadge color={"#2c4d7f"}>
            <div className="rating-badge-title">ADVANCED</div>
            <span>740-900</span>
          </RatingBadge>
        </div>

        {DETAILS_DATA.map((category) => (
          <div
            key={category.title}
            ref={scrollTo === category.title ? riskRef : null}
          >
            <h2 className="rating-category-title">{category.title}</h2>
            <div>
              {category.list.map((text, index: number) => (
                <p key={index}>{text}</p>
              ))}
            </div>
            {category.subCategories.map((riskVector) => (
              <div
                key={riskVector.title}
                ref={scrollTo === riskVector.title ? riskRef : null}
              >
                <div className="rating-risk-title">
                  <RatingBadgeCircle
                    color={
                      filterRisk(riskVector.title)?.grade_color || "#495057"
                    }
                  >
                    {filterRisk(riskVector.title)?.grade || "N/A"}
                  </RatingBadgeCircle>
                  <h3>
                    {FIND_FULL_RISK_NAME[riskVector.title] || riskVector.title}
                    {riskVector.beta && "*"}
                    {riskVector.info && "**"}
                  </h3>
                </div>
                <p>{riskVector.description}</p>

                {!!riskVector.list.length && (
                  <>
                    <h4>{"Remediation Suggestions"}</h4>
                    <ul>
                      {riskVector.list.map((text, index: number) => (
                        <li key={index}>{text}</li>
                      ))}
                    </ul>
                  </>
                )}

                {riskVector.beta && (
                  <div>
                    * Risk Vector does not currently affect Security Ratings
                  </div>
                )}
                {riskVector.info && (
                  <div>
                    ** Informational risk vector (will never affect Security
                    Ratings)
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </LeftContainer>

      <RightContainer>
        <RiskRatingHead
          companyUrl={!!(companyUrl && data)}
          color={data?.ratings[0]?.rating_color}
          className="risk-rating-head"
        >
          <div>
            <h2>Rating Details for {companyName}</h2>
            <h3>Risk Vector Breakdown</h3>
          </div>
          <div className="risk-rating-number">
            <p>{data ? data.ratings[0].rating : "490"}</p>
            <span>Security Rating</span>
          </div>
        </RiskRatingHead>

        <CategoryListCollapse
          data={ratingDetailsMemo}
          category="Compromised Systems"
        />
        <CategoryListCollapse data={ratingDetailsMemo} category="Diligence" />
        <CategoryListCollapse
          data={ratingDetailsMemo}
          category="User Behavior"
        />
        <CategoryListCollapse
          data={ratingDetailsMemo}
          category="Public Disclosures"
        />
      </RightContainer>
    </Content>
  )
}

export default TabDetails
