import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import { Heading } from "@src/Components/Common"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import Tooltip from "@src/Components/Tooltip"
import { colors } from "@src/theme"
import * as React from "react"

import { Section } from "../StyledUtils"
import {
  ButtonContainer,
  CardIcon,
  IconButton,
  IconGroup,
  PageHeaderContainer,
  Vr,
} from "./styles"

interface IPageHeader {
  heading?: string | JSX.Element
  infoText?: string | JSX.Element
  showButton?: boolean
  onClickButton?: () => void
  buttonType?: "primary" | "secondary"
  buttonText?: string
  buttonWidth?: number
  showHelpCenter?: boolean
  helpCenterLinkUrl?: string
  showViewIntro?: boolean
  onClickViewIntro?: () => void
  id?: string
  color?: string
  cardIconUrl?: string
}

export const PageHeader = (props: IPageHeader) => {
  const {
    onClickViewIntro,
    helpCenterLinkUrl,
    showButton,
    onClickButton,
    buttonWidth,
    buttonText,
    buttonType,
    id,
    showViewIntro,
    showHelpCenter,
    heading,
    infoText,
    color,
    cardIconUrl,
  } = props
  const { isDesktop } = usePortalSize()

  return (
    <PageHeaderContainer>
      {isDesktop && (
        <Heading id={id} color={color} lineHeight="1.6">
          {heading || ""}
          {infoText && (
            <Section
              fontSize="15px"
              fontWeight="400"
              fontColor={colors.gray40}
              padding="10 0 0"
            >
              {infoText}
            </Section>
          )}
        </Heading>
      )}
      <div>
        {!isDesktop && <CardIcon src={cardIconUrl} />}
        {isDesktop && (
          <ButtonContainer>
            <ButtonSmall
              hidden={!showButton || (buttonType && buttonType !== "primary")}
              width={buttonWidth || 115}
              onClick={onClickButton}
            >
              {buttonText}
            </ButtonSmall>
            <ButtonSecondary
              hidden={!showButton || buttonType !== "secondary"}
              width={buttonWidth || 115}
              onClick={onClickButton}
            >
              {buttonText}
            </ButtonSecondary>
            {showViewIntro &&
              onClickViewIntro &&
              showHelpCenter &&
              helpCenterLinkUrl && (
                <IconGroup>
                  <Tooltip
                    id="view-intro-tooltip"
                    content={<p>View Intro</p>}
                  />
                  <IconButton
                    data-tip
                    data-for="view-intro-tooltip"
                    src="images/icon_view_intro.svg"
                    onClick={onClickViewIntro}
                  />
                  <Vr />
                  <Tooltip
                    id="help-center-tooltip"
                    content={<p>Help Center</p>}
                  />
                  <IconButton
                    data-tip
                    data-for="help-center-tooltip"
                    src="images/icon_knowledge_base_info_help.svg"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      window.open(helpCenterLinkUrl)
                    }}
                  />
                </IconGroup>
              )}
          </ButtonContainer>
        )}
      </div>
      {!isDesktop && (
        <Section flex="flex-start flex-start column">
          <Heading id={id} color={color} fontSizeMobile="21px">
            {heading || ""}
          </Heading>
          {infoText && (
            <Section fontSize="15px" fontColor={colors.gray40} padding="6 0 0">
              {infoText}
            </Section>
          )}
        </Section>
      )}
    </PageHeaderContainer>
  )
}
export default PageHeader
