import { Tab } from "@blueprintjs/core"
import { Text } from "@src/Components/Text"
import { Panel } from "@src/Components/View"
import { colors } from "@src/theme"
import * as React from "react"
import Select from "react-select"

import { usePortalSize } from "../usePortalSize"
import {
  ContentStyled,
  Header,
  SelectContainer,
  TabsStyled,
  TabText,
} from "./styles"

interface ITabs {
  id: string
  text: string | JSX.Element
  shortText?: string
  disabled?: boolean
}

interface IProps {
  tabs: ITabs[]
  selectedTabId: string
  onTabChange: (key: string) => void
  children?: React.ReactNode
  borderColor?: string
  headTitle?: string
}

const ZegTabs = ({ tabs, selectedTabId, onTabChange }: IProps) => {
  const { clientWidth } = usePortalSize()

  if (!tabs) {
    return null
  }

  const handleTabsChange = (value: string) => {
    const newTab = tabs.find((tab) => tab.id === value)
    if (!newTab?.disabled) {
      onTabChange(value)
    }
  }

  return (
    <TabsStyled
      id="TrainingTabs"
      onChange={handleTabsChange}
      selectedTabId={selectedTabId}
    >
      {tabs.map((tab) => (
        <Tab id={tab.id} key={tab.id}>
          <TabText selected={selectedTabId === tab.id} disabled={tab.disabled}>
            {(clientWidth > 900 && clientWidth < 950 && tab.shortText) ||
              tab.text}
          </TabText>
        </Tab>
      ))}
    </TabsStyled>
  )
}

const ZegSelect = ({ tabs, selectedTabId, onTabChange }: IProps) => {
  const selectTabs = tabs.map((tab) => ({ value: tab.id, label: tab.text }))
  const selectedTab = selectTabs.find((tab) => tab.value === selectedTabId)

  return (
    <SelectContainer margin={"0 0 12px"}>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        value={selectedTab}
        options={selectTabs}
        onChange={(tab) => onTabChange(tab?.value || "")}
        defaultValue={selectTabs[0]}
        isSearchable={false}
      />
    </SelectContainer>
  )
}

const ZegPanel = ({
  tabs,
  selectedTabId,
  onTabChange,
  children,
  borderColor,
  headTitle,
}: IProps) => {
  const { isDesktop } = usePortalSize()

  return (
    <Panel borderColor={borderColor}>
      <Header>
        {!isDesktop ? (
          <>
            {headTitle && (
              <Text
                fontSize="16px"
                fontWeight="600"
                color={colors.black50}
                lineHeight="30px"
              >
                {headTitle}
              </Text>
            )}
            <ZegSelect
              onTabChange={onTabChange}
              selectedTabId={selectedTabId}
              tabs={tabs}
            />
          </>
        ) : (
          <ZegTabs
            onTabChange={onTabChange}
            selectedTabId={selectedTabId}
            tabs={tabs}
          />
        )}
      </Header>
      <ContentStyled>{children}</ContentStyled>
    </Panel>
  )
}

export default ZegPanel
