import { media } from "@src/theme"
import styled from "styled-components"

interface ILoaderBlock {
  width: string
  height: string
  margin?: string
}

interface ILoaderCardContainer {
  height?: number
}

export const CardWrapper = styled.div<{ gridColumn?: string }>`
  overflow: hidden;
  width: 100%;
  @media ${media.medium} {
    width: 100%;
    margin-right: 0;
  }
  @media ${media.large} {
    grid-column: ${({ gridColumn }) => gridColumn || "span 4"};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: none;
  }
`
export const HeaderBlock = styled.div`
  height: 10px;
  background: darkgrey;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
`
export const FooterBlock = styled.div`
  height: 1px;
  background: darkgrey;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
`
export const LoaderCardContainer = styled.div<ILoaderCardContainer>`
  width: 100%;
  padding: 38px 20px 25px 20px;
  max-height: ${({ height }) => height || "365"}px;
  min-height: ${({ height }) => height || "365"}px;
  position: relative;
  border-right: solid 1px;
  border-left: solid 1px;
  color: darkgrey;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 1s infinite;
    content: "";
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`
export const LoaderBlock = styled.div<ILoaderBlock>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  background: #d6d6d6;
`

export const LoaderCardHead = styled.div`
  display: flex;
  margin-bottom: 33px;
  align-items: center;
`

export const LoaderButton = styled.div`
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 16px;
  width: 200px;
  height: 32px;
  position: absolute;
  bottom: 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  background: #d6d6d6;
  overflow: hidden;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 1s infinite;
    content: "";
  }
`

export const LoaderCardMain = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${media.medium} {
    max-width: 440px;
    margin: 0 auto;
  }
`
export const LoaderCardColumn = styled.div``
