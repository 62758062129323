import initial_state from "../../Model/Model"
import { tMarketplaceState, tStatus } from "../../types"
import * as ACTION from "../ActionTypes"

interface tAction {
  type: string
  payload: any
}

function reducer(state: tMarketplaceState, action: tAction) {
  if (!state) {
    state = initial_state.marketplace
  }

  switch (action.type) {
    case ACTION.SET_MARKETPLACE_JSON: {
      const data: tMarketplaceState = action.payload
      return { ...state, ...data }
    }
    case ACTION.SET_MARKETPLACE_STATUS: {
      const status: tStatus = action.payload
      return { ...state, status }
    }
    default:
      return state
  }
}

export default reducer
