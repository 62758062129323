import Waiting from "@src/Components/Waiting"
import * as React from "react"
import { Suspense } from "react"
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"
const CyberConciergeContainer = React.lazy(
  () => import("@src/Portal/CyberConcierge"),
)
const AlertDetails = React.lazy(
  () => import("@src/Portal/CyberConcierge/Tabs/Alerts/AlertDetails"),
)

export const CyberConciergeRouter = ({ match }: RouteComponentProps) => (
  <Suspense fallback={<Waiting text="" />}>
    <Switch>
      <Route
        exact
        path={`${match.path}/alerts/:conciergeMessageId`}
        component={AlertDetails}
      />
      <Route
        exact
        path={[match.path, `${match.path}/:tabId`]}
        component={CyberConciergeContainer}
      />
      <Redirect exact to="/404" />
    </Switch>
  </Suspense>
)
