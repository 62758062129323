import { ButtonCancel, ButtonSmall } from "@src/Components/Button"
import TextField from "@src/Components/Form/TextField"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { usePersonalDetailsValidation } from "@src/Portal/Settings/validation"
import * as T from "@src/types"
import { useFormik } from "formik"
import * as React from "react"
import { useSelector } from "react-redux"

import { SpinnerStyled } from "../styles"
import { ButtonWrapper, Form } from "./styles"

interface IEditUserForm {
  onClose: () => void
  userData: T.tUserProfile | undefined
}

const formInitial: Partial<T.tUserProfile> = {
  firstName: "",
  lastName: "",
  email: "",
  jobTitle: "",
}

export const EditUserForm = ({ onClose, userData }: IEditUserForm) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const userProfile = useSelector((state: tState) => state.portal.userProfile)
  const orgId = useSelector((state: tState) => state.portal.orgId || "")
  const personalDetailsScheme = usePersonalDetailsValidation()

  const formik = useFormik<Partial<T.tUserProfile>>({
    initialValues: userData || formInitial,
    enableReinitialize: true,
    validationSchema: personalDetailsScheme,
    onSubmit: (values) => {
      setIsLoading(true)
      C.Portal.editUser(
        values.cognitoId || "",
        {
          ...userProfile,
          orgId,
          ...values,
        },
        () => {
          setIsLoading(false)
          onClose()
        },
      )
    },
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextField
        label="First Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName || ""}
        name="firstName"
        error={Boolean(formik.errors.firstName && formik.touched.firstName)}
        errorMsg={formik.errors.firstName}
        margin="0 0 15px 0"
      />
      <TextField
        label="Last Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastName || ""}
        name="lastName"
        error={Boolean(formik.errors.lastName && formik.touched.lastName)}
        errorMsg={formik.errors.lastName}
        margin="0 0 15px 0"
      />
      <TextField
        label="Work Email Address"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email || ""}
        name="email"
        error={Boolean(formik.errors.email && formik.touched.email)}
        errorMsg={formik.errors.email}
        margin="0 0 15px 0"
        disabled
      />
      <TextField
        label="Job Title (Optional)"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.jobTitle || ""}
        name="jobTitle"
        error={Boolean(formik.errors.jobTitle && formik.touched.jobTitle)}
        errorMsg={formik.errors.jobTitle}
        margin="0 0 15px 0"
      />
      <ButtonWrapper>
        <ButtonCancel
          width={100}
          onClick={onClose}
          id="settings-edit-user-cancel"
          type="button"
        >
          Cancel
        </ButtonCancel>
        <ButtonSmall
          width={100}
          type="submit"
          disabled={!(formik.isValid && formik.dirty)}
          id="settings-edit-user-confirm"
        >
          {isLoading ? <SpinnerStyled size={25} /> : "Save"}
        </ButtonSmall>
      </ButtonWrapper>
    </Form>
  )
}
