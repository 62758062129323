import Tooltip from "@src/Components/Tooltip"
import { colors } from "@src/theme"
import { IMonitoringDashboardData } from "@src/types"
import * as moment from "moment"
import * as React from "react"

import { CardSlider } from "../../CardSlider"
import { Description, SliderCardWrapper } from "../../styles"
import { DateInfo } from "../../TrainingCard/styles"
import {
  Circle,
  Main,
  MainHead,
  MainSecondSlide,
  ScanInfo,
  ScanInfoWrapper,
  ScanNumber,
  SliderCardHead,
} from "../styles"

interface IFilledStateCard {
  data: IMonitoringDashboardData
}

export const FilledStateCard = ({ data }: IFilledStateCard) => {
  const dateInfoText = () => {
    if (data.webAppsScanned < 1 && !data.isScanInProgress) {
      return "No scans yet"
    } else if (data.isScanInProgress) {
      return "Scan in progress"
    }
    return `Updated ${moment(data.lastScanDate).format("MMM D, YYYY")}`
  }
  return (
    <>
      <CardSlider>
        <SliderCardWrapper>
          <Main>
            <ScanInfo>
              <ScanNumber>{data.webAppsScanned}</ScanNumber>
              <Description>
                Web{data.webAppsScanned > 1 ? "sites" : "site"}
                <br />
                Scanned
              </Description>
            </ScanInfo>
            <hr />
            <ScanInfo>
              <ScanNumber>{data.scansScheduled}</ScanNumber>
              <Description>
                {data.scansScheduled > 1 ? "Scans" : "Scan"}
                <br />
                Scheduled
              </Description>
            </ScanInfo>
          </Main>
          <DateInfo>{dateInfoText()}</DateInfo>
        </SliderCardWrapper>
        <SliderCardWrapper>
          <SliderCardHead data-tip data-for="card-wep-apps-monitoring-tooltip">
            {data.targetList?.length}{" "}
            {data.targetList?.length > 1 ? "Websites" : "Website"}
          </SliderCardHead>
          <MainSecondSlide>
            <MainHead>
              {data.webAppsScanned < 2
                ? "Issue Severity"
                : "Aggregated Issue Severity"}
            </MainHead>
            <ScanInfoWrapper>
              <ScanInfo width="105px">
                <ScanNumber>{data.webAppsIssues?.highs || 0}</ScanNumber>
                <Description>
                  {" "}
                  <Circle className="fas fa-circle" color={colors.error} /> High
                </Description>
              </ScanInfo>
              <ScanInfo width="105px">
                <ScanNumber>{data.webAppsIssues.mediums || 0}</ScanNumber>
                <Description>
                  {" "}
                  <Circle
                    className="fas fa-circle"
                    color={colors.orange90}
                  />{" "}
                  Medium
                </Description>
              </ScanInfo>
              <ScanInfo width="105px">
                <ScanNumber>{data.webAppsIssues.lows}</ScanNumber>
                <Description>
                  {" "}
                  <Circle
                    className="fas fa-circle"
                    color={colors.warning}
                  />{" "}
                  Low
                </Description>
              </ScanInfo>
            </ScanInfoWrapper>
          </MainSecondSlide>
          <DateInfo>{dateInfoText()}</DateInfo>
        </SliderCardWrapper>
      </CardSlider>
      {!!data.targetList.length && (
        <Tooltip
          id="card-wep-apps-monitoring-tooltip"
          content={
            <p>
              {data.targetList.map((t, index) => (
                <p key={`${t.url}-${index}`}>{t.name}</p>
              ))}
            </p>
          }
        />
      )}
    </>
  )
}
