import { colors } from "@src/theme"
import styled from "styled-components"

export const DocumentWrap = styled.div`
  height: 600px;
  overflow: auto;
  width: 100%;

  & .zeg-pdf-page {
    &:not(:last-child) {
      background: ${colors.gray3};
      padding-bottom: 5px;
    }

    & canvas {
      margin: auto;
    }
  }
`
