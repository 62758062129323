import { Section } from "@src/Components/StyledUtils"
import { colors } from "@src/theme"
import styled from "styled-components"

export const TopSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const SectionStyled = styled(Section)`
  padding: 32px 32px 20px;
  border: 1px solid rgba(0, 98, 217, 0.5);
  border-radius: 6px;
  font-family: montserrat;
`
export const CompanyName = styled.div`
  font-size: 21px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  vertical-align: sub;
  color: ${colors.darkBlue};
  padding: 0 0 10px;
  & img {
    transform: translateY(8px);
  }
`
