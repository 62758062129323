import { Spinner } from "@blueprintjs/core"
import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import C from "../../Controller"
import { tState } from "../../Model/Model"
import MagicLinkSignUp from "./MagicLinkSignUp"

interface IMagicLinkSignUpNavParams {
  linkId: string
}

const MagicLinkSignUpContainer = () => {
  const history = useHistory()
  const { linkId }: IMagicLinkSignUpNavParams = useParams()
  const [goToLogin, setGoToLogin] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const recaptchaRef = React.createRef<ReCAPTCHA>()

  const { authenticated, magicLinkSignup } = useSelector(
    (store: tState) => store.portal,
  )
  const { emailDomain } = useSelector((state: tState) => state.brand)

  const app = document.getElementById("portal")
  const email = magicLinkSignup.createdFor
  const linkStatus = magicLinkSignup.status
  const fetchStatus = magicLinkSignup.fetchStatus
  const signupStatus = magicLinkSignup.signupStatus || "READY"
  const signupError = magicLinkSignup.signupError || new Error("")

  const onMagicLinkSignUp = (password: string) => {
    C.heapIdentify(magicLinkSignup.createdFor || "")
    C.partnerStackIdentify(magicLinkSignup.createdFor || "", () =>
      C.Portal.magicLinkSignup(linkId, password, true),
    )
  }

  const executeCaptcha = () => {
    setIsLoading(true)
    const current: any = recaptchaRef.current
    current.execute()
  }

  const resetCaptcha = () => {
    const current: any = recaptchaRef.current
    current.reset()
  }

  const verifyToken = (token: any, callback: any) => {
    C.Recaptcha.verifyToken(token, callback)
  }

  React.useEffect(() => {
    C.initEnvironment()
    C.Portal.magicLinkSignupFetch(linkId)
    C.Recaptcha.loadScriptv2() //Load Recaptcha v2
    C.Portal.checkSession("SignUpContainer")
  }, [])

  React.useEffect(() => {
    if (goToLogin) {
      history.push("/signin")
      setIsLoading(false)
    }
    if (authenticated) {
      history.push("/")
      setIsLoading(false)
    }
    if (fetchStatus === "FAILED") {
      C.toastNotification(
        `Oops, something went wrong. Check your email invite and try again. Questions? Contact us at support@${emailDomain}.`,
        "danger",
      )
      history.push("/")
      setIsLoading(false)
    }
    if (fetchStatus === "SUCCESS") {
      if (linkStatus === "accepted" || linkStatus === "userExists") {
        C.toastNotification(
          `An account already exists. Sign in now. Having problems? Click the Forgot Password link, or email support@${emailDomain}.`,
          "danger",
        )
        history.push("/")
        setIsLoading(false)
      }
    }
  }, [authenticated, goToLogin, fetchStatus])

  if (
    authenticated === null ||
    fetchStatus === "LOADING" ||
    fetchStatus === "READY"
  ) {
    return (
      <div style={{ height: "100%", paddingTop: 200, textAlign: "center" }}>
        <Spinner size={25} /> Loading Cyber Safety Portal...
      </div>
    )
  }

  return (
    <MagicLinkSignUp
      email={email || ""}
      height={app?.clientHeight || 0}
      onMagicLinkSignUp={onMagicLinkSignUp}
      redirectToLogin={() => setGoToLogin(true)}
      recaptchaRef={recaptchaRef}
      executeCaptcha={executeCaptcha}
      verifyToken={verifyToken}
      resetCaptcha={resetCaptcha}
      signupStatus={signupStatus}
      signupError={signupError}
      isLoading={isLoading}
    />
  )
}

export default MagicLinkSignUpContainer
