import { Panel } from "@src/Components/View"
import { colors, media } from "@src/theme"
import styled from "styled-components"

export const CardIcon = styled.img`
  width: 80px;
  margin-right: 32px;
  margin-top: 8px;
`
export const CardBodyWrapper = styled.div<any>`
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 0 0 20px 0;
  font-size: 18px;
  line-height: 1.5;
  width: 100%;
`
export const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`
export const PanelStyled = styled(Panel)`
  @media ${media.medium} {
    border-bottom: 1px solid ${colors.gray5};
  }
`
