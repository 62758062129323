import { colors } from "@src/theme"
import styled from "styled-components"

export const CNGStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const CNGName = styled.span`
  font-size: 14px;
  color: ${colors.link};
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const CNGGrade = styled.span`
  background: ${({ color }) => (color === "#495057" ? colors.white : color)};
  color: ${({ color }) => (color === "#495057" ? colors.black : colors.white)};
  font-size: ${({ color }) => (color === "#495057" ? "11px" : "14px")};
  border-radius: 100px;
  border: ${({ color }) => color === "#495057" && `1px solid ${color}`};
  width: 25px;
  height: 25px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`
