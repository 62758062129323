import { Section } from "@src/Components/StyledUtils"
import LearnMoreSection from "@src/Portal/CyberConcierge/LearnMoreSection"
import * as React from "react"

import EmptyState from "./EmptyState"

const GeneralAlerts = () => {
  return (
    <Section w="100%" flexGrow="1" flex="space-between center column">
      <EmptyState /> <LearnMoreSection />
    </Section>
  )
}

export default GeneralAlerts
