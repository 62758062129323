import styled from "styled-components"

export const PageHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    align-items: center;
    justify-content: center;
  }
`

export const ButtonContainer = styled.div`
  justify-content: end;
  display: flex;
  align-items: center;
`

export const IconGroup = styled.div`
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  @media (max-width: 800px) {
    margin-left: 0px;
  }
`

export const IconButton = styled.img`
  padding: 13px 13px 9px 13px;
  cursor: pointer;
`

export const Vr = styled.div`
  width: 1px;
  height: 26px;
  background-color: #cbcbcb;
`

export const CardIcon = styled.img`
  width: 50px;
  margin-right: 10px;
`
