import { FaIcon } from "@src/Components/FaIcon"
import { Section } from "@src/Components/StyledUtils"
import * as React from "react"

import { StartAssessmentPanel } from "../../../styles"
import { ImageContainerEmptyState } from "../styles"

const EmptyState = () => {
  return (
    <Section w="100%" flexGrow="1" flex="space-between center column">
      <Section w="100%" flexGrow="1" flex="start center column">
        <StartAssessmentPanel>
          <p>
            <FaIcon className="far fa-info-circle" /> We have not yet identified
            any alerts for your organization.
            <br />
            If we do, we will send you an email with a link to details on this
            page.
          </p>
        </StartAssessmentPanel>
        <ImageContainerEmptyState flexGrow="1" flex="center center">
          <img
            src="/images/cyberconcierge/laptopInCloud.svg"
            alt="laptop in cloud"
          />
        </ImageContainerEmptyState>
      </Section>
    </Section>
  )
}

export default EmptyState
