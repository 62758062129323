import { actions as GLOBAL_MODALS } from "@src/Actions/Reducers/GlobalModalsReducer"
import Waiting from "@src/Components/Waiting"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import * as T from "@src/types"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"

import Training from "../Training"

const TrainingContainer = () => {
  const dispatch = useDispatch()
  const [mode, setMode] = React.useState<"reportCard" | "manageTrainee">(
    "reportCard",
  )

  const [trainingDataReady, setTrainingDataReady] = React.useState(false)

  const { status, target, trainingData } = useSelector(
    (store: tState) => store.training,
  )
  const { trainingNumberOfSeats, isHighestPlan } = useSelector(
    (store: tState) => store.plans,
  )

  React.useEffect(() => {
    C.heapTrack(`Viewed ${mode} Page`)
  }, [mode])

  React.useEffect(() => {
    const updateWithSync = trainingNumberOfSeats?.currentUsage != 0
    C.Training.loadTrainingData(updateWithSync)
  }, [])

  React.useEffect(() => {
    if (
      target === "saveTrainees" ||
      target === "editTrainees" ||
      (target === "deleteTrainee" && status === "LOADING")
    ) {
      setTrainingDataReady(false)
    }
    if (target === "loadTrainingData" && status === "READY") {
      setTrainingDataReady(true)
    }
  }, [target, status])

  if (!trainingDataReady) {
    return <Waiting text="Loading Training." />
  }

  const companyName = C.getEnvParam("OrgName")
  return (
    <Training
      hasTrainees={trainingData.traineesData.length > 0}
      mode={mode}
      companyName={companyName}
      trainingNumberOfSeats={trainingNumberOfSeats}
      onChangeMode={(mode: T.tTrainingMode) => setMode(mode)}
      onIntro={() => {
        dispatch(GLOBAL_MODALS.changeIntroModal("training"))
      }}
      isHighestPlan={isHighestPlan}
    />
  )
}

export default TrainingContainer
