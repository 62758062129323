import { media } from "@src/theme"
import styled from "styled-components"

export const TickIcon = styled.i`
  font-size: 18px;
  color: #1c7f30;
  width: 25px;
  height: 18px;
`

export const CheckDiv = styled.div<any>`
  height: 19px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  color: #212528;
  text-align: left;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: ${(props: any) => (props.margin ? "8px" : undefined)};
  @media ${media.medium} {
    display: none;
  }
`
