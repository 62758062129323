import Waiting from "@src/Components/Waiting"
import { ViewTypes } from "@src/types"
import * as React from "react"
import { Suspense } from "react"
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"
const MarketplaceContainer = React.lazy(
  () => import("@src/Portal/Marketplace/MarketplaceContainer"),
)

export const MarketplaceRouter = ({ match }: RouteComponentProps) => (
  <Suspense fallback={<Waiting text="" />}>
    <Switch>
      <Route
        exact
        path={[ViewTypes.marketplace, `${match.path}/:partner`]}
        component={MarketplaceContainer}
      />
      <Redirect exact to="/404" />
    </Switch>
  </Suspense>
)
