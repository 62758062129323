import { Link } from "@src/Components/Link"
import { colors, media } from "@src/theme"
import styled from "styled-components"

export const CardDescription = styled.div`
  flex-grow: 1;
  @media (min-width: 769px) {
    padding-right: 40px;
  }
`
export const LinkText = styled.span`
  @media ${media.medium} {
    max-width: 150px;
  }
`
export const LinkStyled = styled(Link)`
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`
export const HeadingStatus = styled.span`
  background: ${colors.violet};
  border-radius: 3px;
  margin-left: 18px;
  font-weight: 600;
  font-size: 13px;
  width: 50px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
`
export const HeadingWrapper = styled.span<{ margin?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  margin: ${({ margin }) => margin};
  font-size: 26px;
  font-weight: 700;
  color: ${colors.darkBlue};
  img {
    margin-right: 17px;
  }
`
export const ElementInfoModalStyled = styled.div`
  font-size: 15px;
  ul {
    margin: 5px 0 5px 5px;
    padding-left: 15px;
  }
`
export const ButtonContainer = styled.div`
  width: min(90%, 750px);
`
export const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ProgressDetails = styled.div`
  display: flex;
  width: 90%;
  max-width: 750px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 24px;
`
export const ProgressBarContainer = styled.div`
  width: 100%;
  padding: 8px 0 16px;
`
export const SubtleText = styled.div<{ align?: string }>`
  flex: 0 0 30%;
  color: ${colors.gray40};
  font-size: 15px;
  text-align: ${({ align }) => align};
`
export const UpdatedText = styled.div`
  display: flex;
  gap: 0 4px;
  flex-wrap: wrap;
  justify-content: center;
  flex: 0 0 40%;
  color: ${colors.gray40};
  font-size: 15px;
  text-align: center;
  margin-bottom: 8px;
`
