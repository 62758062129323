import ConfirmationModal from "@src/Components/ConfirmationModal"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import {
  CatalogMap as ICatalogMap,
  TraineeData as ITrainee,
} from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"
import { useSelector } from "react-redux"

import ModalEnroll from "../ModalEnroll"
import {
  ConfirmContent,
  ConfirmText,
  ConfirmTextBold,
  ConfirmTextGray,
  Content,
  InfoIcon,
  WarnIcon,
} from "./styles"

interface IColumnSpecial {
  trainee: ITrainee
  goToCourseLibraryTab: () => void
}
export interface ICoursesCS extends ICatalogMap {
  isEnrolled: boolean | undefined
  isFinished: boolean | undefined
}
const deleteConfirm = `All grades from unenrolled courses will be removed.`

const ColumnSpecial: React.FC<IColumnSpecial> = ({
  trainee,
  goToCourseLibraryTab,
}) => {
  const catalogMap = useSelector(
    (state: tState) => state.training.trainingData.catalogMap,
  )

  const [isEnrolling, setIsEnrolling] = React.useState(false)
  const [isConfirming, setIsConfirming] = React.useState(false)
  const [tempCourses, setTempCourses] = React.useState<ICoursesCS[]>([])
  const { firstName, lastName, email, inactive, courseResults, traineeId } =
    trainee

  const fullName = `${firstName} ${lastName}`
  const isPaused = inactive
  const enrolledSpecCourses = courseResults.filter(
    (course) => course.difficulty > 4,
  )
  const allCourses = React.useMemo(
    (): ICoursesCS[] =>
      catalogMap
        .filter((course) => course.isReportCard)
        .map((course) => ({
          ...course,
          isEnrolled: !!courseResults?.find(
            (courseResult) => courseResult.name === course.name,
          ),
          isFinished: !!courseResults?.find(
            (courseResult) =>
              courseResult.name === course.name && courseResult?.averageScore,
          ),
        })),
    [catalogMap, courseResults],
  )

  const enrolledCourses = allCourses.filter((course) => {
    const tempCourse = tempCourses.find(
      (tCourse) => tCourse.name === course.name,
    )
    return !course.isEnrolled && tempCourse?.isEnrolled
  })
  const unEnrolledCourses = allCourses.filter((course) => {
    const tempCourse = tempCourses.find(
      (tCourse) => tCourse.name === course.name,
    )
    return course.isEnrolled && !tempCourse?.isEnrolled
  })

  const onOpenModalEnroll = () => {
    if (!isPaused) {
      setIsEnrolling(true)
    }
  }

  const onEnroll = (data: ICoursesCS[]) => {
    setTempCourses(data)
    setIsConfirming(true)
  }
  const onConfirm = () => {
    setIsEnrolling(false)
    setIsConfirming(false)
    C.Training.editTraineeCourses([
      {
        firstName,
        lastName,
        email,
        traineeId,
        enrolledCourses: enrolledCourses.map((c) => c.id),
        unEnrolledCourses: unEnrolledCourses.map((c) => c.id),
      },
    ])
  }

  const goToCourseTab = () => {
    goToCourseLibraryTab()
    setIsConfirming(false)
  }
  return (
    <Content
      paused={isPaused}
      noCourses={enrolledSpecCourses && enrolledSpecCourses.length < 2}
    >
      <Section flex="flex-start flex-start column" gap="6px">
        {enrolledSpecCourses?.length ? (
          <>
            {enrolledSpecCourses.map((course) => (
              <div key={course.topics[0].id}>{course.name}</div>
            ))}
            <Link lineHeight="normal" onClick={onOpenModalEnroll}>
              Edit Training
            </Link>
          </>
        ) : (
          <Link lineHeight="normal" onClick={onOpenModalEnroll}>
            Add Training
          </Link>
        )}
      </Section>
      <ModalEnroll
        isOpen={isEnrolling && !isConfirming}
        fullName={fullName}
        courses={allCourses}
        onEnroll={onEnroll}
        onClose={() => setIsEnrolling(false)}
        goToCourseTab={goToCourseTab}
      />
      <ConfirmationModal
        isOpen={isConfirming}
        title={`Update Enrollment for ${fullName}?`}
        confirmButton="Update Enrollment"
        onClose={() => setIsConfirming(false)}
        onConfirm={onConfirm}
      >
        <ConfirmContent>
          <ConfirmText>
            {`Please confirm your training enrollment changes for ${fullName} `}
            <ConfirmTextGray>({email})</ConfirmTextGray>?
          </ConfirmText>

          {!!enrolledCourses.length && (
            <>
              <ConfirmTextBold>
                <InfoIcon className="fas fa-info-circle" />
                Courses they will be enrolled in:
              </ConfirmTextBold>
              <ConfirmText>
                {`${enrolledCourses.map((item) => item.name).join(", ")}.`}
              </ConfirmText>
            </>
          )}

          {!!unEnrolledCourses.length && (
            <>
              <ConfirmTextBold>
                <WarnIcon className="fas fa-exclamation-triangle" />
                Courses they will be unenrolled from:
              </ConfirmTextBold>
              <ConfirmText>
                {`${unEnrolledCourses.map((item) => item.name).join(", ")}.`}
              </ConfirmText>
              <ConfirmText>
                <ConfirmTextGray>{deleteConfirm}</ConfirmTextGray>
              </ConfirmText>
            </>
          )}
        </ConfirmContent>
      </ConfirmationModal>
    </Content>
  )
}

export default ColumnSpecial
