import { colors, media } from "@src/theme"
import styled from "styled-components"

interface IButtonRowProps {
  disabled: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Section = styled.div`
  margin: 20px;
`

export const InfoText = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  margin: 10px;
`
export const InfoTextWithBg = styled(InfoText)`
  background-color: ${colors.blueGradient2};
  border: 1px solid ${colors.blueBorderGradient2};
  border-radius: 6px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  text-align: left;
  i {
    margin-right: 10px;
  }
  }
`

export const InfoTextGray = styled(InfoText)`
  margin-top: 10px;
  color: ${colors.gray40};
`

export const FormWrap = styled.div`
  max-height: 500px;
  overflow: auto;

  @media ${media.medium} {
    max-height: 300px;
  }
`

export const ButtonRow = styled.div<IButtonRowProps>`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  opacity: ${({ disabled }) => disabled && 0.7};
  pointer-events: ${({ disabled }) => disabled && "none"};
  @media (max-width: 500px) {
    flex-direction: column;
  }
`

export const Icon = styled.i`
  font-size: 16px;
  margin: 0 5px;
`

export const Link = styled.div`
  color: #0062d8;
  cursor: pointer;
`

export const UpgradeSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px;
`
