import initial_state from "../../Model/Model"
import {
  IEligiblePlan,
  tChannelUiRules,
  tPlansRequestState,
  tPlansState,
  tStatus,
} from "../../types"
import * as ACTION from "../ActionTypes"

interface tAction {
  type: string
  payload: any
}

function reducer(state: tPlansState, action: tAction) {
  if (!state) {
    state = initial_state.plans
  }

  switch (action.type) {
    case ACTION.SET_SAAS_PLANS: {
      const plans: tPlansRequestState = action.payload
      return {
        ...state,
        ...plans,
        channelObject: {
          ...plans.channelObject,
          eligiblePlans: state.channelObject.eligiblePlans,
        },
      }
    }
    case ACTION.SET_UI_RULES: {
      const uiRules: tChannelUiRules = action.payload
      return {
        ...state,
        channelObject: {
          uiRules: { ...uiRules },
          eligiblePlans: state.channelObject.eligiblePlans,
        },
      }
    }
    case ACTION.SET_SAAS_PLANS_STATUS: {
      const status: tStatus = action.payload
      return { ...state, status }
    }
    case ACTION.SET_ELIGIBLE_PLANS: {
      const eligiblePlans: IEligiblePlan[] = action.payload
      return {
        ...state,
        channelObject: {
          ...state.channelObject,
          eligiblePlans,
        },
      }
    }
    case ACTION.SET_ELIGIBLE_PLANS_STATUS: {
      const eligiblePlansStatus: tStatus = action.payload
      return { ...state, eligiblePlansStatus }
    }
    case ACTION.SET_UPDATE_PLANS_STATUS: {
      const updatePlansStatus: tStatus = action.payload
      return { ...state, updatePlansStatus }
    }
    case ACTION.SET_CHARGEBEE_SUBSCRIPTION_STATUS: {
      const chargebeeSubscriptionStatus: boolean = action.payload
      return { ...state, chargebeeSubscriptionStatus }
    }
    default:
      return state
  }
}

export default reducer
