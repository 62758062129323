import * as T from "../types"
import {IDashboardMonitoring, tDashboard, tUserInviteList} from "../types"
import * as ACTION from "./ActionTypes"
import {SET_USER_INVITE_LIST} from "./ActionTypes";

export const setPortalMenu = (newItem: string) => ({
  type: ACTION.SET_PORTAL_MENU,
  payload: { item: newItem },
})

export const setDashboardActivity = (data: tDashboard) => ({
  type: ACTION.SET_DASHBOARD_ACTIVITY,
  payload: data,
})

export const setDashboardMonitoring = (data: IDashboardMonitoring) => ({
  type: ACTION.SET_DASHBOARD_MONITORING,
  payload: data,
})

export const setEnvironment = (env: any) => ({
  type: ACTION.SET_ENVIRONMENT,
  payload: env,
})

export const setConfig = (config: T.tConfig) => ({
  type: ACTION.SET_CONFIG,
  payload: config,
})

export const setUserProfile = (userProfile: T.tUserProfile) => ({
  type: ACTION.SET_USER_PROFILE,
  payload: userProfile,
})

export const setSignupData = (signupData: T.ISignupData) => ({
  type: ACTION.SET_SIGNUP_DATA,
  payload: signupData,
})

export const setUserEmail = (email: string) => {
  return {
    type: ACTION.SET_USER_EMAIL,
    payload: email,
  }
}

export const setCompanyProfile = (
  profile: any,
  orgId: string,
  companyProfileState: T.tStatus,
) => ({
  type: ACTION.SET_COMPANY_PROFILE,
  payload: { profile, orgId, companyProfileState },
})

export const setAuthenticated = (
  authenticated: boolean | null,
  cognitoId: string | null,
  newUser: boolean | null,
  isZeguroServiceSession: boolean,
) => ({
  type: ACTION.SET_AUTHENTICATED,
  payload: {
    authenticated,
    cognitoId,
    newUser,
    isZeguroServiceSession,
  },
})

export const setProduction = (
  isProduction: boolean,
  productionMode: string,
) => ({
  type: ACTION.SET_PRODUCTION,
  payload: { isProduction, productionMode },
})

export const setLegalDisclaimer = (disclaimer: string) => ({
  type: ACTION.SET_LEGAL_DISCLAIMER,
  payload: disclaimer,
})

export const setAccountsPageMode = (mode: string) => ({
  type: ACTION.SET_ACCOUNTS_PAGE_MODE,
  payload: mode,
})

export const setPortalEnvironment = (response: any) => ({
  type: ACTION.SET_PORTAL_ENVIRONMENT,
  payload: response,
})

export const setUserList = (userList: T.tUserList) => ({
  type: ACTION.SET_USER_LIST,
  payload: userList,
})

export const setUserInviteList = (userInviteList: T.tUserInviteList) => ({
  type: ACTION.SET_USER_INVITE_LIST,
  payload: userInviteList,
})

export const setUserListError = (error: any) => ({
  type: ACTION.SET_USER_LIST_ERROR,
  payload: error,
})
export const setUserInviteListError = (error: any) => ({
  type: ACTION.SET_USER_INVITE_LIST_ERROR,
  payload: error,
})
export const setMagicLinkSignup = (
  magicLinkSignupFetchResponse: T.tMagicLinkSignupFetchResponse,
) => ({
  type: ACTION.SET_MAGIC_LINK_SIGNUP,
  payload: magicLinkSignupFetchResponse,
})
