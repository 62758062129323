import { Section } from "@src/Components/StyledUtils"
import { tState } from "@src/Model/Model"
import {
  FaIconCustom,
  SectionContent,
  SectionHeading,
} from "@src/Portal/CyberConcierge/styles"
import * as React from "react"
import ReactMarkdown from "react-markdown"
import { useSelector } from "react-redux"

import LearnMoreSection from "../../LearnMoreSection"
import { Heading, OfferingRow, OfferingSection } from "./styles"

const About = () => {
  const cyberConciergeConfig = useSelector(
    (store: tState) => store.cyberConcierge?.getCyberConciergeConfig,
  )
  const { cyberConciergePage } = cyberConciergeConfig

  return (
    <Section w="100%" flexGrow="1" flex="space-between center column">
      <Section flex="start start column">
        <Heading>
          <ReactMarkdown>
            {cyberConciergePage.cyberConciergeOfferingsHeading}
          </ReactMarkdown>
        </Heading>
        <OfferingSection>
          {cyberConciergePage.cyberConciergeOfferings.map((offering, index) => {
            return (
              <OfferingRow key={index}>
                <FaIconCustom className={offering.cyberConciergeOfferingIcon} />
                <div>
                  <SectionHeading>
                    {offering.cyberConciergeOfferingHeading}
                  </SectionHeading>
                  <SectionContent>
                    <ReactMarkdown>
                      {offering.cyberConciergeOfferingDescription}
                    </ReactMarkdown>
                  </SectionContent>
                </div>
              </OfferingRow>
            )
          })}
        </OfferingSection>
      </Section>
      <LearnMoreSection />
    </Section>
  )
}

export default About
