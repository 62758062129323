import styled from "styled-components"

interface IActionLink {
  hidden?: boolean
}

interface IStatusBadge {
  hidden?: boolean
  status?: string
  flex?: string
  color?: string
  backgroundColor?: string
}

export const NamePanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PopoverLink = styled.div`
  cursor: pointer;
`

export const PopUpContent = styled.div`
  max-width: 350px;
  font-size: 16px;
`

export const Title = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`

export const GroupDescription = styled.span`
  font-size: 16px;
  padding-top: 4px;
  color: #212528;
  font-weight: normal;
  font-family: montserrat;
`

export const DescriptionText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`

export const ActionLink = styled.div`
  display: ${(p: IActionLink) => (p.hidden ? "none" : "inline-block")};
  font-size: 16px;
  line-height: 1.31;
  letter-spacing: 0.2px;
  color: #212528;
  white-space: nowrap;
  cursor: pointer;
`

export const InactiveText = styled.span`
  color: #767676;
  font-size: 16px;
`

export const BadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 4px 0;
  gap: 6px 10px;
`

export const StatusBadge = styled.div`
  display: ${(p: IStatusBadge) => (p.hidden ? "none" : "inline-block")};
  height: 18px;
  white-space: nowrap;
  border-radius: 3px;
  background-color: ${(p: IStatusBadge) => p.backgroundColor || "initial"};
  color: ${(p: IStatusBadge) => p.color || "#fff"};
  font-size: 13px;
  line-height: 19px;
  padding: 0px 10px;
  text-align: center;
  text-indent: 0px;
`

export const ConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ConfirmText = styled.div`
  font-size: 16px;
  margin: 5px 0;
`

export const TableContainer = styled.div`
  overflow: auto;
  & table {
    min-width: 100%;
  }
`
