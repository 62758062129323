import { createSlice } from "@reduxjs/toolkit"
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction"
import { basicInitialState, tCustomerPortalDataState } from "@src/Model/Model"
import { CustomerPortalData } from "@zeguro/schema-validator/dist/types/coco/core/combined"

export const initialCustomerPortalDataState: tCustomerPortalDataState = {
  data: null,
  getCustomerPortalData: {
    ...basicInitialState,
  },
  updateCustomerPortalData: {
    ...basicInitialState,
  },
}

const customerPortalDataSlice = createSlice({
  name: "customerPortalData",
  initialState: initialCustomerPortalDataState,
  reducers: {
    getCustomerPortalDataRequest({ getCustomerPortalData }) {
      getCustomerPortalData.isLoading = true
      getCustomerPortalData.error = undefined
    },
    getCustomerPortalDataSuccess(
      store,
      { payload }: PayloadAction<CustomerPortalData["data"]>,
    ) {
      store.data = payload
      store.getCustomerPortalData.isLoading = false
      store.getCustomerPortalData.error = undefined
    },
    getCustomerPortalDataFailure(
      { getCustomerPortalData },
      { payload }: PayloadAction<Error>,
    ) {
      getCustomerPortalData.error = payload
      getCustomerPortalData.isLoading = false
    },
    updateCustomerPortalDataRequest({ updateCustomerPortalData }) {
      updateCustomerPortalData.isLoading = true
      updateCustomerPortalData.error = undefined
    },
    updateCustomerPortalDataSuccess(
      store,
      { payload }: PayloadAction<CustomerPortalData["data"]>,
    ) {
      store.data = { ...store.data, ...payload }
      store.updateCustomerPortalData.isLoading = false
      store.updateCustomerPortalData.error = undefined
    },
    updateCustomerPortalDataFailure(
      { updateCustomerPortalData },
      { payload }: PayloadAction<Error>,
    ) {
      updateCustomerPortalData.error = payload
      updateCustomerPortalData.isLoading = false
    },
  },
})

export const { name, actions, reducer } = customerPortalDataSlice
