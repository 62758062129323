import { Section } from "@src/Components/StyledUtils"
import styled from "styled-components"

export const ImageContainerEmptyState = styled(Section)`
  @media (min-width: 600px) {
    margin-top: 30px;
  }
  @media (max-width: 400px) {
    img {
      width: 100%;
    }
  }
`
