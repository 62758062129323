import styled from "styled-components"

export const NAV_BAR_HEIGHT = 76

export const Wait = styled.div`
  text-align: center;
  font-size: 16px;
  width: 100%;
`

export const ButtonRow = styled.div<any>`
  display: ${(props: any) => (props.hidden ? "none" : "block")};
  text-align: center;
  margin-top: 30px;
  width: 100%;
`
