import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import Dashboard from "./Dashboard"

const DashboardContainer = () => {
  const { modules } = useSelector((state: tState) => state.plans)
  const history = useHistory()
  React.useEffect(() => {
    C.Portal.loadModuleActivity()
    C.Portal.loadDashboardMonitoring()
  }, [])
  const setView = (view: string) => history.push(view)

  return <Dashboard modules={modules} changeView={setView} />
}

export default DashboardContainer
