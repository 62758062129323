import { Link } from "@src/Components/Link"
import { StyledMarkdown } from "@src/Components/Markdown/styles"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { glossarySelector } from "@src/Selectors/utils"
import { getPrivateAsset } from "@src/Utils"
import * as React from "react"
import {
  ComponentPropsWithoutRef,
  ReactMarkdownProps,
} from "react-markdown/lib/ast-to-react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import Tooltip from "../Tooltip"
import { LinkStyled } from "./styles"

interface IMarkdown {
  children: string
  margin?: string
  pMaxWidth?: string
}
type PropsHelper<Element extends React.ElementType> =
  ComponentPropsWithoutRef<Element> & ReactMarkdownProps

export const Markdown = ({ children, margin, pMaxWidth }: IMarkdown) => {
  return (
    <StyledMarkdown
      margin={margin}
      pMaxWidth={pMaxWidth}
      components={{
        a: MarkdownLink,
      }}
    >
      {children}
    </StyledMarkdown>
  )
}

const MarkdownLink = ({ href, children }: PropsHelper<"a">) => {
  const history = useHistory()
  const glossary = useSelector((state: tState) => glossarySelector(state))
  const linkText = String(children[0]).toLowerCase()
  const handlerOnCLick = () => {
    const linkUrl = String(href)
    if (linkUrl.startsWith("https://zeguro-private-assets")) {
      getPrivateAsset(linkUrl)
    } else if (linkUrl.startsWith("http")) {
      window.open(linkUrl, "_blank")
    } else if (linkUrl !== "tooltip") {
      history.push(linkUrl)
    }
  }
  if (glossary[linkText]) {
    return (
      <LinkStyled
        data-tip
        data-for={String(children)}
        onClick={handlerOnCLick}
        onMouseEnter={() => C.heapTrack(`hover on ${children[0]}`)}
      >
        <Tooltip content={<p>{glossary[linkText]}</p>} id={String(children)} />
        {children}
      </LinkStyled>
    )
  } else {
    return <Link onClick={handlerOnCLick}>{children}</Link>
  }
}
