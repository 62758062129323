import { colors, media, shadows } from "@src/theme"
import styled from "styled-components"

import { P } from "./StyledUtils"

export const Spacer = styled.div<any>`
  display: inline-block;
  width: ${P("width")}px;
`

interface ISubHeading {
  fontWeight?: string
  color?: string
  textAlign?: string
  width?: string
  margin?: string
  display?: string
  align?: string
}

interface IVSpacer {
  height?: number
  hidden?: boolean
}

interface IContent {
  height?: number
  backgroundImage?: string
}
export const VSpacer = styled.div<any>`
  height: ${(props: IVSpacer) =>
    props.height != undefined ? props.height : 30}px;
  display: ${(props: IVSpacer) => (props.hidden ? "none" : undefined)};
`

export const View = styled.div`
  font-family: proxima-nova, sans-serif;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(306deg, #f7f7f7, #ffffff);
`

export const FormHeader = styled.div`
  font-family: montserrat;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212294;
  margin-bottom: 15px;
`

export const ErrorContainer = styled.div`
  color: red;
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  font-weight: bold;
  padding-top: 20px;
`

export const Heading = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  gap: 0 24px;
  font-family: montserrat;
  font-size: ${(props: any) => props.fontSize || "32px"};
  font-weight: 600;
  text-align: ${(props: any) => props.align || "left"};
  align-self: ${(props: any) => props.align || "left"};
  justify-content: ${(props: any) => props.align || "left"};
  color: ${(props: any) => props.color || colors.darkBlue};
  line-height: ${(props: any) => props.lineHeight || "38px"};
  letter-spacing: 0.005em;
  align-items: center;
  margin: ${(props: any) => props.margin};
  width: ${(props: any) => props.width};
  text-transform: ${(props: any) => props.uppercase && "uppercase"};
  @media ${media.medium} {
    font-size: ${(props: any) => props.fontSizeMobile || "27px"};
    display: flex;
    align-items: end;
    line-height: inherit;
    margin: 0 0 0 0;
  }
`

export const PageDescription = styled.div`
  font-family: proxima-nova, sans-serif;
  margin-top: 10px;
  color: #6b696c;
  font-size: 16px;
  line-height: 22px;
`

export const Link = styled.div<any>`
  // TODO need to remove
  display: ${(props: any) => (props.hidden ? "none" : "flex")};
  margin: 20px 0px 0px 0px;
  width: ${(props: any) => props.width || "100%"};
  text-align: center;
  font-family: proxima-nova, sans-serif;
  align-items: center;
  font-size: 15px;
  line-height: 21px;
  color: #858585;
  justify-content: center;
  font-weight: ${(props: any) => props.fontWeight || "600"};
  > p > a {
    color: #0062d9;
  }
`
export const LinkWrapper = styled.div<any>`
  display: ${(props: any) => (props.hidden ? "none" : "flex")};
  flex-direction: ${(props: any) => props.column && "column"};
  margin: 20px 0px 0px 0px;
  width: ${(props: any) => props.width || "100%"};
  text-align: center;
  font-family: proxima-nova, sans-serif;
  align-items: center;
  font-size: 15px;
  line-height: 21px;
  color: #858585;
  justify-content: center;
  font-weight: ${(props: any) => props.fontWeight || "600"};
  > p > a {
    color: #0062d9;
  }
`
export const Content = styled.div<IContent>`
  font-family: proxima-nova, sans-serif;
  height: ${({ height }) => height && `${height}px`};
  background-color: #fff;
  background-image: ${({ backgroundImage }) =>
    backgroundImage || `url("images/illo_cityscape_login.svg")`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1260px;
  background-attachment: fixed;
`

export const FormElement = styled.div<any>`
  display: ${(props: any) => (props.hidden ? "none" : "flex")};
  flex-direction: ${(props: any) => props.flexDirection || "column"};
  justify-content: space-between;
  margin-bottom: ${(props: any) => props.marginBottom};
  align-items: center;
  width: ${(props: any) => props.width || "100%"};
  @media ${media.medium} {
    flex-direction: ${(props: any) => props.flexDirectionMobile || "column"};
  }
`
export const FormContainer = styled(FormElement).attrs({
  as: "form",
})``

export const PanelContainer = styled.div<any>`
  height: ${(props: any) => props.height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media ${media.medium} {
    width: 100%;
  }
`

export const Panel = styled.div<any>`
  margin-top: ${(props: any) => props.marginTop}px;
  margin-left: ${(props: any) => props.marginLeft}px;
  padding: 20px 40px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: ${shadows.panel};
  text-align: center;
  width: ${(props: any) => props.width || undefined};
  z-index: 7;
  @media ${media.medium} {
    width: 100%;
    margin: 0px;
    margin-top: ${(props: any) => props.marginTop}px;
    flex: 1 0 auto;
    padding: 20px 20px;
    justify-content: center;
  }
`

export const SubHeading = styled.div<ISubHeading>`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color || colors.black50};
  text-align: ${({ textAlign }) => textAlign || "center"};
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin || "0px 0px 20px 0px"};
  display: ${({ display }) => display};
  align-self: ${({ align }) => align || "left"};
  justify-content: ${({ align }) => align || "left"};
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
`

export const Divider = styled.hr<any>`
  display: ${(props: any) => (props.hidden ? "none" : undefined)};
  width: ${(props: any) => props.width || "100%"};
  margin: ${(props: any) => props.margin || "0px"};
  height: ${(props: any) => props.height};
  border: none;
  border-bottom: 1px solid #d6d6d6;
`

export const PopoverContent = styled.div<any>`
  width: ${(props: any) => props.width || "298px"};
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  padding: 10px;
`
