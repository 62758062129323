import * as React from "react"

import {
  CardWrapper,
  FooterBlock,
  HeaderBlock,
  LoaderBlock,
  LoaderButton,
  LoaderCardColumn,
  LoaderCardContainer,
  LoaderCardHead,
  LoaderCardMain,
} from "./styles"

interface ILoaderCard {
  gridColumn?: string
  height?: number
}

export const LoaderCard = ({ height, gridColumn }: ILoaderCard) => (
  <CardWrapper gridColumn={gridColumn}>
    <LoaderCardContainer height={height}>
      <HeaderBlock />
      <LoaderCardHead>
        <LoaderBlock
          className="skeleton"
          height="44px"
          width="44px"
          margin="0 18px 0 0"
        />
        <LoaderBlock className="skeleton" height="20px" width="157px" />
      </LoaderCardHead>
      <LoaderCardMain>
        <LoaderCardColumn>
          <LoaderBlock
            className="skeleton"
            height="33px"
            width="68px"
            margin="0 0 13px 0"
          />
          {(!height || height > 240) && (
            <LoaderBlock
              className="skeleton"
              height="16px"
              width="68px"
              margin="0 0 13px 0"
            />
          )}
          {(!height || height > 220) && (
            <LoaderBlock
              className="skeleton"
              height="16px"
              width="68px"
              margin="0 0 13px 0"
            />
          )}
        </LoaderCardColumn>
        <LoaderCardColumn>
          <LoaderBlock
            className="skeleton"
            height="33px"
            width="68px"
            margin="0 0 13px 0"
          />
          {(!height || height > 240) && (
            <LoaderBlock
              className="skeleton"
              height="16px"
              width="68px"
              margin="0 0 13px 0"
            />
          )}
          {(!height || height > 220) && (
            <LoaderBlock
              className="skeleton"
              height="16px"
              width="68px"
              margin="0 0 13px 0"
            />
          )}
        </LoaderCardColumn>
        <LoaderCardColumn>
          <LoaderBlock
            className="skeleton"
            height="33px"
            width="68px"
            margin="0 0 13px 0"
          />
          {(!height || height > 240) && (
            <LoaderBlock
              className="skeleton"
              height="16px"
              width="68px"
              margin="0 0 13px 0"
            />
          )}
          {(!height || height > 220) && (
            <LoaderBlock
              className="skeleton"
              height="16px"
              width="68px"
              margin="0 0 13px 0"
            />
          )}
        </LoaderCardColumn>
      </LoaderCardMain>
      <LoaderButton className="skeleton" />
      <FooterBlock />
    </LoaderCardContainer>
  </CardWrapper>
)
