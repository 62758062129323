export const industries: string[][] = [
  ["Banking or Investment Management", "BANKING_INVESTMENT", "Finance"],
  ["Business and Professional Services", "BUSINESS_PROFESSIONAL_SERVICES"],
  ["Construction", "CONSTRUCTION"],
  ["Education", "EDUCATION"],
  ["Energy", "ENERGY"],
  ["Entertainment and Media", "ENTERTAINMENT_MEDIA"],
  ["Food and Agriculture", "FOOD_AGRICULTURE"],
  ["Hospitals or Nursing Homes", "HOSPITALS_NURSING_HOMES", "Healthcare"],
  ["Insurance", "INSURANCE", "Finance"],
  ["IT - Hardware, Software, or Services", "IT"],
  ["Manufacturing", "MANUFACTURING"],
  ["Mining and Primary Industries", "MINING_PRIMARY_INDUSTRIES"],
  ["Municipalities", "MUNICIPALITIES"],
  ["Non-Profit", "NON_PROFIT"],
  ["Other Healthcare", "OTHER_HEALTH_CARE", "Healthcare"],
  ["Pharmaceuticals", "PHARMACEUTICALS"],
  ["Real Estate", "REAL_ESTATE"],
  ["Retail", "RETAIL"],
  ["Telecommunications", "TELECOMMUNICATIONS"],
  ["Tourism and Hospitality", "TOURISM_HOSPITALITY"],
  ["Transportation", "TRANSPORTATION"],
  ["Utilities", "UTILITIES"],
  [
    "Adult Business, Gambling, or Cannabis related industries",
    "ADULT_GAMBLING_CANNABIS",
  ],
  ["Other", "OTHER"],
]

export const noOfEmployees: string[][] = [
  ["0-10"],
  ["11-20"],
  ["21-30"],
  ["31-40"],
  ["41-50"],
  ["51-100"],
  ["More than 100"],
]
