import { actions as GLOBAL_MODALS } from "@src/Actions/Reducers/GlobalModalsReducer"
import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import Dialog from "@src/Components/Dialog"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import { tState } from "@src/Model/Model"
import { updateCustomerPortalData } from "@src/Sagas"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"

import featuresCfg from "./IntroDialogCfg"
import {
  Body,
  ButtonSpacer,
  Container,
  Counter,
  Footer,
  Header,
  HeaderImg,
  Text,
  Title,
} from "./styles"

interface IIntroDialog {
  isOpen: boolean
}

const IntroDialog = ({ isOpen }: IIntroDialog) => {
  const dispatch = useDispatch()
  const isZeguroServiceSession = useSelector(
    (state: tState) => state.portal.isZeguroServiceSession,
  )
  const { openedFeature, currentPage } = useSelector(
    (store: tState) => store.globalModals.introModalState,
  )
  const customerPortalData = useSelector(
    (store: tState) => store.customerPortalData,
  )
  const introViewed = customerPortalData.data?.introViewed
  const isLoading = customerPortalData.updateCustomerPortalData.isLoading

  const isIntroDialogEmpty = !openedFeature || openedFeature === "dashboard"
  React.useEffect(() => {
    if (
      openedFeature &&
      !isZeguroServiceSession &&
      !introViewed?.[openedFeature] &&
      !isLoading
    ) {
      dispatch(
        updateCustomerPortalData({
          introViewed: { [openedFeature]: true },
        }),
      )
    }
  }, [isLoading, isZeguroServiceSession, introViewed, openedFeature])

  if (isIntroDialogEmpty) {
    return null
  }

  const pageNum = featuresCfg[openedFeature].length
  const feature = featuresCfg[openedFeature][currentPage - 1]

  const onClose = () => dispatch(GLOBAL_MODALS.changeIntroModal(null))
  const onChangePage = (pageNum: number) =>
    dispatch(GLOBAL_MODALS.changeIntroModalPage(pageNum))

  return (
    <Dialog className="zeg-intro" isOpen={isOpen} onClose={() => {}}>
      <Body>
        <Header>
          <HeaderImg src={feature.imageSrc} />
        </Header>
        <Container>
          <Title>{feature.title}</Title>
          <Text>{feature.text}</Text>
          <Footer alignItems={pageNum === 1 ? "flex-end" : ""}>
            <Section>
              {currentPage === 1 ? (
                <ButtonSpacer />
              ) : (
                <ButtonSecondary
                  onClick={() => {
                    currentPage > 1 ? onChangePage(currentPage - 1) : onClose
                  }}
                  width={100}
                >
                  Back
                </ButtonSecondary>
              )}
              <Counter hidden={pageNum < 2}>
                {currentPage} of {pageNum}
              </Counter>
              <ButtonSmall
                width={100}
                onClick={() => {
                  if (currentPage === pageNum) {
                    onClose()
                  } else {
                    currentPage < pageNum
                      ? onChangePage(currentPage + 1)
                      : onClose
                  }
                }}
              >
                {currentPage < pageNum ? "Next" : "Got It"}
              </ButtonSmall>
            </Section>
            <Section padding="10 0 0 0">
              {currentPage !== pageNum && (
                <Link onClick={onClose}>Skip Intro</Link>
              )}
            </Section>
          </Footer>
        </Container>
      </Body>
    </Dialog>
  )
}

export default IntroDialog
