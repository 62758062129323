import { PrivateRoute } from "@src/Components/Common/PrivateRoute"
import IncorrectURL from "@src/Components/IncorrectURL"
import Waiting from "@src/Components/Waiting"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { ForgotPassword } from "@src/Portal/Accounts/ForgotPassword"
import Login from "@src/Portal/Accounts/Login"
import MagicLinkSignupContainer from "@src/Portal/Accounts/MagicLinkSignupContainer"
import Maintenance from "@src/Portal/Accounts/Maintenance"
import OrgUserMagicLinkInviteSignup from "@src/Portal/Accounts/OrgUserMagicLinkInviteSignup"
import ErrorPageUsedLink from "@src/Portal/Accounts/OrgUserMagicLinkInviteSignup/ErrorPageUsedLink"
import { ResetPassword } from "@src/Portal/Accounts/ResetPassword"
import SignUpContainer from "@src/Portal/Accounts/SignUpContainer"
import VerifyEmailContainer from "@src/Portal/Accounts/VerifyEmailContainer"
import DashboardContainer from "@src/Portal/Dashboard/DashboardContainer"
import InsurancePage from "@src/Portal/Insurance/InsurancePage"
import MonitoringContainer from "@src/Portal/Monitoring/MonitoringContainer"
import CyberSafetyIntroContainer from "@src/Portal/OnBoarding/ModuleIntros/CyberSafety/CyberSafetyIntroContainer"
import OnBoardingContainer from "@src/Portal/OnBoarding/OnBoardingContainer"
import PortalContainer from "@src/Portal/PortalContainer/index"
import RatingsContainer from "@src/Portal/Ratings/RatingsContainer"
import SubscriptionContainer from "@src/Portal/Subscription/SubscriptionContainer"
import { VIEW_PATHS } from "@src/Routes/constants"
import { CyberConciergeRouter } from "@src/Routes/CyberConcierge"
import { getCustomerPortalData } from "@src/Sagas"
import { ViewTypes } from "@src/types"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom"
import { ToastContainer } from "react-toastify"
import styled from "styled-components"

import PageNotFound from "../Components/PageNotFound"
import { MarketplaceRouter } from "./Marketplace"
import { SecurityPolicyRouter } from "./SecurityPolicy"
import { SettingsRouter } from "./Settings"
import { TrainingRouter } from "./Training"
import { ZimmRouter } from "./Zimm"

interface IRoutesParams {
  subscriptionId?: string
}
const Routes: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const pathname = history.location.pathname
  const path = pathname.split("/")[1]
  const modules = useSelector((state: tState) => state.plans.modules)
  const inactivePaths: string[] = ["/"]
  const notAvailablePaths: string[] = []
  for (const module in modules) {
    if (modules[module as keyof typeof modules] === "INACTIVE") {
      inactivePaths.push(VIEW_PATHS[module])
    } else if (modules[module as keyof typeof modules] === "NOT_AVAILABLE") {
      notAvailablePaths.push(VIEW_PATHS[module])
    }
  }

  const authenticated = useSelector(
    (state: tState) => state.portal.authenticated,
  )

  const maintenance = useSelector((state: tState) => state.portal.maintenance)

  const companyProfileState = useSelector(
    (state: tState) => state.portal.companyProfileState,
  )
  const userProfileState = useSelector(
    (state: tState) => state.portal.userProfile,
  )
  const companyProfile = useSelector(
    (state: tState) => state.portal.companyProfile,
  )
  const planStatus = useSelector((state: tState) => state.plans?.status)
  const config = useSelector((state: tState) => state.portal.config)
  const subscriptionIdState = useSelector(
    (state: tState) => state.subscriptionId.subscriptionIdState,
  )
  const { webDomain } = useSelector((state: tState) => state.brand)

  const match = useRouteMatch<IRoutesParams>([
    "/signin/:subscriptionId",
    "/signup/:subscriptionId",
    "/resetPassword/:linkId/:subscriptionId",
    "/forgotPassword/:subscriptionId",
  ])

  const monitorSessionTimeout = () => {
    const INACTIVITY_CHECK_TIME = 300
    const checkSession = () => {
      if (authenticated && config.sessionInactivityTimeout) {
        C.Portal.periodicSessionCheck(
          (response: any) => {
            const authenticated = response.authenticated
            const expires = response.expires
            if (authenticated && expires > 0) {
              if (response.expires < INACTIVITY_CHECK_TIME) {
                C.timeoutNotification(response.expires)
              }
              const nextCheckSec = expires - INACTIVITY_CHECK_TIME + 3
              const n = nextCheckSec > 0 ? nextCheckSec : expires + 3
              setTimeout(checkSession, n * 1000)
            } else {
              C.Portal.logout()
            }
          },
          () => {
            setTimeout(checkSession, 30000)
          },
        )
      } else {
        setTimeout(checkSession, 1000)
      }
    }
    checkSession()
  }

  React.useEffect(() => {
    C.initEnvironment()
    C.Portal.checkSession("PortalContainer")
    C.Plans.isValidSubscriptionId(match?.params?.subscriptionId)
  }, [])

  React.useEffect(() => {
    if (authenticated) {
      dispatch(getCustomerPortalData())
    }
  }, [authenticated])

  React.useEffect(() => {
    if (maintenance?.enabled) {
      return
    }
    if (authenticated) {
      monitorSessionTimeout()
      C.Plans.isValidSubscriptionId()
    } else if (match?.params?.subscriptionId) {
      C.Plans.isValidSubscriptionId(match?.params?.subscriptionId)
    }
  }, [authenticated, config.sessionInactivityTimeout])

  React.useEffect(() => {
    const rootPath = userProfileState.hasSeenOrgGoals
      ? VIEW_PATHS["dashboard"]
      : VIEW_PATHS["intro"]
    if (maintenance?.enabled) {
      history.push("/maintenance")
      return
    }
    if (authenticated == false || (authenticated && planStatus === "SUCCESS")) {
      if (companyProfileState === "SUCCESS" && authenticated) {
        if (inactivePaths.includes("/" + path)) {
          if (
            modules.compliance !== "ACTIVE" &&
            modules.monitoring !== "ACTIVE" &&
            modules.training !== "ACTIVE"
          ) {
            history.push(VIEW_PATHS["subscription"])
          } else {
            history.push(rootPath)
          }
        }
        if (
          pathname !== "/" &&
          notAvailablePaths.includes("/" + pathname.split("/")[1])
        ) {
          history.push("/404")
        }
      } else if (pathname === "/" || pathname === "") {
        history.push(ViewTypes.signin)
      }
    }
  }, [userProfileState, companyProfileState, authenticated, pathname])

  if (authenticated === null || subscriptionIdState === "loading") {
    return <Waiting text="Loading Cyber Safety Portal..." />
  }
  if (
    companyProfileState !== "SUCCESS" &&
    authenticated &&
    path !== "onboarding"
  ) {
    return <Waiting text="Please wait a moment, we're loading your profile." />
  }
  return (
    <>
      <ToastContainerStyled position="top-center" closeOnClick />
      <PortalContainer
        authenticated={authenticated}
        isMaintenance={!!maintenance?.enabled}
      >
        <Switch>
          <PrivateRoute
            exact
            path={ViewTypes.intro}
            isAuthenticated={authenticated}
            component={CyberSafetyIntroContainer}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            exact
            path={[ViewTypes.dashboard, "/", ""]}
            isAuthenticated={authenticated}
            component={DashboardContainer}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            path={ViewTypes.zimm}
            isAuthenticated={authenticated}
            component={ZimmRouter}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            path={ViewTypes.compliance}
            component={SecurityPolicyRouter}
            isAuthenticated={authenticated}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            exact
            path={[`${ViewTypes.monitoring}/:id`, ViewTypes.monitoring]}
            component={MonitoringContainer}
            isAuthenticated={authenticated}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            exact
            path={ViewTypes.attacksurface}
            component={RatingsContainer}
            isAuthenticated={authenticated}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            path={ViewTypes.training}
            component={TrainingRouter}
            isAuthenticated={authenticated}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            path={ViewTypes.insurance}
            component={InsurancePage}
            isAuthenticated={authenticated}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            path={ViewTypes.marketplace}
            component={MarketplaceRouter}
            isAuthenticated={authenticated}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            path={ViewTypes.settings}
            component={SettingsRouter}
            isAuthenticated={authenticated}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            exact
            path={ViewTypes.plan}
            component={SubscriptionContainer}
            isAuthenticated={authenticated}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <PrivateRoute
            path={ViewTypes.cyberConcierge}
            component={CyberConciergeRouter}
            isAuthenticated={authenticated}
            companyProfile={companyProfile}
            webDomain={webDomain}
          />
          <Route path={"/incorrect-url"} component={IncorrectURL} />
          <Route exact path="/maintenance" component={Maintenance} />
          <Route
            exact
            path={["/signin/:subscriptionId", "/signin"]}
            component={Login}
          />
          <Route
            path="/signupLink/:linkId"
            component={MagicLinkSignupContainer}
          />
          <Route
            path="/invite-link/:linkId"
            component={OrgUserMagicLinkInviteSignup}
          />
          <Route path="/link-error-page" component={ErrorPageUsedLink} />
          <Route
            path="/signupSec/:linkId"
            render={() => <MagicLinkSignupContainer />}
          />
          <Route
            path={["/signup/:subscriptionId", "/signup"]}
            component={SignUpContainer}
          />
          <Route path="/onboarding" component={OnBoardingContainer} />
          <Route path="/verify" component={VerifyEmailContainer} />
          <Route
            path={[
              "/resetPassword/:linkId/:subscriptionId",
              "/resetPassword/:linkId",
            ]}
            component={ResetPassword}
          />
          <Route
            path={["/forgotPassword/:subscriptionId", "/forgotPassword"]}
            component={ForgotPassword}
          />
          <Route path="/404" component={PageNotFound} />
          <Redirect exact to="/404" />
        </Switch>
      </PortalContainer>
    </>
  )
}

export default Routes

export const ToastContainerStyled = styled(ToastContainer)`
  word-break: break-word;
  .toast-warning {
    color: black;
    .Toastify__close-button {
      color: black;
    }
  }
`
