import { Spinner } from "@blueprintjs/core"
import { ButtonSmall } from "@src/Components/Button"
import Card from "@src/Components/Card"
import Dialog from "@src/Components/Dialog"
import { FaIcon } from "@src/Components/FaIcon"
import Icon from "@src/Components/Icon"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { EditInviteForm } from "@src/Portal/Settings/ManageAdmins/EditInviteForm"
import { hasTrialPlanExpiredSelector } from "@src/Selectors/utils"
import { colors } from "@src/theme"
import * as T from "@src/types"
import { format } from "date-fns"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import { InviteUserForm } from "../InviteUserForm"
import { EditUserForm } from "./EditUserForm"
import {
  CardBody,
  AddUserIcon,
  CardAdmin,
  CardContainer,
  CardsContainer,
  CardTextHead,
  CardTextSub,
  Container,
  EmptyPanelState,
  ErrorPanelState,
  ManageAdminsContainer,
  StyledSectionHeader,
  Title,
  UserIcon,
} from "./styles"
import { usePortalSize } from "@src/Components/Common/usePortalSize";
const ManageAdmins = () => {
  const { clientWidth } = usePortalSize()
  const history = useHistory()
  const userList = useSelector((store: tState) => store.portal.userList)
  const userInviteListState = useSelector(
    (store: tState) => store.portal.userInviteList,
  )
  const userInviteList =
    userInviteListState?.filter((invite) => invite.status === "pending") || []
  const userListError = useSelector(
    (store: tState) => store.portal.userListError,
  )
  const userInviteListError = useSelector(
    (store: tState) => store.portal.userInviteListError,
  )
  const currentUser = useSelector((state: tState) => state.portal.cognitoId)
  const hasTrialExpired = useSelector((state: tState) =>
    hasTrialPlanExpiredSelector(state),
  )
  const amountUsers = (userList?.length || 0) + (userInviteList?.length || 0)
  const useListFiltered = userList?.filter(
    (user) => user.cognitoId !== currentUser,
  ) as any

  const [userToEdit, setUserToEdit] = React.useState<T.tUserProfile>()
  const [userInviteToEdit, setUserInviteToEdit] =
    React.useState<T.tUserInvite>()
  const [isModalInviteOpen, setIsModalInviteOpen] = React.useState(false)
  const [isModalInviteEditOpen, setIsModalInviteEditOpen] =
    React.useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = React.useState(false)
  const [isModalRemoveOpen, setIsModalRemoveOpen] = React.useState(false)
  const [isInviteModalRemoveOpen, setIsInviteModalRemoveOpen] =
    React.useState(false)
  const [modalRemoveLoading, setModalRemoveLoading] = React.useState(false)
  const getMenuItems = (userToEdit: T.tUserProfile) => {
    const cognitoId = userToEdit.cognitoId || ""
    return [
      {
        iconClass: "fas fa-edit",
        caption: "Edit",
        action: () => {
          setUserToEdit(userToEdit)
          setIsModalEditOpen(true)
        },
      },
      ...(cognitoId && currentUser !== cognitoId
        ? [
            {
              iconClass: "fas fa-trash-alt",
              color: colors.error,
              caption: "Remove",
              action: () => {
                setUserToEdit(userToEdit)
                setIsModalRemoveOpen(true)
              },
            },
          ]
        : []),
    ]
  }
  const getInviteMenuItems = (userToEdit: T.tUserInvite) => {
    return [
      {
        iconClass: "fas fa-edit",
        caption: "Edit",
        action: () => {
          setUserInviteToEdit(userToEdit)
          setIsModalInviteEditOpen(true)
        },
      },
      {
        iconClass: "fas far fa-user-slash",
        color: colors.error,
        caption: "Cancel",
        action: () => {
          setUserInviteToEdit(userToEdit)
          setIsInviteModalRemoveOpen(true)
        },
      },
    ]
  }
  const getUserCardContent = React.useMemo(() => {
    let cardContent
    if (useListFiltered && useListFiltered.length > 0) {
      cardContent = (
        <div>
          {useListFiltered.map((user: T.tUserProfile, index: number) =>
            user.cognitoId && currentUser !== user.cognitoId ? (
              <Card
                content={
                  <CardContainer id="settings-user-card">
                    <UserIcon className="fas fa-user-circle" />
                    <CardBody>
                      <CardTextHead>{`${user.firstName || ""} ${
                        user.lastName || ""
                      }`}</CardTextHead>
                      <CardTextSub>{user.email}</CardTextSub>
                      <CardTextSub>{user.jobTitle}</CardTextSub>
                    </CardBody>
                  </CardContainer>
                }
                menuItems={getMenuItems(user)}
                key={index}
              />
            ) : (
              <CardAdmin key={index}>
                <CardContainer id="settings-user-card">
                  <UserIcon className="fas fa-user-circle" />
                  <div>
                    <CardTextHead>{`${user.firstName || ""} ${
                      user.lastName || ""
                    }`}</CardTextHead>
                    <CardTextSub>{user.email}</CardTextSub>
                    <CardTextSub>{user.jobTitle}</CardTextSub>
                  </div>
                </CardContainer>
                <Icon
                  data-tip
                  data-for="admin-card-tooltip"
                  onClick={() => {
                    setUserToEdit(user)
                    setIsModalEditOpen(true)
                  }}
                >
                  <i className="fas fa-edit" />
                </Icon>
                <ReactTooltip
                  className="tooltip-content-custom"
                  id="admin-card-tooltip"
                  aria-haspopup="true"
                  effect="solid"
                  place="top"
                  border={true}
                >
                  <p>Edit</p>
                </ReactTooltip>
              </CardAdmin>
            ),
          )}
        </div>
      )
    } else {
      if (userListError) {
        cardContent = (
          <ErrorPanelState>
            <FaIcon
              className="fas fa-exclamation-triangle"
              fontSize="16px"
              color={colors.error}
            />
            Unable to retrieve this information.
          </ErrorPanelState>
        )
      } else {
        cardContent = (
          <EmptyPanelState>There are no admins yet.</EmptyPanelState>
        )
      }
    }
    return (
      <>
        {cardContent}
        {hasTrialExpired ? (
          <Tooltip
            id="manage-admins-button-tooltip"
            clickable
            content={
              <Section fontSize="16px" padding="4">
                <Link
                  onClick={() => {
                    history.push("/plan")
                  }}
                  isUnderline
                >
                  Subscribe to use.
                </Link>
              </Section>
            }
          />
        ) : null}
      </>
    )
  }, [userList])
  const getUserInviteCardContent = React.useMemo(() => {
    let cardContent
    if (userInviteList && userInviteList.length > 0) {
      cardContent = (
        <div>
          {userInviteList.map((user) => (
            <Card
              content={
                <CardContainer id="settings-invite-user-card">
                  <Section w="100%" flex="space-between flex-start row">
                    <Section flex="flext-start">
                      <UserIcon
                        className="fas fa-user-circle"
                        color={colors.gray40}
                      />
                      <CardBody>
                        <CardTextHead>{`${user.createdFor.firstName || ""} ${
                          user.createdFor.lastName || ""
                        }`}</CardTextHead>
                        <CardTextSub>{user.createdFor.email}</CardTextSub>
                        <CardTextSub>{user.createdFor.jobTitle}</CardTextSub>
                      </CardBody>
                    </Section>
                    <FaIcon
                      className="far fa-info-circle"
                      color={colors.gray40}
                      margin="0 10px 0 0"
                      data-tip
                      data-for="created-at-invite-tooltip"
                      cursor="pointer"
                    />
                  </Section>
                  <Tooltip
                    id="created-at-invite-tooltip"
                    content={
                      <span>
                        Invited on{" "}
                        {format(new Date(user?.createdOn), "MMM d, yyyy")}
                      </span>
                    }
                  />
                </CardContainer>
              }
              menuItems={getInviteMenuItems(user)}
              key={user.createdFor.email}
            />
          ))}
        </div>
      )
    } else {
      if (userInviteListError) {
        cardContent = (
          <ErrorPanelState>
            <FaIcon
              className="fas fa-exclamation-triangle"
              fontSize="16px"
              color={colors.error}
            />
            Unable to retrieve this information.
          </ErrorPanelState>
        )
      } else {
        cardContent = (
          <EmptyPanelState>There are no pending invitations.</EmptyPanelState>
        )
      }
    }
    return (
      <>
        {cardContent}
        {hasTrialExpired ? (
          <Tooltip
            id="manage-admins-button-tooltip"
            clickable
            content={
              <Section fontSize="16px" padding="4">
                <Link
                  onClick={() => {
                    history.push("/plan")
                  }}
                  isUnderline
                >
                  Subscribe to use.
                </Link>
              </Section>
            }
          />
        ) : null}
      </>
    )
  }, [userInviteList])

  const onRemoveUser = () => {
    if (!userToEdit?.cognitoId) {
      return
    }
    setModalRemoveLoading(true)

    const invite = userInviteListState?.find(
      (invite) => invite.createdFor.email === userToEdit?.email,
    )
    if (invite) {
      C.Portal.removeInvite(invite, () => {}, false)
    }
    C.Portal.removeUser(userToEdit.cognitoId, () => {
      setModalRemoveLoading(false)
      setIsModalRemoveOpen(false)
    })
  }
  const onRemoveInvite = () => {
    if (!userInviteToEdit) return
    setModalRemoveLoading(true)
    C.Portal.removeInvite(userInviteToEdit, () => {
      setModalRemoveLoading(false)
      setIsInviteModalRemoveOpen(false)
    })
  }
  const onCancelRemoveUser = () => {
    setIsModalRemoveOpen(false)
  }
  return (
    <ManageAdminsContainer>
      <StyledSectionHeader flex="space-between center">
        <Section>
          <Title>Cyber Safety Admins</Title>
          <span>Admins have full access to Cyber Safety.</span>
        </Section>
        <ButtonSmall
          width={160}
          type="button"
          id="manage-admins-button-tooltip"
          onClick={() => setIsModalInviteOpen(true)}
          disabled={hasTrialExpired || amountUsers >= 5}
        >
          <span data-tip data-for="manage-admins-button-tooltip">
            {clientWidth > 600 && <AddUserIcon className="fas fa-user-plus" />}
            Invite Admin
          </span>
        </ButtonSmall>
      </StyledSectionHeader>
      <Container>
        <CardsContainer>{getUserCardContent}</CardsContainer>
      </Container>
      <Section flexLayout="space-between center">
        <Title>Pending Invitations</Title>
        <span>
          These people have not yet accepted the invitation sent from your
          organization.
        </span>
      </Section>
      <Container>
        <CardsContainer>{getUserInviteCardContent}</CardsContainer>
      </Container>
      {!hasTrialExpired && amountUsers >= 5 && (
        <Tooltip
          id="manage-admins-button-tooltip"
          content={<span>You’ve reached the maximum number of admins.</span>}
        />
      )}
      <Dialog
        title="Invite Admin"
        isOpen={isModalInviteOpen}
        onClose={() => setIsModalInviteOpen(false)}
        content={<InviteUserForm onClose={() => setIsModalInviteOpen(false)} />}
      />
      <Dialog
        title="Edit Admin"
        isOpen={isModalEditOpen}
        onClose={() => setIsModalEditOpen(false)}
        content={
          <EditUserForm
            onClose={() => setIsModalEditOpen(false)}
            userData={userToEdit}
          />
        }
      />
      <Dialog
        title="Edit Invite"
        isOpen={isModalInviteEditOpen}
        onClose={() => setIsModalInviteEditOpen(false)}
        content={
          <EditInviteForm
            onClose={() => setIsModalInviteEditOpen(false)}
            inviteData={userInviteToEdit}
          />
        }
      />
      <Dialog
        title="Remove Admin?"
        text={`Are you sure you want to permanently remove ${
          userToEdit?.email || "admin"
        }?`}
        actionText="Remove"
        className="zeg-danger"
        isOpen={isModalRemoveOpen}
        onClose={() => onCancelRemoveUser()}
        onAction={() => onRemoveUser()}
        content={
          modalRemoveLoading ? (
            <Section padding="10">
              <Spinner size={50} />
            </Section>
          ) : undefined
        }
      />
      <Dialog
        title="Cancel Invite?"
        text={`Are you sure you want to cancel the invite to ${
          userInviteToEdit?.createdFor.firstName || ""
        } ${userInviteToEdit?.createdFor.lastName || ""} ${userInviteToEdit
          ?.createdFor.email}?`}
        actionText="Cancel Invite"
        actionButtonWidth={130}
        className="zeg-danger"
        isOpen={isInviteModalRemoveOpen}
        onClose={() => setIsInviteModalRemoveOpen(false)}
        onAction={onRemoveInvite}
        showOnlyPrimaryButton
        content={
          modalRemoveLoading ? (
            <Section padding="10">
              <Spinner size={50} />
            </Section>
          ) : undefined
        }
      />
    </ManageAdminsContainer>
  )
}

export default ManageAdmins
