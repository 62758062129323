import { IFastRatingsData, IRatingDetailsData } from "@src/types"
import * as React from "react"

import CategoryList from "../CategoryList"
import DownloadRatingReport from "../DownloadRatingReport"
import { ButtonWrap, OverviewStyled, RatingText } from "./styles"

const overviewText = `
  Rating Overview Panel shows how well this company is managing each risk vector.
  Click on a risk vector to see more details about the risk.
`

const gradesText = `
  The grades show how well this company is managing each risk vector.
  These grades do not contribute evenly to a company’s overall Security Rating.
`

const explanation1 = `* Risk Vector does not currently affect Security Ratings`
const explanation2 = `** Informational Risk Vector (will never affect Security Ratings)`

const calcLink =
  "https://www.bitsight.com/hubfs/guides/How%20BitSight%20Calculates%20Security%20Ratings%201_2.pdf?hsCtaTracking=8a6b00a7-4510-454f-9d53-fbda79148697%7Ccc70dab7-b414-4e63-8b5c-cbcb51a9271a"
const moreLink =
  "https://www.bitsight.com/hubfs/guides/How%20BitSight%20Calculates%20Security%20Ratings%201_2.pdf?hsCtaTracking=8a6b00a7-4510-454f-9d53-fbda79148697%7Ccc70dab7-b414-4e63-8b5c-cbcb51a9271a"

interface IProps {
  data: IFastRatingsData
  companyUrl: string
  ratingDetails: IRatingDetailsData
}

const Overview = ({ data, ratingDetails, companyUrl }: IProps) => {
  const ratingDetailsMemo = React.useMemo(() => {
    return Object.keys(ratingDetails).map((key) => ({
      ...ratingDetails[key],
      id: key,
    }))
  }, [ratingDetails])

  return (
    <OverviewStyled>
      <div className="overview-left-container">
        <h2 className="overview-rating-title">
          Attack Surface Security Rating Overview
        </h2>
        <p>{overviewText}</p>
        <div className="overview-list-container">
          <div className="category-list-wrapper">
            <CategoryList
              data={ratingDetailsMemo}
              category="Compromised Systems"
            />
            <CategoryList data={ratingDetailsMemo} category="User Behavior" />
            <CategoryList
              data={ratingDetailsMemo}
              category="Public Disclosures"
            />
          </div>
          <div className="category-list-wrapper">
            <CategoryList data={ratingDetailsMemo} category="Diligence" />
          </div>
        </div>
        <span className="overview-footer-text">
          {explanation1} <br />
          {explanation2}
        </span>
      </div>
      <div className="overview-right-container">
        <div className="overview-right-head">
          <div className="overview-right-head-wrap">
            <div className="overview-right-head-sub">
              <p>Your Company</p>
              <RatingText
                companyUrl={!!(companyUrl && data)}
                color={data?.ratings[0]?.rating_color}
              >
                {data ? data.ratings[0].rating : "490"}
              </RatingText>
            </div>
            <RatingText companyUrl={true}>vs</RatingText>
            <div className="overview-right-head-sub">
              <p>Industry Average</p>
              <RatingText companyUrl={!!(companyUrl && data)}>
                {data ? data.industry_grade?.average_rating || "N/A" : "790"}
              </RatingText>
            </div>
          </div>
          <ButtonWrap>
            <DownloadRatingReport
              title={`Get Your ${!data ? "Free " : ""}Snapshot`}
              secondary
            />
          </ButtonWrap>
        </div>
        <div className="security-graph-wrapper">
          <h4>What Makes A Security Rating?</h4>
          <img src="/images/overview_graph.png" alt="overview graph" />
        </div>
        <p className="overview-footer-text">
          {gradesText}
          <br />
          <br />
          Breaches have a negative impact on Security Ratings{" "}
          <strong>only if they occur.</strong>
        </p>
        <a href={calcLink} target="_blank" rel="noreferrer">
          Learn more about how ratings are calculated
        </a>
        <a href={moreLink} target="_blank" rel="noreferrer">
          Learn more about every risk vector
        </a>
      </div>
    </OverviewStyled>
  )
}

export default Overview
