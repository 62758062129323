import Dialog from "@src/Components/Dialog"
import { colors } from "@src/theme"
import styled from "styled-components"

export const StyledDialog = styled(Dialog)<{ grayBackground?: boolean }>`
  &.bp5-dialog {
    margin: 0;
    width: 965px;
    max-width: 965px;
    max-width: 100vw;
    background-color: ${({ grayBackground }) =>
      grayBackground && colors.gray25};
  }
`
