import { createSlice } from "@reduxjs/toolkit"
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction"
import {
  ICheckWebAppUrl,
  IGetScanMap,
  IGetScheduleMap,
  IMonitoringState,
} from "@src/Model/Model"
import { IBasicState, IProbelyTarget } from "@src/types"

type tMonitoringModalSKeys = {
  isWebScanCancelConfirm: "isWebScanCancelConfirm"
  isAddAppModal: "isAddAppModal"
  isCloseAddAppConfirm: "isCloseAddAppConfirm"
  isEditAppModal: "isEditAppModal"
  isEditScheduleModal: "isEditScheduleModal"
  isDeleteAppConfirm: "isDeleteAppConfirm"
}
export const MONITORING_MODALS_KEYS = {
  isWebScanCancelConfirm: "isWebScanCancelConfirm",
  isAddAppModal: "isAddAppModal",
  isCloseAddAppConfirm: "isCloseAddAppConfirm",
  isEditAppModal: "isEditAppModal",
  isEditScheduleModal: "isEditScheduleModal",
  isDeleteAppConfirm: "isDeleteAppConfirm",
}
const basicInitialState: IBasicState = {
  error: undefined,
  isLoading: false,
  message: "",
  success: false,
}

export const initialMonitoringState: IMonitoringState = {
  createTarget: {
    ...basicInitialState,
  },
  editTarget: {
    ...basicInitialState,
  },
  deleteTarget: {
    ...basicInitialState,
  },
  createScheduleScan: {
    ...basicInitialState,
  },
  updateScheduleScan: {
    ...basicInitialState,
  },
  editScheduleScan: {
    ...basicInitialState,
  },
  cancelScan: {
    ...basicInitialState,
  },
  syncScans: {
    ...basicInitialState,
  },
  getTargetList: {
    data: [],
    ...basicInitialState,
  },
  getScheduleMap: {
    data: {},
    ...basicInitialState,
  },
  getScanMap: {
    data: {},
    ...basicInitialState,
  },
  getScanReport: {
    ...basicInitialState,
  },
  checkAppUrl: {
    data: {},
    ...basicInitialState,
  },
  toggleModal: {
    isWebScanCancelConfirm: false,
    isAddAppModal: false,
    isCloseAddAppConfirm: false,
    isEditAppModal: false,
    isEditScheduleModal: false,
    isDeleteAppConfirm: false,
  },
}

const monitoringSlice = createSlice({
  name: "monitoring",
  initialState: initialMonitoringState,
  reducers: {
    createTargetRequest({ createTarget }: IMonitoringState) {
      createTarget.isLoading = true
      createTarget.error = undefined
    },
    createTargetSuccess({ createTarget }: IMonitoringState) {
      createTarget.isLoading = false
      createTarget.error = undefined
    },
    createTargetFailure(
      { createTarget }: IMonitoringState,
      { payload }: PayloadAction<Error>,
    ) {
      createTarget.error = payload
      createTarget.isLoading = false
    },
    editTargetRequest({ editTarget }: IMonitoringState) {
      editTarget.message = ""
      editTarget.isLoading = true
    },
    editTargetSuccess({ editTarget }: IMonitoringState) {
      editTarget.isLoading = false
    },
    editTargetFailure(
      { editTarget }: IMonitoringState,
      { payload }: PayloadAction<string>,
    ) {
      editTarget.message = payload
      editTarget.isLoading = false
    },
    deleteTargetRequest({ deleteTarget }: IMonitoringState) {
      deleteTarget.message = ""
      deleteTarget.isLoading = true
    },
    deleteTargetSuccess({ deleteTarget }: IMonitoringState) {
      deleteTarget.isLoading = false
    },
    deleteTargetFailure(
      { deleteTarget }: IMonitoringState,
      { payload }: PayloadAction<{ message: string }>,
    ) {
      deleteTarget.message = payload.message
      deleteTarget.isLoading = false
    },
    createScheduleScanRequest({ createScheduleScan }: IMonitoringState) {
      createScheduleScan.message = ""
      createScheduleScan.isLoading = true
    },
    createScheduleScanSuccess({ createScheduleScan }: IMonitoringState) {
      createScheduleScan.isLoading = false
    },
    createScheduleScanFailure(
      { createScheduleScan }: IMonitoringState,
      { payload }: PayloadAction<{ message: string }>,
    ) {
      createScheduleScan.message = payload.message
      createScheduleScan.isLoading = false
    },
    updateScheduleScanRequest({ updateScheduleScan }: IMonitoringState) {
      updateScheduleScan.message = ""
      updateScheduleScan.isLoading = true
    },
    updateScheduleScanSuccess({ updateScheduleScan }: IMonitoringState) {
      updateScheduleScan.isLoading = false
    },
    updateScheduleScanFailure(
      { updateScheduleScan }: IMonitoringState,
      { payload }: PayloadAction<{ message: string }>,
    ) {
      updateScheduleScan.message = payload.message
      updateScheduleScan.isLoading = false
    },
    editScheduleScanRequest({ editScheduleScan }: IMonitoringState) {
      editScheduleScan.message = ""
      editScheduleScan.isLoading = true
    },
    editScheduleScanSuccess({ editScheduleScan }: IMonitoringState) {
      editScheduleScan.isLoading = false
    },
    editScheduleScanFailure(
      { editScheduleScan }: IMonitoringState,
      { payload }: PayloadAction<{ message: string }>,
    ) {
      editScheduleScan.message = payload.message
      editScheduleScan.isLoading = false
    },
    cancelScanRequest({ cancelScan }: IMonitoringState) {
      cancelScan.message = ""
      cancelScan.isLoading = true
    },
    cancelScanSuccess({ cancelScan }: IMonitoringState) {
      cancelScan.isLoading = false
    },
    cancelScanFailure(
      { cancelScan }: IMonitoringState,
      { payload }: PayloadAction<string>,
    ) {
      cancelScan.message = payload
      cancelScan.isLoading = false
    },
    syncScansRequest({ syncScans }: IMonitoringState) {
      syncScans.message = ""
      syncScans.isLoading = true
    },
    syncScansSuccess({ syncScans }: IMonitoringState) {
      syncScans.isLoading = false
    },
    syncScansFailure(
      { syncScans }: IMonitoringState,
      { payload }: PayloadAction<{ message: string }>,
    ) {
      syncScans.message = payload.message
      syncScans.isLoading = false
    },
    getTargetListRequest({ getTargetList }: IMonitoringState) {
      getTargetList.isLoading = true
      getTargetList.error = undefined
    },
    getTargetListSuccess(
      { getTargetList }: IMonitoringState,
      { payload }: PayloadAction<{ data: IProbelyTarget[] }>,
    ) {
      getTargetList.data = payload.data
      getTargetList.isLoading = false
      getTargetList.error = undefined
    },
    getTargetListFailure(
      { getTargetList }: IMonitoringState,
      { payload }: PayloadAction<Error>,
    ) {
      getTargetList.error = payload
      getTargetList.isLoading = false
    },
    getScheduleMapRequest({ getScheduleMap }: IMonitoringState) {
      getScheduleMap.isLoading = true
      getScheduleMap.error = undefined
    },
    getScheduleMapSuccess(
      { getScheduleMap }: IMonitoringState,
      { payload }: PayloadAction<IGetScheduleMap>,
    ) {
      getScheduleMap.data = payload.data
      getScheduleMap.isLoading = false
      getScheduleMap.error = undefined
    },
    getScheduleMapFailure(
      { getScheduleMap }: IMonitoringState,
      { payload }: PayloadAction<Error>,
    ) {
      getScheduleMap.error = payload
      getScheduleMap.isLoading = false
    },
    getScanMapRequest({ getScanMap }: IMonitoringState) {
      getScanMap.isLoading = true
      getScanMap.error = undefined
    },
    getScanMapSuccess(
      { getScanMap }: IMonitoringState,
      { payload }: PayloadAction<IGetScanMap>,
    ) {
      getScanMap.data = payload.data
      getScanMap.isLoading = false
      getScanMap.error = undefined
    },
    getScanMapFailure(
      { getScanMap }: IMonitoringState,
      { payload }: PayloadAction<Error>,
    ) {
      getScanMap.error = payload
      getScanMap.isLoading = false
    },
    getScanReportRequest({ getScanReport }: IMonitoringState) {
      getScanReport.isLoading = true
      getScanReport.error = undefined
    },
    getScanReportSuccess({ getScanReport }: IMonitoringState) {
      getScanReport.isLoading = false
      getScanReport.error = undefined
    },
    getScanReportFailure(
      { getScanReport }: IMonitoringState,
      { payload }: PayloadAction<Error>,
    ) {
      getScanReport.error = payload
      getScanReport.isLoading = false
    },
    checkAppUrlRequest({ checkAppUrl }: IMonitoringState) {
      checkAppUrl.isLoading = true
      checkAppUrl.error = undefined
    },
    checkAppUrlSuccess(
      { checkAppUrl }: IMonitoringState,
      { payload }: PayloadAction<ICheckWebAppUrl>,
    ) {
      checkAppUrl.data = payload.data
      checkAppUrl.isLoading = false
      checkAppUrl.error = undefined
    },
    checkAppUrlFailure(
      { checkAppUrl }: IMonitoringState,
      { payload }: PayloadAction<Error>,
    ) {
      checkAppUrl.error = payload
      checkAppUrl.isLoading = false
    },
    toggleModal(
      { toggleModal }: IMonitoringState,
      { payload }: PayloadAction<string>,
    ) {
      toggleModal[payload as keyof tMonitoringModalSKeys] =
        !toggleModal[payload as keyof tMonitoringModalSKeys]
    },
  },
})

export const { name, actions, reducer } = monitoringSlice
