import { createSlice } from "@reduxjs/toolkit"
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction"
import { basicInitialState, tZimmState } from "@src/Model/Model"
import { IGetAssessmentData } from "@src/types"

export const initialZimmState: tZimmState = {
  getConfig: {
    elements: [],
    glossary: {},
    interstitials: [],
    ...basicInitialState,
  },
  getAssessmentData: {
    data: null,
    nextQuestion: null,
    additionalLoading: false,
    ...basicInitialState,
  },
  saveAssessmentData: {
    ...basicInitialState,
  },
  removeAssessmentData: {
    ...basicInitialState,
  },
}

const zimmSlice = createSlice({
  name: "zimm",
  initialState: initialZimmState,
  reducers: {
    getConfigRequest({ getConfig }) {
      getConfig.isLoading = true
      getConfig.error = undefined
    },
    getConfigSuccess({ getConfig }, { payload }: PayloadAction<any>) {
      getConfig.elements = payload.data.elements
      getConfig.glossary = payload.data.glossary
      getConfig.interstitials = payload.data.interstitials
      getConfig.isLoading = false
      getConfig.success = true
      getConfig.error = undefined
    },
    getConfigFailure({ getConfig }, { payload }: PayloadAction<Error>) {
      getConfig.error = payload
      getConfig.isLoading = false
    },
    getAssessmentDataRequest(
      { getAssessmentData },
      { payload }: PayloadAction<{ useAdditionalLoading?: boolean }>,
    ) {
      getAssessmentData.isLoading = !payload?.useAdditionalLoading
      getAssessmentData.additionalLoading = !!payload.useAdditionalLoading
      getAssessmentData.error = undefined
    },
    getAssessmentDataSuccess(
      { getAssessmentData },
      { payload }: PayloadAction<IGetAssessmentData>,
    ) {
      getAssessmentData.data = payload.data
      getAssessmentData.nextQuestion = payload.nextQuestion
      getAssessmentData.isLoading = false
      getAssessmentData.additionalLoading = false
      getAssessmentData.success = true
      getAssessmentData.error = undefined
    },
    getAssessmentDataFailure(
      { getAssessmentData },
      { payload }: PayloadAction<Error>,
    ) {
      getAssessmentData.error = payload
      getAssessmentData.isLoading = false
      getAssessmentData.additionalLoading = false
    },
    saveAssessmentDataRequest({ saveAssessmentData }) {
      saveAssessmentData.isLoading = true
      saveAssessmentData.error = undefined
    },
    saveAssessmentDataSuccess({ saveAssessmentData }) {
      saveAssessmentData.isLoading = false
      saveAssessmentData.success = true
      saveAssessmentData.error = undefined
    },
    saveAssessmentDataFailure(
      { saveAssessmentData },
      { payload }: PayloadAction<Error>,
    ) {
      saveAssessmentData.error = payload
      saveAssessmentData.isLoading = false
    },
    removeAssessmentDataRequest({ removeAssessmentData }) {
      removeAssessmentData.isLoading = true
      removeAssessmentData.error = undefined
    },
    removeAssessmentDataSuccess({ removeAssessmentData }) {
      removeAssessmentData.isLoading = false
      removeAssessmentData.success = true
      removeAssessmentData.error = undefined
    },
    removeAssessmentDataFailure(
      { removeAssessmentData },
      { payload }: PayloadAction<Error>,
    ) {
      removeAssessmentData.error = payload
      removeAssessmentData.isLoading = false
    },
  },
})

export const { name, actions, reducer } = zimmSlice
