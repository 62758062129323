import DialogComp from "@src/Components/Dialog"
import { colors, media } from "@src/theme"
import styled from "styled-components"

interface ITollTipContentList {
  isScrollable?: boolean
  height?: string
}

export const Dialog = styled(DialogComp)`
  width: 90%;
  max-width: 900px;

  &.zeg-danger {
    max-width: 500px;
  }
  h3 {
    font-size: 18px;
    margin-top: 0;
    span {
      border-bottom: 1px dashed ${colors.black};
      margin-left: 5px;
      cursor: pointer;
    }
  }
`

export const Section = styled.div`
  margin: 0;
`

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${media.medium} {
    margin-top: 10px;
    width: 100%;
    .cancel-button-enroll {
      border: 1px solid;
      border-radius: 50px;
      margin-right: 10px;
    }
  }
`
export const Footer = styled.div`
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 16px;
    margin: 0;
    a {
      color: ${colors.link};
      text-decoration: none;
    }
  }
  @media ${media.medium} {
    flex-direction: column;
    gap: 12px;
  }
`

export const TollTipContentList = styled.div<ITollTipContentList>`
  display: flex;
  flex-direction: column;
  overflow: ${({ isScrollable }) => isScrollable && "auto"};
  max-height: ${({ height }) => height || "100%"};
  h5 {
    margin: 0;
    color: ${colors.white};
    font-size: 16px;
  }
`

export const Content = styled.div`
  max-height: 500px;
  overflow: auto;
  padding: 20px;
  border-bottom: solid 1px ${colors.gray5};
  .tooltip-content-custom {
    padding: 17px 20px;
    text-align: left;
    span {
      margin: 0;
      color: ${colors.white};
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media ${media.medium} {
    max-height: 300px;
  }
`

export const CourseList = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${media.medium} {
    flex-direction: column;
  }
`

export const CourseItem = styled.div`
  flex: 50%;
  padding: 5px;
  @media ${media.medium} {
    input {
      margin-left: 0;
    }
  }
`

export const CheckWrap = styled.div`
  display: flex;
  align-item: center;
`

export const CheckIcon = styled.i`
  font-size: 18px;
  color: ${colors.green30};
  margin-right: 10px;
  margin-left: 3px;
`

export const CheckLabel = styled.span`
  font-size: 16px;
  color: ${colors.gray40};
`
