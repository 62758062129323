import { ButtonSecondary } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import C from "@src/Controller"
import { colors } from "@src/theme"
import { IGetAssessmentData, ViewTypes } from "@src/types"
import { CyberMaturityCatalog as ICyberMaturityCatalog } from "@zeguro/schema-validator/dist/types/coco/cyberMaturity/combined"
import * as React from "react"
import { useHistory } from "react-router-dom"

import { CATEGORY_ICONS } from "../../constants"
import { ReportHeaderMobile } from "../../HeaderCard/ReportHeaderMobile"
import Level from "../../Level"
import { TooltipText } from "../../styles"
import { textToPath } from "../../utils"
import Element from "./Element"
import {
  Category,
  ExpandIcon,
  ExplanationDescription,
  ExplanationPanel,
  ExplanationText,
  ExplanationTitle,
  GroupIcon,
  LevelsContainer,
  Metric,
  MetricPanel,
  MetricTitle,
  ScoreboardPanel,
  ScoreGroup,
  ScoreTable,
} from "./styles"

export type tElement = ICyberMaturityCatalog["elements"][number]

export interface IProps {
  config: ICyberMaturityCatalog["elements"]
  categories: Pick<tElement, "elementCategory" | "elementSubCategory">[]
  assessmentData: IGetAssessmentData
}

const ScoreBoard = ({ assessmentData, config, categories }: IProps) => {
  const history = useHistory()
  const { isDesktop } = usePortalSize()
  const [currentQuestion, setCurrentQuestion] = React.useState("")

  const assessmentLink = `${ViewTypes.zimm}/assessment/${textToPath(
    assessmentData?.nextQuestion?.elementPath,
  )}`

  const getLevel = (id: string) =>
    assessmentData?.data?.response.questions.find(
      (question) => question.questionId === id,
    )?.responseLevel

  const sortedConfig = [...config].sort(
    (el1, el2) =>
      (getLevel(el1.question.questionId) ?? -1) -
      (getLevel(el2.question.questionId) ?? -1),
  )

  const getLevelInfo = (level?: number) =>
    typeof level === "number"
      ? assessmentData?.data?.levelInfo[level.toString()]
      : undefined

  const getCategoryIcon = (name: string) =>
    CATEGORY_ICONS.find((cat) => cat.name === name)?.icon

  return (
    <ScoreboardPanel>
      {!isDesktop && (
        <ReportHeaderMobile
          overallLevel={assessmentData?.data?.assessment.overallLevel}
          levelInfo={getLevelInfo(
            assessmentData?.data?.assessment.overallLevel,
          )}
          assessmentStatus={assessmentData?.data?.assessment.assessmentStatus}
          assessmentLink={assessmentLink}
          lastUpdate={assessmentData?.data?.lastUpdated}
        />
      )}
      <ExplanationPanel>
        <Section flex="flex-start" flexWrap="wrap" margin="16 20 16 0">
          <ExplanationTitle>Level Explanation</ExplanationTitle>
          <ExplanationDescription>
            {isDesktop
              ? "Roll over circles to learn more"
              : "Tap to learn more"}
          </ExplanationDescription>
        </Section>
        <Section flex="space-between center" flexBasis="450px">
          <ExplanationText>Low</ExplanationText>
          <LevelsContainer>
            {[0, 1, 2, 3, 4, 5].map((level) => (
              <Level key={level} level={level} cursor="help" />
            ))}
          </LevelsContainer>
          <ExplanationText>High</ExplanationText>
        </Section>
      </ExplanationPanel>
      {[0, 1, 2, 3, 4, 5].map((level) => (
        <Tooltip
          key={level}
          id={`tooltip-Level-info-${level}`}
          content={
            <Section flex="center" padding="0 10" align="center">
              <TooltipText>{getLevelInfo(level)?.levelName}</TooltipText>
            </Section>
          }
        />
      ))}
      {categories.map((category, index) => {
        return (
          <ScoreGroup
            key={`${category.elementCategory}${
              category.elementSubCategory || ""
            }`}
          >
            {(!category.elementSubCategory ||
              category.elementCategory !==
                categories[index - 1].elementCategory) && (
              <Category
                cursor="pointer"
                onClick={() =>
                  history.push(
                    `${ViewTypes.zimm}/assessment/${category.elementCategory}`,
                  )
                }
              >
                <GroupIcon
                  src={`/images/zimm/${getCategoryIcon(
                    category.elementCategory,
                  )}`}
                  alt={category.elementCategory}
                />
                <span>{category.elementCategory}</span>
              </Category>
            )}
            {category.elementSubCategory && (
              <Category isSubCategory>
                <GroupIcon
                  isSubCategory
                  src={`/images/zimm/${getCategoryIcon(
                    category.elementSubCategory,
                  )}`}
                  alt={category.elementSubCategory}
                />
                <span>{category.elementSubCategory}</span>
              </Category>
            )}
            <ScoreTable>
              <tbody>
                {sortedConfig
                  .filter(
                    (element) =>
                      element.elementCategory === category.elementCategory &&
                      element.elementSubCategory ===
                        category.elementSubCategory,
                  )
                  ?.map((element: any) => {
                    const questionId = element.question.questionId
                    const selected = currentQuestion === questionId
                    const level = getLevel(element.question.questionId)
                    const answered = typeof level === "number"
                    return (
                      <tr key={questionId}>
                        <Metric selected={selected}>
                          <MetricPanel
                            id={`cm-question-${
                              config.findIndex(
                                (confElement) =>
                                  confElement.elementName ===
                                  element.elementName,
                              ) + 1
                            }-in-report-${
                              currentQuestion === questionId
                                ? "opened"
                                : "closed"
                            }`}
                            selected={selected}
                            onClick={() => {
                              setCurrentQuestion(
                                currentQuestion === questionId
                                  ? ""
                                  : questionId,
                              )
                            }}
                          >
                            <Section
                              flex="space-between center"
                              flexWrap="wrap"
                              flexGrow="1"
                            >
                              <MetricTitle>{element.elementName}</MetricTitle>
                              {answered && (
                                <LevelsContainer>
                                  {[0, 1, 2, 3, 4, 5].map((lev) => {
                                    return (
                                      <Level
                                        key={lev}
                                        level={
                                          level === lev ? level : undefined
                                        }
                                        showTooltip={false}
                                      />
                                    )
                                  })}
                                </LevelsContainer>
                              )}
                            </Section>
                            <Section flex="flex-end center">
                              {!answered && (
                                <ButtonSecondary
                                  width="90"
                                  margin={"0 20"}
                                  backgroundColor={colors.white}
                                  onClick={() => {
                                    C.heapTrack("Clicked answer button")
                                    history.push(
                                      `${
                                        ViewTypes.zimm
                                      }/assessment/${textToPath(
                                        element.elementName,
                                      )}`,
                                    )
                                  }}
                                >
                                  Answer
                                </ButtonSecondary>
                              )}
                              <ExpandIcon
                                className={
                                  currentQuestion === questionId
                                    ? "fas fa-chevron-up"
                                    : "fas fa-chevron-down"
                                }
                              />
                            </Section>
                          </MetricPanel>
                          <Element
                            level={getLevel(element.question.questionId)}
                            element={element}
                            hidden={currentQuestion !== questionId}
                          />
                        </Metric>
                      </tr>
                    )
                  })}
              </tbody>
            </ScoreTable>
          </ScoreGroup>
        )
      })}
    </ScoreboardPanel>
  )
}

export default ScoreBoard
