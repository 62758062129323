import Waiting from "@src/Components/Waiting"
import * as React from "react"
import { Document, Page } from "react-pdf"

import { DocumentWrap } from "./styles"

interface IProps {
  pdfUrl: string
}

interface IOnDocumentLoad {
  numPages: number
}

const SamplePdf: React.FC<IProps> = ({ pdfUrl }) => {
  const [numPages, setNumPages] = React.useState<number>(0)

  const onDocumentLoadSuccess = ({ numPages }: IOnDocumentLoad) => {
    setNumPages(numPages)
  }

  return (
    <DocumentWrap>
      <Document
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode="canvas"
        loading={<Waiting text="Loading PDF" />}
      >
        {!numPages ? (
          <Waiting text="Loading PDF" />
        ) : (
          Array.from(Array(numPages).keys()).map((page, index) => (
            <Page
              key={index}
              height={600}
              className="zeg-pdf-page"
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          ))
        )}
      </Document>
    </DocumentWrap>
  )
}

export default SamplePdf
