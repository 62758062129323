import { colors, media } from "@src/theme"
import styled from "styled-components"

import { DashboardCardHeading, Description } from "../styles"
export const ElementTitle = styled.p`
  font-weight: bold;
  font-size: 21px;
  font-family: montserrat;
  color: ${colors.black50};
  margin: 0;
  padding-top: 16px;
  text-align: center;
`
export const TooltipArea = styled.div`
  border-radius: 50%;
`

export const ProgressText = styled.div<{ align?: string }>`
  flex: 0 0 30%;
  color: ${colors.gray40};
  font-size: 15px;
  text-align: ${({ align }) => align};
  margin-bottom: 8px;
`

export const UpdatedText = styled.div`
  display: flex;
  gap: 0 4px;
  flex-wrap: wrap;
  justify-content: center;
  flex: 0 0 40%;
  color: ${colors.gray40};
  font-size: 15px;
  text-align: center;
  margin-bottom: 8px;
`

export const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ProgressDetails = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 34px 0 45px;
  width: min(770px, 80%);
`

export const ZimmHeading = styled(DashboardCardHeading)`
  white-space: nowrap;
  min-width: 0;
  line-height: 1.1;
  div {
    font-family: montserrat;
  }
`

export const ZimmDescription = styled(Description)`
  @media ${media.large} {
    font-size: 21px;
  }
`

export const StyledUl = styled.ul`
  padding: 0 0 0 16px;
  margin: 0;
`

export const StyledLi = styled.li`
  margin-bottom: 8px;
  text-align: left;
`

export const CompanyName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DateInfo = styled.div<{ margin?: string }>`
  color: ${colors.gray40};
  font-size: 15px;
  text-align: center;
  margin: ${({ margin }) => margin};
`
