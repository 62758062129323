import { colors } from "@src/theme"
import * as React from "react"
import styled, { css } from "styled-components"

interface IProgressWizard {
  steps: string[]
  stepsCompleted: number
  backgroundColor?: string
}

interface IProgressWizardState {}

export default class ProgressWizard extends React.Component<
  IProgressWizard,
  IProgressWizardState
> {
  public readonly state: IProgressWizardState = {}

  render() {
    const { steps, stepsCompleted, backgroundColor } = this.props
    return (
      <WizardContainer>
        <WizardProgress>
          {steps.map((step, index) => (
            <Step
              key={index}
              complete={index < stepsCompleted}
              progress={index === stepsCompleted}
              backgroundColor={backgroundColor}
            >
              {step}
              <Node
                complete={index < stepsCompleted}
                progress={index === stepsCompleted}
              />
            </Step>
          ))}
        </WizardProgress>
      </WizardContainer>
    )
  }
}

const baseColor = "#39ceff"
const mainColor = "#26249e"
const textBaseColor = "#858585"
const textMainColor = "#212528"

const WizardContainer = styled.div`
  overflow: hidden;
`

const WizardProgress = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  table-layout: fixed;
  position: relative;
  margin-bottom: 30px;
`

const Step = styled.div<any>`
  text-align: center;
  vertical-align: top;
  overflow: visible;
  position: relative;
  font-family: montserrat;
  font-size: 15px;
  color: ${textBaseColor};
  @media (max-width: 460px) {
    font-size: 3vw;
  }

  &:before {
    background-color: ${baseColor};
    margin-left: 8px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 8px);
    top: 33px;
    height: 1px;
    width: 100vw;
  }

  &:last-child {
    &:before {
      background-color: ${({ backgroundColor }) =>
        backgroundColor || colors.white};
      top: 32px;
      height: 3px;
    }
  }

  ${(props: any) => {
    if (props.complete) {
      return css`
        color: ${textMainColor};
        &:before {
          background-color: ${mainColor};
        }
      `
    }
    if (props.progress) {
      return css`
        color: ${textMainColor};
        color: ${colors.darkBlue};
        font-weight: 700;
        &:before {
          background-color: ${baseColor};
        }
      `
    }
  }}
`

const Node = styled.div<any>`
  display: inline-block;
  background-color: ${baseColor};
  border-radius: 16px;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 25px;
  left: calc(50% - 8px);
  ${(props: any) => {
    if (props.complete || props.progress) {
      return css`
        background-color: ${mainColor};
      `
    }
  }}
`
