import * as React from "react"
import Slider from "react-slick"

import { SliderContainer } from "./styles"

interface ICardSlider {
  children: React.ReactNode
  afterChangeCallBack?: (index: number) => void
}

export const CardSlider = ({ children, afterChangeCallBack }: ICardSlider) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    afterChange: afterChangeCallBack,
  }
  return (
    <SliderContainer>
      <Slider {...settings}>{children}</Slider>
    </SliderContainer>
  )
}
