import { media } from "@src/theme"
import * as _ from "lodash"
import * as React from "react"
import styled from "styled-components"

interface IInfoBox {
  message: JSX.Element | string
  width?: number | string
  hideIcon?: boolean
  boxColor?: string
  borderColor?: string
}

const InfoBox = (props: IInfoBox) => {
  return (
    <InfoCard
      width={props.width}
      boxColor={props.boxColor}
      borderColor={props.borderColor}
    >
      {props.hideIcon ? null : <Icon className="fas fa-info-circle" />}
      <Message>{props.message}</Message>
    </InfoCard>
  )
}

const InfoCard = styled.div<any>`
  border-radius: 6px;
  border: solid 1px
    ${(props: any) =>
      props.borderColor ? props.borderColor : "rgba(135, 188, 48, 0.8)"};
  background-color: ${(props: any) =>
    props.boxColor ? props.boxColor : "transparent"};
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  align-items: left;
  justify-content: left;
  max-width: ${(props: any) =>
    props.width
      ? _.isNumber(props.width)
        ? `${props.width}px`
        : props.width
      : "100%"};
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  @media ${media.medium} {
    padding: 5px 5px;
  }
`

const Icon = styled.i`
  padding: 3px 10px 0px 0px;
  color: #2a6a37;
`

const Message = styled.div`
  color: #2a6a37;
  align-items: center;
  display: flex;
`

export default InfoBox
