import { ButtonSecondary } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Section } from "@src/Components/StyledUtils"
import { colors, media, shadows } from "@src/theme"
import { ViewTypes } from "@src/types"
import * as React from "react"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import styled, { css } from "styled-components"

const createDetailMarkup = (html: string) => {
  return { __html: html }
}

interface IPartnerCard {
  introduction: any
  name: string
}

const PartnerCard = (props: IPartnerCard) => {
  const history = useHistory()
  const { isDesktop } = usePortalSize()
  const [isHover, setIsHover] = useState(false)
  return (
    <CardContainer
      isDesktop
      onClick={() => {
        history.push(`${ViewTypes.marketplace}/${props.name}`)
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <CardBody>
        {props.name ? <PartnerName>{props.name}</PartnerName> : null}
        {props.introduction.heading ? (
          <CardHeading>{props.introduction.heading}</CardHeading>
        ) : null}
        {props.introduction.description ? (
          <Description>{props.introduction.description}</Description>
        ) : null}
        <IconContainer>
          <ButtonSecondary
            width={120}
            inverse={isDesktop && isHover ? true : false}
          >
            Learn More
          </ButtonSecondary>
          {props.introduction.footerMessage ? (
            <DiscountMessage>
              {props.introduction.footerIconName ? (
                <Section padding="0 5 0 0">
                  <i className={props.introduction.footerIconName} />{" "}
                </Section>
              ) : null}
              <Section
                dangerouslySetInnerHTML={createDetailMarkup(
                  props.introduction.footerMessage,
                )}
              />
            </DiscountMessage>
          ) : null}
        </IconContainer>
      </CardBody>
    </CardContainer>
  )
}

const CardContainer = styled.div<{ isDesktop: boolean }>`
  display: flex;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  box-shadow: ${shadows.panel};
  flex: 1 0 400px;
  min-height: 300px;
  @media (max-width: 500px) {
    flex-basis: 270px;
  }
  @media ${media.medium} {
    min-height: 20px;
    border: none;
    border-bottom: 1px solid ${colors.gray5};
    box-shadow: none;
    padding-bottom: 20px;
  }
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      &:hover {
        background-color: #f0f7ff;
        cursor: pointer;
        box-shadow: ${shadows.hoveredPanel};
      }
    `}
`

const CardBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 24px;
  @media ${media.medium} {
    padding: 0;
  }
`

const PartnerName = styled.div`
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #212528;
`

const IconContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  button:hover {
    background-color: ${colors.darkBlue};
    color: ${colors.white};
  }
`

const CardHeading = styled.div`
  margin-bottom: 5px;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #291c9e;
  min-height: 30px;
`

const Description = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  margin: 0px 0px 10px 0px;
`

const DiscountMessage = styled.div`
  flex: 1 1 30%;
  color: #1c7f30;
  display: flex;
  justify-content: flex-end;
`

export default PartnerCard
