import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { FaIcon } from "@src/Components/FaIcon"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { colors } from "@src/theme"
import * as React from "react"

import { IGoalsOption } from "./OrgGoals"
import { CommentTextArea, GoalOptionContainer } from "./styles"

interface IGoalOption {
  goal: IGoalsOption
  isSelected: boolean
  withText?: boolean
  onChangeText?: (val: string) => void
  onSelect: () => void
}

const GoalOption = ({
  goal,
  isSelected,
  withText,
  onChangeText,
  onSelect,
}: IGoalOption) => {
  const { isDesktop } = usePortalSize()
  const { key, label, icon, labelMobile, tooltip } = goal
  const [text, setText] = React.useState("")

  const handleSelect = () => {
    if (isSelected) {
      setText("")
    }
    onSelect()
  }

  const handleChangeText = (val: string) => {
    setText(val)
    onChangeText?.(val)
  }

  return (
    <GoalOptionContainer
      withComment={withText}
      onClick={handleSelect}
      isSelected={isSelected}
    >
      <Section flex="start center">
        <FaIcon
          className={`fas fa-${icon}`}
          margin={isDesktop ? "0 22px 0 0" : "0 12px 0 0"}
        />
        <Section flexGrow="1">{(!isDesktop && labelMobile) || label}</Section>
        {isDesktop && tooltip && (
          <>
            <FaIcon
              data-tip
              data-for={`goal-tooltip-${key}`}
              className="fas fa-info-circle"
              fontSize="24px"
              margin="0 26px 8px 0"
              color={colors.gray42}
            />
            <Tooltip id={`goal-tooltip-${key}`} content={<p>{tooltip}</p>} />
          </>
        )}
        <FaIcon
          className={isSelected ? "fas fa-check-circle" : "fal fa-circle"}
          fontSize={isDesktop ? "32px" : "24px"}
          color={isSelected ? colors.success : colors.gray20}
        />
      </Section>
      {withText && (
        <CommentTextArea
          onChange={(e) => handleChangeText(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          value={text}
          placeholder="Type any other goals (optional)"
        />
      )}
    </GoalOptionContainer>
  )
}

export default GoalOption
