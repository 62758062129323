import { Collapse } from "@blueprintjs/core"
import { FaIcon } from "@src/Components/FaIcon"
import Tooltip from "@src/Components/Tooltip"
import {
  GRADE_COLOR,
  TOOLTIP_INFO_COURSES,
} from "@src/Portal/Training/constants"
import { colors } from "@src/theme"
import { TCollapse } from "@src/types"
import { CourseResults as ICourseResults } from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"

import { scoreToGrade } from "../../utils"
import {
  ChevronIcon,
  GradeText,
  GradeTextWrap,
  HeadCardDetails,
  HeaderPanel,
  HeadSubTitle,
  HeadTitle,
  HeadTitleWrapper,
  Image,
} from "./styles"

interface ICollapsiblePanel {
  children: React.ReactNode
  collapse: TCollapse
  title: string
  icon: string
  setCollapse: (arg: Record<string, boolean>) => void
  courseKey: string
  course: ICourseResults
}

const defaultCourseIcon = "images/training/default_course.svg"

const CollapsiblePanel = ({
  children,
  collapse,
  title,
  icon,
  setCollapse,
  courseKey,
  course,
}: ICollapsiblePanel) => {
  const [isCollapse, setIsCollapse] = React.useState(collapse[title])
  const iconSrc: string = icon || defaultCourseIcon
  const [chevronIcon, setChevronIcon] = React.useState("fas fa-chevron-up")
  const collapseRef = React.useRef<HTMLDivElement | null>(null)
  const isGapOrBeginner = course.difficulty < 3
  const isBeginnerCourse = course.name === "Beginner"
  const toggle = () => setCollapse({ ...collapse, [title]: !isCollapse })
  const grade = (courseData: ICourseResults) =>
    courseData?.topics.filter((topic) => typeof topic.score === "number")
      .length === courseData?.topics?.length &&
    typeof courseData.averageScore === "number"
      ? scoreToGrade(courseData.averageScore)
      : undefined

  React.useEffect(() => {
    setChevronIcon(isCollapse ? "fas fa-chevron-up" : "fas fa-chevron-down")
  }, [isCollapse])

  React.useEffect(() => {
    setIsCollapse(collapse[title])
  }, [collapse, title])

  React.useEffect(() => {
    const { current } = collapseRef
    current !== null &&
      current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
  }, [collapseRef.current])

  const courseTooltip = (courseName: string) => (
    <>
      {TOOLTIP_INFO_COURSES[courseName].map((text, i) => (
        <p key={i}>
          {text}
          {i < TOOLTIP_INFO_COURSES[courseName].length - 1 && (
            <>
              <br />
              <br />
            </>
          )}
        </p>
      ))}
    </>
  )

  return (
    <HeaderPanel
      isCollapse={isCollapse}
      ref={title === courseKey ? collapseRef : null}
    >
      <HeadCardDetails onClick={toggle}>
        <HeadTitleWrapper>
          <Image src={iconSrc} alt="card type" />
          <HeadTitle>
            {!!course.subject && <HeadSubTitle>{course.subject}</HeadSubTitle>}
            {isGapOrBeginner ? "Knowledge Assessment + Beginner" : course.name}
            &nbsp;
            {TOOLTIP_INFO_COURSES[course.name] && (
              <>
                <FaIcon
                  data-tip
                  data-for={`${course.name}-course-tooltip`}
                  className="fas fa-info-circle"
                  color={colors.gray40}
                  fontSize="16px"
                />
                <Tooltip
                  width="600px"
                  id={`${course.name}-course-tooltip`}
                  content={courseTooltip(course.name)}
                />
              </>
            )}
          </HeadTitle>

          <GradeTextWrap>
            <GradeText
              bgColor={
                GRADE_COLOR[
                  isGapOrBeginner && !isBeginnerCourse
                    ? "PENDING"
                    : grade(course) || "PENDING"
                ]
              }
            >
              {isGapOrBeginner && !isBeginnerCourse
                ? "Not Finished"
                : grade(course)
                ? `Finished ${grade(course)}`
                : "Not Finished"}
            </GradeText>
          </GradeTextWrap>
        </HeadTitleWrapper>
        <ChevronIcon>
          <i className={chevronIcon} />
        </ChevronIcon>
      </HeadCardDetails>
      <Collapse isOpen={collapse[title]}>
        <div>{children}</div>
      </Collapse>
    </HeaderPanel>
  )
}

export default CollapsiblePanel
