import styled from "styled-components"

interface IIcon {
  height?: number
  width?: number
  hidden?: boolean
}
const Icon = styled.div`
  display: inline-block;
  visibility: ${(props: IIcon) => (props.hidden ? "hidden:" : "visible")};
  ${(props: IIcon) => (props.width ? "width:" + props.width + "px;" : "")};
  ${(props: IIcon) => (props.height ? "height:" + props.height + "px;" : "")};
  ${(props: IIcon) =>
    props.height ? "line-height:" + props.height + "px;" : ""};
  text-align: center;
  cursor: pointer;
`

export default Icon
