import TitledList from "@src/Components/TitledList"
import * as React from "react"

import { GreenBoldText, SubTitle, TrainingIntroList } from "./styles"

interface IFeatureCfg {
  [key: string]: {
    title: string | JSX.Element
    imageSrc: string
    imageHeight?: string
    text: string | JSX.Element
    contentPadding?: string
    actionButtonText?: string
    actionButtonWidth?: number
    actionButtonLink?: string
  }[]
}

const featuresCfg: IFeatureCfg = {
  compliance: [
    {
      title: "Welcome to Security Policies.",
      imageSrc: "/images/intro/graphic_compliance_modal3.svg",
      text: `Security policies document the official guidance for your organization.
      In order to handle data, information systems, and business processes
      in accordance with your compliance objectives,
      your team members need policies to govern the work they do.`,
    },
    {
      title: "How can these security policies help me?",
      imageSrc: "/images/intro/graphic_compliance_modal2.svg",
      text: (
        <div>
          <p>
            Cyber Safety security policy templates are lightweight and were
            written by our team of cybersecurity experts. They are designed for
            an organization looking to implement security policies for the first
            time.
          </p>
          <p>
            If you have compliance requirements, such as HIPAA or PCI-DSS, you
            will need to write additional policies to meet those requirements,
            but Cyber Safety can help you get started with your core policies.
          </p>
        </div>
      ),
    },
    {
      title: "How do I use security policies?",
      imageSrc: "/images/intro/graphic_compliance_modal1.svg",
      text: (
        <div>
          <p>
            We recommend you start with our Core policies, but feel free to pick
            the ones your organization needs most.
          </p>
          <TitledList
            orderedList
            items={[
              "Navigate to the desired policy.",
              "Read through the policy and make any edits/customizations.",
              'Click "Approve Policy"; your policy is now active and can be downloaded for distribution to your team.',
            ]}
          />
        </div>
      ),
    },
  ],
  monitoring: [
    {
      title: "Welcome to Monitoring.",
      imageSrc: "/images/intro/graphic_scanning_modal1.svg",
      text: (
        <div>
          <p>
            Websites (software that runs in a web browser) can contain security
            vulnerabilities. Hackers can exploit these to steal your
            company&apos;s data or disrupt your operations.
          </p>
          <p>
            Website vulnerability scans let you find these weaknesses before
            hackers do, so you can fix them.
          </p>
        </div>
      ),
    },
    {
      title: "How does it work?",
      imageSrc: "/images/intro/graphic_scanning_modal2.svg",
      text: (
        <TitledList
          items={[
            " We'll ask you for the details of your website on the next page, including the web address and the frequency you'd like to scan it.",
            "The scan will run automatically at the frequency you have specified.",
            "A report with your scan results will be available to view and download here in the Monitoring module.",
          ]}
        />
      ),
    },
  ],
  ratings: [
    {
      title: "Welcome to Attack Surface Analytics.",
      imageSrc: "/images/Sr_color.svg",
      text: (
        <p>
          Attack Surface Report provides security rating data to dynamically
          measure an organization’s cybersecurity performance.
        </p>
      ),
    },
    {
      title: "How does it work?",
      imageSrc: "/images/intro/graphic_scanning_modal2.svg",
      text: `Learn about your attack surfaces to get the comprehensive outside-in view of your company’s security risk. `,
    },
  ],
  training: [
    {
      title: "Welcome to Security and Compliance Training.",
      imageSrc: "/images/intro/graphic_training_modal1.svg",
      text: (
        <div>
          Online cybersecurity training will help{" "}
          <strong>protect your team</strong> against a{" "}
          <strong>wide range of security risks</strong>. Learn about crucial
          topics such as ransomware, phishing schemes, and security best
          practices.
          <br />
          <br />
          <GreenBoldText>NEW</GreenBoldText> Get your team up to speed on{" "}
          <strong>HIPAA</strong>, <strong>GDPR</strong>, <strong>CCPA</strong>,
          and other compliance-related topics.
        </div>
      ),
    },
    {
      title: "How is training done?",
      imageSrc: "/images/intro/graphic_training_modal2.svg",
      text: (
        <div>
          <strong>Training starts with the Cybersecurity Series.</strong> Each
          team member will:{" "}
          <TrainingIntroList>
            <li>
              Be emailed a link to an assessment to identify their{" "}
              <strong>strengths and weaknesses</strong>.
            </li>
            <li>
              Assigned one course per month on subjects they need the{" "}
              <strong>most help with</strong>.
            </li>
            <li>
              All team members will complete the{" "}
              <strong>same 12 courses</strong> over one year.
            </li>
            <li>
              Choose additional <strong>specialized courses</strong> for team
              members tailored to their roles.
            </li>
          </TrainingIntroList>
        </div>
      ),
    },
    {
      title: "How do I track results?",
      imageSrc: "/images/intro/graphic_training_modal3.svg",
      text: (
        <div>
          View report cards to track your{" "}
          <strong>organization’s progress</strong>, as well as{" "}
          <strong>individual team members</strong>.
        </div>
      ),
    },
  ],
  cybermaturity: [
    {
      title: "Welcome to Cyber Maturity",
      imageSrc: "/images/zimm/icon_cyber_maturity_lg.svg",
      text: (
        <div>
          <h3>How does it work?</h3>
          You’ll start with an assessment to determine your organization’s level{" "}
          of <strong>security maturity.</strong>
          <br />
          <br />
          These security questions cover three broad areas:{" "}
          <strong>People, Process, </strong> and <strong>Technology.</strong>
          <br />
          <br />
          Within these areas are <strong>Elements</strong>, building blocks to
          establish a more secure organization. For example,{" "}
          <strong>Training</strong> is an <strong>Element</strong> that
          addresses <strong>People.</strong>
        </div>
      ),
    },
    {
      title: "Cyber Maturity Levels",
      imageSrc: "/images/intro/graphic_zimm_modal2.svg",
      imageHeight: "32px",
      text: (
        <div>
          Based on your responses, we’ll assign a numerical{" "}
          <strong>Level</strong> (score) from 0-5 to each{" "}
          <strong>Element</strong>. We’ll help you{" "}
          <strong>increase your Levels</strong> by providing{" "}
          <strong>recommended actions </strong>
          and useful templates.
        </div>
      ),
    },
    {
      title: "Your Cyber Maturity Level",
      imageSrc: "/images/intro/graphic_zimm_modal3.svg",
      text: (
        <div>
          Your <strong>Cyber Maturity Level</strong> is the same as your{" "}
          <strong>lowest scoring Element.</strong> For example, if your
          organization had all 4s, except “Identity and Access Management”
          scored a 2, then your <strong>Maturity Level</strong> would be 2. This
          way we help you <strong>avoid security blindspots.</strong>
        </div>
      ),
    },
  ],
  cyberconcierge: [
    {
      title: "Welcome to Cyber Concierge",
      imageSrc: "/images/intro/graphic_cyberConcierge_modal.svg",
      text: (
        <div>
          <SubTitle>One-on-one live help from a cyber expert</SubTitle>
          The Concierge can help with all your{" "}
          <strong>cybersecurity needs</strong>. Meet in a{" "}
          <strong>virtual session</strong>, or <strong>email</strong> to{" "}
          <strong>ask anything</strong> about cybersecurity. Get{" "}
          <strong>customized advice</strong> about protecting your organization.
          You’ll also get <strong>alerts</strong> about{" "}
          <strong>security events</strong> and <strong>vulnerabilities</strong>{" "}
          that affect <strong>your organization</strong>.
        </div>
      ),
    },
  ],
}

export default featuresCfg
