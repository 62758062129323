import { colors } from "@src/theme"
import styled from "styled-components"

export const SubscribeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 98, 216, 0.05);
  border: 1px solid rgba(0, 98, 216, 0.5);
  border-radius: 6px;
  padding: 10px 28px;
  margin-bottom: 20px;
`

export const CrownIcon = styled.i`
  color: ${colors.pink};
  font-size: 24px;
  margin-right: 12px;
`

export const SubscribeText = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.darkBlue};
`
