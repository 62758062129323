import { openPolicyHistory } from "@src/Actions/PolicyActions"
import * as ACTION from "@src/Actions/PolicyActions"
import { downloadPolicy } from "@src/ControllerModules/Compliance"
import * as T from "@src/types"
import * as React from "react"
import { connect } from "react-redux"

import { IHistoryRecord, tState } from "../../Model/Model"
import History from "./History"

interface IHistoryContainer {
  height: number
  request: T.IRequestStatus
  historyList: IHistoryRecord[]
  policyType: string
  policyName: string
  onDownload: (policyType: string, policyVersion: number) => void
  onVersion: (policyType: string, version: number) => void
  onClose: () => void
}

class HistoryContainer extends React.Component<IHistoryContainer, object> {
  render() {
    return (
      <History
        height={this.props.height}
        request={this.props.request}
        historyList={this.props.historyList}
        policyType={this.props.policyType}
        policyName={this.props.policyName}
        onDownload={this.props.onDownload}
        onVersion={this.props.onVersion}
        onClose={this.props.onClose}
      />
    )
  }
}

interface IHistoryContainerMap {
  request: T.IRequestStatus
  historyList: IHistoryRecord[]
  policyType: string
  policyName: string
}

const mapStateToProps = (store: tState): IHistoryContainerMap => {
  return {
    request: store.policy.request,
    historyList: store.policy.historyList,
    policyType: store.policy.historyPolicyType,
    policyName: store.policy.historyPolicyName,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onVersion: (id: string, version: number) => {
      dispatch(ACTION.requestPolicy({ id, version }))
    },
    onDownload: (policyType: string, policyVersion: number) => {
      downloadPolicy(policyType, policyVersion)
    },
    onClose: () => {
      dispatch(openPolicyHistory({ historyOpen: false }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContainer)
