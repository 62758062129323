import { FaIcon } from "@src/Components/FaIcon"
import { colors } from "@src/theme"
import styled from "styled-components"

interface IOval {
  borderColor: string
  color: string
}

export const Oval = styled.div<IOval>`
  width: 16px;
  height: 16px;
  border: solid 1px ${({ borderColor }) => borderColor || "#ff002a"};
  margin-right: 10px;
  background-color: ${({ color }) => color || "#ff002a"};
  border-radius: 8px;
`

export const BoxBody = styled.div`
  width: 100%;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: normal;
`
export const IssueSeverityContainer = styled.div`
  min-height: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 901px) and (max-width: 985px) {
    flex-direction: column;
    align-items: start;
  }
  @media (max-width: 624px) {
    flex-direction: column;
    align-items: start;
  }
`
export const IssuesText = styled.p`
  font-weight: 600;
  font-size: 21px;
  font-family: montserrat;
  margin-right: 16px;
  display: flex;
  align-items: center;
  color: ${colors.darkBlue};
`
export const IssueContainer = styled.div`
  min-height: 30px;
  width: 100%;
  font-family: proxima-nova, sans-serif;
  display: flex;
  @media (max-width: 1071px) {
    flex-direction: column;
  }
`
export const ScanInfoContainer = styled.div`
  padding: 20px 0 0 0;
  margin-top: 30px;
  border-top: 1px solid ${colors.gray5};
  a {
    text-decoration: none;
  }
`
export const ScanInfoContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 215px;
  margin-top: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 0;
  }
`
export const ScanInfoElement = styled.div<{ align?: string }>`
  display: flex;
  justify-content: center;
  align-items: ${({ align }) => align || "center"};
  flex-direction: column;
`
export const ScanInfoElementText = styled.p<{ color?: string }>`
  font-size: 16px;
  color: ${({ color }) => color || colors.darkBlue};
  margin-top: 5px;
  text-align: center;
`
export const CollapseArrow = styled(FaIcon)<{ showMore: boolean }>`
  transition: transform 333ms ease-out;
  transform: ${({ showMore }) => showMore && "rotate(180deg)"};
`
export const DownloadRow = styled.div<{ justifyContent?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  #scan-tables-download-report-btn {
    padding: 0 10px;
    i {
      margin: 0 3px;
    }
  }
`
export const PopoverDownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  line-height: 24px;
`
export const DownloadHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: proxima-nova, sans-serif;
  padding: 0 0 13px 0;
  @media (max-width: 625px) {
    justify-content: start;
  }
  @media (max-width: 320px) {
    padding: 0 13px 13px 13px;
  }
`
export const Issue = styled.div<{ opacity?: number }>`
  width: 33.33%;
  padding: 0 15px;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 176px;
  &:not(:last-child) {
    @media (min-width: 625px) {
      border-right: 1px solid ${colors.gray5};
    }
    @media (min-width: 901px) and (max-width: 985px) {
      border-right: none;
    }
  }
  @media (min-width: 901px) and (max-width: 985px) {
    border-right: none;
    align-items: center;
    width: 100%;
    padding: 0;
    flex-direction: row;
    justify-content: start;
    margin-bottom: 10px;
  }
  @media (max-width: 624px) {
    align-items: center;
    width: 100%;
    padding: 0;
    flex-direction: row;
    justify-content: start;
    margin-bottom: 10px;
  }
`

export const IssueCount = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212528;
  @media (min-width: 901px) and (max-width: 985px) {
    margin-right: 15px;
  }
  @media (max-width: 624px) {
    margin-right: 15px;
  }
`

export const IssueSeverity = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #212528;
`

export const PopoverContent = styled.div`
  padding: 10px 16px;
  max-width: 350px;
  font-size: 16px;
  line-height: 21px;
  font-family: proxima-nova, sans-serif;
  a {
    color: ${colors.white};
    &:hover {
      color: ${colors.white};
    }
  }
`
