import history from "@src/browserHistory"
import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { FaIcon } from "@src/Components/FaIcon"
import { Markdown } from "@src/Components/Markdown"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import { ICategory } from "@src/Portal/Zimm/Assessment"
import {
  removeAssessmentDataWatcher,
  saveAssessmentDataWatcher,
} from "@src/Sagas"
import { colors } from "@src/theme"
import { ViewTypes } from "@src/types"
import {
  CyberMaturityCatalog as ICyberMaturityCatalog,
  CyberMaturityOrgData as ICyberMaturityOrgData,
} from "@zeguro/schema-validator/dist/types/coco/cyberMaturity/combined"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router-dom"

import { CATEGORY_ICONS } from "../constants"
import QuestionSelector from "../QuestionSelector"
import { textToPath } from "../utils"
import {
  ContentPanel,
  Divider,
  Header,
  Img,
  InterstitialBody,
  InterstitialHeader,
  LinkStyled,
  NavigationPanel,
  ProgressPanel,
  ProgressTabPanel,
  QuestionPanel,
  QuestionText,
  Toolbar,
} from "./styles"

interface IProps {
  categories: ICategory[]
  config: ICyberMaturityCatalog["elements"]
  interstitials: ICyberMaturityCatalog["interstitials"]
  response?: ICyberMaturityOrgData["response"]
}

const Questionnaire = ({
  categories,
  config,
  response,
  interstitials,
}: IProps) => {
  const dispatch = useDispatch()
  const { isDesktop } = usePortalSize()
  const { params } = useRouteMatch<{ id?: string }>()
  const isSaveAssessmentLoading = useSelector(
    (state: tState) => state.zimm.saveAssessmentData.isLoading,
  )
  const isRemoveAssessmentLoading = useSelector(
    (state: tState) => state.zimm.removeAssessmentData.isLoading,
  )
  const isLoading = isSaveAssessmentLoading || isRemoveAssessmentLoading
  const [selectedOptionId, setSelectedOptionId] = React.useState(null)
  const isAssessmentStarted = !!response?.questions.length
  const element = config.find(
    ({ elementName }) => params.id === textToPath(elementName),
  )
  const category =
    categories.find(
      ({ elementCategory }) => elementCategory === element?.elementCategory,
    ) || categories.find(({ elementCategory }) => elementCategory === params.id)
  const interstitial =
    !element &&
    interstitials.find(
      ({ elementCategory }) => elementCategory === category?.elementCategory,
    )
  const isQuestionWithAnswer = response?.questions.find(
    (question) => question.questionId === element?.question.questionId,
  )
  const findOption = (optionId: string) =>
    response?.questions.find((el) => el.optionId === optionId)
  const questionnaireRoutes = categories.reduce((prev, cur) => {
    const elements = config
      .filter(({ elementCategory }) => elementCategory === cur.elementCategory)
      .map((el) => el.elementName)
    return [...prev, cur.elementCategory, ...elements]
  }, [])
  const currentRouteIndex = questionnaireRoutes.findIndex(
    (route) => textToPath(route) === params.id,
  )

  const onSubmitAnswer = ({ optionId }: any) => {
    if (element) {
      setSelectedOptionId(optionId)
      if (findOption(optionId)) {
        dispatch(removeAssessmentDataWatcher({ optionId }))
      } else {
        const payload = {
          elementId: element.elementId,
          optionId,
        }
        dispatch(saveAssessmentDataWatcher(payload))
      }
    }
  }
  const goToQuestion = (name: string) =>
    history.push(`${ViewTypes.zimm}/assessment/${textToPath(name)}`)

  const previousQuestion = () =>
    goToQuestion(questionnaireRoutes[currentRouteIndex - 1])

  const nextQuestion = () => {
    if (currentRouteIndex + 1 === questionnaireRoutes.length) {
      history.push(
        `${ViewTypes.zimm}/${isAssessmentStarted ? "report" : "assessment"}`,
      )
    } else {
      goToQuestion(questionnaireRoutes[currentRouteIndex + 1])
    }
  }
  const options = element?.question.options
  const getCategoryIcon = (category?: string) =>
    CATEGORY_ICONS.find(({ name }) => name === category)?.colorIcon

  React.useEffect(() => {
    setSelectedOptionId(null)
  }, [element])

  if (!category) {
    history.push("/404")
    return null
  }

  return (
    <Section w="100%" flex="start stretch column" flexGrow="1">
      <Header>
        Assessment
        {!isDesktop && `: ${category.elementCategory}`}
      </Header>
      <Toolbar>
        {isDesktop && (
          <Section flex="flex-start">
            <ProgressPanel>
              {categories.map(({ elementCategory }, index) => (
                <>
                  <ProgressTabPanel
                    key={elementCategory}
                    selected={elementCategory === category?.elementCategory}
                    onClick={() =>
                      history.push(
                        `${ViewTypes.zimm}/assessment/${elementCategory}`,
                      )
                    }
                  >
                    {elementCategory}
                  </ProgressTabPanel>
                  {index + 1 < categories.length && <Divider />}
                </>
              ))}
            </ProgressPanel>
          </Section>
        )}
        <QuestionSelector
          categories={categories}
          config={config}
          response={response}
          curOption={element?.elementName || category.elementCategory}
          goToQuestion={goToQuestion}
        />
      </Toolbar>
      <ContentPanel>
        {element ? (
          <>
            <QuestionText>
              <Markdown pMaxWidth="60ch">
                {element.question.questionText}
              </Markdown>
            </QuestionText>
            <Img margin="0px 0px 36px 0px" src={element.elementImageUrl} />
            {options?.map((option, index) => {
              const elementIndex = config.findIndex(
                ({ elementId }) => elementId === element.elementId,
              )
              return (
                <QuestionPanel
                  selected={
                    !!response?.questions?.find(
                      (question) => question.optionId === option.optionId,
                    )
                  }
                  activeState={
                    selectedOptionId === option.optionId && isLoading
                  }
                  onClick={() => onSubmitAnswer(option)}
                  key={"qp-" + option.optionId}
                  id={`cm-questionnaire-question-${
                    elementIndex + 1
                  }-answer-${index}`}
                >
                  {selectedOptionId === option.optionId && isLoading
                    ? "Saving..."
                    : option.option}
                </QuestionPanel>
              )
            })}
          </>
        ) : (
          interstitial && (
            <>
              <InterstitialHeader>
                <Img
                  height="55px"
                  margin="0 14px 0 0"
                  src={`/images/zimm/${getCategoryIcon(
                    interstitial.elementCategory,
                  )}`}
                  alt={interstitial.elementCategory}
                ></Img>
                {interstitial.elementCategory}
              </InterstitialHeader>
              <InterstitialBody>
                <Markdown>{interstitial.description || ""}</Markdown>
              </InterstitialBody>
            </>
          )
        )}
        <NavigationPanel maxWidth={(interstitial && "740px") || undefined}>
          <Section flex="flex-start center" gap="36px" flexBasis="30%">
            {!!currentRouteIndex && (
              <ButtonSecondary
                width="41"
                backgroundColor={colors.white}
                onClick={previousQuestion}
              >
                <FaIcon className="fas fa-chevron-left" />
              </ButtonSecondary>
            )}
          </Section>
          {isDesktop && (
            <LinkStyled
              data-tip
              data-for="tooltip-see-my-level"
              disabled={!isAssessmentStarted}
              onClick={() => history.push(`${ViewTypes.zimm}/report`)}
            >
              <Img
                height="16px"
                margin="0 10px 0 0"
                src="images/zimm/icon_cyber_maturity_lg.svg"
                cursor="inherit"
              />
              See current level
            </LinkStyled>
          )}
          <Section flex="flex-end center" flexBasis="30%" gap="16px">
            {!interstitial && (
              <LinkStyled
                padding="0 20px"
                onClick={nextQuestion}
                disabled={!!isQuestionWithAnswer}
              >
                Skip
              </LinkStyled>
            )}
            <ButtonSmall
              width="86"
              onClick={nextQuestion}
              disabled={!interstitial && !isQuestionWithAnswer}
            >
              Next
            </ButtonSmall>
          </Section>
        </NavigationPanel>
        {!isAssessmentStarted && (
          <Tooltip
            content={<p>Answer at least one question to see your level</p>}
            width="220px"
            id="tooltip-see-my-level"
          />
        )}
        {!isDesktop && (
          <Section margin={!isDesktop && "24 0"}>
            <LinkStyled
              data-tip
              data-for="tooltip-see-my-level-mobile"
              disabled={!isAssessmentStarted}
              onClick={() => history.push(`${ViewTypes.zimm}/report`)}
            >
              <Img
                height="16px"
                margin="0 10px 0 0"
                src="images/zimm/icon_cyber_maturity_lg.svg"
                cursor="inherit"
              />
              See current level
            </LinkStyled>
            {!isAssessmentStarted && (
              <Tooltip
                content={<p>Answer at least one question to see your level</p>}
                width="220px"
                id="tooltip-see-my-level-mobile"
              />
            )}
          </Section>
        )}
      </ContentPanel>
    </Section>
  )
}

export default Questionnaire
