import DialogComp from "@src/Components/Dialog"
import { Link } from "@src/Components/Link"
import styled from "styled-components"

export const Dialog = styled(DialogComp)`
  width: 90%;
  max-width: 500px;

  &.zeg-danger {
    max-width: 500px;
  }
`

export const Section = styled.div`
  margin: 20px;
  @media (max-width: 400px) {
    margin: 15px;
  }
`

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
`
export const ConfirmationText = styled.div<{ contentMargin?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #212528;
  margin: ${({ contentMargin }) => contentMargin || "0 15px 15px"};
`
