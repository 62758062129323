import { Collapse } from "@blueprintjs/core"
import { IRatingDetails, ITabsContext } from "@src/types"
import * as React from "react"

import CategoryNameGrade from "../CategoryNameGrade"
import { TabsContext } from "../Ratings"
import { CategoryListStyled, LinkOverview } from "./styles"

interface IProps {
  category: string
  data: IRatingDetails[]
}

const CategoryListCollapse = ({ category, data }: IProps) => {
  const [isCollapse, setIsCollapse] = React.useState(true)
  const [chevronIcon, setChevronIcon] = React.useState("fas fa-chevron-up")
  const { setScrollTo } = React.useContext(TabsContext) as ITabsContext

  const filtered = data
    .filter((risk) => risk.category === category)
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .sort((a, b) => (a.beta ? (b.beta ? 0 : 1) : b.beta ? -1 : 0))

  React.useEffect(() => {
    setChevronIcon(isCollapse ? "fas fa-chevron-up" : "fas fa-chevron-down")
  }, [isCollapse])

  return (
    <CategoryListStyled>
      <div
        className="head-category-wrapper"
        onClick={() => setIsCollapse(!isCollapse)}
      >
        <h3>{category}</h3>
        <i className={chevronIcon} />
      </div>
      <Collapse isOpen={isCollapse}>
        <ul>
          <LinkOverview onClick={() => setScrollTo(category)}>
            Overview
          </LinkOverview>
          {filtered.map((risk) => (
            <div key={risk.id}>
              <CategoryNameGrade key={risk.id} risk={risk} />
            </div>
          ))}
        </ul>
      </Collapse>
    </CategoryListStyled>
  )
}

export default CategoryListCollapse
