import { usePortalSize } from "@src/Components/Common/usePortalSize"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { ViewTypes } from "@src/types"
import * as _ from "lodash"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import OnBoardingForm from "./OnBoardingForm"

export type tOnSubmit = (values: any, callback: any) => void

const OnBoardingContainer = () => {
  const { clientHeight, clientWidth } = usePortalSize()
  const history = useHistory()
  const { companyProfile, signupData } = useSelector(
    (state: tState) => state.portal,
  )
  const brand = useSelector((state: tState) => state.brand)

  const onSubmit: tOnSubmit = (values: any, callback: () => void) => {
    C.Portal.saveOnBoardingForm(values, callback)
  }
  const goToIntro = () => history.push(ViewTypes.intro)

  React.useEffect(() => {
    if (!_.isEmpty(companyProfile)) {
      goToIntro()
    }
  })
  const inviteUser = (
    inviteUser: {
      email: string
      firstName: string
      lastName: string
      jobTitle: string
    },
    callback?: () => void,
  ) => {
    if (inviteUser.email) {
      C.Portal.inviteUser(
        inviteUser.email,
        inviteUser.firstName,
        inviteUser.lastName,
        inviteUser.jobTitle,
        (res: any) => {
          callback && callback()
          if (res.success) {
            C.toastNotification("Invited user successfully", "success")
          } else {
            C.toastNotification("Error in user invitation", "danger")
          }
        },
      )
    }
  }

  return (
    <OnBoardingForm
      height={clientHeight}
      width={clientWidth}
      onSubmit={onSubmit}
      inviteUser={inviteUser}
      goToIntro={goToIntro}
      signupData={signupData}
      brand={brand}
    />
  )
}

export default OnBoardingContainer
