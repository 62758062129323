import { LoaderCard } from "@src/Portal/Dashboard/LoaderCard"
import * as React from "react"

interface ICardWrapper {
  isLoading: boolean
  isNotAvailable: boolean
  content: JSX.Element
  height?: number
  gridColumn?: string
}
export const CardWrapper = ({
  isLoading,
  isNotAvailable,
  height,
  gridColumn,
  content,
}: ICardWrapper) => {
  if (isLoading) {
    return <LoaderCard height={height} gridColumn={gridColumn} />
  }
  if (isNotAvailable) {
    return null
  }

  return content
}
