import Waiting from "@src/Components/Waiting"
import { ViewTypes } from "@src/types"
import * as React from "react"
import { Suspense } from "react"
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"
const TrainingContainer = React.lazy(
  () => import("@src/Portal/Training/TrainingContainer"),
)

export const TrainingRouter = ({ match }: RouteComponentProps) => (
  <Suspense fallback={<Waiting text="" />}>
    <Switch>
      <Route
        exact
        path={[
          ViewTypes.training,
          `${match.path}/:tab`,
          `${match.path}/:tab/:course`,
          `${match.path}/:tab/:course/:trainee`,
        ]}
        component={TrainingContainer}
      />
      <Redirect exact to="/404" />
    </Switch>
  </Suspense>
)
