import * as Yup from "yup"

type verifyIdentityFormValidation = Yup.AnyObjectSchema

export const useVerifyIdentityFormValidation =
  (): verifyIdentityFormValidation => {
    return Yup.object({
      workEmail: Yup.string()
        .trim()
        .email("Please enter a valid email address.")
        .required("Work email address is required.")
        .default(""),
    })
  }
