import { colors, media, shadows } from "@src/theme"
import styled from "styled-components"

interface IGradeText {
  bgColor: string
}
interface IHeaderPanel {
  isCollapse: boolean
}

export const HeaderPanel = styled.div<IHeaderPanel>`
  position: relative;
  border: 1px solid ${colors.gray10};
  padding: 5px 15px 15px;
  margin: 12px 0;
  box-shadow: ${({ isCollapse }) => isCollapse && shadows.panel};
  border-radius: 3px;

  &:hover {
    box-shadow: ${({ isCollapse }) => !isCollapse && shadows.panel};
  }

  @media ${media.medium} {
    box-shadow: none;
    border: none;
    padding: 0;
  }
`

export const Image = styled.img`
  height: 32px;
  width: 40px;
  margin-right: 15px;
`

export const GradeText = styled.span<IGradeText>`
  font-size: 15px;
  line-height: 20px;
  color: ${colors.white};
  background: ${({ bgColor }) => bgColor || colors.gray5};
  padding: 5px 8px;
  margin: 10px;
  border-radius: 3px;
  display: inline-block;
  width: 100px;
  text-align: center;
`

export const GradeTextWrap = styled.div`
  flex: 0 0 25%;
  display: flex;
  justify-content: center;
  min-width: 210px;

  @media (max-width: 600px) {
    display: flex;
    flex: 100%;
  }
`

export const HeadTitle = styled.h1`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: ${colors.darkBlue};
  flex: 50%;
  margin: 5px 0;
`

export const HeadSubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.darkBlue};
`

export const HeadCardDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const HeadTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    padding-right: 20px;
  }
`

export const ChevronIcon = styled.div`
  position: absolute;
  right: 25px;
  top: 20px;

  @media (max-width: 600px) {
    right: 15px;
  }
`
