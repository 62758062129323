import { Spinner } from "@blueprintjs/core"
import {
  actions as GLOBAL_MODALS,
  GLOBAL_MODALS_KEYS,
} from "@src/Actions/Reducers/GlobalModalsReducer"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Link } from "@src/Components/Link"
import { Content, ContentArea, Panel } from "@src/Components/View"
import { tState } from "@src/Model/Model"
import { HeaderCard } from "@src/Portal/Zimm/HeaderCard"
import { getAssessmentDataWatcher, getConfigWatcher } from "@src/Sagas"
import { colors } from "@src/theme"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"

import { ContactUs, LoadingPanel, LoadingText } from "./styles"

interface IProps {
  children: JSX.Element
}

export const ContactUsZimm = () => {
  const dispatch = useDispatch()
  const openContactModal = () => {
    dispatch(
      GLOBAL_MODALS.toggleGlobalModal(
        GLOBAL_MODALS_KEYS.isContactCyberConcierge,
      ),
    )
  }
  return (
    <ContactUs>
      Need help?{" "}
      <Link isUnderline withIcon onClick={openContactModal}>
        Contact Cyber Concierge (included in your subscription){" "}
      </Link>
    </ContactUs>
  )
}
const ZimmContainer = ({ children }: IProps) => {
  const dispatch = useDispatch()
  const getConfig = useSelector((store: tState) => store.zimm.getConfig)
  const getAssessmentData = useSelector(
    (store: tState) => store.zimm.getAssessmentData,
  )
  const { isDesktop } = usePortalSize()

  React.useEffect(() => {
    dispatch(getConfigWatcher())
    dispatch(getAssessmentDataWatcher())
  }, [])

  if (getConfig.isLoading || getAssessmentData.isLoading) {
    return (
      <LoadingPanel>
        <Spinner size={25} />
        <LoadingText>Loading...</LoadingText>
      </LoadingPanel>
    )
  }
  return (
    <ContentArea borderTopColor={colors.green30}>
      <HeaderCard />
      <Panel>
        <Content justify="space-between" padding="28px">
          {children}
          {isDesktop && <ContactUsZimm />}
        </Content>
      </Panel>
    </ContentArea>
  )
}

export default ZimmContainer
