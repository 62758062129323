import { PageLoadFailure } from "@src/Components/PageLoadFailure"
import Waiting from "@src/Components/Waiting"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { tRatingsTabs } from "@src/types"
import * as React from "react"
import { shallowEqual, useSelector } from "react-redux"

import Ratings from "./Ratings"

interface IProps {
  height: number
  width: number
}

const RatingsContainer: React.FC<IProps> = () => {
  const [selectedTab, setSelectedTab] = React.useState<tRatingsTabs>("overview")

  const status = useSelector((state: tState) => state.ratings.status)
  const ratingsData = useSelector(
    (state: tState) => state.ratings.ratingsData?.data,
    shallowEqual,
  )
  const companyProfile = useSelector(
    (state: tState) => state.portal.companyProfile,
    shallowEqual,
  )
  const companyUrl = companyProfile?.companyUrl

  React.useEffect(() => {
    C.heapTrack(`Viewed Ratings Page`)

    if (companyUrl && !ratingsData) {
      C.Ratings.loadRatingsData(companyUrl)
    }
  }, [companyUrl, ratingsData])

  if (status === "LOADING" && companyUrl) {
    return <Waiting text="Loading Rating." />
  }

  if (status === "FAILED") {
    return <PageLoadFailure />
  }

  return (
    <Ratings
      data={ratingsData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      companyProfile={companyProfile}
    />
  )
}

export default RatingsContainer
