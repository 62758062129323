import { tState } from "@src/Model/Model"
import * as moment from "moment"
import { createSelector } from "reselect"

const zimmGlossarySelector = (state: tState) => state.zimm.getConfig.glossary
export const glossarySelector = createSelector(
  zimmGlossarySelector,
  (glossary) => {
    return Object.keys(glossary).reduce((acc: Record<string, string>, key) => {
      acc[key.toLowerCase()] = glossary[key]
      return acc
    }, {})
  },
)

const plans = (state: tState) => state.plans
export const hasTrialPlanExpiredSelector = createSelector(plans, (plans) => {
  return (
    moment(Date.now())
      .startOf("day")
      .isAfter(Date.parse(plans.expirationDate), "day") &&
    plans.subscriptionStatus === "INTRIAL"
  )
})

const globalModalsSelector = (state: tState) =>
  state.globalModals.globalModalState
export const isAnyOfGlobalModalIsActiveSelector = createSelector(
  globalModalsSelector,
  (globalModals) => {
    return Object.values(globalModals).some((value) => value === true)
  },
)
