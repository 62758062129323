/**
 * PolicyListContainer.tsx
 * Container component for the policy list.
 *
 * Connects the stateless PolicyList components to the store.
 *
 */

import * as ACTION from "@src/Actions/PolicyActions"
import { actions as GLOBAL_MODALS } from "@src/Actions/Reducers/GlobalModalsReducer"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import C from "@src/Controller"
import {
  downloadPolicy,
  newCustomPolicy,
} from "@src/ControllerModules/Compliance"
import { tState } from "@src/Model/Model"
import * as T from "@src/types"
import { PolicyListItem } from "@zeguro/schema-validator/dist/types/coco/securityPolicy/combined"
import * as React from "react"
import { connect } from "react-redux"

import HistoryContainer from "../HistoryContainer"
import PolicyList from "../PolicyList"

interface IPolicyListContainer {
  width: number
  height: number
  onHistory: (policy: PolicyListItem) => void
  onDownload: (policy: PolicyListItem) => void
  onNewCustomPolicy: () => void
  onIntro: () => void
  onMount: () => void
}

interface IReduxProps {
  policies: PolicyListItem[]
  historyOpen: boolean
  request: T.IRequestStatus
}

const PolicyListContainer = (props: IPolicyListContainer & IReduxProps) => {
  const policyList = [...props.policies]
  const { clientWidth, clientHeight } = usePortalSize()

  React.useEffect(() => {
    C.heapTrack("Viewed Security Policy Home")
    props.onMount()
  }, [])

  return (
    <>
      {props.historyOpen && <HistoryContainer height={clientHeight} />}
      {(!props.historyOpen || clientWidth >= 450) && (
        <PolicyList
          request={props.request}
          policies={policyList}
          onHistory={props.onHistory}
          onDownload={props.onDownload}
          onIntro={props.onIntro}
        />
      )}
    </>
  )
}

const mapStateToProps = (store: tState): IReduxProps => {
  return {
    policies: store.policy.list,
    historyOpen: store.policy.historyOpen,
    request: store.policy.request,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onHistory: (policy: PolicyListItem) => {
      const { id, name } = policy
      dispatch(ACTION.requestHistory(id, name))
    },
    onNewCustomPolicy: (policyName: string, outOfboxPolicy: string) => {
      const id = outOfboxPolicy || policyName
      newCustomPolicy(id, policyName, 1)
    },
    onDownload: (policy: PolicyListItem) => {
      downloadPolicy(policy.id, policy.version)
    },
    onIntro: () => {
      dispatch(GLOBAL_MODALS.changeIntroModal("compliance"))
    },
    onMount: () => {
      dispatch(ACTION.requestList())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PolicyListContainer)
