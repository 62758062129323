import FloatingLabelInput from "@src/Components/FloatingLabelInput"
import { validateEmail } from "@src/Validators"
import * as React from "react"

import { Form, InputWrap } from "./styles"

interface IData {
  firstName: string
  lastName: string
  email: string
  noGapEnrollment?: boolean
}

interface IFormRow {
  data: IData
  allData: IData[]
  isColumn?: boolean
  onChange: (e: IData) => void
  isEdit?: boolean
}

const FormRow: React.FC<IFormRow> = ({ data, allData, isColumn, onChange }) => {
  const onDataChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    const value = e.target.value

    onChange({
      ...data,
      [field]: value,
    })
  }

  const validateTraineeEmail = (email: string) => {
    const emailAdded = allData.filter((item) => email && item.email === email)
    if (!email.length) {
      return "This field is required"
    }
    if (emailAdded.length > 1) {
      onChange({ ...data })
      return "Email already added"
    } else if (validateEmail(email.trim()) || email === "") {
      onChange({ ...data })
      return ""
    } else {
      onChange({ ...data })
      return "Please enter a valid email."
    }
  }

  return (
    <>
      <Form isColumn={!!isColumn}>
        <InputWrap>
          <FloatingLabelInput
            id="trainee-form-first-name"
            label="First Name"
            value={data.firstName}
            onChange={(e) => onDataChange(e, "firstName")}
            onValidate={(firstName) =>
              firstName.length ? "" : "This field is required"
            }
          />
        </InputWrap>
        <InputWrap>
          <FloatingLabelInput
            id="trainee-form-last-name"
            label="Last Name"
            value={data.lastName}
            onChange={(e) => onDataChange(e, "lastName")}
            onValidate={(lastName) =>
              lastName.length ? "" : "This field is required"
            }
          />
        </InputWrap>
        <InputWrap>
          <FloatingLabelInput
            id="trainee-form-email"
            label="Email"
            value={data.email}
            onChange={(e) => onDataChange(e, "email")}
            onValidate={(email) => validateTraineeEmail(email.trim())}
          />
        </InputWrap>
      </Form>
      {/*TODO return when usecure will fix auto enrollment to GAP*/}
      {/*{!isEdit && (*/}
      {/*  <Switch*/}
      {/*    name="noGapEnrollment"*/}
      {/*    checked={!data.noGapEnrollment}*/}
      {/*    style={{ margin: "0 0 5px 5px" }}*/}
      {/*    labelElement={*/}
      {/*      <span>*/}
      {/*        {data.noGapEnrollment ? <b>Don’t enroll</b> : "Enroll"} in*/}
      {/*        Cybersecurity Series*/}
      {/*      </span>*/}
      {/*    }*/}
      {/*    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>*/}
      {/*      onChange({*/}
      {/*        ...data,*/}
      {/*        noGapEnrollment: !e.target.checked*/}
      {/*      })*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
    </>
  )
}

export default FormRow
