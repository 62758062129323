import { colors, media } from "@src/theme"
import styled from "styled-components"

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 8px 0;
`

export const CourseLibraryContainer = styled.div`
  flex-grow: 1;
  .cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media (max-width: 1300px) {
      grid-template-columns: repeat(2, 1fr);
    }

    // @media ${media.medium} {
    //   gap: 14px;
    // }

    @media ${media.medium} {
      // display: flex;
      // flex-wrap: wrap;
      gap: 14px;
      grid-template-columns: 1fr;
    }
  }
`

export const HeadTitle = styled.h1`
  font-family: montserrat;
  font-size: 28px;
  line-height: 39px;
  color: ${colors.black50};
  margin: 0;
  margin-right: 10px;
  @media (max-width: 700px) {
    text-align: center;
  }
  @media (max-width: 500px) {
    font-size: 24px;
    line-height: initial;
  }
  @media (max-width: 330px) {
    font-size: 21px;
  }
`

export const HeadSubTitle = styled.span`
  // margin: 0 10px 0;
`

export const HeadWrap = styled.span`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`

export const HeadNavigationText = styled.p`
  font-size: 16px;
  color: ${colors.black50};
  margin: 0;

  i {
    font-size: 16px;
    margin-right: 5px;
  }

  span {
    color: ${colors.link};
    margin-left: 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 700px) {
    margin-top: 5px;
    text-align: center;
  }
`
