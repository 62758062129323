import { ButtonSmall } from "@src/Components/Button"
import { tState } from "@src/Model/Model"
import * as React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

export const PageLoadFailure = () => {
  const { emailDomain } = useSelector((state: tState) => state.brand)
  const contactText = `Please try again. If the problem persists please contact us at <a href="mailto:support@${emailDomain}" target="_blank">support@${emailDomain}</a>.`
  return (
    <ErrorMessage>
      <Message>
        <i className="far fa-frown" aria-hidden="true" />
        oops, something went wrong.
      </Message>
      <ContactUs dangerouslySetInnerHTML={{ __html: contactText }} />

      <ButtonSmall
        width={100}
        onClick={() => {
          window.location.replace("/")
        }}
      >
        Continue
      </ButtonSmall>
    </ErrorMessage>
  )
}

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const Message = styled.div`
  font-size: 22px;
  margin-bottom: 20px;
`

const ContactUs = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
`
