import { Store } from "redux"

import * as MARKETPLACE from "../Actions/MarketplaceActions"
import * as T from "../types"
import { serverLog } from "./Logging"
import * as Middletier from "./Middletier"

class Marketplace {
  private store: Store
  public window: T.tExtWindow & Window = window

  constructor(store: Store) {
    this.store = store
  }

  fetchMarketingJson() {
    try {
      this.store.dispatch(MARKETPLACE.setMarketplaceStatus("LOADING"))
      Middletier.fetchJson(`/marketplace/getJson`, (success, data) => {
        if (success && data.success) {
          this.store.dispatch(
            MARKETPLACE.setMarketplaceJson({
              status: "SUCCESS",
              data: data.data,
            }),
          )
        } else {
          this.store.dispatch(MARKETPLACE.setMarketplaceStatus("FAILED"))
        }
      })
    } catch (err) {
      this.store.dispatch(MARKETPLACE.setMarketplaceStatus("FAILED"))
      serverLog({
        level: "error",
        message: err.message,
        filename: __filename,
      })
    }
  }
}

export default Marketplace
