import { actions as GLOBAL_MODALS } from "@src/Actions/Reducers/GlobalModalsReducer"
import ExternalLink from "@src/Components/ExternalLink"
import PageHeaderCard from "@src/Components/PageHeaderCard"
import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import { ViewTypes } from "@src/types"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import styled from "styled-components"

const OverviewStyled = styled.span`
  display: flex;
  align-items: end;
  color: ${colors.gray50};
`

const ZegHeader: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { helpCenter } = useSelector((state: tState) => state.brand)
  return (
    <PageHeaderCard
      heading={<OverviewStyled>Attack Surface Analytics</OverviewStyled>}
      id="monitoring-heading"
      showViewIntro={true}
      onClickViewIntro={() =>
        dispatch(GLOBAL_MODALS.changeIntroModal("ratings"))
      }
      showHelpCenter={true}
      helpCenterLinkUrl={`https://${helpCenter}/hc/en-us/articles/360033542954`}
      buttonText="Upgrade"
      buttonType="secondary"
      showButton={false}
      onClickButton={() => history.push(ViewTypes.plan)}
      cardIconUrl="/images/Sr_color.svg"
      color="#C84400"
      cardBody={
        <ul>
          <li>
            Understand your security rating to manage your organization’s
            cybersecurity program.
          </li>
          <li>
            Gain the confidence to make faster, more strategic cyber risk
            decisions with the resources you have today.
          </li>
          <li>
            Measure, monitor, and investigate sovereign cybersecurity risks
            across industry sectors and key critical infrastructure companies.{" "}
            <ExternalLink
              url={"https://www.bitsight.com/security-ratings"}
              text="More"
              margin="0px 40px 0px 0px"
            />
          </li>
        </ul>
      }
    />
  )
}

export default ZegHeader
