import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import { colors } from "@src/theme"
import { TraineeData as ITrainee } from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as React from "react"
import { useHistory } from "react-router"

import CollapsiblePanel from "../CollapsiblePanel"
import { DateTime } from "../styles"
import TraineeCard from "../TraineeCard"
import {
  CollapseButton,
  HeadCardDetails,
  HeadContainer,
  HeadSubTitle,
  HeadTitleTrainee,
  IUserCircle,
  NameSubTitle,
  WarnText,
} from "./styles"

interface ITraineeTab {
  courseResultsList: ITrainee[]
  date: string
  currentTrainee: ITrainee
  courseKey: string
}

const TraineeTab = ({
  courseResultsList,
  date,
  currentTrainee,
  courseKey,
}: ITraineeTab) => {
  const history = useHistory()
  const [collapse, setCollapse] = React.useState({})
  const [collapseText, setCollapseText] = React.useState("Collapse all")
  const chevronIcon =
    collapseText === "Expand all" ? "fas fa-chevron-down" : "fas fa-chevron-up"

  const traineeCourseResults = courseResultsList?.filter(
    (res) => res.email === currentTrainee?.email,
  )
  const courseResults = traineeCourseResults[0].courseResults
  const isBeginnerCourseExists = !!courseResults.find((c) => c.difficulty === 2)
  const courseList = React.useMemo(() => {
    return courseResults.reduce((acc, curr) => {
      if (curr.difficulty === 1 && isBeginnerCourseExists) {
        return acc
      }
      return [...acc, curr]
    }, [])
  }, [currentTrainee.email])

  const collapseAll = () => {
    let collapseList = {}
    if (collapseText === "Collapse all") {
      Object.keys(collapse).forEach((key) => {
        collapseList = { ...collapseList, [key]: false }
      })
      setCollapseText("Expand all")
    }
    if (collapseText === "Expand all") {
      Object.keys(collapse).forEach((key) => {
        collapseList = { ...collapseList, [key]: true }
      })
      setCollapseText("Collapse all")
    }
    setCollapse(collapseList)
  }

  React.useEffect(() => {
    if (courseList) {
      let collapseList = {}
      courseList.forEach((course) => {
        const isGapOrBeginner =
          courseKey === "Knowledge Assessment" && course.name === "Beginner"
        collapseList = {
          ...collapseList,
          [course.name]: isGapOrBeginner || course.name === courseKey,
        }
      })
      setCollapse(collapseList)
    }
  }, [courseList])

  React.useEffect(() => {
    if (Object.values(collapse).every((val) => !val)) {
      setCollapseText("Expand all")
    }
    if (Object.values(collapse).every((val) => val)) {
      setCollapseText("Collapse all")
    }
  }, [collapse])

  return (
    <div>
      <HeadContainer>
        <Link
          bold
          color={colors.darkBlue}
          onClick={() => history.push("/training/reportcards")}
        >
          <FaIcon fontSize="13px" className="fas fa-chevron-left" />
          &nbsp;Back
        </Link>
        <DateTime>Updated {date}</DateTime>
      </HeadContainer>
      <HeadContainer>
        <HeadCardDetails>
          <IUserCircle className="fas fa-user-circle" />
          <HeadTitleTrainee>
            <NameSubTitle>
              {currentTrainee.firstName} {currentTrainee.lastName}
            </NameSubTitle>
            <HeadSubTitle>{`${currentTrainee.email}`}</HeadSubTitle>
          </HeadTitleTrainee>
        </HeadCardDetails>
        {!!courseList?.length && (
          <CollapseButton>
            <Link onClick={collapseAll} color={colors.darkBlue}>
              {collapseText}&nbsp;
              <FaIcon className={chevronIcon} fontSize="14px" />
            </Link>
          </CollapseButton>
        )}
      </HeadContainer>
      {currentTrainee &&
        (courseList?.length ? (
          courseList.map((course) => {
            return (
              <CollapsiblePanel
                key={course.topics[0].id}
                collapse={collapse}
                title={course.name}
                icon={course.imageUrl}
                setCollapse={setCollapse}
                courseKey={courseKey}
                course={course}
              >
                <TraineeCard
                  currentCourse={course}
                  trainee={currentTrainee}
                  collapse={collapse}
                  courseResults={courseResults}
                />
              </CollapsiblePanel>
            )
          })
        ) : (
          <WarnText>
            This team member is not enrolled in any course yet.
          </WarnText>
        ))}
    </div>
  )
}

export default React.memo(TraineeTab)
