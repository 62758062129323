import { actions as ZIMM } from "@src/Actions/Reducers/ZimmReducer"
import C from "@src/Controller"
import { getAssessmentDataWatcher } from "@src/Sagas"
import {
  IGetAssessmentData,
  IRemoveAssessmentData,
  ISaveAssessmentData,
} from "@src/Sagas/types"
import axios from "axios"
import { delay, put, takeLeading } from "redux-saga/effects"

function* getConfig(): any {
  try {
    yield put(ZIMM.getConfigRequest())
    const { data } = yield axios.get(`/api/zimm/assessmentConfig`)
    if (data.success) {
      yield put(ZIMM.getConfigSuccess(data))
    }
  } catch (e) {
    yield put(ZIMM.getConfigFailure(e))
  }
}

function* getAssessmentData(action: IGetAssessmentData): any {
  try {
    const useAdditionalLoading = action?.payload?.useAdditionalLoading
    yield put(ZIMM.getAssessmentDataRequest({ useAdditionalLoading }))
    const { data } = yield axios.get(`/api/zimm/assessment`)
    if (data.success) {
      yield put(ZIMM.getAssessmentDataSuccess(data))
    }
  } catch (e) {
    yield put(ZIMM.getAssessmentDataFailure(e))
  }
}

function* saveAssessmentData(action: ISaveAssessmentData): any {
  try {
    yield put(ZIMM.saveAssessmentDataRequest())
    yield delay(200)
    const { data } = yield axios.post(
      `/api/zimm/saveAssessment`,
      JSON.stringify({
        data: action.payload,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    if (data.success) {
      if (data.completedCategory) {
        C.toastNotification(
          `Congrats! You completed the Security Area: ${data.completedCategory}.`,
          "success",
        )
      }
      yield put(ZIMM.saveAssessmentDataSuccess())
      yield put(getAssessmentDataWatcher({ useAdditionalLoading: true }))
    } else {
      throw data.error
    }
  } catch (e) {
    C.toastNotification(
      "Oops! Something went wrong. Please try again.",
      "danger",
    )
    yield put(ZIMM.saveAssessmentDataFailure(e))
  }
}

function* removeAssessmentData(action: IRemoveAssessmentData): any {
  try {
    yield put(ZIMM.removeAssessmentDataRequest())
    yield delay(200)
    const { data } = yield axios.post(
      `/api/zimm/removeAssessment`,
      JSON.stringify({
        data: action.payload,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    if (data.success) {
      yield put(ZIMM.removeAssessmentDataSuccess())
      yield put(getAssessmentDataWatcher({ useAdditionalLoading: true }))
    } else {
      throw data.error
    }
  } catch (e) {
    C.toastNotification(
      "Oops! Something went wrong. Please try again.",
      "danger",
    )
    yield put(ZIMM.removeAssessmentDataFailure(e))
  }
}
export function* zimmWatcherSaga() {
  yield takeLeading("GET_CONFIG_WATCHER", getConfig)
  yield takeLeading("GET_ASSESSMENT_DATA_WATCHER", getAssessmentData)
  yield takeLeading("SAVE_ASSESSMENT_DATA_WATCHER", saveAssessmentData)
  yield takeLeading("REMOVE_ASSESSMENT_DATA_WATCHER", removeAssessmentData)
}
