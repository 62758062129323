import { colors } from "@src/theme"
import styled from "styled-components"

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  position: relative;

  @media (max-width: 600px) {
    padding-right: 0;
  }
`

export const HeadCardDetails = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 120px;
`

export const CollapseButton = styled.div`
  position: absolute;
  right: 0;
  top: 17px;

  @media (max-width: 600px) {
    position: initial;
    flex: 100%;
  }
`

export const HeadTitleTrainee = styled.h1`
  font-size: 22px;
  line-height: 22px;
  color: ${colors.darkBlue};
  letter-spacing: 0.5px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`

export const HeadSubTitle = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: ${colors.gray50};
`

export const NameSubTitle = styled.span`
  margin-right: 10px;
  font-size: 21px;
  color: ${colors.darkBlue};
`

export const IUserCircle = styled.i`
  font-size: 30px;
  margin: 0 10px 0 0;
  color: ${colors.darkBlue};
`

export const WarnText = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0;
`
