import { ButtonDashboard } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { colors } from "@src/theme"
import * as React from "react"

import {
  CTA,
  DashboardCardHeading,
  Description,
  FeatureCard,
  HeadingImg,
  SubHeading,
} from "./styles"
interface IMarketplaceCard {
  isActive: boolean
  gridColumn?: string
  setView: () => void
}

export const MarketplaceCard: React.FC<IMarketplaceCard> = ({
  isActive,
  gridColumn,
  setView,
}) => {
  const { isLargeScreen } = usePortalSize()

  return (
    <FeatureCard
      color={colors.warning}
      isEmpty
      gridColumn={gridColumn}
      minHeight={isLargeScreen ? "220px" : undefined}
    >
      <DashboardCardHeading marginBottom={isLargeScreen ? "15px" : undefined}>
        <HeadingImg src="images/icon_marketplace_dash.svg" marginBottom="6px" />{" "}
        MARKETPLACE
      </DashboardCardHeading>
      <SubHeading>Explore our Cyber Safety partner ecosystem.</SubHeading>
      <Description>
        Save up to 66% on tools and services from our trusted marketplace
        partners.
      </Description>
      <CTA>
        <ButtonDashboard
          width={isLargeScreen ? "300px" : "60%"}
          backgroundColor="#ffffff"
          onClick={setView}
        >
          {isActive ? "Connect and Secure" : "Subscribe to Use"}
        </ButtonDashboard>
      </CTA>
    </FeatureCard>
  )
}
