import C from "@src//Controller"
import { tBrandState, tState } from "@src//Model/Model"
import {
  IEligiblePlan,
  tChannelUiRules,
  tStatus,
  tSubscriptionStatus,
} from "@src//types"
import * as _ from "lodash"
import * as React from "react"
import { connect } from "react-redux"

import Subscription from "./Subscription"

interface ISubscriptionContainer {
  height: number
  width: number
  planName: string
  subscriptionStatus: tSubscriptionStatus
  expirationDate: string
  subscriptionDate: string
  eligiblePlans: IEligiblePlan[]
  uiRules?: tChannelUiRules
  eligiblePlansStatus: tStatus
  menuHidden: boolean
  updatePlansStatus: tStatus
  currPlanIndex: number
  onPlanUpdate: (planId: string) => void
  hasInsurance: boolean
  chargebeeSubscriptionStatus: boolean
  brand: tBrandState
}

interface ISubscriptionContainerState {}

class SubscriptionContainer extends React.Component<
  ISubscriptionContainer,
  ISubscriptionContainerState
> {
  public readonly state: ISubscriptionContainerState = {}

  componentDidMount() {
    C.Plans.getEligiblePlans()
  }

  render() {
    return (
      <Subscription
        planName={this.props.planName}
        subscriptionStatus={this.props.subscriptionStatus}
        expirationDate={this.props.expirationDate}
        subscriptionDate={this.props.subscriptionDate}
        onSubscribe={(
          chargebeePlanId: string,
          planId: string,
          couponCode?: string,
        ) => {
          C.Chargebee.handleSubscribe(chargebeePlanId, planId, couponCode)
        }}
        onPlanUpdate={this.props.onPlanUpdate}
        eligiblePlans={this.props.eligiblePlans}
        uiRules={this.props.uiRules}
        menuHidden={this.props.menuHidden}
        updatePlansStatus={this.props.updatePlansStatus}
        currPlanIndex={this.props.currPlanIndex}
        eligiblePlansStatus={this.props.eligiblePlansStatus}
        hasInsurance={this.props.hasInsurance}
        chargebeeSubscriptionStatus={this.props.chargebeeSubscriptionStatus}
        brand={this.props.brand}
      />
    )
  }
}

interface ISubscriptionContainerMap {
  planName: string
  subscriptionStatus: tSubscriptionStatus
  expirationDate: string
  subscriptionDate: string
  eligiblePlans: IEligiblePlan[]
  uiRules?: tChannelUiRules
  eligiblePlansStatus: tStatus
  updatePlansStatus: tStatus
  currPlanIndex: number
  hasInsurance: boolean
  onPlanUpdate: (planId: string) => void
  chargebeeSubscriptionStatus: boolean
  brand: tBrandState
}

const mapStateToProps = (store: tState): ISubscriptionContainerMap => {
  let currPlanIndex = -1
  const eligiblePlans = store.plans.channelObject.eligiblePlans
  const planName = store.plans.subscribedPlan.planName
  let hasInsurance = false
  eligiblePlans.map((eligiblePlan, index) => {
    if (
      _.lowerCase(planName) === _.lowerCase(eligiblePlan.planDetail?.planName)
    ) {
      currPlanIndex = index
    }
    if (eligiblePlan.planDetail?.insurance === "ACTIVE") {
      hasInsurance = true
    }
  })

  return {
    planName,
    subscriptionStatus: store.plans.subscriptionStatus,
    expirationDate: store.plans.expirationDate,
    subscriptionDate: store.plans.subscriptionDate,
    eligiblePlans,
    currPlanIndex,
    uiRules: store.plans.channelObject.uiRules,
    eligiblePlansStatus: store.plans.eligiblePlansStatus,
    updatePlansStatus: store.plans.updatePlansStatus,
    chargebeeSubscriptionStatus: store.plans.chargebeeSubscriptionStatus,
    hasInsurance,
    onPlanUpdate: (planId: string) => C.Plans.updatePlan(planId),
    brand: store.brand,
  }
}

export default connect(mapStateToProps)(SubscriptionContainer)
