import PageHeaderCard from "@src/Components/PageHeaderCard"
import { css, flex, margin, padding, width } from "@src/Components/StyledUtils"
import { Content, ContentArea, Panel } from "@src/Components/View"
import { colors } from "@src/theme"
import * as React from "react"
import styled from "styled-components"

const InsurancePage = () => {
  return (
    <ContentArea>
      <PageHeaderCard
        imageWidth="260px"
        contentWidth="100%"
        heading="Zeguro Cyber Safety is now part of HSB."
        id="insurance-heading"
        borderColor={colors.gray40}
        showHelpCenter={true}
        helpCenterLinkUrl="https://zeguro.zendesk.com/hc/en-us/sections/360005274933-Cyber-Insurance"
        cardIconUrl="images/insurance/Cyber_Safety_Plus_HSB.svg"
        cardBody={
          <div>
            At Zeguro, our mission has always been to equip organizations with
            the tools to withstand the digital unknown. It is with great
            excitement that we announce we are going on an exciting journey
            focused on cybersecurity for small and medium-sized businesses. As a
            result of this new direction, we will be unable to provide cyber
            insurance services.
          </div>
        }
      />
      <Panel borderColor={colors.gray40}>
        <Content padding="44px">
          <Section>
            <Title>What does this mean for you?</Title>
            <Info margin="24 0 0 28">
              <p>
                A notice of non-renewal will be mailed to you by The Hartford
                Steam Boiler (HSB).
              </p>
              <p>
                For questions about your cyber insurance policy or to submit a
                claim under the policy, you should contact HSB directly.
                <br />
                FHSB’s contact information is as follows:
              </p>
              <p>
                <b>Name:</b> The Hartford Steam Boiler Inspection and Insurance
                Company
                <br />
                <b>Mailing Address:</b> One State Street, Hartford, CT
                06102-5024
              </p>
            </Info>
          </Section>

          <Section padding="24 0 0 0">
            <Title>To report a claim</Title>
            <Info margin="24 0 0 28">
              <p>
                <b>Phone:</b>&nbsp;
                <MailPhoneLink>
                  <a
                    target="_blank"
                    href={"tel:1-888-HSB-LOSS"}
                    rel="noreferrer"
                  >
                    {"1-888-HSB-LOSS"}
                  </a>{" "}
                  (472-5677)
                </MailPhoneLink>
              </p>
              <p>
                <b>Fax:</b>{" "}
                <MailPhoneLink>
                  <a
                    target="_blank"
                    href={"tel:1-888-329-5677"}
                    rel="noreferrer"
                  >
                    {"1-888-329-5677"}
                  </a>
                </MailPhoneLink>
              </p>
              <p>
                <b>Email:</b>{" "}
                <MailPhoneLink>
                  <a
                    target="_blank"
                    href={"mailto:New_Loss@hsb.com"}
                    rel="noreferrer"
                  >
                    {"New_Loss@hsb.com"}
                  </a>
                </MailPhoneLink>
              </p>
            </Info>
          </Section>
          <Section padding="24 0 0 0">
            <Title>To ask a question about your policy</Title>
            <Info margin="21 0 0 28">
              <p>
                <b>Phone:</b>&nbsp;
                <MailPhoneLink>
                  <a
                    target="_blank"
                    href={"tel:1-800-472-1866"}
                    rel="noreferrer"
                  >
                    {"1-800-472-1866"}
                  </a>
                </MailPhoneLink>
              </p>
              <p>
                As long as your insurance policy is in force, the Cyber Safety
                solution will remain available to you. In the event your
                insurance policy expires, you will be able to opt back into your
                account. We are excited to bring you more cybersecurity features
                and solutions to keep you safe. We strive to provide security
                features and solutions that help you remain safe.
              </p>
            </Info>
          </Section>
        </Content>
      </Panel>
    </ContentArea>
  )
}

const Section = styled.div<any>`
  ${width}
  ${flex}
  ${css}
  ${margin}
  ${padding}
`

const Info = styled.div<any>`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #212528;
  ${margin}
  ${css}
`

const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.03em;
  color: #26249e;
`

export const MailPhoneLink = styled.span`
  font-family: Graphie;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0062d8;
`

export default InsurancePage
