import { colors } from "@src/theme"
import styled from "styled-components"

export const CustomNameHeadStyled = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  height: 54px;
  white-space: normal;
  text-align: center;

  &:hover {
    color: ${colors.black50};
  }

  i {
    color: ${colors.gray40};
    margin-left: 5px;

    &:hover {
      cursor: pointer;
    }
  }
`
