import styled from "styled-components"

const Shield = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background-color: black;
  opacity: 0.5;
  top: 0;
  left: 0;
`

export default Shield
