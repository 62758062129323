import { ButtonSmall } from "@src/Components/Button"
import { Heading } from "@src/Components/Common"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import ConfirmationModal from "@src/Components/ConfirmationModal"
import ExternalLink from "@src/Components/ExternalLink"
import { Link } from "@src/Components/Link"
import PageHeaderCard from "@src/Components/PageHeaderCard"
import Table from "@src/Components/SortedTable"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { Content, ContentArea, Panel } from "@src/Components/View"
import Waiting, { Failed } from "@src/Components/Waiting"
import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import { IRequestStatus, ViewTypes } from "@src/types"
import { PolicyListItem } from "@zeguro/schema-validator/dist/types/coco/securityPolicy/combined"
import * as moment from "moment"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { NewPolicyModal } from "../NewPolicyModal"
import {
  BadgesContainer,
  ConfirmContent,
  ConfirmText,
  DescriptionText,
  GroupDescription,
  NamePanel,
  PopoverLink,
  PopUpContent,
  StatusBadge,
  TableContainer,
  Title,
} from "./styles"

const description1 = `Security policies define rules and procedures for protecting your IT resources. Use them to help meet your legal, regulatory, and contractual obligations.`

const decriptionCustomPolicy = `If you need greater flexibility. Create security policies for areas not covered by our templates.`

export interface IPolicyList {
  policies: PolicyListItem[]
  request: IRequestStatus
  onHistory: (policy: PolicyListItem) => void
  onDownload: (policy: PolicyListItem) => void
  onIntro: () => void
}

const Badges = (p: PolicyListItem) => {
  const isActive = p.status === "Active"
  const isInactive = p.status === "Inactive"
  const isDraft = p.status === "Draft"
  const isArchived = p.status === "isArchived"
  const isOutOfBox = p.category === "custom"
  return (
    <BadgesContainer>
      {isInactive ? (
        <StatusBadge color="#fff" backgroundColor="#858585">
          Inactive
        </StatusBadge>
      ) : (
        <>
          <StatusBadge
            key={1}
            hidden={!isActive}
            color="#fff"
            backgroundColor="#1c7f30"
          >
            In Effect
          </StatusBadge>
          <StatusBadge key={3} hidden={!isDraft} backgroundColor="#5e0081">
            Draft
          </StatusBadge>
          <StatusBadge
            key={2}
            hidden={!p.custom || isOutOfBox}
            backgroundColor="#0062d8"
          >
            Custom
          </StatusBadge>
          <StatusBadge key={4} hidden={!isArchived} backgroundColor="#ff6100">
            Archived
          </StatusBadge>
        </>
      )}
    </BadgesContainer>
  )
}

const PolicyList = ({
  policies,
  request,
  onHistory,
  onDownload,
  onIntro,
}: IPolicyList) => {
  const history = useHistory()
  const { isDesktop } = usePortalSize()

  const [listLoading, setListLoading] = React.useState(true)

  const [newPolicyDialogOpened, setNewPolicyDialogOpened] =
    React.useState(false)
  const [confirmUpdateModalOpened, setConfirmUpdateModalOpened] =
    React.useState(false)
  const [updatedPolicy, setUpdatedPolicy] =
    React.useState<PolicyListItem | null>(null)
  const lastUpdatedPolicy = policies.reduce(
    (prev: PolicyListItem | null, cur) =>
      new Date(cur.updated || 0) > new Date(prev?.updated || 0) ? cur : prev,
    null,
  )
  const { helpCenter, emailDomain } = useSelector(
    (state: tState) => state.brand,
  )

  React.useEffect(() => {
    if (request.target === "list" && request.status !== "LOADING") {
      setListLoading(false)
    }
  }, [request.target, request.status])

  const onSelect = (policy: PolicyListItem) => {
    history.push(`${ViewTypes.compliance}/${policy.id}/${policy.version}`)
  }

  const getOriginPolicy = (originPolicy: PolicyListItem) =>
    policies.find(
      (policy) =>
        policy.id === originPolicy.id &&
        policy.status === "Active" &&
        policy.category === "custom",
    )

  const headerDef = [
    {
      name: "name",
      caption: "Policy ",
      width: "52%",
      headerStyle: {
        minWidth: "300px",
      },
      style: {
        color: "#0062d8",
        verticalAlign: "top",
        padding: "6px 16px",
      },
      render: (policy: any) => (
        <Info
          onSelect={() => onSelect(policy)}
          policy={policy}
          originPolicy={getOriginPolicy(policy)}
        />
      ),
    },
    {
      name: "status",
      caption: "Status",
      width: "16%",
      headerStyle: {
        textAlign: "center",
        minWidth: "94px",
      },
      style: {
        textAlign: "center",
        textIndent: 0,
      },
      render: (policy: any) => Badges(policy),
    },
    {
      name: "download",
      caption: "Download",
      width: "16%",
      headerStyle: {
        textAlign: "center",
        minWidth: "94px",
      },
      style: {
        borderLeft: "1px solid #ccc",
        textAlign: "center",
        textIndent: 0,
      },
      render: (policy: any) => {
        const isActive = policy.status != "Inactive"
        const onDownloadClick = () => isActive && onDownload(policy)
        return (
          <>
            <Tooltip
              id={`${policy.name}-download-tooltip`}
              content={<PopUpContent>Download PDF</PopUpContent>}
            />
            <PopoverLink data-tip data-for={`${policy.name}-download-tooltip`}>
              <i
                className="fas fa-file-pdf"
                id="security-policy-download"
                data-policy-name={`${policy.name}`}
                onClick={onDownloadClick}
                style={{
                  color: isActive ? "#0062d8" : "#cccc",
                  cursor: isActive ? "pointer" : "not-allowed",
                  fontSize: 16,
                  textIndent: 0,
                }}
              />
            </PopoverLink>
          </>
        )
      },
    },
    {
      name: "history",
      caption: "History",
      width: "16%",
      style: {
        borderLeft: "1px solid #ccc",
        textAlign: "center",
        textIndent: 0,
      },
      headerStyle: {
        textAlign: "center",
        minWidth: "94px",
      },
      render: (policy: any) => {
        const isActive = policy.status != "Inactive" && policy.status != "Draft"
        const onHistoryClick = () => isActive && onHistory(policy)
        return (
          <>
            <Tooltip
              id={`${policy.name}-history-tooltip`}
              content={<PopUpContent>View policy version history</PopUpContent>}
            />
            <PopoverLink data-tip data-for={`${policy.name}-history-tooltip`}>
              <i
                className="fas fa-table"
                id="security-policy-history"
                data-policy-name={`${policy.name}`}
                onClick={onHistoryClick}
                style={{
                  color: isActive ? "#0062d8" : "#cccc",
                  cursor: isActive ? "pointer" : "not-allowed",
                  fontSize: 16,
                  textIndent: 0,
                }}
              />
            </PopoverLink>
          </>
        )
      },
    },
  ]

  const policyByCategory = (category: string) => {
    return policies.filter((e) => e.category === category)
  }

  const customPolicies = policyByCategory("custom")
  const onClosenewPolicyDialog = () => {
    setNewPolicyDialogOpened(false)
  }

  if (listLoading) {
    return <Waiting text="Loading your Security Policies." />
  }

  const requestFailed = request.status === "FAILED"
  if (requestFailed) {
    return <Failed text="Request Failed." />
  }

  const onNewCustomPolicy = (policyName: string) => {
    const policy = policies.find((policy) => policy.id === policyName)
    if (policy) {
      setUpdatedPolicy(policy)
      setConfirmUpdateModalOpened(true)
    } else {
      history.push(`/securitypolicy/${encodeURIComponent(policyName)}/0`)
    }
  }

  const onUpdatePolicy = () => {
    if (updatedPolicy) {
      onSelect(updatedPolicy)
    }
  }

  const onCancelConfirmUpdate = () => {
    setConfirmUpdateModalOpened(false)
    setNewPolicyDialogOpened(true)
  }

  const PolicyGroup = (props: any) => (
    <Panel
      borderColor={colors.blue5}
      hidden={listLoading}
      flexGrow={0}
      margin={`0px 0px ${isDesktop ? 32 : 0}px 0px`}
    >
      <Content padding="24px 44px 44px 44px">
        <Title>
          <Heading
            margin="0px 0px 13px 0px"
            fontSize="24px"
            fontSizeMobile="18px"
          >
            {props.title}
            <GroupDescription>{props.subtitle}</GroupDescription>
          </Heading>
        </Title>
        <TableContainer>
          <Table
            headers={headerDef}
            data={policyByCategory(props.category)}
            withoutHover
          />
        </TableContainer>
      </Content>
    </Panel>
  )

  return (
    <ContentArea>
      <NewPolicyModal
        isOpen={newPolicyDialogOpened}
        onCreate={onNewCustomPolicy}
        onClose={onClosenewPolicyDialog}
      />
      <ConfirmationModal
        infoOnly
        isOpen={confirmUpdateModalOpened}
        title="Policy name already exists"
        confirmButton="Update"
        isBackButton
        onBack={onCancelConfirmUpdate}
        onClose={onCancelConfirmUpdate}
        onConfirm={onUpdatePolicy}
      >
        <ConfirmContent>
          <ConfirmText>
            This name is already being used. Do you want to update existing
            policy?
          </ConfirmText>
        </ConfirmContent>
      </ConfirmationModal>
      <PageHeaderCard
        heading="Security Policies"
        infoText={
          (lastUpdatedPolicy &&
            `Last updated ${moment(lastUpdatedPolicy.updated).format(
              "MMM D, YYYY",
            )}`) ||
          undefined
        }
        id="security-policy-heading"
        showViewIntro={true}
        onClickViewIntro={onIntro}
        showHelpCenter={true}
        helpCenterLinkUrl={`https://${helpCenter}/hc/en-us/articles/360034474673-Security-Policies-Module-Overview`}
        cardIconUrl="images/icon_sec_pols_dash.svg"
        borderColor={colors.blue5}
        cardBody={
          <div>
            {description1}&nbsp;
            <ExternalLink
              text="More"
              margin="0px 40px 0px 0px"
              url={`https://${helpCenter}/hc/en-us/articles/360034474673-Security-Policies-Module-Overview`}
            />
            <br />
          </div>
        }
      />
      <PolicyGroup category="core" title="Recommended Policies" />
      <PolicyGroup category="business" title="Business-Specific Policies" />
      <PolicyGroup category="nice-to-have" title="Specialized Policies" />
      <Panel hidden={listLoading} flexGrow={0} borderColor={colors.blue5}>
        <Content padding="24px 44px 44px 44px">
          <Title>
            <Heading fontSize="24px" fontSizeMobile="18px">
              Custom Policies
            </Heading>
          </Title>
          <DescriptionText>
            <Section margin="10 0 16 0">
              {decriptionCustomPolicy}&nbsp;
              <ExternalLink
                margin="0px"
                text="More"
                url={`https://${helpCenter}/hc/en-us/articles/360039397974`}
              />
            </Section>
          </DescriptionText>
          <Section>
            <ButtonSmall
              width={200}
              onClick={() => {
                setNewPolicyDialogOpened(true)
              }}
            >
              <i className="fas fa-file-plus" />
              &nbsp;&nbsp; New Security Policy
            </ButtonSmall>
          </Section>
          <TableContainer>
            <Table
              hidden={customPolicies.length === 0}
              headers={headerDef}
              data={customPolicies}
              withoutHover
            />
          </TableContainer>
          <Section align="start" margin="44 0 0 0">
            Having trouble? Contact us at{" "}
            <Link href={`mailto:support@${emailDomain}`} isUnderline>
              support@{emailDomain}
            </Link>
            .
          </Section>
        </Content>
      </Panel>
    </ContentArea>
  )
}

interface IInfo {
  policy: PolicyListItem
  originPolicy?: PolicyListItem
  onSelect: (policy: PolicyListItem) => void
}

const Info = (props: IInfo) => {
  const policy = props.policy
  const originPolicy = props.originPolicy
  const version = policy.version
  const versionStr = version > 0 ? "v" + version : ""
  const isInfo = policy.info && policy.info.length > 0
  const policyName =
    originPolicy &&
    policy.custom &&
    policy.status === "Draft" &&
    policy.name !== originPolicy.name
      ? `${policy.name} (Was ${originPolicy.name})`
      : policy.name

  const onClick = () => props.onSelect(policy)
  return (
    <NamePanel>
      <Link fontSize="16px" onClick={onClick}>
        {policyName} {versionStr}
      </Link>
      <Tooltip
        id={`${policy.name}-${versionStr}-info-tooltip`}
        content={<PopUpContent>{policy.info}</PopUpContent>}
      />
      <PopoverLink
        data-tip
        data-for={`${policy.name}-${versionStr}-info-tooltip`}
        onClick={(e) => e.stopPropagation()}
      >
        <i
          className="fas fa-info-circle"
          style={{
            display: !isInfo ? "none" : undefined,
            textIndent: 0,
            color: "#adadad",
            marginRight: "1px",
          }}
        />
      </PopoverLink>
    </NamePanel>
  )
}

export default PolicyList
