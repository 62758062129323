import { IProbelyTarget } from "@src/types"
import { validateUrl } from "@src/Validators"
import * as moment from "moment"
import * as Yup from "yup"

type addWebAppValidation = Yup.AnyObjectSchema
interface IValidationData {
  targetMap?: IProbelyTarget[]
}

export const useAddWebAppValidation = (
  validationData: IValidationData,
): addWebAppValidation => {
  return Yup.object({
    webAppName: Yup.string()
      .required("Please enter a name.")
      .default("")
      .max(255, "Max 255 symbols.")
      .test(
        "urlAlreadyExists",
        "This name already exists, choose another.",
        (value) =>
          !validationData.targetMap?.find(
            (target) => target.name.toLowerCase() === value.toLowerCase(),
          ),
      ),
    webAppUrl: Yup.string()
      .test("validUrl", "Please enter a valid URL.", (value) =>
        validateUrl(value || ""),
      )
      .default(""),
    scanLevel: Yup.string().required().default(""),
    scheduleDate: Yup.string()
      .required("Please enter a date.")
      .default("")
      .test("dateIsValid", "Date is not valid.", (value) => {
        return !moment(value)
          .startOf("day")
          .isBefore(moment(new Date()).startOf("day"))
      }),
    dayOfMonth: Yup.number()
      .min(1, "Not a valid day of the month.")
      .max(31, "Not a valid day of the month."),
    protocol: Yup.string().required("Please select a protocol.").default(""),
  })
}
