import { Breadcrumbs } from "@blueprintjs/core"
import { Section } from "@src/Components/StyledUtils"
import * as ReactQuill from "react-quill"
import styled from "styled-components"

export const NoteSection = styled(Section)`
  background-color: #ffeced;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
  @media (min-width: 1100px) {
    margin-top: 28px;
  }
`
export const StyledBreadcrumbs = styled(Breadcrumbs)`
  font-family: proxima-nova, sans-serif;
`
export const ReactQuillStyled = styled(ReactQuill)`
  margin-top: 20px;
  .ql-editor {
    padding: 0;
  }
`
export const HeaderSection = styled(Section)``

export const Img = styled.img<{ margin?: string }>`
  margin ${({ margin }) => margin};
`
export const AlertInfoTable = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  > div:last-child {
    text-align: right;
  }
`
export const DocumentHeading = styled.div`
  display: flex;
  > div:last-child {
    margin: 22px 0px 10px;
  }
`
