import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { basicInitialState, tBrandState } from "@src/Model/Model"

export const initialBrandState: tBrandState = {
  ...basicInitialState,
  brandId: "",
  brandLogo:
    "https://images.zeguro.com/Brand+Logos/logo_cyber_safety_brand_agnostic_hor.svg",
  brandNameLong: "Cyber Safety",
  brandNameShort: "Cyber Safety",
  webDomain: "",
  conciergeEmail: "",
  conciergeSchedulingLink: "",
  helpCenter: "",
  emailDomain: "cybersafety-mail.com",
  marketingCollateral: "",
}

const brandSlice = createSlice({
  name: "brand",
  initialState: initialBrandState,
  reducers: {
    getBrandRequest(state) {
      state.isLoading = true
    },
    getBrandSuccess(state, { payload }: PayloadAction<Partial<tBrandState>>) {
      return { ...initialBrandState, ...payload, isLoading: false }
    },
    getBrandFailure(state, { payload }: PayloadAction<Error>) {
      return { ...initialBrandState, error: payload, isLoading: false }
    },
  },
})

export const { name, actions, reducer } = brandSlice
