import { Popover, PopoverInteractionKind, Position } from "@blueprintjs/core"
import { actions as MONITORING } from "@src/Actions/Reducers/MonitoringReducer"
import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Dropdown } from "@src/Components/Dropdown"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import * as T from "@src/types"
import { ViewTypes } from "@src/types"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import ScanTables from "../ScanTables"
import {
  DisabledButton,
  HorizontalRule,
  Padding,
  PageHolder,
  SelectApp,
  SelectAppWrapper,
  SelectHeader,
  TabHeading,
  TabPopover,
} from "./styles"

interface IScansPage {
  targetMap: { [key: string]: T.IProbelyTarget }
  scanMap: { [key: string]: T.IProbelyScan[] }
  scheduleMap: { [key: string]: T.IProbelySchedule[] }
  numberOfTargets: T.tPlanCount
  activeTargetId: string
  activeTargetName: string
  activeScanLevel: string
  scansLoaded: boolean
  isHighestPlan?: boolean
  getScanReport: (
    targetName: string,
    targetId: string,
    scanId: string,
    reportType: string,
  ) => void
  setActiveTargetAndSchedule: (
    name?: string | undefined,
    id?: string | undefined,
    scheduledScanId?: string | undefined,
    recurrence?: string | undefined,
    activeScanLevel?: string | undefined,
    scheduledDayOfWeek?: number | undefined,
    weekIndex?: string | undefined,
    runOnDayOfWeek?: boolean | undefined,
  ) => void
  showSubscriptionPage: () => void
}

const ScansPage = ({
  targetMap,
  scanMap,
  scheduleMap,
  numberOfTargets,
  activeTargetId,
  activeTargetName,
  activeScanLevel,
  scansLoaded,
  isHighestPlan,
  getScanReport,
  setActiveTargetAndSchedule,
  showSubscriptionPage,
}: IScansPage) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { emailDomain } = useSelector((state: tState) => state.brand)
  const { clientWidth } = usePortalSize()
  const disableAddTarget = (numberOfTargets?.remaining || 0) <= 0
  const allowedTagets = numberOfTargets?.maximumAllowed || 0
  const targetsRemaining = numberOfTargets?.remaining || 0

  const getTargetListFromMap = (targetMap: {
    [key: string]: T.IProbelyTarget
  }) => {
    return Object.keys(targetMap).map((targetId: string) => [
      targetMap[targetId].name,
      targetId,
    ])
  }

  const [targetList, setTargetList] = React.useState<string[][]>(
    getTargetListFromMap(targetMap),
  )

  const onSelectWebApp = (item: string[]) => {
    const schedule = scheduleMap?.[item[1]]?.[0]
    const scheduledScanId = schedule?.scheduledScanId || undefined
    const recurrence = schedule?.recurrence || undefined
    setActiveTargetAndSchedule(
      item[0],
      item[1],
      scheduledScanId,
      recurrence,
      activeScanLevel,
    )
    history.replace(`${ViewTypes.monitoring}/${encodeURIComponent(item[0])}`)
  }

  React.useEffect(() => {
    const lastScan = Object.values(targetMap).shift()
    const lastScanKey = Object.keys(targetMap).shift()
    if (lastScanKey && lastScan) {
      const schedule = scheduleMap?.[lastScanKey]?.[0]
      const scheduledScanId = schedule?.scheduledScanId || undefined
      const recurrence = schedule?.recurrence || undefined
      const scheduledDayOfWeek = schedule?.scheduledDayOfWeek || undefined
      const weekIndex = schedule?.weekIndex || undefined
      const runOnDayOfWeek = schedule?.runOnDayOfWeek || undefined

      setTargetList(getTargetListFromMap(targetMap))

      setActiveTargetAndSchedule(
        lastScan.name,
        lastScan.targetId,
        scheduledScanId,
        recurrence,
        activeScanLevel,
        scheduledDayOfWeek,
        weekIndex,
        runOnDayOfWeek,
      )
    }

    if (lastScan?.name) {
      history.replace(
        `${T.ViewTypes.monitoring}/${encodeURIComponent(lastScan?.name)}`,
      )
    }
  }, [JSON.stringify(targetMap)])

  return (
    <PageHolder>
      <SelectHeader>
        <TabHeading>Select Website</TabHeading>
        <SelectAppWrapper>
          <SelectApp width={`${clientWidth < 500 ? clientWidth - 106 : 400}px`}>
            <Dropdown
              id="select-web-app"
              items={targetList || []}
              placeholder="Select Website"
              selectedItem={[activeTargetName, activeTargetId]}
              onSelectItem={(item: string[]) => onSelectWebApp(item)}
              height="38px"
              disabled={targetList?.length < 2}
              width={`${clientWidth < 500 ? clientWidth - 106 : 400}px`}
            />
          </SelectApp>
          <Padding />
          {disableAddTarget ? (
            <Popover
              popoverClassName={isHighestPlan ? "bp5-dark" : ""}
              content={
                <TabPopover color={!isHighestPlan ? "black" : ""}>
                  <p>
                    You&apos;ve reached the maximum of {allowedTagets} website
                    {allowedTagets == 1 ? "" : "s"}.<br />
                    {isHighestPlan ? (
                      <>
                        Need to scan more websites? <br />
                        <>
                          Contact us at&nbsp;
                          <Link
                            href={`mailto:support@{emailDomain}`}
                            color={!isHighestPlan ? colors.white : ""}
                          >
                            support@{emailDomain}
                          </Link>
                          .
                        </>
                        <br />
                      </>
                    ) : (
                      <ButtonSmall
                        margin="20 0 0 0"
                        width={187}
                        onClick={showSubscriptionPage}
                      >
                        Upgrade Your Plan
                      </ButtonSmall>
                    )}
                  </p>
                </TabPopover>
              }
              interactionKind={PopoverInteractionKind.HOVER}
              position={Position.TOP}
            >
              <DisabledButton>
                <ButtonSecondary width={210} id="add-another-web-app">
                  <FaIcon className="fas fa-plus-square" />
                  &nbsp;Add Another Website
                </ButtonSecondary>
              </DisabledButton>
            </Popover>
          ) : (
            <Popover
              popoverClassName="bp5-dark"
              content={
                <TabPopover>
                  {targetsRemaining} website
                  {targetsRemaining == 1 ? "" : "s"} remaining
                </TabPopover>
              }
              interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
              position={Position.TOP}
            >
              <ButtonSecondary
                onClick={() =>
                  dispatch(MONITORING.toggleModal("isAddAppModal"))
                }
                id="add-another-web-app"
                width={210}
              >
                <FaIcon className="fas fa-plus-square" />
                &nbsp;Add Another Website
              </ButtonSecondary>
            </Popover>
          )}
        </SelectAppWrapper>
      </SelectHeader>
      <HorizontalRule />

      <ScanTables
        target={targetMap[activeTargetId]}
        key={activeTargetId}
        scanMap={scanMap}
        scheduleMap={scheduleMap}
        getScanReport={getScanReport}
        scansLoaded={scansLoaded}
      />
    </PageHolder>
  )
}

export default ScansPage
