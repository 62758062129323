import DialogComp from "@src/Components/Dialog"
import { colors, media } from "@src/theme"
import styled from "styled-components"

export const Dialog = styled(DialogComp)`
  width: 90%;
  max-width: 500px;

  &.zeg-danger {
    max-width: 500px;
  }
`

export const Section = styled.div`
  margin: 20px;
`

export const ButtonWrap = styled.div`
  margin: 15px 5px;
`

export const WarningText = styled.div`
  color: ${colors.gray40};
  margin: 5px;
`

export const FormWrap = styled.div`
  max-height: 500px;
  overflow: auto;

  @media ${media.medium} {
    max-height: 300px;
  }
`
