import { colors } from "@src/theme"
import styled from "styled-components"

interface ISelectContainer {
  margin?: string
  maxWidth?: string
}

export const SelectContainer = styled.div<ISelectContainer>`
  position: relative;
  margin: ${({ margin }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
  .react-select-container {
    .react-select__control {
      border-color: ${colors.gray30};
      box-shadow: none;
      .react-select__value-container {
        padding: 8px 0 6px 16px;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .react-select__indicators {
      .react-select__indicator {
        padding: 11px 11px 11px 3px;
        svg {
          fill: ${colors.black};
        }
      }
      .react-select__indicator-separator {
        background-color: transparent;
      }
    }
    .react-select__menu {
      z-index: 2;
    }
  }
`

export const Label = styled.div`
  position: absolute;
  transform: translateY(-50%);
  font-size: 13px;
  color: ${colors.darkBlue};
  background-color: ${colors.white};
  padding: 0 12px;
  top: 0px;
  left: 10px;
`

export const ErrorMeaage = styled.div`
  color: ${colors.red};
  margin-top: 2px;
`
