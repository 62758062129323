import { Radio } from "@blueprintjs/core"
import { colors } from "@src/theme"
import styled from "styled-components"

export const StyledRadio = styled(Radio)`
  margin-bottom: 0;
`

export const InfoPanel = styled.div`
  padding: 10px 28px;
  color: ${colors.black80};
  background: rgba(0, 98, 216, 0.05);
  border: 1px solid rgba(0, 98, 216, 0.5);
  border-radius: 6px;
`
export const RepeatScanText = styled.span`
  display: flex;
  align-items: center;
`
