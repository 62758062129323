import Waiting from "@src/Components/Waiting"
import ZimmContainer from "@src/Portal/Zimm"
import * as React from "react"
import { Suspense } from "react"
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"
const Assessment = React.lazy(() => import("@src/Portal/Zimm/Assessment"))
const Report = React.lazy(() => import("@src/Portal/Zimm/Report"))

export const ZimmRouter = ({ match }: RouteComponentProps) => (
  <ZimmContainer>
    <Suspense fallback={<Waiting text="" />}>
      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.path}/assessment`} />
        </Route>
        <Route
          exact
          path={[`${match.path}/assessment`, `${match.path}/assessment/:id`]}
          component={Assessment}
        />
        <Route exact path={`${match.path}/report`} component={Report} />
        <Redirect exact to="/404" />
      </Switch>
    </Suspense>
  </ZimmContainer>
)
