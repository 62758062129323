import { ButtonDashboard } from "@src/Components/Button"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import { FilledStateCard } from "@src/Portal/Dashboard/MonitoringCard/FilledState"
import * as React from "react"
import { useSelector } from "react-redux"

import {
  CTA,
  DashboardCardHeading,
  FaIconHead,
  FeatureCard,
  HeadingImg,
} from "../styles"
import { EmptyStateCard } from "./EmptyState"

interface IMonitoringCard {
  isActive: boolean
  gridColumn?: string
  setView: () => void
}

export const MonitoringCard: React.FC<IMonitoringCard> = ({
  isActive,
  gridColumn,
  setView,
}) => {
  const monitoringData = useSelector(
    (state: tState) => state.portal.dashboard.monitoring,
  )

  const emptyState =
    monitoringData &&
    monitoringData.scansScheduled < 1 &&
    monitoringData.webAppsScanned < 1
  return (
    <FeatureCard color="#FF7800" isEmpty={emptyState} gridColumn={gridColumn}>
      <DashboardCardHeading>
        <HeadingImg src="images/icon_monitoring_dash.svg" /> MONITORING
        {!emptyState && (
          <FaIconHead
            className="fas fa-info-circle"
            data-tip
            data-for="card-title-monitoring-tooltip"
          />
        )}
      </DashboardCardHeading>
      {emptyState ? (
        <>
          <EmptyStateCard />
          <CTA>
            <ButtonDashboard
              width="60%"
              backgroundColor="#ffffff"
              onClick={setView}
            >
              {isActive ? "Schedule Scans" : "Subscribe to Use"}
            </ButtonDashboard>
          </CTA>
        </>
      ) : (
        <>
          {monitoringData && <FilledStateCard data={monitoringData} />}
          <CTA>
            <ButtonDashboard
              width="60%"
              backgroundColor="#ffffff"
              onClick={setView}
            >
              {isActive ? "View Scan Findings" : "Subscribe to Use"}
            </ButtonDashboard>
          </CTA>
        </>
      )}
      <Tooltip
        id="card-title-monitoring-tooltip"
        content={
          <>
            <h3>Find and fix security vulnerabilities.</h3>
            <p>
              Scan your websites to identify threats. Proactively address issues
              that can be exploited by cyber criminals.
            </p>
          </>
        }
      />
    </FeatureCard>
  )
}
