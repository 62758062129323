import { ButtonSmall } from "@src/Components/Button"
import { Link } from "@src/Components/Link"
import C from "@src/Controller"
import { ViewTypes } from "@src/types"
import { validateEmail } from "@src/Validators"
import * as pluralize from "pluralize"
import * as React from "react"
import { useHistory } from "react-router"

import FormBulk from "../FormBulk"
import FormRow from "../FormRow"
import {
  ButtonRow,
  Container,
  FormWrap,
  Icon,
  InfoText,
  InfoTextGray,
  InfoTextWithBg,
  Section,
  UpgradeSection,
} from "./styles"

interface IData {
  firstName: string
  lastName: string
  email: string
  noGapEnrollment: boolean
  withError?: boolean
}

interface IDataReady {
  data: IData[]
  ready: boolean
}

interface IFormAdd {
  numberOfSeats: Record<string, number>
  data: IData[]
  setData: (data: IData[]) => void
}

const initData = {
  firstName: "",
  lastName: "",
  email: "",
  noGapEnrollment: false,
  withError: false,
}

const FormAdd: React.FC<IFormAdd> = ({ numberOfSeats, data, setData }) => {
  const [dataReady, setDataReady] = React.useState<IDataReady>({
    ready: false,
    data: [],
  })
  const [isBulk, setIsBulk] = React.useState(false)

  const remaining = numberOfSeats.remaining - dataReady.data.length
  const seatsRemaining = numberOfSeats.remaining - data.length
  const history = useHistory()

  const goToPlans = () => {
    history.push(ViewTypes.plan)
  }

  const toggleBulk = () => {
    setIsBulk(!isBulk)
  }
  const onChange = (value: IData, index: number) => {
    const newData = [...data]
    newData[index] = value

    setData(newData)
  }

  const onAddAnother = () => {
    setData([...data, initData])
  }

  const onSave = () => {
    const traineesData = dataReady.data.map((data) => ({
      ...data,
      email: data.email.trim(),
    }))
    setData(traineesData)
    C.Training.saveTrainees(traineesData)
    setData([initData])
  }

  React.useEffect(() => {
    const newData: IData[] = []
    let ready = true

    data.forEach((item) => {
      const { firstName, lastName, email, noGapEnrollment, withError } = item
      const existingEmail = newData.find((newItem) => newItem.email === email)
      if (withError) {
        return (ready = false)
      }

      if (!firstName && !lastName && !email) {
        return
      } else if (
        firstName &&
        lastName &&
        email &&
        validateEmail(email.trim()) &&
        !existingEmail
      ) {
        return newData.push({ firstName, lastName, email, noGapEnrollment })
      }
      ready = false
    })
    setDataReady({
      data: newData,
      ready: ready && !!newData.length,
    })
  }, [data])

  React.useEffect(() => {
    setData([initData])
  }, [])

  if (numberOfSeats.remaining <= 0) {
    return (
      <UpgradeSection>
        <Section>
          <img
            height="140px"
            src="/images/training/graphic_gauge_no_seats_left.svg"
            alt="upgrade section head"
          />
        </Section>
        <InfoText>
          You’ve reached the maximum number of team members you can add with
          your plan.
        </InfoText>
        <Section>
          <ButtonSmall borderRadius={6} onClick={goToPlans}>
            Upgrade Your Plan
          </ButtonSmall>
        </Section>
      </UpgradeSection>
    )
  }

  return (
    <Container>
      {!isBulk ? (
        <Section className="bp5-dialog-body">
          <InfoText>
            Team members will receive emails with links to online courses, and
            reminders to complete overdue assignments.
          </InfoText>
          <InfoTextWithBg>
            <i className="fas fa-info-circle" />
            You can enroll team members in specialized training once you have
            added them.
          </InfoTextWithBg>
          <FormWrap>
            {data.map((dataRow, index) => (
              <FormRow
                key={index}
                data={dataRow}
                allData={data}
                onChange={(value: IData) => onChange(value, index)}
              />
            ))}
          </FormWrap>
          <ButtonRow disabled={seatsRemaining <= 0}>
            <Link bold padding="10px 0" onClick={onAddAnother}>
              <Icon className="fas fa-plus" />
              Add Another Team Member
            </Link>
            <Link bold padding="10px 0" onClick={toggleBulk}>
              <Icon className="fas fa-users" />
              Invite Many People
            </Link>
          </ButtonRow>
          <ButtonSmall
            borderRadius={6}
            disabled={!dataReady.ready}
            onClick={onSave}
          >
            {`Add ${dataReady.data.length || ""} Team ${pluralize(
              "Member",
              dataReady.data.length || 1,
            )}`}
          </ButtonSmall>
          <InfoTextGray>
            {`${remaining} of ${numberOfSeats.maximumAllowed} seats remaining`}
          </InfoTextGray>
        </Section>
      ) : (
        <FormBulk seatsRemaining={seatsRemaining} toggleBulk={toggleBulk} />
      )}
    </Container>
  )
}

export default FormAdd
