import * as Action from "@src/Actions/TrainingActions"
import C from "@src/Controller"
import {
  ISaveTraineeData,
  ITraineeEditCourses,
  ITrainingEditData,
} from "@src/types"
import { Store } from "redux"

import { serverLog } from "./Logging"
import * as Middletier from "./Middletier"

class Training {
  private store: Store

  constructor(store: Store) {
    this.store = store
  }

  saveTrainees(trainees: ISaveTraineeData[]) {
    this.store.dispatch(Action.setTrainingStatus("LOADING"))
    this.store.dispatch(Action.setTrainingTarget("saveTrainees"))
    Middletier.xhrPost(
      "/training/saveTrainees",
      JSON.stringify(trainees),
      (response: any) => {
        C.Plans.getFeatureUsageProfile()

        const errors = response.data?.errors
        const success = response.data?.success
        if (errors?.length) {
          for (const error of errors) {
            C.toastNotification(error, "danger")
          }
        }
        if (success) {
          C.toastNotification(success, "success", 10000)
        }
        if (response?.error) {
          C.toastNotification(response?.error, "danger")
        }
        this.loadTrainingData()
      },
    )
  }

  saveTraineesBulk = async (file: any) => {
    const data = new FormData()
    data.append("file", file)
    // TODO : Cannot use MiddleTier.xhrPost here. Consolidate and improvise MiddleTier.xhrPost to remove callbacks
    // 1. Possible timeout for large upload
    // 2. Bulk upload expects a data back to be shown on UI which are actionable which xhrPost currently does not support
    // 3. Middletier uses old callback style

    const csrfToken = await Middletier.getCSRFToken()
    return fetch("api/training/saveTraineesBulk", {
      method: "POST",
      body: data, // This is your file object
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) {
          C.toastNotification(res.message || "Cannot upload file.", "danger")
        }
        return res
      })
      .catch((error) => {
        C.toastNotification(error || "Cannot upload file.", "danger")
        return { success: false }
      })
  }

  editTrainees(trainees: ITrainingEditData[]) {
    this.store.dispatch(Action.setTrainingStatus("LOADING"))
    this.store.dispatch(Action.setTrainingTarget("editTrainees"))
    Middletier.xhrPost(
      "/training/editTrainee",
      JSON.stringify({ trainees }),
      (response: any) => {
        C.Plans.getFeatureUsageProfile()

        const errors = response.data?.errors
        const success = response.data?.success
        if (errors?.length) {
          for (const error of errors) {
            C.toastNotification(error, "danger")
          }
        }
        if (success) {
          C.toastNotification(success, "success", 10000)
        }
        if (response?.error) {
          C.toastNotification(response?.error, "danger")
        }
        this.loadTrainingData()
      },
    )
  }
  editTraineeCourses(trainees: ITraineeEditCourses[]) {
    this.store.dispatch(Action.setTrainingStatus("LOADING"))
    this.store.dispatch(Action.setTrainingTarget("editTrainees"))
    Middletier.xhrPost(
      "/training/editTraineesCourses",
      JSON.stringify(trainees),
      (response: any) => {
        C.Plans.getFeatureUsageProfile()

        const errors = response.data?.errors
        const success = response.data?.success
        if (errors?.length) {
          for (const error of errors) {
            C.toastNotification(error, "danger")
          }
        }
        if (success) {
          C.toastNotification(success, "success", 10000)
        }
        if (response?.error) {
          C.toastNotification(response?.error, "danger")
        }
        this.loadTrainingData()
      },
    )
  }

  deleteTrainee(trainee: Omit<ITrainingEditData, "inactive">) {
    this.store.dispatch(Action.setTrainingStatus("LOADING"))
    this.store.dispatch(Action.setTrainingTarget("deleteTrainee"))
    Middletier.xhrPost(
      "/training/deleteTrainee",
      JSON.stringify({ trainee }),
      (response: any) => {
        C.Plans.getFeatureUsageProfile()
        const errors = response.data?.errors
        const success = response.data?.success
        if (errors?.length) {
          for (const error of errors) {
            C.toastNotification(error, "danger")
          }
        }
        if (success) {
          C.toastNotification(success, "success", 10000)
        }
        if (response?.error) {
          C.toastNotification(response?.error, "danger")
        }
        this.loadTrainingData()
      },
    )
  }

  loadTrainingData(updateWithSync: boolean = false) {
    this.store.dispatch(Action.setTrainingStatus("LOADING"))
    this.store.dispatch(Action.setTrainingTarget("loadTrainingData"))
    const endpoint = "/training/loadTrainingData"
    Middletier.xhrPost(
      endpoint,
      JSON.stringify({ updateWithSync }),
      (response: any) => {
        if (response?.isSyncError) {
          C.toastNotification(
            "Failed to upload the most recent report",
            "danger",
          )
        }
        if (response.success) {
          const data = response.data
          this.store.dispatch(Action.setTrainingData(data))
        } else {
          C.toastNotification(response?.error, "danger")
          serverLog({
            level: "error",
            message: "Load training data failed",
            endpoint,
            filename: __filename,
            payload: response,
          })
        }
      },
      () => {},
    )
  }
}

export default Training
