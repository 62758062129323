import { IRatingDetails } from "@src/types"
import * as React from "react"

import CategoryNameGrade from "../CategoryNameGrade"
import { CategoryListStyled } from "./styles"

interface IProps {
  category: string
  data: IRatingDetails[]
}

const CategoryList = ({ category, data }: IProps) => {
  const filtered = data
    .filter((risk) => risk.category === category)
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .sort((a, b) => (a.beta ? (b.beta ? 0 : 1) : b.beta ? -1 : 0))

  return (
    <CategoryListStyled>
      <h3>{category}</h3>
      <ul>
        {filtered.map((risk) => (
          <CategoryNameGrade key={risk.id} risk={risk} />
        ))}
      </ul>
    </CategoryListStyled>
  )
}

export default CategoryList
