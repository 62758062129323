import { shadows } from "@src/theme"
import * as React from "react"
import styled from "styled-components"

import ThreeDotMenu from "./ThreeDotMenu"

interface ICard {
  content: JSX.Element
  menuItems: IMenuItemConf[]
}

interface IMenuItemConf {
  iconClass: string
  caption: string
  action: () => void
}

const Card = (props: ICard) => {
  return (
    <Panel>
      {props.content}
      <ThreeDotMenu menuItems={props.menuItems} />
    </Panel>
  )
}

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-radius: 6px;
  box-shadow: ${shadows.panel};
  border: solid 1px #979797;
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 9px;
`

export default Card
