import { colors, media } from "@src/theme"
import styled from "styled-components"

export const QuestionSelectorStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 450px;
  span {
    margin-right: 9px;
    font-weight: bold;
  }
  .react-select-container {
    width: 100%;
  }
  @media (max-width: 1150px) {
    margin-top: 25px;
    margin-left: auto;
  }
  @media ${media.medium} {
    margin-top: 15px;
  }
`
export const MenuItemText = styled.div<{ isSelected?: boolean }>`
  flex-grow: 1;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ isSelected }) => isSelected && "darkgreen"};
`
export const QuestionNumber = styled.span<{ isSelected?: boolean }>`
  color: ${({ isSelected }) => (isSelected ? "darkgreen" : colors.gray20)};
  margin-right: 10px;
  width: 16px;
  text-align: center;
`
export const MenuItem = styled.div<{ answered?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  color: ${({ answered }) => answered && colors.success};
  span {
    color: ${({ answered }) => answered && colors.success};
  }
`

export const GroupItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: ${colors.darkBlue};
  cursor: pointer;
`

export const GroupIcon = styled.div`
  margin-right: 5px;
  min-width: 23px;
`

export const Title = styled.div`
  flex-grow: 1;
`

export const ControlPanel = styled.div<any>`
  display: ${(p) => (p.hidden ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  width: 34px;
  cursor: pointer;
`
export const MenuItemIcon = styled.i<{ color: string }>`
  color: ${({ color }) => color};
`
