import * as React from "react"
import ReactSelect from "react-select"

import { ErrorMeaage, Label, SelectContainer } from "./styles"

interface ISelectValue {
  value: string | number
  label: string
}

interface ISelect {
  name: string
  label: string
  placeholder: string
  isSearchable?: boolean
  options: ISelectValue[]
  onChange: (field: string, value: string | number) => void
  value: ISelectValue | undefined
  defaultValue?: ISelectValue
  margin?: string
  maxWidth?: string
  errorMessage?: string
}

export const Select = ({
  name,
  label,
  placeholder,
  isSearchable,
  options,
  onChange,
  value,
  defaultValue,
  margin,
  maxWidth,
  errorMessage,
}: ISelect) => {
  const onSelect = (el: ISelectValue) => {
    onChange(name, el.value)
  }

  return (
    <SelectContainer margin={margin} maxWidth={maxWidth}>
      <ReactSelect
        className={"react-select-container"}
        classNamePrefix="react-select"
        placeholder={placeholder}
        isSearchable={isSearchable}
        options={options}
        onChange={onSelect}
        defaultValue={defaultValue}
        value={value}
      />
      <Label>{label}</Label>
      <ErrorMeaage>{errorMessage}</ErrorMeaage>
    </SelectContainer>
  )
}
