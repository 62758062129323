import { colors, media } from "@src/theme"
import styled from "styled-components"

interface IFaIcon {
  large?: boolean
}

export const Container = styled.div`
  display: flex;
  margin-bottom: 35px;
  @media ${media.medium} {
    flex-direction: column;
  }
`
export const Form = styled.form`
  width: 100%;
  max-width: 650px;
  @media ${media.medium} {
    max-width: 100%;
  }
`
export const Hr = styled.hr`
  margin-bottom: 35px;
`
export const Title = styled.h1`
  font-size: 32px;
  margin: 0 0 20px 0;
  @media ${media.medium} {
    font-size: 21px;
  }
`

export const SubTitle = styled.h1`
  margin: 0 0 0 0;
  font-size: 22px;
  @media ${media.medium} {
    font-size: 16px;
  }
`
export const FaIcon = styled.i<IFaIcon>`
  font-size: ${({ large }) => (large ? "64" : "26")}px;
  margin-right: ${({ large }) => (large ? "36" : "12")}px;
  color: ${colors.darkBlue};
  width: ${({ large }) => large && "100%"};
  max-width: ${({ large }) => large && "62px"};
`
