import { ButtonDashboard } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { FaIcon } from "@src/Components/FaIcon"
import Tooltip from "@src/Components/Tooltip"
import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import * as React from "react"
import { useSelector } from "react-redux"

import {
  CTA,
  DashboardCardHeading,
  FaIconHead,
  FeatureCard,
  HeadingBadge,
  HeadingImg,
} from "../styles"
import { EmptyStateCard } from "./EmptyState"
import { FilledStateCard } from "./FilledState"

interface ICyberConciergeCard {
  gridColumn?: string
  setView: (isEmptyState: boolean) => void
}

export const CyberConciergeCard = ({
  gridColumn,
  setView,
}: ICyberConciergeCard) => {
  const { isLargeScreen } = usePortalSize()
  const numberOfAlerts = useSelector(
    (state: tState) => state.portal.dashboard.cyberConcierge?.numberOfAlerts,
  )

  const isEmptyState = !numberOfAlerts

  return (
    <FeatureCard
      color={colors.violet}
      gridColumn={gridColumn}
      minHeight={
        (isLargeScreen && (isEmptyState ? "260px" : "285px")) || undefined
      }
    >
      <DashboardCardHeading marginBottom={isLargeScreen ? "20px" : "40px"}>
        <HeadingImg src="/images/concierge_color.svg" width="47px" />
        CYBER CONCIERGE
        <HeadingBadge content="NEW" />
        {!isEmptyState && (
          <FaIconHead
            className="fas fa-info-circle"
            data-tip
            data-for="card-title-cyber-concierge-tooltip"
          />
        )}
      </DashboardCardHeading>
      <Tooltip
        id="card-title-cyber-concierge-tooltip"
        type="dark"
        content={
          <p>
            <strong>Get live help from a cyber expert.</strong>
            <br />
            Included with your subscription.
          </p>
        }
      />
      {isEmptyState ? (
        <EmptyStateCard />
      ) : (
        <FilledStateCard numberOfAlerts={numberOfAlerts} />
      )}
      <CTA>
        {isEmptyState ? (
          <ButtonDashboard width="60%" onClick={() => setView(isEmptyState)}>
            <FaIcon className="fas fa-user-headset" margin="0 6px 0 0" />
            Learn More
          </ButtonDashboard>
        ) : (
          <ButtonDashboard width="60%" onClick={() => setView(isEmptyState)}>
            <FaIcon
              className="fas fa-exclamation-triangle"
              margin="0 6px 0 0"
            />
            View Security Alerts
          </ButtonDashboard>
        )}
      </CTA>
    </FeatureCard>
  )
}
