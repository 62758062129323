import * as Sentry from "@sentry/react"
import * as T from "@src/types"

import * as Middletier from "./Middletier"

export const serverLog = (log: T.tLog) => {
  Sentry.captureException(JSON.stringify(log))

  Middletier.xhrPost(
    "/utils/logger",
    JSON.stringify(log),
    () => {},
    () => {
      console.warn("WARNING: logging to server failed ")
    },
  )
}
