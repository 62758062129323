import styled from "styled-components"

enum HeadingVariants {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
}

const headingFondSizes: Record<HeadingVariants, string> = {
  [HeadingVariants.h1]: "32px",
  [HeadingVariants.h2]: "28px",
  [HeadingVariants.h3]: "24px",
  [HeadingVariants.h4]: "21px",
  [HeadingVariants.h5]: "18px",
  [HeadingVariants.h6]: "16px",
}

interface IHeading {
  variant?: keyof typeof HeadingVariants
  fontFamily?: string
  fontSize?: string
  fontWeight?: string
  lineHeight?: string
  color?: string
  margin?: string
  padding?: string
}

const Heading = styled.h3.attrs<IHeading>(({ variant = "h4" }) => ({
  as: variant,
  fontSize: headingFondSizes[variant],
}))<IHeading>`
  font-family: ${({ fontFamily }) => fontFamily || "montserrat"};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight || "600"};
  line-height: ${({ lineHeight }) => lineHeight || "1.4"};
  color: ${({ color }) => color || "#26249E"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding};
`

export { Heading }
