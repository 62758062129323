import * as React from "react"
import styled from "styled-components"

const TitledList = (p: any) => {
  return (
    <DescriptionPanel width={p.width}>
      <DescriptionTitle hidden={!p.title}>{p.title}</DescriptionTitle>
      {p.orderedList ? (
        <OrderedList width={p.width}>
          {p.items.map((e: any, n: number) => (
            <ListItem key={n} itemSpace={p.itemSpace}>
              {e}
            </ListItem>
          ))}
        </OrderedList>
      ) : (
        <List width={p.width}>
          {p.items.map((e: any, n: number) => (
            <ListItem key={n} itemSpace={p.itemSpace}>
              {e}
            </ListItem>
          ))}
        </List>
      )}
    </DescriptionPanel>
  )
}

const List = styled.ul<any>`
  margin: 15px 0px 0px 0px;
  text-align: left;
  width: ${(p: any) => p.width}px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`

const OrderedList = styled.ol<any>`
  margin: 15px 0px 0px 0px;
  text-align: left;
  width: ${(p: any) => p.width}px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`

const ListItem = styled.li<any>`
  padding-bottom: ${(p: any) => p.itemSpace || 15}px;
`

const DescriptionTitle = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: bold;
  color: #212528;
  font-family: montserrat;
`

const DescriptionPanel = styled.div<any>`
  display: ${(p: any) => (p.hidden ? "none" : "flex")};
  flex-direction: column;
  text-align: left;
  width: ${(p: any) => p.width}px;
`

export default TitledList
