import { colors } from "@src/theme"
import styled from "styled-components"

interface IRatingText {
  companyUrl?: boolean
  color?: string
}

export const OverviewStyled = styled.div`
  display: flex;

  .overview-left-container {
    padding: 15px;
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .security-graph-wrapper {
    text-align: center;
    h4 {
      margin-bottom: 0;
    }
  }
  .overview-right-container {
    padding: 15px;
    width: 80%;
    display: flex;
    flex-direction: column;
    background: ${colors.gray3};

    img {
      max-width: 250px;
      margin: 0 auto;
      margin-bottom: 25px;
    }
  }
  .overview-rating-title {
    margin-top: 0;
  }

  .overview-right-head {
    text-align: center;
    justify-content: center;

    .overview-right-head-sub {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-wrap {
      display: flex;
    }

    p {
      display: inline-block;
      font-weight: 600;
      font-size: 18px;
      max-width: 200px;
      width: 100%;
    }

    button {
      margin-top: 25px;
      margin-bottom: 80px;
    }
  }
  .overview-right-head-wrap {
    justify-content: center;
  }
  .overview-footer-text {
    color: ${colors.gray50};

    a {
      color: ${colors.link};
    }
  }

  h3 {
    color: ${colors.black};
    border-bottom: 1px solid ${colors.gray30};
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .overview-list-container {
    display: flex;
  }

  .category-list-wrapper {
    margin-right: 30px;
    width: 100%;
  }

  @media (max-width: 1100px) {
    flex-direction: column;

    .overview-right-container {
      width: 100%;
    }
  }
`

export const RatingText = styled.span<IRatingText>`
  margin: 0 10px;
  font-size: 30px;
  color: ${({ companyUrl, color }) =>
    companyUrl ? color || colors.blue50 : "transparent"};
  font-weight: 700;
  text-shadow: ${({ companyUrl }) => !companyUrl && `0 0 8px ${colors.link}`};
  display: flex;
  align-items: flex-end;
`

export const ButtonWrap = styled.div`
  // max-width: 180px;
  width: 50%;
  margin: auto;
`
export const ErrorMsg = styled.p`
  color: ${colors.error};
  margin-bottom: 20px;
`
