import { tState } from "@src/Model/Model"
import { ViewTypes } from "@src/types"
import {
  CourseResults as ICourseResults,
  TraineeData as ITrainee,
} from "@zeguro/schema-validator/dist/types/coco/training/combined"
import * as moment from "moment"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import CompanyTab from "./CompanyTab"
import CourseTab from "./CourseTab"
import { TrainingContainer } from "./styles"
import TraineeTab from "./TraineeTab"

interface IReportCard {
  companyName: string
  stepTab: "company" | "course" | "trainee"
  currentCourse: ICourseResults | undefined
  currentTrainee: ITrainee | undefined
  setCurrentTrainee: (key: ISetCurrentTrainee) => void
}

export interface ISetCurrentTrainee {
  name: string
  lastName: string
  traineeId: string
}

const ReportCard: React.FC<IReportCard> = ({
  companyName,
  stepTab,
  currentCourse,
  currentTrainee,
  setCurrentTrainee,
}) => {
  const history = useHistory()
  const { courseMap, traineesData, ts } = useSelector(
    (state: tState) => state.training.trainingData,
  )

  const date = moment(ts).format("MMM D, YYYY")

  return (
    <TrainingContainer>
      {stepTab === "company" && (
        <CompanyTab
          courseList={courseMap}
          setCurrentCourse={(course) => {
            const courseName =
              course.name === "Beginner" ? "Knowledge Assessment" : course.name
            history.push(`${ViewTypes.training}/reportcards/${courseName}`)
          }}
          companyName={companyName}
          date={date}
        />
      )}
      {stepTab === "course" && currentCourse && (
        <CourseTab
          courseResultsList={traineesData}
          date={date}
          currentCourse={currentCourse || traineesData[0]}
          setCurrentTrainee={setCurrentTrainee}
        />
      )}
      {stepTab === "trainee" && currentTrainee && (
        <TraineeTab
          courseResultsList={traineesData}
          date={date}
          currentTrainee={currentTrainee}
          courseKey={currentCourse?.name || ""}
        />
      )}
    </TrainingContainer>
  )
}

export default React.memo(ReportCard)
