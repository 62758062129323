export const VIEW_PATHS: Record<string, string> = {
  dashboard: "/dashboard",
  cybermaturity: "/cybermaturity",
  attacksurface: "/attacksurface",
  compliance: "/securitypolicy",
  securityPolicies: "/securitypolicy",
  monitoring: "/webappscan",
  training: "/training",
  marketplace: "/marketplace",
  insurance: "/insurance",
  settings: "/settings",
  subscription: "/plan",
  intro: "/intro",
  cyberconcierge: "/concierge",
}
export const VIEW_PATHS_NAV_MENU: Record<string, Array<string>> = {
  securitypolicy: ["/securitypolicy/:id/:version"],
  monitoring: ["/webappscan/:targetName"],
  training: ["/training/:tab/:course/:trainee", "/training/:tab/:course"],
  marketplace: ["/marketplace/:partner"],
}
