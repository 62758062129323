import * as T from "@src/types"
import { ActionCreator } from "redux"

import * as ACTION from "./ActionTypes"

export const setTrainingData = (trainingData: T.tDictAny) => ({
  type: ACTION.SET_TRAINING_DATA,
  payload: trainingData,
})

export const setTrainingStatus = (status: string) => ({
  type: ACTION.SET_TRAINING_STATUS,
  payload: status,
})

export const setTrainingTarget = (target: string) => ({
  type: ACTION.SET_TRAINING_TARGET,
  payload: target,
})

export const getTrainingOrgListSaga: ActionCreator<{
  orgId: string
  usecureCustomerId?: string
}> = (orgId, usecureCustomerId) => ({
  type: "GET_TRAINING_ORG_LIST_WATCHER",
  orgId,
  usecureCustomerId,
})
