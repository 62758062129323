import { colors, media } from "@src/theme"
import styled from "styled-components"

interface IStyle {
  hidden?: boolean
  status?: string
  flex?: string
  color?: string
}
interface ILinkPanel {
  paddingTop?: string | undefined
}
export const NonCompleted = styled.span`
  color: red;
`

export const Info = styled.div`
  padding-left: 27px;
  border-radius: 4px;
  border: solid 1px rgba(0, 98, 216, 0.5);
  background-color: rgba(0, 98, 216, 0.05);
  padding: 12px 28px;
  @media ${media.medium} {
    padding: 8px 12px;
  }
`

export const ActionLinkContent = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
`

export const PopUpContent = styled.div`
  max-width: 350px;
`

export const LinkPanel = styled.div<ILinkPanel>`
  display: flex;
  justify-content: flex-end;
  gap: 0 24px;
  @media ${media.medium} {
    flex-direction: column;
  }
`

export const Version = styled.span`
  display: ${(p: IStyle) => (p.hidden ? "none" : null)};
  font-weight: normal;
  font-size: 16px;
  color: #707379;
  margin: 2px 10px 2px 0;
`

export const TitleText = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${colors.black50};
  vertical-align: baseline;
  margin-right: 10px;
  @media ${media.medium} {
    font-size: 16px;
  }
`

export const TitleInput = styled.input`
  font-size: 32px;
  font-weight: 600;
  color: #271b9c;
  vertical-align: baseline;
  margin-right: 10px;
  border: 1px solid ${colors.gray20};
  padding: 0 8px;
  border-radius: 5px;
  max-width: 100%;
`

export const PolicyPane = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

export const PolicyText = styled.div`
  padding-top: 10px;
  border-top: solid 1px #d6d6d6;
`

export const PolicyHeader = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const ApprovalDate = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #858585;
`

export const UserDirective = styled.div`
  display: ${(p: IStyle) => (p.hidden ? "none" : null)};
  margin-top: 8px;
`

export const ButtonRow = styled.div`
  display: ${(p: IStyle) => (p.hidden ? "none" : "flex")};
  margin: 30px 0 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  @media ${media.medium} {
    margin: 10px 0;
    flex-direction: column;
  }
`

export const PopoverLink = styled.div`
  cursor: pointer;
`

export const ActionLinkText = styled.div<{
  textColor?: string
  hidden?: boolean
}>`
  display: ${(p: IStyle) => (p.hidden ? "none" : "flex")};
  font-size: 16px;
  line-height: 1.31;
  letter-spacing: 0.2px;
  white-space: nowrap;
  cursor: pointer;
  align-items: center;
  color: ${({ textColor }) => textColor || "#212528"};
`

export const StatusBadge = styled.div`
  display: ${(p: IStyle) => (p.hidden ? "none" : "inline-block")};
  height: 19px;
  white-space: nowrap;
  border-radius: 3px;
  background-color: ${(p: IStyle) => p.color || "default"};
  color: #fff;
  font-size: 13px;
  line-height: 19px;
  padding: 0px 10px;
  vertical-align: text-bottom;
  margin-right: 10px;
`

export const BackButton = styled.button`
  cursor: pointer;
  text-align: center;
  background: transparent;
  height: 32px;
  border: none;
  color: ${colors.darkBlue};
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
  i {
    margin-right: 5px;
    font-size: 13px;
  }
  &:hover {
    opacity: 0.7;
  }
  @media ${media.medium} {
    margin: 6px 8px;
  }
`

export const ButtonsPanel = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px 20px;
  @media ${media.medium} {
    flex-direction: column-reverse;
  }
`
