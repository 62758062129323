import { usePortalSize } from "@src/Components/Common/usePortalSize"
import ZegPanel from "@src/Components/Common/ZegPanel"
import { FaIcon } from "@src/Components/FaIcon"
import { ContentArea } from "@src/Components/View"
import Waiting from "@src/Components/Waiting"
import { tState } from "@src/Model/Model"
import {
  getCyberConciergeAlertsWatcher,
  getCyberConciergeConfigWatcher,
} from "@src/Sagas"
import * as T from "@src/types"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"

import HeaderCard from "./HeaderCard"
import { TabItem } from "./styles"
import About from "./Tabs/About"
import Alerts from "./Tabs/Alerts"
import GeneralAlerts from "./Tabs/GeneralAlerts"

const TAB_IDS = ["about", "alerts", "generalalerts"]
const CyberConcierge = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { clientWidth } = usePortalSize()
  const match = useRouteMatch<{ tabId?: string }>()
  const tabId = match?.params?.tabId
  const { isLoading } = useSelector(
    (store: tState) => store.cyberConcierge?.getCyberConciergeConfig,
  )
  const { alerts, isLoading: isAlertsLoading } = useSelector(
    (state: tState) => state.cyberConcierge.getCyberConciergeAlerts,
  )
  const tabsData = () => [
    {
      id: "about",
      text: (
        <TabItem>
          <FaIcon className="far fa-info-circle" />{" "}
          {clientWidth >= 1100 ? "About Cyber Concierge" : "About Concierge"}
        </TabItem>
      ),
      path: "about",
    },
    {
      id: "alerts",
      text: (
        <TabItem>
          <FaIcon className="far fa-building" />{" "}
          {clientWidth >= 1100 ? "Alerts for Your Organization" : "Your Alerts"}{" "}
          ({alerts.length})
        </TabItem>
      ),
      path: "alerts",
    },
    {
      id: "generalalerts",
      text: (
        <TabItem>
          <img
            src="images/cyberconcierge/icon_cloud-exclamation.svg"
            alt="cloud exclamation"
          />{" "}
          General Alerts
        </TabItem>
      ),
      path: "generalalerts",
    },
  ]

  const onTabChange = (tabId: string) => {
    history.push(`${T.ViewTypes.cyberConcierge}/${tabId}`)
  }

  React.useEffect(() => {
    if (tabId && !TAB_IDS.includes(tabId)) {
      history.push("/404")
    }

    dispatch(getCyberConciergeConfigWatcher())
    dispatch(getCyberConciergeAlertsWatcher({ tabId }))
  }, [])

  if (isLoading || isAlertsLoading) {
    return <Waiting text="Loading..." />
  }
  return (
    <ContentArea padding="32px">
      <HeaderCard cardIconUrl="/images/concierge_color.svg" />
      <ZegPanel
        headTitle="Choose a section"
        tabs={tabsData()}
        onTabChange={onTabChange}
        selectedTabId={tabId || "about"}
      >
        {tabId === "about" && <About />}
        {tabId === "alerts" && <Alerts />}
        {tabId === "generalalerts" && <GeneralAlerts />}
      </ZegPanel>
    </ContentArea>
  )
}
export default CyberConcierge
