import styled from "styled-components"

interface IText {
  fontSize?: string
  fontWeight?: string
  lineHeight?: string
  color?: string
  padding?: string
  textAlign?: string
  margin?: string
  cursor?: string
}

const Text = styled.span<IText>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight || "1.4"};
  color: ${({ color }) => color};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  text-align: ${({ textAlign }) => textAlign};
  cursor: ${({ cursor }) => cursor};
`

export { Text }
