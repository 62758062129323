import { colors } from "@src/theme"
import styled from "styled-components"

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Link = styled.div`
  color: ${colors.link};
  cursor: pointer;
  white-space: normal;

  &:hover {
    opacity: 0.7;
  }
`

export const CellText = styled.div`
  white-space: normal;
`

export const customStyles: any = {
  rows: {
    style: {
      minHeight: 45,
    },
  },
  headCells: {
    style: {
      "&:nth-child(2)": {
        flex: 3,
        paddingLeft: 13,
        paddingRight: 13,
      },
      "&:nth-child(3)": {
        flex: 2,
        minWidth: 175,
        paddingLeft: 15,
        paddingRight: 15,
      },
      "&:last-child": {
        minWidth: 180,
        flex: 2,
        paddingLeft: 18,
        paddingRight: 18,
        ".rdt_TableCol_Sortable": {
          flex: "1",
          ">div": {
            width: "100%",
          },
        },
      },
    },
  },
  cells: {
    style: {
      display: "flex",
      alignItems: "baseline",
      "&:first-child": {
        paddingTop: 12,
        alignItems: "baseline",
      },
      "&:nth-child(2)": {
        flex: 3,
        paddingLeft: 13,
        paddingRight: 13,
      },
      "&:nth-child(3)": {
        flex: 2,
        minWidth: 175,
        paddingLeft: 15,
        paddingRight: 15,
      },
      "&:last-child": {
        minWidth: 180,
        flex: 2,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 18,
        paddingRight: 18,
      },
    },
  },
}
